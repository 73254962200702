import { ButtonBase, ListItem } from '@mui/material'
import React from 'react'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { AnalyticEvent } from '../../../analytics/adobe/types'

const styles: Styles = {
  listItem: {
    padding: 0,
  },
  link: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    paddingX: '0.75rem',
    height: '100%',
    fontFamily: tokens.type.fontFamilies.preloBook,
    fontSize: '1rem',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    '&[aria-current="true"]': {
      fontFamily: tokens.type.fontFamilies.preloBold,
    },
  }),
  button: (theme) => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingX: '0.75rem',
    height: '100%',
    fontFamily: tokens.type.fontFamilies.preloBook,
    fontSize: '1rem',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  }),
}

const analyticEventType: AnalyticEvent = 'Navigation_Click'

interface ButtonProps {
  children: React.ReactNode
  tabIndex?: number
  testId: string
  onClick: (params: unknown) => unknown
}

const ButtonNavListItem = ({
  children,
  onClick,
  tabIndex,
  testId,
}: ButtonProps) => (
  <ListItem sx={styles.listItem}>
    <ButtonBase
      sx={styles.button}
      tabIndex={tabIndex}
      data-testid={testId}
      data-analytics-id={testId}
      data-analytic-event={analyticEventType}
      onClick={onClick}>
      {children}
    </ButtonBase>
  </ListItem>
)

export default ButtonNavListItem
