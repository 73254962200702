import React from 'react'

const HousePercentIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.77 72.2002H15.63V43.6902H4L40.26 6.74023L51.69 18.7402V11.6002H60.38V27.4902L77 43.6902H64.47V72.2002H59.57"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M20.2891 41.4401C20.2891 36.2401 23.1791 33.0001 28.5591 33.0001C33.9391 33.0001 36.8291 36.2401 36.8291 41.4401C36.8291 46.6401 33.9991 49.9401 28.5591 49.9401C23.1191 49.9401 20.2891 46.7001 20.2891 41.4401ZM32.1391 41.4401C32.1391 38.3201 30.9291 36.7601 28.5591 36.7601C26.1891 36.7601 25.0291 38.3201 25.0291 41.4401C25.0291 44.5601 26.2391 46.1801 28.5591 46.1801C30.8791 46.1801 32.1391 44.5601 32.1391 41.4401ZM36.6491 72.1301L27.7791 76.4201L27.4891 67.4201L30.0391 68.4101L48.3891 32.7401L46.3591 31.8001L53.2291 27.5801L53.4891 35.5801L51.7891 34.7001L33.8991 70.2501L36.6491 72.1401V72.1301ZM42.3591 65.6701C42.3591 60.4101 45.2491 57.1701 50.6291 57.1701C56.0091 57.1701 58.8991 60.4101 58.8991 65.6701C58.8991 70.9301 56.0691 74.1701 50.6291 74.1701C45.1891 74.1701 42.3591 70.8701 42.3591 65.6701ZM54.2091 65.6701C54.2091 62.5501 52.9991 60.9901 50.6291 60.9901C48.2591 60.9901 47.0991 62.5501 47.0991 65.6701C47.0991 68.7901 48.3091 70.3501 50.6291 70.3501C52.9491 70.3501 54.2091 68.7901 54.2091 65.6701V65.6701Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default HousePercentIcon
