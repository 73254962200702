import { Box, Link, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnalyticEvent } from '../../../analytics/adobe/types'
import { Styles } from '../../../types'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const styles: Styles = {
  container: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      display: 'flex',
    },
  }),
  heading: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    color: theme.palette.secondary.main,
    fontSize: '1.125rem',
    // BEGIN cross-browser compatible line-clamp styles from Tailwind
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    // END cross-browser compatible line-clamp styles from Tailwind
    [theme.breakpoints.down('tablet')]: {
      flexWrap: 'wrap',
    },
  }),
  link: (theme) => ({
    maxWidth: '6.875rem',
    color: theme.palette.secondary.main,
    textDecorationColor: theme.palette.secondary.main,
  }),
}

interface ArticleListItemTitleProps {
  articleIndex: number
  articleTitleId: string
  focusIndex?: number
  title?: string
  id?: string
}

const ArticleListItemTitle = ({
  articleIndex,
  articleTitleId,
  focusIndex = 0,
  title = '',
  id = undefined,
}: ArticleListItemTitleProps) => {
  const focusElementRef = useRef(null as unknown as HTMLDivElement)
  const navigate = useNavigate()

  useEffect(() => {
    // scrollIntoView(false) to scroll align the element to the bottom of the scrollable parent element
    focusElementRef?.current?.scrollIntoView(false)
  }, [focusElementRef?.current])

  return (
    <Box sx={styles.container}>
      <Typography
        sx={styles.heading}
        aria-level={2}
        id={`${articleTitleId}heading`}
        ref={articleIndex === focusIndex ? focusElementRef : undefined}
        role="heading"
        tabIndex={articleIndex === focusIndex ? -1 : undefined}
        data-testid={`Lbl_Article_Title_${articleIndex}`}>
        <Link
          sx={styles.link}
          aria-label={title}
          data-testid={`Lnk_Article_Title_${articleIndex}`}
          data-analytic-event={analyticEventType}
          onClick={() => navigate(`/insights/${encodeURI(id ?? '')}`)}
          href="#ref">
          {title}
        </Link>
      </Typography>
    </Box>
  )
}

export default ArticleListItemTitle
