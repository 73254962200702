import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import { Heading } from '../../components/typography'

const NoMatch = () => {
  const { t } = useTranslation()

  useEffect(() => {
    document.title = t('ECPNoMatchTitle')
  }, [])

  return (
    <>
      <MainMenu />
      <MainContent>
        <Heading>{t('NoMatchHeader')}</Heading>
      </MainContent>
    </>
  )
}

export default NoMatch
