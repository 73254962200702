import React from 'react'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../utils/hooks'
import AccountMenu from '../AccountMenu'
import ToolBarNotifyMenu from '../ToolBarNotifyMenu'
import { Styles } from '../../../types'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'

const styles: Styles = {
  userInfo: (theme) => ({
    textAlign: 'right',
    [theme.breakpoints.down('tablet')]: {
      display: 'none',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'block',
    },
  }),
  mobileNotificationInfo: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      display: 'block',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
  }),
  notificationBell: {
    marginLeft: '1rem',
  },
}

const ToolBarHeaderName = () => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(getUserInfo)

  return (
    <>
      <Box sx={styles.userInfo}>
        <Box sx={{ lineHeight: 2.5 }} data-testid="Lbl_Header_WelcomeFull">
          {t('Navigation.Welcome')}
          {userInfo?.firstName ? ', ' : ' '}
          <Typography
            variant="body1"
            component="span"
            sx={{ fontFamily: 'prelo-bold' }}
            data-testid="Lbl_Header_WelcomeName">
            {userInfo?.firstName} {userInfo?.lastName}
          </Typography>
          <AccountMenu />
          {isFeatureFlagEnabled('Notifications') && userInfo.isEmployee && (
            <Box
              data-testid="Sec_Notifications_DesktopContainer"
              sx={styles.notificationBell}
              component="span">
              <ToolBarNotifyMenu />
            </Box>
          )}
        </Box>
      </Box>
      {isFeatureFlagEnabled('Notifications') && userInfo.isEmployee && (
        <Box
          data-testid="Sec_Notifications_MobileContainer"
          sx={styles.mobileNotificationInfo}>
          <ToolBarNotifyMenu />
        </Box>
      )}
    </>
  )
}

export default ToolBarHeaderName
