import React from 'react'

const ToastSuccessIcon = ({ color }: any) => (
  <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.0002 0.333496C12.2802 0.333496 0.333496 12.2802 0.333496 27.0002C0.333496 41.7202 12.2802 53.6668 27.0002 53.6668C41.7202 53.6668 53.6668 41.7202 53.6668 27.0002C53.6668 12.2802 41.7202 0.333496 27.0002 0.333496ZM21.6668 40.3335L8.3335 27.0002L12.0935 23.2402L21.6668 32.7868L41.9068 12.5468L45.6668 16.3335L21.6668 40.3335Z"
      fill={color} />
  </svg>
)

export default ToastSuccessIcon
