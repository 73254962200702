import React from 'react'

const ContractIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131003)">
      <path
        d="M80.3801 65.8409C66.3301 67.2909 57.8802 57.0809 56.4302 43.0309L52.6502 6.38086L1.66016 11.6509L5.51016 48.2909C6.96016 62.3309 14.3802 72.5509 28.4302 71.1009L80.3902 65.8409H80.3801Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.66016 19.7306L15.4002 19.1406"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.1504 24.4599L27.3804 22.6699"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.6094 28.9508L19.2294 28.0508"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.9199 64.9899L58.0399 61.6699"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.5195 60.93C29.8195 61.82 31.6895 61.95 32.4995 61.99C33.6195 62.04 34.0995 61.68 34.9595 61.01C36.3895 59.91 38.3895 57.57 36.9695 55.68C35.9895 54.36 34.6995 55.19 33.9495 56.32C33.3095 57.29 33.2895 58.38 33.5695 59.5C34.0695 61.54 37.9195 67.97 33.8795 68.56C31.6295 68.89 30.6995 66.96 31.9995 65.18C32.7595 64.13 33.7295 63.97 34.9295 63.85"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M36.0791 62.0905C35.6891 63.5505 37.5891 64.5305 38.8291 64.0605C39.9891 63.6205 40.9591 61.4205 40.7991 60.1905C40.3391 56.6805 36.4291 60.7905 39.0591 62.4205C40.1791 63.1205 41.8491 63.3805 42.6391 62.1005C43.0791 61.3905 43.8191 59.1505 43.2591 58.3505C42.3091 57.0105 40.8991 58.8405 40.9991 59.9405C41.1591 61.6305 43.4091 62.4805 44.9191 62.3905"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.8594 41.0698L46.3294 37.5098"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.2695 45.0598L40.9995 42.0898"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.6797 49.0092L47.1497 45.4492"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.1699 53.7706L36.1499 51.3906"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131003">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ContractIcon
