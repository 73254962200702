import React from 'react'

const VSTX = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="2" y="12" width="16" height="7" rx="0.8" fill="#576D7E" />
    <path
      d="M5.71138 16.2354L6.38594 13.9392H7.05171L6.08052 17.022H5.30708L4.3249 13.9392H5.02144L5.70698 16.2354H5.71138ZM8.69863 14.4578C8.34854 14.4578 8.17349 14.561 8.17349 14.7676C8.17349 14.8877 8.2438 14.9785 8.38442 15.04C8.4811 15.0825 8.6415 15.1301 8.86563 15.1829C9.48086 15.3293 9.78848 15.6333 9.78848 16.0947C9.78848 16.4097 9.68081 16.647 9.46548 16.8066C9.25161 16.9648 8.95938 17.0439 8.58877 17.0439C8.21816 17.0439 7.8439 16.9875 7.46597 16.8748L7.56924 16.3474C7.95303 16.4368 8.30532 16.4814 8.62612 16.4814C8.77847 16.4814 8.90005 16.4514 8.99087 16.3914C9.08169 16.3298 9.1271 16.2537 9.1271 16.1628C9.1271 16.0706 9.10586 16.0061 9.06338 15.9695C9.0209 15.9329 8.98794 15.9065 8.9645 15.8904C8.92642 15.864 8.84438 15.8318 8.71841 15.7937C8.5939 15.7556 8.49429 15.7285 8.41958 15.7124C7.81313 15.5381 7.50991 15.2188 7.50991 14.7544C7.50991 14.46 7.61318 14.2432 7.81973 14.104C8.02773 13.9648 8.3002 13.8953 8.63711 13.8953C8.97549 13.8953 9.33364 13.9524 9.71157 14.0667L9.56655 14.5918C9.18276 14.5024 8.89346 14.4578 8.69863 14.4578ZM11.6837 14.5017V17H11.0531V14.5017H10.2533V13.9392H12.4835V14.5017H11.6837ZM14.2645 15.9299H14.2557L13.6536 17H12.9505L13.8733 15.4114L13.001 13.9392H13.7327L14.2952 14.895H14.3062L14.8489 13.9392H15.5872L14.7105 15.4114L15.6685 17H14.917L14.2645 15.9299Z"
      fill="white"
    />
  </svg>
)

export default VSTX
