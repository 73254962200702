import React from 'react'

const EmptyPcIcon = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9541 87.9099C11.727 87.9099 10.5 86.975 10.5 85.8064V29.2461C10.5 28.0775 11.4816 27.1426 12.9541 27.1426H107.926C109.153 27.1426 110.38 28.0775 110.38 29.2461V85.8064C110.38 86.975 109.399 87.9099 107.926 87.9099H12.9541Z"
      stroke="#009CDE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.6733 87.6758V93.2851"
      stroke="#009CDE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M65.7163 87.9102V93.2857"
      stroke="#009CDE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M83.6306 93.2852H35.7764"
      stroke="#009CDE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17.3713 83.703C16.1442 83.703 15.1626 83.0018 15.1626 81.8332V33.2194C15.1626 32.2845 16.1442 31.3496 17.3713 31.3496H103.754C104.981 31.3496 105.963 32.0508 105.963 33.2194V82.0669C105.963 83.0018 104.981 83.9367 103.754 83.9367H17.3713V83.703Z"
      stroke="#009CDE"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M52.7107 57.526C52.7107 56.3574 51.7291 55.6562 50.7474 55.6562C49.5204 55.6562 48.7842 56.5911 48.7842 57.526C48.7842 58.6946 49.7658 59.3958 50.7474 59.3958C51.7291 59.3958 52.7107 58.6946 52.7107 57.526Z"
      fill="#CECECD"
    />
    <path
      d="M62.5271 57.526C62.5271 56.3574 61.5455 55.6562 60.5638 55.6562C59.3368 55.6562 58.6006 56.5911 58.6006 57.526C58.6006 58.6946 59.5822 59.3958 60.5638 59.3958C61.5455 59.3958 62.5271 58.6946 62.5271 57.526Z"
      fill="#CECECD"
    />
    <path
      d="M72.343 57.526C72.343 56.3574 71.3614 55.6562 70.3798 55.6562C69.1527 55.6562 68.4165 56.5911 68.4165 57.526C68.4165 58.6946 69.3981 59.3958 70.3798 59.3958C71.3614 59.3958 72.343 58.6946 72.343 57.526Z"
      fill="#CECECD"
    />
  </svg>
)

export default EmptyPcIcon
