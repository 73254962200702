/* eslint-disable class-methods-use-this */
import { getAccessToken } from '@rsmus/react-auth'

export default class AuthClientConfig {
  /**
   * Returns a valid value for the Authorization header.
   * Used to dynamically inject the current auth header.
   */
  getAuthorization = (): Promise<string> =>
    getAccessToken().then((at) => `Bearer ${at}`)
}
