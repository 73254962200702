import React from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { visuallyHidden } from '@mui/utils'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import OverFlowToolTip from '../../OverFlowToolTip'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import { InvoicesWithPayments } from '../../../store/invoices/invoiceSelectedInvoicesSlice'

interface ScheduledPaymentSuccessTableProps {
  invoicePayments: InvoicesWithPayments
}

const styles: Styles = {
  tableContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  }),
  scheduledPaymentTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
        },
      },
      '& .MuiTableCell-body': {
        padding: '1.5rem 1rem',
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-root': {
      '&:nth-of-type(10)': {
        width: '3.5rem',
        padding: '0.5rem',
      },
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(5), :nth-of-type(6), :nth-of-type(9)':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ':nth-of-type(6)': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(7), &:nth-of-type(8)':
          {
            display: 'none',
          },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root': {
        '&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(7), &:nth-of-type(8)':
          {
            display: 'none',
          },
        '&:nth-of-type(3)': {
          width: '50%',
        },
        '&:nth-of-type(6)': {
          width: '50%',
        },
      },
    },
  }),
}

const ScheduledPaymentSuccessTable = ({
  invoicePayments,
}: ScheduledPaymentSuccessTableProps) => {
  const { t } = useTranslation()

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      <Table id="invoicesPaymentTable" sx={styles.scheduledPaymentTable}>
        <Box component="caption" sx={visuallyHidden}>
          {t('Invoicing.Invoices')}
        </Box>
        <TableHead>
          <TableRow>
            <TableCell>{t('Invoicing.CustomerName')}</TableCell>
            <TableCell>{t('Invoicing.CustomerNumber')}</TableCell>
            <TableCell>{t('Invoicing.InvoiceNumber')}</TableCell>
            <TableCell>{t('Invoicing.InvoiceDate')}</TableCell>
            <TableCell>{t('Invoicing.OriginalAmount')}</TableCell>
            <TableCell>{t('Invoicing.OpenAmount')}</TableCell>
            <TableCell>{t('Invoicing.Status')}</TableCell>
            <TableCell>{t('Invoicing.Company')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicePayments.map((invoice: any) => (
            <TableRow key={`invoice-${invoice.id}`}>
              <TableCell>
                <OverFlowToolTip>{invoice.customerName}</OverFlowToolTip>
              </TableCell>
              <TableCell>
                <OverFlowToolTip>{invoice.customerNumber}</OverFlowToolTip>
              </TableCell>
              <TableCell>
                <OverFlowToolTip>{invoice.invoiceNumber}</OverFlowToolTip>
              </TableCell>
              <TableCell sx={{ 'text-transform': 'uppercase' }}>
                <OverFlowToolTip>
                  {format(new Date(`${invoice.date}`), 'MMM d, yyyy')}
                </OverFlowToolTip>
              </TableCell>
              <TableCell>
                <OverFlowToolTip>
                  {formatCurrency(invoice.originalAmount)}
                </OverFlowToolTip>
              </TableCell>
              <TableCell>
                <OverFlowToolTip>
                  {formatCurrency(invoice.openAmount)}
                </OverFlowToolTip>
              </TableCell>
              <TableCell>
                <OverFlowToolTip>{invoice.status}</OverFlowToolTip>
              </TableCell>
              <TableCell>
                <OverFlowToolTip>{invoice.company}</OverFlowToolTip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <hr />
    </TableContainer>
  )
}

export default ScheduledPaymentSuccessTable
