import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress, styled, useTheme } from '@mui/material'
import { setCMSHealthInfo } from '../../../store/serviceHealth/serviceHealthSlice'
import {
  ArticleMetaData,
  getSpotlightArticle,
} from '../../../utils/helpers/Article.service'
import SpotlightArticle from './SpotlightArticle'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import BrandEvolutionSpotlightArticle from './BrandEvolutionSpotlightArticle'
import {
  setHasPopBgColor,
  setHasPopBgImg,
  setPopBgColor,
  setPopBgImg,
} from '../../../store/spotlightArticle/spotlightArticleSlice'

import { userAppsStyles } from '../../UserApp/UserApps.styles'

const StyledLoadingContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: '37.5rem',
  paddingTop: '15.75rem',
}))

const SpotlightArticleWrapper = () => {
  const [articleMetadata, setArticleMetaData] = useState<ArticleMetaData>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = userAppsStyles(theme)

  const init = async () => {
    try {
      const article = await getSpotlightArticle()

      if (
        article &&
        article.bannerPoPBackgroundColor &&
        article.bannerPoPBackgroundColor.length > 0
      ) {
        dispatch(setPopBgColor(article.bannerPoPBackgroundColor))
        dispatch(setHasPopBgColor(true))
      }

      if (
        article &&
        article.bannerPoPBackgroundPathDesktop &&
        article.bannerPoPBackgroundPathDesktop.length > 0
      ) {
        dispatch(setPopBgImg(article.bannerPoPBackgroundPathDesktop))
        dispatch(setHasPopBgImg(true))
      }

      if (article === undefined) {
        dispatch(
          setCMSHealthInfo({
            IsHealthy: false,
            ErrorMessage: 'No results found',
          }),
        )
      } else {
        dispatch(
          setCMSHealthInfo({
            IsHealthy: true,
            ErrorMessage: undefined,
          }),
        )
      }
      setArticleMetaData(article)
      setIsLoaded(true)
    } catch {
      dispatch(
        setCMSHealthInfo({
          IsHealthy: false,
          ErrorMessage: 'getSpotlightArticle call failed.',
        }),
      )
      setArticleMetaData(undefined)
      setIsLoaded(true)
    }
  }

  useEffect(() => {
    init()
  }, [])

  if (!isLoaded) {
    return (
      <StyledLoadingContainer>
        <CircularProgress
          sx={classes.circularProgress}
          size="6rem"
          data-testid="Spn_SpotlightArticle_Loading"
        />
      </StyledLoadingContainer>
    )
  }

  return isFeatureFlagEnabled('SpotlightArticleBrandEvolution') ? (
    <BrandEvolutionSpotlightArticle article={articleMetadata} />
  ) : (
    <SpotlightArticle article={articleMetadata} />
  )
}

export default SpotlightArticleWrapper
