function specialCapitalization(givenString: string): string {
  let newString = givenString
  switch (givenString.toUpperCase().replace(/ /g, '')) {
    case 'CECL':
    case 'CEO':
    case 'CFO':
    case 'CHRO':
    case 'CIO':
    case 'CTO':
    case 'ESG':
    case 'M&A':
    case 'SPAC':
      newString = givenString.toUpperCase().replace(/ /g, '')
      break
    // Capitalize just the first word for these ones
    case 'AICPAMATTERS':
    case 'PCAOBMATTERS':
    case 'SECMATTERS':
      newString = `${givenString
        .split(' ')[0]
        .toLocaleUpperCase()} ${givenString.split(' ')[1].toLocaleLowerCase()}`
      break
    default:
      break
  }

  newString = newString.replace(/canada/g, 'Canada')

  return newString
}

export function revertFormattedTagString(formatedString: string): string {
  // Hypens replaced with spaces
  const unFormattedTagValue = formatedString
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/-&-/g, '-and-')

  return unFormattedTagValue
}

// Removes "-" from string
export function formatTagString(unformatedString: string): string {
  // Hypens replaced with spaces
  let newString = unformatedString.replace(/-/g, ' ')

  // first letter capitalized, everything else lower case
  newString = newString.toLowerCase()

  if (newString.length > 1)
    newString = newString.charAt(0).toUpperCase() + newString.slice(1)
  else if (newString.length === 1) newString = newString.charAt(0).toUpperCase()

  // " and " becames " & " but "grand band" remains unchanged
  newString = newString.replace(/ and /g, ' & ')

  // if matches a special capitlization list, captalize the string
  newString = specialCapitalization(newString)

  return newString
}

export function formatFilterGroupings(unformattedString: string): string {
  let newString = unformattedString.replace(/rsm-sites/g, '')
  newString = newString.replace(/rsm-filters/g, '')
  newString = newString.replace(/\//g, '')

  return newString
}
