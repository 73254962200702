import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField, Box } from '@mui/material'
import { Close as CloseIconSmall } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from '../../../components/icons'

interface SearchBoxProps {
  setFilter: (v: string) => void
  placeholderText?: string
  testId?: string
}

const SearchBox = ({
  setFilter,
  placeholderText = '',
  testId = '',
}: SearchBoxProps) => {
  const { t } = useTranslation()
  const [showClear, setShowClear] = useState(false)
  const [value, setValue] = useState('')

  const handleChange = (e: string) => {
    setValue(e)
    // Minimum length of 2 chars required to search (or zero to clear search)
    if (e.length !== 1) {
      setFilter(e)
    }
    if (e !== '') {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
  }

  return (
    <>
      <TextField
        role="search"
        placeholder={placeholderText}
        variant="outlined"
        className="subvariant-search"
        onChange={(event) => handleChange(event.target.value)}
        value={value}
        InputProps={{
          'aria-describedby': 'search-instructions',
          endAdornment: showClear ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                onClick={() => handleChange('')}
                data-testid="Btn_Projects_SearchBoxClear">
                <CloseIconSmall fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        fullWidth
        data-testid={testId ?? ''}
      />
      <Box sx={{ fontSize: '0rem' }}>
        <span aria-hidden id="search-instructions">
          {t('SearchScreenReaderInstructions')}
        </span>

        <span aria-live="polite">
          {value} {t('Results')}
        </span>
      </Box>
    </>
  )
}

export default SearchBox
