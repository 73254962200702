import React, { SyntheticEvent } from 'react'
import {
  Autocomplete,
  Box,
  FormHelperText,
  styled,
  TextField,
} from '@mui/material'
import { AutoPayRecurrenceInterval } from '@rsmus/ecp-financeservice'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { ArrowDownIcon } from '../../../icons'
import { Styles } from '../../../../types'
import { tokens } from '../../../../styles/materialTheme'

interface AutoPayFrequencyProps {
  frequencyChange: (frequency: AutoPayRecurrenceInterval | undefined) => void
}

interface FrequencyOption {
  value: AutoPayRecurrenceInterval
  label: string
}

const styles: Styles = {
  SelectFrequencyContainer: (theme) => ({
    width: '29rem',
    marginTop: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
      paddingBottom: '0.05rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
      width: 'calc(50% - 4rem)',
    },
  }),
  FrequencyContainer: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      width: '100%',
    },
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

export const AutoPayFrequencyIntervals: FrequencyOption[] = [
  {
    value: AutoPayRecurrenceInterval.Daily,
    label: 'Invoicing.AutoPayPage.Daily',
  },
  {
    value: AutoPayRecurrenceInterval.Weekly,
    label: 'Invoicing.AutoPayPage.Weekly',
  },
  {
    value: AutoPayRecurrenceInterval.BiWeekly,
    label: 'Invoicing.AutoPayPage.BiWeekly',
  },
  {
    value: AutoPayRecurrenceInterval.Monthly,
    label: 'Invoicing.AutoPayPage.Monthly',
  },
]

const AutoPayFrequency = ({ frequencyChange }: AutoPayFrequencyProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Box sx={styles.FrequencyContainer}>
      <StyledLabel htmlFor="autoPayFrequency">
        {t('Invoicing.AutoPayPage.Frequency')}
        <span className="sr-only">{t('srOnlyRequired')}</span>
      </StyledLabel>
      <Controller
        name="autoPayFrequency"
        control={control}
        defaultValue=""
        rules={{ required: `${t('Invoicing.AutoPayPage.FrequencyRequired')}` }}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <>
            <Autocomplete
              popupIcon={<ArrowDownIcon />}
              id="autoPayFrequency"
              fullWidth
              value={value}
              onChange={(event: SyntheticEvent, newValue) => {
                const val: AutoPayRecurrenceInterval | null = newValue
                  ? (newValue.value as AutoPayRecurrenceInterval)
                  : null
                onChange(newValue)
                frequencyChange(val || undefined)
              }}
              options={AutoPayFrequencyIntervals}
              getOptionLabel={(frequency) =>
                frequency ? t(frequency.label) : ''
              }
              isOptionEqualToValue={(option, val) =>
                option?.value === val?.value
              }
              sx={styles.SelectFrequencyContainer}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  inputRef={ref}
                  error={!!error}
                  inputProps={{
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    ...params.inputProps,
                    'aria-describedby': error
                      ? 'frequencyErrorText'
                      : 'frequencyMessage',
                  }}
                  placeholder=""
                />
              )}
            />
            {error && (
              <FormHelperText id="frequencyErrorText" sx={styles.requiredError}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  )
}

export default AutoPayFrequency
