import { tokens } from '../../../styles/materialTheme'
import { sxStyles } from '../../../styles/sxStyles'

const useStyles = () =>
  sxStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1rem',
    },
    closeIcon: {
      position: 'absolute',
      zIndex: 1,
      top: '0.5rem',
      right: '0.5rem',
    },
    title: (theme) => ({
      padding: '1rem 0rem',
      fontStyle: 'normal',
      marginTop: '1.125rem',
      color: `${tokens.colors.rsmGray.copy}`,
      fontFamily: 'Prelo-Light, sans-serif',
      fontSize: '2.25rem',
      [theme.breakpoints.down('tablet')]: {
        fontSize: '1.875rem',
      },
    }),
    btnContainer: (theme) => ({
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.875rem',
      width: '100%',
      [theme.breakpoints.down('tablet')]: {
        flexWrap: 'wrap',
      },
    }),
    ecpWarning: {
      fontStyle: 'italic',
      fontSize: '1rem',
      color: tokens.colors.rsmPurple.secondary,
    },
  })

export default useStyles
