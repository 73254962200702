import { Box, IconButton, List, SwipeableDrawer } from '@mui/material'
import { OidcIdentityContext } from '@rsmus/react-auth'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import {
  isTargetedFeatureEnabled,
  isCemFeatureEnabled,
  isFeatureFlagEnabled,
} from '../../../rsmCoreComponents/utils/featureFlagUtils'

import { Styles } from '../../../types'
import { useAppSelector } from '../../../utils/hooks'
import { Size, useWindowSize } from '../../../utils/hooks/useWindowSize'
import { CloseIcon, MenuIcon } from '../../icons'

import {
  getUserInfo,
  getTargetedFeatureFlags,
  getCemFeatures,
} from '../../../store/userInfo/userInfoSlice'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
  CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
} from '../../../utils/constants/constants'
import RouterNavListItem from '../../Navigation/NavListItem/RouterNavListItem'
import ButtonNavListItem from '../../Navigation/NavListItem/ButtonNavListItem'

const styles: Styles = {
  swipeableDrawer: {
    zIndex: 3000,
  },
  iconContainer: () => ({
    width: '100%',
    textAlign: 'right',
    padding: '1.25rem',
  }),
  userInfo: (theme) => ({
    width: '100%',
    alignItems: 'center',
    padding: '1rem 1.75rem',
    color: '#fff',
    textAlign: 'left',
    verticalAlign: 'center',
    bgcolor: theme.palette.primary.main,
  }),
  list: {
    border: 'none',
    'li.MuiListItem-root': {
      'a, button': {
        flex: 1,
        borderBottom: '0.0625rem solid #ccc',
        paddingX: '1.75rem',
        minHeight: '4rem',
      },
    },
  },
}

type HamburgerMenuProps = {
  currentItem?: string
}

const HamburgerMenu = ({ currentItem = '' }: HamburgerMenuProps) => {
  const { t } = useTranslation()
  const userInfo = useAppSelector(getUserInfo)
  const cemFeatures = useAppSelector(getCemFeatures)
  const targetedFeatureFlags = useAppSelector(getTargetedFeatureFlags)
  const size: Size = useWindowSize()
  const { isMobile } = useDeviceType()
  const [open, setOpen] = useState<boolean>(false)

  const invoicingEnabled = useMemo(
    () =>
      isCemFeatureEnabled(
        [
          CEM_FEATURE_PAYMENT_PAY_INVOICES,
          CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
          CEM_FEATURE_PAYMENT_CLIENT_ADMIN,
        ],
        cemFeatures,
        'any',
      ),
    [userInfo, cemFeatures],
  )

  const engagementsEnabled = isTargetedFeatureEnabled(
    'Targeting_Engagement',
    targetedFeatureFlags,
  )

  const isEmployee = useAppSelector(
    (state) => state.userInfo.UserInfo.isEmployee,
  )

  // Used for logout() and redirect
  const context = useContext(OidcIdentityContext)
  const handleLogoutClick = () => {
    context.logout(false)
  }

  const fullName = `${userInfo?.firstName ?? ''}${
    userInfo?.firstName && userInfo?.lastName ? ' ' : ''
  }${userInfo?.lastName ?? ''}`

  return (
    <nav>
      <IconButton
        data-testid="Btn_Header_HamburgerMenuOpen"
        size="large"
        aria-label={t('Navigation.OpenMainNavigationMenu')}
        tabIndex={0}
        onClick={() => setOpen(true)}
        color="inherit">
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        sx={styles.swipeableDrawer}
        PaperProps={{ sx: { width: size.menuWidth } }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => {}}>
        <Box sx={styles.iconContainer}>
          <IconButton
            data-testid="Btn_Header_HamburgerMenuClose"
            aria-label={t('Navigation.CloseMainNavigationMenu')}
            tabIndex={0}
            size="large"
            onClick={() => setOpen(false)}
            color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={styles.userInfo}
          className="font-prelo-bold"
          data-testid="Lbl_Header_WelcomeFull_HamburgerMenu">
          <span className="font-prelo-book">
            {t('Navigation.Welcome')}
            {fullName ? ', ' : null}
          </span>
          <span data-testid="Lbl_Header_WelcomeName_HamburgerMenu">
            {fullName}
          </span>
        </Box>

        <Box component="nav" aria-label={t('Navigation.Main')}>
          <List sx={styles.list}>
            <RouterNavListItem
              to="/"
              value="home"
              isCurrent={currentItem === 'home'}
              testId="Lnk_Navigation_Home">
              {t('Navigation.Home')}
            </RouterNavListItem>

            {engagementsEnabled && (
              <RouterNavListItem
                to="/engagements"
                value="engagements"
                isCurrent={currentItem === 'engagements'}
                testId="Lnk_Navigation_Engagements">
                {t('Navigation.Engagements')}
              </RouterNavListItem>
            )}

            <RouterNavListItem
              to="/insights"
              value="insights"
              isCurrent={currentItem === 'insights'}
              testId="Lnk_Navigation_Insights">
              {t('Navigation.Insights')}
            </RouterNavListItem>

            {isFeatureFlagEnabled('Invoicing') && invoicingEnabled && (
              <RouterNavListItem
                to="/invoicing"
                value="invoicing"
                isCurrent={currentItem === 'invoicing'}
                testId="Lnk_Navigation_Invoicing">
                {t('Navigation.PayMyBill')}
              </RouterNavListItem>
            )}

            <RouterNavListItem
              to="/support"
              value="support"
              isCurrent={currentItem === 'support'}
              testId="Lnk_Navigation_Support">
              {t('Navigation.Support')}
            </RouterNavListItem>

            {isFeatureFlagEnabled('UserProfile') && isMobile && (
              <RouterNavListItem
                to="/profile"
                value="profile"
                isCurrent={currentItem === 'profile'}
                testId="Lnk_Navigation_Profile">
                {t('Navigation.Profile')}
              </RouterNavListItem>
            )}

            {!isEmployee && isMobile && (
              <ButtonNavListItem
                testId="Lnk_Navigation_Logout"
                onClick={handleLogoutClick}>
                {t('Navigation.Logout')}
              </ButtonNavListItem>
            )}
          </List>
        </Box>
      </SwipeableDrawer>
    </nav>
  )
}

export default HamburgerMenu
