interface FileResponse {
  data: Blob
  status: number
  fileName?: string
  headers?: {
    [name: string]: any
  }
}

const downloadFileinWindowStream = (response: FileResponse) => {
  if (response.data) {
    const fileName = response.fileName ?? 'untitled.pdf'
    const url = URL.createObjectURL(response.data)
    const newTab = window.open(url, '_blank')
    if (newTab) {
      newTab.onload = () => {
        URL.revokeObjectURL(url)
      }
      newTab.onbeforeunload = () => {
        URL.revokeObjectURL(url)
      }
    } else {
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = fileName
      document.body.appendChild(downloadLink)
      downloadLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      )
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(url)
    }
  }
}

export default downloadFileinWindowStream
