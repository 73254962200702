import React from 'react'

const DataCloudIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.86 38.8698C26.86 39.7998 26.11 40.5498 25.18 40.5498C24.25 40.5498 23.5 39.7998 23.5 38.8698V37.1898C23.5 36.2598 24.25 35.5098 25.18 35.5098C26.11 35.5098 26.86 36.2598 26.86 37.1898V38.8698Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M29.0996 35.5098V40.5498"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.6998 38.8698C34.6998 39.7998 33.9498 40.5498 33.0198 40.5498C32.0898 40.5498 31.3398 39.7998 31.3398 38.8698V37.1898C31.3398 36.2598 32.0898 35.5098 33.0198 35.5098C33.9498 35.5098 34.6998 36.2598 34.6998 37.1898V38.8698Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M36.9492 35.5098V39.0498"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.1895 39.7398V37.1898C39.1895 36.2598 39.9395 35.5098 40.8695 35.5098C41.7995 35.5098 42.5495 36.2598 42.5495 37.1898V38.8698C42.5495 39.4098 42.2995 39.8898 41.8995 40.1998"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M50.3991 38.8698C50.3991 39.7998 49.6491 40.5498 48.7191 40.5498C47.7891 40.5498 47.0391 39.7998 47.0391 38.8698V37.1898C47.0391 36.2598 47.7891 35.5098 48.7191 35.5098C49.6491 35.5098 50.3991 36.2598 50.3991 37.1898V38.8698Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M44.7891 35.5098V40.5498"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.6406 35.5098V40.5498"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.2409 38.8698C58.2409 39.7998 57.4909 40.5498 56.5609 40.5498C55.6309 40.5498 54.8809 39.7998 54.8809 38.8698V37.1898C54.8809 36.2598 55.6309 35.5098 56.5609 35.5098C57.4909 35.5098 58.2409 36.2598 58.2409 37.1898V38.8698Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M60.4805 35.5098V40.5498"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66.0905 38.8698C66.0905 39.7998 65.3405 40.5498 64.4105 40.5498C63.4805 40.5498 62.7305 39.7998 62.7305 38.8698V37.1898C62.7305 36.2598 63.4805 35.5098 64.4105 35.5098C65.3405 35.5098 66.0905 36.2598 66.0905 37.1898V38.8698Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M73.9303 38.8698C73.9303 39.7998 73.1803 40.5498 72.2503 40.5498C71.3203 40.5498 70.5703 39.7998 70.5703 38.8698V37.1898C70.5703 36.2598 71.3203 35.5098 72.2503 35.5098C73.1803 35.5098 73.9303 36.2598 73.9303 37.1898V38.8698Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M68.3301 35.5098V40.5498"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.9303 31.61C22.9303 32.54 22.1803 33.29 21.2503 33.29C20.3203 33.29 19.5703 32.54 19.5703 31.61V29.93C19.5703 29 20.3203 28.25 21.2503 28.25C22.1803 28.25 22.9303 29 22.9303 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M25.1699 28.25V33.29"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.7799 31.61C30.7799 32.54 30.0299 33.29 29.0999 33.29C28.1699 33.29 27.4199 32.54 27.4199 31.61V29.93C27.4199 29 28.1699 28.25 29.0999 28.25C30.0299 28.25 30.7799 29 30.7799 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M33.0195 28.25V33.29"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.6198 31.61C38.6198 32.54 37.8698 33.29 36.9398 33.29C36.0098 33.29 35.2598 32.54 35.2598 31.61V29.93C35.2598 29 36.0098 28.25 36.9398 28.25C37.8698 28.25 38.6198 29 38.6198 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M46.4694 31.61C46.4694 32.54 45.7194 33.29 44.7894 33.29C43.8594 33.29 43.1094 32.54 43.1094 31.61V29.93C43.1094 29 43.8594 28.25 44.7894 28.25C45.7194 28.25 46.4694 29 46.4694 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M40.8594 28.25V33.29"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.7109 28.25V33.29"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M54.3112 31.61C54.3112 32.54 53.5612 33.29 52.6312 33.29C51.7012 33.29 50.9512 32.54 50.9512 31.61V29.93C50.9512 29 51.7012 28.25 52.6312 28.25C53.5612 28.25 54.3112 29 54.3112 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M56.5508 28.25V33.29"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62.1608 31.61C62.1608 32.54 61.4108 33.29 60.4808 33.29C59.5508 33.29 58.8008 32.54 58.8008 31.61V29.93C58.8008 29 59.5508 28.25 60.4808 28.25C61.4108 28.25 62.1608 29 62.1608 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M70.0006 31.61C70.0006 32.54 69.2506 33.29 68.3206 33.29C67.3906 33.29 66.6406 32.54 66.6406 31.61V29.93C66.6406 29 67.3906 28.25 68.3206 28.25C69.2506 28.25 70.0006 29 70.0006 29.93V31.61Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M64.4004 28.25V33.29"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.1608 23.36C42.1608 24.29 41.4108 25.04 40.4808 25.04C39.5508 25.04 38.8008 24.29 38.8008 23.36V21.68C38.8008 20.75 39.5508 20 40.4808 20C41.4108 20 42.1608 20.75 42.1608 21.68V23.36Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M50.0006 23.36C50.0006 24.29 49.2506 25.04 48.3206 25.04C47.3906 25.04 46.6406 24.29 46.6406 23.36V21.68C46.6406 20.75 47.3906 20 48.3206 20C49.2506 20 50.0006 20.75 50.0006 21.68V23.36Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M44.4004 19.9902V25.0402"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.2402 19.9902V25.0402"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M57.8502 23.36C57.8502 24.29 57.1002 25.04 56.1702 25.04C55.2402 25.04 54.4902 24.29 54.4902 23.36V21.68C54.4902 20.75 55.2402 20 56.1702 20C57.1002 20 57.8502 20.75 57.8502 21.68V23.36Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M60.0898 19.9902V25.0402"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M65.6901 23.36C65.6901 24.29 64.9401 25.04 64.0101 25.04C63.0801 25.04 62.3301 24.29 62.3301 23.36V21.68C62.3301 20.75 63.0801 20 64.0101 20C64.9401 20 65.6901 20.75 65.6901 21.68V23.36Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M67.9316 19.9902V25.0402"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.6901 13.4297V15.1097C45.6901 16.0397 44.9401 16.7897 44.0101 16.7897C43.0801 16.7897 42.3301 16.0397 42.3301 15.1097"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M53.5397 15.11C53.5397 16.04 52.7897 16.79 51.8597 16.79C50.9297 16.79 50.1797 16.04 50.1797 15.11V13.43C50.1797 12.5 50.9297 11.75 51.8597 11.75C52.7897 11.75 53.5397 12.5 53.5397 13.43V15.11Z"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M47.9297 12.7402V16.7902"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.7793 11.7402V16.7902"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.3795 13.4293V15.1093C61.3795 16.0393 60.6295 16.7893 59.6995 16.7893C58.7695 16.7893 58.0195 16.0393 58.0195 15.1093V13.4293C58.0195 12.8393 58.3195 12.3293 58.7695 12.0293"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.6309 13V16.79"
      stroke="#009CDE"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.74062 43.4602C4.65062 43.4602 2.14062 41.0602 2.14062 38.1002C2.14062 35.1402 4.64062 32.7202 7.74062 32.7202C8.27062 32.7202 8.78063 32.7902 9.27063 32.9302C9.60063 27.7402 14.0906 23.6502 19.5706 23.6502C23.1006 23.6502 26.2206 25.3502 28.0806 27.9402C29.7706 26.4202 32.0106 25.4902 34.5006 25.4902C35.4606 25.4902 36.3906 25.6302 37.2706 25.8902C37.9806 17.5502 45.2506 10.9902 54.1306 10.9902C63.4606 10.9902 71.0406 18.2702 71.0406 27.2302C71.0406 27.5602 71.0306 27.8902 71.0106 28.2202C74.9506 28.6802 78.0006 31.9002 78.0006 35.8102C78.0006 40.0302 74.4306 43.4502 70.0306 43.4502"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M70.668 43.5898H69.168"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M66.0595 43.5898H48.9395"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M47.3887 43.5898H45.8887"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M25.3691 59.3906C25.6391 59.4306 25.9291 59.4506 26.2091 59.4506H26.8691"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.6895 59.4609H62.1195"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M63.5293 59.4609H65.0293"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.5494 43.9306C44.6894 41.3406 41.5694 39.6406 38.0394 39.6406C32.5594 39.6406 28.0794 43.7406 27.7394 48.9206C27.2494 48.7906 26.7294 48.7106 26.2094 48.7106C23.1094 48.7106 20.6094 51.1106 20.6094 54.0806C20.6094 56.7706 22.6794 59.0006 25.3694 59.3906"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M37.6094 54.4102C37.8794 54.4502 38.1694 54.4702 38.4494 54.4702H39.1094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.9297 54.4707H74.3597"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M75.7695 54.4707H77.2695"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M33.25 49.5898C33.52 49.6298 33.81 49.6498 34.09 49.6498H34.75"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M37.5605 49.6504H69.9905"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M71.4004 49.6504H72.9004"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default DataCloudIcon
