import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Theme } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import JsZip from 'jszip'
import { EntityLevelEnum } from '@rsmus/ecp-userservice'
import { Styles } from '../../../types'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { InvoiceData } from '../../../store/invoices/invoiceSearchService'
import {
  getSelectedInvoices,
  setSelectedInvoices,
  setPayingInvoices,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import streamSaver from '../../../utils/helpers/streamSaver'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import api from '../../../api'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'

const styles: Styles = {
  container: (theme) => ({
    position: 'sticky',
    zIndex: 1200,
    bottom: '0rem',
    left: '0rem',
    marginTop: '2rem',
    padding: '1rem',
    opacity: '92%',
    boxShadow: '0 0.25rem 0.5rem rgba(0,0,0, 0.5)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.only('tablet')]: {
      marginRight: '2rem',
    },
    [theme.breakpoints.down('tablet')]: {
      bottom: '0rem',
      marginRight: '-1rem',
      marginLeft: '-1rem',
    },
  }),
  content: (theme) => ({
    display: 'grid',
    marginLeft: '1rem',
    [theme.breakpoints.down('tablet')]: {
      height: 'fit-content',
      gap: '1.25rem',
      gridTemplateColumns: 'repeat(1, 1fr)',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  actionButtons: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    '.MuiButton-root': {
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      [theme.breakpoints.down('tablet')]: {
        paddingRight: '0.75rem',
        paddingLeft: '0.75rem',
      },
      '&:first-of-type': {
        marginLeft: '0rem',
      },
      '&:last-of-type': {
        marginRight: '0rem',
      },
    },
  }),
  payButton: (theme) => ({
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  }),
  clearButton: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textDecoration: 'underline',
    color: 'white',
  },
  invoicesSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

const InvoiceActionBar = () => {
  const { isDesktop } = useDeviceType()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedInvoices = useSelector(getSelectedInvoices)
  const cemFeatures = useSelector(getCemFeatures)
  const navigate = useNavigate()
  const [downloadStatus, setDownloadStatus] = useState<
    'nofailure' | 'notfound' | 'fail'
  >('nofailure')
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()

  const handleClose = useCallback(() => {
    setOpen(false)
    setDownloadStatus('nofailure')
  }, [setOpen])

  const deselectAllInvoices = () => {
    dispatch(setSelectedInvoices([]))
    const clearSelectionFocusTargetElement = document.querySelector(
      '#invoicesTable input',
    ) as HTMLElement
    clearSelectionFocusTargetElement?.focus()
  }

  useEffect(() => {
    deselectAllInvoices()
  }, [pathname])

  const handlePayInvoicesAction = (invoices: InvoiceData[]) => {
    dispatch(setPayingInvoices(invoices))
    navigate('/invoicing/invoices/pay-invoices')
  }

  const handleDownloadInvoicesAction = async (invoices: InvoiceData[]) => {
    let hasSuccess = false
    let hasNotFound = false
    let hasFailures = false

    if (invoices.length === 1) {
      const response = await api.finance
        .invoiceDocument_DownloadInvoice(invoices[0].id)
        .catch((e) => {
          if (e?.status === 404) {
            hasNotFound = true
          }
          hasFailures = true
          setOpen(true)
          return null
        })

      if (response) {
        if (response.data?.size > 0) {
          hasSuccess = true
          downloadFileStream(response)
        } else {
          if (response.status === 404) {
            hasNotFound = true
          } else {
            hasFailures = true
          }
          setOpen(true)
        }
      }
    } else {
      // get all filestream promises
      const downloadPromises = invoices.map((invoice) =>
        api.finance.invoiceDocument_DownloadInvoice(invoice.id),
      )
      // formats currentDate to yyyymmdd
      // (\d+) matches one or more digits followed by a slash followed by one or more digits
      const currentDate = new Date()
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3$1$2')

      const zip = JsZip()
      const zipFileName = `invoices-${currentDate}.zip`

      await Promise.allSettled(downloadPromises)
        .then((res) => {
          const fileStream = streamSaver.createWriteStream(zipFileName)

          res.forEach((promiseResult) => {
            if (promiseResult.status === 'fulfilled') {
              if (promiseResult.value.status === 404) {
                hasNotFound = true
                return
              }
              if (
                promiseResult.value.status !== 200 ||
                !(promiseResult.value.data?.size > 0)
              ) {
                hasFailures = true
                return
              }
              hasSuccess = true
              const fileName = promiseResult.value.fileName ?? 'untitled.pdf'
              // Add the download files to the zip file.
              zip.file(fileName, promiseResult.value.data)
            } else {
              if (promiseResult?.reason?.status === 404) {
                hasNotFound = true
              }
              hasFailures = true
            }
          })

          // If some of the files downloaded successfully, prompt the user to save the zip.
          if (hasSuccess) {
            // Prompt the user to save the zip.
            zip
              .generateAsync({ type: 'blob' })
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .then((zipFile: any) => zipFile.stream().pipeTo(fileStream))
          }
        })
        .catch((e: any) => {
          if (e?.status === 404) {
            hasNotFound = true
          }
          hasFailures = true
          setOpen(true)
        })
        .finally(() => {
          setOpen(true)
        })
    }

    if (hasNotFound) {
      setDownloadStatus('notfound')
    } else if (hasFailures) {
      setDownloadStatus('fail')
    }
  }

  const computedStyles = {
    downloadButton: (theme: Theme) =>
      selectedInvoices.every(
        (invoice) => invoice.status.toLowerCase() === 'open',
      )
        ? {}
        : {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
          },
  }

  return (
    <>
      <Box data-testid="errorAlert">
        {downloadStatus === 'notfound' && (
          <CustomErrorAlert
            testId="Banner_InvoicesActionBar_DownloadErrorMessageForNotFound"
            header="Invoicing.DownloadError"
            open={open}
            close={handleClose}
            message="Invoicing.DownloadErrorMessageForNotFound"
          />
        )}
        {downloadStatus === 'fail' && (
          <CustomErrorAlert
            testId="Banner_InvoicesActionBar_DownloadErrorMessage"
            header="Invoicing.DownloadError"
            open={open}
            close={handleClose}
            message="Invoicing.DownloadErrorMessage"
          />
        )}
      </Box>
      <Box
        hidden={selectedInvoices.length < 1}
        role="status"
        style={selectedInvoices.length < 1 ? visuallyHidden : undefined}
        sx={styles.container}>
        {selectedInvoices.length > 0 && (
          <Box sx={styles.content}>
            <Box sx={styles.invoicesSelected}>
              {isDesktop &&
                `${selectedInvoices.length} ${
                  selectedInvoices.length === 1
                    ? t('Invoicing.InvoiceSelected').toLowerCase()
                    : t('Invoicing.InvoicesSelected').toLowerCase()
                }\u00A0\u00A0|\u00A0\u00A0`}
              {`${
                isDesktop
                  ? t('Invoicing.AmountSelected')
                  : t('Invoicing.Amount')
              } ${formatCurrency(
                selectedInvoices
                  .map((row) => row.openAmount)
                  .reduce((accumulator, value) => accumulator + value, 0),
              )}\u00A0\u00A0\u00A0\u00A0`}
              <Button
                variant="text"
                sx={styles.clearButton}
                data-testid="Btn_InvoicesPage_ClearSelection"
                onClick={() => deselectAllInvoices()}>
                {`${
                  isDesktop
                    ? t('Invoicing.ClearSelection')
                    : t('Invoicing.Clear')
                }`}
              </Button>
            </Box>
            <Box sx={styles.actionButtons}>
              {isCemFeatureEnabled(
                [
                  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                  CEM_FEATURE_PAYMENT_PAY_INVOICES,
                ],
                cemFeatures,
                'any',
              ) && (
                <Button
                  sx={(theme) => computedStyles.downloadButton(theme)}
                  variant="outlined"
                  color="secondary"
                  data-testid="Btn_Invoices_Download"
                  onClick={() =>
                    handleDownloadInvoicesAction(selectedInvoices)
                  }>
                  {t('Invoicing.Download')}
                </Button>
              )}
              {selectedInvoices.every(
                (invoice) =>
                  // Invoice is open and either the user is assigned at the application level (internal super users)
                  // or user is assigned at the legal entity level and the invoice is for the same legal entity.
                  invoice.status.toLowerCase() !== 'closed' &&
                  (isCemFeatureEnabled(
                    CEM_FEATURE_PAYMENT_PAY_INVOICES,
                    cemFeatures,
                    'any',
                    EntityLevelEnum.Application,
                  ) ||
                    isCemFeatureEnabled(
                      CEM_FEATURE_PAYMENT_PAY_INVOICES,
                      cemFeatures,
                      'any',
                      EntityLevelEnum.LegalEntity,
                      invoice.customerNumber,
                    )),
              ) && (
                <Button
                  sx={styles.payButton}
                  variant="outlined"
                  color="secondary"
                  data-testid="Btn_Invoices_Pay"
                  onClick={() => handlePayInvoicesAction(selectedInvoices)}>
                  {t('Invoicing.Pay')}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default InvoiceActionBar
