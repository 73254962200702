/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ListItem, ListItemText, Checkbox } from '@mui/material'
import { styled } from '@mui/material/styles'
import { CheckboxIcon, CheckboxOutlinedIcon } from '../../icons'
import '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup.css'
import { setFilterValue } from '../../../store/invoices/invoiceSelectedFiltersSlice'

const FilterItem = styled(ListItem)(() => ({
  display: 'flex',
  alignItems: 'middle',
  padding: '0 0',
}))

interface CheckBoxFilterItemProps {
  filterCategory: string
  option: string
  isFilterChecked: (category: string, option: string) => boolean
  changeFilterAndSearch: (s: string) => void
  testId: string
}

const CheckBoxFilterItem = ({
  filterCategory,
  option,
  isFilterChecked,
  changeFilterAndSearch,
  testId,
}: CheckBoxFilterItemProps) => {
  const { t } = useTranslation()
  return (
    <FilterItem key={option}>
      <Checkbox
        id={`${filterCategory}_${option}`}
        icon={<CheckboxOutlinedIcon />}
        checkedIcon={<CheckboxIcon />}
        checked={isFilterChecked(filterCategory, option)}
        onChange={() =>
          changeFilterAndSearch(setFilterValue(filterCategory, option))
        }
        data-testid={testId}
      />
      <label htmlFor={`${filterCategory}_${option}`}>
        <ListItemText primary={t(`Invoicing.FilterCheckboxes.${option}`)} />
      </label>
    </FilterItem>
  )
}

export default CheckBoxFilterItem
