import * as React from 'react'
import { SVGProps } from 'react'

const PayTraceIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width}
    height={height}>
    <style>
      {
        '.st34{fill:#4596ca}.st41{fill:#928a84}.st42{fill:#f89d3b}.st53{fill:#4596ca;stroke:#4596ca;stroke-width:.17;stroke-miterlimit:10}'
      }
    </style>
    <title>PayTrace</title>
    <g id="content">
      <path
        d="M43.7 5.4C46 5.4 48 7.3 48 9.7v13.6h-2.2l5 8 4.8-8h-2.3V9.7C53.4 4.4 49 0 43.7 0H12C6.6 0 2.3 4.4 2.3 9.7V17h5.4V9.7c0-2.4 1.9-4.3 4.3-4.3h31.7"
        className="st34"
        style={{
          fill: '#4596ca',
        }}
      />
      <path
        d="M12 45.6c-2.3 0-4.3-1.9-4.3-4.3V27.8h2.2l-5-8-4.8 8h2.3v13.6c0 5.3 4.4 9.7 9.7 9.7h31.7c5.3 0 9.7-4.4 9.7-9.7V34H48v7.4c0 2.3-1.9 4.3-4.3 4.3L12 45.6"
        className="st42"
        style={{
          fill: '#f89d3b',
        }}
      />
      <path
        d="M27.8 38.2c-7.8 0-14.2-5.7-14.2-12.8h5.3c0 4.1 4 7.5 9 7.5s9-3.3 9-7.5h5.3c-.1 7.1-6.5 12.8-14.4 12.8z"
        className="st42"
        style={{
          fill: '#f89d3b',
        }}
      />
    </g>
  </svg>
)
export default PayTraceIcon
