import { useTrackEvent } from '@microsoft/applicationinsights-react-js'
import { useEffect } from 'react'
import { rcPlugin } from './appInsights'

export const useAITracking = (componentName: string, key?: string) => {
  useEffect(() => {
    const start: any = new Date()
    return () => {
      const end: any = new Date()
      const agg = (end - start) / 1000
      const metric = {
        average: agg,
        name: 'React Component (seconds)',
        sampleCount: 1,
      }
      const cName = {
        'Component Name': componentName,
        Navigation: key,
      }
      rcPlugin.trackMetric(metric, cName)
    }
  }, [componentName, key])
}

export const useAITrackClick = (eventName: string, eventData?: any) =>
  useTrackEvent(rcPlugin, eventName, eventData)

export default useAITracking
