import * as React from 'react'
import { SVGProps } from 'react'

const CalendarInCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
    viewBox="0 0 33 33"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}>
    <g data-name="Layer 1">
      <circle cx={16.5} cy={16.5} r={16.5} fill="#d9d9d9" />
      <path
        fill="#515356"
        d="M13.94 8h5.62V6.44c0-.51.39-.94.94-.94.51 0 .94.43.94.94V8H23c1.37 0 2.5 1.13 2.5 2.5V23c0 1.41-1.13 2.5-2.5 2.5H10.5C9.09 25.5 8 24.41 8 23V10.5C8 9.13 9.09 8 10.5 8h1.56V6.44c0-.51.39-.94.94-.94.51 0 .94.43.94.94V8ZM9.88 23c0 .35.27.62.62.62H23c.31 0 .62-.27.62-.62V13H9.87v10Z"
      />
    </g>
  </svg>
)
export default CalendarInCircleIcon
