import React from 'react'

const GearDigitizationIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.6993 55.4C28.6493 55.4 22.1193 48.88 22.1193 40.82C22.1193 32.76 28.6493 26.25 36.6993 26.25C37.1293 26.25 37.5493 26.27 37.9693 26.31V4H33.1193L31.9793 9.33C30.5493 9.54 29.1593 9.85 27.7993 10.25L24.1393 6.2L15.5293 11.17L17.0193 15.8C15.7593 16.8 14.5693 17.89 13.4693 19.06L8.8393 17.57L3.8693 26.19L7.0993 29.11C6.4593 30.73 5.9393 32.4 5.5693 34.14L1.2793 35.07V45.02L5.2693 45.87C5.5593 47.69 6.0093 49.47 6.5993 51.17L3.4793 53.99L8.4493 62.6L12.3593 61.34C13.5193 62.71 14.7993 64 16.1693 65.15L14.8393 69.28L23.4593 74.25L26.4293 70.95C28.0193 71.49 29.6493 71.92 31.3293 72.2L32.3193 76.84H37.9693V55.34C37.5493 55.38 37.1293 55.4 36.6993 55.4V55.4Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9791 4H42.1191V13.86H51.9791V4Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9791 19.9609H42.1191V29.8209H51.9791V19.9609Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9688 43.4498V33.5898H42.1087V43.4498H51.9688Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9688 58.8405V48.9805H42.1087V58.8405H51.9688Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9785 77.0104V67.1504H42.1185V77.0104H51.9785Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.8913 18.0508H69.0312V27.9108H78.8913V18.0508Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.8913 34.9805H69.0312V44.8405H78.8913V34.9805Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.8913 53.6797H69.0312V63.5397H78.8913V53.6797Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.4303 11.0898H55.5703V20.9498H65.4303V11.0898Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.4303 27.6895H55.5703V37.5495H65.4303V27.6895Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.4297 52.1295V42.2695H55.5697V52.1295H65.4297Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.4297 69.5104V59.6504H55.5697V69.5104H65.4297Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GearDigitizationIcon
