import {
  Box,
  IconButton,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  Paper,
  Theme,
} from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseIcon } from '../../../components/icons'
import { RsmLogo } from '../../../components/media'
import { Heading } from '../../../components/typography'
import { Styles } from '../../../types'
import { useDeviceType } from '../../hooks/useDeviceType'

const styles: Styles = {
  modal: (theme) => ({
    // Modal Dialog Root Node Styles
    zIndex: 3001,
    // Should have highest current z-index since it overlays everything else (including the hamburger menu).
    // Toast notifications might make sense to have at a higher z-index than the modal in the future.

    // Dialog Box Container Styles
    '& .RsmModal-dialogContainer': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      [theme.breakpoints.down('tablet')]: {
        position: 'fixed',
        top: '0rem',
        right: '0rem',
        bottom: 'env(safe-area-inset-bottom)',
        left: '0rem',
        margin: '0rem',
        width: '100%',
        height: 'calc(100% - env(safe-area-inset-bottom))',
      },
      [theme.breakpoints.only('tablet')]: {
        margin: '1.5rem',
        height: 'calc(100% - 3rem)',
      },
      [theme.breakpoints.up('desktop')]: {
        position: 'absolute',
        top: '1.5rem',
        right: '0rem',
        left: '0rem',
        margin: '0rem auto',
        width: '80%',
        maxWidth: '73.125rem',
        height: 'calc(100% - 3rem)',
        '@media screen and (min-height: 40em)': {
          top: '3rem',
          height: 'calc(100% - 6rem)',
        },
        '@media screen and (min-height: 50em)': {
          top: '5rem',
          height: 'calc(100% - 10rem)',
        },
        '@media screen and (min-height: 60em)': {
          top: '5rem',
          height: 'calc(100% - 12.5rem)',
        },
        '@media screen and (min-height: 70em)': {
          top: '5rem',
          height: 'calc(85% - 5rem)',
        },
      },
      '&:focus, &:focus-visible': {
        outline: '0rem',
      },
    },

    // Dialog Box Styles
    '& .RsmModal-dialog': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      position: 'relative',
      fontFamily: 'Prelo-Book, sans-serif',
    },

    // Dialog Header Styles
    '& .RsmModal-dialogHeader': {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      gap: '2rem',
      overflow: 'visible',
      position: 'relative',
      padding: '1rem',
    },
    '& .RsmModal-closeButtonContainer': {
      alignSelf: 'start',
      minHeight: '1.5rem',
      [theme.breakpoints.up('tablet')]: {
        minHeight: '2rem',
      },
    },
    '& .RsmModal-closeButton': {
      position: 'absolute',
      top: '0.25rem',
      right: '0.25rem',
      fontSize: '1.5rem',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) !important',
      '& svg': {
        zIndex: 1,
        margin: '0.25rem',
        '& path': {
          transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        },
      },
      [theme.breakpoints.up('tablet')]: {
        top: '0.5rem',
        right: '0.5rem',
      },
      '&:focus-visible': {
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
      },
      '&:active': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        '& svg path': {
          fill: theme.palette.text.primary,
        },
      },
    },
    '& .RsmModal-dialogTitle': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .RsmModal-dialogHeadingContainer': {
      '& h1': {
        [theme.breakpoints.up('tablet')]: {
          marginTop: '2rem',
          marginLeft: '3rem',
        },
        [theme.breakpoints.down('desktop')]: {
          fontSize: '1.875rem',
        },
      },
    },

    // Dialog Content Styles
    '& .RsmModal-dialogContent': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
      padding: '0 1rem 1rem',
      [theme.breakpoints.up('tablet')]: {
        padding: '0 4rem 2.5rem',
      },
    },
  }),
}

interface ResponsiveModalProps extends MuiModalProps {
  // Dialog Box
  desktopWidth?: number | string
  desktopHeight?: number | string
  desktopMinWidth?: number | string
  desktopMinHeight?: number | string
  desktopMaxWidth?: number | string
  desktopMaxHeight?: number | string

  // Header
  removeHeaderSpacing?: boolean
  removeCloseButton?: boolean
  hasRsmLogo?: boolean

  // Content
  removeContentSpacing?: boolean
  disableContentScrolling?: boolean

  showTimerSlot?: React.ReactNode

  // MUI Modal Prop Overrides
  onClose: () => void
}

const ResponsiveModal = ({
  // ---------------------------------------------------------------------------
  // Custom props to override default Dialog Box dimensions
  // ---------------------------------------------------------------------------

  desktopWidth = undefined,
  desktopHeight = undefined,
  desktopMinWidth = undefined,
  desktopMinHeight = undefined,
  desktopMaxWidth = undefined,
  desktopMaxHeight = undefined,

  // ---------------------------------------------------------------------------
  // Custom props to configure Dialog Header appearance/behavior
  // ---------------------------------------------------------------------------

  removeHeaderSpacing = false,
  // Removes interior spacing from dialog's header area. To completely hide the header:
  // - The removeHeaderSpacing and removeCloseButton props should both be truthy.
  // - The title and hasRsmLogo props should both be falsy.

  removeCloseButton = false,
  // Removes the close button from the dialog's header area.
  // - Enabling this will also prevent closing the modal when pressing ESC or clicking on the backdrop.

  hasRsmLogo = false,
  // Adds the RSM logo to the header.

  // ---------------------------------------------------------------------------
  // Custom props to configure Dialog Content appearance/behavior
  // ---------------------------------------------------------------------------

  removeContentSpacing = false,
  // Removes the default interior spacing from the dialog's content area.
  // - If enabled, define any custom interior spacing within the child components (instead of overriding the dialog's elements).

  disableContentScrolling = false,
  // Applies { overflow: hidden } to the dialog's content area.
  // Enable this when you don't want the dialog's entire content area to be scrollable.
  // - A common scenario for using this would be to implement a footer that's always locked to the bottom of the dialog.

  showTimerSlot = null,

  // ---------------------------------------------------------------------------
  // MUI Modal props
  // ---------------------------------------------------------------------------

  onClose,
  // This prop from MUI Modal is excluded from muiModalProps since this component
  // overrides its type/behavior before passing it through to MUI's Modal component.

  ...muiModalProps // Used to directly spread any other props (e.g., open, title, children) into the MUI Modal component.
}: ResponsiveModalProps) => {
  const paperRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()
  const { isMobile, isTablet } = useDeviceType()
  const dialogElevation = isMobile || isTablet ? undefined : 16

  const handleMuiModalClose = useCallback(() => {
    if (onClose && !removeCloseButton) {
      onClose()
    }
  }, [onClose])

  // a11y Requirement: Focused close icon should only look for enter and space key presses to trigger close.
  const handleCloseIconKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (onClose && (event.keyCode === 13 || event.keyCode === 32)) {
        event.preventDefault() // prevent re-opening the dial when enter pressed
        onClose()
      }
    },
    [onClose],
  )

  const computedStyles = {
    dialogContainer: useCallback(
      (theme: Theme) => ({
        '&.RsmModal-dialogContainer': {
          [theme.breakpoints.up('desktop')]: {
            width: desktopWidth,
            height: desktopHeight,
            minWidth: desktopMinWidth,
            minHeight: desktopMinHeight,
            maxWidth: desktopMaxWidth,
            maxHeight: `min(${desktopMaxHeight}, calc(100% - 3rem))`,
            ...(desktopMaxHeight
              ? {
                  '@media screen and (min-height: 40em)': {
                    height: desktopHeight,
                    maxHeight: `min(${desktopMaxHeight}, calc(100% - 6rem))`,
                  },
                  '@media screen and (min-height: 50em)': {
                    height: desktopHeight,
                    maxHeight: `min(${desktopMaxHeight}, calc(100% - 10rem))`,
                  },
                  '@media screen and (min-height: 60em)': {
                    height: desktopHeight,
                    maxHeight: `min(${desktopMaxHeight}, calc(100% - 12.5rem))`,
                  },
                  '@media screen and (min-height: 70em)': {
                    height: desktopHeight,
                    maxHeight: `min(${desktopMaxHeight}, calc(85% - 5rem))`,
                  },
                }
              : {}),
          },
        },
      }),
      [
        desktopHeight,
        desktopMaxHeight,
        desktopMaxWidth,
        desktopMinWidth,
        desktopWidth,
      ],
    ),
    dialogHeader: useCallback(
      () =>
        removeHeaderSpacing
          ? { '&.RsmModal-dialogHeader': { gap: '0rem', padding: '0rem' } }
          : undefined,
      [removeContentSpacing, disableContentScrolling],
    ),
    dialogContent: useCallback(
      (theme: Theme) => {
        const spacingStyles = removeContentSpacing
          ? {
              padding: '0rem',
              [theme.breakpoints.up('tablet')]: {
                padding: '0rem',
              },
            }
          : {}

        const scrollingStyles = disableContentScrolling
          ? { overflow: 'hidden' }
          : {}

        return {
          '&.RsmModal-dialogContent': {
            ...spacingStyles,
            ...scrollingStyles,
          },
        }
      },
      [removeContentSpacing, disableContentScrolling],
    ),
  }

  useEffect(() => {
    if (muiModalProps.open) {
      document.body.classList.add('RsmBody-RsmModal-open')
      document.body.style.overflow = 'hidden'
    } else {
      document.body.classList.remove('RsmBody-RsmModal-open')
      document.body.style.overflow = ''
    }
  }, [muiModalProps.open])

  useEffect(() => {
    const autofocusRef = removeCloseButton ? paperRef : closeButtonRef
    autofocusRef.current?.focus()
  }, [closeButtonRef, paperRef])

  return (
    <MuiModal
      role="dialog"
      sx={styles.modal}
      aria-describedby="RsmModal-title"
      data-testid="Dlg_Modal"
      BackdropProps={{ className: 'mui-fixed' }}
      onClose={() => handleMuiModalClose()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...muiModalProps}>
      <Paper
        ref={paperRef}
        className="RsmModal-dialogContainer"
        elevation={dialogElevation}
        sx={(theme) => computedStyles.dialogContainer(theme)}>
        <Box className="RsmModal-dialog" sx={styles.dialog}>
          <Box
            component="header"
            className="RsmModal-dialogHeader"
            sx={computedStyles.dialogHeader()}>
            {showTimerSlot}
            {!removeCloseButton && (
              <Box className="RsmModal-closeButtonContainer">
                <IconButton
                  ref={closeButtonRef}
                  className="RsmModal-closeButton"
                  tabIndex={0}
                  aria-label={t('ModalDialog.CloseDialog')}
                  data-testid="Btn_Modal_Close"
                  onClick={onClose}
                  onKeyDown={(event: React.KeyboardEvent) =>
                    handleCloseIconKeyDown(event)
                  }
                  disableFocusRipple
                  disableRipple>
                  <CloseIcon />
                </IconButton>
              </Box>
            )}

            <Box className="RsmModal-dialogTitle">
              {hasRsmLogo && (
                <Box
                  data-testid="Img_Modal_RsmLogo"
                  className="RsmModal-rsmLogo">
                  <RsmLogo />
                </Box>
              )}

              {muiModalProps.title && (
                <Box className="RsmModal-dialogHeadingContainer">
                  <Heading variant="h1WithH2Styling" id="RsmModal-title">
                    {muiModalProps.title}
                  </Heading>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            className="RsmModal-dialogContent Rsm-scrollBoundaries"
            sx={(theme) => computedStyles.dialogContent(theme)}>
            {muiModalProps.children}
          </Box>
        </Box>
      </Paper>
    </MuiModal>
  )
}

export default ResponsiveModal
