import Button from '@mui/material/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './RequestSummaryStyles.css'

import SwiperCore, { Pagination, Navigation, Keyboard, A11y } from 'swiper'
import { Box } from '@mui/material'
import PressIcon from '../../../icons/PressIcon'
import TimeIcon from '../../../icons/TimeIcon'
import WebPageIcon from '../../../icons/WebPageIcon'
import { useAppSelector } from '../../../../utils/hooks'
import { RequestSummaryProps } from '../../../Engagement/EngagementVisualizations/EngagementVisualizations.service'
import RequestSummaryWidget from './RequestSummaryWidget'
import { useDeviceType } from '../../../../rsmCoreComponents/hooks/useDeviceType'
import { isFeatureFlagEnabled } from '../../../../rsmCoreComponents/utils/featureFlagUtils'

import { Styles } from '../../../../types'

const styles: Styles = {
  roundedBox: (theme) => ({
    marginBottom: '2rem',
    textAlign: 'right',
    [theme.breakpoints.down('tablet')]: {
      textAlign: 'center',
    },
  }),
  viewAllRequestsButton: (theme) => ({
    marginTop: '1.125rem',
    marginBottom: '1.125rem',
    fontSize: '1.125rem',
    [theme.breakpoints.only('tablet')]: {
      width: 'inherit',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  requestsCarouselContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '1.5rem',
    color: theme.palette.text.primary,
  }),
}

// future story - redirect to page
const ViewAllRequestsBtnClick = () => {}

const RequestSummary = ({ all, userSubset }: RequestSummaryProps) => {
  const { t } = useTranslation()

  const [filterByUser, setFilterByUser] = useState(true)

  const dataset = filterByUser ? userSubset : all

  const isEmployee = useAppSelector(
    (state) => state.userInfo.UserInfo.isEmployee,
  )

  const slides = [
    {
      id: 1,
      pendingRequests: dataset.overdueRequests,
      totalRequests: dataset.total,
      requestPhrase: t('EngagementDashboard.Summary.OverdueRequestsPhrase'),
      iconType: <TimeIcon classNames="inline" />,
    },
    {
      id: 2,
      pendingRequests: dataset.priorityOutstandingRequests,
      totalRequests: dataset.total,
      requestPhrase: t('EngagementDashboard.Summary.OutstandingRequestsPhrase'),
      iconType: <PressIcon classNames="inline" />,
    },
    {
      id: 3,
      pendingRequests: dataset.followUpRequests,
      totalRequests: dataset.total,
      requestPhrase: t('EngagementDashboard.Summary.FollowUpRequestsPhrase'),
      iconType: <WebPageIcon classNames="inline" />,
    },
  ]

  const { isMobile } = useDeviceType()

  SwiperCore.use([Keyboard])

  return (
    <>
      <Box sx={{ fontFamily: 'Prelo-Book, sans-serif' }}>
        <Box role="tablist" sx={styles.roundedBox}>
          <Button
            role="tab"
            id={dataset.name}
            aria-selected={filterByUser === true}
            aria-controls="requests-carousel"
            onClick={() => setFilterByUser(true)}
            sx={(theme) => ({
              fontSize: '1rem',
              lineHeight: '1.5rem',
              marginRight: '1rem',
              fontWeight: filterByUser ? '700' : '500',
              color: filterByUser
                ? theme.palette.text.primary
                : theme.palette.secondary.main,
              textDecorationLine: filterByUser ? 'none' : 'underline',
            })}>
            {isEmployee ? (
              <span>{t('EngagementDashboard.Summary.CreatedByYou')}</span>
            ) : (
              <span>{t('EngagementDashboard.Summary.AssignedToYou')}</span>
            )}
          </Button>{' '}
          |{' '}
          <Button
            role="tab"
            id={dataset.name}
            aria-controls="requests-carousel"
            aria-selected={filterByUser === false}
            onClick={() => setFilterByUser(false)}
            type="button"
            sx={(theme) => ({
              fontSize: '1rem',
              marginleft: '1rem',
              fontWeight: filterByUser ? '500' : '700',
              color: filterByUser
                ? theme.palette.secondary.main
                : theme.palette.text.primary,
              textDecorationLine: filterByUser ? 'underline' : 'none',
            })}>
            {' '}
            {t('EngagementDashboard.Summary.AllRequests')}
          </Button>
        </Box>
      </Box>
      <Box sx={styles.requestsCarouselContainer}>
        {isMobile ? (
          <Swiper
            id="requests-carousel"
            role="tabpanel"
            aria-labelledby={dataset.name}
            modules={[Pagination, Navigation, A11y, Keyboard]}
            loop
            pagination
            navigation
            autoHeight
            keyboard={{ enabled: true }}
            a11y={{ enabled: true }}>
            {slides.map((slide) => (
              <SwiperSlide key={slide.id}>
                <RequestSummaryWidget
                  icon={slide.iconType}
                  pending={slide.pendingRequests}
                  total={slide.totalRequests}
                  phrase={slide.requestPhrase}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          slides.map((slide) => (
            <RequestSummaryWidget
              key={slide.id}
              icon={slide.iconType}
              pending={slide.pendingRequests}
              total={slide.totalRequests}
              phrase={slide.requestPhrase}
            />
          ))
        )}
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        {isFeatureFlagEnabled('DocumentRequest') && (
          <Button
            onClick={ViewAllRequestsBtnClick}
            variant="contained"
            color="primary"
            sx={styles.viewAllRequestsButton}>
            {t('EngagementDashboard.Summary.ViewAllRequests')}
          </Button>
        )}
      </Box>
    </>
  )
}

export default RequestSummary
