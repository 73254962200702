import React, { useMemo } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Styles } from '../../../../types'
import { getPartialAmount } from '../../../../store/invoices/invoiceSelectedInvoicesSlice'
import { getCemFeatures } from '../../../../store/userInfo/userInfoSlice'
import { isCemFeatureEnabled } from '../../../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_CREATE_INSTALLMENTS } from '../../../../utils/constants/constants'

const styles: Styles = {
  PaymentTypeSelection: (theme) => ({
    paddingTop: '3rem',
    [theme.breakpoints.only('mobile')]: {
      paddingTop: '1rem',
    },
  }),
  CategoryHeader: () => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    paddingBottom: '1.25rem',
  }),
  CategoryLegend: (theme) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    paddingBottom: '0.5rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.only('mobile')]: {
      paddingBottom: 0,
    },
  }),
  RadioButton: (theme) => ({
    [theme.breakpoints.only('mobile')]: {
      paddingTop: '0.3125rem',
      paddingBottom: '0.3125rem',
    },
  }),
}

interface PaymentCategoryProps {
  paymentCategoryChanged: (category: string) => void
}

const PaymentCategory = ({ paymentCategoryChanged }: PaymentCategoryProps) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const radiodisable = !!useSelector(getPartialAmount)
  const cemFeatures = useSelector(getCemFeatures)

  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_CREATE_INSTALLMENTS,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const paymentCategories = [
    t('Invoicing.PayNow'),
    t('Invoicing.SchedulePayment'),
    ...(isAccountManager ? [t('Invoicing.SetupInstallments')] : []),
  ]

  return (
    <Box sx={styles.PaymentTypeSelection}>
      <FormControl component="fieldset">
        <Box component="legend">
          <Box component="h2" sx={styles.CategoryHeader}>
            {t('Invoicing.PaymentInformationHeader')}
          </Box>
          <Box component="div" sx={styles.CategoryLegend}>
            <i>{t('Invoicing.whenWillYouPay')}</i>
          </Box>
        </Box>
        <Controller
          name="paymentCategory"
          control={control}
          defaultValue={paymentCategories[0]}
          render={({ field: { onChange, value, ref } }) => (
            <RadioGroup
              ref={ref}
              row
              value={value}
              onChange={(e) => {
                onChange(e)
                paymentCategoryChanged(e.target.value)
              }}
              defaultValue={paymentCategories[0]}>
              {paymentCategories.map((option: string) => (
                <FormControlLabel
                  key={option}
                  sx={{
                    marginRight: '3.5rem',
                    color: (theme) => theme.palette.grey[500],
                  }}
                  value={option}
                  label={option}
                  control={<Radio sx={styles.RadioButton} />}
                  disabled={radiodisable}
                  data-test-id={`Radio_Payment_${option.replace(/\s+/g, '')}`}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>
    </Box>
  )
}

export default PaymentCategory
