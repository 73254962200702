/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, PropsWithChildren } from 'react'
import { Link, LinkProps, styled, SvgIcon, Box } from '@mui/material'
import GreaterthanIcon from '../../icons/GreaterthanIcon'

const StyledLink = styled(Link)(({ theme, style }) => ({
  display: 'inline-block',
  [theme.breakpoints.only('mobile')]: {
    marginTop: '1rem', // 16px
  },
  [theme.breakpoints.only('tablet')]: {
    marginTop: '1.875rem', // 30px
  },
  [theme.breakpoints.only('desktop')]: {
    marginTop: '0.4375rem', // 7px
  },
  color: theme.palette.secondary.main,
  textDecoration: 'none',
  fontSize: '1.125rem',
  fontWeight: '500',
  fontFamily: 'Prelo-Book,sans-serif',
  height: '1.5rem',
  marginTop: '.625rem',
  marginBottom: '.625rem',
  ' svg': {
    marginTop: '.6rem',
    color: theme.palette.secondary.main,
    marginLeft: '.3rem',
  },
  ':focus-visible': {
    boxShadow: '0 0 0 0.125rem black !important',
  },
  ...style,
}))

const CaretLink = forwardRef<
  HTMLAnchorElement | null,
  PropsWithChildren<LinkProps>
>(({ children, ...rest }: PropsWithChildren<LinkProps>, ref) => (
  <StyledLink rel="noopener noreferrer" ref={ref} {...rest}>
    <Box
      data-testid={`${'PendoClickArea'}_${(rest as any)['data-testid']}`}
      data-analytic-id={`${'PendoClickArea'}_${
        (rest as any)['data-analytic-id']
      }`}
      data-analytic-event={(rest as any)['data-analytic-event']}>
      {children}
      <SvgIcon>
        <GreaterthanIcon />
      </SvgIcon>
    </Box>
  </StyledLink>
))

export default CaretLink
