import React from 'react'
import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../../types'

const styles: Styles = {
  AccountInformationSubLabel: (theme) => ({
    fontFamily: 'Prelo, sans-serif',
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: '300',
    color: theme.palette.text.primary,
    display: 'block',
  }),
}

const StyledHeading = styled('h3')(() => ({
  fontFamily: 'Prelo-Bold, sans-serif',
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  paddingBottom: '1.25rem',
}))

const AccountInfoSubText = () => {
  const { t } = useTranslation()
  return (
    <>
      <StyledHeading>{t('Invoicing.AccountInformation')}</StyledHeading>
      <Box sx={styles.AccountInformationSubLabel}>
        {t('Invoicing.AccountInformationSub')}
      </Box>
    </>
  )
}

export default AccountInfoSubText
