/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, PropsWithChildren } from 'react'
import { Link, LinkProps, styled, SvgIcon } from '@mui/material'
import GreaterthanIcon from '../../icons/GreaterthanIcon'

const StyledLink = styled(Link)(({ theme, style }) => ({
  fontFamily: 'Prelo-Book, sans-serif',
  fontSize: '1.6rem',
  color: theme.palette.common.white,
  textDecorationColor: 'rgba(0, 156, 222, 0)',
  '> svg': {
    marginTop: '.6rem',
    color: theme.palette.common.white,
    marginLeft: '.3rem',
  },
  ':focus-visible': {
    boxShadow: '0 0 0 0.125rem black !important',
  },
  ...style,
}))

const CaretLinkWhiteVariation = forwardRef<
  HTMLAnchorElement | null,
  PropsWithChildren<LinkProps>
>(({ children, ...rest }: PropsWithChildren<LinkProps>, ref) => (
  <StyledLink rel="noopener noreferrer" ref={ref} {...rest}>
    {children}
    <SvgIcon>
      <GreaterthanIcon />
    </SvgIcon>
  </StyledLink>
))

export default CaretLinkWhiteVariation
