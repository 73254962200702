import { AnalyticEvent, AdobeAnalyticsEventObject } from './types'

const dataAnalyticsId = 'Lnk_Navigation_Insights'
const analyticEvent: AnalyticEvent = 'Navigation_Click'

const processAdobeInsightsNavigationClick = (
  analyticsObject: AdobeAnalyticsEventObject,
) => {
  if (analyticsObject?.event_data_id === dataAnalyticsId) {
    window.adobeDataLayer?.push({
      event: analyticEvent,
      event_data_id: dataAnalyticsId,
    })
  }
}

export default processAdobeInsightsNavigationClick
