/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, styled } from '@mui/material'
import { useDebounce } from 'usehooks-ts'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import RsmPickersDay from '../../../rsmCoreComponents/components/DynamicForm/FormControls/FormDatePicker/RsmPickersDay'
import { Styles } from '../../../types'
import '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup.css'
import {
  getInvoiceSelectedFilters,
  InvoiceSelectedFiltersConstants,
  setFilterValue,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'
import useInvoicesSearch from './useInvoicesSearch'
import tokens from '../../../styles/tokens.json'
import { handleStartDateError, handleEndDateError } from './dateUtils'
import {
  getStartDateError,
  getEndDateError,
  minYear,
  maxYear,
} from './InvoicesDateRangeFilters.service'

const zIndex = 4000
const styles: Styles = {
  datePickerBox: {
    padding: '0.5rem 2.75rem 0.5rem 0.5625rem',
    '& div.MuiInputBase-root:focus-within': {
      boxShadow: 'revert !important',
    },
  },
  datePickerTextInput: {
    '& div.MuiOutlinedInput-input': {
      textTransform: 'uppercase',
    },
  },
  styledLink: () => ({
    color: 'primary',
  }),
  titleBox: {
    width: '100%',
    paddingRight: '2.75rem',
    justifyContent: 'space-between',
    display: 'flex',
  },
  error: {
    color: tokens.colors.rsmRed.secondary,
    marginLeft: '0.5rem',
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
  },
}

const DatePicker = styled(MuiDatePicker)(() => ({
  '& .MuiPaper-root': {},
}))

interface InvoiceFiltersDateRangeProps {
  filterCategory: string
  clearCategoryFilter: (s: string) => void
  changeFilterAndSearch: (s: string) => void
}

const InvoicesFiltersDateRange = ({
  filterCategory,
  clearCategoryFilter,
  changeFilterAndSearch,
}: InvoiceFiltersDateRangeProps) => {
  const { t } = useTranslation()
  const { changeFilterValue } = useInvoicesSearch()
  const closeOnSelect = true
  const showToolbar = false
  const unmountOnExit = false
  const startDateName = 'startdate'
  const endDateName = 'enddate'
  const CalendarIcon = React.memo(() => <FontAwesomeIcon icon={faCalendar} />)
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const [startDateError, setStartDateError] = useState<boolean>(false)
  const [endDateError, setEndDateError] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [startDateDebounceValue, setStartDateDebounce] = useState<Date | null>(
    null,
  )
  const [endDateDebounceValue, setEndDateDebounce] = useState<Date | null>(null)
  const startDateDebounce = useDebounce(startDateDebounceValue, 500)
  const endDateDebounce = useDebounce(endDateDebounceValue, 500)
  const localeOptions: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }

  const clearDatePickers = () => {
    setStartDate(null)
    setEndDate(null)
  }

  const handleOnChange = () => {
    if (
      selectedFilters.invoiceStartDate &&
      selectedFilters.invoiceEndDate &&
      selectedFilters.invoiceStartDate >= minYear &&
      selectedFilters.invoiceEndDate <= maxYear &&
      selectedFilters.invoiceStartDate <= selectedFilters.invoiceEndDate
    ) {
      const invoiceDateRange = `${selectedFilters.invoiceStartDate.toLocaleDateString(
        'en-US',
        localeOptions,
      )} - ${selectedFilters.invoiceEndDate.toLocaleDateString(
        'en-US',
        localeOptions,
      )}`
      changeFilterAndSearch(
        setFilterValue(
          InvoiceSelectedFiltersConstants.invoiceDates,
          invoiceDateRange,
        ),
      )
    }
  }

  const onDateChange = (filter: string, newDate: Date | null) => {
    const filterString = setFilterValue(filter, newDate)

    if (filter === InvoiceSelectedFiltersConstants.invoiceStartDate) {
      setStartDateDebounce(newDate)
      setStartDate(newDate)
    } else {
      setEndDateDebounce(newDate)
      setEndDate(newDate)
    }

    if (newDate === null) {
      changeFilterAndSearch(filterString)
    } else {
      changeFilterValue(filterString)
    }
  }

  useEffect(() => {
    if (startDateDebounce) {
      handleOnChange()
    }
  }, [startDateDebounce])

  useEffect(() => {
    if (endDateDebounce) {
      handleOnChange()
    }
  }, [endDateDebounce])

  useEffect(() => {
    if (selectedFilters.invoiceStartDate === null && startDate !== null) {
      setStartDate(null)
    } else if (
      selectedFilters.invoiceStartDate !== null &&
      startDate === null
    ) {
      setStartDate(selectedFilters.invoiceStartDate)
    }
  }, [selectedFilters.invoiceStartDate])

  useEffect(() => {
    if (selectedFilters.invoiceEndDate === null && endDate !== null) {
      setEndDate(null)
    } else if (selectedFilters.invoiceEndDate !== null && endDate === null) {
      setEndDate(selectedFilters.invoiceEndDate)
    }
  }, [selectedFilters.invoiceEndDate])

  useEffect(() => {
    setStartDateError(false)
    setEndDateError(false)
  }, [
    selectedFilters.invoiceEndDate,
    selectedFilters.invoiceStartDate,
    startDate,
    endDate,
  ])

  useEffect(() => {
    handleStartDateError(startDate, endDate, setStartDateError)
    handleEndDateError(startDate, endDate, setEndDateError)
  }, [startDate, endDate])

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      clearCategoryFilter={() => {
        clearDatePickers()
        clearCategoryFilter(filterCategory)
      }}
      unmountOnExit={unmountOnExit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <>
          <Box sx={styles.datePickerBox}>
            <DatePicker
              label={t('Invoicing.FilterGroups.startdate')}
              value={
                startDate !== null &&
                startDate !== selectedFilters.invoiceStartDate
                  ? startDate
                  : selectedFilters.invoiceStartDate
              }
              minDate={minYear} // January 1, 1950
              maxDate={endDate === null ? maxYear : endDate}
              format="MM/dd/yyyy"
              timezone="system"
              slots={{ day: RsmPickersDay, openPickerIcon: CalendarIcon }}
              slotProps={{
                textField: {
                  id: startDateName,
                  name: startDateName,
                  sx: styles.datePickerTextInput,
                  fullWidth: true,
                  autoComplete: 'off',
                  error: startDateError,
                  inputProps: {
                    'aria-describedby': `${startDateName}InputAlert`,
                    'data-testid': `${startDateName}-test-id`,
                  },
                },
                popper: {
                  placement: 'bottom',
                  style: { zIndex },
                  'aria-label': 'Calendar',
                },
                dialog: {
                  style: { zIndex },
                },
                actionBar: {
                  sx: { display: 'none' },
                },
                toolbar: {
                  hidden: !showToolbar,
                },
              }}
              onChange={(newDate) => {
                onDateChange(
                  InvoiceSelectedFiltersConstants.invoiceStartDate,
                  newDate,
                )
              }}
              onError={(reason) => {
                if (reason) {
                  setStartDateError(true)
                } else {
                  setStartDateError(false)
                }
              }}
              closeOnSelect={closeOnSelect}
            />
            {startDateError && (
              <Box
                id={`${startDateName}InputAlert`}
                component="span"
                sx={styles.error}
                role="alert">
                {getStartDateError(selectedFilters.invoiceStartDate)}
              </Box>
            )}
          </Box>
          <Box sx={styles.datePickerBox}>
            <DatePicker
              label={t('Invoicing.FilterGroups.enddate')}
              value={
                endDate !== null && endDate !== selectedFilters.invoiceEndDate
                  ? endDate
                  : selectedFilters.invoiceEndDate
              }
              minDate={startDate === null ? undefined : startDate}
              maxDate={maxYear}
              format="MM/dd/yyyy"
              timezone="system"
              slots={{ day: RsmPickersDay, openPickerIcon: CalendarIcon }}
              slotProps={{
                textField: {
                  id: endDateName,
                  name: endDateName,
                  sx: styles.datePickerTextInput,
                  fullWidth: true,
                  autoComplete: 'off',
                  error: endDateError,
                  inputProps: {
                    'aria-describedby': `${endDateName}InputAlert`,
                    'data-testid': `${endDateName}-test-id`,
                  },
                },
                popper: {
                  placement: 'bottom',
                  style: { zIndex },
                  'aria-label': 'Calendar',
                },
                dialog: {
                  style: { zIndex },
                },
                actionBar: {
                  sx: { display: 'none' },
                },
                toolbar: {
                  hidden: !showToolbar,
                },
              }}
              onChange={(newDate) => {
                onDateChange(
                  InvoiceSelectedFiltersConstants.invoiceEndDate,
                  newDate,
                )
              }}
              onError={(reason) => {
                if (reason) {
                  setEndDateError(true)
                } else {
                  setEndDateError(false)
                }
              }}
              closeOnSelect={closeOnSelect}
            />
            {endDateError && (
              <Box
                id={`${endDateName}InputAlert`}
                component="span"
                sx={styles.error}
                role="alert">
                {getEndDateError(selectedFilters.invoiceEndDate)}
              </Box>
            )}
          </Box>
        </>
      </LocalizationProvider>
    </InvoicesFilterCategoryContainer>
  )
}

export default InvoicesFiltersDateRange
