/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InvoicingMenu from '../../components/Invoicing/InvoicingMenu'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import DashboardLayout from '../../components/Invoicing/Dashboard/DashboardLayout'

const InvoicePreviewLayout = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = t('Invoicing.ECPInvoicingTitle')
  }, [])

  return (
    <>
      <MainMenu currentItem="invoicing" />
      <InvoicingMenu currentItem="dashboard" />
      <MainContent>
        <DashboardLayout />
      </MainContent>
    </>
  )
}

export default InvoicePreviewLayout
