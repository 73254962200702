import { Box, Divider, styled } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { PersonIcon } from '../../icons'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'

const styles: Styles = {
  container: {
    float: 'left',
    width: '100%',
  },
  divider: {
    borderColor: tokens.colors.rsmGray.dividers,
    marginBottom: '2rem',
  },
  title: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
  },
  contributorsContainer: (theme) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    fontSize: '1rem',
    gap: '2.5rem',
    marginTop: '1.5625rem',
    marginBottom: '3.5rem',
    [theme.breakpoints.only('tablet')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '6rem',
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
  }),
  contributor: {
    fontFamily: 'Prelo-Book, sans-serif',
    marginBottom: '-1rem',
  },
  contributorName: {
    fontFamily: 'Prelo-Bold, sans-serif',
  },
}

const Styled = {
  PersonIcon: styled(PersonIcon)(() => ({
    float: 'left',
    marginRight: '1.5rem',
    width: '5rem',
    height: '5rem',
  })),
}

interface ArticleContributorsProps {
  contributors?: string[]
}

const ArticleContributors = ({
  contributors = undefined,
}: ArticleContributorsProps) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={styles.container}
      data-test-id="contributors-section"
      data-testid="Txt_Insight_ArticleContributors">
      <Divider sx={styles.divider} aria-hidden="true" />
      <Box component="span" sx={styles.title}>
        {t('InsightArticle.RSMContributors')}
      </Box>
      <Box
        sx={styles.contributorsContainer}
        data-testid="Sec_Insight_ArticleContributors">
        {contributors?.map((name) => (
          <Box key={name} sx={styles.contributor}>
            <Styled.PersonIcon />
            <Box>
              <Box sx={styles.contributorName}>
                <OverFlowToolTip>{name}</OverFlowToolTip>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default ArticleContributors
