import React from 'react'

const IdeationIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.9995 56.1304C29.6495 53.0104 28.2095 50.1204 25.9595 47.8604C22.0595 43.9204 19.8395 38.3204 20.4195 32.2204C21.2795 23.1904 28.5695 15.7804 37.5795 14.7704C38.3195 14.6904 39.0395 14.6504 39.7395 14.6504H39.9095C40.6095 14.6504 41.3295 14.6904 42.0695 14.7704C51.0795 15.7804 58.3695 23.1904 59.2295 32.2204C59.8095 38.3204 57.5895 43.9204 53.6895 47.8604C51.4495 50.1204 49.9995 53.0104 49.6495 56.1304"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.8301 55.6V40"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.6293 32.1992L39.8293 39.9992L32.0293 32.1992"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.8301 1V6.85"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 12.4199L16.39 16.5599"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83008 40H7.68008"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.9805 40H77.8305"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.2695 16.5599L67.4095 12.4199"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.5309 59.4996C51.5309 60.5696 51.1009 61.5496 50.3809 62.2496C49.6809 62.9696 48.7009 63.3996 47.6309 63.3996H32.0309C29.8909 63.3996 28.1309 61.6396 28.1309 59.4996C28.1309 58.4296 28.5609 57.4496 29.2809 56.7496C29.9809 56.0296 30.9609 55.5996 32.0309 55.5996H47.6309C48.3309 55.5996 49.0109 55.7896 49.5809 56.1296C50.7509 56.8096 51.5309 58.0596 51.5309 59.4996V59.4996Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.9409 63.4004H29.7209C28.8427 63.4004 28.1309 64.1123 28.1309 64.9904V69.6104C28.1309 70.4885 28.8427 71.2004 29.7209 71.2004H49.9409C50.819 71.2004 51.5309 70.4885 51.5309 69.6104V64.9904C51.5309 64.1123 50.819 63.4004 49.9409 63.4004Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.0293 71.1992H47.6293V75.8292C47.6293 77.5792 46.2093 78.9992 44.4593 78.9992H35.1993C33.4493 78.9992 32.0293 77.5792 32.0293 75.8292V71.1992Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IdeationIcon
