import React from 'react'

const StrategyIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M78.5109 4H68.6309V13.88H78.5109V4Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M78.5109 36.4492H68.6309V46.3292H78.5109V36.4492Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M78.5109 67.9609H68.6309V77.8409H78.5109V67.9609Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M68.4705 9.49023H59.0605V72.9802H68.6305"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M68.6296 41.3105H40.0996"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.1191 17.3302L17.9891 9.49023C17.9891 9.49023 20.4991 14.3502 16.1091 13.8802C16.1091 13.8802 6.22907 22.6602 5.28907 26.8902C4.34907 31.1202 8.42907 32.8502 8.42907 32.8502C8.42907 32.8502 16.5791 27.6802 22.6991 33.7902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.9905 21.0898C17.9905 21.0898 15.0105 21.0898 14.2305 23.4398"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.05 30.9609C20.34 47.2609 9.68 52.7509 7.02 54.1609V62.9409C7.02 66.2309 2 66.8609 2 66.8609V72.9709H24.89"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.9895 62.1602H7.01953"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.9798 28.1406C37.1198 44.3806 44.0198 55.2606 44.0198 55.2606H27.0898C27.0898 55.2606 34.2998 43.5406 29.2798 28.1406"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30.1904 23.9093C29.2304 22.7393 28.6504 21.2293 28.6504 19.5993C28.6504 15.8293 31.7004 12.7793 35.4704 12.7793C39.2404 12.7793 42.2904 15.8293 42.2904 19.5993C42.2904 21.2393 41.7104 22.7393 40.7504 23.9093"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M47.1492 63.7305H23.9492V72.9805H47.1492V63.7305Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.1691 23.9102H28.9291C28.3437 23.9102 27.8691 24.3847 27.8691 24.9702V27.0802C27.8691 27.6656 28.3437 28.1402 28.9291 28.1402H42.1691C42.7546 28.1402 43.2291 27.6656 43.2291 27.0802V24.9702C43.2291 24.3847 42.7546 23.9102 42.1691 23.9102Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M45.0392 55.2598H26.0592C24.8939 55.2598 23.9492 56.2044 23.9492 57.3698V61.6198C23.9492 62.7851 24.8939 63.7298 26.0592 63.7298H45.0392C46.2045 63.7298 47.1492 62.7851 47.1492 61.6198V57.3698C47.1492 56.2044 46.2045 55.2598 45.0392 55.2598Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default StrategyIcon
