import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import EngagementMenu from '../../components/Engagement/EngagementMenu'
import EngagementMenuMobile from '../../components/Engagement/EngagementMenu/EngagementMenuMobile'
import EngagementVisualizations from '../../components/Engagement/EngagementVisualizations'
import TeamMembersWrapper from '../../components/Engagement/TeamMembers/TeamMembersWrapper'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import SectionHeader from '../../components/layouts/SectionHeader'
import MovedOrDeletedError from '../../components/MoveOrDeletedError/MovedOrDeletedError'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import { isFeatureFlagEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import {
  getActiveEngagement,
  getEngagements,
  setActiveEngagement,
} from '../../store/engagements/engagementSlice'
import { EngagementView } from '../EngagementList/Interfaces/EngagementViewTypes'
import { ActiveEngagement } from './Engagement.service'
import { subscribe } from '../../utils/helpers/PubSub.service'

const Engagement = () => {
  const unauthorizedEngagementTopic = 'unauthorizedEngagement'
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isMobile } = useDeviceType()
  const [isMissing, setIsMissing] = useState<boolean>(false)

  const reduxEngagementList: EngagementView[] = useSelector(getEngagements)
  const reduxActiveEngagementId: string =
    useSelector(getActiveEngagement)?.id || ''

  useAITracking(t('ECPEngagementsTitle'), window.location.href)

  useEffect(() => {
    document.title = t('ECPEngagementsTitle')
    const unsubscribe = subscribe(
      unauthorizedEngagementTopic,
      () =>
        new Promise((resolve) => {
          setIsMissing(true)
          resolve()
        }),
    )
    return () => unsubscribe()
  }, [])

  const updateActiveEngagement = (
    newEngagementId: string,
    activeEngagementId: string,
    engagementsList: EngagementView[],
  ) => {
    // If it's already been set, no need to update.
    if (newEngagementId === activeEngagementId) {
      return
    }

    const filteredEngagements: EngagementView[] = engagementsList.filter(
      (x) => x.id === newEngagementId,
    )

    const activeEngagement: ActiveEngagement = {
      id:
        filteredEngagements.length === 0
          ? newEngagementId
          : filteredEngagements[0].id,
      name: filteredEngagements.length === 0 ? '' : filteredEngagements[0].name,
    }

    dispatch(setActiveEngagement(activeEngagement))
  }

  const params = useParams()
  updateActiveEngagement(
    params.engagementId || '',
    reduxActiveEngagementId,
    reduxEngagementList,
  )

  return (
    <>
      <MainMenu currentItem="engagements" />

      {isMissing ? (
        <MainContent>
          <MovedOrDeletedError />
        </MainContent>
      ) : (
        <>
          {isMobile ? (
            <EngagementMenuMobile defaultSelected={t('Navigation.Dashboard')} />
          ) : (
            <EngagementMenu currentItem="dashboard" />
          )}

          <MainContent>
            {isFeatureFlagEnabled('DocumentRequest') && (
              <>
                <EngagementVisualizations engagementId={params.engagementId} />
                <div className="flex mt-[32px] bg-white">
                  <section className="w-full py-[40px] px-rsm-mobilePadding tablet:px-rsm-tabletPadding desktop:px-rsm-desktopPadding">
                    <SectionHeader
                      title={t('EngagementDashboard.DueDateCalendarHeader')}
                      testId="Lbl_Engagements_DueDateCalendarHeaderTitle"
                    />

                    <section className="w-1/2 py-[40px] pr-[24px] pl-rsm-mobilePadding tablet:pl-rsm-tabletPadding desktop:pl-rsm-desktopPadding">
                      {/* <Calendar Widget /> */}
                    </section>

                    <section className="w-1/2 ml-[32px] py-[40px] pl-[24px] pr-rsm-mobilePadding tablet:pr-rsm-tabletPadding desktop:pr-rsm-desktopPadding">
                      {/* <Due Date List/> */}
                    </section>
                  </section>
                </div>
              </>
            )}

            <div className="flex mt-[32px] bg-white mb-[-60px]">
              <section className="w-full py-[40px] px-rsm-mobilePadding tablet:px-rsm-tabletPadding desktop:px-rsm-desktopPadding">
                <SectionHeader
                  title={t('EngagementDashboard.TeamMembersHeader')}
                  testId="Lbl_Engagements_TeamMembersHeaderTitle"
                />
                <div className="text-[14px] leading-[24px] text-rsm-gray-copy tablet:font-prelo-light mobile:font-prelo-book mb-[32px]">
                  {t('EngagementDashboard.TeamMembersSubHeader')}
                </div>
                <TeamMembersWrapper engagementId={params.engagementId ?? ''} />
              </section>
            </div>
          </MainContent>
        </>
      )}
    </>
  )
}

export default Engagement
