import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

// Define a type for the slice state
interface DocRequestsState {
  documents: any[]
}

// Define the initial state using that type
const initialState: DocRequestsState = {
  documents: [],
}

export const docRequestsSlice = createSlice({
  name: 'docRequests',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDocuments: (state, { payload }) => {
      state.documents = payload
    },
  },
})

export const { setDocuments } = docRequestsSlice.actions
export const getDocuments = (state: RootState) => state.docRequests.documents

export default docRequestsSlice.reducer
