import { t } from 'i18next'
import Papa from 'papaparse'
import { EngagementViewTable } from '../Interfaces/EngagementViewTypes'
import { Actions, GenericObject } from './actions'
import { EngagementListState } from './context'

export function reducer(
  state: EngagementListState,
  action: Actions,
): EngagementListState {
  const produceCSV = (data: EngagementViewTable[]) => {
    const source = data.map((e) => {
      const engagement: GenericObject = {
        [t('Engagement.Name')]: e.name,
        [t('Engagement.StartDate')]: e.scheduledStartDateView,
        [t('Engagement.EndDate')]: e.scheduledEndDateView,
        [t('Engagement.Client')]: e.mdmClientName,
        [t('Engagement.LineOfBusiness')]: e.lineOfBusiness,
        [t('Engagement.Type')]: e.type,
      }
      return engagement
    })

    const fields = state.isEmployee
      ? [
          t('Engagement.Name'),
          t('Engagement.StartDate'),
          t('Engagement.EndDate'),
          t('Engagement.Client'),
          t('Engagement.LineOfBusiness'),
          t('Engagement.Type'),
        ]
      : [
          t('Engagement.Name'),
          t('Engagement.StartDate'),
          t('Engagement.EndDate'),
          t('Engagement.LineOfBusiness'),
          t('Engagement.Type'),
        ]

    return Papa.unparse({
      data: source,
      fields,
    })
  }

  const downloadEngagements = (data: string, fileName: string) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: 'csv' })
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }

  switch (action.type) {
    case 'PUSH_TO_DOWNLOAD_QUEUE': {
      const { engagements, fileName } = action.payload

      const downloadData = produceCSV(engagements)

      let updatedQueue = state.downloadQueue || {}

      updatedQueue = { ...updatedQueue, [fileName]: downloadData }

      return {
        ...state,
        downloadQueue: updatedQueue,
      }
    }

    case 'DOWNLOAD_FROM_QUEUE': {
      const { downloadQueue } = state

      const data = (downloadQueue || {})[action.payload]
      if (!data) {
        return { ...state }
      }

      downloadEngagements(data || '', action.payload)
      return {
        ...state,
      }
    }

    case 'IS_EMPLOYEE': {
      return {
        ...state,
        isEmployee: action.payload,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export default reducer
