/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const FallBack = () => (
  <Box
    sx={{
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    }}
    display="flex">
    <CircularProgress size={64} disableShrink thickness={3} />
  </Box>
)

// eslint-disable-next-line react/function-component-definition
const Loader = ({
  loading,
  children,
}: {
  loading: boolean
  children: React.ReactNode
  // eslint-disable-next-line react/jsx-no-useless-fragment
}) => (loading ? <FallBack /> : <>{children}</>)

export default Loader
