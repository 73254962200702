import React, { memo } from 'react'

const CheckCircleBig = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 62.5C10 34.9609 32.2656 12.5 60 12.5C87.5391 12.5 110 34.9609 110 62.5C110 90.2344 87.5391 112.5 60 112.5C32.2656 112.5 10 90.2344 10 62.5ZM82.4609 53.9062C84.6094 51.7578 84.6094 48.4375 82.4609 46.2891C80.3125 44.1406 76.9922 44.1406 74.8438 46.2891L53.75 67.3828L44.9609 58.7891C42.8125 56.6406 39.4922 56.6406 37.3438 58.7891C35.1953 60.9375 35.1953 64.2578 37.3438 66.4062L49.8438 78.9062C51.9922 81.0547 55.3125 81.0547 57.4609 78.9062L82.4609 53.9062Z"
      fill="#515356"
    />
  </svg>
)

export default memo(CheckCircleBig)
