/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DocumentModel } from '@rsmus/ecp-documentrequestservice'

// material ui components
import {
  FormControlLabel,
  Checkbox,
  IconButton,
  styled,
  useTheme,
} from '@mui/material'

import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// icons
import { t } from 'i18next'
import EllipsisIcon from '../../icons/EllipsisIcon/EllipsisIcon'
import RSMTableBody from '../../../rsmCoreComponents/components/table/RSMTableBody'
import {
  CheckboxIcon,
  CheckboxOutlinedIcon,
  GenericFileIcon,
} from '../../icons'
import {
  DocumentsViewTable,
  structureBodyData,
  structureHeaderData,
} from './DocTable.service'
import RsmTable from '../../../rsmCoreComponents/components/table/RSMTable'
import {
  getTableFilterState,
  setTableFilter,
} from '../../../store/document/documentSlice'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'

type TableProps = {
  documents: DocumentModel[]
  allSelected: boolean
  selected: { documentId: number; documentRequestId: number }[]
  onSelectAllChange: (allSelected: boolean) => void
  onSelectedChange: (
    selected: { documentId: number; documentRequestId: number }[],
    rowIds: { documentId: number; documentRequestId: number },
  ) => void
  onDeleteSingleFile: (document: DocumentModel | undefined) => Promise<void>
  onDeleteSelectedFiles: () => Promise<void>
  onDownloadSingleFile: (document: DocumentModel | undefined) => Promise<void>
  onDownloadSelectedFiles: () => Promise<void>
}

const StyledTableCell = styled(TableCell)({
  color: '#515356',
  fontSize: '16px',
  fontFamily: 'Prelo-Book, sans-serif',
  lineHeight: '24px',
  padding: '8px',
  borderBottomWidth: 0,
})

// BELOW is weird, in other places we are using mobile renderer but css tricks here.
// can be fixed as part of doc table mobile view story fixed column width story
const mobileStyles = {
  table: {
    tr: {
      th: {
        padding: '0px',
      },
      td: {
        padding: '0px',
      },
      'th:nth-of-type(3)': {
        width: '50%',
      },
      'th:nth-of-type(7),th:nth-of-type(6),th:nth-of-type(4),th:nth-of-type(5),th:nth-of-type(8),th:nth-of-type(9)':
        {
          display: 'none',
        },
      'td:nth-of-type(7),td:nth-of-type(6),td:nth-of-type(4),td:nth-of-type(5),td:nth-of-type(8),td:nth-of-type(9)':
        {
          display: 'none',
        },
      'td:nth-of-type(1)': {
        width: '20%',
      },
      'td:nth-of-type(2)': {
        width: '10%',
      },
      'td:nth-of-type(3)': {
        width: '60%',
      },
    },
  },
}

const StyledContainer = styled(TableContainer)(({ theme }) => ({
  div: {
    boxShadow: '0px 0px 0px 0px',
  },
  table: {
    tr: {
      th: {
        padding: '4px',
        whiteSpace: 'nowrap',
        fontSize: '14px',
      },
      td: {
        whiteSpace: 'nowrap',
        color: '#515356',
        fontSize: '16px',
        padding: '4px',
      },
    },
  },
  [`${theme.breakpoints.down('desktop')} and (orientation:landscape) `]: {
    ...mobileStyles,
  },
  '@media (max-width: 480px)': {
    ...mobileStyles,
  },
}))

const DocUploadTable = ({
  documents,
  allSelected,
  selected,
  onSelectAllChange,
  onSelectedChange,
  onDeleteSingleFile,
  onDeleteSelectedFiles,
  onDownloadSingleFile,
  onDownloadSelectedFiles,
}: TableProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [showEngagementSortHoverDocName, setShowEngagementSortHoverDocName] =
    useState<boolean>(false)
  const [
    showEngagementSortHoverCreatedBy,
    setShowEngagementSortHoverCreatedBy,
  ] = useState<boolean>(false)
  const [
    showEngagementSortHoverCreatedDate,
    setShowEngagementSortHoverCreatedDate,
  ] = useState<boolean>(false)
  const [
    showEngagementSortHoverGroupLevel1,
    setShowEngagementSortHoverGroupLevel1,
  ] = useState<boolean>(false)
  const [
    showEngagementSortHoverGroupLevel2,
    setShowEngagementSortHoverGroupLevel2,
  ] = useState<boolean>(false)
  const [showEngagementSortHoverStatus, setShowEngagementSortHoverStatus] =
    useState<boolean>(false)

  const [selectedDocument, setSelectedDocument] = useState<
    DocumentModel | undefined
  >(undefined)

  const dispatch = useDispatch()

  const tableFilterState = useSelector(getTableFilterState)

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
  ) => {
    setSelectedDocument(item)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSelectedDocument(undefined)
  }

  const handleSelectRow = (
    documentRequestId: number | undefined,
    documentId: number | undefined,
  ) => {
    if (documentId) {
      onSelectAllChange(false)
      const temp = [...selected]
      const idx = temp.findIndex((x) => x.documentId === documentId)
      if (idx > -1) {
        temp.splice(idx, 1)
      } else {
        temp.push({
          documentId,
          documentRequestId: documentRequestId || 0,
        })
      }

      onSelectedChange(temp, {
        documentId,
        documentRequestId: documentRequestId || 0,
      })
    }
  }

  return (
    <StyledContainer>
      {selected.length > 0 && (
        <div className="flex mt-[10PX]">
          <Button
            id="downloadSelectedFiles"
            variant="text"
            onClick={onDownloadSelectedFiles}>
            <label htmlFor="downloadSelectedFiles">
              Download {selected.length} Files
            </label>
          </Button>
          <Button
            id="deleteSelectedFiles"
            variant="text"
            onClick={onDeleteSelectedFiles}>
            <label htmlFor="deleteSelectedFiles">
              Delete {selected.length} Files
            </label>
          </Button>
        </div>
      )}
      <RsmTable>
        <RSMTableBody
          isAscending
          isCheckable
          allSelected={allSelected}
          onSelectAllChange={onSelectAllChange}
          tableSortConfig={tableFilterState}
          handleTableSort={(value: object) => {
            dispatch(setTableFilter(value))
          }}
          tableKey="date"
          checkboxStyles={{ width: '40px' }}
          itemList={structureBodyData(documents)}
          header={structureHeaderData(
            showEngagementSortHoverDocName,
            showEngagementSortHoverCreatedBy,
            showEngagementSortHoverCreatedDate,
            showEngagementSortHoverGroupLevel1,
            showEngagementSortHoverGroupLevel2,
            showEngagementSortHoverStatus,
            setShowEngagementSortHoverDocName,
            setShowEngagementSortHoverCreatedBy,
            setShowEngagementSortHoverCreatedDate,
            setShowEngagementSortHoverGroupLevel1,
            setShowEngagementSortHoverGroupLevel2,
            setShowEngagementSortHoverStatus,
            theme,
          )}
          customRenderers={{
            id: (it: DocumentsViewTable) => (
              <StyledTableCell align="right">
                <Button
                  aria-label="more options"
                  disableRipple
                  onClick={(e) => handleClick(e, it)}
                  aria-describedby={`${it.fileName}`}
                  sx={{ justifyContent: 'center' }}>
                  <EllipsisIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  sx={{
                    mt: '30px',
                    '& .MuiMenu-list': { width: '200px', border: 0 },
                  }}
                  anchorEl={anchorEl}
                  open={open && selectedDocument?.documentId === it.documentId}
                  onClose={handleClose}
                  keepMounted
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  MenuListProps={{ 'aria-labelledby': 'more options' }}>
                  <MenuItem
                    id={`menu-download-${it.id}`}
                    sx={{
                      borderTop: '1px solid #979797',
                      borderLeft: '1px solid #979797',
                      borderRight: '1px solid #979797',
                    }}
                    onClick={() =>
                      onDownloadSingleFile(
                        documents.find(
                          (e) => e.documentId === selectedDocument?.documentId,
                        ),
                      )
                    }>
                    <label htmlFor={`menu-download-${it.id}`}>
                      {t('Download')}
                    </label>
                  </MenuItem>
                  <MenuItem
                    id={`menu-delete-${it.id}`}
                    sx={{ border: '1px solid #979797' }}
                    onClick={() =>
                      onDeleteSingleFile(
                        documents.find(
                          (e) => e.documentId === selectedDocument?.documentId,
                        ),
                      )
                    }>
                    <label htmlFor={`menu-delete-${it.id}`}>
                      {t('Delete')}
                    </label>
                  </MenuItem>
                </Menu>
              </StyledTableCell>
            ),
            icon: (it: DocumentsViewTable) => (
              <StyledTableCell align="center">
                <IconButton title={`icon-${it.fileName}`} disableRipple>
                  <GenericFileIcon />
                </IconButton>
              </StyledTableCell>
            ),
            documentRequestName: (it: DocumentsViewTable) => (
              <StyledTableCell align="left">
                <Link to={`/requests/${it.id}`}>
                  <OverFlowToolTip overrideText="View details ">
                    {it.documentRequestName}
                  </OverFlowToolTip>
                </Link>
              </StyledTableCell>
            ),
            documentId: (it: DocumentsViewTable) => (
              <StyledTableCell align="left">
                <IconButton
                  disableRipple
                  title={`select-${it.fileName}`}
                  aria-describedby={`${it.fileName}`}
                  onClick={() => handleSelectRow(it.id, it.documentId)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`checkbox-${it.documentId}`}
                        icon={<CheckboxOutlinedIcon />}
                        checkedIcon={<CheckboxIcon />}
                        checked={
                          it.documentId
                            ? selected.findIndex(
                                (x) => x.documentId === it.documentId,
                              ) > -1
                            : false
                        }
                        aria-label="select document"
                        aria-describedby={`${it.fileName}`}
                      />
                    }
                    label=""
                    htmlFor={`checkbox-${it.documentId}`}
                    aria-label={`select-${it.fileName}`}
                  />
                </IconButton>
              </StyledTableCell>
            ),
            date: () => null,
          }}
        />
      </RsmTable>
      <div className="flex items-center w-full justify-between mt-8">
        <div>&nbsp;</div>
        <div>
          <Button
            variant="contained"
            className="h-[50px] px-6 py-3"
            aria-label={t('InsightsList.LoadMore')}>
            {t('InsightsList.LoadMore')}
          </Button>
        </div>
        <div>&nbsp;</div>
      </div>
    </StyledContainer>
  )
}

export default DocUploadTable
