import React from 'react'

const MagnifyingGlassIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.17948 76.1509L31.8995 52.9909C33.0895 51.8309 32.7695 49.5709 31.1795 47.9409C29.5895 46.3109 27.3395 45.9409 26.1495 47.1009L2.43948 70.2609C1.66948 71.0009 1.52948 72.2009 1.94948 73.3909C1.94948 73.3909 5.30948 78.9609 8.18948 76.1509H8.17948Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.3203 48.9404L37.4903 43.9004"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.9004 46.9609L35.3604 41.7109"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M69.6292 43.1798C60.3692 52.2198 45.5292 52.0398 36.4892 42.7798C27.4492 33.5198 27.6292 18.6798 36.8992 9.62983C46.1692 0.589829 60.9992 0.769829 70.0392 10.0398C79.0792 19.2998 78.8992 34.1398 69.6292 43.1798V43.1798Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66.2404 39.7106C58.8904 46.8806 47.1304 46.7306 39.9604 39.3906C32.7904 32.0506 32.9404 20.2806 40.2804 13.1106C47.6304 5.94058 59.4004 6.08059 66.5604 13.4306C73.7304 20.7706 73.5804 32.5406 66.2404 39.7106V39.7106Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default MagnifyingGlassIcon
