import { Button, DialogActions, DialogContent } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'

const styles: Styles = {
  dialogContent: {
    padding: '1rem',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 1rem 1.5rem',
    '& .MuiButton-root': {
      margin: '1rem 0rem 0rem',
      width: '100%',
    },
  },
}

interface SearchLayoutDialogProps {
  children: React.ReactNode
  isOpen: boolean
  title?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  onClickPrimaryButton: () => void
  onClickSecondaryButton?: () => void
  onClose: () => void
}

const SearchLayoutDialog = ({
  children,
  isOpen,
  title = undefined,
  primaryButtonText = undefined,
  secondaryButtonText = undefined,
  onClickPrimaryButton,
  onClickSecondaryButton = undefined,
  onClose,
}: SearchLayoutDialogProps) => {
  const { t } = useTranslation()

  return (
    <ResponsiveModal
      open={isOpen}
      onClose={onClose}
      title={title || t('SearchLayout.Filters')}
      disableContentScrolling
      removeContentSpacing>
      <>
        <DialogContent
          className="Rsm-scrollBoundaries"
          sx={styles.dialogContent}>
          {children}
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          {onClickSecondaryButton && (
            <Button
              variant="outlined"
              onClick={onClickSecondaryButton}
              data-testid="Btn_Dialog_Secondary_Action">
              {secondaryButtonText || t('SearchLayout.ClearAll')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={onClickPrimaryButton}
            data-testid="Btn_Dialog_Primary_Action">
            {primaryButtonText || t('SearchLayout.Apply')}
          </Button>
        </DialogActions>
      </>
    </ResponsiveModal>
  )
}

export default SearchLayoutDialog
