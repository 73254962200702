/* eslint-disable import/prefer-default-export */

import intlTemplateParser from './intlTemplateParser'

function parseString(
  inString: string | undefined,
  caseSensitive: boolean,
): string {
  if (!inString) return ''
  return caseSensitive === true
    ? inString.trim()
    : inString.trim().toLowerCase()
}

/**
 * @description - String comparer function with option for sorting and case sensitivity Default sort is Asc and Ignore case.
 */
export const stringComparer = (
  a: string | undefined,
  b: string | undefined,
  isDescending = false,
  caseSensitive = false,
): number => {
  const anew = parseString(a, caseSensitive)
  const bnew = parseString(b, caseSensitive)
  if (anew > bnew) return isDescending === true ? -1 : 1
  if (anew < bnew) return isDescending === true ? 1 : -1
  return 0
}

export const stringIntlTemplatParse = (val: string, params?: any) =>
  intlTemplateParser(val, JSON.parse(params || '{}'))
    .map((x) => x)
    .toString()
