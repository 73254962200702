import { useMemo } from 'react'

/**
 * @param {T} items - the array of items to filter.
 * @param {T[keyof T][][]} filterGroups - an array of arrays, the o the inner arrays contains the valueslist of properties in an item to filter on.
 * @description - A reusable function for returning filtered values from multiple properties of an array of objects.
 */
export default function filterGridValues<T>(
  items: T[],
  filters: Map<keyof T, Set<T[keyof T]>>,
  reactUseMemo: typeof useMemo,
) {
  return reactUseMemo(
    () =>
      items.filter((item) => {
        let isMatch = true

        Array.from(filters.entries()).every(([key, set]) => {
          const filterValues = Array.from(set.values())
          if (item[key] instanceof Date) {
            if (
              !filterValues
                .map((x) => (x as unknown as Date).getTime())
                .some((v) => v === (item[key] as unknown as Date).getTime())
            ) {
              isMatch = false
              return false
            }
          } else if (!filterValues.some((v) => v === item[key])) {
            isMatch = false
            return false
          }
          return true
        })
        return isMatch
      }),
    [items, filters],
  )
}
