import React from 'react'

const POTX = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="2" y="12" width="16" height="7" rx="0.8" fill="#E34E26" />
    <path
      d="M4.94673 15.3674H5.55537C5.84688 15.3674 5.99189 15.2231 5.99043 14.9346C5.99043 14.646 5.84541 14.5017 5.55537 14.5017H4.94673V15.3674ZM4.94673 15.9299V17H4.31611V13.9392H5.62129C5.93916 13.9392 6.18818 14.0293 6.36836 14.2095C6.54854 14.3896 6.63862 14.6321 6.63862 14.9368C6.63862 15.2415 6.54854 15.4832 6.36836 15.6619C6.18818 15.8406 5.93916 15.9299 5.62129 15.9299H4.94673ZM9.12051 14.6797C9.02529 14.5068 8.83926 14.4204 8.5624 14.4204C8.28701 14.4204 8.10098 14.5068 8.0043 14.6797C7.90908 14.8525 7.86147 15.1045 7.86147 15.4355C7.86001 15.7666 7.90176 16.0229 7.98672 16.2046C8.0834 16.4126 8.27529 16.5166 8.5624 16.5166C8.84951 16.5166 9.04141 16.4126 9.13809 16.2046C9.22305 16.0215 9.26553 15.7651 9.26553 15.4355C9.26553 15.1045 9.21719 14.8525 9.12051 14.6797ZM8.5624 17.0439C8.04385 17.0439 7.6813 16.8857 7.47476 16.5693C7.29165 16.291 7.2001 15.9167 7.2001 15.4465C7.2001 14.6877 7.43301 14.2095 7.89883 14.0117C8.08047 13.9341 8.30166 13.8953 8.5624 13.8953C9.08389 13.8967 9.44717 14.0483 9.65225 14.3501C9.83389 14.6211 9.92544 14.9866 9.9269 15.4465C9.9269 16.2214 9.69473 16.7129 9.23037 16.9209C9.0458 17.0029 8.82314 17.0439 8.5624 17.0439ZM11.8265 14.5017V17H11.1959V14.5017H10.3961V13.9392H12.6263V14.5017H11.8265ZM14.4073 15.9299H14.3985L13.7964 17H13.0933L14.0162 15.4114L13.1438 13.9392H13.8755L14.438 14.895H14.449L14.9917 13.9392H15.73L14.8533 15.4114L15.8113 17H15.0599L14.4073 15.9299Z"
      fill="white"
    />
  </svg>
)

export default POTX
