import React from 'react'

const RoboticArmIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131093)">
      <path
        d="M11.5792 62.8108C13.1698 62.8108 14.4592 61.5214 14.4592 59.9308C14.4592 58.3402 13.1698 57.0508 11.5792 57.0508C9.98864 57.0508 8.69922 58.3402 8.69922 59.9308C8.69922 61.5214 9.98864 62.8108 11.5792 62.8108Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M50.0695 62.8108C51.66 62.8108 52.9495 61.5214 52.9495 59.9308C52.9495 58.3402 51.66 57.0508 50.0695 57.0508C48.4789 57.0508 47.1895 58.3402 47.1895 59.9308C47.1895 61.5214 48.4789 62.8108 50.0695 62.8108Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M67.6691 62.8108C69.2596 62.8108 70.5491 61.5214 70.5491 59.9308C70.5491 58.3402 69.2596 57.0508 67.6691 57.0508C66.0785 57.0508 64.7891 58.3402 64.7891 59.9308C64.7891 61.5214 66.0785 62.8108 67.6691 62.8108Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M33.2491 21.92C37.3691 21.92 40.7091 18.58 40.7091 14.46C40.7091 10.34 37.3691 7 33.2491 7C29.129 7 25.7891 10.34 25.7891 14.46C25.7891 18.58 29.129 21.92 33.2491 21.92Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18.5691 32.0992C20.3365 32.0992 21.7691 30.6665 21.7691 28.8992C21.7691 27.1319 20.3365 25.6992 18.5691 25.6992C16.8018 25.6992 15.3691 27.1319 15.3691 28.8992C15.3691 30.6665 16.8018 32.0992 18.5691 32.0992Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M21.9102 29L33.9902 39.19C33.9902 39.19 35.4302 41.13 33.6502 42.61C31.8702 44.09 30.0602 42.31 30.0602 42.31L18.9102 31.94"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M47.3799 30.4903C48.8766 30.4903 50.0899 29.277 50.0899 27.7803C50.0899 26.2836 48.8766 25.0703 47.3799 25.0703C45.8832 25.0703 44.6699 26.2836 44.6699 27.7803C44.6699 29.277 45.8832 30.4903 47.3799 30.4903Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M58.5798 33.8402L56.7798 26.3302L49.8398 26.2402"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M45.7109 29.9102L46.1509 37.5402L53.1409 39.3702"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M45.4302 25.9605C47.3202 23.8705 47.1502 20.6105 45.0502 18.7205L40.7402 14.4805"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M33.8809 22.0996L38.1909 26.3396C40.2809 28.2296 43.5409 28.0596 45.4309 25.9596"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M23.8887 30.6705L33.0087 21.8105"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M14.3398 32.8203C16.0398 34.5703 18.7098 34.8403 20.7098 33.6003"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M25.8594 14.4609L14.4394 25.5709C12.4194 27.5409 12.3794 30.8009 14.3394 32.8209"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M29.6602 35.5305C30.2302 35.3905 30.9102 35.3105 31.7102 35.3105C37.1302 35.3105 37.1002 39.2105 37.1002 39.2105V52.4905"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M26.5605 52.4891V39.1191"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M40.7109 64.5602H74.3009C76.8909 64.5602 79.0009 62.4402 79.0009 59.8602C79.0009 57.2702 76.8809 55.1602 74.3009 55.1602H40.7109"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M22.9497 55.1602H5.62969C3.03969 55.1602 0.929688 57.2802 0.929688 59.8602C0.929688 62.4502 3.04969 64.5602 5.62969 64.5602H22.9497"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M40.7092 67.2202H22.9492V52.4902H26.5592H40.7092V67.2202Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131093">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default RoboticArmIcon
