import React from 'react'

const CertificateIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_132040)">
      <path d="M15.1406 2.07029H69.7506C69.7506 2.07029 75.3106 1.76029 77.4106 9.00029C78.8606 14.0003 77.1706 17.6903 77.1706 17.6903H63.9906" />
      <path
        d="M15.1406 2.07029H69.7506C69.7506 2.07029 75.3106 1.76029 77.4106 9.00029C78.8606 14.0003 77.1706 17.6903 77.1706 17.6903H63.9906"
        stroke="#009ADE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5098 67.6992C15.5298 65.5092 15.1298 62.9492 14.1198 59.9492C11.6598 52.6292 4.21984 39.5292 3.89984 23.1292C3.50984 2.87923 13.4598 1.99923 13.4598 1.99923H69.3898C69.3898 1.99923 62.5798 1.32923 63.5098 19.5792C64.4698 38.4392 72.4198 53.5692 74.2698 59.9392C79.7798 78.9292 69.3898 78.9292 69.3898 78.9292"
        stroke="#009ADE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.2594 51.4305C63.8294 52.7405 63.5594 54.0005 64.3994 55.6905C62.5994 56.2705 61.8994 57.3005 62.0494 59.1705C60.1794 59.0205 59.1494 59.7105 58.5694 61.5205C56.8694 60.6805 55.6194 60.9505 54.3094 62.3805C52.9994 60.9505 51.7394 60.6805 50.0494 61.5205C49.4694 59.7205 48.4394 59.0205 46.5694 59.1705C46.7194 57.3005 46.0294 56.2705 44.2194 55.6905C45.0594 53.9905 44.7894 52.7405 43.3594 51.4305C44.7894 50.1205 45.0594 48.8605 44.2194 47.1705C46.0194 46.5905 46.7194 45.5605 46.5694 43.6905C48.4394 43.8405 49.4694 43.1505 50.0494 41.3405C51.7494 42.1805 52.9994 41.9105 54.3094 40.4805C55.6194 41.9105 56.8794 42.1805 58.5694 41.3405C59.1494 43.1405 60.1794 43.8405 62.0494 43.6905C61.8994 45.5605 62.5894 46.5905 64.3994 47.1705C63.5594 48.8705 63.8294 50.1205 65.2594 51.4305Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.309 59.1199C58.3683 59.1199 61.659 55.8292 61.659 51.7699C61.659 47.7106 58.3683 44.4199 54.309 44.4199C50.2497 44.4199 46.959 47.7106 46.959 51.7699C46.959 55.8292 50.2497 59.1199 54.309 59.1199Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.3907 78.9392H9.24073C9.24073 78.9392 5.65073 79.7492 2.89073 75.5792C0.130728 71.4092 1.82073 67.6992 1.82073 67.6992H63.6107C63.6107 67.6992 61.8907 71.7592 63.8107 75.5092C65.7407 79.2592 69.3807 78.9392 69.3807 78.9392H69.3907Z"
        stroke="#009ADE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.9206 14.8496H15.1406"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.1895 21.7207H12.9395"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.8102 28.8203H14.6602"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.7798 35.9004H16.5098"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9608 43H17.5508"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.8792 49.4805H19.4492"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_132040">
        <rect width="80" height="80" />
      </clipPath>
    </defs>
  </svg>
)

export default CertificateIcon
