import React from 'react'

const ConstructionCraneIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0591 47.9998C18.0591 49.7998 16.5991 51.2598 14.7991 51.2598C12.9991 51.2598 11.5391 49.7998 11.5391 47.9998C11.5391 46.1998 12.9991 44.7398 14.7991 44.7398V40.0898"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.33 40.0893H13.27L10.75 32.5293H18.85L16.33 40.0893Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8008 9.53906V32.5291"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.9395 66.8299C39.9395 65.2199 41.2395 63.9199 42.8495 63.9199C44.4595 63.9199 45.7595 65.2199 45.7595 66.8299C45.7595 68.4399 44.4595 69.7399 42.8495 69.7399C41.2395 69.7399 39.9395 68.4399 39.9395 66.8299Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M52.1406 66.8299C52.1406 65.2199 53.4406 63.9199 55.0506 63.9199C56.6606 63.9199 57.9606 65.2199 57.9606 66.8299C57.9606 68.4399 56.6606 69.7399 55.0506 69.7399C53.4406 69.7399 52.1406 68.4399 52.1406 66.8299Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M64.3301 66.8299C64.3301 65.2199 65.6301 63.9199 67.2401 63.9199C68.8501 63.9199 70.1501 65.2199 70.1501 66.8299C70.1501 68.4399 68.8501 69.7399 67.2401 69.7399C65.6301 69.7399 64.3301 68.4399 64.3301 66.8299Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.1498 51.2695H2.83984V69.7495H23.1498V51.2695Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.3308 44.35L37.4908 52.48L14.8008 9.54L24.2408 4L51.3308 44.35Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M21.2793 21.8005L31.6193 15.7305"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.5996 35.9605L41.2396 29.3105"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M24.2393 4L21.2793 21.8"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M31.6196 15.7305L29.5996 35.9605"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.2402 29.3105L37.4902 52.4805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.8496 49.3302V59.6702H63.9496V52.4802H74.5296V26.7402H51.3296V44.3502"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M69.0499 32.0898H55.9199V45.2198H69.0499V32.0898Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M76.7695 59.6699H36.1895C35.4053 59.6699 34.7695 60.3057 34.7695 61.0899V72.5799C34.7695 73.3642 35.4053 73.9999 36.1895 73.9999H76.7695C77.5538 73.9999 78.1895 73.3642 78.1895 72.5799V61.0899C78.1895 60.3057 77.5538 59.6699 76.7695 59.6699Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default ConstructionCraneIcon
