import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { Heading } from '../../typography'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'

interface AutoPayDetailsProps {
  frequencey: string | undefined
  autoPayStartDate: string | undefined
  autoPayLimit: string | undefined
  paymentMethod: string | undefined
  payerName: string | undefined
  clientName: string | undefined
}

const styles: Styles = {
  DetailsContainer: (theme) => ({
    padding: '1.5rem',
    maxWidth: '28rem',
    [theme.breakpoints.only('mobile')]: {
      padding: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      maxWidth: '30rem',
    },
  }),
  details: (theme) => ({
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    maxWidth: '28rem',
    width: '28rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
    [theme.breakpoints.only('tablet')]: {
      width: '100%',
    },
  }),
}

const AutoPayDetails = ({
  frequencey = '',
  autoPayStartDate = '',
  autoPayLimit = '',
  paymentMethod = '',
  payerName = '',
  clientName = '',
}: AutoPayDetailsProps) => {
  const { t } = useTranslation()

  const details = [
    {
      label: t('Invoicing.AutoPayDetailsPage.Frequency'),
      value: frequencey,
    },
    {
      label: t('Invoicing.AutoPayDetailsPage.AutoPayStartDate'),
      value: autoPayStartDate,
    },
    {
      label: t('Invoicing.AutoPayDetailsPage.AutoPayLimit'),
      value: autoPayLimit,
    },
    {
      label: t('Invoicing.AutoPayDetailsPage.PaymentMethod'),
      value: paymentMethod,
    },
    {
      label: t('Invoicing.AutoPayDetailsPage.PayerName'),
      value: payerName,
    },
    {
      label: t('Invoicing.AutoPayDetailsPage.ClientName'),
      value: clientName,
    },
  ]

  return (
    <Box sx={styles.DetailsContainer}>
      <Heading variant="h2WithH3Styling" data-testid="Hed_AutoPay_Details">
        {t('Invoicing.AutoPayDetailsPage.Details')}
      </Heading>
      <Box component="ul" sx={styles.details}>
        {details.map(({ label, value }) => (
          <>
            <Box
              component="li"
              sx={{
                fontWeight: 'bold',
                height: '1.875rem',
                whiteSpace: 'nowrap',
              }}>
              <OverflowTooltip>{label}</OverflowTooltip>
            </Box>
            <Box
              component="li"
              sx={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
                height: '1.875rem',
              }}>
              <OverflowTooltip>{value}</OverflowTooltip>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  )
}

export default AutoPayDetails
