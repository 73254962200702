import { Box, Button, CircularProgress } from '@mui/material'
import { t } from 'i18next'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import Unavailable from '../../../pages/Unavailable'
import { FilterGroupExpandedStateDictionary } from '../../../rsmCoreComponents/components/FiltersGroup/FiltersGroup'
import { getAppliedTagFilters } from '../../../store/insights/insightsSlice'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import ArticleList from '../ArticleList'
import useCustomFetch from '../useInsightsSearch'

const styles: Styles = {
  container: () => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  loadingIndicatorContainer: () => ({
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    height: '2rem',
    marginTop: '2rem',
  }),
  loadMoreButtonContainer: () => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5rem',
  }),
}

interface InsightsResultsProps {
  filter: string
  pageNumber: number
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
  onLoadMore: () => void
}

const InsightsResults = ({
  filter,
  pageNumber,
  setPageNumber,
  onLoadMore,
}: InsightsResultsProps) => {
  const [filterGroupExpanded, setFilterGroupExpanded] =
    useState<FilterGroupExpandedStateDictionary>({})
  const [articleMetadata, setArticleMetaData] = useState<ArticleMetaData[]>([])
  const [fetchError, setFetchError] = useState<boolean>(false)
  const { pathname } = useLocation()
  const debouncedFilter = useDebounce<string>(filter, 500)
  const [focusIndex, setFocusIndex] = useState<number | undefined>(undefined)
  const filterDataState = useSelector(getAppliedTagFilters)

  const [isLoadMore, setIsLoadMore] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { fetchFilteredArticles, fetchFilteredArticlesPaged, loadFilters } =
    useCustomFetch(
      setIsLoading,
      setFetchError,
      setPageNumber,
      setIsLoadMore,
      setFocusIndex,
      setArticleMetaData,
      debouncedFilter,
      pageNumber,
      articleMetadata,
    )

  const renderUnavailable = useCallback(
    () => <Unavailable returnUrl={`${pathname}?key=${new Date().getTime()}`} />,
    [pathname, Unavailable],
  )

  const debounceFetchFilteredArticles = debounce(fetchFilteredArticles, 1000)
  const debounceFetchFilteredArticlesPaged = debounce(
    fetchFilteredArticlesPaged,
    1000,
  )

  useEffect(() => {
    if (pageNumber !== 1) {
      debounceFetchFilteredArticlesPaged()
    } else {
      debounceFetchFilteredArticles()
    }
  }, [pageNumber])

  useEffect(() => {
    setIsLoading(true)
    setPageNumber(1)
    debounceFetchFilteredArticles()
  }, [debouncedFilter])

  useEffect(() => {
    setIsLoading(true)
  }, [filter])

  useEffect(() => {
    setIsLoading(true)
    setPageNumber(1)
    debounceFetchFilteredArticles()
  }, [filterDataState])

  useEffect(() => {
    loadFilters(filterGroupExpanded, setFilterGroupExpanded)
  }, [])

  if (fetchError === true) {
    return renderUnavailable()
  }

  return (
    <Box sx={styles.container}>
      {isLoading ? (
        <Box sx={styles.loadingIndicatorContainer} aria-busy="true">
          <CircularProgress
            color="secondary"
            data-testid="Spn_Insights_Loading"
          />
        </Box>
      ) : (
        <ArticleList
          filter={filter}
          articleListMetaData={articleMetadata}
          focusIndex={focusIndex}
        />
      )}
      {isLoadMore && !isLoading && (
        <Box sx={styles.loadMoreButtonContainer}>
          <Button
            variant="contained"
            onClick={onLoadMore}
            aria-label={t('InsightsList.LoadMore')}
            data-testid="Btn_Insight_LoadMore">
            {t('InsightsList.LoadMore')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default InsightsResults
