import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, FormHelperText, Select, styled } from '@mui/material'
import { PaymentInstallmentFrequencyEnum } from '@rsmus/ecp-financeservice'
import { EntityLevelEnum } from '@rsmus/ecp-userservice'
import { isCemFeatureEnabled } from '../../../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS } from '../../../../utils/constants/constants'
import { getCemFeatures } from '../../../../store/userInfo/userInfoSlice'
import { ArrowDownIcon } from '../../../icons'
import { formatCurrency } from '../../../../rsmCoreComponents/utils/formatters'
import { Styles } from '../../../../types'
import { tokens } from '../../../../styles/materialTheme'

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledSelectField = styled(Select)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
  width: 'calc(100% - 4rem)',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}))

const styles: Styles = {
  InstallmentsOtherContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(50% - 4rem)',
      paddingLeft: 0,
    },
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
}

interface InstallmentPlanOtherProps {
  getPaymentAmounts: (installments: number) => { Amount: number }[]
}

const InstallmentPlanOther = ({
  getPaymentAmounts,
}: InstallmentPlanOtherProps) => {
  const { control, getValues, watch } = useFormContext()
  const cemFeatures = useSelector(getCemFeatures)
  const { t } = useTranslation()

  const maxInstallments = {
    [PaymentInstallmentFrequencyEnum.Weekly]: 24,
    [PaymentInstallmentFrequencyEnum.BiWeekly]: 12,
    [PaymentInstallmentFrequencyEnum.Monthly]: 6,
  }
  const maxInstallmentsExtended = {
    [PaymentInstallmentFrequencyEnum.Weekly]: 48,
    [PaymentInstallmentFrequencyEnum.BiWeekly]: 24,
    [PaymentInstallmentFrequencyEnum.Monthly]: 12,
  }

  const hasExtendedInstallments = useCallback(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS,
        cemFeatures,
        'any',
        EntityLevelEnum.Application,
      ),
    [cemFeatures],
  )

  const getOtherPaymentOptions = useCallback(() => {
    const frequency = getValues('frequency') as keyof typeof maxInstallments
    const max = hasExtendedInstallments()
      ? maxInstallmentsExtended[frequency]
      : maxInstallments[frequency]

    const options: { NumPayments: number; PaymentAmount: number }[] = []
    for (let i = 2; i <= max; i += 1) {
      options.push({
        NumPayments: i,
        PaymentAmount: getPaymentAmounts(i)[0]?.Amount,
      })
    }

    return options
  }, [getValues, hasExtendedInstallments])

  return (
    <Box sx={styles.InstallmentsOtherContainer}>
      <StyledLabel htmlFor="numberOfInstallmentsOther">
        {t('Invoicing.InstallmentPaymentOtherSelect')} *
        <span className="sr-only">{t('srOnlyRequired')}</span>
      </StyledLabel>
      <Controller
        name="numberOfInstallmentsOther"
        control={control}
        defaultValue=""
        rules={{
          required: `${t('Invoicing.SelectInstallmentPayment')}`,
        }}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <>
            <StyledSelectField
              native
              id="numberOfInstallmentsOther"
              value={value}
              inputRef={ref}
              IconComponent={ArrowDownIcon}
              onChange={onChange}
              error={!!error}
              disabled={!watch('frequency')}
              inputProps={{
                'aria-describedby': error
                  ? 'installmentsErrorText'
                  : 'installmentmessage',
              }}>
              <option
                key="numberOfInstallmentsOther_0"
                style={{ display: 'none' }}
                label=" "
              />
              {getOtherPaymentOptions()?.map(
                ({ NumPayments, PaymentAmount }) => (
                  <option
                    key={`numberOfInstallmentsOther_${NumPayments}`}
                    value={NumPayments}>
                    {t('Invoicing.InstallmentPaymentsUnformatted', {
                      num: NumPayments,
                      paymentAmount: formatCurrency(PaymentAmount),
                    })}
                  </option>
                ),
              )}
            </StyledSelectField>
            {error && (
              <FormHelperText
                id="installmentsErrorText"
                sx={styles.requiredError}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  )
}

export default InstallmentPlanOther
