import { ApiResponseOfConcurrentDictionaryOfStringAndIEnumerableOfString } from '@rsmus/ecp-cmsservice'
import api from '..'

const getInsightFilters =
  async (): Promise<ApiResponseOfConcurrentDictionaryOfStringAndIEnumerableOfString> => {
    try {
      const response = await api.cms.insights_FilterOptions()
      return response
    } catch (e) {
      const error: ApiResponseOfConcurrentDictionaryOfStringAndIEnumerableOfString =
        {
          statusCode: 500,
          message: 'call failed',
          data: undefined,
          error: undefined,
        }
      return error
    }
  }

export default getInsightFilters
