import React from 'react'

const CallUsIcon = () => (
  <svg
    width="78"
    height="95"
    viewBox="0 0 78 95"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.1787 83.0243V92.2234L12.2915 72.6505V63.4514C0.828856 50.3382 0.518272 34.5733 3.16086 27.4399C8.01701 14.3241 15.5921 6.29979 29.3709 2.10995C40.6888 -1.33209 54.6624 3.97682 61.8295 10.0203C70.181 17.0661 74.0659 24.8942 74.845 35.0718C75.6215 45.2494 73.2053 49.2457 73.2053 49.2457L74.9766 63.6476L66.6856 63.4514L64.1589 78.1318C62.9929 82.4383 61.4399 82.8281 58.1367 83.0243C54.8335 83.2206 43.1787 83.0243 43.1787 83.0243Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.998 43.0234C44.998 49.6503 39.6255 55.0234 32.9993 55.0234C26.3706 55.0234 20.998 49.6503 20.998 43.0234C20.998 36.3966 26.3706 31.0234 32.9993 31.0234C39.6255 31.0234 44.998 36.3966 44.998 43.0234Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path d="M26.9988 4.625V32.225" stroke="#009CDE" strokeWidth="2" />
    <path d="M34.198 2.22266V31.0227" stroke="#009CDE" strokeWidth="2" />
    <path d="M38.998 52.625L68.998 75.425" stroke="#009CDE" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.998 75.4242C68.998 77.4109 70.6086 79.0242 72.598 79.0242C74.5847 79.0242 76.198 77.4109 76.198 75.4242C76.198 73.4375 74.5847 71.8242 72.598 71.8242C70.6086 71.8242 68.998 73.4375 68.998 75.4242Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.7988 41.8242C37.7988 45.1395 35.1121 47.8242 31.8002 47.8242C28.4856 47.8242 25.7988 45.1395 25.7988 41.8242C25.7988 38.5089 28.4856 35.8242 31.8002 35.8242C35.1121 35.8242 37.7988 38.5089 37.7988 41.8242Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
  </svg>
)

export default CallUsIcon
