import { useMemo } from 'react'
import { sortArray } from './sortableData'

/**
 * @param {T} items - the array of items to get distinct values from.
 * @param {(keyof T)[]} properties - the list of properties in an item to get distinct values from.
 * @returns - A Map with the object property as the Map key and a Set of distinct values as the Map value.
 * @description - A reusable function for returning distinct values from multiple properties of an array of objects.
 */
export default function distinctGridValues<T>(
  items: T[] | undefined,
  properties: (keyof T)[],
  reactUseMemo: typeof useMemo,
) {
  return reactUseMemo(() => {
    const propMap = new Map<keyof T, Set<T[keyof T]>>()

    items?.forEach((item) => {
      properties.forEach((property) => {
        const distinctValues = propMap.get(property) ?? new Set<T[keyof T]>()
        distinctValues.add(item[property])
        propMap.set(property, distinctValues)
      })
    })

    properties.forEach((property) => {
      let sortablePropValues = [
        ...Array.from(propMap.get(property)?.values() ?? []),
      ]

      // Since Dates are reference types, we need one more step to make them unique.
      if (
        sortablePropValues.length > 0 &&
        sortablePropValues[0] instanceof Date
      ) {
        sortablePropValues = sortablePropValues.filter(
          (date, i, self) =>
            self.findIndex(
              (d) =>
                (d as unknown as Date).getTime() ===
                (date as unknown as Date).getTime(),
            ) === i,
        )
      }

      sortArray<T[keyof T]>(sortablePropValues, false)
      propMap.set(property, new Set(sortablePropValues))
    })

    return propMap
  }, [items, properties])
}
