import React from 'react'

const ClapperboardIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8808 30.7891L15.3008 40.6091"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.4691 30.7891L29.1191 40.4191"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.1803 30.7891L42.8203 40.4191"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.0199 30.7891L55.6699 40.4191"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.3306 30.4102H9.64062V78.0002H72.3306V30.4102Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M66.9152 2.01117L6.63867 19.2383L9.75214 30.1321L70.0287 12.905L66.9152 2.01117Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M72.7098 40.6094H9.25977"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M65.1605 14.1691L50.8105 6.61914"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M51.5609 17.8703L37.2109 10.3203"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M38.7191 21.6498L24.3691 14.0898"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M25.8793 25.4191L11.5293 17.8691"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M19.0801 52.6992H63.6501"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M18.3301 64.7793H62.8901"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClapperboardIcon
