/* eslint-disable */
import { createApi } from '@reduxjs/toolkit/query/react'
import { TaxonomyCategoryModel } from '@rsmus/ecp-cmsservice'
import { CMSSERVICE_BASE_URL } from '../../envVariables'
import { axiosBaseQuery, Response } from '../../api/BaseQuery'

const cmsService = createApi({
  reducerPath: 'cmsService',
  tagTypes: ['Interests', 'preferences'],
  baseQuery: axiosBaseQuery({
    baseURL: CMSSERVICE_BASE_URL,
  }),
  endpoints: (builder) => ({
    getIndustryList: builder.query<Response<TaxonomyCategoryModel[]>, void>({
      query: () => ({
        url: '/api/v1/Taxonomy/industries',
        method: 'get',
      }),
      providesTags: ['Interests'],
    }),
    getTaxonomyPreferences: builder.query<
      Response<TaxonomyCategoryModel[]>,
      void
    >({
      query: () => ({
        url: '/api/v1/Taxonomy/Preferences',
        method: 'get',
      }),
      providesTags: ['preferences'],
    }),
    setTaxonomyPreferences: builder.mutation<Response<boolean>, number[]>({
      query: (data) => ({
        url: '/api/v1/Taxonomy/Preferences',
        data,
        method: 'put',
      }),
      invalidatesTags: ['preferences'],
    }),
    getTopicList: builder.query<Response<TaxonomyCategoryModel[]>, void>({
      query: () => ({
        url: '/api/v1/Taxonomy/topics',
        method: 'get',
      }),
      providesTags: ['Interests'],
    }),
    getServiceList: builder.query<Response<TaxonomyCategoryModel[]>, void>({
      query: () => ({
        url: '/api/v1/Taxonomy/services',
        method: 'get',
      }),
      providesTags: ['Interests'],
    }),
  }),
})

export const {
  useGetTopicListQuery,
  useGetIndustryListQuery,
  useGetServiceListQuery,
  useGetTaxonomyPreferencesQuery,
  useSetTaxonomyPreferencesMutation,
} = cmsService

export default cmsService
