import React, { useReducer, useMemo } from 'react'
import { EngagementsContext, EngagementListState } from './context'
import { reducer } from './reducer'

const EngagementsListProvider = ({
  initialState,
  children,
}: {
  initialState: EngagementListState
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState })

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <EngagementsContext.Provider value={contextValue}>
      {children}
    </EngagementsContext.Provider>
  )
}

export default EngagementsListProvider
