import { FormLabel, styled } from '@mui/material'

const DynamicFormLabel = styled(FormLabel)(({ theme }) => ({
  '.MuiFormLabel-root': {
    fontSize: '16px',
  },
  opacity: 1,
  fontSize: '16px',
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: '.5em',
}))

export default DynamicFormLabel
