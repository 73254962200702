import React, { memo, useEffect } from 'react'
import versionService from '../../utils/helpers/version.service'

const VersionService = () => {
  useEffect(() => {
    versionService()
  }, [])

  return <div />
}

export default memo(VersionService)
