import React from 'react'

export interface IconProps {
  color?: string
  size?: string
}

const CircleErrorIcon = ({ color = 'white', size = '1.5rem' }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0833 13.7499H11.9166V15.5833H10.0833V13.7499ZM10.0833 6.41658H11.9166V11.9166H10.0833V6.41658ZM10.9908 1.83325C5.93081 1.83325 1.83331 5.93992 1.83331 10.9999C1.83331 16.0599 5.93081 20.1666 10.9908 20.1666C16.06 20.1666 20.1666 16.0599 20.1666 10.9999C20.1666 5.93992 16.06 1.83325 10.9908 1.83325ZM11 18.3333C6.94831 18.3333 3.66665 15.0516 3.66665 10.9999C3.66665 6.94825 6.94831 3.66659 11 3.66659C15.0516 3.66659 18.3333 6.94825 18.3333 10.9999C18.3333 15.0516 15.0516 18.3333 11 18.3333Z"
      fill={color}
    />
  </svg>
)

export default CircleErrorIcon
