import {
  CursorPaging,
  InsightModel,
  PagingRequest,
} from '@rsmus/ecp-cmsservice'
import axios from 'axios'
import { DateTime } from 'luxon'
import { load } from 'cheerio'
import { sha256 } from 'js-sha256'
import {
  INSIGHT_CONTENT_IMAGE_PREFIX,
  REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK,
} from '../../envVariables'
import api from '../../api'

export interface ArticleMetaData extends InsightModel {
  favorite: boolean
}

export interface ArticlesPaged {
  articles: ArticleMetaData[]
  pagingRequest?: PagingRequest | undefined
  totalRows: number | undefined
  cursorPaging?: CursorPaging | undefined
}

export const searchArticleList = async (
  pageNumber: number | undefined,
  pageSize: number | undefined,
  searchString: string | null,
) => {
  const response = await api.cms.insights_Search(
    pageNumber,
    pageSize,
    searchString,
  )
  const articles =
    response.data?.result?.map((a: InsightModel) => ({
      ...a,
      favorite: false,
    })) ?? []

  const pagedResults: ArticlesPaged = {
    articles,
    pagingRequest: response.data?.pagingRequest,
    totalRows: response.data?.totalRows,
    cursorPaging: response.data?.cursorPaging,
  }

  return pagedResults
}

export const filterArticleList = async (
  pageNumber: number,
  pageSize: number,
  searchString: string,
  filterTerms: string[],
  favorites: boolean,
  recentlyViewed: boolean,
  myInterests: boolean,
  sharedWithMe: boolean,
) => {
  const response = await api.cms.insights_Filter({
    pageNumber,
    pageSize,
    filterTerms,
    searchString,
    favorites,
    recentlyViewed,
    myInterests,
    sharedWithMe,
  })
  const articles =
    response.data?.result?.map((a: InsightModel) => ({
      ...a,
      id: sha256(a.url ?? ''),
      favorite: false,
    })) ?? []

  const pagedResults: ArticlesPaged = {
    articles,
    pagingRequest: response.data?.pagingRequest,
    totalRows: response.data?.totalRows,
    cursorPaging: response.data?.cursorPaging,
  }

  return pagedResults
}

export const getArticleListMetadata = async (
  pageNumber: number | undefined,
  pageSize: number | undefined,
) => {
  const response = await api.cms.insights_GetInsights(pageNumber, pageSize)
  const articles =
    response.data?.result?.map((a: InsightModel) => ({
      ...a,
      favorite: false,
    })) ?? []

  const pagedResults: ArticlesPaged = {
    articles,
    pagingRequest: response.data?.pagingRequest,
    totalRows: response.data?.totalRows,
    cursorPaging: response.data?.cursorPaging,
  }

  return pagedResults
}

export const getArticleListForHomePage = async (pageSize: number) => {
  const response = await api.cms.insights_GetInsightsForHomePage(pageSize)
  const articles =
    response.data?.result?.map((a: InsightModel) => ({
      ...a,
      favorite: false,
    })) ?? []

  const pagedResults: ArticlesPaged = {
    articles,
    pagingRequest: response.data?.pagingRequest,
    totalRows: response.data?.totalRows,
    cursorPaging: response.data?.cursorPaging,
  }

  return pagedResults
}

export const getArticleMetadataByEcpId = async (ecpArticleId: string) => {
  const response = await api.cms.insights_GetInsight(ecpArticleId)

  return response
}
export const getSharedArticleData = async (ecpArticleId: string) => {
  try {
    const response = await api.cms.sharedArticle_GetSharedArticleInfo(
      ecpArticleId,
    )
    return response
  } catch {
    return Promise.resolve({ data: undefined })
  }
}

export const getArticleContent = async (url: string): Promise<string> => {
  const imgPrefix = INSIGHT_CONTENT_IMAGE_PREFIX ?? 'https://rsmus.com/'
  const { data } = await axios.get(url)
  const $ = load(data)

  // While content grabs all the containers we want, it's stores it to some sort of array
  // so content.html() actually only returns content[0] (or content.first())
  const content = $('.cmp-container_container .text').parent()

  // to address this, we'll create a new object that starts with the first content node
  const fullContent = content.first()

  content.find('.responsivegrid').remove()

  // Loop through the rest of the content nodes and add it to our full content object
  for (let i = 1; i < content.length; i += 1) {
    fullContent.append($('.cmp-container_container .text').parent().eq(i))
  }

  // identify relative path links, update themw ith the prefix
  // and have clicking it open in a new tab
  content.find('a').each(function loopThroughLinks() {
    const hrefSrc = $(this).attr('href')
    if (hrefSrc?.startsWith('/')) {
      $(this).attr('href', `${imgPrefix}${hrefSrc}`)
      $(this).attr('target', '_blank')
    }
  })

  // remove the following divs
  content.find('.tag-list').remove()
  content.find('.contributors-list').remove()
  content.find('.drop-target-player').remove() // suppresses video player. When ready to implement, this line of code will need to be removed. Bug#390074

  // add prefix to all images (fixes relative path issue)
  content.find('img').each(function loopThroughImages() {
    const imgSrc = $(this).attr('src')
    $(this).attr('src', `${imgPrefix}${imgSrc}`)
  })

  // add prefix to all icon images causing spacing issues
  content.find('img').each(function loopThroughImages() {
    const imgSrc = $(this).attr('src')
    const imgClass = $(this).attr('class')
    if (
      imgSrc?.includes('tax-icon-1-white.svg') ||
      imgSrc?.includes('general-icon-8-white.svg') ||
      imgSrc?.includes('gov-icon-2-white.svg')
    )
      $(this).attr('class', `icon-${imgClass}`)
  })

  return fullContent.html() ?? ''
}

export const getArticleMetadata = async (
  articleId: string,
): Promise<ArticleMetaData> => {
  const response = await getArticleMetadataByEcpId(articleId)
  const insightModel = (response.data as InsightModel) || {}

  const article: ArticleMetaData = {
    ...insightModel,
    favorite: false,
  }

  return article as ArticleMetaData
}

export const getSpotlightArticle = async () => {
  const response = await api.cms.insights_GetMainInsight()

  if (response?.data) {
    const insightModel = (response.data as InsightModel) || {}

    const article: ArticleMetaData = {
      ...insightModel,
      favorite: false,
    }
    return article
  }

  return undefined
}

export const CheckRsmusIsDown = async (): Promise<boolean> => {
  try {
    const currentTime = DateTime.now().toString()
    const response = await axios.get(
      `${REACT_APP_RSMUS_DOT_COM_LIVENESS_CHECK}?dt=${currentTime}`,
    )
    if (response.status !== 200) return true
  } catch {
    return true
  }
  return false
}

// check if any articles shared with this user
export const hasSharedWithMeArticles = async (): Promise<boolean> => {
  const pagedResults = await filterArticleList(
    1,
    12,
    '',
    [],
    false,
    false,
    false,
    true,
  )
  return pagedResults?.articles?.length > 0
}
