interface QueueItem<T> {
  promise: () => Promise<T>
  resolve: (value: T) => void
  reject: (reason?: any) => void
}

/// Ensures promises are resolved in seqeunce.
export default class Queue<T> {
  private queue: QueueItem<T>[] = []

  private workingOnPromise = false

  /**
   * @param {promise} promise - A callback that returns a promise to start once all previous promises in the queue have been resolved or rejected.
   * @returns {Promise<T>} - A promise that is resolved once the callback promise is dequeued and resolved.
   */
  enqueue(promise: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.queue.push({
        promise,
        resolve,
        reject,
      })
      this.dequeue()
    })
  }

  private dequeue() {
    if (this.workingOnPromise) {
      return false
    }
    const item = this.queue.shift()
    if (!item) {
      return false
    }
    try {
      this.workingOnPromise = true
      item
        .promise()
        .then((value) => {
          this.workingOnPromise = false
          item.resolve(value)
          this.dequeue()
        })
        .catch((err) => {
          this.workingOnPromise = false
          item.reject(err)
          this.dequeue()
        })
    } catch (err) {
      this.workingOnPromise = false
      item.reject(err)
      this.dequeue()
    }
    return true
  }
}
