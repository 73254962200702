import { Box, Theme } from '@mui/material'
import React, { useCallback } from 'react'
import { Heading } from '../../typography'

type SectionHeaderProps = {
  title: string
  alternateStyle?: boolean
  headingElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  testId: string
}

const SectionHeader = ({
  title,
  alternateStyle = false,
  headingElement = 'h2',
  testId,
}: SectionHeaderProps) => {
  const computedStyles = {
    container: useCallback(
      (theme: Theme) => ({
        display: 'flex',
        marginBottom: '2.1875rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        '& hr': {
          flexShrink: 1,
          alignSelf: 'flex-end',
          marginBottom: '0.125rem',
          marginLeft: '0.5rem',
          borderColor: alternateStyle
            ? theme.palette.common.white
            : theme.palette.primary.main,
          width: '100%',
        },
        '& h1, & h2, & h3, & h4, & h5, & h6': {
          color: alternateStyle ? theme.palette.common.white : undefined,
        },
      }),
      [alternateStyle],
    ),
  }

  return (
    <Box sx={(theme) => computedStyles.container(theme)}>
      <Heading variant={`${headingElement}WithH5Styling`} testId={testId ?? ''}>
        {title}
      </Heading>
      <hr aria-hidden="true" />
    </Box>
  )
}

export default SectionHeader
