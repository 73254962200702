import React from 'react'
import { v4 as uuidv4 } from 'uuid'

export type RsmLogoProps = {
  variant?: string
  width?: number
  height?: number
}

const RsmLogo = ({ variant = '', width = 129, height = 54 }: RsmLogoProps) => {
  const id = uuidv4()
  if (variant === 'white') {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 129 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48 14H129V0H48V14Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 14H10V0H0V14Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 14H42V0H16V14Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.2726 33.8345C70.2726 30.6794 69.1934 28.1369 67.1535 26.5131C65.0546 24.8563 62.6131 24.4586 58.999 24.4586H48V53.5701H53.9629V29.6999H59.2138C60.6982 29.6999 61.777 29.884 62.4893 30.313C63.538 30.926 64.1565 32.0597 64.1565 33.8345C64.1565 36.8681 62.2731 37.9425 59.8015 37.9425H55.8278V43.2122H58.1962L64.4028 53.5701H71.2923L64.4028 42.2331C68.4192 41.0674 70.2726 37.758 70.2726 33.8345"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.3467 37.0837C84.1159 35.6437 79.3549 35.4599 79.3549 32.2731C79.3549 30.2218 81.2406 29.2719 83.9283 29.2719C86.5559 29.2719 88.9036 29.5139 92.8567 30.558V25.2869C89.4599 24.3373 86.9883 24 83.7755 24C77.9662 24 73.0542 26.2976 73.0542 32.1523C73.0542 36.777 75.3689 39.1073 79.9434 40.6675C84.0206 42.0489 88.623 42.3243 88.623 45.4489C88.623 47.7803 86.3376 48.7594 83.0331 48.7594C79.8192 48.7594 77.8141 48.5127 73.7035 47.41V52.7095C77.1333 53.7224 80.0053 54 83.3416 54C90.6296 54 94.8969 50.8427 94.8969 44.9908C94.8969 40.3615 91.838 38.28 88.3467 37.0837"
          fill="white"
        />
        <mask
          id={`mask0_${id}`}
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="110"
          y="24"
          width="19"
          height="30">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M110.744 24.4585H129V53.5704H110.744V24.4585Z"
            fill="white"
          />
        </mask>
        <g mask={`url(#mask0_${id})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.215 24.4585L110.744 53.5704H116.429L123.069 35.0613H123.161V53.5704H129V24.4585H121.215Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.772 42.5767L105.954 24.4584H98.0446V53.5703H103.823V35.0612H103.917L109.826 50.7665L112.772 42.5767Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 129 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 14H129V0H48V14Z"
        fill="#009ADD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14H10V0H0V14Z"
        fill="#63666A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 14H42V0H16V14Z"
        fill="#4B9C2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.2726 33.8345C70.2726 30.6794 69.1934 28.1369 67.1535 26.5131C65.0546 24.8563 62.6131 24.4586 58.999 24.4586H48V53.5701H53.9629V29.6999H59.2138C60.6982 29.6999 61.777 29.884 62.4893 30.313C63.538 30.926 64.1565 32.0597 64.1565 33.8345C64.1565 36.8681 62.2731 37.9425 59.8015 37.9425H55.8278V43.2122H58.1962L64.4028 53.5701H71.2923L64.4028 42.2331C68.4192 41.0674 70.2726 37.758 70.2726 33.8345Z"
        fill="#5C6064"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.3467 37.0837C84.1159 35.6437 79.3549 35.4599 79.3549 32.2731C79.3549 30.2218 81.2406 29.2719 83.9283 29.2719C86.5559 29.2719 88.9036 29.5139 92.8567 30.558V25.2869C89.4599 24.3373 86.9883 24 83.7755 24C77.9662 24 73.0542 26.2976 73.0542 32.1523C73.0542 36.777 75.3689 39.1073 79.9434 40.6675C84.0206 42.0489 88.623 42.3243 88.623 45.4489C88.623 47.7803 86.3376 48.7594 83.0331 48.7594C79.8192 48.7594 77.8141 48.5127 73.7035 47.41V52.7095C77.1333 53.7224 80.0053 54 83.3416 54C90.6296 54 94.8969 50.8427 94.8969 44.9908C94.8969 40.3615 91.838 38.28 88.3467 37.0837Z"
        fill="#5C6064"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.215 24.4585L110.744 53.5704H116.429L123.069 35.0613H123.161V53.5704H129V24.4585H121.215Z"
        fill="#5C6064"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.772 42.5767L105.954 24.4584H98.0446V53.5703H103.823V35.0612H103.917L109.826 50.7665L112.772 42.5767Z"
        fill="#5C6064"
      />
    </svg>
  )
}

export default RsmLogo
