import React from 'react'

const GavelStrikeIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.7492 10.6412C33.545 10.0004 32.0492 10.4571 31.4084 11.6613C30.7676 12.8656 31.2243 14.3613 32.4285 15.0022L45.9881 22.218C47.1923 22.8588 48.6881 22.4021 49.3289 21.1979C49.9698 19.9936 49.513 18.4979 48.3088 17.857L34.7492 10.6412Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.1418 37.6353C19.9376 36.9945 18.4418 37.4512 17.801 38.6555C17.1601 39.8597 17.6169 41.3554 18.8211 41.9963L32.3807 49.2121C33.5849 49.853 35.0806 49.3962 35.7215 48.192C36.3623 46.9877 35.9056 45.492 34.7014 44.8512L21.1418 37.6353Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.0397 14.7402L20.4297 37.3402"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.8894 22.4902L35.3594 45.3602"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.7007 36.691L42.5907 31.171C42.8807 30.591 44.1907 30.181 45.3507 30.761C46.5107 31.341 47.0507 32.801 46.7607 33.381L43.9007 38.811C43.6107 39.391 42.1807 39.941 41.0207 39.361C39.8607 38.781 39.4107 37.281 39.7007 36.701V36.691Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.5792 33.7207C46.5792 33.7207 74.5192 44.1107 77.7492 47.5407C80.9792 50.9707 77.4292 56.9207 72.6492 55.5007C67.8692 54.0807 44.1992 38.2407 44.1992 38.2407"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.0104 62.7206C13.0104 65.4306 52.6204 64.9206 55.3304 62.7206C55.3304 62.7206 55.1404 56.3206 50.4204 56.5806C34.3304 58.3906 23.6104 57.0306 17.1504 56.5806C10.6904 56.1306 11.0104 62.7206 11.0104 62.7206V62.7206Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.9902 56.73C17.9902 53.93 19.2102 52 21.7302 52H43.8402C46.9402 52 48.5402 54.04 48.5402 56.73"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.7001 40.3697L12.0801 33.9297"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.01 43.8598L1 41.3398"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.3108 49.5L4.30078 52.84"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default GavelStrikeIcon
