import { useContext } from 'react'
import { OidcIdentityContext } from '@rsmus/react-auth'
import type { UserProfile } from 'oidc-client-ts'

interface RsmUserProfile extends UserProfile {
  ver?: number
  idp?: string
  rsmuid?: string
  FirstName?: string
  LastName?: string
}

type User = {
  id_token?: string
  session_state?: string | null
  access_token: string
  refresh_token?: string
  token_type: string
  scope?: string
  profile: RsmUserProfile
  expires_at?: number
  userState?: unknown
}

const useUser = (): User | undefined => {
  const { user } = useContext(OidcIdentityContext)
  return user
}

export default useUser
