import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ActiveEngagement } from '../../../pages/Engagement/Engagement.service'
import { EngagementView } from '../../../pages/EngagementList/Interfaces/EngagementViewTypes'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  getActiveEngagement,
  getEngagements,
  setActiveEngagement,
} from '../../../store/engagements/engagementSlice'
import { useAppSelector } from '../../../utils/hooks'
import Dropdown, { DropdownObject } from '../../Dropdown/Dropdown'
import {
  determineDropDownOptions,
  setDefaultDropdown,
} from './EngagementMenuMobile.service'

export interface EngagementMenuMobileProps {
  defaultSelected: string
}

const EngagementMenuMobile = ({
  defaultSelected,
}: EngagementMenuMobileProps) => {
  const navigate = useNavigate()
  const engagement = useSelector(getActiveEngagement)
  const isEmployee = useAppSelector(
    (state) => state.userInfo.UserInfo.isEmployee,
  )
  const [isLoading, setIsLoading] = useState(true)
  const [dropdownData, setDropDownData] = useState<DropdownObject[]>([])
  const [dropdownDefault, setDropdownDefault] = useState<DropdownObject>({
    value: '0',
    display: 'Please select',
  })
  const dispatch = useDispatch()
  const engagementsList: EngagementView[] = useSelector(getEngagements)

  const params = useParams()

  useEffect(() => {
    const filterEng: EngagementView[] = engagementsList.filter(
      (x) => x.id === params?.engagementId,
    )
    const activeEngagement: ActiveEngagement = {
      id: params?.engagementId || '',
      name: 'Dashboard',
    }
    if (filterEng?.length) {
      activeEngagement.id = filterEng[0].id
      activeEngagement.name = filterEng[0].name
    }
    dispatch(setActiveEngagement(activeEngagement))
  }, [params, engagementsList])

  const redirectToDropdownValue = (data: string | unknown) => {
    navigate(`${data}`)
  }

  const determineData = async () => {
    if (engagement) {
      const data = await determineDropDownOptions(
        isEmployee,
        engagement.id,
        isFeatureFlagEnabled('DocumentRequest'),
      )
      setDropDownData(data)
      const defaultOption = await setDefaultDropdown(data, defaultSelected)
      setDropdownDefault(defaultOption)
      setIsLoading(false)
    }
  }

  const engagementName = useSelector(getActiveEngagement)?.name || 'Dashboard'

  useEffect(() => {
    determineData()
  }, [isEmployee])

  return (
    <div className="bg-[#F7F6F4] px-[16px] mb-[36px]">
      {isLoading ? (
        <div
          className="flex justify-center align-middle h-[98px]"
          aria-busy="true">
          {' '}
          <CircularProgress size="100px" />{' '}
        </div>
      ) : (
        <div>
          <h1 className="mb-[16px] font-prelo-light text-[30px] leading-[40px] text-rsm-gray-copy">
            {engagementName}
          </h1>
          <div className="w-full max-w-[500px]">
            <Dropdown
              data={dropdownData}
              defaultSelection={dropdownDefault}
              dropdownName="SubNavigationDropdown"
              ariaLabel="Sub Navigation Dropdown"
              onCompleteFunction={redirectToDropdownValue}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default EngagementMenuMobile
