import React from 'react'
import { Box } from '@mui/material'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import ArticleListItemFavorite from './ArticleListItemFavorite'
import ArticleListItemImage from '../InsightsSubcomponents/ArticleListItemComponents/ArticleListItemImage'
import ArticleListItemSnapshot from '../InsightsSubcomponents/ArticleListItemComponents/ArticleListItemSnapshot'
import ArticleListItemTitle from './ArticleListItemTitle'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    borderColor: tokens.colors.rsmGray.disabled,
    borderWidth: '0.0625rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
  }),
  content: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '1.5rem',
      height: '12.625rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingLeft: '2rem',
      height: '14.875rem',
    },
  }),
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1.5rem',
    marginBottom: '1rem',
  },
  subHeader: {
    paddingBottom: '1rem',
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
  },
  readMoreButtonContainer: (theme) => ({
    marginRight: '-0.125rem',
    marginBottom: '0.875rem',
    marginLeft: '-0.125rem',
    [theme.breakpoints.up('desktop')]: {
      marginTop: '0.75rem',
      marginBottom: '0.75rem',
    },
  }),
}

interface ArticleListItemProps {
  article: ArticleMetaData
  articleIndex: number
  articleListLength: number
  focusIndex?: number
}

const ArticleListItem = ({
  article,
  articleListLength,
  focusIndex = 0,
  articleIndex,
}: ArticleListItemProps) => {
  const articleTitleId = `article${articleIndex}Title`

  return (
    <Box
      sx={styles.container}
      aria-posinset={articleIndex + 1}
      aria-setsize={articleListLength}
      data-testid={`Sec_Insight_Article_${articleIndex + 1}`}>
      <ArticleListItemImage
        url={article.imageUrl}
        id={article.ecpId}
        index={articleIndex}
      />
      <Box sx={styles.content}>
        <Box sx={styles.header} id={`${articleTitleId}header`}>
          <ArticleListItemTitle
            articleIndex={articleIndex}
            articleTitleId={articleTitleId}
            focusIndex={focusIndex}
            title={article.title}
            id={article.ecpId}
          />
          <ArticleListItemFavorite
            article={article}
            articleTitleId={articleTitleId}
            index={articleIndex}
          />{' '}
        </Box>
        <Box component="p" sx={styles.subHeader}>
          {article.label && article.label.length > 0 ? (
            <>
              <span data-testid={`Lbl_Article_Industry_${articleIndex}`}>
                {article.label}
              </span>
              <span>&nbsp;|&nbsp;</span>
            </>
          ) : null}
          {article.publishedOn ? (
            <span data-testid={`Lbl_Article_Date_${articleIndex}`}>
              {article.publishedOn.toLocaleDateString('en-us', {
                month: 'long',
                day: 'numeric',
                timeZone: 'UTC',
              })}
            </span>
          ) : null}
        </Box>
        <ArticleListItemSnapshot
          articleTitleId={articleTitleId}
          snapshot={article.snapshot}
          index={articleIndex}
        />
      </Box>
    </Box>
  )
}

export default ArticleListItem
