import { t } from 'i18next'

export const minYear = new Date('50')
export const maxYear = new Date((new Date().getFullYear() + 28).toString())

export const getStartDateError = (start: Date | null): string => {
  if (!start) {
    return t('Invoicing.InvoiceDateErrors.StartDateIsRequired')
  }
  if (start > maxYear || start < minYear) {
    return t('Invoicing.InvoiceDateErrors.InvalidDateRange')
  }
  return t('Invoicing.InvoiceDateErrors.InvalidStartDate')
}

export const getEndDateError = (end: Date | null) => {
  if (!end) {
    return t('Invoicing.InvoiceDateErrors.EndDateIsRequired')
  }
  if (end > maxYear || end < minYear) {
    return t('Invoicing.InvoiceDateErrors.InvalidDateRange')
  }
  return t('Invoicing.InvoiceDateErrors.InvalidEndDate')
}
