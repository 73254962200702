import { Box, Button } from '@mui/material'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../rsmCoreComponents/Loader'
import {
  useGetTaxonomyPreferencesQuery,
  useSetTaxonomyPreferencesMutation,
} from '../../../store/cmsService'
import ProfileModal from '../ProfileModal'
import { Styles } from '../../../types'
import NoInterests from './NoInterests'
import UsersInterestsPerCategory from './UsersInterestsPerCategory'
import { setPopoverContent } from '../../../store/form/formSlice'

const styles: Styles = {
  noInterests: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  interestsContainer: {
    display: 'grid',
    gridTemplateColumns: {
      mobile: '1fr',
      tablet: '0.5fr 1fr 1fr 1fr 0.5fr',
      desktop: '1fr 1fr 1fr 1fr 0.5fr',
    },
  },
}

const AllowedCategories = ['Industry', 'Service', 'Topic']

interface InterestsSectionProps {
  reloadKey?: string | undefined | null
}

const InterestsSection: FC<InterestsSectionProps> = ({
  reloadKey = undefined,
}: InterestsSectionProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const getTaxonomyPreferences = useGetTaxonomyPreferencesQuery()
  const [setTaxonomyPreferences] = useSetTaxonomyPreferencesMutation()

  const saveAllAndCloseDialog = (data: number[]) => {
    setOpen(false)
    setTaxonomyPreferences(data)
  }

  const closeDialog = useCallback(async () => {
    setOpen(false)
    navigate(`/profile`)
  }, [setOpen, dispatch, navigate, setPopoverContent, t])

  useEffect(() => {
    if (reloadKey) getTaxonomyPreferences.refetch()
  }, [reloadKey])

  return (
    <>
      <Loader loading={getTaxonomyPreferences.isLoading}>
        {!getTaxonomyPreferences.data?.data.length ? (
          <NoInterests openModal={() => setOpen(true)} />
        ) : (
          <>
            <Box
              key="interestsDetails"
              sx={styles.interestsContainer}
              data-testid="Sec_Profile_Interesets">
              {/* Empty Boxes acts as relative margins for grid to be responsive across devices */}
              <Box key="leftMargin">&nbsp;</Box>
              {AllowedCategories.map((category) => (
                <UsersInterestsPerCategory
                  key={category}
                  title={t(`UserProfile.${category}Label`)}
                  interests={
                    getTaxonomyPreferences.data?.data
                      .filter((r) => r.category === category) // category is a key, unnecessary to check for safe comparison
                      .map((r) => r.name ?? '') as string[]
                  }
                />
              ))}
              <Box key="rightMargin">&nbsp;</Box>
            </Box>
            <Box key="interestsButton" sx={styles.noInterests}>
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                data-testid="Btn_Profile_InterestsUpdate">
                {t('UserProfile.PageUpdateInterestsButton')}
              </Button>
            </Box>
          </>
        )}
      </Loader>
      <ProfileModal
        open={open}
        defaultValue={getTaxonomyPreferences.data?.data.map((r) => r.id) ?? []}
        skipProfile
        onSubmit={saveAllAndCloseDialog}
        onClose={closeDialog}
      />
    </>
  )
}

export default InterestsSection
