import React from 'react'

const CareIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.9206 64.1696L35.4406 59.0396C35.4406 59.0396 55.2306 61.7896 55.1006 54.5696C54.9706 47.3496 34.9906 47.5896 34.9906 47.5896L30.7306 45.8096C30.7306 45.8096 20.2606 38.2296 3.89062 47.3696L4.10062 65.7596L40.7406 78.0096L68.7306 59.1796L73.4206 45.5896C73.4206 45.5896 65.7206 41.9196 61.7206 52.9696L47.3206 61.7596L43.2706 59.5596"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.7501 12.97C41.7501 12.97 37.1801 2 25.7301 2C14.2801 2 12.6901 16.36 17.0901 24.79C21.4901 33.22 41.7501 44.5 41.7501 44.5C41.7501 44.5 62.9401 31.43 66.8801 24.61C70.8201 17.79 69.8901 2.21 58.4201 2.21C46.9501 2.21 41.7501 12.97 41.7501 12.97V12.97Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CareIcon
