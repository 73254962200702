import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { format, isValid } from 'date-fns'
import { Styles } from '../../../types'
import { getInvoiceSelectedFilters } from '../../../store/invoices/invoiceSelectedFiltersSlice'

const styles: Styles = {
  main: () => ({
    textAlign: 'center',
    marginBottom: '2rem',
  }),
  linkStyle: (theme) => ({
    marginLeft: '0.15rem',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    display: 'inline-block',
  }),
}

const InvoicesRetentionMessage = () => {
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const [selectedDate, setSelectedDate] = useState<Date | null>()
  const { t } = useTranslation()
  const today = new Date()
  const lowerRetentionDate = new Date(
    today.setFullYear(today.getFullYear() - 7),
  )
  const formatedRetention = `${
    selectedDate && isValid(selectedDate)
      ? format(new Date(selectedDate), 'MM/dd/yyyy')
      : null
  } - ${format(new Date(lowerRetentionDate), 'MM/dd/yyyy')}`

  useEffect(() => {
    if (selectedFilters && selectedFilters.invoiceStartDate) {
      setSelectedDate(selectedFilters.invoiceStartDate)
    } else {
      setSelectedDate(null)
    }
  }, [selectedFilters])

  const show: boolean = selectedDate ? selectedDate < lowerRetentionDate : false

  return (
    <Box>
      {show && (
        <Box sx={styles.main}>
          <Box>{`${t('Invoicing.Retention', {
            date: formatedRetention,
          })}`}</Box>
          <Box>
            <Box sx={styles.linkStyle}>
              <a href="mailto:Accounts_receivable@rsmus.com">
                Accounts_receivable@rsmus.com
              </a>
            </Box>
            <span> or</span>
            <Box sx={styles.linkStyle}>
              <a href="mailto:Accounts_receivable@rsmcanada.com">
                Accounts_receivable@rsmcanada.com
              </a>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default InvoicesRetentionMessage
