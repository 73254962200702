import React from 'react'

const XLS = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="5" y="12" width="13" height="7" rx="0.8" fill="#34A798" />
    <path
      d="M8.71709 15.9299H8.7083L8.10625 17H7.40312L8.32598 15.4114L7.45366 13.9392H8.18535L8.74785 14.895H8.75884L9.30156 13.9392H10.0398L9.16313 15.4114L10.1211 17H9.36968L8.71709 15.9299ZM11.2979 13.9392V16.4375H12.7195V17H10.6672V13.9392H11.2979ZM14.4235 14.4578C14.0734 14.4578 13.8984 14.561 13.8984 14.7676C13.8984 14.8877 13.9687 14.9785 14.1093 15.04C14.206 15.0825 14.3664 15.1301 14.5905 15.1829C15.2058 15.3293 15.5134 15.6333 15.5134 16.0947C15.5134 16.4097 15.4057 16.647 15.1904 16.8066C14.9765 16.9648 14.6843 17.0439 14.3137 17.0439C13.9431 17.0439 13.5688 16.9875 13.1909 16.8748L13.2941 16.3474C13.6779 16.4368 14.0302 16.4814 14.351 16.4814C14.5034 16.4814 14.625 16.4514 14.7158 16.3914C14.8066 16.3298 14.852 16.2537 14.852 16.1628C14.852 16.0706 14.8308 16.0061 14.7883 15.9695C14.7458 15.9329 14.7128 15.9065 14.6894 15.8904C14.6513 15.864 14.5693 15.8318 14.4433 15.7937C14.3188 15.7556 14.2192 15.7285 14.1445 15.7124C13.538 15.5381 13.2348 15.2188 13.2348 14.7544C13.2348 14.46 13.3381 14.2432 13.5446 14.104C13.7526 13.9648 14.0251 13.8953 14.362 13.8953C14.7004 13.8953 15.0585 13.9524 15.4365 14.0667L15.2915 14.5918C14.9077 14.5024 14.6184 14.4578 14.4235 14.4578Z"
      fill="white"
    />
  </svg>
)

export default XLS
