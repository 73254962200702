export function convertAlphaToHex(alphaDecimal: number) {
  return Math.round(alphaDecimal * 255)
    .toString(16)
    .toUpperCase()
    .padStart(2, '0')
}

export function brandColorToHex(brandColor: string, opacity: number): string {
  const hexColor = '#000000'
  switch (brandColor) {
    // Decided to comment out and address this later in a future release
    // case 'blue':
    //   hexColor = '#007bff'
    //   break
    // case 'black':
    //   hexColor = '#000000'
    //   break
    // case 'green':
    //   hexColor = '#28a745'
    //   break
    // case 'gray':
    //   hexColor = '#6c757d'
    //   break
    // case 'lightgray':
    //   hexColor = '#dedede'
    //   break
    // case 'dark':
    //   hexColor = '#343a40'
    //   break
    // case 'light':
    //   hexColor = '#f8f9fa'
    //   break
    default:
      break
  }
  return hexColor + convertAlphaToHex(opacity)
}
