import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getAutoPaySetup,
  getPayOnAccountPayment,
  getPaymentTransaction,
  getScheduledInstallments,
  setAutoPaySetup,
  setPayOnAccountPayment,
  setPaymentTransaction,
  setScheduledInstallments,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import InstallmentPaymentSuccess from '../Payments/InstallmentPaymentSuccess'
import ScheduledPaymentSuccess from '../Payments/ScheduledPaymentSuccess'
import PayNowPaymentSuccess from '../Payments/PayNowPaymentSuccess'
import PayOnAccountPaymentSuccess from '../Payments/PayOnAccountPaymentSuccess'
import AutoPaySetupSuccess from '../Payments/AutoPaySetupSuccess'

const InvoicePaymentSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const paymentTransaction = useSelector(getPaymentTransaction)
  const scheduledInstallments = useSelector(getScheduledInstallments)
  const payOnAccountPayment = useSelector(getPayOnAccountPayment)
  const autoPaySetup = useSelector(getAutoPaySetup)

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (
      (scheduledInstallments?.installments?.length || 0) === 0 &&
      !paymentTransaction &&
      !payOnAccountPayment &&
      !autoPaySetup
    ) {
      navigate('/invoicing')
    }

    // unmount useEffect to reset store values when navigating away from this page
    return () => {
      dispatch(setPayOnAccountPayment(null))
      dispatch(setPaymentTransaction(null))
      dispatch(setScheduledInstallments(null))
      dispatch(setAutoPaySetup(null))
    }
  }, [])

  return (
    <>
      {(scheduledInstallments?.installments?.length || 0) > 1 && (
        <InstallmentPaymentSuccess
          scheduledInstallments={scheduledInstallments}
        />
      )}
      {(scheduledInstallments?.installments?.length || 0) === 1 && (
        <ScheduledPaymentSuccess
          scheduledInstallments={scheduledInstallments}
        />
      )}
      {paymentTransaction && (
        <PayNowPaymentSuccess paymentTransaction={paymentTransaction} />
      )}
      {payOnAccountPayment && (
        <PayOnAccountPaymentSuccess paymentTransaction={payOnAccountPayment} />
      )}
      {autoPaySetup && <AutoPaySetupSuccess autoPaySetup={autoPaySetup} />}
    </>
  )
}

export default InvoicePaymentSuccess
