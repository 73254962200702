import React from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'
import { useTheme } from '@mui/material'

const ArrowLeftIcon = (props: SvgIconProps) => {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={8}
      height={12}
      fill="none"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6 0 1.41 1.41L2.83 6l4.58 4.59L6 12 0 6l6-6Z"
        fill={theme.palette.secondary.main}
      />
    </svg>
  )
}

export default ArrowLeftIcon
