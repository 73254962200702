import React from 'react'

import { v4 as uuidv4 } from 'uuid'

const MenuIcon = () => {
  const id = uuidv4()
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.857143 6.0185H23.1429C23.6163 6.0185 24 5.64349 24 5.18084V3.08669C24 2.62403 23.6163 2.24902 23.1429 2.24902H0.857143C0.383732 2.24902 0 2.62403 0 3.08669V5.18084C0 5.64349 0.383732 6.0185 0.857143 6.0185ZM0.857143 14.3951H23.1429C23.6163 14.3951 24 14.0201 24 13.5575V11.4633C24 11.0007 23.6163 10.6256 23.1429 10.6256H0.857143C0.383732 10.6256 0 11.0007 0 11.4633V13.5575C0 14.0201 0.383732 14.3951 0.857143 14.3951ZM23.1429 22.7717H0.857143C0.383732 22.7717 0 22.3967 0 21.9341V19.8399C0 19.3773 0.383732 19.0023 0.857143 19.0023H23.1429C23.6163 19.0023 24 19.3773 24 19.8399V21.9341C24 22.3967 23.6163 22.7717 23.1429 22.7717Z"
        fill="#888B8D"
      />
      <mask
        id={`mask0_${id}`}
        maskUnits="userSpaceOnUse"
        x="0"
        y="2"
        width="24"
        height="21">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.857143 6.0185H23.1429C23.6163 6.0185 24 5.64349 24 5.18084V3.08669C24 2.62403 23.6163 2.24902 23.1429 2.24902H0.857143C0.383732 2.24902 0 2.62403 0 3.08669V5.18084C0 5.64349 0.383732 6.0185 0.857143 6.0185ZM0.857143 14.3951H23.1429C23.6163 14.3951 24 14.0201 24 13.5575V11.4633C24 11.0007 23.6163 10.6256 23.1429 10.6256H0.857143C0.383732 10.6256 0 11.0007 0 11.4633V13.5575C0 14.0201 0.383732 14.3951 0.857143 14.3951ZM23.1429 22.7717H0.857143C0.383732 22.7717 0 22.3967 0 21.9341V19.8399C0 19.3773 0.383732 19.0023 0.857143 19.0023H23.1429C23.6163 19.0023 24 19.3773 24 19.8399V21.9341C24 22.3967 23.6163 22.7717 23.1429 22.7717Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_${id})`} />
    </svg>
  )
}

export default MenuIcon
