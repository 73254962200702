import * as React from 'react'
import { SVGProps } from 'react'

const CreditAmexIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_2_00000164496143594587805480000001810749838214405259_"
    data-testid="credit-amex-icon"
    x="0px"
    y="0px"
    viewBox="0 0 285.4 185.8"
    enableBackground="new 0 0 285.4 185.8"
    width={width}
    height={height}>
    <title>American Express</title>
    <g id="American_Express">
      <g>
        <path
          fill="#016FD0"
          stroke="#016FD0"
          strokeWidth="2.14"
          strokeMiterlimit="10"
          d="M22.3,1.1h240.9c11.7,0,21.2,9.5,21.2,21.2
           v141.3c0,11.7-9.5,21.2-21.2,21.2H22.3c-11.7,0-21.2-9.5-21.2-21.2V22.3C1.1,10.6,10.6,1.1,22.3,1.1z"
        />
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="228.4,92.5 250.4,63.5 219.7,63.5 212.8,72.2 
               206.1,63.5 195.1,63.5 144,63.5 121.2,63.5 116.6,76.8 111.9,63.5 81.8,63.5 81.8,81.8 73.8,63.5 49.6,63.5 24.4,122.2 
               35.5,122.2 52.9,122.2 56.6,113 66.5,113 70.2,122.2 195.9,122.2 195.9,122.2 205.7,122.2 212.5,113.4 219.2,122.2 251.2,122.2 
                           "
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#016FD0"
            d="M54.5,70.8l-18.9,43.9h12.3l3.5-8.8h20.3l3.5,8.8h12.6
               L68.9,70.8H54.5L54.5,70.8z M61.5,81l6.2,15.4H55.3L61.5,81L61.5,81z"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#016FD0"
            points="89,114.7 89,70.8 106.5,70.8 116.7,99.2 126.6,70.8 
               144,70.8 144,114.7 133,114.7 133,82.3 121.3,114.7 111.7,114.7 100,82.3 100,114.7 89,114.7 			"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#016FD0"
            points="151.5,114.7 151.5,70.8 187.4,70.8 187.4,80.6 
               162.6,80.6 162.6,88.1 186.8,88.1 186.8,97.4 162.6,97.4 162.6,105.2 187.4,105.2 187.4,114.7 151.5,114.7 			"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#016FD0"
            points="188.7,114.7 206.2,93 188.3,70.8 202.2,70.8 
               212.8,84.5 223.5,70.8 236.8,70.8 219.2,92.7 236.6,114.7 222.8,114.7 212.5,101.2 202.4,114.7 188.7,114.7 			"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CreditAmexIcon
