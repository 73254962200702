import { TeamDocsSiteViewModel } from '@rsmus/ecp-userservice'
import { useCallback, useEffect, useState } from 'react'
import api from '../..'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'

function useProjects() {
  const [state, setState] = useState<{
    results: TeamDocsSiteViewModel[]
    loading: boolean
    called: boolean
    error?: Error
  }>({
    loading: false,
    results: [],
    called: false,
  })

  async function fetch() {
    try {
      setState({ ...state, loading: true, called: true })

      if (isFeatureFlagEnabled('TeamDocs2024External')) {
        const response = await api.user
          .teamDocs_GetTeamDocsSitesOfUser()
          .then((res) => res.data || [])
        setState({ ...state, results: response || [], loading: false })
      } else {
        const response =
          await api.user.teamDocsUserMappings_GetTeamDocsUserMappings()
        setState({
          ...state,
          results:
            response.data?.map(
              (o) =>
                ({
                  name: o.project,
                  url: o.siteUrl,
                  legalEntityId: Number(o?.client ?? '0'),
                  isRoot: false,
                  lastSeenFromVaronisUtc: new Date(),
                } as TeamDocsSiteViewModel),
            ) || [],
          loading: false,
        })
      }
    } catch (error) {
      setState({ ...state, results: [], loading: false, error: error as Error })
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const refetch = useCallback(() => fetch(), [])

  const { results, loading, called, error } = state

  return { results, loading: loading && called, error, refetch }
}

export default useProjects
