import React from 'react'

const BlockchainIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.94 23.3906L14.75 27.9006L14.92 38.1206"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.3105 22.8809L64.8105 27.3909L64.6505 37.6109"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.6197 58.8908L40.1197 65.1908L27.4297 58.5508"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9395 18.48L40.2695 12L53.3095 17.07L40.1195 23.39L26.9395 18.48Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9395 18.4403V33.3303L40.4695 38.1203L53.3095 32.1003V17.0703"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.1191 23.3906V38.1206"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.6191 44.5991L64.9491 38.1191L77.9991 43.1891L64.8091 49.5091L51.6191 44.5991Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.6191 44.5595V59.4495L65.1491 64.2395L77.9991 58.2195V43.1895"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M64.8086 49.5098V64.2398"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.56055 44.5991L14.8905 38.1191L27.9405 43.1891L14.7505 49.5091L1.56055 44.5991Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.56055 44.5595V59.4495L15.0905 64.2395L27.9405 58.2195V43.1895"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 49.5098V64.2398"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default BlockchainIcon
