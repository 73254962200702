import React from 'react'

const HandPeopleIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131908)">
      <path
        d="M51.6604 16.32C50.7704 15.62 49.7804 15.11 48.7504 15.03C49.7504 14.33 50.5404 13.35 50.9804 12.19L51.0204 12.08C51.0204 12.08 51.0504 11.98 51.0704 11.93C51.0704 11.91 51.0904 11.88 51.0904 11.86C51.1004 11.81 51.1204 11.77 51.1304 11.72C51.1504 11.66 51.1604 11.61 51.1804 11.55C51.1804 11.52 51.2004 11.49 51.2004 11.46C51.2004 11.43 51.2104 11.4 51.2204 11.37C51.2204 11.35 51.2304 11.33 51.2304 11.3C51.2304 11.28 51.2304 11.25 51.2404 11.23C51.2404 11.21 51.2404 11.19 51.2504 11.17C51.2604 11.12 51.2704 11.06 51.2804 11.01C51.2804 10.97 51.2904 10.92 51.3004 10.88C51.3004 10.85 51.3004 10.82 51.3104 10.79C51.3204 10.71 51.3304 10.63 51.3304 10.55C51.3304 10.53 51.3304 10.52 51.3304 10.5C51.3404 10.35 51.3504 10.21 51.3504 10.06C51.3504 6.71 48.6204 4 45.2504 4C41.8804 4 39.1504 6.71 39.1504 10.06C39.1504 10.12 39.1504 10.17 39.1504 10.23C39.1504 10.31 39.1504 10.38 39.1604 10.45C39.1604 10.49 39.1604 10.53 39.1604 10.56C39.1604 10.61 39.1604 10.66 39.1704 10.71C39.1704 10.77 39.1804 10.82 39.1904 10.88C39.1904 10.92 39.2004 10.97 39.2104 11.01C39.2204 11.08 39.2304 11.15 39.2504 11.22C39.2504 11.27 39.2704 11.32 39.2804 11.37C39.2804 11.4 39.2904 11.43 39.3004 11.46C39.3104 11.51 39.3204 11.55 39.3404 11.6C39.3804 11.77 39.4404 11.93 39.4904 12.09C39.5004 12.13 39.5204 12.16 39.5304 12.2C39.5704 12.31 39.6204 12.42 39.6704 12.53C39.7004 12.6 39.7304 12.66 39.7604 12.73C40.2304 13.66 40.9204 14.46 41.7804 15.04C41.4104 15.1 41.0504 15.21 40.6904 15.37"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.7312 15.0605C41.3812 15.1205 41.0412 15.2305 40.7012 15.3805"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.75 15.0293C49.78 15.1093 50.77 15.6193 51.66 16.3193"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.2504 16.12C48.6193 16.12 51.3504 13.4068 51.3504 10.06C51.3504 6.71315 48.6193 4 45.2504 4C41.8815 4 39.1504 6.71315 39.1504 10.06C39.1504 13.4068 41.8815 16.12 45.2504 16.12Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2302 23.3301C26.9502 23.7001 24.8102 25.7601 23.4002 27.5901C22.0602 29.3301 21.2102 31.7201 20.6602 34.4301"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.75 23.3008C40.28 23.5008 42.64 25.8308 44.16 27.8908C45.14 29.2308 45.84 30.9408 46.35 32.8808"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.47 24.6408C37.5956 24.6408 40.94 21.3187 40.94 17.2208C40.94 13.1228 37.5956 9.80078 33.47 9.80078C29.3444 9.80078 26 13.1228 26 17.2208C26 21.3187 29.3444 24.6408 33.47 24.6408Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.291 43.1207C43.791 38.8407 44.731 34.9507 46.681 32.4107C48.281 30.3307 50.801 27.9707 53.411 27.9707C55.551 27.9707 58.111 27.9807 61.061 27.9907C63.801 27.9907 66.381 30.5107 68.001 32.7307C70.101 35.6007 70.941 40.1307 71.361 44.9807"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.9301 51.9902H58.0801"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.4311 28.0009C63.4111 26.6209 64.7111 24.3409 64.7111 21.7509C64.7111 19.8309 64.0011 18.0809 62.8211 16.7409C61.4111 15.1409 59.3411 14.1309 57.0411 14.1309C54.9511 14.1309 53.0511 14.9609 51.6711 16.3109C50.2511 17.6909 49.3711 19.6209 49.3711 21.7509C49.3711 24.3509 50.6811 26.6509 52.6911 28.0209"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6303 58.3604L35.9603 54.5404C35.9603 54.5404 57.0803 60.5704 58.0503 52.6804C59.0203 44.8004 37.2303 42.0004 37.2303 42.0004L32.8703 39.4104C32.8703 39.4104 22.6303 29.5604 3.41031 36.9904L0.820312 57.0404L38.8303 76.0004L72.1903 59.8004L79.3803 45.7304C79.3803 45.7304 71.5603 40.5504 65.5103 51.9704L48.4903 59.3304L44.4203 56.3104"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131908">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default HandPeopleIcon
