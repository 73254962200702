import React from 'react'

const CircuitryIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131221)">
      <path
        d="M45.0697 19.6309H28.5897C26.2922 19.6309 24.4297 21.4934 24.4297 23.7909V40.2709C24.4297 42.5684 26.2922 44.4309 28.5897 44.4309H45.0697C47.3672 44.4309 49.2297 42.5684 49.2297 40.2709V23.7909C49.2297 21.4934 47.3672 19.6309 45.0697 19.6309Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M49.2305 39.4707H52.7805"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M49.2305 34.3105H52.7805"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M49.2305 29.7598H52.7805"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M49.2305 24.5898H52.7805"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.8691 39.4707H24.4291"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.8691 34.3105H24.4291"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.8691 29.7598H24.4291"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.8691 24.5898H24.4291"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3 4.05078H45.54L52.06 9.83078H60.81"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M71.4803 18.1406H64.0703V41.7006"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M79.78 38.2896H73.71L67.78 46.2996H58.73V14.4296H49.23L41.98 8.34961H15.3V21.9896L6.85 29.9196V56.3796H3"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M47.2308 12.7598H15.3008"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.0391 32.0293V56.3793H19.0091L25.2291 49.4093H50.9991"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M51 52.8203V56.3803L53.91 59.2803H71.48"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M69.1098 63.2009H50.9998L44.4998 57.7109H35.7598"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M3 60.4492H20.87L27.9 66.4592H36.83"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12 69H26.69L38.1358 75H67"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M64.2908 13.3198C66.2183 13.3198 67.7808 11.7573 67.7808 9.82984C67.7808 7.90237 66.2183 6.33984 64.2908 6.33984C62.3633 6.33984 60.8008 7.90237 60.8008 9.82984C60.8008 11.7573 62.3633 13.3198 64.2908 13.3198Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M75.1697 21.6304C77.0972 21.6304 78.6597 20.0679 78.6597 18.1404C78.6597 16.2129 77.0972 14.6504 75.1697 14.6504C73.2422 14.6504 71.6797 16.2129 71.6797 18.1404C71.6797 20.0679 73.2422 21.6304 75.1697 21.6304Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M75.1697 62.7691C77.0972 62.7691 78.6597 61.2065 78.6597 59.2791C78.6597 57.3516 77.0972 55.7891 75.1697 55.7891C73.2422 55.7891 71.6797 57.3516 71.6797 59.2791C71.6797 61.2065 73.2422 62.7691 75.1697 62.7691Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M31.9002 61.2007C33.8276 61.2007 35.3902 59.6382 35.3902 57.7107C35.3902 55.7832 33.8276 54.2207 31.9002 54.2207C29.9727 54.2207 28.4102 55.7832 28.4102 57.7107C28.4102 59.6382 29.9727 61.2007 31.9002 61.2007Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.3201 70.4409C42.2476 70.4409 43.8101 68.8784 43.8101 66.9509C43.8101 65.0235 42.2476 63.4609 40.3201 63.4609C38.3926 63.4609 36.8301 65.0235 36.8301 66.9509C36.8301 68.8784 38.3926 70.4409 40.3201 70.4409Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.34938 72.7593C10.2768 72.7593 11.8394 71.1968 11.8394 69.2693C11.8394 67.3418 10.2768 65.7793 8.34938 65.7793C6.4219 65.7793 4.85938 67.3418 4.85938 69.2693C4.85938 71.1968 6.4219 72.7593 8.34938 72.7593Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M42.6596 25.0195H30.9896C30.2219 25.0195 29.5996 25.6419 29.5996 26.4095V38.0795C29.5996 38.8472 30.2219 39.4695 30.9896 39.4695H42.6596C43.4273 39.4695 44.0496 38.8472 44.0496 38.0795V26.4095C44.0496 25.6419 43.4273 25.0195 42.6596 25.0195Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131221">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CircuitryIcon
