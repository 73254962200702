import React from 'react'

const VisionDentalMedicalIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131925)">
      <path
        d="M25.7503 43.6406C25.7503 39.9106 22.7203 36.8906 19.0003 36.8906C17.0303 36.8906 15.3003 38.1506 15.3003 38.1506C14.6003 38.6606 13.4703 38.6606 12.7703 38.1506C12.7703 38.1506 11.0403 36.8906 9.07031 36.8906C5.34031 36.8906 2.32031 39.9106 2.32031 43.6406C2.32031 44.0606 2.36031 44.4606 2.43031 44.8606C2.44031 45.0306 2.46031 45.2206 2.50031 45.4006L7.46031 67.1806C7.87031 68.9806 8.54031 68.9806 8.96031 67.1806L13.1203 56.6506C13.6403 55.6806 14.3303 55.6806 14.8903 56.6306L19.0503 67.1706C19.4603 68.9706 20.1303 68.9706 20.5503 67.1706L25.5103 45.3906C25.5103 45.3906 25.5303 45.3006 25.5303 45.2606C25.6603 44.7406 25.7303 44.2006 25.7303 43.6406H25.7503Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M19.0003 41.1309C17.0303 41.1309 15.3003 42.3909 15.3003 42.3909C14.6003 42.9009 13.4703 42.9009 12.7703 42.3909C12.7703 42.3909 11.0403 41.1309 9.07031 41.1309"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M63.6698 49.5209H58.8998V44.7509C58.8998 43.2109 57.6498 41.9609 56.1098 41.9609C54.5698 41.9609 53.3098 43.2109 53.3098 44.7509V49.5209H48.5498C47.0098 49.5209 45.7598 50.7709 45.7598 52.3209C45.7598 53.8709 47.0098 55.1109 48.5498 55.1109H53.3098V59.8709C53.3098 61.4109 54.5598 62.6609 56.1098 62.6609C57.6598 62.6609 58.8998 61.4109 58.8998 59.8709V55.1109H63.6698C65.2098 55.1109 66.4598 53.8609 66.4598 52.3209C66.4598 50.7809 65.2098 49.5209 63.6698 49.5209V49.5209Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M79.1398 61.9215V33.7715H33.0898V72.0115H79.1398V64.2215V61.9215Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M47.3691 33.7709V30.2009C47.3691 28.2109 48.4791 26.7109 49.9491 26.7109H62.4791C63.9491 26.7109 65.0591 28.2109 65.0591 30.2009V33.7709M62.7391 33.7709V30.2009C62.7391 29.5509 62.5091 29.1509 62.3991 29.0409H50.0291C49.9191 29.1509 49.6891 29.5509 49.6891 30.2009V33.7709"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M70.9391 52.8905C70.9391 61.0805 64.2991 67.7105 56.1191 67.7105C47.9391 67.7105 41.2891 61.0705 41.2891 52.8905C41.2891 44.7105 47.9291 38.0605 56.1191 38.0605C64.3091 38.0605 70.9391 44.7005 70.9391 52.8905V52.8905Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.90039 17.8694C1.90039 17.8694 7.90039 8.78938 23.2004 8.70938C38.5004 8.63938 44.3704 17.8094 44.3704 17.8094C44.3704 17.8094 38.3804 28.5194 23.0804 28.5894C7.78039 28.6594 1.90039 17.8694 1.90039 17.8694V17.8694Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.90039 17.8705C1.90039 17.8705 7.78039 28.6605 23.0804 28.5905C38.3804 28.5205 44.3704 17.8105 44.3704 17.8105"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M43.8301 17.1006L45.7401 15.1406"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.5703 14.8002L43.2603 12.6602"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M38.5996 12.6293L39.9896 10.2793"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M35.1094 10.8891L36.1794 8.36914"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.3496 9.65023L32.0796 6.99023"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.3804 6.10938L27.1504 8.90937"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.5095 8.73977L22.4395 6.00977"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.0597 9.10961L17.6797 6.34961"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.8009 10.0596L12.9609 7.34961"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.59023 11.7193L8.24023 9.2793"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5.98047 13.9203L4.23047 11.8203"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.05 16.4797L1 14.6797"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.3301 18.6694C13.3301 13.2494 17.7301 8.85938 23.1501 8.85938C28.5701 8.85938 32.9601 13.2494 32.9601 18.6694C32.9601 24.0894 28.5701 28.4894 23.1501 28.4894C17.7301 28.4894 13.3301 24.0894 13.3301 18.6694V18.6694Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.7109 18.6702C17.7109 15.6702 20.1409 13.2402 23.1409 13.2402C26.1409 13.2402 28.5709 15.6702 28.5709 18.6702C28.5709 21.6702 26.1409 24.1002 23.1409 24.1002C20.1409 24.1002 17.7109 21.6702 17.7109 18.6702Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131925">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default VisionDentalMedicalIcon
