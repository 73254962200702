/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FocusTrap } from '@mui/base'
import {
  NotificationUserViewModel,
  NotificationViewModel,
} from '@rsmus/ecp-communicationservice'

import {
  Box,
  IconButton,
  Stack,
  ClickAwayListener,
  Grow,
  Paper,
  Backdrop,
  Badge,
  Typography,
  Menu,
  MenuItem,
  Button,
  PaperProps,
} from '@mui/material'
import { Link } from 'react-router-dom'
import tokens from '../../../styles/tokens.json'
import CircularProgress from '../../../rsmCoreComponents/components/RsmCircularProgress'
import { Arrow, styles, Popper } from './ToolbarNotifyMenuStyles'
import { EllipsisIcon } from '../../icons'
import {
  getNotificationCount,
  setNewNotificationCount,
} from '../../../store/notification/notificationSlice'
import api from '../../../api'

const AutoFocusContainer = ({
  preventFocus,
  children,
  ...props
}: PaperProps & { preventFocus: boolean }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [focused, setFocused] = useState<boolean>(false)

  useEffect(() => {
    if (!focused && !preventFocus) {
      containerRef.current?.focus()
      setFocused(true)
    }
    return () => {
      setFocused(true)
    }
  })

  return (
    <Paper
      onClick={() => {
        containerRef.current?.focus()
        setFocused(true)
      }}
      tabIndex={0}
      ref={containerRef}
      {...props}>
      {children}
    </Paper>
  )
}

const AutoFocusListItem = ({
  active,
  notification,
  lastViewed,
  onSelected,
  onOptionsClick,
  onFocus,
  testId = undefined,
}: {
  active: boolean
  notification: NotificationViewModel
  lastViewed: Date
  onSelected: (notification: NotificationViewModel) => void
  onOptionsClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  onFocus: (event: any) => void
  testId?: string
}) => {
  const { t } = useTranslation()
  const linkRef = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    if (active) {
      linkRef.current?.focus()
    }
    return () => {
      linkRef.current?.blur()
    }
  })

  if (!notification.notificationContext) {
    return null
  }

  const article = JSON.parse(notification.notificationContext)

  return (
    <Box
      sx={styles.liContainer}
      component="li"
      style={{
        fontWeight: lastViewed <= notification.createdDate ? 'bold' : 'normal',
        backgroundColor: notification.clickedDate
          ? 'white'
          : 'rgba(217, 217, 217, 0.35)',
      }}
      data-testid={testId ?? 'Sec_ECP_ListItem'}>
      {!notification.clickedDate && <Box sx={styles.dot} />}

      <Link
        to={`/insights/${article.ArticleId}`}
        id={`NotificationLink${notification.notificationId}`}
        ref={linkRef}
        onFocus={onFocus}
        onClick={() => onSelected(notification)}
        data-testid="Txt_Shared_Article">
        <>
          {!notification.clickedDate && (
            <span className="sr-only">
              {lastViewed <= notification.createdDate ? 'New' : 'Unread'}
            </span>
          )}
          <Box sx={styles.textContent} component="span">
            <Trans
              i18nKey={`${notification.titleToken}`}
              values={{
                FirstName: article?.FirstName,
                LastName: article?.LastName,
                ArticleName: article?.ArticleName,
              }}
              components={[
                <Box
                  component="span"
                  sx={{
                    color: tokens.colors.rsmGreen.primary,
                    textDecoration: 'underline',
                  }}
                  data-testid="Lnk_Shared_Article"
                />,
              ]}
            />
          </Box>
          <Box
            sx={styles.timeAgo}
            component="span"
            data-testid="Txt_Notification_HumanReadableCreatedDate">
            {t('NotificationTemplates.DateAgo', {
              date: notification.createdDate,
            })}
          </Box>
        </>
      </Link>
      <Button
        type="button"
        aria-describedby={`NotificationLink${notification.notificationId}`}
        id={`deleteButton${notification.notificationId}`}
        aria-label="notification options"
        data-testid="Btn_Notification_Delete"
        aria-haspopup="menu"
        disableRipple
        onClick={onOptionsClick}
        sx={{
          minHeight: '2.75rem',
          minWidth: '2.75rem',
          justifyContent: 'center',
          alignSelf: 'center',
        }}>
        <EllipsisIcon />
      </Button>
    </Box>
  )
}

export const ToolBarNotifyMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [arrowRef, setArrowRef] = useState<any>()
  const [notificationList, setNotificationList] =
    useState<NotificationUserViewModel>()
  const [isNotificationLoading, setIsNotificationLoading] =
    useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMoreOptions = Boolean(anchorEl)
  const [selectedNotificationIndex, setSelectedNotificationIndex] =
    useState<number>()
  const [updateTrigger, setUpdateTrigger] = useState<number>()
  const notificationCount = useSelector(getNotificationCount)
  const iconButtonRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const selectedNotification = useMemo(() => {
    const list = notificationList?.notifications || []
    if (!list.length) return undefined

    return list[selectedNotificationIndex || 0]
  }, [selectedNotificationIndex, updateTrigger])

  // Get/Set notification count
  const setNoteCount = async () => {
    const response =
      await api.communication.notification_GetNewNotificationCount()
    const count = response?.data || 0
    dispatch(setNewNotificationCount(count))
  }

  // Reset notification count
  const resetNoteCount = async () => {
    if (notificationCount && notificationCount > 0) {
      await api.communication.notification_ResetLastViewed()
      dispatch(setNewNotificationCount(0))
    }
  }

  // Get list of all notifications
  const getAllNotificationList = async () => {
    setIsNotificationLoading(true)
    try {
      const notificationResponse =
        await api.communication.notification_GetNotifications()
      if (notificationResponse?.data) {
        setNotificationList(notificationResponse?.data)
      }
    } finally {
      setIsNotificationLoading(false)
    }
  }

  const handleToggle = () => {
    if (!isOpen) {
      resetNoteCount()
    }

    if (isOpen) {
      setSelectedNotificationIndex(undefined)
    }

    setIsOpen((prevOpen) => !prevOpen)
  }

  const handleSelectedNotification = async (
    notification: NotificationViewModel,
    index: number,
  ) => {
    const { notificationId, clickedDate } = notification

    setSelectedNotificationIndex(index)
    handleToggle()
    if (!clickedDate) {
      setIsNotificationLoading(true)
      try {
        await api.communication.notification_SetReadNotification(notificationId)
      } finally {
        setIsNotificationLoading(false)
      }
    }
  }

  const handleMenuListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setIsOpen((prevOpen) => !prevOpen)
    } else if (event.key === 'Escape') {
      event.preventDefault()
      setIsOpen(false)
    }
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (iconButtonRef.current?.contains(event.target as HTMLElement)) {
      return
    }
    setSelectedNotificationIndex(undefined)
    setIsOpen(false)
  }

  const handleMoreOptionsClick = (
    notificationIndex: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreOptionsClose = () => {
    setAnchorEl(null)
  }

  const handleDeleteClick = async () => {
    setAnchorEl(null)

    const currentIndex = selectedNotificationIndex || 0

    if (notificationList?.notifications?.length) {
      const currentlySelected = notificationList.notifications[currentIndex]
      if (currentlySelected) {
        const result = await api.communication.notification_DeleteNotification(
          currentlySelected.notificationId,
        )

        if (result) {
          if (notificationList && currentlySelected.notificationId > 0) {
            const filteredNotifications =
              notificationList?.notifications?.filter(
                (x) => x.notificationId !== currentlySelected.notificationId,
              )

            setNotificationList({
              lastViewed: notificationList.lastViewed,
              userId: notificationList.userId,
              notifications: filteredNotifications,
            })

            // calculate new index
            const notifications = filteredNotifications || []
            const isOutOfBounds = currentIndex >= notifications.length

            if (!notifications.length) {
              // the list is empty
              setSelectedNotificationIndex(undefined)
            } else if (isOutOfBounds) {
              // if the notification deleted was the last we need to move the index back
              const newIndex = currentIndex - 1
              // if the las notification is the only notification then we will clear the selected index
              setSelectedNotificationIndex(newIndex < 0 ? undefined : newIndex)
            } else if (currentIndex === selectedNotificationIndex) {
              // if we removed a notification, the index remains unchanged, in this case we force the memoized value for the selectedItem to be updated
              // with the new notification now at the same index
              setUpdateTrigger(new Date().getMilliseconds())
            } else {
              // default
              setSelectedNotificationIndex(currentIndex)
            }
          }
        }
      }
    }
  }

  const prevOpen = useRef(isOpen)

  // Get the notification count and display it on the bell icon
  useEffect(() => {
    setNoteCount()
  }, [])

  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      iconButtonRef.current?.focus()
    }

    prevOpen.current = isOpen

    if (isOpen) {
      getAllNotificationList()
    }
  }, [isOpen])

  const id = isOpen ? 'notification-popover' : null

  const notificationsLabel = (count: number) => {
    if (!isOpen) {
      if (count === 0)
        return `${t('Open')} ${t('NotificationTemplates.NotificationsTitle')}`
      if (count > 99)
        return `${t('Open')} ${t(
          'NotificationTemplates.NotificationsTitle',
        )}, 99+`
      return `${t('Open')} ${t('NotificationTemplates.NotificationsLabel', {
        count,
      })}`
    }
    return `${t('Close')} ${t('NotificationTemplates.NotificationsTitle')}`
  }

  const renderNotification = () => {
    if (isNotificationLoading) {
      return (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )
    }
    if (!notificationList || notificationList?.notifications?.length === 0) {
      return (
        <Box sx={styles.noNotificationContainer}>
          <Typography>{t('NotificationTemplates.NoMessages')}</Typography>
        </Box>
      )
    }
    return notificationList?.notifications?.map(
      (notification: NotificationViewModel, notificationIndex: number) => (
        <AutoFocusListItem
          key={notification.notificationId}
          notification={notification}
          lastViewed={notificationList?.lastViewed}
          active={notificationIndex === selectedNotificationIndex}
          onSelected={() =>
            handleSelectedNotification(notification, notificationIndex)
          }
          testId={`Sec_Notification_NotificationContainer_${notificationIndex}`}
          onFocus={() => setSelectedNotificationIndex(notificationIndex)}
          onOptionsClick={(e) => handleMoreOptionsClick(notificationIndex, e)}
        />
      ),
    )
  }

  const isNotificationPresent =
    (notificationList?.notifications?.length || 0) > 0

  return (
    <>
      <Backdrop
        aria-hidden={isOpen}
        open={isOpen}
        onClick={handleToggle}
        sx={styles.backdrop}
      />
      <Stack direction="row" spacing={2} sx={styles.stack}>
        <div>
          <FocusTrap open={isOpen}>
            <div>
              <IconButton
                sx={styles.iconButton}
                role="button"
                aria-label={notificationsLabel(notificationCount || 0)}
                data-testid="Btn_Header_Notifications"
                ref={iconButtonRef}
                onClick={handleToggle}
                onKeyDown={handleMenuListKeyDown}
                disableRipple>
                <Badge
                  badgeContent={notificationCount}
                  overlap="circular"
                  color="error"
                  aria-hidden="true"
                  data-testid="badge-container">
                  <FontAwesomeIcon
                    style={{ width: '1.375rem' }}
                    color="white"
                    icon={faBell}
                  />
                </Badge>
              </IconButton>
              <Popper
                id={id || ''}
                style={{
                  zIndex: 1000,
                }}
                anchorEl={iconButtonRef.current}
                modifiers={[
                  {
                    name: 'arrow',
                    enabled: true,
                    options: {
                      element: arrowRef,
                    },
                  },
                ]}
                role="dialog"
                aria-label={t('NotificationTemplates.NotificationsTitle')}
                open={isOpen}
                placement="bottom"
                transition
                disablePortal>
                {({ TransitionProps }) => (
                  <Grow
                    in={TransitionProps?.in}
                    onEnter={TransitionProps?.onEnter}
                    onExited={TransitionProps?.onExited}>
                    <div>
                      <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
                      <AutoFocusContainer
                        elevation={1}
                        sx={styles.paper}
                        preventFocus={
                          selectedNotificationIndex !== null &&
                          selectedNotificationIndex !== undefined
                        }>
                        <ClickAwayListener onClickAway={handleClose}>
                          <>
                            <Box
                              sx={
                                isNotificationPresent
                                  ? styles.paperContent
                                  : styles.paperContentNoNotifications
                              }
                              data-testid="Sec_Notification_Popover">
                              <Box fontWeight="bold" component="h1">
                                {t('NotificationTemplates.NotificationsTitle')}
                              </Box>
                              <Box
                                sx={styles.innerContent}
                                component={
                                  isNotificationPresent ? 'ul' : 'div'
                                }>
                                {renderNotification()}
                              </Box>
                            </Box>
                            <Menu
                              id="options-menu"
                              anchorEl={anchorEl}
                              open={openMoreOptions}
                              onClose={handleMoreOptionsClose}
                              disableScrollLock
                              MenuListProps={{
                                'aria-labelledby': `deleteButton${selectedNotification?.notificationId}`,
                              }}>
                              <MenuItem onClick={handleDeleteClick}>
                                {t('NotificationTemplates.DeleteNotification')}
                              </MenuItem>
                            </Menu>
                            {isNotificationPresent && (
                              <Button
                                sx={{ fontSize: '.95rem' }}
                                onClick={handleClose}
                                variant="text">
                                {t('Close')}
                              </Button>
                            )}
                          </>
                        </ClickAwayListener>
                      </AutoFocusContainer>
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          </FocusTrap>
        </div>
      </Stack>
    </>
  )
}
export default ToolBarNotifyMenu
