import React from 'react'

const CapitolIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.2598 12.6691C39.5798 11.6491 42.4198 11.7991 45.1698 12.5491"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M36.3105 19.8108C39.4805 18.6108 42.4005 18.7108 45.1705 19.8908"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.9805 25.2593C38.1505 24.0593 43.2405 24.2293 46.4605 25.2593"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M38.2899 24.4199C27.3099 28.8499 26.6699 45.0399 26.6699 45.0399"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M43.1797 24.4199C54.1597 28.8499 54.7997 45.0399 54.7997 45.0399"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.6495 24.4199C34.8095 28.7299 34.5195 44.4899 34.5195 44.4899"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.8105 24.4199C46.6505 28.7299 46.9405 44.4899 46.9405 44.4899"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.3809 12.0195V18.4795"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.0703 11.9902V19.0102"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M40.7402 0V9.27"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.9297 46.47C30.2197 43.29 52.1097 43.35 61.3097 46.55"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.6191 58.7505C33.5591 53.9405 55.7091 55.2905 65.5991 58.7505"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.6191 52.8601C27.7191 46.9701 55.5591 47.5901 65.5991 52.8601"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M62.8809 51.5898V57.9298"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M60.5605 50.9609V57.4209"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.9395 50.3203V56.9003"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M54.9492 49.7598V56.4298"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.0605 49.1191V55.9891"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.9395 48.8691V55.7691"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.8008 48.6699V55.6399"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M38.6504 48.6699V55.6599"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.5195 48.8594V55.7694"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30.3906 49.2109V56.1609"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M26.6602 49.7109V56.6109"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.5098 50.3008V57.0608"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M20.7598 50.9707V57.5607"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M18.5703 51.7207V58.0207"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.3301 66.5203C21.6401 58.9903 60.5601 59.3103 70.1601 66.5203"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.3301 78.8499C22.7501 72.9099 59.3301 73.3599 69.8101 78.6899"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M14.0098 65V77.61"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M16.8594 63.9199V76.9399"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M20.0098 63.0508V76.3908"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.7891 62.2793V75.4693"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M28.2891 61.6504V75.0304"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M33.25 61.2207V74.5407"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M38.2305 60.8809V74.5409"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M43.2207 60.9902V74.3402"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M48.1992 61.3203V74.5403"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M53.1602 61.6504V75.0304"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.6602 62.3008V75.6008"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M61.4395 63.0508V76.1908"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M64.5898 63.9902V76.9402"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M67.4395 65.1191V77.7991"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M70.1605 79.9504V61.3204C70.1605 61.3204 67.5705 59.2804 65.6005 58.7504V48.5904C65.6005 48.5904 63.5305 47.3004 61.4405 46.6104C61.4405 46.6104 61.8805 30.0904 46.4605 25.2504V20.8604C46.3905 20.4104 45.1605 19.8904 45.1605 19.8904V12.5404L46.1905 12.8204L46.1705 10.4204C46.1705 10.4204 44.7405 8.89044 40.7105 8.90044C36.6905 8.92044 35.2505 10.1204 35.2505 10.1204V12.7704L36.2405 12.6604L36.2905 19.8004C36.2905 19.8004 35.3505 20.3404 34.9705 20.8504V25.2404C19.7805 30.7104 19.9205 46.4504 19.9205 46.4504C17.7305 47.1404 15.6005 48.5704 15.6005 48.5704V58.7304C13.2405 59.4804 11.3105 61.3504 11.3105 61.3504V79.9804"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default CapitolIcon
