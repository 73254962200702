import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface UserSettingsState {
  highContrast: boolean
}

const initialState: UserSettingsState = {
  highContrast: false,
}

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setHighContrast: (state, { payload }) => {
      state.highContrast = payload
    },
  },
})

export const getHighContrast = (state: RootState) =>
  state.userSettings.highContrast

export const { setHighContrast } = userSettingsSlice.actions
export default userSettingsSlice.reducer
