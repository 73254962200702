import React, { useState } from 'react'
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PayerPaymentAccountViewModel } from '@rsmus/ecp-financeservice'
import api from '../../../api'
import RsmDialog from '../../overlay/RsmDialog'
import { Styles } from '../../../types'

export type PaymentMethod = PayerPaymentAccountViewModel & {
  isBankAccount: boolean
  index: number
}

interface ConfirmPaymentDeleteProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  onError: () => void
  paymentMethod: PaymentMethod
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxHeight: '100%',
    height: '100%',
    border: '0',
    padding: '0.5rem',
  },
  acceptWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    border: '0',
  },
  mobileDialogModal: (theme) => ({
    '& .MuiDialog-paper': {
      maxWidth: '40rem',
      maxHeight: '15.375rem',
      [theme.breakpoints.only('mobile')]: {
        position: 'fixed',
        top: '0rem',
        right: '0rem',
        bottom: 'env(safe-area-inset-bottom)',
        left: '0rem',
        margin: '0rem',
        width: '100%',
        maxWidth: '100%',
        maxHeight: 'calc(100% - env(safe-area-inset-bottom))',
      },
    },
  }),
}

const ConfirmPaymentMethodDelete = ({
  open,
  onClose,
  onSuccess,
  onError,
  paymentMethod,
}: ConfirmPaymentDeleteProps) => {
  const { t } = useTranslation()
  const [confirmDisabled, setConfirmDisabled] = useState(false)

  const handleConfirmDelete = async () => {
    try {
      setConfirmDisabled(true)
      const response = await api.finance.payment_DeletePaymentMethod(
        paymentMethod.id,
      )

      if (response.data?.deleteSuccessful) {
        onClose()
        onSuccess()
      }
    } catch (e: any) {
      onError()
    } finally {
      setConfirmDisabled(false)
    }
  }

  return (
    <RsmDialog
      isOpen={open}
      closeHandler={onClose}
      dialogCustomProps={{
        sx: styles.mobileDialogModal,
        'aria-label': 'Delete payment method',
      }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.dialogContent}>
              <Typography variant="body1">
                {paymentMethod.isBankAccount
                  ? t(
                      'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.DeleteBankAccountReview',
                      {
                        accountNumber: paymentMethod.accountNumberSuffix,
                      },
                    )
                  : t(
                      'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.DeleteCreditCardReview',
                      {
                        cardType: paymentMethod.institutionName,
                        cardNumber: paymentMethod.accountNumberSuffix,
                      },
                    )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.acceptWrapper}>
              <Stack sx={styles.acceptWrapper}>
                <Button variant="outlined" onClick={onClose}>
                  {t(
                    'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.Cancel',
                  )}
                </Button>
                <Button
                  disabled={confirmDisabled}
                  variant="contained"
                  onClick={handleConfirmDelete}>
                  {t(
                    'Invoicing.PaymentManagement.ConfirmDeletePaymentMethod.Proceed',
                  )}
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </RsmDialog>
  )
}

export default ConfirmPaymentMethodDelete
