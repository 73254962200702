/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import {
  DividerProps,
  Divider,
  Stack,
  Typography,
  Box,
  styled,
} from '@mui/material'
import { t } from 'i18next'

const BlueLine = styled((props: DividerProps) => (
  <Divider variant="fullWidth" {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginTop: '0.75rem',
  lineHeight: '1.125rem',
}))

const Header = () => (
  <>
    <Stack sx={{ marginTop: '2.5rem' }} direction="row" spacing={1}>
      <Typography
        variant="h5"
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '0.875rem',
          fontFamily: 'Prelo-Bold, sans-serif',
          textTransform: 'uppercase',
        }}
        component="h1"
        data-testid="Hed_Projects_ProjectsTitle">
        {t('Projects.Title')}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <BlueLine />
      </Box>
    </Stack>

    <Typography
      variant="body1"
      component="p"
      sx={{ marginTop: '1.75rem', marginBottom: '1.5rem' }}
      data-testid="Hed_Projects_ProjectsSubtitle">
      {t('Projects.HeaderMessage')}
    </Typography>
  </>
)

export default Header
