import React, { useCallback, useEffect, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormikContext } from 'formik'
import FormControl from '@mui/material/FormControl'
import { Typography } from '@mui/material'
import { t } from 'i18next'
import {
  useGetContactMethodsListQuery,
  useGetNotificationFrequenciesListQuery,
} from '../../../../../store/userService'
import { FormValues } from '../../../../../rsmCoreComponents/components/DynamicForm/models'
import { useAppDispatch } from '../../../../../utils/hooks'
import { setSelectedContactMethod } from '../../../../../store/profile/profileFormSlice'

const ContactMethodRadioGroup = (
  props: RadioGroupProps & {
    name: string
  },
) => {
  const { name, ...rest } = props
  /* eslint-disable react/jsx-props-no-spreading */
  const { values, setFieldValue, handleChange } = useFormikContext<FormValues>()
  const { data } = useGetContactMethodsListQuery()
  const notificationFrequenciesData =
    useGetNotificationFrequenciesListQuery()?.data?.data

  const dispatch = useAppDispatch()
  const [selectedValue, setSelectedValue] = useState<string>(
    (values[name] as string) ?? '1',
  )
  const [entryValues, setEntryValues] = useState<FormValues>(values)

  useEffect(() => {
    setSelectedValue(values[name].toString())
    dispatch(setSelectedContactMethod(selectedValue.toString()))
  }, [values, selectedValue])

  useEffect(() => {
    setEntryValues(JSON.parse(JSON.stringify(values)))
  }, [])

  const handleRadioChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (entryValues.preferredContactMethod.toString() === e.target.value) {
        setFieldValue(
          'engagementActivityNotificationFrequency',
          entryValues.engagementActivityNotificationFrequency,
        )
        setFieldValue(
          'insightsActivityNotificationFrequency',
          entryValues.insightsActivityNotificationFrequency,
        )
      } else {
        const filteredNotificationFrequencies =
          notificationFrequenciesData?.filter(
            (item) => item?.contactMethodId?.toString() === e.target.value,
          )
        const frequencyId: number | undefined = filteredNotificationFrequencies
          ? filteredNotificationFrequencies?.reduce(
              (min, currentItem) =>
                Math.min(Number(min), Number(currentItem.id)),
              filteredNotificationFrequencies[0].id,
            )
          : 1

        setFieldValue('engagementActivityNotificationFrequency', frequencyId)
        setFieldValue('insightsActivityNotificationFrequency', frequencyId)
      }
    },
    [setFieldValue, notificationFrequenciesData, entryValues],
  )

  return (
    <FormControl>
      <RadioGroup
        {...rest}
        sx={{ marginX: '0.5rem' }}
        row
        aria-labelledby="contact-methods-group-label"
        name={name}
        onChange={(e) => {
          handleRadioChange(e)
          handleChange(e)
        }}
        value={selectedValue}>
        {data?.data.map((item) => (
          <FormControlLabel
            key={item.id}
            sx={{ mr: 2 }}
            value={item.id}
            control={<Radio />}
            label={
              <Typography>
                {`${t(
                  `UserProfile.ContactPreferenceForm.ContactMethods.${item.contactMedium}`,
                )}`}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default ContactMethodRadioGroup
