import React from 'react'

const BankIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6833_16798)">
      <path
        d="M24.6123 7.04968C24.2523 6.82768 23.8143 6.66568 23.3943 6.59368C22.2903 6.41368 21.0303 6.94768 21.1203 8.15968C21.2103 9.38968 22.6023 9.35968 23.5683 9.55768C24.1443 9.67168 24.7383 9.77968 25.0443 10.3497C25.3983 11.0097 24.9483 11.8437 24.3243 12.1437C23.9643 12.3177 23.5023 12.3957 23.1003 12.3957C22.3923 12.3957 21.3423 12.1977 20.7783 11.7717"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23.0947 5.50195V13.38"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M43.6141 41.7725H4.03809V44.5865H43.6141V41.7725Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.1099 18.9722C7.0919 18.4982 7.6199 18.1982 7.9919 18.4622C8.4599 18.7922 8.5679 19.4342 8.2379 19.9022C7.8239 20.4842 7.0199 20.6222 6.4379 20.2142C5.7119 19.6982 5.5379 18.6902 6.0539 17.9642C6.3539 17.5382 6.7859 17.2682 7.2539 17.1602H11.9699C12.4379 17.2682 12.8699 17.5382 13.1699 17.9642C13.6859 18.6902 13.5119 19.6982 12.7859 20.2142C12.2039 20.6282 11.5139 20.5982 10.9199 20.0522C10.4999 19.6682 10.6199 18.9182 11.0879 18.5882C11.4599 18.3242 12.2279 18.2702 12.2279 19.0622"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.42773 39.5941V20.4961"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.79 20.4961V39.5941"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.6123 21.4326V37.8666"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.3916 39.5938H5.80762V41.7718H13.3916V39.5938Z"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.5 15.6001L23.232 0.996094L41.958 15.6001H4.5Z"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.92383 13.3078L23.1178 3.3418L36.2398 13.3078"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.624 44.5859H2.04004V47.3999H45.624V44.5859Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6138 18.9722C16.5958 18.4982 17.1238 18.1982 17.4958 18.4622C17.9638 18.7922 18.0718 19.4342 17.7418 19.9022C17.3278 20.4842 16.5238 20.6222 15.9418 20.2142C15.2158 19.6982 15.0418 18.6902 15.5578 17.9642C15.8578 17.5382 16.2898 17.2682 16.7578 17.1602H21.4738C21.9418 17.2682 22.3738 17.5382 22.6738 17.9642C23.1898 18.6902 23.0158 19.6982 22.2898 20.2142C21.7078 20.6282 21.0178 20.5982 20.4238 20.0522C20.0038 19.6682 20.1238 18.9182 20.5918 18.5882C20.9638 18.3242 21.7318 18.2702 21.7318 19.0622"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.9375 39.5941V20.4961"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.2998 20.4961V39.5941"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M19.1221 21.4326V37.8666"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M22.9014 39.5938H15.3174V41.7718H22.9014V39.5938Z"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.8003 18.9722C25.7823 18.4982 26.3103 18.1982 26.6823 18.4622C27.1503 18.7922 27.2583 19.4342 26.9283 19.9022C26.5143 20.4842 25.7103 20.6222 25.1283 20.2142C24.4023 19.6982 24.2283 18.6902 24.7443 17.9642C25.0443 17.5382 25.4763 17.2682 25.9443 17.1602H30.6603C31.1283 17.2682 31.5603 17.5382 31.8603 17.9642C32.3763 18.6902 32.2023 19.6982 31.4763 20.2142C30.8943 20.6282 30.2043 20.5982 29.6103 20.0522C29.1903 19.6682 29.3103 18.9182 29.7783 18.5882C30.1503 18.3242 30.9183 18.2702 30.9183 19.0622"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.124 39.5941V20.4961"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M30.4863 20.4961V39.5941"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.3027 21.4326V37.8666"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.082 39.5938H24.498V41.7718H32.082V39.5938Z"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M35.3101 18.9722C35.2921 18.4982 35.8201 18.1982 36.1921 18.4622C36.6601 18.7922 36.7681 19.4342 36.4381 19.9022C36.0241 20.4842 35.2201 20.6222 34.6381 20.2142C33.9121 19.6982 33.7381 18.6902 34.2541 17.9642C34.5541 17.5382 34.9861 17.2682 35.4541 17.1602H40.1701C40.6381 17.2682 41.0701 17.5382 41.3701 17.9642C41.8861 18.6902 41.7121 19.6982 40.9861 20.2142C40.4041 20.6282 39.7141 20.5982 39.1201 20.0522C38.7001 19.6682 38.8201 18.9182 39.2881 18.5882C39.6601 18.3242 40.4281 18.2702 40.4281 19.0622"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M35.6338 39.5941V20.4961"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M39.9961 20.4961V39.5941"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.8125 21.4326V37.8666"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.5918 39.5938H34.0078V41.7718H41.5918V39.5938Z"
        stroke="#9FA2A3"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6833_16798">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BankIcon
