import { TeamDocsSiteViewModel } from '@rsmus/ecp-userservice'
import React, { Dispatch } from 'react'
import { Actions } from './actions'

export interface ProjectsState {
  projects: TeamDocsSiteViewModel[]
  filter?: string
  displayedCount?: number
  pageSize?: number
  page?: number
  currentPageStartIndex?: number
}

export const ProjectsContext = React.createContext<{
  state: ProjectsState
  dispatch: Dispatch<Actions>
}>({
  state: { projects: [] },
  dispatch: () => {},
})
