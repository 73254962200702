import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import {
  FileResponse,
  ReferenceDocumentTypeEnum,
} from '@rsmus/ecp-financeservice'
import SectionHeader from '../../layouts/SectionHeader'
import { Styles } from '../../../types'
import { tokens } from '../../../styles/materialTheme'
import CaretLink from '../../Navigation/CaretLink'
import { PDF } from '../../icons/FileTypeIcons'
import api from '../../../api'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'

const styles: Styles = {
  cardContainer: (theme) => ({
    background: theme.palette.common.white,
    border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
  }),
  cells: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column',
    },
  }),
  cell: (theme) => ({
    flex: '1 1 50%',
    [theme.breakpoints.up('desktop')]: {
      '&:first-of-type': {
        flex: '1 1 35%',
      },
      '&:last-of-type': {
        flex: '1 1 65%',
      },
    },
    [theme.breakpoints.down('tablet')]: {
      flex: 'none',
    },
  }),
  cellTitle: {
    fontWeight: 800,
    marginBottom: '0.5rem',
  },
  file: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    marginTop: '1rem',
  },
  downloadFaq: {
    fontSize: '1rem',
    height: '2.2rem',
    marginBottom: 0,
    ':hover': { cursor: 'pointer' },
  },
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '800',
  textDecoration: 'underline',
}))

const AccountReferenceDocuments = () => {
  const { t } = useTranslation()

  const handleClick = async (refDoc: ReferenceDocumentTypeEnum) => {
    const response =
      await api.finance.invoiceDocument_DownloadReferenceDocument(refDoc)
    downloadFileStream(response)
  }

  const handleDownloadFaq = useCallback(async () => {
    const response: FileResponse =
      await api.finance.invoiceDocument_DownloadInvoicingFAQ()
    downloadFileStream(response)
  }, [api.finance.invoiceDocument_DownloadInvoicingFAQ, downloadFileStream])

  return (
    <Box sx={styles.cardContainer}>
      <SectionHeader
        title={t('Invoicing.ReferenceDocuments')}
        testId="Lbl_AccountReferenceDocuments_Header"
      />
      <Box sx={styles.cells}>
        <Box sx={styles.cell}>
          <Box component="h3" sx={styles.cellTitle}>
            {t('Invoicing.AccountReferenceDocuments.FaqTitle')}
          </Box>
          <Box sx={styles.description}>
            {t('Invoicing.AccountReferenceDocuments.FaqDescription')}
          </Box>
          <Box sx={styles.file}>
            <PDF />
            <CaretLink
              onClick={handleDownloadFaq}
              sx={styles.downloadFaq}
              aria-label={t(
                'Invoicing.AccountReferenceDocuments.FaqButtonText',
              )}
              data-testid="PayMyBillDashboard_FaqSection_ReadFAQ_PDF">
              {t('Invoicing.AccountReferenceDocuments.FaqButtonText')}
            </CaretLink>
          </Box>
        </Box>{' '}
        <Box sx={styles.cell}>
          <Box component="h3" sx={styles.cellTitle}>
            {t('Invoicing.AccountReferenceDocuments.AddlDocsTitle')}
          </Box>
          <Box sx={styles.file}>
            <PDF />
            <StyledLink
              to="#ref"
              onClick={(e) => {
                handleClick(
                  ReferenceDocumentTypeEnum.ConsentToElectronicCommunications,
                )
                e.preventDefault()
              }}
              data-testid="PayMyBillDashboard_AdditionalDocumentationSection_ConsentToElectronicCommunications_PDF">
              {t('Invoicing.AccountReferenceDocuments.FirstTermsAndConditions')}
            </StyledLink>
          </Box>
          <Box sx={styles.file}>
            <PDF />
            <StyledLink
              to="#ref"
              onClick={(e) => {
                handleClick(ReferenceDocumentTypeEnum.OneTimePaymentUSAndCanada)
                e.preventDefault()
              }}
              data-testid="PayMyBillDashboard_AdditionalDocumentationSection_OneTimePaymentUSAndCanada_PDF">
              {t('Invoicing.AccountReferenceDocuments.OneTimePayment')}
            </StyledLink>
          </Box>
          <Box sx={styles.file}>
            <PDF />
            <StyledLink
              to="#ref"
              onClick={(e) => {
                handleClick(
                  ReferenceDocumentTypeEnum.AutomaticPaymentPlanRecurringPaymentUS,
                )
                e.preventDefault()
              }}
              data-testid="PayMyBillDashboard_AdditionalDocumentationSection_AutomaticPaymentPlanRecurringPaymentUS_PDF">
              {t('Invoicing.AccountReferenceDocuments.RecurringPayment')}
            </StyledLink>
          </Box>
          <Box sx={styles.file}>
            <PDF />
            <StyledLink
              to="#ref"
              onClick={(e) => {
                handleClick(
                  ReferenceDocumentTypeEnum.BusinessPreAuthorizedDebitAgreementCanada,
                )
                e.preventDefault()
              }}
              data-testid="PayMyBillDashboard_AdditionalDocumentationSection_BusinessPreAuthorizedDebitAgreementCanada_PDF">
              {t('Invoicing.AccountReferenceDocuments.PADAgreementCanada')}
            </StyledLink>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountReferenceDocuments
