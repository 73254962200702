import React from 'react'

const ReadingIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9099 52.4439H63.9731" stroke="#CCCCCC" />
    <path
      d="M13.2852 33.9558V15.2945C13.2852 14.5797 13.8461 14 14.5387 14H64.6659C65.3584 14 65.9193 14.5797 65.9193 15.2945V35.2417"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.7172 49.2885H18.7499C18.1651 49.2885 17.6914 48.8114 17.6914 48.2232V18.6606C17.6914 18.0723 18.1651 17.5952 18.7499 17.5952H61.0111C61.5959 17.5952 62.0679 18.0723 62.0679 18.6606V48.2232C62.0679 48.8114 61.5959 49.2885 61.0111 49.2885H54.8379H51.7172Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M17.6919 21.2271H61.6135" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M22.6634 27.417H38.0706" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M22.6634 31.0376H38.0706" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M22.6634 34.6582H38.0706" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M41.75 27.417H57.0871" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M41.75 31.0376H57.0871" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M41.75 34.6582H57.0871" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M22.6634 38.2798H38.0706" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M22.6634 41.9009H38.0706" stroke="#CCCCCC" strokeWidth="2" />
    <path
      d="M57.0864 49.2883V38.2793H41.7493V49.2883"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3641 38.6528C16.3641 41.1357 15.8152 45.3286 15.9024 49.5216C15.981 53.3862 16.2581 53.7026 12.9304 58.5368C11.6975 60.3271 10.8271 65.069 10.8271 65.069L2 61.9756C2 61.9756 3.9135 58.2614 3.61083 54.4156C3.30645 50.5698 2.67545 43.1415 4.06911 41.7239C5.46106 40.3063 10.2782 34.2751 10.7912 35.7338C11.3042 37.1907 9.66768 47.5431 9.66768 47.5431C9.66768 47.5431 10.9895 33.9742 13.782 33.8784C14.0521 33.887 14.295 33.9195 14.5121 33.9725C16.7027 34.5145 16.3641 37.1753 16.3641 38.6528Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.6359 39.5868C63.6359 42.0698 64.1849 46.2627 64.0976 50.4557C64.019 54.3203 63.742 54.6366 67.0696 59.4708C68.3026 61.2612 69.173 66.0031 69.173 66.0031L78 62.9097C78 62.9097 76.0865 59.1955 76.3892 55.3497C76.6936 51.5039 77.3246 44.0756 75.9309 42.658C74.539 41.2404 69.7219 35.2092 69.2089 36.6679C68.6959 38.1248 70.3323 48.4789 70.3323 48.4789C70.3323 48.4789 69.0105 34.9083 66.2181 34.8125C65.9479 34.8211 65.7051 34.8535 65.4879 34.9066C63.2974 35.4486 63.6359 38.1094 63.6359 39.5868Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path d="M22.6634 45.7891H38.0706" stroke="#CCCCCC" strokeWidth="2" />
  </svg>
)

export default ReadingIcon
