import React from 'react'

const FinancialTalkBubblesIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.8694 28.48C49.8694 26.34 53.2794 25.24 56.6994 25.06V23.13C56.6994 18.08 52.6094 14 47.5594 14H10.7394C5.68937 14 1.60938 18.09 1.60938 23.13V38.27C1.60938 43.32 5.69938 47.4 10.7394 47.4H13.6194V57.07L19.9794 47.4H39.4394C37.9994 40.4 40.6694 32.89 46.8594 28.47L46.8694 28.48Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.1289 30.3496H44.7189"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.1289 23.8496H48.2689"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.1289 37.0898H39.7389"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M72.8402 32.8409C66.8702 24.4609 55.2402 22.5109 46.8702 28.4809C38.4902 34.4509 36.5402 46.0809 42.5102 54.4509C47.3002 61.1709 55.7402 63.7509 63.2202 61.4209L77.0002 70.7609L72.6702 54.6909C77.3102 48.3809 77.6302 39.5709 72.8402 32.8409Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M61.8305 37.0115C60.8105 36.3715 59.5705 35.9215 58.3805 35.7315C55.2505 35.2215 51.6905 36.7315 51.9405 40.1615C52.2005 43.6415 56.1405 43.5615 58.8705 44.1215C60.5005 44.4515 62.1805 44.7515 63.0605 46.3715C64.0605 48.2415 62.7905 50.6015 61.0205 51.4515C59.9905 51.9415 58.6805 52.1715 57.5505 52.1615C55.5405 52.1515 52.5705 51.6115 50.9805 50.4015"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M57.5312 32.6406V54.9406"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default FinancialTalkBubblesIcon
