import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { v4 as uuidv4 } from 'uuid'

const ArrowDownIcon = (props: SvgIconProps) => {
  const id = uuidv4()
  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <SvgIcon {...props}>
      <path
        d="M10.4189 14.1949L5.74242 9.51847C5.41919 9.19524 5.41919 8.67258 5.74242 8.35279L6.51954 7.57567C6.84276 7.25245 7.36543 7.25245 7.68521 7.57567L11 10.8905L14.3148 7.57567C14.638 7.25245 15.1607 7.25245 15.4805 7.57567L16.2576 8.35279C16.5808 8.67602 16.5808 9.19868 16.2576 9.51847L11.5811 14.1949C11.2648 14.5182 10.7421 14.5182 10.4189 14.1949Z"
        fill="#515356"
      />
      <mask
        id={`mask0_${id}`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="7"
        width="12"
        height="8">
        <path
          d="M10.4189 14.1949L5.74242 9.51847C5.41919 9.19524 5.41919 8.67258 5.74242 8.35279L6.51954 7.57567C6.84276 7.25245 7.36543 7.25245 7.68521 7.57567L11 10.8905L14.3148 7.57567C14.638 7.25245 15.1607 7.25245 15.4805 7.57567L16.2576 8.35279C16.5808 8.67602 16.5808 9.19868 16.2576 9.51847L11.5811 14.1949C11.2648 14.5182 10.7421 14.5182 10.4189 14.1949Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_${id})`} />
    </SvgIcon>
  )
}

export default ArrowDownIcon
