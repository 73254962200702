import React from 'react'

export interface IconProps {
  classNames?: string
}

const PressIcon = ({ classNames = '' }: IconProps) => (
  <svg
    className={classNames}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.8069 34.8388C44.87 32.1587 49.0816 26.0138 48.9988 18.9308C48.8851 9.48042 41.167 1.88669 31.7939 2.00128C22.4227 2.11392 14.8876 9.89409 15.0013 19.3426C15.0841 26.4255 19.4441 32.4655 25.5689 35"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path
      d="M38.9028 27.8414C41.4319 25.8699 43.0423 22.8466 42.9992 19.4758C42.9267 13.6274 37.933 8.92905 31.8658 9.00081C25.8025 9.07068 20.9264 13.8842 21.0008 19.7307C21.042 23.1015 22.7268 26.0871 25.3049 28"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.6296 40.4496C70.6004 38.1398 68.7044 36.2719 66.4152 36.2994L65.3933 36.3131C63.1041 36.3406 61.2529 38.2537 61.2801 40.5635L61.395 50.1917L59.5944 50.2113L59.4795 40.5851C59.4523 38.2753 57.5544 36.4074 55.2652 36.4349L54.2432 36.4486C51.954 36.4761 50.1047 38.3892 50.132 40.699L50.2449 50.3272L48.4462 50.3468L48.3294 40.7206C48.3022 38.4108 46.4062 36.5429 44.117 36.5704L43.0951 36.5822C40.8059 36.6097 38.9547 38.5247 38.9819 40.8345L39.0968 50.4627L37.2962 50.4823L36.9224 19.1505C36.8932 16.8388 34.9972 14.9709 32.708 15.0003L31.6861 15.0102C30.1093 15.0318 28.7409 15.9451 28.0498 17.265C27.7072 17.8856 27.5126 18.5967 27.5223 19.3509L27.6002 25.9464L27.6391 29.1696L27.6761 32.1983L27.7014 34.3018C27.7014 34.4511 27.7131 34.5984 27.7286 34.7418C27.7306 34.7595 27.7364 34.7772 27.7384 34.7948C27.7442 34.84 27.752 34.8832 27.7598 34.9284L28.0304 57.5591L17.5947 47.2788C15.6208 45.3363 12.6114 45.187 10.9081 46.9489L10.149 47.7345C8.44374 49.4983 8.66176 52.529 10.6356 54.4734L23.4033 67.0478C23.5357 67.1794 23.6719 67.2992 23.8121 67.4131C24.6647 68.405 27.3958 71.6832 28.6124 74.3387C29.7512 76.8252 32.1961 79.0369 35.2269 78.9995L64.1455 78.648C67.9608 78.6008 71.0462 75.413 70.9995 71.5614L70.8691 60.5348L70.8262 57.0779L70.6296 40.4496Z"
      stroke="#CECECD"
      strokeWidth="2"
    />
  </svg>
)

export default PressIcon
