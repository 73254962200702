import React from 'react'

const FlagIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.36936 2.90596C7.95172 2.90596 6.7809 2.00015 4.99854 2.00015C4.33836 2.00015 3.74698 2.11432 3.19841 2.31299C3.27444 2.11754 3.30679 1.90826 3.29328 1.6994C3.2464 0.958878 2.62971 0.364893 1.87659 0.334737C1.03021 0.300831 0.333252 0.966196 0.333252 1.79182C0.333252 2.28729 0.584495 2.72476 0.968173 2.98831V13.0418C0.968173 13.387 1.25243 13.6668 1.60309 13.6668H2.02637C2.37704 13.6668 2.66129 13.387 2.66129 13.0418V10.5835C3.41026 10.2693 4.34336 10.0074 5.68868 10.0074C7.10635 10.0074 8.27714 10.9132 10.0595 10.9132C11.3338 10.9132 12.3523 10.4889 13.3004 9.8492C13.5301 9.69422 13.6666 9.4369 13.6666 9.16279V2.83206C13.6666 2.22286 13.0245 1.81982 12.4633 2.07643C11.5549 2.49174 10.4407 2.90596 9.36936 2.90596Z"
      fill="#515356"
    />
  </svg>
)

export default FlagIcon
