import React from 'react'

const GearCircuitryIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M75.6507 36.21V34.66C75.6507 33.37 74.6007 32.32 73.3107 32.32H67.3207C67.0007 30.38 66.4407 28.52 65.6707 26.74L70.6307 24.14C71.8407 23.51 72.2507 21.97 71.5207 20.82L69.7607 18.05C69.1107 17.02 67.7807 16.67 66.7007 17.23L61.3907 20.02C60.0607 18.51 58.5607 17.15 56.9007 15.94L59.7007 11.53C60.4307 10.38 60.0207 8.84 58.8107 8.21L55.5507 6.5C54.4707 5.94 53.1507 6.29 52.4907 7.32L49.4807 12.06C47.5307 11.37 45.4807 10.85 43.3407 10.56V5.34C43.3407 4.05 42.2907 3 41.0007 3H37.1207C35.8307 3 34.7807 4.05 34.7807 5.34V10.56C32.6507 10.85 30.5907 11.36 28.6407 12.06L25.6307 7.32C24.9807 6.29 23.6507 5.94 22.5707 6.5L19.3107 8.21C18.1007 8.85 17.6907 10.38 18.4207 11.53L21.2207 15.94C19.5607 17.14 18.0607 18.51 16.7307 20.02L11.4207 17.23C10.3407 16.67 9.0207 17.02 8.3607 18.05L6.6007 20.82C5.8707 21.97 6.2807 23.51 7.4907 24.14L12.4507 26.74C11.6907 28.51 11.1207 30.38 10.8007 32.32H4.8107C3.5207 32.32 2.4707 33.37 2.4707 34.66V36.21"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M47.5791 22.5708C47.5791 24.6508 45.8891 26.3408 43.8091 26.3408C41.7291 26.3408 40.0391 24.6508 40.0391 22.5708C40.0391 20.4908 41.7291 18.8008 43.8091 18.8008C45.8891 18.8008 47.5791 20.4908 47.5791 22.5708Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M60.3193 31.2798C60.3193 33.3598 58.6293 35.0498 56.5493 35.0498C54.4693 35.0498 52.7793 33.3598 52.7793 31.2798C52.7793 29.1998 54.4693 27.5098 56.5493 27.5098C58.6293 27.5098 60.3193 29.1998 60.3193 31.2798V31.2798Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M73.0791 41.3403C73.0791 43.4203 71.3891 45.1103 69.3091 45.1103C67.2291 45.1103 65.5391 43.4203 65.5391 41.3403C65.5391 39.2603 67.2291 37.5703 69.3091 37.5703C71.3891 37.5703 73.0791 39.2603 73.0791 41.3403Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M27.1396 27.2602C27.1396 29.3402 25.4496 31.0302 23.3696 31.0302C21.2896 31.0302 19.5996 29.3402 19.5996 27.2602C19.5996 25.1802 21.2896 23.4902 23.3696 23.4902C25.4496 23.4902 27.1396 25.1802 27.1396 27.2602Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M12.6806 44.02C12.6806 46.1 10.9906 47.79 8.91062 47.79C6.83063 47.79 5.14062 46.1 5.14062 44.02C5.14062 41.94 6.83063 40.25 8.91062 40.25C10.9906 40.25 12.6806 41.94 12.6806 44.02Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M43.7896 26.3398V38.9398C43.7896 40.2098 43.1796 41.3898 42.1396 42.1298L39.4996 43.9998C38.4696 44.7298 37.8496 45.9198 37.8496 47.1898V76.0098"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M56.5508 35.0508V43.1508C56.5508 44.4208 55.9408 45.6008 54.9008 46.3408L45.4508 53.0308C44.4208 53.7608 43.8008 54.9508 43.8008 56.2208V76.0008"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.3691 31.0293V42.7493C23.3691 44.1393 24.1091 45.4193 25.3091 46.1193L29.9691 48.8393C31.1691 49.5393 31.9091 50.8193 31.9091 52.2093V75.9893"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M69.3098 45.1094V48.8494C69.3098 50.1194 68.6998 51.2994 67.6598 52.0394L51.4098 63.5594C50.3798 64.2894 49.7598 65.4794 49.7598 66.7494V76.0094"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M8.91016 47.7891V52.5391C8.91016 53.9291 9.65016 55.2091 10.8502 55.9091L24.0102 63.5891C25.2102 64.2891 25.9502 65.5691 25.9502 66.9591V75.9991"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default GearCircuitryIcon
