import React from 'react'

const PPSX = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="2" y="12" width="16" height="7" rx="0.8" fill="#E34E26" />
    <path
      d="M5.06978 15.3674H5.67842C5.96992 15.3674 6.11494 15.2231 6.11348 14.9346C6.11348 14.646 5.96846 14.5017 5.67842 14.5017H5.06978V15.3674ZM5.06978 15.9299V17H4.43916V13.9392H5.74434C6.06221 13.9392 6.31123 14.0293 6.49141 14.2095C6.67158 14.3896 6.76167 14.6321 6.76167 14.9368C6.76167 15.2415 6.67158 15.4832 6.49141 15.6619C6.31123 15.8406 6.06221 15.9299 5.74434 15.9299H5.06978ZM8.02407 15.3674H8.63271C8.92422 15.3674 9.06924 15.2231 9.06777 14.9346C9.06777 14.646 8.92275 14.5017 8.63271 14.5017H8.02407V15.3674ZM8.02407 15.9299V17H7.39346V13.9392H8.69863C9.0165 13.9392 9.26553 14.0293 9.4457 14.2095C9.62588 14.3896 9.71597 14.6321 9.71597 14.9368C9.71597 15.2415 9.62588 15.4832 9.4457 15.6619C9.26553 15.8406 9.0165 15.9299 8.69863 15.9299H8.02407ZM11.4793 14.4578C11.1292 14.4578 10.9542 14.561 10.9542 14.7676C10.9542 14.8877 11.0245 14.9785 11.1651 15.04C11.2618 15.0825 11.4222 15.1301 11.6463 15.1829C12.2616 15.3293 12.5692 15.6333 12.5692 16.0947C12.5692 16.4097 12.4615 16.647 12.2462 16.8066C12.0323 16.9648 11.7401 17.0439 11.3695 17.0439C10.9989 17.0439 10.6246 16.9875 10.2467 16.8748L10.35 16.3474C10.7337 16.4368 11.086 16.4814 11.4068 16.4814C11.5592 16.4814 11.6808 16.4514 11.7716 16.3914C11.8624 16.3298 11.9078 16.2537 11.9078 16.1628C11.9078 16.0706 11.8866 16.0061 11.8441 15.9695C11.8016 15.9329 11.7687 15.9065 11.7452 15.8904C11.7071 15.864 11.6251 15.8318 11.4991 15.7937C11.3746 15.7556 11.275 15.7285 11.2003 15.7124C10.5938 15.5381 10.2906 15.2188 10.2906 14.7544C10.2906 14.46 10.3939 14.2432 10.6004 14.104C10.8084 13.9648 11.0809 13.8953 11.4178 13.8953C11.7562 13.8953 12.1144 13.9524 12.4923 14.0667L12.3473 14.5918C11.9635 14.5024 11.6742 14.4578 11.4793 14.4578ZM14.2842 15.9299H14.2754L13.6734 17H12.9703L13.8931 15.4114L13.0208 13.9392H13.7525L14.315 14.895H14.326L14.8687 13.9392H15.607L14.7303 15.4114L15.6883 17H14.9368L14.2842 15.9299Z"
      fill="white"
    />
  </svg>
)

export default PPSX
