import { ActionType, createAction } from 'typesafe-actions'
import { EngagementViewTable } from '../Interfaces/EngagementViewTypes'

export interface GenericObject {
  [key: string]: string | undefined
}

export const updateEmployeeStatus = createAction('IS_EMPLOYEE')<boolean>()

export const pushToDownloadQueue = createAction('PUSH_TO_DOWNLOAD_QUEUE')<{
  engagements: EngagementViewTable[]
  fileName: string
}>()

export const downloadFromQueue = createAction('DOWNLOAD_FROM_QUEUE')<string>()

export type Actions =
  | ActionType<typeof pushToDownloadQueue>
  | ActionType<typeof downloadFromQueue>
  | ActionType<typeof updateEmployeeStatus>
