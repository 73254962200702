import React, { DependencyList, ReactNode, useEffect } from 'react'
import { useElementSize, useEventListener } from 'usehooks-ts'

export interface MeasureChildrenProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: ReactNode
  dependencies?: DependencyList
  onMeasured: (widthPx: number, heightPx: number) => void
}

const Dimensions = ({
  children = undefined,
  onMeasured,
  dependencies = undefined,
  ...rest
}: MeasureChildrenProps) => {
  const [divRef, { width, height }] = useElementSize()

  const handleResize = () => {
    onMeasured(width || 0, height || 0)
  }

  useEventListener('resize', handleResize)
  const deps = dependencies
    ? [...Array.from(dependencies.values()), children, width, height]
    : [children, width, height]
  useEffect(() => handleResize, deps)

  return (
    <div
      ref={divRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}>
      {children}
    </div>
  )
}

export default Dimensions
