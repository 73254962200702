import React from 'react'

export interface TimeProps {
  classNames?: string
  height?: string
  width?: string
}

const TimeIcon = ({
  classNames = '',
  height = '81',
  width = '81',
}: TimeProps) => (
  <svg
    className={classNames}
    width={width}
    height={height}
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.5038 43.1274C39.381 43.1274 40.0076 42.3716 40.0076 41.6158L40.0076 20.4521C40.0076 19.5703 39.381 18.9404 38.5038 18.9404C37.6266 18.9404 37 19.6963 37 20.4521L37 41.6158C37 42.4976 37.6266 43.1274 38.5038 43.1274Z"
      fill="#CECECD"
    />
    <path
      d="M47.777 52.4497C48.1529 52.4497 48.5289 52.3237 48.9048 51.9458C49.5314 51.3159 49.5314 50.4341 48.9048 49.8042L39.8821 40.8601C39.2555 40.2302 38.2529 40.2302 37.7517 40.8601C37.1251 41.49 37.1251 42.4978 37.7517 43.0017L46.6491 51.9458C46.8998 52.1978 47.2757 52.4497 47.777 52.4497Z"
      fill="#CECECD"
    />
    <path
      d="M8.02631 24.0844L9.90606 23.7065C10.7833 23.5805 11.6605 24.2104 11.7858 25.0922C11.9111 25.974 11.2845 26.8558 10.4073 26.9818L8.52758 27.3597C7.65036 27.4857 6.77315 26.8558 6.64783 25.974C6.52251 25.2182 7.02378 24.3363 8.02631 24.0844Z"
      fill="#009CDE"
    />
    <path
      d="M6.64738 24.3364L8.52712 23.9584C9.40434 23.8325 10.2816 24.4623 10.4069 25.3441C10.5322 26.226 9.90561 27.1078 9.02839 27.2338L7.14864 27.6117C6.27143 27.7377 5.39421 27.1078 5.2689 26.226C5.14358 25.3441 5.77016 24.4623 6.64738 24.3364Z"
      fill="#009CDE"
    />
    <path
      d="M9.78068 23.8324L11.6604 23.4545C12.5376 23.3286 13.4149 23.9584 13.5402 24.8402C13.6655 25.7221 13.0389 26.6039 12.1617 26.7299L10.2819 27.1078C9.40473 27.2337 8.52751 26.6039 8.4022 25.7221C8.27688 24.8402 8.90346 23.9584 9.78068 23.8324Z"
      fill="#009CDE"
    />
    <path
      d="M11.0336 23.5805L12.9134 23.2026C13.7906 23.0766 14.6678 23.7065 14.7931 24.5883C14.9184 25.4701 14.2918 26.3519 13.4146 26.4779L11.5349 26.8558C10.6577 26.9818 9.78044 26.3519 9.65513 25.4701C9.52981 24.5883 10.1564 23.7065 11.0336 23.5805Z"
      fill="#009CDE"
    />
    <path
      d="M7.52468 20.4311L7.90063 22.3207C8.02595 23.2025 7.39937 24.0843 6.52215 24.2103C5.64494 24.3363 4.76772 23.7064 4.64241 22.8246L4.26646 20.935C4.14114 20.0531 4.76772 19.1713 5.64494 19.0453C6.52215 18.9194 7.27405 19.5492 7.52468 20.4311Z"
      fill="#009CDE"
    />
    <path
      d="M7.27419 19.0452L7.65014 20.9348C7.77546 21.8166 7.14888 22.6985 6.27166 22.8244C5.39445 22.9504 4.51723 22.3205 4.39192 21.4387L4.01597 19.6751C3.89065 18.7933 4.51723 17.9114 5.39445 17.7855C6.27166 17.5335 7.14888 18.1634 7.27419 19.0452Z"
      fill="#009CDE"
    />
    <path
      d="M7.77566 22.1947L8.15161 24.0843C8.27693 24.9662 7.65034 25.848 6.77313 25.974C5.89591 26.0999 5.0187 25.4701 4.89338 24.5882L4.51743 22.6986C4.39212 21.8168 5.0187 20.935 5.89591 20.809C6.77313 20.683 7.65034 21.3129 7.77566 22.1947Z"
      fill="#009CDE"
    />
    <path
      d="M8.02615 23.5805L8.4021 25.4701C8.52742 26.3519 7.90083 27.2337 7.02362 27.3597C6.1464 27.4857 5.26919 26.8558 5.14387 25.974L4.76792 24.0844C4.6426 23.2026 5.26919 22.3207 6.1464 22.1948C7.02362 22.0688 7.90083 22.6987 8.02615 23.5805Z"
      fill="#009CDE"
    />
    <line
      x1="39"
      y1="76"
      x2="39"
      y2="70"
      stroke="#CECECD"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <line
      x1="39"
      y1="12"
      x2="39"
      y2="6"
      stroke="#CECECD"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <line
      x1="74"
      y1="42"
      x2="68"
      y2="42"
      stroke="#CECECD"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M4.7388 48.2559C6.45614 56.6016 11.078 64.0677 17.7823 69.3262C24.4867 74.5848 32.8389 77.2949 41.3535 76.9745C49.868 76.6542 57.993 73.3242 64.2833 67.5767C70.5735 61.8293 74.6213 54.037 75.7067 45.5858C76.7921 37.1347 74.8447 28.5724 70.211 21.422C65.5772 14.2716 58.5574 8.99649 50.4001 6.53497C42.2428 4.07344 33.4768 4.58505 25.6612 7.97879C17.8457 11.3725 11.4872 17.4284 7.71666 25.0693"
      stroke="#009CDE"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TimeIcon
