import React from 'react'

const DecisionIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.17969 35.3995L15.4897 12.0195L27.7897 35.3995H3.17969Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M53.0508 35.3995L65.3508 12.0195L77.6608 35.3995H53.0508Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M65.3602 9.64062H15.4902V11.8206H65.3602V9.64062Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M42.3995 11.8203H38.4395V64.2703H42.3995V11.8203Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.2496 64.2695H47.5896C47.9496 64.2695 48.2396 64.5595 48.2396 64.9195V66.4795C48.2396 66.8395 47.9496 67.1295 47.5896 67.1295H33.2496C32.8896 67.1295 32.5996 66.8395 32.5996 66.4795V64.9195C32.5996 64.5595 32.8896 64.2695 33.2496 64.2695Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.8301 9.64C36.1601 7.4 37.0601 5 40.4201 5C43.7801 5 44.6801 7.4 45.0101 9.64H35.8301V9.64Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.9803 73.74C52.8403 73.91 52.6203 74 52.3503 74H28.4803C28.2203 74 27.9903 73.91 27.8503 73.74C27.7103 73.57 27.6603 73.33 27.7103 73.08L28.5803 68.69C28.7003 68.06 29.3803 67.5 30.0303 67.5H50.8003C51.4503 67.5 52.1203 68.06 52.2503 68.69L53.1203 73.08C53.1703 73.34 53.1203 73.58 52.9803 73.74Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.4901 49.5895C23.3101 49.5895 29.6401 43.2495 29.6401 35.4395H1.33008C1.33008 43.2595 7.67008 49.5895 15.4801 49.5895H15.4901Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M65.3506 48.2295C58.1506 48.2295 52.2506 42.5495 51.8906 35.4395H78.8006C78.4406 42.5595 72.5406 48.2295 65.3406 48.2295H65.3506Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default DecisionIcon
