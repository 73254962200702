import { Box, IconButton, Popover as MuiPopover } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { dismissPopover, setPopoverContent } from '../../store/form/formSlice'
import { Styles } from '../../types'
import { CloseIcon } from '../icons'
import { popoverComponentFactory } from '../Profile/ProfileModal/ProfileModalContainer'

const styles: Styles = {
  popoverContainer: () => ({
    position: 'fixed',
    top: '-1.4em',
    right: 0,
    bottom: 'env(safe-area-inset-bottom)',
    left: 0,
    height: '100vh',
  }),
  backdrop: {
    height: '100%',
  },
  paper: () => ({
    overflow: 'visible',
    marginTop: 'calc(env(safe-area-inset-top) + 2.5rem)',
    marginRight: '1rem',
    padding: '1rem',
    width: '17.5rem',
    boxShadow: 'none',
    lineHeight: '1.5rem',
    textAlign: 'center',
  }),
  triangle: (theme) => ({
    '&::before': {
      backgroundColor: 'white',
      content: '""',
      display: 'block',
      position: 'absolute',
      width: '1.5rem',
      height: '1.5rem',
      transform: 'rotate(45deg)',
      top: '-0.75rem',
      right: '7.9375rem',
      [theme.breakpoints.down('desktop')]: {
        right: '5.4em',
      },
    },
  }),
  infoIcon: {
    marginBottom: '0.5rem',
  },
  closeButton: (theme) => ({
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    width: '2rem',
    height: '2rem',
    outline: 0,
    fontSize: '1.5rem',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    '& svg': {
      zIndex: 1,
      margin: '0.25rem',
      '& path': {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1)',
      },
    },
    '&:focus-visible': {
      backgroundColor: 'rgba(0, 0, 0, 0.075)',
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      '& svg path': {
        fill: theme.palette.text.primary,
      },
    },
  }),
}

// it is a shame that popover does not handle this or maybe we are using it badly
function removePageBlock() {
  document.body.style.overflow = ''
}

const Popover = () => {
  const { messageType, popOverAnchor, shouldDisplay } = useAppSelector(
    (store) => store.popover,
  )
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(
    () => () => {
      removePageBlock()
    },
    [],
  )

  useEffect(() => {
    if (!shouldDisplay) removePageBlock()
  }, [shouldDisplay])

  useEffect(() => {
    if (shouldDisplay) buttonRef?.current?.focus()
  }, [buttonRef, shouldDisplay])

  if (!messageType) {
    return null
  }

  const handleOnClose = () => {
    removePageBlock()
    dispatch(dismissPopover())
    dispatch(setPopoverContent(undefined))
  }

  return (
    <MuiPopover
      id="popover"
      role="dialog"
      aria-label={t('UserProfile.ProfilePopover.FinishProfile')}
      open={!!shouldDisplay}
      onClose={handleOnClose}
      anchorEl={popOverAnchor}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={styles.popoverContainer}
      slotProps={{
        paper: { sx: styles.paper },
        root: { slotProps: { backdrop: { sx: styles.backdrop } } },
      }}
      data-testid="Sec_ECP_Popover">
      <Box sx={styles.triangle} />
      <IconButton
        role="button"
        size="small"
        sx={styles.closeButton}
        aria-label={t('UserProfile.ProfilePopover.CloseDialog')}
        disableFocusRipple
        disableRipple
        onClick={handleOnClose}
        data-testid="Btn_Popover_Close"
        ref={buttonRef}>
        <CloseIcon />
      </IconButton>
      <Box>{popoverComponentFactory(messageType)}</Box>
    </MuiPopover>
  )
}

export default Popover
