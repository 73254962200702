import api from '..'
import { ArticleMetaData } from '../../utils/helpers/Article.service'

const flagFavorites = async (articles: ArticleMetaData[]) => {
  const favorites = (await api.cms.insights_GetFavoriteArticles())?.data

  articles.forEach((article) => {
    if (favorites?.includes(article?.ecpId ?? '')) {
      // eslint-disable-next-line no-param-reassign
      article.favorite = true
    }
  })

  return articles
}

export default flagFavorites
