import { Box, Button, Grid } from '@mui/material'
import React, { ReactElement, ReactNode, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../types'
import flattenReactFragments from '../../../utils/helpers/flattenReactFragments'
import LessThanIcon from '../../icons/LessThanIcon'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    '& .RsmWizard-stepCounter': {
      fontFamily: 'Prelo-Bold, sans-serif',
      lineHeight: 1,
      textTransform: 'uppercase',
      color: theme.palette.text.primary,
    },
    '& .RsmWizardStep-root': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'auto',
    },
    '& .RsmWizard-controls': {
      display: 'flex',
      padding: '1.5rem 1rem 0',
      width: '100%',
      [theme.breakpoints.up('tablet')]: {
        paddingBottom: '1.5rem',
      },
      '& button': {
        whiteSpace: 'nowrap',
      },
    },
    '& .RsmWizard-controlsGridContainer': {
      alignItems: 'center',
    },
    '& .RsmWizard-backButtonGridItem': {
      [theme.breakpoints.down('tablet')]: {
        position: 'relative',
        order: 2,
        marginTop: '-1rem',
        minHeight: '2.5rem',
      },
    },
    '& .RsmWizard-backButton': {
      padding: '0.5rem',
      lineHeight: '2.25rem',
      color: theme.palette.secondary.main,
      [theme.breakpoints.down('tablet')]: {
        padding: '0.5rem 0',
        margin: '0.25rem 0',
      },
    },
    '& .RsmWizard-backButtonText': {
      marginLeft: '0.5rem',
      fontFamily: 'Prelo-Medium, sans-serif',
      fontSize: '1.125rem',
    },
    '& .RsmWizard-actionButtonsGridItem': {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'center',
      [theme.breakpoints.down('tablet')]: {
        order: 1,
      },
    },
  }),
}

interface WizardProps {
  children: ReactNode
  defaultStepIndex?: number
}

const Wizard = ({ children, defaultStepIndex = 0 }: WizardProps) => {
  const stepCounterRef = useRef<HTMLHeadingElement>()
  const { t } = useTranslation()
  const [activeStepIndex, setActiveStepIndex] = useState(defaultStepIndex)
  const [stepCounterTabIndex, setStepCounterTabIndex] = useState(-1)

  const childrenArray = flattenReactFragments(children)
  const activeStepReactElement = childrenArray[activeStepIndex] as ReactElement

  const setFocusOnStepCounter = () => {
    setStepCounterTabIndex(0)
    stepCounterRef.current?.focus()
  }

  const handleStepCounterBlur = () => {
    setStepCounterTabIndex(-1)
  }

  const defaultBackHandler = () => {
    setActiveStepIndex((prevIndex: number) => prevIndex - 1)
    setFocusOnStepCounter()
  }

  const defaultNextHandler = () => {
    setActiveStepIndex(activeStepIndex + 1)
    setFocusOnStepCounter()
  }

  const handleBack = () => {
    const customBackHandler = activeStepReactElement?.props?.handleBack

    if (customBackHandler) {
      Promise.resolve(customBackHandler()).then(() => defaultBackHandler())
    } else {
      defaultBackHandler()
    }
  }

  const handleNext = () => {
    const customNextHandler = activeStepReactElement?.props?.handleNext

    if (customNextHandler) {
      Promise.resolve(customNextHandler()).then(() => defaultNextHandler())
    } else {
      defaultNextHandler()
    }
  }

  const handleSave = activeStepReactElement?.props?.handleSave
  const handleDone = activeStepReactElement?.props?.handleDone

  const hasBackButton = activeStepIndex > 0
  const hasNextButton = activeStepIndex + 1 < childrenArray.length
  const hasSaveButton = Boolean(handleSave)
  const hasDoneButton = Boolean(handleDone)

  return (
    <Box className="RsmWizard-root" sx={styles.container}>
      <Box className="RsmWizard-content Rsm-scrollBoundaries">
        <Box
          component="h1"
          className="RsmWizard-stepCounter"
          tabIndex={stepCounterTabIndex}
          data-testid="Hed_Wizard_StepCounter"
          ref={stepCounterRef}
          onBlur={handleStepCounterBlur}>
          {t('UserProfile.ProfileWizard.StepCounter', {
            currentStep: activeStepIndex + 1,
            totalSteps: childrenArray.length,
          })}
        </Box>

        {childrenArray[activeStepIndex]}
      </Box>

      <Box className="RsmWizard-controls">
        <Grid container spacing={2} className="RsmWizard-controlsGridContainer">
          <Grid item xs={12} md={2} className="RsmWizard-backButtonGridItem">
            {hasBackButton && (
              <Button
                className="RsmWizard-backButton"
                data-testid="Btn_Wizard_Back"
                onClick={handleBack}>
                <LessThanIcon />
                <Box component="span" className="RsmWizard-backButtonText">
                  {t('UserProfile.ProfileWizard.Back')}
                </Box>
              </Button>
            )}
          </Grid>

          <Grid item xs={12} md={8} className="RsmWizard-actionButtonsGridItem">
            {hasNextButton && (
              <Button
                variant="contained"
                className="RsmWizard-nextButton"
                data-testid="Btn_Wizard_Next"
                onClick={handleNext}>
                {t('UserProfile.ProfileWizard.Next')}
              </Button>
            )}

            {hasDoneButton && (
              <Button
                variant="contained"
                className="RsmWizard-doneButton"
                data-testid="Btn_Wizard_Done"
                onClick={handleDone}>
                {t('UserProfile.ProfileWizard.SaveAndExit')}
              </Button>
            )}

            {hasSaveButton && (
              <Button
                variant="outlined"
                className="RsmWizard-saveButton"
                data-testid="Btn_Wizard_Save"
                onClick={handleSave}>
                {t('UserProfile.ProfileWizard.SaveAndContinueLater')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Wizard
