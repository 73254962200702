import React, { useReducer, useMemo } from 'react'
import { ProjectsContext, ProjectsState } from './context'
import { reducer } from './reducer'

const ProjectsProvider = ({
  initialState,
  children,
}: {
  initialState: ProjectsState
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState })

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <ProjectsContext.Provider value={contextValue}>
      {children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsProvider
