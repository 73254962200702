import { t } from 'i18next'
import { DropdownObject } from '../../Dropdown/Dropdown'

// Employees have the navigation options of "Dashboard, Docuements, and Document Requests. Clients only have Dashboard, and Docuement Requests."
export function determineDropDownOptions(
  isEmployee: boolean,
  engagementId: string | undefined,
  documentRequestFeatureEnabled: boolean,
): DropdownObject[] {
  const data = [
    {
      value: `/engagements/${engagementId}`,
      display: t('Navigation.Dashboard'),
    },
  ]

  if (documentRequestFeatureEnabled) {
    if (isEmployee) {
      data.push({
        value: `/engagements/${engagementId}/docs`,
        display: t('Navigation.Documents'),
      })
    }
    data.push({
      value: `/engagements/${engagementId}/requests`,
      display: t('Navigation.DocumentRequests'),
    })
  }
  return data
}

export function setDefaultDropdown(
  data: DropdownObject[],
  defaultLabel: string,
): DropdownObject {
  let defaultOption = data[0]

  data.forEach((item) => {
    if (item.display === defaultLabel) {
      defaultOption = item
    }
  })

  return defaultOption
}
