import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import StringSchema, { RequiredStringSchema } from 'yup/lib/string'
import { AnyObject } from 'yup/lib/types'
import {
  FormDefinition,
  FormFieldValidationType,
  FormValidations,
  SupportedValidationTypes,
} from '../models'

const useValidationFragments = () => {
  const { t } = useTranslation()

  const phoneRegex =
    /^((\+\d{1,2}|1)[\s.-]?)?\(?[2-9](?!11)\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

  const buildValidationSchema = useCallback((definition: FormDefinition) => {
    let validationFields: FormValidations = {}

    const getValidationRule = (validator?: SupportedValidationTypes) => {
      if (!validator) {
        return yup.string()
      }

      const preset = validator as FormFieldValidationType
      if (preset) {
        switch (preset) {
          case 'Password':
            return yup
              .string()
              .min(8, 'Form.Password.Minimum.Lenght.Error')
              .required('Form.RequiredField')

          case 'Email':
            return yup.string().email(t('Form.Invalid.Email'))

          case 'Date':
            return yup.date().nullable()

          case 'Phone':
            return yup
              .string()
              .matches(phoneRegex, 'Phone number is not valid')
              .nullable()

          default:
            return yup.string()
        }
      }
      return validator as
        | RequiredStringSchema<string | undefined, AnyObject>
        | StringSchema<string | undefined, AnyObject, string | undefined>
    }

    Object.keys(definition).forEach((name) => {
      const {
        validator,
        required,
        minLength: min,
        maxLength: max,
      } = definition[name]

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let validationRule = getValidationRule(validator)

      validationRule = required
        ? validationRule.required(t('Form.RequiredField'))
        : validationRule

      validationRule = min
        ? validationRule.min(min, t('Form.Minimum'))
        : validationRule

      validationRule = max
        ? validationRule.max(
            max,
            t('Form.Maximum').replace('{{MAX_VALUE}}', max.toString()),
          )
        : validationRule

      validationFields = {
        ...validationFields,
        [name]: validationRule,
      }
    })

    const validationSchema = yup.object({ ...validationFields })

    return validationSchema
  }, [])

  return { buildValidationSchema }
}

export default useValidationFragments
