import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, Button, Typography, Box } from '@mui/material'
import tokens from '../../styles/tokens.json'
import RoboIcon from '../../components/icons/RoboIcon'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import { RsmLogo } from '../../components/media'
import { Styles } from '../../types'

interface UnavailableProps {
  returnUrl?: string
  headerTextKey?: string
  subHeaderTextheaderTextKey?: string
  subHeader2ndLineTextheaderTextKey?: string
  subHeader3rdLineTextheaderTextKey?: string
  showTryAgain?: boolean
  showRsmLogo?: boolean
  textColor?: string
  isSpotlightSection?: boolean
}

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10%',
    paddingBottom: '10%',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('tablet')]: {
      height: '55rem',
    },
  }),
  spotlightContainer: () => ({
    display: 'flex',
    flexDirection: 'column',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textAlign: 'center',
  }),
}

const Unavailable = ({
  returnUrl = undefined,
  headerTextKey = 'PageUnavailableHeader',
  subHeaderTextheaderTextKey = 'TechnicalIssuesMessage',
  subHeader2ndLineTextheaderTextKey = 'PleaseTryAgainMessage',
  subHeader3rdLineTextheaderTextKey = '',
  showTryAgain = true,
  showRsmLogo = false,
  textColor = undefined,
  isSpotlightSection = false,
}: UnavailableProps) => {
  const { t } = useTranslation()
  const { isMobile } = useDeviceType()

  const Styled = {
    RoboIconWrap: styled('div')({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    MobileSubHeading: styled('div')({
      marginTop: '2%',
      marginBottom: '15%',
      color: textColor || tokens.colors.rsmGray.copy,
      fontFamily: 'Prelo-Book, sans-serif',
      fontWeight: '400',
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
    }),
    SubHeading: styled('div')({
      marginTop: '1%',
      color: textColor || tokens.colors.rsmGray.copy,
      fontFamily: 'Prelo-Book, sans-serif',
      fontWeight: '400',
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
    }),
    SubHeading2: styled('div')({
      marginTop: '10%',
      color: tokens.colors.rsmGray.copy,
      fontFamily: 'Prelo-Book, sans-serif',
      fontWeight: '400',
      lineHeight: '1.5rem',
      fontSize: '1.125rem',
    }),
  }

  return (
    <Box sx={isSpotlightSection ? styles.spotlightContainer : styles.container}>
      {showRsmLogo ? <RsmLogo /> : null}
      <div>
        <Styled.RoboIconWrap>
          <RoboIcon />
        </Styled.RoboIconWrap>
        <Typography
          variant="h1"
          sx={{
            marginTop: '1.25rem',
            fontFamily: 'Prelo-Light, sans-serif',
            color: textColor || tokens.colors.rsmGray.copy,
            fontWeight: '300',
            lineHeight: '2.5rem',
            fontSize: '2.25rem',
          }}>
          {t(headerTextKey || '')}
        </Typography>
        {isMobile ? (
          <Styled.MobileSubHeading>
            {t(subHeaderTextheaderTextKey || '')}
            <br />
            {t(subHeader2ndLineTextheaderTextKey || '')}
            <br />
            <br />
            {t(subHeader3rdLineTextheaderTextKey || '')}
          </Styled.MobileSubHeading>
        ) : (
          <>
            <Styled.SubHeading>
              {t(subHeaderTextheaderTextKey || '')}{' '}
              {t(subHeader2ndLineTextheaderTextKey || '')}
            </Styled.SubHeading>
            <Styled.SubHeading2>
              {t(subHeader3rdLineTextheaderTextKey || '')}
            </Styled.SubHeading2>
          </>
        )}
        {showTryAgain ? (
          <Button
            type="button"
            variant="contained"
            color="primary"
            sx={{ margin: '4%' }}
            aria-label=""
            tabIndex={0}
            onClick={() =>
              returnUrl
                ? window.location.replace(returnUrl)
                : window.location.reload()
            }>
            {t('TryAgainButtonLabel')}
          </Button>
        ) : null}
      </div>
    </Box>
  )
}

export default memo(Unavailable)
