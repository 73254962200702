import { useEffect, useState } from 'react'
import tokens from '../../styles/tokens.json'

export const useDeviceType = () => {
  const tablet = tokens.grid.tablet.breakpoint
  const desktop = tokens.grid.desktop.breakpoint

  const [isMobile, setIsMobile] = useState(window.innerWidth < tablet)
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= tablet && window.innerWidth < desktop,
  )
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= desktop)

  const updateMedia = () => {
    setIsMobile(window.innerWidth < tablet)
    setIsTablet(window.innerWidth >= tablet && window.innerWidth < desktop)
    setIsDesktop(window.innerWidth >= desktop)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  return { isMobile, isTablet, isDesktop }
}

export default useDeviceType
