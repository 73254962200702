import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { FileResponse } from '@rsmus/ecp-financeservice'
import { PaymentData } from '../../../store/invoices/paymentSearchService'
import {
  setSelectedInvoices,
  setPayingInvoices,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import api from '../../../api'
import { InvoiceData } from '../../../store/invoices/invoiceSearchService'

// this HOOK should have all ACTIONS related to action menu
const useActionMenu = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handlePayInvoiceAction = (invoice: InvoiceData) => {
    dispatch(setSelectedInvoices([invoice]))
    dispatch(setPayingInvoices([invoice]))
    navigate('/invoicing/invoices/pay-invoices')
  }

  const handleDownloadInvoiceAction = useCallback(
    async (
      invoice: InvoiceData,
      setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>,
      setIsNotFound: React.Dispatch<React.SetStateAction<boolean>>,
      setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      try {
        setIsNotFound(false)
        const response: FileResponse =
          await api.finance.invoiceDocument_DownloadInvoice(invoice.id)

        if (response.data?.size > 0) {
          downloadFileStream(response)
          setIsSuccess(true)
        } else {
          if (response?.status === 404) {
            setIsNotFound(true)
          }
          setIsSuccess(false)
          setOpen(true)
        }
      } catch (e: any) {
        if (e?.status === 404) {
          setIsNotFound(true)
        }
        setIsSuccess(false)
        setOpen(true)
      }
    },
    [api.finance.invoiceDocument_DownloadInvoice],
  )

  const handleDownloadReceipt = useCallback(
    async (payment: PaymentData) => {
      const response: FileResponse =
        await api.finance.invoiceDocument_DownloadPaymentReceipt(
          payment.transactionId,
        )
      downloadFileStream(response)
    },
    [api.finance.invoiceDocument_DownloadPaymentReceipt, downloadFileStream],
  )

  const handleDownloadPayonAccountReciept = useCallback(
    async (payment: PaymentData) => {
      const response: FileResponse =
        await api.finance.invoiceDocument_DownloadPayonAccountReceipt(
          payment.transactionId,
        )
      downloadFileStream(response)
    },
    [api.finance.invoiceDocument_DownloadPaymentReceipt, downloadFileStream],
  )

  const handleDownloadPaymentDetails = useCallback(
    async (payment: PaymentData) => {
      let response
      if (Number(payment.planId) > 0) {
        const numberofInstall = (
          await api.finance.payment_GetPlanDetails(Number(payment.planId))
        ).data?.totalInstallmentCount
        if (numberofInstall !== undefined && numberofInstall > 1) {
          response =
            await api.finance.invoiceDocument_DownloadInstallmentPlanSummary(
              Number(payment.planId),
            )
        } else {
          response =
            await api.finance.invoiceDocument_DownloadScheduledPaymentReceipt(
              payment.transactionId,
            )
        }
        downloadFileStream(response)
      }
    },
    [
      api.finance.invoiceDocument_DownloadInstallmentPlanSummary,
      api.finance.invoiceDocument_DownloadScheduledPaymentReceipt,
      downloadFileStream,
    ],
  )

  const handleManageScheduledPayment = (payment: PaymentData) => {
    // eslint-disable-next-line no-alert
    window.alert(
      `${t('Invoicing.ManageScheduledPayment')} Transaction ID ${
        payment.transactionId
      }`,
    )
  }

  const handleDownloadScheduledPlanDetails = useCallback(
    async (transactionId: string) => {
      const response =
        await api.finance.invoiceDocument_DownloadScheduledPaymentReceipt(
          transactionId,
        )
      downloadFileStream(response)
    },
    [
      api.finance.invoiceDocument_DownloadScheduledPaymentReceipt,
      downloadFileStream,
    ],
  )

  const handleDownloadFailedPaymentNotice = useCallback(
    async (payment: PaymentData) => {
      const response: FileResponse =
        await api.finance.invoiceDocument_DownloadFailedPaymentStatement(
          payment.transactionId,
        )
      downloadFileStream(response)
    },
    [
      api.finance.invoiceDocument_DownloadFailedPaymentStatement,
      downloadFileStream,
    ],
  )

  const handleDownloadCancelledPaymentNotice = useCallback(
    async (payment: PaymentData) => {
      const response: FileResponse =
        await api.finance.invoiceDocument_DownloadCancelledPaymentStatement(
          payment.transactionId,
        )
      downloadFileStream(response)
    },
    [
      api.finance.invoiceDocument_DownloadCancelledPaymentStatement,
      downloadFileStream,
    ],
  )

  const handleDownloadFailedPayOnAccountNotice = useCallback(
    async (payment: PaymentData) => {
      const response: FileResponse =
        await api.finance.invoiceDocument_DownloadFailedPayOnAccountStatement(
          payment.transactionId,
        )
      downloadFileStream(response)
    },
    [
      api.finance.invoiceDocument_DownloadFailedPayOnAccountStatement,
      downloadFileStream,
    ],
  )

  const handleViewClientAccountManagement = (invoice: InvoiceData) => {
    navigate(`/invoicing/account-management/${invoice.customerNumber}`)
  }

  const handleViewClientDashboard = (invoice: InvoiceData) => {
    navigate(`/invoicing/dashboard/${invoice.customerNumber}`)
  }

  return {
    handlePayInvoiceAction,
    handleDownloadInvoiceAction,
    handleDownloadReceipt,
    handleDownloadPayonAccountReciept,
    handleDownloadPaymentDetails,
    handleDownloadFailedPaymentNotice,
    handleDownloadCancelledPaymentNotice,
    handleDownloadFailedPayOnAccountNotice,
    handleManageScheduledPayment,
    handleDownloadScheduledPlanDetails,
    handleViewClientAccountManagement,
    handleViewClientDashboard,
  }
}

export default useActionMenu
