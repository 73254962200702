import React, { useRef, useEffect, useState, useCallback } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ScheduledInvoiceViewModel } from '@rsmus/ecp-financeservice'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { Styles } from '../../../types'
import AlertIconCircle from '../../icons/AlertIcon/AlertIconCircle'
import api from '../../../api'
import {
  formatCurrency,
  formatCurrencyWithCurrencyCode,
} from '../../../rsmCoreComponents/utils/formatters'

interface CancelScheduleInvoicesDialogProps {
  open: boolean
  onClose: () => void
  scheduledInvoicesData: ScheduledInvoiceViewModel[]
  selectedPlanId: number
  planDate: string
  onConfirm: () => void
  isScheduled: boolean
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxHeight: '100%',
    height: '100%',
    border: '0',
    padding: '0.5rem',
  },
  subText: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'center',
    padding: '0.5rem',
    [theme.breakpoints.only('mobile')]: {
      textAlign: 'justify',
      padding: '0.0223rem 0.5rem 0.5rem 0.0223rem',
    },
    [theme.breakpoints.only('tablet')]: {
      textAlign: 'center',
      paddingTop: '0.5rem',
    },
  }),
  subTextLineItems: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.0rem',
    lineHeight: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'left',
    padding: '0.5rem',
    fontWeight: 'bold',

    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '5.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8.5rem',
    },
    [theme.breakpoints.unit ?? '499Px']: {
      paddingLeft: '9.5.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '14.5rem',
    },
  }),
  scrollableContainer: (theme) => ({
    maxHeight: '10rem',
    overflowY: 'auto',
    width: '100%',
    border: '0',
    [theme.breakpoints.only('desktop')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: '2.5rem',
    },
    '@media (max-width: 35.5rem)': {
      paddingLeft: '2.5rem',
    },
    '@media (min-width: 40rem) and (max-width: 52rem)': {
      paddingLeft: '12.5rem',
    },
  }),
  acceptWrapper: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '0.5rem',
    border: '0',
    [theme.breakpoints.only('mobile')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'initial',
    },
  }),
}
const CancelScheduleInvoicesDialog = ({
  open,
  onClose,
  scheduledInvoicesData,
  selectedPlanId,
  planDate,
  onConfirm,
  isScheduled,
}: CancelScheduleInvoicesDialogProps) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-restricted-globals
  const { isMobile } = useDeviceType()
  const desktopMaxWidth = '46rem'
  // State to manage the max height
  const [desktopMaxHeight, setDesktopMaxHeight] = useState('38rem')
  // Ref to get the content height
  const contentRef = useRef<HTMLTableElement>(null)
  const handleConfirmCancelInstallment = useCallback(() => {
    api.finance.payment_CancelPaymentPlan(selectedPlanId).then(() => {
      onConfirm()
    })
  }, [onConfirm, selectedPlanId])

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      const newHeight = Math.min(contentHeight + 80, 640) // max height 640px (40rem)
      const newHeightInRem = newHeight / 16 // convert to rem
      setDesktopMaxHeight(`${newHeightInRem}rem`)
    }
  }, [scheduledInvoicesData, open])

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={
          isScheduled
            ? t('Invoicing.CancelScheduledInvoicesDialog.DialogTextschedl')
            : t('Invoicing.CancelScheduledInvoicesDialog.DialogText')
        }
        desktopMaxHeight={desktopMaxHeight}
        desktopMaxWidth={desktopMaxWidth}
        removeHeaderSpacing>
        <Table ref={contentRef}>
          <TableBody>
            <TableRow>
              <TableCell sx={styles.dialogContent}>
                <AlertIconCircle />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.subText}>
                {isScheduled
                  ? t(
                      'Invoicing.CancelScheduledInvoicesDialog.SubTextSchedule',
                      {
                        date: planDate,
                      },
                    )
                  : t('Invoicing.CancelScheduledInvoicesDialog.SubText1', {
                      date: planDate,
                    })}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: '0' }}>
                <Box sx={styles.scrollableContainer} tabIndex={0}>
                  {scheduledInvoicesData.map((invoice) => (
                    <TableRow key={invoice.invoiceNumber}>
                      <TableCell sx={styles.subTextLineItems}>
                        <span
                          style={{ marginRight: '0.6rem', fontWeight: 'bold' }}>
                          •
                        </span>
                        {isMobile
                          ? t(
                              'Invoicing.CancelScheduledInvoicesDialog.ScheduledInvoicesitemsMobile',
                              {
                                invoicenumber: invoice.invoiceNumber,
                                openAmount: formatCurrency(
                                  invoice.openAmount ?? 0,
                                ),
                              },
                            )
                          : t(
                              'Invoicing.CancelScheduledInvoicesDialog.ScheduledInvoiceslineitems',
                              {
                                invoicenumber: invoice.invoiceNumber,
                                clientName:
                                  invoice.clientName &&
                                  invoice.clientName.length > 26
                                    ? `${invoice.clientName.slice(0, 26)},...`
                                    : invoice.clientName,
                                openAmount: formatCurrencyWithCurrencyCode(
                                  invoice.openAmount ?? 0,
                                ),
                              },
                            )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Box>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={styles.acceptWrapper}>
                <Stack sx={styles.acceptWrapper}>
                  <Button variant="outlined" onClick={onClose}>
                    {t('Invoicing.CancelScheduledInvoicesDialog.Cancel')}
                  </Button>
                  <Button
                    disableFocusRipple
                    disableRipple
                    variant="contained"
                    onClick={handleConfirmCancelInstallment}>
                    {t('Invoicing.CancelScheduledInvoicesDialog.Confirm')}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveModal>
    </Box>
  )
}

export default CancelScheduleInvoicesDialog
