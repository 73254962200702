import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface SelectedContactMethod {
  contactMethod: string
}

const initialState: SelectedContactMethod = {
  contactMethod: '1',
}

export const profileFormSlice = createSlice({
  name: 'profileForm',
  initialState,
  reducers: {
    setSelectedContactMethod: (state, { payload }) => {
      state.contactMethod = payload
    },
  },
})

export const { setSelectedContactMethod } = profileFormSlice.actions
export const getSelectedContactMethod = (state: RootState) =>
  state.profileForm.contactMethod

export default profileFormSlice.reducer
