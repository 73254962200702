import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Typography,
  SelectChangeEvent,
  FormHelperText,
  Pagination,
  styled,
  Tooltip,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { InvoicePayerViewModel } from '@rsmus/ecp-financeservice'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import CheckboxIcon from '../../../rsmCoreComponents/components/icons/CheckboxIcon'
import CheckboxOutlinedIcon from '../../../rsmCoreComponents/components/icons/CheckboxOutlinedIcon'
import { InvoiceData } from '../../../store/invoices/invoiceSearchService'
import tokens from '../../../styles/tokens.json'
import { ArrowDownIcon, CalendarInCircleIcon } from '../../icons'
import { Styles } from '../../../types'
import OverflowTooltip from '../../OverFlowToolTip'
import {
  getSelectedPaymentMethod,
  setSelectedPaymentMethod,
} from '../../../store/invoices/paymentInfoSlice'
import {
  setSelectedInvoices,
  getSelectedInvoices,
  setPayingInvoices,
  getPayingInvoices,
  setPayableAmount,
  getPayableAmount,
  setInvoicePayments,
  getInvoicePayments,
  setInvoicePayers,
  setPartialAmount,
  getIsAccountInfoLoaded,
  getselectedAccInvoices,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import {
  formatCurrency,
  formatDate,
} from '../../../rsmCoreComponents/utils/formatters'
import useInvoicesSearch from './useInvoicesSearch'
import api from '../../../api'
import UnrelatedInvoicesDialog from './UnrelatedInvoicesDialog'
import ConfirmAmountDialog from './ConfirmAmountDialog'
import { USER_PREF_INVOICING_IGNOREUNRELATED } from '../../../utils/constants/constants'
import InvoicesHeader from './InvoicesHeader'

const styles: Styles = {
  pagination: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    justifyContent: 'center',
    width: '100%', // Ensure the pagination container is full width
    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'column-reverse',
      gap: '1rem',
      flexWrap: 'initial',
    },
    ul: {
      flexWrap: 'initial',
      margin: '0 auto', // Center the pagination
    },
  }),
  tableContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
  }),
  invoicesPaymentTable: (theme) => ({
    // -------------------------------------------------------------------------
    // TODO: (Ideally move these styles to a RsmTable variant, if possible.)
    // -------------------------------------------------------------------------
    '&.MuiTable-root': {
      tableLayout: 'fixed',
      width: '100%',
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& th.MuiTableCell-root': {
        fontFamily: 'Prelo-Bold, sans-serif',
      },
      '& td.MuiTableCell-root': {
        fontFamily: 'Prelo-Book, sans-serif',
      },
      '& .MuiTableCell-head': {
        padding: '1rem',
        fontSize: '0.875rem',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        verticalAlign: 'top',
      },
      '& tbody': {
        '& .MuiTableRow-root': {
          '&:nth-of-type(odd)': {
            backgroundColor: tokens.colors.rsmGray.accessibility,
          },
          '&:last-of-type()': {
            '& .MuiTableCell-root': {
              marginTop: '1rem',
              marginBottom: '1rem',
            },
          },
        },
      },
      '& .MuiTableCell-body, .MuiTableRow-root:not(:nth-last-of-type(3))>.MuiTableCell-footer':
        {
          padding: '1.5rem 1rem',
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      '& .MuiTableCell-body, .MuiTableRow-root:not(:nth-last-of-type(4))>.MuiTableCell-footer':
        {
          padding: '1rem',
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      '.MuiFormControlLabel-root': {
        margin: '0rem',
      },
      '.MuiCheckbox-root': {
        boxSizing: 'content-box',
        padding: '0.5rem',
      },
    },
    // -----------------------------------------------------------------------
    // Invoicing Payment Table-specific Styles
    // -----------------------------------------------------------------------
    '& .MuiTableRow-root:not(.MuiTableRow-footer)': {
      '& .MuiTableCell-root': {
        '&:nth-of-type(1)': {
          width: '3.5rem',
          padding: '0.5rem',
        },
        '&:nth-of-type(2)': {
          width: '15rem',
          padding: '0.5rem',
        },
        '&:nth-of-type(3)': {
          width: '5rem',
          padding: '0.5rem',
        },
        '&:nth-of-type(9)': {
          minWidth: '5.75rem',
        },
      },
    },
    '& .MuiTableCell-body': {
      '&:nth-of-type(2), &:nth-of-type(3), :nth-of-type(4), :nth-of-type(5), :nth-of-type(10),':
        {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      ':nth-of-type(7)': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    [theme.breakpoints.only('tablet')]: {
      '& .MuiTableCell-root:not(.MuiTableCell-footer)': {
        '&:nth-of-type(4)': {
          display: 'none',
        },
      },
    },
    [theme.breakpoints.only('mobile')]: {
      '& .MuiTableCell-root:not(.MuiTableCell-footer)': {
        '&:nth-of-type(2),&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(8)':
          {
            display: 'none',
          },
      },
    },
  }),
  // -----------------------------------------------------------------------
  // TODO: Possible RSM Table Utility Styles
  // -----------------------------------------------------------------------
  date: {
    textTransform: 'uppercase',
  },
  tooltipText: {
    padding: '1rem',
  },
  tooltipIconButton: {
    width: '2.25rem',
    height: '2.25rem',
    marginLeft: 0,
    marginRight: 0,
  },
  statusTooltip: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  continue: (theme) => ({
    textAlign: 'center',
    paddingBottom: '2rem',
    [theme.breakpoints.only('desktop')]: {
      textAlign: 'right',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: 0,
    },
  }),
  continueButton: (theme) => ({
    width: '16.4375rem',
    marginTop: '1rem',
    textAlign: 'right',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  paymentType: (theme) => ({
    paddingTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.only('desktop')]: {
      paddingTop: '1rem',
      textAlign: 'right',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingTop: '1rem',
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: 0,
    },
  }),
  paymentTypeSelect: (theme) => ({
    width: '16.4375rem',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  partialPaymentText: (theme) => ({
    textAlign: 'right',
    padding: 0,
    [theme.breakpoints.only('mobile')]: {
      textAlign: 'start',
      fontSize: '0.75rem',
      paddingTop: '0.75rem',
    },
  }),
  totalRightCol: (theme) => ({
    textAlign: 'right',
    [theme.breakpoints.only('mobile')]: {
      textAlign: 'left',
    },
  }),
  requiredError: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
    textAlign: 'center',
    [theme.breakpoints.only('desktop')]: {
      textAlign: 'right',
      marginRight: '5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingRight: '1rem',
      paddingBottom: 0,
      textAlign: 'left',
    },
    [theme.breakpoints.only('tablet')]: {
      textAlign: 'center',
      marginRight: '5rem',
    },
  }),
}

type InvoicePayments = {
  invoices: {
    invoice: InvoiceData
    partialAmount: string
    originalindex: number
  }[]
}

const InvoicesPaymentTable = () => {
  const isAccountInfoLoaded = useSelector(getIsAccountInfoLoaded)
  const selectedInvoices = useSelector(getSelectedInvoices)
  const selectedClientAccInfoInvoices = useSelector(getselectedAccInvoices)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const payingInvoices = useSelector(getPayingInvoices)
  const invoicePayments = useSelector(getInvoicePayments)
  const { isMobile, isTablet } = useDeviceType()
  const [unrelatedInvoicesDialogOpen, setUnrelatedInvoicesDialogOpen] =
    useState(false)
  const [showConfirmAmountDialog, setShowConfirmAmountDialog] = useState(false)
  const [hasDifferentAmount, setHasDifferentAmount] = useState(false)
  const [hasInvoicesWithCC, setHasInvoicesWithCC] = useState(false)
  const [isMastersame, setisMastersame] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalRows, setTotalRows] = useState(0)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { handleSubmit, control, getValues, setValue, watch } =
    useForm<InvoicePayments>({
      defaultValues: {
        // If coming to the page the first time, show the invoices that the user selected on the invoices page
        // else if returning to the page, show the invoices that the user originally selected but with the payment amounts they entered
        // AND with any checkbox state changes that they made while on the invoice payment page.
        invoices: !invoicePayments?.length
          ? selectedInvoices.map((x, index) => ({
              invoice: x,
              partialAmount: x.openAmount?.toFixed(2) || '',
              originalindex: index,
            }))
          : payingInvoices.map((x, index) => ({
              invoice: x as InvoiceData,
              partialAmount:
                invoicePayments
                  .find((ip) => ip.id === x.id)
                  ?.paidAmount?.toFixed(2)
                  .toString() || '',
              originalindex: index,
            })),
      },
    })
  const { fields } = useFieldArray({ control, name: 'invoices' })
  const watchFieldArray = watch('invoices')
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }))
  const dispatch = useDispatch()
  const payableAmount = useSelector(getPayableAmount)
  const { changeSearchFilterAndSearch } = useInvoicesSearch()

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const newSize = +event.target.value
    setPageSize(newSize)
    setPageNumber(1)
  }
  const shouldDisplayPagination = () => {
    if (isMobile || isTablet) {
      return totalRows >= 5
    }
    return totalRows >= 10
  }

  // Toggle the selection of an invoice
  const toggleInvoiceSelection = (invoice: InvoiceData, index: number) => {
    if (selectedInvoices.find((row) => row?.id === invoice.id)) {
      // Check if the invoice is already selected
      dispatch(
        setSelectedInvoices(
          selectedInvoices.filter((row) => row.id !== invoice.id),
        ),
      )
      setValue(`invoices.${index}.partialAmount`, '')
    } else {
      // If not selected, add it to both the selected invoices and paying invoices lists
      setValue(
        `invoices.${index}.partialAmount`,
        invoice.openAmount?.toString() || '',
      )
      dispatch(setSelectedInvoices([...selectedInvoices, invoice]))
    }
  }

  const handleBackToInvoices = () => {
    dispatch(setSelectedInvoices([]))
    dispatch(setPayingInvoices([]))
    dispatch(setInvoicePayments([]))
    dispatch(setPayableAmount(0))
    dispatch(setPartialAmount(false))
    dispatch(setInvoicePayers([]))
    navigate('/invoicing/invoices')
  }

  const getHrColSpan = () => {
    if (isMobile) return 4
    if (isTablet) return 6
    return 7
  }

  const getTotalLeftColSpan = () => {
    if (isMobile) return 2
    if (isTablet) return 3
    return 2
  }

  const getTotalRightColSpan = () => {
    if (isMobile) return 4
    if (isTablet) return 3
    return 5
  }
  const [iserror, setiserror] = useState<string>('')
  const filterChipsRef = useRef<HTMLElement>(null)
  const validPaymenttype = () => {
    let isvalid: boolean
    if (
      selectedPaymentMethod === undefined ||
      selectedPaymentMethod === `${t('Invoicing.SelectPaymentType')}` ||
      selectedInvoices.length === 0
    ) {
      isvalid = false
      setiserror(
        selectedInvoices.length === 0
          ? ''
          : `${t('Invoicing.PaymentTyperequired')}`,
      )
      filterChipsRef.current?.focus()
    } else {
      isvalid = true
      setiserror('')
    }
    return isvalid
  }
  const paymentTypeChange = (
    e: SelectChangeEvent<'BankAccount' | 'CreditCard' | 'Unselected'>,
  ) => {
    if (
      e.target.value === 'Unselected' ||
      e.target.value === `${t('Invoicing.SelectPaymentType')}`
    ) {
      setiserror(`${t('Invoicing.PaymentTyperequired')}`)
      filterChipsRef.current?.focus()
    } else {
      setiserror('')
    }

    const val =
      e.target.value === 'Unselected'
        ? undefined
        : (e.target.value as 'BankAccount' | 'CreditCard')
    dispatch(setSelectedPaymentMethod(val))
  }

  const onPaymentChanged = (newValue: number, invoice: InvoiceData) => {
    if (newValue === 0) {
      dispatch(
        setSelectedInvoices(
          selectedInvoices.filter((row) => row.id !== invoice.id),
        ),
      )
    } else if (newValue > 0) {
      if (!selectedInvoices.some((row) => row.id === invoice.id)) {
        dispatch(setSelectedInvoices([...selectedInvoices, invoice]))
      }
    }
  }

  const StyledLabel = styled('label')(({ theme }) => ({
    fontFamily: 'Prelo-Black, sans-serif',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    paddingRight: '7.5rem',
    [theme.breakpoints.only('mobile')]: {
      marginRight: '1.5rem',
    },
  }))

  const calcPayableAmount = (newValue: number, index: number | null) =>
    dispatch(
      setPayableAmount(
        +controlledFields
          .reduce((a, v, i) => {
            const partialAmount = index === i ? newValue : +v.partialAmount
            const payment =
              partialAmount > 0 ? partialAmount : v.invoice.openAmount
            const selected = selectedInvoices.some((x) => x.id === v.invoice.id)
            const amount = selected ? payment : 0
            // eslint-disable-next-line no-param-reassign, no-return-assign
            return (a += amount)
          }, 0)
          .toFixed(2),
      ),
    )

  useEffect(() => {
    // Clears out "search by keywords"
    if (!isAccountInfoLoaded) {
      changeSearchFilterAndSearch('')
    }

    // Prevent user from accessing this page directly.
    if (selectedInvoices.length === 0) {
      navigate('/invoicing')
    }

    if (selectedInvoices.length === 0 && isAccountInfoLoaded) {
      dispatch(setSelectedInvoices(selectedClientAccInfoInvoices))
    }
  }, [])

  useEffect(() => {
    const newTotalRows = controlledFields.length
    setTotalRows(newTotalRows)
    const totalPages = Math.ceil(newTotalRows / pageSize)
    if (pageNumber > totalPages) {
      setPageNumber(totalPages)
    } else if (pageNumber < 1) {
      setPageNumber(1)
    }
  }, [selectedInvoices, pageSize, pageNumber])

  useEffect(() => {
    // this useEffect will default the selected payment method to BankAccount during intiail load
    if (selectedInvoices.length > 0) {
      dispatch(setSelectedPaymentMethod('BankAccount'))
    }
  }, [])

  useEffect(() => {
    calcPayableAmount(0, null)

    api.finance
      .invoice_InvoicesHaveCC(selectedInvoices.map((x) => x.id))
      .then((x) => {
        if (x.data === hasInvoicesWithCC) return // No need to update state if the value hasn't changed
        setHasInvoicesWithCC(x.data)
        if (!x.data) {
          dispatch(setSelectedPaymentMethod('BankAccount'))
        } else {
          dispatch(setSelectedPaymentMethod(undefined))
        }
      })
  }, [selectedInvoices.length])

  const checkAmountDifferences = () => {
    const selectedInvoicesWithCheckbox = controlledFields.filter((field) =>
      selectedInvoices.some((row) => row.id === field.invoice.id),
    )
    const newHasDifferentAmount = selectedInvoicesWithCheckbox.some(
      (field) => +field.partialAmount !== field.invoice.openAmount,
    )
    setHasDifferentAmount(newHasDifferentAmount)
    return newHasDifferentAmount
  }

  useEffect(() => {
    checkAmountDifferences()
  }, [controlledFields])

  const checkPartialDifferences = () => {
    const selectedInvoicesWithCheckbox = controlledFields.filter((field) =>
      selectedInvoices.some((row) => row.id === field.invoice.id),
    )
    const newHasDifferentAmount = selectedInvoicesWithCheckbox.some(
      (field) => +field.partialAmount !== field.invoice.openAmount,
    )
    dispatch(setPartialAmount(newHasDifferentAmount))
    return newHasDifferentAmount
  }

  useEffect(() => {
    checkPartialDifferences()
  }, [controlledFields])
  const onSubmit = async () => {
    if (!validPaymenttype()) {
      filterChipsRef.current?.focus()
      return
    }
    const values = getValues()
    const invoicesWithPayments = values.invoices
      .filter((x) => selectedInvoices.some((i) => i.id === x.invoice.id))
      .map((row) => ({
        ...row.invoice,
        paidAmount: +row.partialAmount || row.invoice.openAmount,
      }))
    const invoiceIds = invoicesWithPayments.map((x) => x.id)

    // Run in parallel to save time
    const [payerResult, ignoreUnrelatedResult] = await Promise.all([
      api.finance.invoice_GetInvoicePayers(invoiceIds),
      api.user.configuration_GetUserStorage(
        USER_PREF_INVOICING_IGNOREUNRELATED,
      ),
    ])

    let payers = payerResult?.data || []
    const payingclients = invoicesWithPayments.map((x) => x.customerNumber)
    const uniqueCustomerNumbers = [...new Set(payingclients)]
    // To find same masterClientIds
    const allSameMasterClientId = (data: InvoicePayerViewModel[]): boolean => {
      if (data.length === 0) return false
      const firstMasterClientId = data[0].masterClientId

      let hasequaldata = data.every(
        (item) => item.masterClientId === firstMasterClientId,
      )
      if (hasequaldata) {
        const payerdiff = data.some((payerId) => payerId !== data[0])
        if (!payerdiff) {
          hasequaldata = false
        }
      }
      return hasequaldata
    }
    const userpayers = payers.filter((payer) =>
      uniqueCustomerNumbers.includes(
        payer.payerId === undefined ? '' : payer.payerId,
      ),
    )
    const areSameMasterClientid =
      uniqueCustomerNumbers.length === userpayers.length
        ? allSameMasterClientId(userpayers)
        : false

    if (
      payers.length > 1 &&
      hasInvoicesWithCC &&
      selectedPaymentMethod !== 'BankAccount'
    ) {
      // If multiple payers and No CC enabled removed the invalid CC Payers
      const payerwithCC: string[] = payers.map((x) => x.payerId) as string[]
      const [payerwithCCResult] = await Promise.all([
        api.finance.invoice_GePayersWithCC(payerwithCC),
      ])
      payers = payerwithCCResult?.data || []
      payers = payers.filter((payer) =>
        uniqueCustomerNumbers.includes(
          payer.payerId === undefined ? '' : payer.payerId,
        ),
      )
    }
    const ignoreUnrelated = ignoreUnrelatedResult?.data || false

    // Add payers to slice so they can be used in the payment type page.
    dispatch(setInvoicePayers(payers))
    dispatch(setInvoicePayments(invoicesWithPayments))
    if (areSameMasterClientid) {
      setisMastersame(true)
      setUnrelatedInvoicesDialogOpen(true)
      return
    }
    if (!ignoreUnrelated) {
      // Check if the invoices are all related to the same customer
      const invoiceMasterClientIds = selectedInvoices.map(
        (x) =>
          payers.find((p) => p.payerId === x.customerNumber)?.masterClientId,
      )
      const areInvoicesUnrelated = invoiceMasterClientIds.some(
        (masterClientId) => masterClientId !== invoiceMasterClientIds[0],
      )

      if (areInvoicesUnrelated) {
        setisMastersame(false)
        setUnrelatedInvoicesDialogOpen(true)
        return
      }
    }

    setShowConfirmAmountDialog(true)
  }

  const handleUnrelatedInvoicesDialogClose = (proceed = false) => {
    setUnrelatedInvoicesDialogOpen(false)
    if (proceed) {
      setShowConfirmAmountDialog(true)
    }
  }

  const getPartialPaymentText = (): JSX.Element => (
    <>
      <Box component="span" sx={{ fontWeight: 'bold' }}>
        {t('Invoicing.PartialPaymentToolTipNote')}
      </Box>
      <Box component="span">{t('Invoicing.PartialPaymentToolTipText')}</Box>
    </>
  )

  enum PaymentStatus {
    Scheduled = 'Scheduled',
    Installments = 'Installments',
    Closed = 'Closed',
  }

  function isScheduledStatus(scheduledPaymentType: string) {
    return (
      scheduledPaymentType === PaymentStatus.Scheduled ||
      scheduledPaymentType === PaymentStatus.Installments
    )
  }

  const [openTooltip, setOpenTooltip] = useState<number | null>(null)

  const toggleTooltip = (index: number) => {
    setOpenTooltip(openTooltip === index ? null : index)
  }

  const getTooltipText = (invoice: {
    scheduledPaymentType: string
    scheduledDate: string
  }) => {
    if (invoice.scheduledPaymentType === 'Scheduled') {
      return `${t('Invoicing.ScheduledFor')} ${formatDate(
        invoice.scheduledDate,
      )}`
    }
    if (invoice.scheduledPaymentType === 'Installments') {
      return `${t('Invoicing.InstallmentScheduledFor')} ${formatDate(
        invoice.scheduledDate,
      )}`
    }
    return null
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={t('Invoicing.PaySelectedInvoices')}
        primaryHeaderDescription={t('Invoicing.PaySelectedHelperText')}
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.BackToInvoices')}
        handleNavigation={handleBackToInvoices}
        amountHeaderId="PaySelected"
        amountHeaderText={t('Invoicing.TotalPayableAmount')}
        amount={payableAmount}
      />
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Table
          // variant="rsm" // TODO: Can we create a variant of MuiTable in the MUI theme?
          id="invoicesPaymentTable"
          sx={styles.invoicesPaymentTable}>
          <Box component="caption" sx={visuallyHidden}>
            {t('Invoicing.Invoices')}
          </Box>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('Invoicing.CustomerName')}</TableCell>
              <TableCell />
              <TableCell>{t('Invoicing.CustomerNumber')}</TableCell>
              <TableCell>{t('Invoicing.InvoiceNumber')}</TableCell>
              <TableCell>{t('Invoicing.OpenAmount')}</TableCell>
              <TableCell>{t('Invoicing.PaymentAmount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controlledFields
              .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
              .map((field, index) => (
                <TableRow
                  key={`invoice-${field.id}-${pageNumber}`}
                  sx={{ height: '6rem' }}>
                  <TableCell>
                    <FormControlLabel
                      label=""
                      aria-label={`${t('Invoicing.SelectInvoice')} ${
                        field.invoice.invoiceNumber
                      }`}
                      htmlFor={`select-invoice-checkbox-${field.invoice.id}`}
                      control={
                        <Checkbox
                          id={`select-invoice-checkbox-${field.invoice.id}`}
                          icon={<CheckboxOutlinedIcon />}
                          checkedIcon={<CheckboxIcon />}
                          checked={selectedInvoices.some(
                            (row) => row.id === field.invoice.id,
                          )}
                          disableRipple
                          onClick={() =>
                            toggleInvoiceSelection(field.invoice, index)
                          }
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <OverflowTooltip>
                      {field.invoice.customerName}
                    </OverflowTooltip>
                  </TableCell>
                  <TableCell
                    data-testid={`calendarIcon-cell-${field.invoice.id}`}>
                    {isScheduledStatus(field.invoice.scheduledPaymentType) && (
                      <Tooltip
                        sx={styles.tooltipIconButton}
                        id={`${field.invoice.id}-${index.toString()}`}
                        open={openTooltip === index}
                        onOpen={() => setOpenTooltip(index)}
                        onClose={() => setOpenTooltip(null)}
                        disableTouchListener
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -20],
                              },
                            },
                          ],
                        }}
                        title={
                          <Box sx={styles.tooltipText} role="status">
                            {getTooltipText(field.invoice)}
                          </Box>
                        }
                        placement="right"
                        describeChild>
                        <Button
                          sx={styles.calendarButton}
                          variant="text"
                          onClick={() => toggleTooltip(index)}
                          aria-atomic="true"
                          aria-label="scheduled date tooltip"
                          aria-live="polite">
                          <CalendarInCircleIcon
                            width="2.063rem"
                            height="2.063rem"
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <OverflowTooltip>
                      {field.invoice.customerNumber}
                    </OverflowTooltip>
                  </TableCell>
                  <TableCell>
                    {/* <OverflowTooltip> */}
                    {field.invoice.invoiceNumber}
                    {/* </OverflowTooltip> */}
                  </TableCell>
                  <TableCell>
                    <OverflowTooltip>
                      {formatCurrency(field.invoice.openAmount)}
                    </OverflowTooltip>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name={`invoices.${field.originalindex}.partialAmount`}
                      control={control}
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <TextField
                          key={`txt-${field.invoice.id}-${pageNumber}`}
                          id={`txt-${field.originalindex}-${pageNumber}`}
                          value={value}
                          onChange={(event) => {
                            onChange(event)
                            onPaymentChanged(+event.target.value, field.invoice)
                            calcPayableAmount(+event.target.value, index)
                          }}
                          onBlur={(event) => {
                            onBlur()
                            calcPayableAmount(+event.target.value, index)
                          }}
                          error={!!error}
                          inputRef={ref}
                          inputProps={{
                            'aria-label': t(
                              'Invoicing.PartialPaymentForInvoice',
                              {
                                invoiceNumber: field.invoice.invoiceNumber,
                              },
                            ),
                          }}
                          helperText={
                            error ? (
                              <OverflowTooltip>
                                <Typography
                                  sx={(theme) => ({
                                    display: 'inline',
                                    float: 'left',
                                    fontFamily: 'Prelo-Book, sans-serif',
                                    fontSize: '0.875rem',
                                    fontWeight: 400,
                                    color: theme.palette.error.main,
                                    paddingTop: '0rem',
                                  })}>
                                  {error?.message}
                                </Typography>
                              </OverflowTooltip>
                            ) : (
                              ''
                            )
                          }
                        />
                      )}
                      rules={{
                        validate: (value) => {
                          // eslint-disable-next-line prefer-destructuring
                          const openAmount = field.invoice.openAmount
                          const isValidInput = /^(0|[1-9]\d*)(\.\d+)?$/.test(
                            value.toString(),
                          ) // Only positivie, decimal numbers
                          const isValidCurrency = /^\d*\.?\d{0,2}$/.test(
                            value.toString(),
                          ) // no more than two decimal points
                          const isLessThanMaxAmount =
                            parseFloat(value) <= openAmount // less than the open invoice amount

                          if (value.length > 0 && !isValidInput)
                            return t('ValidInput').toString()
                          if (!isValidCurrency)
                            return t('ValidCurrency').toString()
                          if (value.length > 0 && !isLessThanMaxAmount)
                            return t(
                              'Invoicing.ErrorMessages.MustBeLessThanOpenAmount',
                            ).toString()
                          return true
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            {shouldDisplayPagination() && (
              <TableRow>
                <TableCell colSpan={getHrColSpan()}>
                  <Box sx={styles.pagination}>
                    <Select
                      id="pagination-rows-per-page"
                      native
                      value={pageSize}
                      onChange={handlePageSizeChange}
                      inputProps={{
                        'aria-label': t('Pagination:RowsPerPage'),
                        'data-testid': 'pagination-rows-per-page',
                      }}>
                      <option key={10} value={10}>
                        10
                      </option>
                      <option key={25} value={25}>
                        25
                      </option>
                      <option key={50} value={50}>
                        50
                      </option>
                      <option key={100} value={100}>
                        100
                      </option>
                    </Select>
                    <Pagination
                      count={Math.ceil(totalRows / pageSize)}
                      page={pageNumber}
                      onChange={(event, newPage) => setPageNumber(newPage)}
                      showFirstButton
                      showLastButton
                      boundaryCount={isMobile ? 0 : 1}
                      size={isMobile ? 'small' : 'large'}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {isMobile && (
              <TableRow key="invoicesSelected">
                <TableCell
                  colSpan={getHrColSpan()}
                  style={{ textAlign: 'left' }}>
                  {t('Invoicing.SelectedOfTotalInvoicesSelected', {
                    selected: selectedInvoices?.length || 0,
                    total: payingInvoices?.length || 0,
                  })}
                </TableCell>
              </TableRow>
            )}
            <TableRow key="separator">
              <TableCell
                colSpan={getHrColSpan()}
                sx={{ padding: 0, color: tokens.colors.halfWhite }}>
                <hr aria-hidden />
              </TableCell>
            </TableRow>
            <TableRow key="total">
              {!isMobile && (
                <TableCell colSpan={getTotalLeftColSpan()}>
                  {t('Invoicing.SelectedOfTotalInvoicesSelected', {
                    selected: selectedInvoices?.length || 0,
                    total: payingInvoices?.length || 0,
                  })}
                </TableCell>
              )}
              <TableCell
                colSpan={getTotalRightColSpan()}
                sx={styles.totalRightCol}>
                <Box
                  component="span"
                  sx={{ fontWeight: 'bold', paddingRight: '1.2rem' }}>
                  {t('Invoicing.TotalNetPayableAmount')}:
                </Box>
                {!isMobile && (
                  <Box component="span" sx={{ fontWeight: 'bold' }}>
                    {formatCurrency(payableAmount)}
                  </Box>
                )}
                {isMobile && (
                  <Box component="span">{formatCurrency(payableAmount)}</Box>
                )}
              </TableCell>
            </TableRow>
            {hasDifferentAmount && !isMobile && (
              <TableRow key="tooltip">
                <TableCell
                  colSpan={getHrColSpan()}
                  sx={styles.partialPaymentText}>
                  {getPartialPaymentText()}
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {hasInvoicesWithCC && (
        <>
          <Box sx={styles.paymentType}>
            <StyledLabel>{t('Invoicing.SelectPaymentType')}</StyledLabel>
          </Box>
          <Box sx={styles.paymentType}>
            <Select
              id="SelectPaymentType"
              ref={filterChipsRef}
              tabIndex={-1}
              onChange={(
                e: SelectChangeEvent<
                  'BankAccount' | 'CreditCard' | 'Unselected'
                >,
              ) => {
                paymentTypeChange(e)
              }}
              inputProps={{
                'aria-label': iserror ? t('Invoicing.PaymentTyperequired') : '',
              }}
              error={!!iserror}
              native
              IconComponent={ArrowDownIcon}
              sx={styles.paymentTypeSelect}
              value={selectedPaymentMethod ?? 'Unselected'}>
              <option value="Unselected">
                {t('Invoicing.SelectPaymentType')}
              </option>
              <option value="BankAccount">{t('Invoicing.BankAccount')}</option>
              <option value="CreditCard">{t('Invoicing.CreditCard')}</option>
            </Select>{' '}
            {iserror && (
              <FormHelperText id="SelectPaymentType" sx={styles.requiredError}>
                {iserror}
              </FormHelperText>
            )}
          </Box>
        </>
      )}
      <Box sx={styles.continue}>
        <Button
          sx={styles.continueButton}
          type="submit"
          color="primary"
          variant="contained"
          data-testid="Btn_Invoicing_ContinueToPayment">
          {t('Invoicing.ContinueToPayment')}
        </Button>
        {hasDifferentAmount && isMobile && (
          <Box sx={styles.partialPaymentText}>{getPartialPaymentText()}</Box>
        )}
      </Box>
      <UnrelatedInvoicesDialog
        open={unrelatedInvoicesDialogOpen}
        onAccept={() => handleUnrelatedInvoicesDialogClose(true)}
        onClose={() => setUnrelatedInvoicesDialogOpen(false)}
        isMasterClientIdsame={isMastersame}
      />
      <ConfirmAmountDialog
        open={showConfirmAmountDialog}
        onClose={() => setShowConfirmAmountDialog(false)}
        payableAmount={payableAmount} // Pass the payableAmount prop here
        checkPartialAmountDifference={hasDifferentAmount}
      />
    </form>
  )
}

export default InvoicesPaymentTable
