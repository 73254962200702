import React from 'react'

const RestaurantIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M72.6702 63.4401L41.0402 66.4201C40.3102 66.4201 39.7202 65.8201 39.7202 65.0801V16.4001C39.7202 15.6601 40.3102 15.0601 41.0402 15.0601L72.6702 12.0801C73.4002 12.0801 73.9902 12.6801 73.9902 13.4201V62.1101C73.9902 62.8501 73.4002 63.4501 72.6702 63.4501V63.4401Z"
      stroke="#85878A"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0298 25.81L63.6498 23.72"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0298 22.2999L52.0098 21.3899"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0298 43.8299L52.0098 42.9199"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.0298 23.3799L71.2798 22.9399"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.0298 45.9L71.2798 45.47"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.0298 30.96L71.2798 30.53"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.0298 53.0101L71.2798 52.5801"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0298 33.4001L63.6498 31.3101"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0298 48.33L63.6498 46.24"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0298 55.4501L63.6498 53.3501"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.91 15.7601C39.87 15.8201 39.83 15.8901 39.81 15.9601C39.79 16.0101 39.77 16.0701 39.76 16.1301C39.74 16.2201 39.73 16.3001 39.73 16.4001V65.0901C39.73 65.1801 39.73 65.2701 39.76 65.3601C39.78 65.4201 39.79 65.4701 39.81 65.5301C39.83 65.6001 39.87 65.6701 39.91 65.7301C40.16 66.1301 40.62 66.4101 41.16 66.4401H76.51C77.34 66.4401 78.01 65.8401 78.01 65.1001V16.4001C78.01 15.6601 77.33 15.0601 76.51 15.0601H74.89"
      stroke="#85878A"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.7502 66.4401H4.33016C3.54016 66.4401 2.91016 65.8301 2.91016 65.0901V16.4001C2.91016 15.6601 3.55016 15.0601 4.33016 15.0601H37.7602C38.5502 15.0601 39.1802 15.6701 39.1802 16.4101V65.1001C39.1802 65.8401 38.5402 66.4401 37.7602 66.4401H37.7502Z"
      stroke="#85878A"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.7702 55.08L16.9702 26.28C16.9702 26.28 14.8002 21.81 9.4502 23.31"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.92 38.0101C30.5212 38.0101 32.63 35.9013 32.63 33.3001C32.63 30.6988 30.5212 28.5901 27.92 28.5901C25.3187 28.5901 23.21 30.6988 23.21 33.3001C23.21 35.9013 25.3187 38.0101 27.92 38.0101Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.92 43.1201H15.75"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.75 33.3H27.92V58C27.92 59.1 27.02 60 25.92 60H17.75C16.65 60 15.75 59.1 15.75 58V33.3Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default RestaurantIcon
