import React from 'react'
import { SvgIconProps } from '@mui/material'

const DownloadIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}>
    <path
      d="M20.514 14.25h-5.613l-1.886 1.797c-.502.469-1.173.703-1.885.703-.754 0-1.424-.234-1.927-.703L7.318 14.25H1.747c-.754 0-1.34.586-1.34 1.25v3.75c0 .703.586 1.25 1.34 1.25h18.767c.712 0 1.34-.547 1.34-1.25V15.5c0-.664-.628-1.25-1.34-1.25Zm-2.01 4.063c-.587 0-1.006-.391-1.006-.938 0-.508.419-.938 1.005-.938.545 0 1.006.43 1.006.938 0 .547-.461.938-1.006.938Zm-8.337-3.165c.251.235.586.352.963.352.335 0 .67-.117.922-.352l5.362-5a1.15 1.15 0 0 0 0-1.757c-.503-.508-1.382-.508-1.885 0l-3.058 2.851V1.75c0-.664-.629-1.25-1.34-1.25-.755 0-1.341.586-1.341 1.25v9.492l-3.1-2.851c-.503-.508-1.382-.508-1.885 0a1.15 1.15 0 0 0 0 1.757l5.362 5Z"
      fill="#515356"
    />
  </svg>
)

export default DownloadIcon
