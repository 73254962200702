import { Box, Button, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PlanetIcon from '../../icons/PlanetIcon'

const styles = {
  noInterests: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  noInterestsMessage: {
    margin: '0.5rem 0 2rem',
  },
}

const NoInterests = ({ openModal }: any) => {
  const { t } = useTranslation()

  const noInterests = useMemo(
    () => (
      <Box sx={styles.noInterests} data-testid="Sec_Profile_NoInteresets">
        <PlanetIcon size={120} />
        <Typography variant="body1" sx={styles.noInterestsMessage}>
          {t('UserProfile.PageNoInterestsMessage')}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => openModal()}
          data-testid="Btn_Profile_AddInterests">
          {t('UserProfile.PageAddInterestsButton')}
        </Button>
      </Box>
    ),
    [t, openModal],
  )

  return noInterests
}

export default NoInterests
