import React from 'react'

const ProtectionIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.5098 2C28.8398 13.08 9.83984 11.5 9.83984 11.5V28.92C9.83984 66.91 41.5098 78 41.5098 78C41.5098 78 73.1798 66.91 73.1798 28.92V11.5C73.1798 11.5 54.1798 13.08 41.5098 2V2Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.5598 31.2305H53.3698C54.3198 31.2305 55.0898 32.0005 55.0898 32.9505V53.6405C55.0898 54.5905 54.3198 55.3605 53.3698 55.3605H29.5598C28.6098 55.3605 27.8398 54.5905 27.8398 53.6405V32.9505C27.8398 32.0005 28.6098 31.2305 29.5598 31.2305V31.2305Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M52.0389 31.2305H48.3789V27.6405C48.3789 23.8205 45.2789 20.7205 41.4589 20.7205C37.6389 20.7205 34.5389 23.8205 34.5389 27.6405V31.2305H30.8789V27.6405C30.8789 21.8105 35.6289 17.0605 41.4589 17.0605C47.2889 17.0605 52.0389 21.8105 52.0389 27.6405V31.2305V31.2305Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M44.4108 40.6214C44.4108 41.5514 43.9808 42.3814 43.3008 42.9114L43.3308 49.1914H39.6308V43.0514C38.8508 42.5214 38.5508 41.6314 38.5508 40.6214C38.5508 39.0014 39.8608 37.6914 41.4808 37.6914C43.1008 37.6914 44.4108 39.0014 44.4108 40.6214Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default ProtectionIcon
