import { Actions } from './actions'
import { ProjectsState } from './context'

export function reducer(state: ProjectsState, action: Actions): ProjectsState {
  switch (action.type) {
    case 'APPLY_FILTER': {
      const { pageSize = 25 } = state

      return {
        ...state,
        filter: action.payload,
        page: 0,
        displayedCount: pageSize,
      }
    }

    case 'NEXT_PAGE': {
      const {
        page = 0,
        pageSize = 25,
        projects = [],
        currentPageStartIndex = 0,
      } = state

      const currentPage = page + 1
      const displayedCount = pageSize * (currentPage + 1)

      if (projects.length <= displayedCount) {
        return {
          ...state,
          currentPageStartIndex: currentPageStartIndex + pageSize,
          displayedCount: projects.length,
        }
      }

      return {
        ...state,
        page: currentPage,
        currentPageStartIndex: currentPageStartIndex + pageSize,
        displayedCount,
      }
    }

    default: {
      return {
        ...state,
      }
    }
  }
}

export default reducer
