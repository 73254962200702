import React from 'react'

const SearchIcon2 = () => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="Group_2">
        <path
          id="Vector"
          d="M38.3075 40.8282C38.068 40.8282 37.8525 40.7318 37.6849 40.5632L27.7225 30.5402C27.3633 30.1788 27.3633 29.6246 27.7225 29.2632C28.0818 28.9018 28.6326 28.9018 28.9918 29.2632L38.9541 39.2862C39.3133 39.6235 39.3133 40.2018 38.9541 40.5632C38.7625 40.7318 38.547 40.8282 38.3075 40.8282Z"
          fill="#149AD6"
        />
      </g>
      <g id="Group_3">
        <path
          id="Vector_2"
          d="M17.042 34.8774C7.70235 34.8774 0.0869141 27.2397 0.0869141 17.8191C0.0869141 8.42254 7.70235 0.760742 17.042 0.760742C26.3817 0.760742 33.9972 8.42254 33.9972 17.8191C33.9972 27.2397 26.3817 34.8774 17.042 34.8774ZM17.042 2.54368C8.68421 2.54368 1.88301 9.38629 1.85906 17.8191C1.85906 26.2278 8.66026 33.0704 17.0181 33.0704C25.3759 33.0704 32.2011 26.2278 32.2011 17.8191C32.2011 9.41039 25.3999 2.54368 17.042 2.54368Z"
          fill="#009CDE"
        />
      </g>
      <g id="Group_4">
        <path
          id="Vector_3"
          d="M7.00801 17.8193H5.21191C5.21191 11.2658 10.5283 5.91699 17.0422 5.91699V7.72402C11.5102 7.72402 7.00801 12.2536 7.00801 17.8193Z"
          fill="#CECECD"
        />
      </g>
    </g>
  </svg>
)

export default SearchIcon2
