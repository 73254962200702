import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import tokens from '../../../styles/tokens.json'

const SkipToMainContent = () => {
  const { t } = useTranslation()

  const [isVisible, setVisibility] = useState(false)

  const focusEvent = () => {
    setVisibility(true)
  }

  const blurEvent = () => {
    setVisibility(false)
  }

  const clickEvent = () => {
    const mc = document.getElementById('main-content')

    if (mc !== null) {
      mc.setAttribute('aria-label', 'Main Content')
      mc.focus()
      window.scrollTo({ top: 0 })
    }
  }

  return (
    <div
      className={`absolute top-[0] ${
        isVisible ? 'left-[0px]' : 'left-[-250px]'
      } w-[164]`}>
      <div className="flex-none">
        <Button
          sx={{
            color: 'white',
            backgroundColor: tokens.colors.rsmBlue.accessibility,
            borderColor: 'white',
          }}
          onFocus={focusEvent}
          onBlur={blurEvent}
          onClick={clickEvent}
          tabIndex={0}
          role="link"
          className="w-[212px] h-[52px] font-prelo-book z-[50]"
          variant="contained"
          data-testid="Btn_Nav_SkipLink">
          {t('Navigation.SkipToMainContent')}
        </Button>
      </div>
    </div>
  )
}

export default SkipToMainContent
