import React from 'react'

const PPTX = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="2" y="12" width="16" height="7" rx="0.8" fill="#E34E26" />
    <path
      d="M5.1313 15.3674H5.73994C6.03145 15.3674 6.17647 15.2231 6.175 14.9346C6.175 14.646 6.02998 14.5017 5.73994 14.5017H5.1313V15.3674ZM5.1313 15.9299V17H4.50068V13.9392H5.80586C6.12373 13.9392 6.37275 14.0293 6.55293 14.2095C6.73311 14.3896 6.82319 14.6321 6.82319 14.9368C6.82319 15.2415 6.73311 15.4832 6.55293 15.6619C6.37275 15.8406 6.12373 15.9299 5.80586 15.9299H5.1313ZM8.0856 15.3674H8.69424C8.98574 15.3674 9.13076 15.2231 9.1293 14.9346C9.1293 14.646 8.98428 14.5017 8.69424 14.5017H8.0856V15.3674ZM8.0856 15.9299V17H7.45498V13.9392H8.76016C9.07803 13.9392 9.32705 14.0293 9.50723 14.2095C9.6874 14.3896 9.77749 14.6321 9.77749 14.9368C9.77749 15.2415 9.6874 15.4832 9.50723 15.6619C9.32705 15.8406 9.07803 15.9299 8.76016 15.9299H8.0856ZM11.6419 14.5017V17H11.0113V14.5017H10.2115V13.9392H12.4417V14.5017H11.6419ZM14.2227 15.9299H14.2139L13.6119 17H12.9087L13.8316 15.4114L12.9593 13.9392H13.691L14.2535 14.895H14.2645L14.8072 13.9392H15.5455L14.6687 15.4114L15.6268 17H14.8753L14.2227 15.9299Z"
      fill="white"
    />
  </svg>
)

export default PPTX
