/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children, Fragment, ReactElement, ReactNode } from 'react'

export default function flattenReactFragments(reactNode: ReactNode) {
  return Children.toArray(reactNode)
    .map((child) =>
      (child as ReactElement<any>).type === Fragment
        ? (child as ReactElement<any>)?.props?.children
        : child,
    )
    .flat()
}
