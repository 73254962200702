import React from 'react'

const PlaceSettingIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M35.0491 59.3598C47.8952 59.3598 58.3091 48.946 58.3091 36.0998C58.3091 23.2537 47.8952 12.8398 35.0491 12.8398C22.2029 12.8398 11.7891 23.2537 11.7891 36.0998C11.7891 48.946 22.2029 59.3598 35.0491 59.3598Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M35.0498 50.0606C42.7597 50.0606 49.0098 43.8105 49.0098 36.1006C49.0098 28.3907 42.7597 22.1406 35.0498 22.1406C27.3399 22.1406 21.0898 28.3907 21.0898 36.1006C21.0898 43.8105 27.3399 50.0606 35.0498 50.0606Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M65.0591 57.1005C65.0591 57.6905 64.5891 58.1705 63.9991 58.1805H63.4691C62.8791 58.1805 62.3991 57.7105 62.3991 57.1205L62.3691 13.8905C62.3691 13.3005 62.8391 12.8205 63.4291 12.8105H63.9591C65.0691 12.8105 66.3991 14.5805 66.3991 23.7705L65.0591 57.1005Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M71.9792 36.5691C70.0792 35.6991 68.6992 33.0691 68.6992 29.9591C68.6992 26.1391 70.7792 23.0391 73.3492 23.0391C75.9192 23.0391 77.9992 26.1391 77.9992 29.9591C77.9992 33.0991 76.5892 35.7491 74.6692 36.5991L74.7192 57.2691C74.7192 57.8591 74.2492 58.3391 73.6592 58.3391H73.1292C72.5392 58.3391 72.0592 57.8691 72.0592 57.2791L71.9892 38.6691V36.5791L71.9792 36.5691Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.65 31.7504L4.62 22.9004H7.25L7.28 31.7804L7.25 22.9004H9.45V36.9204H8.49H7.04L7.09 57.5904C7.09 58.1804 6.62 58.6604 6.03 58.6604H5.5C4.91 58.6604 4.43 58.1904 4.43 57.6004L4.36 36.9204H2V22.9004H4.62L4.65 31.7504Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default PlaceSettingIcon
