import { sxStyles } from '../../styles/sxStyles'

const useStyles = () =>
  sxStyles({
    sCard: (theme) => ({
      display: 'block',
      [theme.breakpoints.down('desktop')]: {
        display: 'none',
      },
    }),
    fullSCard: (theme) => ({
      display: 'none',

      [theme.breakpoints.down('desktop')]: {
        display: 'block',
        padding: '1rem',
      },
      [theme.breakpoints.down('tablet')]: {
        padding: '1.3rem',
      },
    }),
    grid: (theme) => ({
      padding: '0rem 2rem',
      [theme.breakpoints.down('tablet')]: {
        padding: '0rem 1rem',
      },
    }),
  })
export default useStyles
