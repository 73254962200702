import * as React from 'react'
import { SVGProps } from 'react'

const CreditVisaIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_2_00000090991498795456351180000018412173976298168228_"
    data-testid="credit-visa-icon"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 285.4 185.8"
    enableBackground="new 0 0 285.4 185.8"
    xmlSpace="preserve"
    width={width}
    height={height}>
    <title>Visa</title>
    <g id="Visa">
      <g>
        <path
          fill="#000080"
          stroke="#000080"
          strokeWidth="2.14"
          strokeMiterlimit="10"
          d="M22.3,1.1h240.9c11.7,0,21.2,9.5,21.2,21.2
			v141.3c0,11.7-9.5,21.2-21.2,21.2H22.3c-11.7,0-21.2-9.5-21.2-21.2V22.3C1.1,10.6,10.6,1.1,22.3,1.1z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M142.6,62.3l1.2-1.3l0-0.2L121,60.6c0,0.1,0,0.1-0.1,0.1c0.2,1.5,0.1,3.2-0.6,4.9l-19,54.6
				c-0.7,2-1.6,3.5-2.7,4.4l23.1,0l0,0c-0.6-0.7-0.6-1.9,0-3.7L142.6,62.3L142.6,62.3z"
          />
          <path
            fill="#FFFFFF"
            d="M98.1,61l0.4,2.1l-26.1,40c-0.1-0.1-0.2-0.1-0.3-0.1l-0.2-41.9H43.5c0.3,0.3,1.2,1.1,2.7,2.3
				c1.6,1.3,2.8,2.5,3.7,3.8c1.5,2.1,2.2,3.6,2.2,4.4l0.2,53.3l20.6,0.2L117.5,61L98.1,61L98.1,61z"
          />
          <path
            fill="#FFFFFF"
            d="M176.5,91.4c-0.5-0.4-3.6-2.4-9.4-6.2c-5.2-3.4-7.5-5.2-7.6-8.1c-0.1-4,5-7.2,13.8-5.8
				c4.9,0.8,9.6,3.1,14.2,7.1l0.5-0.1l2.5-13.5c-4.4-3-8.4-4.9-12.1-5.7c-14.6-3.3-39.4,2.1-38.8,21.7c0.2,6.6,3.8,11.5,10.8,14.7
				l1.8,0.8c5.3,2.5,13,6.3,12.8,12.3c-0.3,8.4-18.5,7-28.5-0.3l-4.3-3.3l-0.3,13.8c-0.1,2.1,5.3,4.9,8.3,6c13,4.7,29,4,38.3-5.7
				l0,0C188,109.1,187.3,99.9,176.5,91.4L176.5,91.4z"
          />
          <path
            fill="#FFFFFF"
            d="M241.4,120.4l-3.9-59.7l-20,0l-0.2,1.6l-37.6,62.3l17.9-0.1c-0.6-1.4-0.7-2.6-0.2-3.8l7.5-12.2l17.8-0.3
				c0,0.3,0.1,3.9,0.3,10.9c0.1,2.4-0.1,4.2-0.4,5.4l19.4-0.1l0,0L241.4,120.4L241.4,120.4z M211.1,98l9.1-15.3l1.1,15.1l0,0.2
				L211.1,98z"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CreditVisaIcon
