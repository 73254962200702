import React from 'react'
import { useParams } from 'react-router-dom'
import AccountInformation from './AccountInformation'
import AccountReferenceDocuments from './AccountReferenceDocuments'
import ScheduledPayments from './ScheduledPayments'
import PaymentManagement from './PaymentManagement'
import HelpfulLinks from './HelpfulLinks'
import ClientLayoutMultiple from '../ClientLayoutMultiple'
import ClientLayoutSingle from '../ClientLayoutSingle'

const AccountManagement = () => {
  const { clientId = '' } = useParams()

  if (clientId) {
    return (
      <ClientLayoutSingle
        accountInfoSlot={
          <AccountInformation layoutContainer="accountManagement" />
        }
        paymentManagementSlot={
          <PaymentManagement
            key={clientId}
            layoutContainer="accountManagement"
          />
        }
        supportSlot={<HelpfulLinks />}
        referenceDocumentsSlot={<AccountReferenceDocuments />}
        scheduledPaymentsSlot={<ScheduledPayments />}
      />
    )
  }

  return (
    <ClientLayoutMultiple
      accountInfoSlot={
        <AccountInformation layoutContainer="accountManagement" />
      }
      supportSlot={<HelpfulLinks />}
      referenceDocumentsSlot={<AccountReferenceDocuments />}
      scheduledPaymentsSlot={<ScheduledPayments />}
    />
  )
}

export default AccountManagement
