import React from 'react'

const AccountUserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="109"
    height="116"
    viewBox="0 0 109 116"
    fill="none">
    <g filter="url(#filter0_d_7298_44922)">
      <path
        d="M24.0439 27.7734C24.0439 43.1128 37.6801 55.5469 54.501 55.5469C71.3219 55.5469 84.957 43.1128 84.957 27.7734C84.957 12.4341 71.3209 0 54.501 0C37.681 0 24.0439 12.4341 24.0439 27.7734Z"
        fill="#211C5B"
      />
    </g>
    <g filter="url(#filter1_d_7298_44922)">
      <path
        d="M104.999 87.7086C104.647 88.0452 104.288 88.3782 103.928 88.7077C97.5093 94.5609 90.0343 99.1553 81.7086 102.369C73.0891 105.692 63.935 107.378 54.498 107.378C45.061 107.378 35.908 105.692 27.2885 102.369C18.9637 99.1553 11.4877 94.5609 5.06911 88.7077C4.70883 88.3791 4.3505 88.0452 4 87.7086C4.46015 85.6784 5.12296 83.7142 5.9806 81.8233C6.59936 80.4662 7.31798 79.1475 8.13743 77.8699C10.9385 73.4978 14.8507 69.7025 19.7665 66.5866C24.3984 63.6484 29.8115 61.3887 35.8522 59.8691C41.6755 58.404 47.9492 57.6621 54.499 57.6621C61.0488 57.6621 67.3264 58.4058 73.1498 59.8709C79.1934 61.3905 84.6056 63.652 89.2403 66.5902C94.1542 69.7079 98.0674 73.505 100.867 77.877C101.684 79.1529 102.401 80.4707 103.017 81.825C103.876 83.716 104.539 85.6811 105 87.7095L104.999 87.7086Z"
        fill="#149AD6"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7298_44922"
        x="20.0439"
        y="0"
        width="68.9131"
        height="63.5469"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7298_44922"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7298_44922"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_7298_44922"
        x="0"
        y="57.6621"
        width="109"
        height="57.7158"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7298_44922"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_7298_44922"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default AccountUserIcon
