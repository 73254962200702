import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

export enum WizardStep {
  Industry = 'Industry',
  Service = 'Service',
  Topic = 'Topic',
  ContactForm = 'ContactForm',
}

export interface ProfileModalVisibility {
  isOpen: boolean
  initialStep: WizardStep
  fromProfile: boolean
}

interface ProfileModalState {
  profileModalVisibility: ProfileModalVisibility
  // other states like selected industries etc go here
  reloadHomeInsights: boolean
}

// Define the initial state
const initialState: ProfileModalState = {
  profileModalVisibility: {
    isOpen: false,
    initialStep: WizardStep.Industry,
    fromProfile: false,
  },
  reloadHomeInsights: false,
}

export const profileModalSlice = createSlice({
  name: 'profileModal',
  initialState,
  reducers: {
    openProfileModalOnLoad: (): ProfileModalState => ({
      profileModalVisibility: {
        ...initialState.profileModalVisibility,
        isOpen: true,
      },
      reloadHomeInsights: false,
    }),
    openProfileModalFromProfile: (): ProfileModalState => ({
      profileModalVisibility: {
        isOpen: true,
        initialStep: WizardStep.Industry,
        fromProfile: true,
      },
      reloadHomeInsights: false,
    }),
    closeProfileModal: (): ProfileModalState => ({
      profileModalVisibility: initialState.profileModalVisibility,
      reloadHomeInsights: true,
    }),
  },
})

export const {
  openProfileModalOnLoad,
  openProfileModalFromProfile,
  closeProfileModal,
} = profileModalSlice.actions

export const getReloadHomeInsights = (state: RootState) =>
  state.profileModal.reloadHomeInsights

export default profileModalSlice.reducer
