import React from 'react'

const EmptyFolderIcon = () => (
  <svg
    width="121"
    height="120"
    viewBox="0 0 121 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 102.832L35.1103 57.0215H111.5L87.3893 102.832H9.5Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M9.5 102.833V38.9101H49.4591L50.1353 35.5287C50.4294 34.0585 51.7231 33 53.2227 33H77.9215C79.421 33 80.6854 34.0291 81.0088 35.4993L81.8321 39.4099H88.9183V57.1107"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default EmptyFolderIcon
