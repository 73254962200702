import React from 'react'

const ONE = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="5" y="12" width="14" height="7" rx="0.8" fill="#9722CF" />
    <path
      d="M9.55547 14.6797C9.46025 14.5068 9.27422 14.4204 8.99736 14.4204C8.72197 14.4204 8.53594 14.5068 8.43926 14.6797C8.34404 14.8525 8.29644 15.1045 8.29644 15.4355C8.29497 15.7666 8.33672 16.0229 8.42168 16.2046C8.51836 16.4126 8.71025 16.5166 8.99736 16.5166C9.28447 16.5166 9.47637 16.4126 9.57305 16.2046C9.65801 16.0215 9.70049 15.7651 9.70049 15.4355C9.70049 15.1045 9.65215 14.8525 9.55547 14.6797ZM8.99736 17.0439C8.47881 17.0439 8.11626 16.8857 7.90972 16.5693C7.72661 16.291 7.63506 15.9167 7.63506 15.4465C7.63506 14.6877 7.86797 14.2095 8.33379 14.0117C8.51543 13.9341 8.73662 13.8953 8.99736 13.8953C9.51885 13.8967 9.88213 14.0483 10.0872 14.3501C10.2688 14.6211 10.3604 14.9866 10.3619 15.4465C10.3619 16.2214 10.1297 16.7129 9.66533 16.9209C9.48076 17.0029 9.25811 17.0439 8.99736 17.0439ZM11.042 17V13.9392H11.6726L12.8789 15.8442H12.8877V13.9392H13.5007V17H12.8701L11.6638 15.1675H11.655V17H11.042ZM14.8994 14.5017V15.1367H16.1408V15.6992H14.8994V16.4375H16.3671V17H14.2687V13.9392H16.3452V14.5017H14.8994Z"
      fill="white"
    />
  </svg>
)

export default ONE
