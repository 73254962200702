import React from 'react'

const CoinsIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M68.3403 39.6406C74.4403 42.5406 78.2103 46.5506 78.2103 50.9806C78.2103 59.8206 63.2103 66.9806 44.7103 66.9806C27.8903 66.9806 13.9703 61.0606 11.5703 53.3406"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M69.4698 45.8398C71.5398 47.3798 72.7198 49.1298 72.7198 50.9898C72.7198 57.0698 60.1798 61.9898 44.7198 61.9898C34.2698 61.9898 25.1598 59.7398 20.3398 56.4098"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M49.4597 56.9805C48.4597 58.9805 54.4797 61.2905 54.4797 61.2905"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.8906 60.9602C32.8906 60.9602 40.4706 59.9902 39.4706 56.9902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.5801 53.3505V64.5005C11.5801 64.5005 19.4601 76.9805 45.4601 76.9805C71.4601 76.9805 78.4601 63.9805 78.4601 63.9805L78.2101 50.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.4609 59.9805V68.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.4609 62.9805V71.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.4609 64.9805V73.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M33.4609 65.9805V75.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.4609 66.9805V76.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.4609 66.9805V76.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.4609 65.9805V75.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M62.4609 64.9805V73.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.4609 62.9805V71.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M72.4609 59.9805V69.9805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.25 30C58.714 30 71.25 25.0751 71.25 19C71.25 12.9249 58.714 8 43.25 8C27.786 8 15.25 12.9249 15.25 19C15.25 25.0751 27.786 30 43.25 30Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.4199 28.9692C31.4199 28.9692 38.9999 27.9992 37.9999 24.9992C36.9999 21.9992 32.5199 21.9992 32.7599 18.9992C32.9999 15.9992 35.9999 10.9992 37.9999 11.9992C39.9999 12.9992 43.5099 11.9992 47.2499 12.9992C50.9899 13.9992 52.9999 14.9992 52.9999 17.9992C52.9999 20.9992 48.9999 22.9992 47.9999 24.9992C46.9999 26.9992 53.0199 29.3092 53.0199 29.3092"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15 28V37"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20 31V40"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M25 33V42"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32 34V44"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39 35V45"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47 35V45"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55 34V44"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61 33V42"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66 31V40"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M71 28V38"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M59.4009 43.3009C53.3409 46.2009 44.9609 47.9909 35.7109 47.9909C17.2109 47.9909 2.21094 40.8309 2.21094 31.9909C2.21094 28.1609 5.03094 24.6409 9.75094 21.8809"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.3907 41.8594C14.1107 40.0694 7.7207 36.3194 7.7207 31.9794C7.7207 30.5194 8.4407 29.1294 9.7507 27.8594"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.58008 34.3496V45.4996C2.58008 45.4996 10.4601 57.9796 36.4601 57.9796C62.4601 57.9796 69.4601 44.9796 69.4601 44.9796L69.3601 39.9996"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.46094 40.9805V49.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.4609 43.9805V52.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.4609 45.9805V54.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.4609 46.9805V56.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.4609 47.9805V57.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M39.4609 47.9805V57.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.4609 46.9805V56.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M53.4609 45.9805V54.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.4609 43.9805V52.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.4609 41.9805V50.9805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.25 35C61.7515 35 76.75 27.8366 76.75 19C76.75 10.1634 61.7515 3 43.25 3C24.7485 3 9.75 10.1634 9.75 19C9.75 27.8366 24.7485 35 43.25 35Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.1191 21.3602V32.5102C10.1191 32.5102 17.9991 44.9902 43.9991 44.9902C56.1391 44.9902 64.1391 42.1602 69.2091 39.1302C75.0091 35.6802 76.9991 31.9902 76.9991 31.9902L76.7491 18.9902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default CoinsIcon
