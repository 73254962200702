import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import PieChartProps, { assignMetricPercentages } from './PieChartProps'
import '../../../index.css'

const PieChartLegend = ({ pProps }: { pProps: PieChartProps | undefined }) => {
  const { t } = useTranslation()
  // chartProps,
  const [chartProps, setChartProps] = useState<PieChartProps>()

  useEffect(() => {
    if (pProps !== undefined && chartProps === undefined) {
      setChartProps(assignMetricPercentages(pProps))
    }
  })

  return (
    <div className="flex flex-col align-middle ">
      {React.Children.toArray(
        chartProps?.chartItems.map((item) => (
          <div className="flex flex-row pt-[0px] pb=[0px]">
            <div className="w-[55px] items-end">
              <Typography
                align="right"
                color={item.metricColor}
                className="pl-[0px] align-baseline">
                <span className="font-prelo-book  text-2xl pt-[0px] pb-[0px] mt-[0px] mb-[0px]">
                  {item.metricPercent}%
                </span>
              </Typography>
            </div>
            <div className="flex items-bottom">
              <Typography className="flex pl-[4px] mb-[0px] mt-[0px] pb-[0px] pt-[0px] align-bottom">
                <span className="flex font-normal font-prelo-book mt-[0px] mb-[4px] items-end">
                  {t(item.metricInternationalizationKey)}
                </span>
              </Typography>
            </div>
          </div>
        )),
      )}
    </div>
  )
}

export default PieChartLegend
