import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled, Typography } from '@mui/material'
import { SharedArticleInfoViewModel } from '@rsmus/ecp-cmsservice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { AnalyticEvent } from '../../../analytics/adobe/types'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import ArticleHeroImageContainer from '../../layouts/ArticleHeroImageContainer'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const styles: Styles = {
  mainContainer: (theme) => ({
    paddingRight: '1rem',
    paddingLeft: '1rem',
    [theme.breakpoints.only('tablet')]: {
      paddingRight: '2rem',
      paddingLeft: '2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingRight: '11.0625rem',
      paddingLeft: '11.0625rem',
    },
  }),
  backLinkContainer: (theme) => ({
    color: theme.palette.secondary.main,
    marginTop: '1.5rem',
  }),
  backLinkText: {
    textDecorationLine: 'underline',
  },
  heading: (theme) => ({
    marginTop: '2.5rem',
    marginBottom: '1rem',
    fontFamily: 'Prelo-Light, sans-serif',
    [theme.breakpoints.down('tablet')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '3rem',
      lineHeight: '3.5rem',
    },
  }),
  subHeader: {
    marginBottom: '2rem',
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.875rem',
    lineHeight: '1rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
  },
  verticalDivider: {
    paddingRight: '0.625rem',
    paddingLeft: '0.625rem',
  },
}

interface ArticleHeaderProps {
  articleMetadata: ArticleMetaData
  sharedArticleInfoData: SharedArticleInfoViewModel
}

const ArticleHeader = ({
  articleMetadata,
  sharedArticleInfoData,
}: ArticleHeaderProps) => {
  const { t } = useTranslation()

  const Styled = {
    ArticleBanner: styled(ArticleHeroImageContainer)(({ theme }) => ({
      width: '100%',
      height: '24.1875rem',
      [theme.breakpoints.down('desktop')]: {
        height: '20.1875rem',
      },
      [theme.breakpoints.down('tablet')]: {
        height: '18.375rem',
      },
    })),
  }

  return (
    <>
      {isFeatureFlagEnabled('InsightArticle_BannerImage') && (
        <Styled.ArticleBanner
          imageUrl={articleMetadata.imageUrl}
          imageTestId="Img_Insight_ArticleImage"
        />
      )}

      <Box sx={styles.mainContainer}>
        <Box sx={styles.backLinkContainer}>
          <NavLink
            to="/insights"
            data-testid="Lnk_Insight_ViewAllInsights"
            data-analytic-event={analyticEventType}>
            <FontAwesomeIcon icon={faChevronLeft} /> {'\u00A0'}{' '}
            <Box component="span" sx={styles.backLinkText}>
              {t('InsightArticle.BackToInsights')}
            </Box>
          </NavLink>
        </Box>
        <Typography
          variant="h1"
          sx={styles.heading}
          id="insightTitle"
          data-testid="Hed_Insight_Title">
          {articleMetadata.title}
        </Typography>
        <Box
          component="p"
          sx={styles.subHeader}
          data-testid="Txt_Insight_CategoryDate">
          <Box component="span" data-testid="Txt_Insight_Category">
            {articleMetadata.label && articleMetadata.label.length > 0
              ? articleMetadata.label
              : t('InsightArticle.InsightArticle')}
          </Box>
          <Box component="span" sx={styles.verticalDivider}>
            |
          </Box>
          <Box component="span" data-testid="Txt_Insight_Date">
            {articleMetadata.publishedOn?.toLocaleDateString('en-us', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              timeZone: 'UTC',
            })}
          </Box>
          {!!sharedArticleInfoData?.sharedByUserName && (
            <>
              <Box component="span" sx={styles.verticalDivider}>
                |
              </Box>
              <Box component="span" data-testid="Txt_Insight_Shared_Article">
                {`${sharedArticleInfoData?.sharedByUserName} ${t(
                  'InsightArticle.ShareArticleMessage',
                )}`}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ArticleHeader
