const ConsentToElectronicCommunicationsUSAndCanada = () => `<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title>Terms_And_Conditions.html</title>
    <meta name="author" content="Ausland, Melissa" />
    <style type="text/css">
        * {
            margin: 0;
            text-indent: 0;
        }

        h1 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 14pt;
        }

        p {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 14pt;
            margin: 0pt;
        }

        .s1 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 14pt;
        }

        .a {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 14pt;
        }

        .s2 {
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 9pt;
            vertical-align: 4pt;
        }

        #l2 {
            padding-left: 0pt;
            counter-reset: d1 1;
        }

        #l2>li {
            display: block;
        }

        #l2>li>*:first-child:before {
            counter-increment: d1;
            content: "(" counter(d1, decimal) ") ";
            color: black;
            font-family: Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 14pt;
        }

        #l2>li:first-child>*:first-child:before {
            counter-increment: d1 0;
        }
    </style>
</head>

<body>
    <h1 style="text-decoration: underline;">
        CONSENT TO ELECTRONIC COMMUNICATIONS: US and Canada
    </h1>
    <h2 style="
        padding-top: 4pt;
        padding-left: 5pt;
        text-indent: 0pt;
        text-align: left;
      ">
        CONSENT TO ELECTRONIC COMMUNICATIONS:
    </h2>
    <p style="text-indent: 0pt; text-align: left"><br /></p>
    <p style="padding-left: 5pt; text-indent: 0pt; text-align: left">
       The following terms and conditions govern electronic communications
	   between RSM and you (the “Consent”). By clicking the “Accept” button
	   below, you are also confirming that you have agreed to the terms and
	   conditions of the Consent and that you have downloaded or printed a
	   copy of this Consent for your records. A copy of this Consent will appear
	   on the Dashboard page. 
    </p>
    <p style="padding-top: 9pt; padding-left: 5pt; text-indent: 0pt; text-align: left">
	
        You agree that:
    </p>
    <ul id="l1">
        <li data-list-text="·">
            <p style="
            padding-top: 10pt;
            text-align: left;
          ">
				Any disclosure, notice, record, or other type of information that is
				provided to you in connection with your transactions with us,
				including this Consent (the “Communications”), may be sent to you
				electronically by posting the information at our portal, myRSM or
				by sending it to you by e-mail, as permitted by applicable law.
            </p>
        </li>
        <li data-list-text="·">
            <p style="
            padding-top: 9pt;
            text-align: justify;
          ">
				We will not be obligated to provide any Communication to you in paper
				form unless you specifically request us to do so or we are required
				to do so.
            </p>
        </li>
        <li data-list-text="·">
            <p style="
            padding-top: 10pt;
            text-align: left;
          ">
				You may obtain a free copy of any Communication by contacting us at
				Accounts_Receivable@rsmus.com /
				Accounts_Receivable@rsmcanada.com,
				or by calling us at 833-597-2118. You also can withdraw your consent
				to ongoing electronic communications in the same manner and ask that
				they be sent to you in paper or non-electronic form.
            </p>
        </li>
        <li data-list-text="·">
            <p style="
            padding-top: 10pt;
            text-align: left;
          ">
				You agree to provide us with your current e-mail address. If your
				e- mail address changes, you must send us a notice of the new address by
				writing to us at 4650 E 53<span class="s2">rd</span> Street, Davenport, IA 52807
				or sending us an e-mail at
				Accounts_Receivable@rsmus.com or Accounts_Receivable@rsmcanada.com,
				using secure messaging, at least five (5) days before the change.
            </p>
        </li>
        <li data-list-text="·">
            <p style="
            padding-top: 10pt;
            text-align: left;
          ">
				In order to receive electronic communications from us, you will need a
				working connection to the Internet. You will also need either a
				printer connected to your computer to print notices or sufficient hard
				drive space available to save the information (e.g., 1 megabyte or
				more). You must have your own Internet service provider.
            </p>
        </li>
        <li data-list-text="·">
            <p style="
            padding-top: 10pt;
            text-align: justify;
          ">
				We may amend (add to, delete or change) the terms of this Consent to
				electronic communication by providing you with advance notice in
				accordance with applicable law.
            </p>
        </li>
    </ul>
    <p style="
        padding-top: 10pt;
        padding-left: 11pt;
        text-indent: 0pt;
        text-align: left;
      ">

        By clicking the “Accept” button below, you are electronically signing this
        document and confirming that:
    </p>
    <ol id="l2">
        <li data-list-text="(1)">
            <p style="
            padding-top: 4pt;
            padding-left: 47pt;
            text-indent: -36pt;
            text-align: left;
          ">
                Your system meets the requirements set forth above;
            </p>
        </li>
        <li data-list-text="(2)">
            <p style="
            padding-top: 9pt;
            padding-left: 47pt;
            text-indent: -36pt;
            text-align: left;
          ">
                You agree to receive Communications electronically;
            </p>
        </li>
        <li data-list-text="(3)">
            <p style="
            padding-top: 9pt;
            padding-left: 11pt;
            text-indent: 0pt;
            text-align: left;
          ">
		  
				You are able to receive emails send to the email address you have provided
				to us and to access and print or store information provided by us
				electronically; and
            </p>
        </li>
        <li data-list-text="(4)">
            <p style="
            padding-top: 9pt;
            padding-left: 11pt;
            text-indent: 0pt;
            text-align: left;
          ">
				You consent to having information regarding this transaction, and
				others you may enter into with us, transmitted via email to the email
				address you have provided to us, and to having that information
				communicated with any third party who has or obtains access to emails
				sent to that address.
            </p>
        </li>
    </ol>
    <p style="
        padding-top: 9pt;
        padding-left: 11pt;
        text-indent: 0pt;
        line-height: 108%;
        text-align: left;
      ">
        <b>YOUR ELECTRONIC SIGNATURE: </b>You acknowledge and agree that when you
		click the “Accept” button below, you are providing your electronic signature on
		this document. By electronically signing this document, you are agreeing
		to all the terms and conditions set forth in this Consent and certifying
		that all information you have provided to us is complete and accurate. You
		agree that your electronic signature shall have the same force and effect
		and shall bind you to this Agreement in the same manner and to the same
		extent as a physical signature would do, in accordance with the federal
		Electronic Signatures in Global and National Commerce Act (“<u>ESIGN</u>”)
		to the extent applicable. You also agree that any documents you receive
		from us electronically, including this Consent, are electronic records and
		that, as such, they may be transferred, authenticated, stored, and
		transmitted by electronic means.
    </p>
</body>

</html>`

export default ConsentToElectronicCommunicationsUSAndCanada
