import React from 'react'

const VotePinIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40 77.5508C60.4345 77.5508 77 60.9853 77 40.5508C77 20.1162 60.4345 3.55078 40 3.55078C19.5655 3.55078 3 20.1162 3 40.5508C3 60.9853 19.5655 77.5508 40 77.5508Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.63086 22.6309H72.5309"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.41992 58.1016H72.5299"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.4707 13.4199H50.7007"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.3594 66.0215H50.5894"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.8708 33.5898H58.5508V49.9998H67.9908"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.7308 41.6699H58.5508"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.2501 33.5898H42.8301"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.5391 50.8698V33.5898"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34 51C37.866 51 41 46.9706 41 42C41 37.0294 37.866 33 34 33C30.134 33 27 37.0294 27 42C27 46.9706 30.134 51 34 51Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1602 32.8809L17.8102 51.1309L24.3302 32.8809"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default VotePinIcon
