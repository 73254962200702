import * as React from 'react'
import { SVGProps } from 'react'

const CreditDinersIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_2_00000124871323730897331450000009325368883967644044_"
    data-testid="credit-diners-icon"
    x="0px"
    y="0px"
    viewBox="0 0 285.4 185.8"
    enableBackground="new 0 0 285.4 185.8"
    width={width}
    height={height}>
    <title>Discover Card</title>
    <g id="Diners_Club">
      <g>
        <path
          fill="#004A98"
          stroke="#004A98"
          strokeWidth="2.14"
          strokeMiterlimit="10"
          d="M22.3,1.1h240.9c11.7,0,21.2,9.5,21.2,21.2
           v141.3c0,11.7-9.5,21.2-21.2,21.2H22.3c-11.7,0-21.2-9.5-21.2-21.2V22.3C1.1,10.6,10.6,1.1,22.3,1.1z"
        />
        <g>
          <ellipse
            transform="matrix(0.2163 -0.9763 0.9763 0.2163 -42.1488 134.5905)"
            fill="#004A98"
            cx="62.8"
            cy="93.5"
            rx="36.6"
            ry="36.6"
          />
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M123.4,77.5c0-2.5-1.3-2.3-2.5-2.3v-0.7h8c7.2,0,11,4.7,11,9.7c0,2.7-1.6,9.5-11.4,9.5h-7.7v-0.7
                       c1.7-0.1,2.5-0.3,2.5-2.1V77.5L123.4,77.5L123.4,77.5z M126.1,90.4c0,2.1,1.6,2.3,2.9,2.3c6,0,7.8-4.4,7.8-8.6
                       c0-5.1-3.3-8.9-8.6-8.9c-1.2,0-1.7,0.1-2.1,0.1V90.4z"
              />
              <path
                fill="#FFFFFF"
                d="M140.9,92.9h0.5c0.8,0,1.3,0,1.3-0.9v-7.4c0-1.2-0.4-1.4-1.4-2v-0.4c1.3-0.4,2.9-0.9,3-1.1
                       c0.1-0.1,0.4-0.1,0.5-0.1s0.1,0.1,0.1,0.4V92c0,0.9,0.6,0.9,1.4,0.9h0.5v0.7H141v-0.7L140.9,92.9L140.9,92.9z M143.8,76.9
                       c-0.6,0-1.3-0.7-1.3-1.4c0-0.7,0.6-1.3,1.3-1.3s1.3,0.5,1.3,1.3S144.6,76.9,143.8,76.9z"
              />
              <path
                fill="#FFFFFF"
                d="M149.4,84.8c0-1.1-0.3-1.3-1.6-1.8v-0.5c1.2-0.4,2.4-0.8,3.7-1.3c0.1,0,0.1,0.1,0.1,0.3v1.8
                       c1.6-1.2,2.9-2.1,4.7-2.1c2.4,0,3.1,1.7,3.1,3.8V92c0,0.9,0.6,0.9,1.4,0.9h0.5v0.7h-5.9v-0.7h0.5c0.8,0,1.3,0,1.3-0.9v-7.2
                       c0-1.6-0.9-2.3-2.5-2.3c-0.9,0-2.2,0.7-3.1,1.3v8.1c0,0.9,0.6,0.9,1.4,0.9h0.3v0.7h-5.9v-0.7h0.5c0.8,0,1.3,0,1.3-0.9V84.8
                       L149.4,84.8z"
              />
              <path
                fill="#FFFFFF"
                d="M163.5,86.1v1.6c0.1,2.6,1.8,4.7,4.1,4.7c1.6,0,2.7-0.8,3.7-1.8l0.4,0.4c-1.3,1.7-2.9,3.1-5.1,3.1
                       c-4.4,0-5.2-4.2-5.2-6c0-5.4,3.7-6.9,5.6-6.9c2.2,0,4.6,1.4,4.7,4.3V86l-0.3,0.1L163.5,86.1L163.5,86.1z M168.3,85.2
                       c0.6,0,0.8-0.4,0.8-0.7c0-1.4-0.9-2.5-2.4-2.5c-1.7,0-2.9,1.2-3.1,3.3h4.7L168.3,85.2L168.3,85.2z"
              />
              <path
                fill="#FFFFFF"
                d="M172.1,92.9h0.8c0.8,0,1.3,0,1.3-0.9v-7.7c0-0.9-1.1-1.1-1.4-1.2v-0.4c2-0.9,3.1-1.6,3.4-1.6
                       c0.1,0,0.3,0.1,0.3,0.4v2.3l0,0c0.6-1.1,1.8-2.9,3.5-2.9c0.6,0,1.6,0.5,1.6,1.4c0,0.8-0.5,1.4-1.3,1.4c-0.9,0-0.9-0.7-1.8-0.7
                       c-0.5,0-2,0.7-2,2.3V92c0,0.9,0.5,0.9,1.3,0.9h1.6v0.7h-7.1v-0.7H172.1L172.1,92.9z"
              />
              <path
                fill="#FFFFFF"
                d="M182.7,89.9c0.4,1.8,1.4,3.4,3.5,3.4c1.6,0,2.2-1.1,2.2-2c0-3.3-6-2.2-6-6.7c0-1.6,1.2-3.5,4.3-3.5
                       c0.9,0,2.1,0.3,3.1,0.8l0.1,2.7h-0.6c-0.3-1.7-1.2-2.7-3-2.7c-1.1,0-2.1,0.7-2.1,1.8c0,3.3,6.4,2.2,6.4,6.5
                       c0,1.8-1.4,3.8-4.7,3.8c-1.1,0-2.4-0.4-3.4-0.9l-0.4-3.1L182.7,89.9L182.7,89.9z"
              />
              <path
                fill="#FFFFFF"
                d="M215.4,79.5h-0.6c-0.5-3.3-2.7-4.6-5.9-4.6s-7.7,2.1-7.7,8.8c0,5.6,3.9,9.5,8.2,9.5
                       c2.7,0,5-1.8,5.5-4.7l0.6,0.1l-0.6,4.1c-1.2,0.7-4.3,1.4-6.1,1.4c-6.4,0-10.4-4.2-10.4-10.3c0-5.6,5.1-9.7,10.4-9.7
                       c2.2,0,4.3,0.7,6.5,1.4L215.4,79.5L215.4,79.5z"
              />
              <path
                fill="#FFFFFF"
                d="M216.4,92.9h0.5c0.8,0,1.3,0,1.3-0.9V76.7c0-1.8-0.4-1.8-1.4-2.1v-0.5c1.1-0.4,2.2-0.9,2.9-1.2
                       c0.3-0.1,0.5-0.3,0.6-0.3s0.3,0.1,0.3,0.4v19.1c0,0.9,0.6,0.9,1.4,0.9h0.5v0.7h-5.9v-0.8L216.4,92.9L216.4,92.9z"
              />
              <path
                fill="#FFFFFF"
                d="M234.1,92.2c0,0.5,0.3,0.5,0.8,0.5h1.1v0.5c-1.2,0.1-3.4,0.7-3.9,0.9l-0.1-0.1v-2.2
                       c-1.7,1.3-2.9,2.3-4.8,2.3c-1.4,0-3-0.9-3-3.3v-7.1c0-0.7-0.1-1.4-1.7-1.6v-0.5c1.1,0,3.1-0.1,3.5-0.1c0.3,0,0.3,0.1,0.3,0.8
                       v7.1c0,0.8,0,3.1,2.4,3.1c0.9,0,2.2-0.7,3.3-1.7v-7.4c0-0.5-1.3-0.9-2.4-1.2v-0.5c2.5-0.1,4.1-0.4,4.3-0.4s0.3,0.1,0.3,0.5
                       v10.3L234.1,92.2L234.1,92.2z"
              />
              <path
                fill="#FFFFFF"
                d="M239.5,83.1c1.1-0.9,2.6-2,4.1-2c3.1,0,5.1,2.7,5.1,5.8c0,3.7-2.6,7.2-6.5,7.2c-2.1,0-3.1-0.7-3.8-0.9
                       l-0.8,0.7l-0.5-0.3c0.3-1.6,0.4-3.3,0.4-4.8v-12c0-1.8-0.4-1.8-1.4-2.1v-0.5c1.1-0.4,2.2-0.9,2.9-1.2c0.3-0.1,0.5-0.3,0.6-0.3
                       s0.3,0.1,0.3,0.4v10.1L239.5,83.1L239.5,83.1z M239.5,90.6c0,1.1,1.1,2.7,2.9,2.7c3,0,4.2-2.9,4.2-5.4c0-3-2.2-5.5-4.4-5.5
                       c-1.1,0-1.8,0.7-2.6,1.3L239.5,90.6L239.5,90.6z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M120.8,109.7h0.3c0.5,0,1.1-0.1,1.1-0.9v-7.8c0-0.8-0.5-0.9-1.1-0.9h-0.3v-0.4h4.6v0.4h-0.3
                       c-0.5,0-1.1,0.1-1.1,0.9v7.8c0,0.8,0.5,0.9,1.1,0.9h0.3v0.4h-4.6C120.8,110,120.8,109.6,120.8,109.7z"
              />
              <path
                fill="#FFFFFF"
                d="M125.4,110.2h-4.6v-0.4h0.3c0.5,0,1.1,0,1.1-0.8l0,0v-7.8c0-0.8-0.5-0.8-1.1-0.8h-0.3v-0.8h4.7v0.7
                       h-0.4c-0.5,0-1.1,0-1.1,0.8l0,0v7.8c0,0.8,0.5,0.8,1.1,0.8h0.3V110.2L125.4,110.2z M125.4,110.1v-0.3h-0.1
                       c-0.5,0-1.2-0.1-1.2-0.9l0,0V101c0-0.8,0.7-0.9,1.2-0.9h0.1v-0.3h-4.3v0.3h0.1c0.5,0,1.2,0.1,1.2,0.9l0,0v7.8
                       c0,0.8-0.7,0.9-1.2,0.9h-0.1v0.3C121.1,110,125.4,110,125.4,110.1z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M135.7,107.2L135.7,107.2v-5.6c0-1.2-0.9-1.4-1.3-1.4H134v-0.4h3.9v0.4h-0.1c-0.6,0-1.3,0.1-1.3,2v6.8
                       c0,0.5,0,1.1,0.1,1.6h-0.5l-7.7-8.6v6.1c0,1.3,0.3,1.7,1.4,1.7h0.1v0.4h-4.1v-0.4h0.3c1.1,0,1.3-0.7,1.3-2v-6.3
                       c0-0.8-0.7-1.3-1.3-1.3H126v-0.4h3L135.7,107.2L135.7,107.2z"
              />
              <path
                fill="#FFFFFF"
                d="M136.6,110.4H136l-7.6-8.5v6c0,1.3,0.3,1.7,1.3,1.7h0.3v0.5h-4.1v-0.5h0.3c1.1,0,1.3-0.7,1.3-1.8l0,0
                       v-6.3c0-0.8-0.7-1.3-1.3-1.3h-0.3v-0.7h3.1l6.5,7.4v-5.5c0-1.2-0.8-1.3-1.2-1.3H134v-0.7h4.2v0.5h-0.4c-0.6,0-1.3,0.1-1.3,1.8
                       l0,0v6.8C136.6,109.4,136.6,109.9,136.6,110.4L136.6,110.4z M136,110.3h0.5v-1.4l0,0v-6.8c0-1.8,0.8-2,1.4-2h0.1v-0.3h-3.9v0.3
                       h0.3c0.5,0,1.3,0.3,1.3,1.4l0,0v5.6l0,0l-6.7-7.6h-3v0.3h0.1c0.7,0,1.4,0.5,1.4,1.4l0,0v6.3c0,1.2-0.4,2-1.4,2H126v0.5h3.8
                       v-0.3h-0.1c-1.2,0-1.4-0.5-1.4-1.8l0,0v-6.3L136,110.3L136,110.3L136,110.3z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M140.3,100.5c-1.2,0-1.2,0.3-1.4,1.3h-0.4c0-0.4,0.1-0.8,0.1-1.3c0-0.4,0.1-0.8,0.1-1.3h0.4
                       c0.1,0.4,0.5,0.4,0.9,0.4h7.7c0.4,0,0.8,0,0.8-0.5h0.4c0,0.4-0.1,0.8-0.1,1.2v1.2l-0.5,0.5c0-0.5-0.1-1.4-1.1-1.4h-2.5v8
                       c0,1.2,0.5,1.3,1.3,1.3h0.3v0.4h-5v-0.4h0.3c0.8,0,1.3-0.1,1.3-1.3v-8H140.3L140.3,100.5z"
              />
              <path
                fill="#FFFFFF"
                d="M146.1,110.2h-5v-0.5h0.3c0.8,0,1.2,0,1.2-1.2l0,0v-8h-2.4h2.5v8.1c0,1.2-0.5,1.3-1.3,1.3h-0.3v0.1h2.5
                       C144.6,110,145.5,110,146.1,110.2L146.1,110.2l-0.1-0.4h-0.3c-0.8,0-1.3-0.1-1.3-1.3l0,0v-8h2.5c1.1,0,1.2,0.9,1.2,1.4l0,0
                       l0.3-0.1v-1.2l0,0c0-0.4,0.1-0.8,0.1-1.2h-0.3c-0.1,0.4-0.5,0.5-0.9,0.4h-7.8c-0.4,0-0.8,0-0.9-0.4h-0.3c0,0.4,0,0.8-0.1,1.2
                       l0,0c0,0.4-0.1,0.8-0.1,1.2h0.3c0.3-1.1,0.3-1.4,1.4-1.3l0,0c-1.1,0-1.1,0.3-1.3,1.3h-0.5v-0.1c0-0.4,0.1-0.8,0.1-1.3l0,0
                       c0-0.4,0.1-0.8,0.1-1.3l0,0v-0.1h0.9c0.1,0.4,0.4,0.4,0.8,0.4h7.8c0.4,0,0.8,0,0.8-0.4l0,0v-0.1h0.1l0.4,0.1l0,0
                       c0,0.4-0.1,0.8-0.1,1.2l0,0v1.2l0,0l-0.6,0.7v-0.1c0-0.5-0.1-1.4-1.1-1.4h-2.4v7.8c0,1.2,0.5,1.2,1.2,1.2h0.4L146.1,110.2
                       L146.1,110.2L146.1,110.2z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M149.4,109.7h0.1c0.5,0,1.1-0.1,1.1-0.9v-7.8c0-0.8-0.5-0.9-1.1-0.9h-0.3v-0.4h7.6v2.3l-0.4,0.1
                       c-0.1-0.9-0.3-1.7-1.8-1.7h-2.1v3.9h1.8c0.9,0,1.1-0.5,1.2-1.3h0.4v3.4l-0.4,0.1c-0.1-0.9-0.1-1.4-1.2-1.4h-1.8v3.5
                       c0,0.9,0.9,0.9,1.8,0.9c1.8,0,2.6-0.1,3-1.8l0.4,0.1c-0.1,0.8-0.4,1.6-0.5,2.3h-8v-0.5L149.4,109.7L149.4,109.7z"
              />
              <path
                fill="#FFFFFF"
                d="M157.4,110.2h-8.1v-0.4h0.3c0.5,0,1.1,0,1.1-0.8l0,0v-7.8c0-0.8-0.5-0.8-1.1-0.8h-0.3v-0.8h7.8v0.9l0,0
                       v1.6l0,0l-0.6,0.1v-0.1c-0.1-0.9-0.3-1.7-1.7-1.7h-2v3.8h1.7c0.9,0,1-0.4,1.2-1.3h0.4v1.8l0,0v1.7l0,0l-0.5,0.1v-0.1
                       c-0.1-0.9-0.1-1.4-1.1-1.4h-1.7v3.4c0,0.9,0.8,0.9,1.7,0.9l0,0c1.8,0,2.5-0.1,3-1.8l0,0l0.5,0.1l0,0
                       C157.8,108.6,157.5,109.4,157.4,110.2L157.4,110.2L157.4,110.2z M157.2,110.1c0.1-0.8,0.3-1.6,0.5-2.2l0,0l-0.3-0.1
                       c-0.4,1.7-1.3,1.8-3.1,1.8l0,0c-0.9,0-1.8,0-1.8-1.1l0,0V105h1.8c1.1,0,1.2,0.7,1.2,1.4l0,0l0.3-0.1v-1.7l0,0v-1.7h-0.3
                       c-0.1,0.8-0.3,1.3-1.2,1.3h-1.8v-4.1h2.1c1.6,0,1.8,0.8,1.8,1.7l0,0l0.3-0.1v-1.4l0,0v-0.7h-7.4v0.3h0.3c0.5,0,1.2,0.1,1.2,0.9
                       l0,0v7.8c0,0.8-0.6,0.9-1.2,0.9h-0.1v0.5L157.2,110.1L157.2,110.1z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M159.9,101.3c0-1-0.6-1.2-1.1-1.2h-0.3v-0.4h4.7c1.8,0,3.5,0.5,3.5,2.6c0,1.3-0.9,2.2-2.1,2.6l2.6,3.8
                       c0.4,0.7,0.6,0.8,1.4,0.9v0.4h-2.9c-1.2-1.6-2.1-3.1-3.1-4.8h-1.1v3.3c0,1.2,0.5,1.2,1.2,1.2h0.3v0.4h-4.8v-0.4h0.3
                       c0.5,0,1.1-0.3,1.1-0.8v-7.6H159.9L159.9,101.3z M161.8,104.8h0.8c1.4,0,2.4-0.5,2.4-2.3c0-1.3-0.9-2.2-2.2-2.2h-0.9V104.8z"
              />
              <path
                fill="#FFFFFF"
                d="M168.7,110.2h-2.9c-1.2-1.6-2.1-3.1-3.1-4.8h-0.9v3.1c0,1.2,0.5,1.2,1.2,1.2h0.4v0.5h-5v-0.5h0.3
                       c0.5,0,1.1-0.3,1.1-0.8l0,0v-7.6c0-1-0.5-1-1.1-1h-0.4v-0.7h4.7c1.8,0,3.7,0.5,3.7,2.7l0,0c0,1.3-0.9,2.2-2.1,2.6l0,0l2.5,3.8
                       c0.4,0.7,0.6,0.8,1.4,0.9l0,0L168.7,110.2L168.7,110.2L168.7,110.2z M161.8,105.2h1.1c0.9,1.7,2,3.4,3,4.8h2.9v-0.4
                       c-0.6-0.1-1.1-0.3-1.4-0.9l0,0l-2.6-3.9h0.1c1.2-0.5,2.1-1.3,2.1-2.6l0,0c0-2.1-1.6-2.6-3.5-2.6h-4.6v0.3h0.3
                       c0.5,0,1.2,0.1,1.2,1.2l0,0v7.6c0,0.7-0.5,0.9-1.2,0.9h-0.4v0.5h4.6v-0.3H163c-0.6,0-1.3-0.1-1.3-1.3l0,0L161.8,105.2
                       L161.8,105.2L161.8,105.2z M161.8,104.8L161.8,104.8v-4.6h0.9c1.3,0,2.2,0.9,2.2,2.2l0,0c0,1.8-0.9,2.3-2.4,2.3H161.8z
                        M162.5,104.7c1.4,0,2.2-0.5,2.2-2.2l0,0c0-1.3-0.8-2.1-2.1-2.1h-0.8v4.3H162.5z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M178.9,107.2L178.9,107.2v-5.6c0-1.2-0.9-1.4-1.3-1.4h-0.4v-0.4h3.9v0.4h-0.3c-0.6,0-1.3,0.1-1.3,2v6.8
                       c0,0.5,0,1.1,0.1,1.6h-0.5l-7.7-8.6v6.1c0,1.3,0.3,1.7,1.4,1.7h0.3v0.4h-4.1v-0.4h0.3c1.1,0,1.3-0.7,1.3-2v-6.3
                       c0-0.8-0.6-1.3-1.3-1.3h-0.1v-0.4h3L178.9,107.2L178.9,107.2z"
              />
              <path
                fill="#FFFFFF"
                d="M179.8,110.4h-0.6l-7.6-8.5v6c0,1.3,0.3,1.7,1.3,1.7h0.3v0.5h-4.1v-0.5h0.4c1.1,0,1.3-0.7,1.3-1.8l0,0
                       v-6.3c0-0.8-0.6-1.3-1.3-1.3h-0.4v-0.7h3.3l6.5,7.4v-5.5c0-1.2-0.8-1.3-1.2-1.3h-0.4v-0.7h3.9v0.7H181c-0.6,0-1.3,0.1-1.3,1.8
                       l0,0v6.8C179.8,109.4,179.8,109.9,179.8,110.4L179.8,110.4L179.8,110.4z M179.2,110.3h0.5c0-0.4-0.1-0.9-0.1-1.4l0,0v-6.8
                       c0-1.8,0.8-2,1.4-2h0.1v-0.3h-3.9v0.3h0.3c0.5,0,1.3,0.3,1.3,1.4l0,0v5.6l0,0l-6.7-7.6h-3v0.3h0.1c0.6,0,1.4,0.5,1.4,1.4l0,0
                       v6.3c0,1.2-0.4,2-1.4,2h-0.1v0.5h3.8v-0.3h-0.1c-1.2,0-1.4-0.5-1.4-1.8l0,0v-6.3L179.2,110.3L179.2,110.3L179.2,110.3z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M183.6,108c-0.1,0.5-0.4,0.9-0.4,1.2c0,0.4,0.6,0.5,1.2,0.5h0.1v0.4h-3.4v-0.4h0.1
                       c0.5,0,1.1-0.4,1.3-0.9l2.4-6.9c0.1-0.5,0.5-1.3,0.5-1.8c0.5-0.1,1.1-0.5,1.3-0.7h0.3c0,0.1,0.1,0.3,0.1,0.4l2.7,7.8
                       c0.1,0.5,0.4,1,0.5,1.6c0.1,0.4,0.5,0.7,1.1,0.7h0.1v0.4h-4.3v-0.4h0.1c0.4,0,0.9,0,0.9-0.4c0-0.3-0.1-0.7-0.3-1l-0.5-1.7h-3.4
                       L183.6,108L183.6,108z M185.9,101.4L185.9,101.4l-1.4,4.2h2.7L185.9,101.4L185.9,101.4z"
              />
              <path
                fill="#FFFFFF"
                d="M191.6,110.2h-4.4v-0.7h0.3c0.4,0,0.9-0.1,0.9-0.4l0,0c0-0.1-0.1-0.5-0.3-1.1l0,0l-0.5-1.7h-3.3
                       l-0.5,1.6c-0.1,0.5-0.4,0.9-0.4,1.2l0,0c0,0.4,0.5,0.4,1.1,0.4h0.3v0.7H181v-0.7h0.1c0.5,0,1.1-0.3,1.2-0.9l0,0l2.4-6.9
                       c0.3-0.5,0.5-1.3,0.6-2l0,0c0.5-0.1,1.1-0.5,1.3-0.7h0.1c0,0,0.1,0,0.1,0.1l0,0c0,0.1,0.1,0.3,0.1,0.4l0,0l2.7,7.8
                       c0.1,0.5,0.4,1,0.5,1.6l0,0c0.1,0.4,0.4,0.5,0.9,0.5h0.1v0.1L191.6,110.2L191.6,110.2L191.6,110.2z M187.4,110.1h4.2v-0.3l0,0
                       c-0.5,0-0.9-0.3-1.1-0.7l0,0c-0.1-0.4-0.4-1.1-0.5-1.6l0,0l-2.7-7.8c0-0.1-0.1-0.3-0.1-0.4H187c-0.3,0.1-0.9,0.5-1.3,0.7l0,0
                       c-0.1,0.5-0.4,1.3-0.5,1.8l0,0l-2.5,6.9c-0.3,0.7-0.8,1-1.3,1l0,0v0.3h3.3v-0.3h-0.1c-0.5,0-1.2,0-1.2-0.7l0,0
                       c0-0.3,0.1-0.7,0.4-1.2h0.1h-0.1l0.5-1.6h3.5l0.6,1.8c0.1,0.5,0.3,0.9,0.3,1l0,0c0,0.5-0.6,0.5-1.1,0.5h-0.3L187.4,110.1
                       L187.4,110.1L187.4,110.1z M184.4,105.7l1.4-4.3l0,0l1.4,4.3H184.4L184.4,105.7z M184.5,105.6h2.6l-1.3-4.1L184.5,105.6
                       L184.5,105.6L184.5,105.6z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M192.4,100.5c-1.2,0-1.2,0.3-1.4,1.3h-0.4c0-0.4,0.1-0.8,0.1-1.3c0-0.4,0.1-0.8,0.1-1.3h0.4
                       c0.1,0.4,0.5,0.4,0.9,0.4h7.7c0.4,0,0.8,0,0.8-0.5h0.4c0,0.4-0.1,0.8-0.1,1.2v1.2l-0.4,0.1c0-0.5-0.1-1.4-1.1-1.4h-2.5v8
                       c0,1.2,0.5,1.3,1.3,1.3h0.3v0.4h-5v-0.4h0.3c0.8,0,1.3-0.1,1.3-1.3v-8h-2.7L192.4,100.5L192.4,100.5z"
              />
              <path
                fill="#FFFFFF"
                d="M198.3,110.2h-5v-0.5h0.3c0.8,0,1.2,0,1.2-1.2l0,0v-8h-2.4h2.5v8.1c0,1.2-0.5,1.3-1.3,1.3h-0.3v0.1h2.5
                       C196.6,110,197.6,110,198.3,110.2L198.3,110.2l0-0.4H198c-0.8,0-1.3-0.1-1.3-1.3l0,0v-8h2.5c1.1,0,1.2,0.9,1.2,1.4l0,0l0.3-0.1
                       v-1.2l0,0c0-0.4,0.1-0.8,0.1-1.2h-0.3c-0.1,0.4-0.5,0.5-0.9,0.4h-7.8c-0.4,0-0.8,0-0.9-0.4h-0.3c0,0.4,0,0.8-0.1,1.2l0,0
                       c0,0.4-0.1,0.8-0.1,1.2h0.3c0.3-1.1,0.3-1.4,1.4-1.3l0,0c-1.2,0-1.1,0.3-1.3,1.3h-0.5v-0.1c0-0.4,0.1-0.8,0.1-1.3l0,0
                       c0-0.4,0.1-0.8,0.1-1.3l0,0v-0.1h0.5c0.1,0.4,0.4,0.4,0.8,0.4h7.8c0.4,0,0.6,0,0.8-0.4l0,0v-0.1h0.1l0.4,0.1l0,0
                       c0,0.4-0.1,0.8-0.1,1.2l0,0v1.2l0,0l-0.5,0.1v-0.1c0-0.5-0.1-1.4-1.1-1.4h-2.4v7.8c0,1.2,0.5,1.2,1.2,1.2h0.4L198.3,110.2
                       L198.3,110.2L198.3,110.2z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M201.4,109.7h0.3c0.5,0,1.1-0.1,1.1-0.9v-7.8c0-0.8-0.5-0.9-1.1-0.9h-0.3v-0.4h4.6v0.4h-0.3
                       c-0.5,0-1.2,0.1-1.2,0.9v7.8c0,0.8,0.5,0.9,1.2,0.9h0.3v0.4h-4.6C201.4,110,201.4,109.6,201.4,109.7z"
              />
              <path
                fill="#FFFFFF"
                d="M206,110.2h-4.6v-0.4h0.3c0.5,0,1.1,0,1.1-0.8l0,0v-7.8c0-0.8-0.5-0.8-1.1-0.8h-0.3v-0.8h4.6v0.5h-0.3
                       c-0.5,0-1.1,0-1.1,0.8l0,0v7.8c0,0.8,0.5,0.8,1.1,0.8h0.3V110.2L206,110.2z M206,110.1v-0.3h-0.1c-0.5,0-1.2-0.1-1.2-0.9l0,0
                       V101c0-0.8,0.6-0.9,1.2-0.9h0.1v-0.3h-4.3v0.3h0.1c0.5,0,1.2,0.1,1.2,0.9l0,0v7.8c0,0.8-0.6,0.9-1.2,0.9h-0.1v0.3
                       C201.7,110,206,110,206,110.1z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M212,99.5c3.1,0,5.8,2,5.8,5.2s-2.5,5.8-5.6,5.8s-5.6-2.1-5.6-5.4S208.8,99.5,212,99.5L212,99.5z
                        M212.2,109.7c2.9,0,3.4-2.6,3.4-4.7c0-2.2-1.2-4.8-3.7-4.8c-2.6,0-3.4,2.3-3.4,4.3C208.6,107.2,209.7,109.7,212.2,109.7
                       L212.2,109.7z"
              />
              <path
                fill="#FFFFFF"
                d="M206.4,104.9c0-3.1,2.5-5.6,5.6-5.6l0,0v0.3C209,99.6,206.5,101.9,206.4,104.9L206.4,104.9
                       c0.1,3.1,2.5,5.4,5.6,5.4l0,0c3.1,0,5.6-2.2,5.6-5.6l0,0c0-3.1-2.5-5.1-5.6-5.1l0,0v-0.3c3.3,0,5.8,2,5.8,5.2l0,0
                       c0,3.5-2.5,5.8-5.8,5.8l0,0C208.8,110.4,206.4,108.2,206.4,104.9L206.4,104.9L206.4,104.9z M208.4,104.4c0-2,0.8-4.4,3.5-4.4
                       l0,0c2.5,0,3.8,2.7,3.8,5l0,0c0,2.2-0.5,4.8-3.5,4.8l0,0c2.9,0,3.4-2.5,3.4-4.7l0,0c0-2.2-1.2-4.8-3.7-4.8l0,0
                       c-2.6,0-3.3,2.2-3.4,4.3l0,0c0,2.6,1.2,5.2,3.5,5.2l0,0C209.7,109.8,208.4,107.2,208.4,104.4L208.4,104.4L208.4,104.4z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M227.8,107.2L227.8,107.2v-5.6c0-1.2-0.9-1.4-1.3-1.4h-0.3v-0.4h3.9v0.4h-0.3c-0.6,0-1.3,0.1-1.3,2v6.8
                       c0,0.5,0,1.1,0.1,1.6h-0.5l-7.7-8.6v6.1c0,1.3,0.3,1.7,1.4,1.7h0.3v0.4h-4.1v-0.4h0.3c1.1,0,1.3-0.7,1.3-2v-6.3
                       c0-0.8-0.6-1.3-1.3-1.3h-0.1v-0.4h3L227.8,107.2L227.8,107.2z"
              />
              <path
                fill="#FFFFFF"
                d="M228.7,110.4H228l-7.6-8.5v6c0,1.3,0.3,1.7,1.3,1.7h0.3v0.7h-4.2v-0.5h0.3c1.1,0,1.3-0.7,1.3-1.8l0,0
                       v-6.3c0-0.8-0.6-1.3-1.3-1.3h-0.3v-0.8h3.1l6.5,7.4v-5.5c0-1.2-0.8-1.3-1.2-1.3H226v-0.7h4.1v0.5h-0.3c-0.6,0-1.3,0.1-1.3,1.8
                       l0,0v6.8C228.7,109.4,228.7,109.9,228.7,110.4L228.7,110.4L228.7,110.4z M228.2,110.3h0.5c-0.1-0.4-0.1-0.9-0.1-1.4l0,0v-6.8
                       c0-1.8,0.8-2,1.4-2h0.1v-0.3h-3.9v0.3h0.3c0.5,0,1.3,0.3,1.3,1.4l0,0v5.6l0,0l-6.7-7.6h-3v0.3h0.1c0.6,0,1.4,0.5,1.4,1.4l0,0
                       v6.3c0,1.2-0.4,2-1.4,2h-0.1v0.5h3.8v-0.3h-0.1c-1.2,0-1.4-0.5-1.4-1.8l0,0v-6.3L228.2,110.3L228.2,110.3L228.2,110.3z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M232.6,108c-0.1,0.5-0.3,0.9-0.3,1.2c0,0.4,0.6,0.5,1.2,0.5h0.1v0.4h-3.4v-0.4h0.1
                       c0.5,0,1.1-0.4,1.3-0.9l2.4-6.9c0.1-0.5,0.5-1.3,0.5-1.8c0.5-0.1,1.1-0.5,1.3-0.7h0.3c0,0.1,0.1,0.3,0.1,0.4l2.7,7.8
                       c0.1,0.5,0.4,1,0.5,1.6c0.1,0.4,0.5,0.7,1.1,0.7h0.1v0.4h-4.3v-0.4h0.1c0.4,0,0.9,0,0.9-0.4c0-0.3-0.1-0.7-0.3-1l-0.5-1.7h-3.4
                       L232.6,108L232.6,108z M234.7,101.4L234.7,101.4l-1.4,4.2h2.7L234.7,101.4L234.7,101.4z"
              />
              <path
                fill="#FFFFFF"
                d="M240.6,110.2h-4.4v-0.7h0.1c0.4,0,0.9-0.1,0.9-0.4l0,0c0-0.1-0.1-0.5-0.3-1.1l0,0l-0.5-1.7h-3.3
                       l-0.5,1.6l0,0c-0.1,0.5-0.4,0.9-0.3,1.2l0,0c0,0.4,0.5,0.5,1.1,0.5h0.3v0.5h-3.5v-0.7h0.1c0.5,0,1.1-0.3,1.3-0.9l0,0l2.4-6.9
                       c0.1-0.5,0.5-1.3,0.6-2l0,0c0.5-0.1,1.1-0.5,1.3-0.7h0.1c0,0,0.1,0,0.1,0.1l0,0c0,0.1,0.1,0.3,0.1,0.4l0,0l2.7,7.8
                       c0.1,0.5,0.4,1,0.5,1.6l0,0c0.1,0.4,0.4,0.5,0.9,0.5h0.1L240.6,110.2L240.6,110.2L240.6,110.2z M236.3,110.1h4.2v-0.3l0,0
                       c-0.5,0-0.9-0.3-1.1-0.7l0,0c-0.3-0.4-0.4-1.1-0.5-1.6l0,0l-2.7-7.8c0-0.1-0.1-0.3-0.1-0.4h-0.1c-0.3,0.1-0.9,0.5-1.3,0.7l0,0
                       c-0.1,0.5-0.4,1.3-0.5,1.8l0,0l-2.4,6.9c-0.3,0.7-0.8,1-1.3,1l0,0v0.3h3.3v-0.3h-0.1c-0.5,0-1.2,0-1.2-0.7l0,0
                       c0-0.3,0.1-0.7,0.4-1.2l0,0l0.5-1.6h3.5l0.6,1.8c0.1,0.5,0.3,0.9,0.3,1l0,0c0,0.5-0.6,0.5-1.1,0.5h-0.1v0.3L236.3,110.1
                       L236.3,110.1z M233.3,105.7l1.4-4.3l0,0l1.4,4.3H233.3L233.3,105.7z M233.4,105.6h2.6l-1.3-4.1L233.4,105.6L233.4,105.6
                       L233.4,105.6z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M244.4,108.7c0,0.7,0.4,0.8,0.9,0.8h2c0.6-0.1,1.2-0.4,1.4-0.8c0.3-0.4,0.4-0.8,0.5-1.1h0.4
                       c-0.1,0.9-0.4,1.7-0.5,2.5h-7.8v-0.4h0.3c0.5,0,1.2-0.1,1.2-1V101c0-0.8-0.5-0.9-1.2-0.9h-0.3v-0.4h4.6v0.4h-0.4
                       c-0.5,0-1.1,0-1.1,0.8L244.4,108.7L244.4,108.7z"
              />
              <path
                fill="#FFFFFF"
                d="M248.9,110.2h-7.8v-0.5h0.3c0.5,0,1.1,0,1.1-0.9l0,0V101c0-0.8-0.5-0.8-1.1-0.8H241v-0.7h4.6v0.5h-0.4
                       c-0.5,0-0.9,0-0.9,0.8l0,0v7.8c0,0.5,0.4,0.7,0.8,0.8h2c0.6-0.1,1.2-0.4,1.4-0.8l0,0c0.3-0.3,0.4-0.8,0.4-1.1h0.5v0.1
                       C249.5,108.5,249.2,109.3,248.9,110.2L248.9,110.2L248.9,110.2z M248.9,110.1c0.1-0.8,0.4-1.6,0.5-2.3h-0.3
                       c-0.1,0.4-0.3,0.8-0.5,1.1l0,0c-0.3,0.4-0.8,0.8-1.4,0.8h-2c-0.5,0-0.9-0.3-0.9-0.9l0,0v-7.7c0-0.8,0.5-0.9,1.1-0.9h0.3v-0.3
                       h-4.6v0.3h0.1c0.5,0,1.2,0.1,1.2,0.9l0,0v7.8c0,0.9-0.6,1-1.2,1h-0.1v0.3H248.9L248.9,110.1z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M249.3,99.3c0.9,0,1.6,0.7,1.6,1.6s-0.6,1.6-1.6,1.6s-1.6-0.7-1.6-1.6S248.4,99.3,249.3,99.3z
                    M249.3,102.1c0.6,0,1.3-0.7,1.3-1.3s-0.5-1.3-1.3-1.3c-0.6,0-1.3,0.7-1.3,1.3C248,101.5,248.7,102.1,249.3,102.1z M248.6,101.7
                   L248.6,101.7c0.1-0.1,0.3-0.1,0.3-0.3v-1.2c0-0.1,0-0.3-0.3-0.3v-0.1h0.8c0.3,0,0.5,0.1,0.5,0.4s-0.1,0.4-0.4,0.5l0.3,0.4
                   c0.1,0.1,0.3,0.3,0.4,0.4h-0.3c-0.1,0-0.3-0.3-0.5-0.8h-0.1v0.5c0,0.1,0.1,0.1,0.3,0.1v0.1h-0.9L248.6,101.7L248.6,101.7z
                    M249.1,100.9h0.1c0.3,0,0.3-0.1,0.3-0.4s-0.1-0.4-0.3-0.4h-0.1V100.9z"
            />
          </g>
          <path
            fill="#FFFFFF"
            d="M63,130.9c-21,0-38.3-17.1-38.3-37.7c0-22.6,17.3-38.3,38.3-38.3h9.9c20.7,0,39.6,15.7,39.6,38.3
               c0,20.7-19,37.7-39.6,37.7C72.8,130.9,62.9,130.9,63,130.9z M63,58.1c-19.1,0-34.7,15.6-34.7,34.7s15.5,34.7,34.7,34.7
               s34.7-15.6,34.7-34.7S82.1,58.1,63,58.1z M55,113.3v-41c-8.1,3-14,11-14,20.5S46.8,110.2,55,113.3z M84.9,92.8
               c0-9.5-5.9-17.4-14.2-20.5v41.2C79.1,110.2,84.9,102.1,84.9,92.8z"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CreditDinersIcon
