import MuiPopper from '@mui/material/Popper'
import { styled } from '@mui/material'

import { Styles } from '../../../types'

export const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme, arrow = true }: any) => ({
  zIndex: 1,
  '& > div': {
    position: 'relative',
  },

  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: arrow ? 2 : 0,
      [theme.breakpoints.only('mobile')]: {
        marginTop: 0,
      },
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-2.5em',
      width: '5em',
      height: '2em',
      [theme.breakpoints.only('mobile')]: {
        marginTop: '-1em',
      },
      [theme.breakpoints.only('tablet')]: {
        marginTop: '-2em',
      },
      '&::before': {
        [theme.breakpoints.only('mobile')]: {
          borderWidth: '0 2em 2em 2em',
        },
        [theme.breakpoints.only('tablet')]: {
          borderWidth: '0 2em 2em 2em',
        },
        borderWidth: '0 3em 3em 3em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}))

export const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
})

export const styles: Styles = {
  stack: {
    display: 'inline-flex',
  },
  iconButton: (theme) => ({
    borderRadius: '50%',
    color: theme.palette.common.white,
  }),
  badge: () => ({
    '& .MuiBadge-badge': {
      marginRight: '0.125rem',
      top: '0.813rem',
    },
  }),
  paper: (theme) => ({
    marginTop: '0.75rem',
    position: 'relative',
    right: '1.875rem',
    [theme.breakpoints.only('tablet')]: {
      right: '0.5rem',
    },
    [theme.breakpoints.only('mobile')]: {
      margin: '0rem',
      right: '0.0625rem',
    },
  }),
  backdrop: (theme) => ({
    [theme.breakpoints.only('mobile')]: {
      display: 'none',
    },
  }),
  paperContent: (theme) => ({
    '& > div': {
      fontFamily: 'Prelo-Book, sans-serif',
    },
    padding: '1rem',
    textAlign: 'left',
    [theme.breakpoints.only('mobile')]: {
      height: 'calc(100vh - 5.25rem)',
    },
  }),
  innerContent: (theme) => ({
    maxHeight: 'calc(100vh - 10.5rem)',
    maxWidth: 500,
    overflowY: 'auto',
    [theme.breakpoints.only('mobile')]: {
      maxHeight: 'calc(100vh - 9.375rem)',
    },
  }),
  paperContentNoNotifications: (theme) => ({
    '& > div': {
      fontFamily: 'Prelo-Book, sans-serif',
    },
    padding: '1rem',
    textAlign: 'left',
    [theme.breakpoints.only('mobile')]: {
      width: '100vw',
    },
  }),
  iconContainer: {
    top: '0.3125rem',
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  liContainer: {
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '0.5rem',
    marginTop: '0.5rem',
    a: {
      flex: 'auto',
    },
  },
  dot: {
    position: 'relative',
    top: '0.75rem',
    borderRadius: '50%',
    maxWidth: '0.6875rem',
    padding: '0.25rem',
    maxHeight: '0.5rem',
    background: '#515356',
  },
  textContent: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineHeight: 1.1,
    fontFamily: 'Prelo-Book, sans-serif',
    cursor: 'pointer',
  },
  loadingContainer: (theme) => ({
    minWidth: '31.25rem',
    height: '5rem',
    [theme.breakpoints.only('mobile')]: {
      minWidth: '100%',
    },
  }),
  noNotificationContainer: (theme) => ({
    minWidth: '31.25rem',
    height: '5rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('mobile')]: {
      minWidth: '100%',
    },
  }),
  timeAgo: {
    fontStyle: 'italic',
    fontWeight: 400,
    fontFamily: 'Prelo-Book',
    lineHeight: 2,
    fontSize: 12,
  },
}
