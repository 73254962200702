import React, { ChangeEvent, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Styles } from '../../../../types'
import {
  ImplementAutoPayLimitType,
  setImplementAutoPayLimit,
} from '../../../../store/invoices/invoiceSelectedInvoicesSlice'

const styles: Styles = {
  AutoPayRadioRow: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
  },
  rowAreaFixed: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
  },
  radioLabel: (theme) => ({
    marginRight: '3.5rem',
    [theme.breakpoints.only('mobile')]: {
      marginRight: '1rem',
    },
  }),
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
  autopayDescription: (theme) => ({
    width: 'max-content',
    [theme.breakpoints.only('mobile')]: {
      width: 'fit-content',
    },
  }),
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const AutoPayLimit = () => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [autopayLimitSelection, setAutopayLimitSelection] =
    useState<ImplementAutoPayLimitType>('No')

  return (
    <FormControl component="fieldset" sx={styles.fieldContainer}>
      <Box component="legend">
        <StyledLabel>{t('Invoicing.AutoPayPage.AutoPayLimit')}</StyledLabel>
      </Box>
      <Box
        id={
          autopayLimitSelection === 'No'
            ? 'autopayLimitAmountNoDescription'
            : 'autopayLimitAmountYesDescription'
        }
        aria-live="polite"
        aria-atomic="true"
        sx={styles.autopayDescription}>
        <Box component="i">
          {autopayLimitSelection === 'No'
            ? t('Invoicing.AutoPayPage.AutoPayLimitAmountNoDescription')
            : t('Invoicing.AutoPayPage.AutoPayLimitAmountYesDescription')}
        </Box>
      </Box>
      <Controller
        name="autoPayLimitToggle"
        control={control}
        defaultValue={t('Invoicing.AutoPayPage.AutoPayLimitNo')}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            id="autoPayLimitToggle"
            value={value}
            defaultValue={t('Invoicing.AutoPayPage.AutoPayLimitNo')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value)
              setAutopayLimitSelection(
                e.target.value as ImplementAutoPayLimitType,
              )
              dispatch(
                setImplementAutoPayLimit(
                  e.target.value as ImplementAutoPayLimitType,
                ),
              )
            }}>
            <Box sx={styles.AutoPayRadioRow}>
              <Box key="AutoPayLimitYes" sx={styles.rowAreaFixed}>
                {' '}
                <FormControlLabel
                  value={t('Invoicing.AutoPayPage.AutoPayLimitYes')}
                  sx={styles.radioLabel}
                  label={t('Invoicing.AutoPayPage.AutoPayLimitYes')}
                  control={
                    <Radio
                      key={t('Invoicing.AutoPayPage.AutoPayLimitYes')}
                      value={t('Invoicing.AutoPayPage.AutoPayLimitYes')}
                      data-testid="Radio_btn_AutoPayLimitYes"
                      inputProps={{
                        'aria-describedby': 'autopayLimitAmountYesDescription',
                      }}
                    />
                  }
                />
              </Box>
              <Box key="AutoPayLimitNo" sx={styles.rowAreaFixed}>
                <FormControlLabel
                  value={t('Invoicing.AutoPayPage.AutoPayLimitNo')}
                  sx={styles.radioLabel}
                  label={t('Invoicing.AutoPayPage.AutoPayLimitNo')}
                  control={
                    <Radio
                      key={t('Invoicing.AutoPayPage.AutoPayLimitNo')}
                      value={t('Invoicing.AutoPayPage.AutoPayLimitNo')}
                      data-testid="Radio_btn_AutoPayLimitNo"
                      inputProps={{
                        'aria-describedby': 'autopayLimitAmountNoDescription',
                      }}
                    />
                  }
                />
              </Box>
            </Box>
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default AutoPayLimit
