import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

interface OwnProps {
  children: React.ReactNode
}

const Refocus = ({ children }: OwnProps) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const location = useLocation()

  useEffect(() => {
    nodeRef.current?.focus()
  }, [location])

  return (
    <div ref={nodeRef} tabIndex={-1}>
      {children}
    </div>
  )
}

export default Refocus
