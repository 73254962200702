/* eslint-disable react/no-unstable-nested-components */
import { Box, Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EngagementsTable from './EngagementsTable'
import { downloadFromQueue } from '../state/actions'
import { EngagementsContext } from '../state/context'
import SearchLayout from '../../../components/layouts/SearchLayout'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import SearchBox from '../../../rsmCoreComponents/components/Search/SearchBox'
import RsmTable from '../../../rsmCoreComponents/components/table/RSMTable'
import { Styles } from '../../../types'

const styles: Styles = {
  searchContainer: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      width: '20rem',
    },
  }),
}

const EngagementsSearchTable = () => {
  const { t } = useTranslation()
  const { isDesktop } = useDeviceType()
  const { dispatch } = useContext(EngagementsContext)

  const [filter, setFilter] = useState<string>('')

  return (
    <SearchLayout
      title={t('EngagementListHeader')}
      searchSlot={
        <Box sx={styles.searchContainer} data-testid="searchwrapper">
          <SearchBox
            setFilter={setFilter}
            placeholderText={t('SearchByKeyword')}
            testId={'Txt_Engagement_Search' ?? ''}
          />
        </Box>
      }
      searchAuxSlot={
        isDesktop && (
          <Button
            size="small"
            aria-label={t('DownloadAll')}
            variant="outlined"
            onClick={() => dispatch(downloadFromQueue('Engagements.csv'))}>
            {t('DownloadAll')}
          </Button>
        )
      }>
      <RsmTable>
        <EngagementsTable filter={filter} />
      </RsmTable>
    </SearchLayout>
  )
}

export default EngagementsSearchTable
