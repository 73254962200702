/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Collapse,
  Typography,
} from '@mui/material'

import { styled } from '@mui/material/styles'
import {
  ArrowUpIcon,
  ArrowDownIcon,
  CheckboxIcon,
  CheckboxOutlinedIcon,
} from '../icons'

import './FiltersGroup.css'
import StyledButton from '../../../components/DocumentRequests/components/StyledButton/StyledButton'

const FilterList = styled(List)({
  border: 'none',
})

const FilterItem = styled(ListItem)(() => ({
  display: 'flex',
  alignItems: 'middle',
  padding: '0 0',
}))

const FilterTitle = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1.125rem',
  fontFamily: 'Prelo-Bold, sans-serif',
}))

const ClearFilters = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.125rem',
  fontFamily: 'Prelo-Medium, sans-serif',
  textDecoration: 'underline',
}))

export interface FilterGroupExpandedStateDictionary {
  [key: string]: boolean
}

type FilterGroupProps<T> = {
  name: string
  filterProperty: keyof T
  filterTitle: string
  filterIndex: number
  filterOptions: Array<T[keyof T]>
  selectedValues: Map<keyof T, Set<T[keyof T]>> | undefined
  setSelectedValues: (
    payload: Map<keyof T, Set<T[keyof T]>> | undefined,
  ) => void
  formatFilterDisplayFunction?: (valueString: string) => string
  filterClearTranslationKey: string
  visibleItemsCount: number
  showMoreItems: boolean
  handleFilterGroupExpanded: (group: string, state: boolean) => void
}

const FilterGroup = <T,>({
  name,
  filterTitle,
  filterIndex,
  filterOptions,
  selectedValues,
  filterProperty,
  setSelectedValues,
  formatFilterDisplayFunction = (valueString: string) => valueString,
  filterClearTranslationKey,
  visibleItemsCount,
  showMoreItems,
  handleFilterGroupExpanded,
}: FilterGroupProps<T>) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(true)

  const handleSelectedChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: T[keyof T],
  ) => {
    const yourInsights: keyof T = 'yourinsights' as keyof T
    const tempSelected = new Map(selectedValues)

    // if filters are changed and yourinsights === my interests then set to all insights
    if (
      tempSelected.get(yourInsights)?.values().next().value === 'My Interests'
    ) {
      const value = new Set(selectedValues?.get(yourInsights))
      value.add('All Insights' as unknown as T[keyof T])
      value.delete('My Interests' as unknown as T[keyof T])
      tempSelected.set(yourInsights, value)
    }

    if (e.target.checked) {
      const value = new Set(selectedValues?.get(filterProperty))
      value.add(option)

      tempSelected.set(filterProperty, value)
    } else {
      const value = new Set(selectedValues?.get(filterProperty))
      if (value.has(option)) {
        value.delete(option)
        tempSelected.set(filterProperty, value)

        if (value.size === 0) {
          tempSelected.delete(filterProperty)
        }
      }
    }
    setSelectedValues(tempSelected)
  }

  const handleClearFilter = () => {
    const tempSelected = new Map(selectedValues)
    tempSelected.delete(filterProperty)
    setSelectedValues(tempSelected)
  }

  const shouldCollapseItems = filterOptions.length > visibleItemsCount
  const allwaysVisibleItems = shouldCollapseItems
    ? filterOptions.slice(0, visibleItemsCount)
    : filterOptions
  const moreItems = shouldCollapseItems
    ? filterOptions.slice(visibleItemsCount, filterOptions.length)
    : []

  // Replace spaces with dashes.
  const sanitizeWhitespace = (title: string) => title.replace(/\s+/g, '-')

  // Check if filter option is checked
  const isFilterChecked = (option: T[keyof T]) =>
    new Set(selectedValues?.get(filterProperty)).has(option)

  return (
    <div className="filtersGroup">
      <div className="filtersHeader">
        <FilterTitle
          id={`clear_${sanitizeWhitespace(filterTitle)}`}
          data-testid={`Lbl_Filter_Title_${filterIndex}`}
          onClick={() => setExpanded(!expanded)}
          disableRipple
          aria-expanded={expanded}>
          {filterTitle}
          {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </FilterTitle>
        <ClearFilters
          onClick={handleClearFilter}
          disableRipple
          aria-describedby={`clear_${sanitizeWhitespace(filterTitle)}`}
          data-testid={`Btn_Filter_Clear_${filterIndex}`}>
          {t(filterClearTranslationKey)}
        </ClearFilters>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <FilterList>
          {allwaysVisibleItems.map((option: T[keyof T], index: number) => {
            const key = `${option}_${index}`
            return (
              <FilterItem key={key}>
                <Checkbox
                  id={`${filterTitle}_${option}`}
                  icon={<CheckboxOutlinedIcon />}
                  checkedIcon={<CheckboxIcon />}
                  checked={isFilterChecked(option)}
                  onChange={(event) => handleSelectedChange(event, option)}
                  data-testid={`Chk_Filter_Option_${filterIndex}_${index}`}
                />
                <label htmlFor={`${filterTitle}_${option}`}>
                  <ListItemText
                    data-testid={`Lbl_Filter_Option_Title_${filterIndex}_${index}`}
                    primary={
                      typeof option === 'string'
                        ? formatFilterDisplayFunction(option)
                        : `${option}`
                    }
                  />
                </label>
              </FilterItem>
            )
          })}
          <Collapse in={showMoreItems} timeout="auto" unmountOnExit>
            {moreItems.map((option: T[keyof T], index: number) => {
              const key = `${option}_${index}`
              return (
                <FilterItem key={key}>
                  <Checkbox
                    icon={<CheckboxOutlinedIcon />}
                    checkedIcon={<CheckboxIcon />}
                    checked={new Set(selectedValues?.get(filterProperty)).has(
                      option,
                    )}
                    onChange={(event) => handleSelectedChange(event, option)}
                    data-testid={`Chk_Filter_Option_${filterIndex}_${index}`}
                  />
                  <label htmlFor={`${filterTitle}_${option}`}>
                    <ListItemText
                      data-testid={`Lbl_Filter_Option_Title_${filterIndex}_${index}`}
                      primary={
                        typeof option === 'string'
                          ? formatFilterDisplayFunction(option)
                          : `${option}`
                      }
                    />
                  </label>
                </FilterItem>
              )
            })}
          </Collapse>
          {shouldCollapseItems && (
            <FilterItem>
              <StyledButton
                sx={{
                  height: 56,
                  color: 'secondary.main',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  padding: '0 6px',
                }}
                data-testid={
                  showMoreItems
                    ? `Btn_Filter_SeeLess_${filterIndex}`
                    : `Btn_Filter_SeeMore_${filterIndex}`
                }
                onClick={() => handleFilterGroupExpanded(name, !showMoreItems)}
                aria-live="polite">
                <Typography
                  sx={{
                    color: 'secondary.main',
                    fontWeight: 'medium',
                    fontSize: '1.125rem',
                    fontFamily: 'Prelo-Medium, sans-serif',
                  }}>
                  {showMoreItems
                    ? t('Filters.CollapsedToggle.SeeLess')
                    : t('Filters.CollapsedToggle.SeeMore')}
                </Typography>
              </StyledButton>
            </FilterItem>
          )}
        </FilterList>
      </Collapse>
    </div>
  )
}

export default FilterGroup
