/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Routes, Route } from 'react-router-dom'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import Projects from '../../components/Projects'

const ProjectsPage = () => (
  <>
    <MainMenu currentItem="projects" />
    <MainContent>
      <Routes>
        <Route path="/" element={<Projects />} />
        <Route path=":projectId" element={<h1>Project</h1>} />
      </Routes>
    </MainContent>
  </>
)

export default ProjectsPage
