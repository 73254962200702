import { Box, Button, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AnalyticEvent } from '../../../analytics/adobe/types'
import flagFavorites from '../../../api/insights/flagFavorites'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import Loader from '../../../rsmCoreComponents/Loader'
import { getReloadHomeInsights } from '../../../store/profile/profileModalSlice'
import { getCMSHealthInfo } from '../../../store/serviceHealth/serviceHealthSlice'
import { Styles } from '../../../types'
import {
  ArticleMetaData,
  CheckRsmusIsDown,
  getArticleListForHomePage,
} from '../../../utils/helpers/Article.service'
import { useAppSelector } from '../../../utils/hooks'
import SectionHeader from '../../layouts/SectionHeader'
import ArticleHomeTile from '../ArticleHomeTile'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

const styles: Styles = {
  container: (theme) => ({
    marginTop: '2rem',
    padding: '1.6875rem 1rem 1.25rem 1rem',
    minHeight: 600,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('tablet')]: {
      marginTop: '3rem',
    },
    [theme.breakpoints.only('tablet')]: {
      padding: '2.1875rem 2rem 2rem 2rem',
    },
    [theme.breakpoints.up('desktop')]: {
      padding: '2.1875rem 6.5rem 2.6875rem 6.5rem',
    },
  }),
  articlesContainer: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-2.25rem -0.625rem 2.25rem',
    [theme.breakpoints.up('desktop')]: {
      margin: '-1.875rem -1.25rem 2.25rem',
    },
  }),
  articleContainer: (theme) => ({
    marginTop: '0.9375rem',
    width: '100%',
    [theme.breakpoints.only('tablet')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.up('desktop')]: {
      flexBasis: '33.333333%',
    },
  }),
  viewAllButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.375rem',
    [theme.breakpoints.only('tablet')]: {
      marginTop: '0.8125rem',
    },
    [theme.breakpoints.up('desktop')]: {
      marginTop: '1.75rem',
    },
  }),
}

const HomeInsightsSection = () => {
  const { t } = useTranslation()
  const shouldReload = useAppSelector(getReloadHomeInsights)
  const [isRsmusDown, setIsRsmusDown] = useState<boolean>(true)
  const cmsIsHealthy = useSelector(getCMSHealthInfo)
  const [articleMetadata, setArticleMetaData] = useState<ArticleMetaData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { isMobile, isTablet } = useDeviceType()
  const theme = useTheme()

  const loadArticles = useCallback(
    async (pageSize: number) => {
      setIsLoading(true)
      const pagedResults = await getArticleListForHomePage(pageSize)
      // Fetch favorites only for new page
      await flagFavorites(pagedResults.articles)

      setArticleMetaData(pagedResults.articles)
      setIsLoading(false)
    },
    [
      setIsLoading,
      getArticleListForHomePage,
      flagFavorites,
      setArticleMetaData,
    ],
  )

  useEffect(() => {
    const CheckRsmus = async () => {
      setIsRsmusDown(await CheckRsmusIsDown())
    }

    CheckRsmus()
    loadArticles(isMobile || isTablet ? 2 : 3)
  }, [isMobile, isTablet, shouldReload])

  // Handle Error conditions
  if (isRsmusDown || !cmsIsHealthy?.IsHealthy) return null

  return (
    <Box
      component="section"
      sx={styles.container}
      data-testid="Sec_Home_Insights">
      <SectionHeader
        title={t('HomePage.InsightsHeader')}
        testId="Lbl_Home_Insights"
        alternateStyle
      />
      <Loader loading={isLoading}>
        <Box sx={styles.articlesContainer}>
          {articleMetadata.map((article: ArticleMetaData, index: number) => (
            <Box key={article.ecpId} sx={styles.articleContainer}>
              <ArticleHomeTile
                index={index}
                article={article}
                defaultImageColor={theme.palette.primary.main}
              />
            </Box>
          ))}
        </Box>
        <Box sx={styles.viewAllButtonContainer}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate('/insights')}
            data-testid="Btn_Insights_ViewAll"
            data-analytic-event={analyticEventType}>
            {t('HomePage.ViewAllInsights')}
          </Button>
        </Box>
      </Loader>
    </Box>
  )
}

export default HomeInsightsSection
