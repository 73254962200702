import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

export type SpinnerProps = {
  open: boolean
}

const Spinner = ({ open }: SpinnerProps) => (
  <Backdrop
    data-testid="spinner"
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}>
    <CircularProgress color="inherit" />
  </Backdrop>
)
export default Spinner
