import React from 'react'

const GearConferenceIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5608 37.3309L24.0908 42.3909L28.4308 42.8509C28.7408 43.7709 29.1608 44.6309 29.6608 45.4309L27.5008 49.6809L31.4508 52.8809L34.8508 50.1309C35.7008 50.5509 36.6008 50.8709 37.5408 51.0909L39.0208 55.6209L44.0808 55.0909L44.5408 50.7509C45.4608 50.4409 46.3208 50.0209 47.1208 49.5209L51.3708 51.6809L54.5708 47.7309L51.8208 44.3309C52.2408 43.4809 52.5608 42.5809 52.7808 41.6409L57.3108 40.1609L56.7808 35.1109L52.4408 34.6509C52.1308 33.7309 51.7108 32.8709 51.2108 32.0709L53.3608 27.8209L49.4108 24.6209L46.0108 27.3709C45.1608 26.9509 44.2608 26.6309 43.3208 26.4109L41.8408 21.8809L36.7808 22.4109L36.3208 26.7509C35.4108 27.0609 34.5408 27.4809 33.7408 27.9809L29.4908 25.8209L26.2908 29.7709L29.0408 33.1709C28.6208 34.0209 28.3008 34.9209 28.0808 35.8609L23.5508 37.3409L23.5608 37.3309Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.6699 31.4109C43.7099 30.9809 47.3399 33.9209 47.7699 37.9609C48.1999 42.0109 45.2599 45.6309 41.2199 46.0609C37.1699 46.4909 33.5499 43.5509 33.1199 39.5109C32.6899 35.4609 35.6299 31.8409 39.6699 31.4109V31.4109Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.5508 11.9111C57.3108 9.51114 60.1708 7.41114 62.7908 7.06114C64.4708 6.84114 66.6908 6.91114 67.8808 8.11114C68.8508 9.09114 70.0108 10.2611 71.3608 11.6011C72.6108 12.8511 72.6308 15.1711 72.3708 16.9211C71.9808 19.4711 69.9008 22.2511 67.5608 24.9211"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.5209 11.7704C73.0509 12.0504 74.6809 11.6004 75.8609 10.4204C76.7309 9.55037 77.2109 8.42037 77.2809 7.28037C77.3709 5.91037 76.8909 4.51037 75.8409 3.46037C74.8909 2.51037 73.6509 2.02037 72.4009 2.01037C71.1209 1.99037 69.8409 2.47037 68.8709 3.44037C67.6809 4.63037 67.2409 6.27037 67.5209 7.81037"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.1702 63.3092C53.2483 63.3092 63.8502 52.7073 63.8502 39.6292C63.8502 26.5511 53.2483 15.9492 40.1702 15.9492C27.0921 15.9492 16.4902 26.5511 16.4902 39.6292C16.4902 52.7073 27.0921 63.3092 40.1702 63.3092Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.8407 68.5408C56.7852 68.5408 69.7107 55.6152 69.7107 39.6708C69.7107 23.7263 56.7852 10.8008 40.8407 10.8008C24.8962 10.8008 11.9707 23.7263 11.9707 39.6708C11.9707 55.6152 24.8962 68.5408 40.8407 68.5408Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0908 24.7302C9.69083 21.9702 7.59083 19.1101 7.24083 16.4901C7.02083 14.8101 7.09083 12.5901 8.29083 11.4001C9.27083 10.4301 10.4408 9.27015 11.7808 7.92015C13.0308 6.67015 15.3508 6.65015 17.1008 6.91015C19.6508 7.30015 22.4308 9.38015 25.1008 11.7201"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9501 7.77066C12.2301 6.24066 11.7801 4.61066 10.6001 3.43066C9.73005 2.56066 8.60005 2.08066 7.46005 2.01066C6.09005 1.92066 4.69005 2.40066 3.64005 3.45066C2.69005 4.40066 2.20005 5.64066 2.19005 6.89066C2.17005 8.17066 2.65005 9.45066 3.62005 10.4207C4.81005 11.6107 6.45005 12.0507 7.99005 11.7707"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.6188 67.0901C22.8588 69.4901 19.9988 71.5901 17.3788 71.9401C15.6988 72.1601 13.4788 72.0901 12.2888 70.8901C11.3188 69.9101 10.1588 68.7401 8.80882 67.4001C7.55882 66.1501 7.53882 63.8301 7.79882 62.0801C8.18882 59.5301 10.2688 56.7501 12.6088 54.0801"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.64956 67.2309C7.11956 66.9509 5.48956 67.4009 4.30956 68.5809C3.43956 69.4509 2.95956 70.5809 2.88956 71.7209C2.79956 73.0909 3.27956 74.4909 4.32956 75.5409C5.27956 76.4909 6.51956 76.9809 7.76956 76.9909C9.04956 77.0109 10.3296 76.5309 11.2996 75.5609C12.4896 74.3709 12.9296 72.7309 12.6496 71.1909"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.0803 54.2695C70.4803 57.0295 72.5803 59.8895 72.9303 62.5095C73.1503 64.1895 73.0803 66.4095 71.8803 67.5995C70.9003 68.5695 69.7303 69.7295 68.3903 71.0795C67.1403 72.3295 64.8203 72.3495 63.0703 72.0895C60.5203 71.6995 57.7403 69.6195 55.0703 67.2795"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.2212 71.2299C67.9412 72.7599 68.3912 74.3899 69.5712 75.5699C70.4412 76.4399 71.5712 76.9199 72.7112 76.9899C74.0812 77.0799 75.4812 76.5999 76.5312 75.5499C77.4812 74.5999 77.9712 73.3599 77.9812 72.1099C78.0012 70.8299 77.5212 69.5499 76.5512 68.5799C75.3612 67.3899 73.7212 66.9499 72.1812 67.2299"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GearConferenceIcon
