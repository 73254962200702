import { DocumentModel } from '@rsmus/ecp-documentrequestservice'
import { t } from 'i18next'
import { DateTime } from 'luxon'
import { RSMTableHeadData } from '../../../rsmCoreComponents/components/table/RSMTableBody'

export interface DocumentsViewTable {
  documentId: number
  icon?: string
  fileName?: string
  createdBy?: string
  createdDate?: string
  date?: Date
  group?: string
  subgroup?: string
  status?: string
  documentRequestName?: string
  id?: number
}

const formatDate = (old?: Date) => {
  if (!old) return ''
  const date = old.toString()
  return `${date.substr(4, 3)} ${date.substr(8, 2)}, ${date.substr(11, 4)}`
}

export const structureBodyData = (documents: DocumentModel[]) => {
  const bdataArray: DocumentsViewTable[] = []
  documents.forEach((item) => {
    const bdata: DocumentsViewTable = {
      documentId: item.documentId,
      icon: item.contentType,
      fileName: item.fileName,
      createdBy: item.createdBy,
      createdDate: formatDate(item.createdDate),
      date: item.createdDate,
      group: item.group,
      subgroup: item.subgroup,
      status: item.status,
      documentRequestName: item.documentRequestName,
      id: item.documentRequestId,
    }
    bdataArray.push(bdata)
  })
  return bdataArray
}

export const structureHeaderData = (
  showEngagementSortHoverDocName: boolean,
  showEngagementSortHoverCreatedBy: boolean,
  showEngagementSortHoverCreatedDate: boolean,
  showEngagementSortHoverGroupLevel1: boolean,
  showEngagementSortHoverGroupLevel2: boolean,
  showEngagementSortHoverStatus: boolean,
  setShowEngagementSortHoverDocName: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverCreatedBy: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverCreatedDate: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverGroupLevel1: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverGroupLevel2: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverStatus: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  theme: any,
) => {
  const dataArray: RSMTableHeadData[] = []

  const icon: RSMTableHeadData = {
    isSortable: false,
    shouldDisplay: true,
    title: t(''),
    key: 'icon',
    styles: {
      width: '4%',
      [theme.breakpoints.down('desktop')]: {
        width: '6%',
      },
      [theme.breakpoints.down('tablet')]: {
        width: '15%',
      },
    },
  }
  dataArray.push(icon)

  const fileName: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Docs.Headers.documentName'),
    key: 'fileName',
    hover: showEngagementSortHoverDocName,
    setHover: setShowEngagementSortHoverDocName,
    styles: {
      width: '15%',
      [theme.breakpoints.down('tablet')]: {
        width: '15%',
      },
      [theme.breakpoints.down('mobile')]: {
        width: '60%',
      },
    },
  }
  dataArray.push(fileName)

  const createdBy: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Docs.Headers.createdBy'),
    key: 'createdBy',
    hover: showEngagementSortHoverCreatedBy,
    setHover: setShowEngagementSortHoverCreatedBy,
    styles: {
      width: '13%',
      [theme.breakpoints.down('tablet')]: {
        width: '13%',
      },
    },
  }
  dataArray.push(createdBy)

  const createdDate: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Docs.Headers.createdDate'),
    key: 'date',
    hover: showEngagementSortHoverCreatedDate,
    setHover: setShowEngagementSortHoverCreatedDate,
    styles: {
      width: '12%',
      [theme.breakpoints.down('tablet')]: {
        width: '12%',
      },
    },
  }
  dataArray.push(createdDate)

  const groupLevel1: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Docs.Headers.group'),
    key: 'groupLevel1',
    hover: showEngagementSortHoverGroupLevel1,
    setHover: setShowEngagementSortHoverGroupLevel1,
    styles: {
      width: '13%',
      [theme.breakpoints.down('tablet')]: {
        width: '13%',
      },
    },
  }
  dataArray.push(groupLevel1)

  const groupLevel2: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Docs.Headers.subgroup'),
    key: 'groupLevel2',
    hover: showEngagementSortHoverGroupLevel2,
    setHover: setShowEngagementSortHoverGroupLevel2,
    styles: {
      width: '13%',
      [theme.breakpoints.down('tablet')]: {
        width: '13%',
      },
    },
  }
  dataArray.push(groupLevel2)

  const status: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Docs.Headers.status'),
    key: 'status',
    hover: showEngagementSortHoverStatus,
    setHover: setShowEngagementSortHoverStatus,
    styles: {
      width: '9%',
      [theme.breakpoints.down('tablet')]: {
        width: '9%',
      },
    },
  }
  dataArray.push(status)

  const request: RSMTableHeadData = {
    isSortable: false,
    shouldDisplay: true,
    title: t('Docs.Headers.request'),
    key: '',
  }
  dataArray.push(request)

  return dataArray
}

const formatDateSearch = (old?: Date) => {
  if (!old) return ''
  return DateTime.fromJSDate(old).toFormat('DD')
}

export const customSearch = (
  data: any,
  searchString: string,
  searchColumns: string[],
  searchCharacter = 1,
): DocumentModel[] => {
  const filteredData: any = data.filter((item: any) =>
    searchColumns.some((newItem: any) => {
      const searchItem =
        item[newItem] instanceof Date
          ? formatDateSearch(item[newItem])
          : item[newItem]
      if (searchString.length > searchCharacter) {
        return (
          searchItem
            ?.toString()
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1
        )
      }
      return true
    }),
  )
  return filteredData
}
