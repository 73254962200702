import React from 'react'

const MobileLockIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.8309 9.18738L48.7904 1.46149C50.297 1.09728 51.808 2.02829 52.1699 3.52517L67.6757 67.6676C68.0399 69.1742 67.1113 70.695 65.6047 71.0592L33.655 78.7827C32.1484 79.1469 30.6276 78.2183 30.2634 76.7117L14.7599 12.579C14.3957 11.0724 15.3243 9.55159 16.8309 9.18738Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.8792 70.9009C46.6592 69.9709 47.2292 69.0309 48.1592 68.8109C49.0892 68.5909 50.0292 69.1609 50.2592 70.0909C50.4892 71.0209 49.9092 71.9609 48.9792 72.1909C48.0492 72.4209 47.1092 71.8409 46.8792 70.9109V70.9009Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M28.0508 67.5302L65.4608 58.4902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.7305 16.5498L53.1405 7.50977"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30.7109 9.47078L36.5709 8.05078"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M31.6327 38.5789L49.0315 34.3729C49.7217 34.2061 50.4217 34.6335 50.5885 35.3237L54.2447 50.448C54.4115 51.1381 53.9864 51.8479 53.2866 52.0171L35.8877 56.2231C35.1976 56.3899 34.4878 55.9648 34.3187 55.265L30.6625 40.1406C30.4957 39.4505 30.9231 38.7504 31.6133 38.5836L31.6327 38.5789Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M48.0795 34.6099L45.3995 35.2599L44.7595 32.6299C44.0895 29.8399 41.2695 28.1199 38.4795 28.7899C35.6895 29.4599 33.9695 32.2799 34.6395 35.0699L35.2795 37.6999L32.6095 38.3499L31.9695 35.7199C30.9395 31.4499 33.5695 27.1499 37.8395 26.1199C42.1095 25.0899 46.4095 27.7199 47.4395 31.9799L48.0795 34.6099V34.6099Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default MobileLockIcon
