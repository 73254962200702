import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ClientLayoutMultiple from '../ClientLayoutMultiple'
import AccountInformation from '../AccountManagement/AccountInformation'
import AccountSupport from '../AccountManagement/AccountSupport'
import AccountReferenceDocuments from '../AccountManagement/AccountReferenceDocuments'
import ScheduledPayments from '../AccountManagement/ScheduledPayments'
import ClientLayoutSingle from '../ClientLayoutSingle'
import PaymentManagement from '../AccountManagement/PaymentManagement'
import api from '../../../api'

const DashboardLayout = () => {
  const { clientId = '' } = useParams()
  const navigate = useNavigate()

  // If no client was specified and the user only has one, show that client.
  useEffect(() => {
    if (!clientId) {
      api.finance.invoice_GetInvoicePayers([]).then((response) => {
        const payerCount = response?.data?.length || 0
        if (payerCount === 1) {
          navigate(
            `/invoicing/dashboard/${
              response?.data?.[0]?.payerId?.toString() || ''
            }`,
          )
        }
      })
    }
  }, [clientId])

  if (clientId) {
    return (
      <ClientLayoutSingle
        accountInfoSlot={<AccountInformation layoutContainer="dashboard" />}
        paymentManagementSlot={
          <PaymentManagement key={clientId} layoutContainer="dashboard" />
        }
        supportSlot={<AccountSupport />}
        referenceDocumentsSlot={<AccountReferenceDocuments />}
        scheduledPaymentsSlot={<ScheduledPayments />}
      />
    )
  }
  return (
    <ClientLayoutMultiple
      accountInfoSlot={<AccountInformation layoutContainer="dashboard" />}
      supportSlot={<AccountSupport />}
      referenceDocumentsSlot={<AccountReferenceDocuments />}
      scheduledPaymentsSlot={<ScheduledPayments />}
    />
  )
}

export default DashboardLayout
