import React from 'react'

const JPG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M15.25 18.875H5.75V19.5H15.25C15.525 19.5 15.75 19.2188 15.75 18.875V18.25C15.75 18.5938 15.525 18.875 15.25 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="4" y="12" width="12" height="7" rx="0.8" fill="#E49804" />
    <path
      d="M7.5313 13.9392V16.1848C7.5313 16.7576 7.2603 17.0439 6.71831 17.0439C6.54692 17.0439 6.38652 17.0308 6.23711 17.0044L6.28545 16.4771C6.39092 16.4917 6.47661 16.4998 6.54253 16.5012C6.78276 16.5012 6.90288 16.384 6.90288 16.1497V13.9392H7.5313ZM8.88379 15.3674H9.49243C9.78394 15.3674 9.92896 15.2231 9.92749 14.9346C9.92749 14.646 9.78247 14.5017 9.49243 14.5017H8.88379V15.3674ZM8.88379 15.9299V17H8.25317V13.9392H9.55835C9.87622 13.9392 10.1252 14.0293 10.3054 14.2095C10.4856 14.3896 10.5757 14.6321 10.5757 14.9368C10.5757 15.2415 10.4856 15.4832 10.3054 15.6619C10.1252 15.8406 9.87622 15.9299 9.55835 15.9299H8.88379ZM13.5937 15.3521V16.833C13.1953 16.9736 12.8481 17.0439 12.5522 17.0439C11.9985 17.0439 11.6184 16.8857 11.4118 16.5693C11.2287 16.291 11.1372 15.9167 11.1372 15.4465C11.1372 14.6877 11.3701 14.2095 11.8359 14.0117C12.0175 13.9341 12.2614 13.8953 12.5676 13.8953C12.8752 13.8953 13.2172 13.9524 13.5937 14.0667L13.4553 14.5918C13.0715 14.5024 12.758 14.4578 12.5148 14.4578C12.0373 14.4578 11.7985 14.7874 11.7985 15.4465C11.7985 15.7849 11.8571 16.042 11.9743 16.2178C12.0915 16.3936 12.2834 16.4814 12.55 16.4814C12.6906 16.4814 12.8291 16.4565 12.9653 16.4067V15.9146H12.5017V15.3521H13.5937Z"
      fill="white"
    />
  </svg>
)

export default JPG
