import React from 'react'
import { styled } from '@mui/material'

interface ClientLayoutMultipleProps {
  accountInfoSlot: React.ReactNode
  supportSlot: React.ReactNode
  referenceDocumentsSlot: React.ReactNode
  scheduledPaymentsSlot: React.ReactNode
}

const Container = styled('div')(({ theme }) => ({
  padding: '1rem',
  [theme.breakpoints.only('tablet')]: {
    padding: '2.5rem 2rem',
  },
  [theme.breakpoints.up('desktop')]: {
    padding: '2.5rem 6.5rem',
  },
}))

const GridWrapper = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, [col] 1fr)',
  gridTemplateRows: '[row] auto [row] auto [row]',
  gridGap: '1rem',
}))

const AccountInfoBox = styled('div')({
  gridColumn: 'col 1 / span 6',
  gridRow: 'row 1 / span 1',
})

const ScheduledPaymentsBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 5 / span 2',
  gridRow: 'row 2 / span 2',
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 4 / span 3',
    gridRow: 'row 3 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    gridColumn: 'col 1 / span 6',
    gridRow: 'row 3 / span 1',
  },
  display: 'flex',
  '> div': {
    flex: '1 1 100%',
  },
}))

const SupportBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 1 / span 4',
  gridRow: 'row 2 / span 1',
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 1 / span 3',
    gridRow: 'row 3 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    gridColumn: 'col 1 / span 6',
    gridRow: 'row 4 / span 1',
  },
  display: 'flex',
  '> div': {
    flex: '1 1 100%',
  },
}))

const ReferenceDocumentsBox = styled('div')(({ theme }) => ({
  gridColumn: 'col 1 / span 4',
  gridRow: 'row 3 / span 1',
  [theme.breakpoints.only('tablet')]: {
    gridColumn: 'col 1 / span 6',
    gridRow: 'row 4 / span 1',
  },
  [theme.breakpoints.down('tablet')]: {
    gridColumn: 'col 1 / span 6',
    gridRow: 'row 5 / span 1',
  },
  display: 'flex',
  '> div': {
    flex: '1 1 100%',
  },
}))

const ClientLayoutMultiple = ({
  accountInfoSlot,
  supportSlot,
  referenceDocumentsSlot,
  scheduledPaymentsSlot,
}: ClientLayoutMultipleProps) => (
  <Container>
    <GridWrapper>
      <AccountInfoBox>{accountInfoSlot}</AccountInfoBox>
      <ScheduledPaymentsBox>{scheduledPaymentsSlot}</ScheduledPaymentsBox>
      <SupportBox>{supportSlot}</SupportBox>
      <ReferenceDocumentsBox>{referenceDocumentsSlot}</ReferenceDocumentsBox>
    </GridWrapper>
  </Container>
)

export default ClientLayoutMultiple
