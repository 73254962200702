import React from 'react'

const TennisIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131132)">
      <path
        d="M38.1797 19.4609L61.5097 42.3609"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3008 12.8809L67.2708 37.8509"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9805 8.5293L71.5805 31.2393"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.3402 6.58984L36.4102 32.3198"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8392 10.9102L40.1992 39.7402"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.8804 18.4492L46.9004 44.0692"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1904 55.7491C29.1904 55.7491 37.9704 47.9691 46.9704 50.0891C55.9704 52.1991 68.7404 45.3591 73.4504 39.2191C78.1604 33.0791 83.6004 15.8391 72.4004 6.54908C61.2004 -2.74092 48.9604 1.03908 39.5504 7.76908C30.1304 14.4991 27.6204 26.3191 29.8204 33.0791C32.0204 39.8391 27.1704 48.4391 24.9004 50.9191"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.0169 55.1381L25.1005 50.9281C24.0548 49.8039 22.2957 49.7403 21.1716 50.786L1.31466 69.2576C0.190495 70.3033 0.126916 72.0624 1.17265 73.1865L5.089 77.3966C6.13474 78.5208 7.89379 78.5844 9.01795 77.5386L28.8748 59.0671C29.999 58.0213 30.0626 56.2623 29.0169 55.1381Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.3809 40.4395L39.2909 44.8295"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.5654 36.3218C75.4839 27.4507 74.8718 15.4078 67.1982 9.42329C59.5247 3.43882 47.6955 5.77898 40.777 14.6502C33.8585 23.5214 34.4706 35.5643 42.1442 41.5487C49.8178 47.5332 61.647 45.193 68.5654 36.3218Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7197 22.4394C23.2646 22.4394 27.7597 17.9443 27.7597 12.3994C27.7597 6.85444 23.2646 2.35938 17.7197 2.35938C12.1747 2.35938 7.67969 6.85444 7.67969 12.3994C7.67969 17.9443 12.1747 22.4394 17.7197 22.4394Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.67969 10.9307C7.67969 10.9307 12.4697 10.5407 16.0997 14.0707C19.7297 17.6007 18.8797 22.3807 18.8797 22.3807"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8109 12.6396C27.8109 12.6396 23.6909 13.4896 20.1509 10.3396C16.6009 7.18961 16.8109 2.34961 16.8109 2.34961"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131132">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TennisIcon
