export interface MoreLess {
  showMoreLess: boolean
  alwaysVisibleItems: string[]
  moreItems: string[]
  showMore: boolean
}

export const processMoreLess = (
  filterOptions: string[],
  visibleItemsCount: number,
): MoreLess => {
  const showMoreLess = filterOptions.length > visibleItemsCount
  const alwaysVisibleItems = showMoreLess
    ? filterOptions.slice(0, visibleItemsCount)
    : filterOptions
  const moreItems = showMoreLess
    ? filterOptions.slice(visibleItemsCount, filterOptions.length)
    : []

  return {
    showMoreLess,
    alwaysVisibleItems,
    moreItems,
    showMore: moreItems.length > 0,
  }
}
