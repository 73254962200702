import React from 'react'

const ChainLinkIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.7402 33.8501C26.3902 31.7101 28.9802 30.3301 31.8902 30.3301H48.1102C51.0202 30.3301 53.6102 31.7101 55.2602 33.8501"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M32.2305 35.0293H47.3805"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M24.4002 45.1696H7.65023C4.53023 45.1696 1.99023 42.6396 1.99023 39.5096C1.99023 36.3896 4.52023 33.8496 7.65023 33.8496H29.0002C32.1202 33.8496 34.6602 36.3796 34.6602 39.5096C34.6602 41.7796 33.3202 43.7396 31.3902 44.6396"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M22.8792 40.5802H7.86922C7.21922 40.5802 6.69922 40.0502 6.69922 39.4102C6.69922 38.7602 7.22922 38.2402 7.86922 38.2402H28.6792C29.3292 38.2402 29.8492 38.7702 29.8492 39.4102C29.8492 40.0602 29.3192 40.5802 28.6792 40.5802H27.5192"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M55.6001 45.1696H72.3401C75.4601 45.1696 78.0001 42.6396 78.0001 39.5096C78.0001 36.3896 75.4701 33.8496 72.3401 33.8496H50.9901C47.8701 33.8496 45.3301 36.3796 45.3301 39.5096C45.3301 41.7796 46.6701 43.7396 48.6001 44.6396"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.1204 40.5802H72.1304C72.7804 40.5802 73.3004 40.0502 73.3004 39.4102C73.3004 38.7602 72.7704 38.2402 72.1304 38.2402H57.0704H51.3204C50.6704 38.2402 50.1504 38.7702 50.1504 39.4102C50.1504 40.0602 50.6804 40.5802 51.3204 40.5802H52.4804"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.1409 38.2305V40.4205C57.1409 45.4005 53.1009 49.4405 48.1209 49.4405H31.9009C26.9209 49.4405 22.8809 45.4005 22.8809 40.4205V39.3505C22.8809 38.9705 22.9009 38.6005 22.9509 38.2305"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M52.3792 38.0898C52.5792 38.6198 52.6992 39.1998 52.6992 39.7998C52.6992 42.4398 50.5592 44.5798 47.9192 44.5798H32.2292C29.5892 44.5798 27.4492 42.4398 27.4492 39.7998C27.4492 39.2498 27.5392 38.7198 27.7192 38.2298"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M40.0703 20.71V9"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M49.5605 23.0593L55.2705 13.5293"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.1406 30.3291L66.5106 24.6191"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.8504 22.5593L24.1504 13.0293"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M22.2802 29.8291L12.9102 24.1191"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.7109 59.0605V70.7705"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30.2195 56.7109L24.5195 66.2409"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M22.6495 49.4395L13.2695 55.1495"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M49.9297 57.2109L55.6297 66.7409"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.5 49.9395L66.88 55.6495"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default ChainLinkIcon
