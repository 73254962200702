import {
  AuthClientConfig as DocumentRequestServiceConfig,
  DocumentRequestServiceClient,
  IApiResponseOfIEnumerableOfDocumentRequestSummaryModel,
} from '@rsmus/ecp-documentrequestservice-legacy'

import { DOCUMENTREQUESTSERVICE_BASE_URL } from '../../envVariables'

const documentRequestService = new DocumentRequestServiceClient(
  new DocumentRequestServiceConfig(),
  DOCUMENTREQUESTSERVICE_BASE_URL,
)

const GetEmployeeDocumentRequestsSummary = (
  documentRequestId: string,
): Promise<IApiResponseOfIEnumerableOfDocumentRequestSummaryModel> =>
  documentRequestService.documentRequest_GetEmployeeDocumentRequestsSummary(
    documentRequestId,
  )

export default GetEmployeeDocumentRequestsSummary
