import React from 'react'

const OperatorIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.0005 70.58V78L17.8805 62.2V54.78C8.56051 44.2 8.31051 31.47 10.4605 25.71C14.4105 15.13 20.5705 8.64996 31.7705 5.26996C40.9705 2.48996 52.3405 6.77996 58.1605 11.65C64.9505 17.34 68.1105 23.66 68.7405 31.87C69.3705 40.08 67.4105 43.31 67.4105 43.31L68.8505 54.93L62.1105 54.77L60.0605 66.62C59.1105 70.1 57.8505 70.41 55.1605 70.57C52.4705 70.73 43.0005 70.57 43.0005 70.57V70.58Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2698 47.7506C39.8921 47.7506 44.4498 43.1929 44.4498 37.5706C44.4498 31.9484 39.8921 27.3906 34.2698 27.3906C28.6476 27.3906 24.0898 31.9484 24.0898 37.5706C24.0898 43.1929 28.6476 47.7506 34.2698 47.7506Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30.4707 5.69922V28.1792"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M36.1797 4V27.57"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.5098 46.2891L64.3198 63.8691"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M67.0803 67.8696C68.6046 67.8696 69.8403 66.6339 69.8403 65.1096C69.8403 63.5853 68.6046 62.3496 67.0803 62.3496C65.556 62.3496 64.3203 63.5853 64.3203 65.1096C64.3203 66.6339 65.556 67.8696 67.0803 67.8696Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.2695 42.4002C36.937 42.4002 39.0995 40.2378 39.0995 37.5702C39.0995 34.9027 36.937 32.7402 34.2695 32.7402C31.6019 32.7402 29.4395 34.9027 29.4395 37.5702C29.4395 40.2378 31.6019 42.4002 34.2695 42.4002Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default OperatorIcon
