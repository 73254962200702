import { styled } from '@mui/material'
import {
  PickersDay as MuiPickersDay,
  pickersDayClasses,
} from '@mui/x-date-pickers'

const RsmPickersDay = styled(MuiPickersDay)(({ theme }) => ({
  '&&:hover': {
    backgroundColor: '#515356',
    color: theme.palette.common.white,
  },
  [`&&.${pickersDayClasses.today}`]: {
    backgroundColor: '#E5E5E5',
    color: '#515356',
    fontWeight: theme.typography.fontWeightBold,
    borderColor: '#E5E5E5',
  },
  [`&&.${pickersDayClasses.selected}`]: {
    backgroundColor: '#2B6C25',
    color: theme.palette.common.white,
  },
  lineHeight: 0,
}))

export default RsmPickersDay
