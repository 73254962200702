interface FileResponse {
  data: Blob
  status: number
  fileName?: string
  headers?: {
    [name: string]: any
  }
}

const downloadFileStream = (response: FileResponse) => {
  if (response.data) {
    const fileName = response.fileName ?? 'untitled.pdf'
    const url = URL.createObjectURL(response.data)
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = fileName
    document.body.appendChild(downloadLink)

    downloadLink.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    )
    document.body.removeChild(downloadLink)

    // Once we've programmatically clicked the link,
    // we want to release the blob URL in order to release the reference to the file
    // see https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
    URL.revokeObjectURL(url)
  }
}

export default downloadFileStream
