/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useTranslation } from 'react-i18next'

import './Filters.css'
import FilterGroup from '../FiltersGroup/FiltersGroup'
import FilterInsights from '../FilterInsights'
import { useGetTaxonomyPreferencesQuery } from '../../../store/cmsService'

export interface FilterGroupExpandedStateDictionary {
  [key: string]: boolean
}

type FilterProps<T> = {
  setFilterSelected: (
    payload: Map<keyof T, Set<T[keyof T]>> | undefined,
  ) => void
  filterSelected: any | undefined
  filterValues: Map<keyof T, Set<T[keyof T]>> | undefined
  showSharedwithMe: boolean
  formatFilterDisplayFunction?: (valueString: string) => string
  filterTitleTranslationKeyPrefix: string
  filterClearTranslationKey: string
  visibleItemsCount: number
  filterGroupExpanded: FilterGroupExpandedStateDictionary
  handleFilterGroupExpanded: (group: string, state: boolean) => void
}

const Filters = <T,>({
  filterValues,
  filterSelected,
  showSharedwithMe,
  setFilterSelected,
  formatFilterDisplayFunction = (valueString: string) => valueString,
  filterTitleTranslationKeyPrefix,
  filterClearTranslationKey,
  visibleItemsCount,
  filterGroupExpanded,
  handleFilterGroupExpanded,
}: FilterProps<T>) => {
  const taxonomyPreferences = useGetTaxonomyPreferencesQuery()
  const { t } = useTranslation()

  const yourinsights = filterValues?.get('yourinsights' as keyof T)
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  let yourinsightsOptions = []
  if (yourinsights) {
    yourinsightsOptions = Array.from(yourinsights)
    // removing shared-with-me by POSITION last
    if (!showSharedwithMe) yourinsightsOptions.pop()
  }
  const filters = new Map(filterValues)
  filters.delete('yourinsights' as keyof T)

  return (
    <div className="filters">
      {yourinsightsOptions.length > 0 && (
        <FilterInsights
          name="InsightsList.FilterHeaders.yourinsights"
          filterProperty={'yourinsights' as keyof T}
          filterTitle={t('InsightsList.FilterHeaders.yourinsights')}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          filterOptions={yourinsightsOptions}
          selectedValues={filterSelected}
          setSelectedValues={setFilterSelected}
          formatFilterDisplayFunction={formatFilterDisplayFunction}
          visibleItemsCount={visibleItemsCount}
          showMoreItems={
            filterGroupExpanded['InsightsList.FilterHeaders.yourinsights'] ??
            false
          }
          handleFilterGroupExpanded={handleFilterGroupExpanded}
        />
      )}
      {filters !== undefined &&
        Array.from(filters.entries()).map((filter, index) => {
          const key = `${filter}_${index}`
          const title = `${filterTitleTranslationKeyPrefix}.${String(
            filter[0],
          )}`
          const showMoreItems = filterGroupExpanded[title] ?? false
          const options = Array.from(filter[1].values())

          ;(options as any[]).sort((a) =>
            taxonomyPreferences.data?.data
              .map((item) => item?.tag?.split('/').at(-1))
              .includes(a)
              ? -1
              : 1,
          )

          return (
            <FilterGroup
              key={key}
              name={title}
              filterProperty={filter[0]}
              filterTitle={t(title)}
              filterIndex={index}
              filterOptions={options.sort() as Array<T[keyof T]>}
              selectedValues={filterSelected}
              setSelectedValues={setFilterSelected}
              formatFilterDisplayFunction={formatFilterDisplayFunction}
              filterClearTranslationKey={filterClearTranslationKey}
              visibleItemsCount={visibleItemsCount}
              showMoreItems={showMoreItems}
              handleFilterGroupExpanded={handleFilterGroupExpanded}
            />
          )
        })}
    </div>
  )
}

export default Filters
