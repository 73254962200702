import React from 'react'

const ShoppingCartIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2305 24.9805V52.1705"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.9492 24.9805V52.1705"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.6602 23.9199V53.1999"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.3809 24.0195V53.6595"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M49.0898 23.6992V51.2892"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M2.76953 34.0098H55.5295"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M4.10938 44.1309H51.6394"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M78.7092 4.05078L65.1792 6.87078L48.0092 54.1408L4.97922 51.7208L1.94922 25.2508L59.1892 23.3608"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M3.76953 69.0906H54.0695C54.0695 69.0906 59.7295 66.2606 57.0995 62.0206L48.0095 54.1406"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M5.58984 74.3492C5.58984 71.7792 7.66984 69.6992 10.2398 69.6992C12.8098 69.6992 14.8898 71.7792 14.8898 74.3492C14.8898 76.9192 12.8098 78.9992 10.2398 78.9992C7.66984 78.9992 5.58984 76.9192 5.58984 74.3492Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M48.5 74.3492C48.5 71.7792 50.58 69.6992 53.15 69.6992C55.72 69.6992 57.8 71.7792 57.8 74.3492C57.8 76.9192 55.72 78.9992 53.15 78.9992C50.58 78.9992 48.5 76.9192 48.5 74.3492Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default ShoppingCartIcon
