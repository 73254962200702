import { sxStyles } from '../../../styles/sxStyles'
import tokens from '../../../styles/tokens.json'

export const useStyles = () =>
  sxStyles({
    snackbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      right: '1.5rem !important',
    },
    alertRoot: (theme) => ({
      backgroundColor: tokens.colors.warning.main,
      fontWeight: 500,
      color: '#515356',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
      paddingTop: '0.625rem',
      '.MuiAlert-icon': {
        margin: 0,
        paddingLeft: '0.625rem',
      },
      fontFamily: 'Prelo-Book',
      '.MuiAlert-message': {
        paddingRight: '3rem',
        '.subTitle': {
          fontSize: '1.5rem',
        },
      },
      '.MuiAlert-action': {
        position: 'absolute',
        top: '0.313rem',
        right: '0.938rem',
      },
      [theme.breakpoints.up('mobile')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.up('tablet')]: {
        minHeight: '7.5rem',
        flexDirection: 'row',
      },
    }),
    header: (theme) => ({
      fontSize: '1.125rem',
      fontFamily: tokens.type.fontFamilies.preloBold,
      textAlign: {
        desktop: 'left',
        mobile: 'center',
      },

      [theme.breakpoints.up('mobile')]: {
        display: 'block',
        textAlign: 'center',
      },
      [theme.breakpoints.up('tablet')]: {
        display: 'none',
      },
      [theme.breakpoints.up('desktop')]: {
        display: 'block',
        textAlign: 'left',
      },
    }),
    smHeader: (theme) => ({
      fontSize: '1.125rem',
      fontFamily: tokens.type.fontFamilies.preloBold,
      [theme.breakpoints.up('mobile')]: {
        display: 'none',
      },
      [theme.breakpoints.up('tablet')]: {
        display: 'inline-block',
        paddingRight: '0.5rem',
      },
      [theme.breakpoints.up('desktop')]: {
        display: 'none',
      },
    }),
    msg: (theme) => ({
      fontSize: '1rem',
      textAlign: {
        [theme.breakpoints.up('mobile')]: {
          textAlign: 'center',
        },
        [theme.breakpoints.up('tablet')]: {
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    }),
    contentRoot: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '1rem',
    },
    alertContainer: {},
    successAlert: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#C8E6C6',
      fontWeight: 500,
      minHeight: '7.813rem',
      color: '#515356',
      fontSize: '1rem',
      fontFamily: 'Prelo-Book',
    },
    successSnackbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      right: '1.5rem !important',
    },
    alertIcon: (theme) => ({
      '> svg': {
        [theme.breakpoints.up('mobile')]: {
          height: '5.938rem',
          width: '5.938rem',
        },
        [theme.breakpoints.up('tablet')]: {
          height: '3.063rem',
          width: '3.063rem',
        },
        [theme.breakpoints.up('desktop')]: {
          height: '4rem',
          width: '4rem',
        },
      },
    }),
  })

export default useStyles
