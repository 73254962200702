import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface SupportState {
  isDashIssue: boolean
}

const initialState: SupportState = {
  isDashIssue: false,
}

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setIsDashIssue: (state, { payload }) => {
      state.isDashIssue = payload
    },
  },
})

export const getIsDashIssue = (state: RootState) => state.support.isDashIssue
export const { setIsDashIssue } = supportSlice.actions

export default supportSlice.reducer
