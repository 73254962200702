import React, { useMemo, useCallback } from 'react'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FileResponse } from '@rsmus/ecp-financeservice'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import api from '../../../api'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import {
  getPayableAmount,
  PayOnAccountPayment,
  setPayOnAccountClient,
  setPayOnAccountPayment,
  setPayableAmount,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from '../Invoices/InvoicesHeader'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import { getPaymentMethodDisplay } from './PaymentSuccess.service'

interface PayOnAccountPaymentSuccessProps {
  paymentTransaction: PayOnAccountPayment
}

const styles: Styles = {
  paymenthistoryLink: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      justifyContent: 'start',
      verticalAlign: 'top',
      padding: 0,
      marginBottom: '1rem',
    },
    [theme.breakpoints.only('mobile')]: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      fontSize: '0.75rem',
      padding: 0,
    },
  }),
  paymenthistorylinkButton: {
    display: 'block',
    textDecoration: 'underline',
  },
  detailContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingTop: '4rem',
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '4rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  detailSection: () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  }),
  details: () => ({
    flex: '1 1 auto',
  }),
  detailRow: () => ({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  }),
  detailLabel: (theme) => ({
    fontWeight: 'bold',
    [theme.breakpoints.only('desktop')]: {
      minWidth: '15rem',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '10rem',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '7rem',
      fontSize: '0.875rem',
    },
  }),
  detailLabelContent: (theme) => ({
    [theme.breakpoints.only('tablet')]: {
      minWidth: '10rem',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    },
  }),
  autopay: () => ({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20rem',
    height: '10rem',
  }),
  ButtonDownload: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.only('tablet')]: {
      width: '25rem',
      left: '1rem',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '14.5rem',
    },
    [theme.breakpoints.only('desktop')]: {
      display: 'block',
      marginBottom: '0.5rem',
      width: '16rem',
    },
  }),
  descriptionContainer: (theme) => ({
    marginTop: '-1rem',
    marginBottom: '-15rem',
    [theme.breakpoints.only('mobile')]: {
      marginTop: '-2.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      marginBottom: 0,
    },
  }),
}

const PayOnAccountPaymentSuccess = ({
  paymentTransaction,
}: PayOnAccountPaymentSuccessProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop, isTablet, isMobile } = useDeviceType()

  const cemFeatures = useSelector(getCemFeatures)
  const payableAmount = useSelector(getPayableAmount)

  const isAccountManager = useMemo(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_ACCOUNT_MANAGEMENT,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const handleDownloadReceipt = useCallback(async () => {
    const response: FileResponse =
      await api.finance.invoiceDocument_DownloadPayonAccountReceipt(
        paymentTransaction?.transactionId || '',
      )
    downloadFileStream(response)
  }, [
    api.finance.invoiceDocument_DownloadPaymentReceipt,
    downloadFileStream,
    paymentTransaction,
  ])

  const handleBackToButtonNavigation = () => {
    dispatch(setPayOnAccountPayment(null))
    dispatch(setPayOnAccountClient(null))
    dispatch(setPayableAmount(0))
    if (isAccountManager) {
      navigate(`/invoicing/account-management/${paymentTransaction?.clientId}`)
    } else {
      navigate(`/invoicing/dashboard/${paymentTransaction?.clientId}`)
    }
  }

  const getButtonSlot = (): JSX.Element | undefined =>
    isCemFeatureEnabled(
      [
        CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
        CEM_FEATURE_PAYMENT_PAY_INVOICES,
      ],
      cemFeatures,
      'any',
    ) ? (
      <Button
        sx={styles.ButtonDownload}
        size="small"
        aria-label={t('Invoicing.DownloadReceipt')}
        variant="outlined"
        onClick={handleDownloadReceipt}>
        {t('Invoicing.DownloadReceipt')}
      </Button>
    ) : undefined

  const paynowSucessDescription = (
    <Box sx={styles.descriptionContainer}>
      {t('Invoicing.PaymentSuccessPage.PaynowPaymentSucessDescription')}{' '}
      <Button
        onClick={() => navigate('/invoicing/payments')}
        sx={styles.paymenthistoryLink}
        role="link">
        <Box component="span" sx={styles.paymenthistorylinkButton}>
          {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription2')}{' '}
        </Box>
      </Button>{' '}
      {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription3')}
    </Box>
  )

  return (
    <>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={t('Invoicing.PaymentSuccessPage.PaymentSuccess')}
        primaryHeaderDescription={paynowSucessDescription}
        buttonSlot={isDesktop ? getButtonSlot() : null}
        backButtonId={
          isAccountManager ? 'BackToAccountManagement' : 'BackToDashboard'
        }
        backButtonText={
          isAccountManager
            ? t('Invoicing.PayOnAccountPage.BackToAccountManagement')
            : t('Invoicing.PayOnAccountPage.BackToDashboard')
        }
        handleNavigation={handleBackToButtonNavigation}
        amountHeaderId="PaidAmount"
        amountHeaderText={t('Invoicing.PaidAmount')}
        amount={payableAmount}
      />
      <Box sx={{ backgroundColor: tokens.colors.white }}>
        <Box sx={styles.detailContainer}>
          <Box sx={styles.detailSection}>
            <Box sx={styles.details}>
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.AccountName')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {paymentTransaction?.accountName}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.TransactionId')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {paymentTransaction?.transactionId}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.TransactionDate')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {t('Invoicing.PaymentSuccessPage.TransactionDateFormat', {
                    date: paymentTransaction?.transactionDate,
                  })}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PaymentMethod')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaymentMethodDisplay(
                    paymentTransaction?.paymentMethod,
                    paymentTransaction?.accountNumber,
                  )}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PayerName')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {paymentTransaction?.payer}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box
                sx={styles.detailRow}
                style={{ marginTop: '1.5rem', justifyContent: 'center' }}>
                {(isTablet || isMobile) && getButtonSlot()}
              </Box>
            </Box>
            {isDesktop && <Box sx={styles.autopay} />}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default PayOnAccountPaymentSuccess
