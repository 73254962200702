import React from 'react'

const RoboIcon = () => (
<svg width="161" height="162" viewBox="0 0 161 162" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M49.1211 88.6932V75.3383C49.1211 75.3383 48.2465 56.9723 67.8889 56.9723C87.5313 56.9723 87.5313 76.5438 87.5313 76.5438V88.6932" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M93.5107 88.693H42.502V116.301C42.502 116.301 43.8256 131.311 68.0063 131.311C92.1871 131.311 93.5107 116.065 93.5107 116.065V88.693Z" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M68.0059 88.8595V99.0707" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M68.0059 112.094V131.311" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M42.5023 91.9321H31.8184" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M42.4554 102.427H31.7715" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M31.8184 121.714V102.545" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M37.1613 129.113V124.197L31.8194 121.715L27.3047 125.426V130.366L32.8358 134.314" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M93.5117 92.0975H104.196" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M93.5332 102.592H104.217" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M104.195 121.88V102.71" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M98.8535 129.113V124.197L104.195 121.715L108.687 125.426V130.366L103.155 134.314" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M50.3262 127.505V141.214" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M60.8223 130.554V141.238" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M86.2305 126.797V141.618" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M75.7363 130.98V141.664" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M55.6445 146.534V149.725" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M80.9121 146.936V150.127" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M62.1447 150.127V161H41.5332L49.1207 150.127H62.1447Z" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M74.8613 150.127V161H95.4492L87.8854 150.127H74.8613Z" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M49.1211 80.9165H87.5313" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M78.3125 59.4551V52.2694" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M78.5265 52.269C81.281 52.269 83.5139 50.0361 83.5139 47.2816C83.5139 44.5271 81.281 42.2942 78.5265 42.2942C75.772 42.2942 73.5391 44.5271 73.5391 47.2816C73.5391 50.0361 75.772 52.269 78.5265 52.269Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M57.4883 59.4551V52.2694" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M57.7003 52.269C60.4548 52.269 62.6877 50.0361 62.6877 47.2816C62.6877 44.5271 60.4548 42.2942 57.7003 42.2942C54.9458 42.2942 52.7129 44.5271 52.7129 47.2816C52.7129 50.0361 54.9458 52.269 57.7003 52.269Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M62.5227 73.7792C63.8804 73.7792 64.981 72.6786 64.981 71.3209C64.981 69.9633 63.8804 68.8627 62.5227 68.8627C61.1651 68.8627 60.0645 69.9633 60.0645 71.3209C60.0645 72.6786 61.1651 73.7792 62.5227 73.7792Z" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M74.2219 73.7792C75.5796 73.7792 76.6802 72.6786 76.6802 71.3209C76.6802 69.9633 75.5796 68.8627 74.2219 68.8627C72.8643 68.8627 71.7637 69.9633 71.7637 71.3209C71.7637 72.6786 72.8643 73.7792 74.2219 73.7792Z" stroke="#CCCCCC" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M78.0053 112.094H57.9847C55.29 112.094 53.0918 109.896 53.0918 107.201V103.963C53.0918 101.268 55.29 99.0702 57.9847 99.0702H78.0053C80.6999 99.0702 82.8981 101.268 82.8981 103.963V107.201C82.8981 109.896 80.6999 112.094 78.0053 112.094Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M31.8183 102.545C34.7556 102.545 37.1367 100.164 37.1367 97.2268C37.1367 94.2895 34.7556 91.9084 31.8183 91.9084C28.8811 91.9084 26.5 94.2895 26.5 97.2268C26.5 100.164 28.8811 102.545 31.8183 102.545Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M104.195 102.711C107.133 102.711 109.514 100.33 109.514 97.3923C109.514 94.4551 107.133 92.074 104.195 92.074C101.258 92.074 98.877 94.4551 98.877 97.3923C98.877 100.33 101.258 102.711 104.195 102.711Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M55.6445 146.534C58.5817 146.534 60.9628 144.153 60.9628 141.216C60.9628 138.279 58.5817 135.898 55.6445 135.898C52.7073 135.898 50.3262 138.279 50.3262 141.216C50.3262 144.153 52.7073 146.534 55.6445 146.534Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M80.9121 146.935C83.8493 146.935 86.2304 144.554 86.2304 141.617C86.2304 138.68 83.8493 136.299 80.9121 136.299C77.9749 136.299 75.5938 138.68 75.5938 141.617C75.5938 144.554 77.9749 146.935 80.9121 146.935Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M142.06 18.8223C142.06 8.98933 131.093 1 117.549 1C104.005 1 93.0371 8.96569 93.0371 18.8223C93.0371 26.0316 98.9227 32.2246 107.385 35.0374L103.13 45.7213L120.125 36.5501C132.44 35.6047 142.06 28.0172 142.06 18.8223Z" stroke="#009CDE" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
<path d="M110.695 19.7926C110.695 18.7053 109.82 17.8544 108.757 17.8544C107.669 17.8544 106.818 18.7289 106.818 19.7926C106.818 20.8799 107.693 21.7308 108.757 21.7308C109.844 21.7308 110.695 20.8799 110.695 19.7926Z" fill="#CCCCCC"/>
<path d="M120.15 19.7925C120.15 18.7052 119.275 17.8542 118.212 17.8542C117.124 17.8542 116.273 18.7288 116.273 19.7925C116.273 20.8798 117.148 21.7307 118.212 21.7307C119.299 21.7307 120.15 20.8798 120.15 19.7925Z" fill="#CCCCCC"/>
<path d="M129.605 19.7925C129.605 18.7052 128.73 17.8542 127.667 17.8542C126.579 17.8542 125.729 18.7288 125.729 19.7925C125.729 20.8798 126.603 21.7307 127.667 21.7307C128.754 21.7307 129.605 20.8798 129.605 19.7925Z" fill="#CCCCCC"/>
</svg>
)

export default RoboIcon
