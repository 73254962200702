/* eslint-disable */
import React, { useCallback, useEffect, useRef } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal as MuiModal,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

import { Heading } from '../../typography'
import { CloseIcon } from '../../icons'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { RsmLogo } from '../../media'

export type ModalProps = {
  isOpen: boolean | undefined
  children: any
  title?: string
  subTitle?: string
  closeHandler: () => void
  dialogCustomProps?: any
  hasRsmLogo?: boolean
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
    margin: 'auto',
    minWidth: '17.5rem',
    maxWidth: '95%',
    minHeight: '11rem',
    maxHeight: '95%',
    bgcolor: '#fff',
    boxShadow: '1.5rem',
    // pt: '2rem',
    // pb: '4rem',
    // px: '2.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id?: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  const closeButtonRef = useRef<HTMLDivElement>(null)

  // a11y requirement, close only on enter and space
  const closeModal = useCallback(
    (event: any) => {
      if (event.keyCode === 13 || event.keyCode === 32) {
        event.preventDefault() // prevent re-opening the dial when enter pressed
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    closeButtonRef?.current?.focus()
  }, [closeButtonRef?.current])

  return (
    <Box sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close dialog"
          data-testid="Btn_Modal_Close"
          onClick={onClose}
          onKeyDown={(event: any) => closeModal(event)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            padding: '.5rem',
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </Box>
  )
}

const RsmDialog = ({
  isOpen = false,
  closeHandler = () => {},
  children = null,
  title = '',
  subTitle = '',
  dialogCustomProps = {},
  hasRsmLogo = false,
}: ModalProps) => {
  const setTestId = (rootDiv: HTMLDivElement | null) => {
    if (rootDiv) {
      rootDiv.setAttribute('data-testid', 'Sec_ECP_Modal')
    }
  }

  return (
    <div>
      <StyledDialog
        fullWidth
        role={'dialog'}
        data-testid="ecp_top_modal"
        aria-describedby="ecp_top_modal_title"
        maxWidth={'sm'}
        sx={{
          overflowY: 'auto',
          zIndex: 3001, // should have highest Zindex as it overlays other items in site including hamburger menu
          // paper: { width: '95%', minWidth: '17.5rem' },
        }}
        onClose={closeHandler}
        open={isOpen}
        ref={setTestId}
        {...dialogCustomProps}>
        <BootstrapDialogTitle onClose={closeHandler}>
          <Box>
            {hasRsmLogo && (
              <Box data-testid="Img_Modal_RsmLogo" className="RsmModal-rsmLogo">
                <RsmLogo />
              </Box>
            )}
            {title && (
              <Heading
                variant="h1WithH2Styling"
                id="ecp_top_modal_title"
                data-testId="Hed_Modal_Title">
                {title}
              </Heading>
            )}
          </Box>
          <Box sx={{ marginTop: '.5rem' }}>
            {subTitle && (
              <Typography sx={{ lineHeight: '24px' }} variant="subtitle1">
                {subTitle}
              </Typography>
            )}
          </Box>
        </BootstrapDialogTitle>
        <DialogContent>{children}</DialogContent>
      </StyledDialog>
    </div>
  )
}

export default RsmDialog
