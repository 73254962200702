import { AnalyticEvent } from './adobe/types'
import processAnalytics from './processAnalytics'

function analyticsClickTracker(event: MouseEvent) {
  window.adobeDataLayer = window.adobeDataLayer || []

  let analyticsId = ''
  let analyticsEvent: AnalyticEvent = 'Link_Click'
  let useParentAnalyticEvent = false

  // Take data-analytics-id, but if there isn't one take the data-testid
  const target = event.target as HTMLElement
  if (target?.getAttribute('data-analytic-id') !== null) {
    analyticsId = target.getAttribute('data-analytic-id') ?? ''
  } else if (target?.getAttribute('data-testid') !== null) {
    analyticsId = target.getAttribute('data-testid') ?? ''
    // if there isn't a data-testid, check the parent for a data-analytic-id
  } else if (target?.parentElement?.getAttribute('data-analytic-id') !== null) {
    analyticsId = target.parentElement?.getAttribute('data-analytic-id') ?? ''
    useParentAnalyticEvent = true

    // and if there isn't a data-testid, but there is one on the parent, use that instead
  } else if (target?.parentElement?.getAttribute('data-testid') !== null) {
    analyticsId = target.parentElement?.getAttribute('data-testid') ?? ''
    useParentAnalyticEvent = true
  }

  if (
    !useParentAnalyticEvent &&
    target?.getAttribute('data-analytic-event') !== null
  ) {
    analyticsEvent =
      <AnalyticEvent>target.getAttribute('data-analytic-event') ??
      analyticsEvent
  } else if (
    useParentAnalyticEvent &&
    target?.parentElement?.getAttribute('data-analytic-event') !== null
  ) {
    analyticsEvent =
      <AnalyticEvent>(
        target.parentElement?.getAttribute('data-analytic-event')
      ) ?? analyticsEvent
  }

  if (analyticsId !== '') {
    processAnalytics({
      event: analyticsEvent,
      event_data_id: analyticsId,
    })
  }
}

export default analyticsClickTracker
