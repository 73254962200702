import React from 'react'

const ShoppingBagsIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3906 40.3098V13.0098H71.2106V75.1098H49.9506"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.8711 13.0098V75.1098"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.8711 75.1084L64.5411 66.8984L71.2011 75.1084H57.8711Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M71.2011 13.0098L64.5411 21.2298L57.8711 13.0098H71.2011Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M64.541 21.2285V66.8985"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M47.0802 17.1192C47.0802 18.0392 46.3302 18.7892 45.4102 18.7892C44.4902 18.7892 43.7402 18.0392 43.7402 17.1192C43.7402 16.1992 44.4902 15.4492 45.4102 15.4492C46.3302 15.4492 47.0802 16.1992 47.0802 17.1192V17.1192Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M30.7599 17.1192C30.7599 18.0392 30.0099 18.7892 29.0899 18.7892C28.1699 18.7892 27.4199 18.0392 27.4199 17.1192C27.4199 16.1992 28.1699 15.4492 29.0899 15.4492C30.0099 15.4492 30.7599 16.1992 30.7599 17.1192V17.1192Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M45.4098 15.4491V10.6991C45.4098 6.18906 41.7598 2.53906 37.2498 2.53906C32.7398 2.53906 29.0898 6.18906 29.0898 10.6991V15.4491"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M63.4001 13.0091V10.6991C63.4001 6.18906 59.7501 2.53906 55.2401 2.53906C50.7301 2.53906 47.0801 6.18906 47.0801 10.6991V13.0091"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.3508 79.0005H11.5508L15.0608 40.3105H42.3508V79.0005Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.3516 79.0005H50.3516L46.3516 40.3105H42.3516"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.1317 40.2206V32.7006C34.1317 30.0406 31.9817 27.8906 29.3217 27.8906C26.6617 27.8906 24.5117 30.0406 24.5117 32.7006V40.2206"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M37.8915 40.2206V32.7006C37.8915 30.0406 35.7415 27.8906 33.0815 27.8906C30.4215 27.8906 28.2715 30.0406 28.2715 32.7006V40.2206"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default ShoppingBagsIcon
