import React, { memo } from 'react'

const PackmanPieIcon = () => (
  <svg
    width="154"
    height="155"
    viewBox="0 0 154 155"
    className="packman-pie-icon"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>packman pie icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.3786 76.7056L147.516 107.012C162.555 68.043 145.897 21.6158 106.935 6.59253C67.0612 -8.78002 22.2701 8.43406 6.68055 47.2438C-8.91423 86.0694 8.57618 131.216 47.1874 147.856C66.4417 156.153 87.2435 155.719 105.293 148.523L76.3786 76.7056Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.86 152.972C133.724 146.715 145.571 136.011 152.966 121.8C153.048 121.642 152.976 121.443 152.809 121.373L97 98L119.443 152.807C119.507 152.969 119.698 153.045 119.86 152.972Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
  </svg>
)

export default memo(PackmanPieIcon)
