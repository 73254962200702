import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface SpotlightArticleState {
  hasPopBgColor: boolean
  hasPopBgImg: boolean
  popBgColor: string
  popBGImg: string | null
}

const initialState: SpotlightArticleState = {
  hasPopBgColor: false,
  hasPopBgImg: false,
  popBgColor: '#fff',
  popBGImg: null,
}

export const spoltighArticleSlice = createSlice({
  name: 'spoltighArticle',
  initialState,
  reducers: {
    setHasPopBgColor: (state, { payload }) => {
      state.hasPopBgColor = payload
    },
    setHasPopBgImg: (state, { payload }) => {
      state.hasPopBgImg = payload
    },
    setPopBgColor: (state, { payload }) => {
      state.popBgColor = payload
    },
    setPopBgImg: (state, { payload }) => {
      state.popBGImg = payload
    },
  },
})

export const { setHasPopBgColor } = spoltighArticleSlice.actions
export const getHasPopBgColor = (state: RootState) =>
  state.spoltighArticle.hasPopBgColor

export const { setHasPopBgImg } = spoltighArticleSlice.actions
export const getHasPopImg = (state: RootState) =>
  state.spoltighArticle.hasPopBgImg

export const { setPopBgColor } = spoltighArticleSlice.actions
export const getPopBgColor = (state: RootState) =>
  state.spoltighArticle.popBgColor

export const { setPopBgImg } = spoltighArticleSlice.actions
export const getPopBgImg = (state: RootState) => state.spoltighArticle.popBGImg

export default spoltighArticleSlice.reducer
