import React from 'react'

const NewsIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M78.1491 61.4902V71.2002C78.1491 74.9602 75.0891 78.0102 71.3291 78.0002L9.03906 77.8602"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M7.0407 25.0905C3.3707 25.0905 1.4707 27.0005 1.4707 30.6605V70.3105C1.4707 74.4805 4.8507 77.8605 9.0207 77.8605C12.2107 77.8605 14.8007 75.2705 14.8007 72.0805V9.78047C14.8007 6.02047 17.8507 2.98047 21.6007 2.98047H71.3507C75.1107 2.98047 78.1507 6.03047 78.1507 9.78047V63.0005"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M11.3499 24.0801H9.72992C6.93992 24.0801 4.66992 26.9101 4.66992 30.4101V68.1901C4.66992 72.1801 6.26992 74.2201 9.34992 75.0901"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M5.53906 76.9493C8.72906 76.9493 11.3191 74.4493 11.3191 71.3693V11.1593C11.3191 7.5293 14.3691 3.7793 18.1191 3.7793"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M70.2498 30.8398H60.5898V48.9998H70.2498V30.8398Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21.8809 30.8398H35.7909"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M40.2207 30.8398H54.5707"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21.8301 42.6094H35.7401"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M40.1699 42.6094H54.5199"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21.8301 53.5996H35.7401"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M40.1699 53.5996H54.5199"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M21.8301 65.0293H35.7401"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M40.1699 65.0293H54.5199"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M70.1307 54.8594H60.4707V64.9994H70.1307V54.8594Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M23.7402 21.4591V8.86914L31.9802 21.4591V9.05914"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M42.7802 9.06055H35.7402V21.4605H42.8702"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.9302 15.1602H35.7402"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.4297 9.05914L49.0297 21.6391L52.5397 9.05914L56.2297 21.4591L59.7397 8.86914"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.5 11.1095C68.72 8.29945 64.28 8.29945 63.39 11.1095C61.61 16.7295 72.27 13.3495 70.5 19.2495C68.72 24.2195 62.5 21.0895 62.5 20.3095"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default NewsIcon
