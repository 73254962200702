import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

export interface FaqItemModel {
  question: string
  answer: string
}

export interface FaqTopicModel {
  topic: string
  items: FaqItemModel[]
}

// Define a type for the slice state
interface FaqsState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  faqData: FaqTopicModel[]
  expandAll: boolean
  hasFocus?: string
}

// Define the initial state using that type
const initialState: FaqsState = {
  faqData: [],
  expandAll: false,
}

export const faqsSlice = createSlice({
  name: 'faqs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setfaqData: (state, { payload }) => {
      state.faqData = payload
    },
    setExpandAll: (state, { payload }) => {
      state.expandAll = payload
    },
  },
})

export const { setfaqData, setExpandAll } = faqsSlice.actions
export const getFaqs = (state: RootState) => state.faqs.faqData
export const getExpandAll = (state: RootState) => state.faqs.expandAll

export default faqsSlice.reducer
