import React from 'react'
import { AppBar, Box, Toolbar } from '@mui/material'
import { Styles } from '../../../types'
import ToolBarHeaderName from '../ToolBarHeaderName'
import ToolBarHighContrast from '../ToolBarHighContrast'
import ErrorBoundary from '../../ErrorBoundary'

const styles: Styles = {
  toolbar: (theme) => ({
    alignItems: 'start',
    margin: '0 auto',
    width: '100%',
    minHeight: '2.5rem !important',
    height: '2.5rem !important',
    lineHeight: 2.5,
    [theme.breakpoints.down('tablet')]: {
      justifyContent: 'space-between',
      paddingX: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingX: '2rem',
    },
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingX: '6.5rem',
    },
  }),
}

const WelcomeBar = () => (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static">
      <Toolbar sx={styles.toolbar}>
        <ErrorBoundary noErrorMessage>
          <ToolBarHighContrast />
        </ErrorBoundary>
        <ErrorBoundary noErrorMessage>
          <ToolBarHeaderName />
        </ErrorBoundary>
      </Toolbar>
    </AppBar>
  </Box>
)

export default WelcomeBar
