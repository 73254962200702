import React, { Dispatch } from 'react'
import { Actions } from './actions'

export interface FileDownloadQueue {
  [key: string]: string | undefined
}
export interface EngagementListState {
  downloadQueue?: FileDownloadQueue
  isEmployee?: boolean
}

export const EngagementsContext = React.createContext<{
  state: EngagementListState
  dispatch: Dispatch<Actions>
}>({
  state: {},
  dispatch: () => {},
})
