import * as React from 'react'
import { SVGProps } from 'react'

const CreditMastercardIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_2_00000126317611443760284100000002902434598180924303_"
    data-testid="credit-mc-icon"
    x="0px"
    y="0px"
    viewBox="0 0 285.4 185.8"
    enableBackground="new 0 0 285.4 185.8"
    xmlSpace="preserve"
    width={width}
    height={height}>
    <title>Mastercard</title>
    <g id="Mastercard">
      <g>
        <path
          fill="#231F20"
          stroke="#231F20"
          strokeWidth="2.14"
          strokeMiterlimit="10"
          d="M22.3,1.1h240.9c11.7,0,21.2,9.5,21.2,21.2
			v141.3c0,11.7-9.5,21.2-21.2,21.2H22.3c-11.7,0-21.2-9.5-21.2-21.2V22.3C1.1,10.6,10.6,1.1,22.3,1.1z"
        />
        <g>
          <path
            fill="none"
            d="M145.5,141.3c-1.9,0-3.1,1.2-3.3,3.1h6.5C148.4,142.4,147.2,141.3,145.5,141.3L145.5,141.3z"
          />
          <path
            fill="none"
            d="M182.3,141.4c-2.4,0-3.8,1.8-3.8,4s1.4,4,3.8,4s3.8-1.7,3.8-4S184.6,141.4,182.3,141.4z"
          />
          <path
            fill="none"
            d="M207.7,141.4c-2.4,0-3.8,1.8-3.8,4s1.4,4,3.8,4s3.8-1.7,3.8-4S209.9,141.4,207.7,141.4z"
          />
          <path
            fill="none"
            d="M105.2,141.4c-2.4,0-3.8,1.8-3.8,4s1.4,4,3.8,4s3.8-1.7,3.8-4S107.5,141.4,105.2,141.4z"
          />
          <path
            fill="#FFFFFF"
            d="M157,140.7v-1.5h-2.7v12.6h2.7v-7c0-2.1,0.9-3.2,2.7-3.2c0.6,0,1.1,0.1,1.7,0.3l0.8-2.6
				c-0.6-0.2-1.4-0.3-1.9-0.3C158.7,138.9,157.7,139.6,157,140.7L157,140.7z"
          />
          <path
            fill="#FFFFFF"
            d="M134.4,149.5c-1.4,0-1.8-0.8-1.8-2.1v-5.7h4.5v-2.5h-4.5v-3.8h-2.8v3.8h-2.6v2.5h2.6v5.7
				c0,2.9,1.1,4.6,4.4,4.6c1.2,0,2.6-0.4,3.4-1l-0.8-2.3C136,149.2,135.1,149.5,134.4,149.5L134.4,149.5z"
          />
          <path
            fill="#FFFFFF"
            d="M145.5,138.9c-3.7,0-6.2,2.7-6.2,6.6s2.7,6.6,6.4,6.6c1.9,0,3.6-0.5,5.1-1.8l-1.3-2
				c-1.1,0.8-2.4,1.3-3.6,1.3c-1.8,0-3.4-0.8-3.8-3.1h9.3c0-0.3,0.1-0.7,0.1-1.1C151.5,141.5,149.1,138.9,145.5,138.9L145.5,138.9z
				 M142.1,144.4c0.3-1.8,1.4-3.1,3.3-3.1c1.8,0,2.9,1.1,3.2,3.1H142.1L142.1,144.4z"
          />
          <path
            fill="#FFFFFF"
            d="M108.8,140.7c-0.9-1.1-2.2-1.8-4-1.8c-3.5,0-6.3,2.8-6.3,6.6s2.8,6.6,6.3,6.6c1.8,0,3.1-0.7,4-1.8v1.5
				h2.7v-12.6h-2.7V140.7L108.8,140.7z M105.2,149.5c-2.4,0-3.8-1.8-3.8-4s1.4-4,3.8-4s3.8,1.7,3.8,4S107.5,149.5,105.2,149.5z"
          />
          <path
            fill="#FFFFFF"
            d="M91.2,138.9c-1.6,0-3.3,0.5-4.4,2.2c-0.9-1.4-2.2-2.2-4.2-2.2c-1.3,0-2.7,0.4-3.7,1.9v-1.6h-2.7v12.6H79
				v-7c0-2.2,1.2-3.3,3.1-3.3s2.7,1.2,2.7,3.3v7h2.8v-7c0-2.2,1.3-3.3,3.1-3.3s2.8,1.2,2.8,3.3v7h2.8v-7.9
				C96.2,140.9,94.3,138.9,91.2,138.9L91.2,138.9z"
          />
          <path
            fill="#FFFFFF"
            d="M121,144.2l-1.3-0.2c-1.2-0.2-2.1-0.4-2.1-1.2c0-0.9,0.9-1.5,2.4-1.5c1.6,0,3.2,0.6,3.9,1.1l1.2-2.2
				c-1.3-0.9-3.1-1.3-5.1-1.3c-3.2,0-5.2,1.5-5.2,4c0,2.1,1.5,3.3,4.3,3.7l1.3,0.2c1.5,0.2,2.2,0.6,2.2,1.3c0,1-1,1.5-2.9,1.5
				s-3.3-0.6-4.2-1.3l-1.3,2.1c1.5,1.1,3.4,1.6,5.4,1.6c3.6,0,5.7-1.7,5.7-4.1C125.4,145.8,123.7,144.6,121,144.2L121,144.2z"
          />
          <path
            fill="#FFFFFF"
            d="M195,140.7v-1.5h-2.7v12.6h2.7v-7c0-2.1,0.9-3.2,2.7-3.2c0.6,0,1.1,0.1,1.7,0.3l0.8-2.6
				c-0.6-0.2-1.4-0.3-1.9-0.3C196.7,138.9,195.7,139.6,195,140.7L195,140.7z"
          />
          <path
            fill="#FFFFFF"
            d="M211.3,140.7c-0.9-1.1-2.2-1.8-4-1.8c-3.5,0-6.3,2.8-6.3,6.6s2.8,6.6,6.3,6.6c1.8,0,3.1-0.7,4-1.8v1.5
				h2.7v-17.6h-2.7V140.7L211.3,140.7z M207.7,149.5c-2.4,0-3.8-1.8-3.8-4s1.4-4,3.8-4s3.8,1.7,3.8,4S209.9,149.5,207.7,149.5z"
          />
          <path
            fill="#FFFFFF"
            d="M169.7,141.4c1.2,0,2.3,0.4,3.3,1.1l1.3-2.2c-1.4-1.1-2.6-1.5-4.5-1.5c-4.1,0-6.7,2.8-6.7,6.6
				s2.6,6.6,6.7,6.6c1.9,0,3.1-0.4,4.5-1.5l-1.3-2.2c-1,0.7-2.1,1.1-3.3,1.1c-2.2,0-3.8-1.6-3.8-4S167.5,141.4,169.7,141.4
				L169.7,141.4z"
          />
          <path
            fill="#FFFFFF"
            d="M185.9,140.7c-0.9-1.1-2.2-1.8-4-1.8c-3.5,0-6.3,2.8-6.3,6.6s2.8,6.6,6.3,6.6c1.8,0,3.1-0.7,4-1.8v1.5
				h2.7v-12.6h-2.7V140.7L185.9,140.7z M182.3,149.5c-2.4,0-3.8-1.8-3.8-4s1.4-4,3.8-4s3.8,1.7,3.8,4S184.6,149.5,182.3,149.5z"
          />
          <path
            fill="#FF5F00"
            d="M126.8,80.7c0,15.1,7.1,28.5,18.1,37.2c11-8.7,18.1-22.1,18.1-37.2s-7.1-28.5-18.1-37.2
				C133.9,52.1,126.8,65.6,126.8,80.7z"
          />
          <path
            fill="#EB001B"
            d="M126.8,80.7c0-15.1,7.1-28.5,18.1-37.2c-8.1-6.3-18.2-10.1-29.2-10.1c-26.1,0-47.3,21.2-47.3,47.3
				S89.5,128,115.6,128c11,0,21.2-3.8,29.2-10.1C133.9,109.2,126.8,95.8,126.8,80.7z"
          />
          <path
            fill="#F79E1B"
            d="M174.1,33.4c-11,0-21.2,3.8-29.2,10.1c11,8.7,18.1,22.1,18.1,37.2s-7.1,28.5-18.1,37.2
				c8.1,6.3,18.2,10.1,29.2,10.1c26.1,0,47.3-21.2,47.3-47.3S200.2,33.4,174.1,33.4z"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CreditMastercardIcon
