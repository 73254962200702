import React from 'react'

const PostItsIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_132032)">
      <path
        d="M39.2501 29H58.4601V77H15.7101L3.33008 64.85V29H13.1101"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15.7101 77.0007L15.5001 65.2207H3.33008"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.8398 21.5806L64.4698 14.6406L78.0698 59.9006L58.4598 68.1806"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.62 15.08C32.86 13.06 37.81 13.41 40.3 16.45C43.93 20.88 40.92 29.35 33.58 35.36C26.24 41.38 17.34 42.67 13.71 38.24C11.55 35.6 11.74 31.53 13.81 27.39"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.5799 17.1733C21.4102 13.2128 23.3898 7.641 21.0016 4.72835C18.6133 1.8157 12.7616 2.66517 7.93143 6.6257C3.10121 10.5862 1.12158 16.158 3.50981 19.0707C5.89803 21.9833 11.7497 21.1339 16.5799 17.1733Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.9905 33.8301C34.9905 33.8301 38.8505 38.0901 42.0805 41.7801C44.3505 44.1501 41.0005 47.5401 38.4305 44.3001C35.5005 40.6101 31.8105 36.7801 31.8105 36.7801"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.81055 19.3805C3.81055 19.3805 19.4305 30.6305 21.7105 32.3405C24.6305 34.8105 35.1505 27.8405 31.9605 22.6405C28.0005 16.1605 21.0205 4.73047 21.0205 4.73047"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_132032">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PostItsIcon
