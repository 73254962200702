/* eslint-disable */
import React from 'react'
import { Box, IconButton, styled, Tooltip as MUIToolTip } from '@mui/material'

export type TooltipProps = {
  Icon: any
  IconAriaLabel?: string
  width?: string

  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  text: string
}

const StyledIconButton = styled(IconButton)({
  '&.Mui-focusVisible': {
    border: '1px solid black',
  },
})

const Tooltip = ({
  Icon,
  IconAriaLabel = '',
  placement = 'right-start',
  text = '',
  width = '',
}: TooltipProps) => {
  return (
    <div className="pl-2 inline">
      <MUIToolTip
        placement={placement}
        describeChild
        title={
          <Box
            className="flex flex-col items-center justify-center"
            sx={{
              width: width,
              minWidth: width,
            }}>
            <div className="pt-5">
              <Icon />
            </div>
            <div className="font-prelo-light text-base p-5">{text}</div>
          </Box>
        }>
        <StyledIconButton size="small" disableRipple aria-label={IconAriaLabel}>
          <Icon />
        </StyledIconButton>
      </MUIToolTip>
    </div>
  )
}

export default Tooltip
