import React from 'react'

const TaxDocumentIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M63.7806 67.4194V77.9994H13.1706L6.14062 70.1394V7.60938H63.7806V60.2294"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.5309 55.79V3H16.7109V7.13"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.7812 72.2702H68.5312V62.4102"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4492 38.5293H53.6192"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5195 42.7695H37.0495"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4492 48.8398H53.6192"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5195 53.0703H37.0495"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4492 59.1406H53.6192"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5195 63.3711H37.0495"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1706 77.4605V69.9805H6.14062L13.1706 77.8705"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1298 37.4805H10.7598V43.8505H17.1298V37.4805Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1298 47.7695H10.7598V54.1395H17.1298V47.7695Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1298 58.0508H10.7598V64.4208H17.1298V58.0508Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.5801 70.6595L59.1601 64.9395L62.5001 68.3094L56.5801 70.6595Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.5502 52.1387L59.1602 64.9387L62.5002 68.3087L75.7702 55.3087L72.5502 52.1387Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.5908 50.0893L72.5508 52.1293L75.7708 55.2993L77.9408 52.8993C77.9408 52.8993 78.7308 51.9893 77.2508 50.6093C75.7708 49.2293 74.5908 50.0793 74.5908 50.0793V50.0893Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.0293 70.6602H39.2793"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M42.0488 70.6602H51.7388"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M53.1191 70.6602H54.3691"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M32.7207 67.8008L35.8207 70.9008"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.8207 67.8008L32.7207 70.9008"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8906 29.8796V15.8496"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5402 15.5898H16.2402"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.8398 29.88L32.6798 15L37.5798 29.88"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.5391 25.3594H29.3691"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.7109 15.0801L51.9909 30.5001"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9909 15.0801L41.7109 30.5001"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TaxDocumentIcon
