/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  Grid,
  GridProps,
} from '@mui/material'
import { GridSpacing } from '@mui/system'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Styles } from '../../../../types'
import {
  FormDefinition,
  FormExclusions,
  FormField,
  FormValues,
} from '../models'
import useValidationFragments from '../validations'
import DynamicFormLabel from './DynamicFormLabel/DynamicFormLabel'

const styles: Styles = {
  formControl: {
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}

interface DynamicFormProps {
  containerLayout?: Exclude<GridProps, GridSpacing>
  definition: FormDefinition
  exclusions?: FormExclusions
  footerComponent?: React.ReactNode
  formRef?: React.Ref<FormikProps<FormValues>>
  handleSubmit: (values: FormValues) => void
  initialValues?: FormValues
  isViewOnlyMode?: boolean
  rootAriaLabel?: string
  rootTestId?: string
  useMobileSpacing?: boolean
}

const DynamicForm = ({
  containerLayout = {},
  definition,
  exclusions = {},
  footerComponent = undefined,
  formRef = undefined,
  handleSubmit,
  initialValues = {},
  isViewOnlyMode = false,
  rootAriaLabel = undefined,
  rootTestId = undefined,
  useMobileSpacing = false,
}: DynamicFormProps) => {
  const { t } = useTranslation()
  const { buildValidationSchema } = useValidationFragments()

  const dynamicFormFieldComponent = (name: string, field: FormField) => {
    const { ariaLabel, component, readonly, ...rest } = field

    if (!component) return null

    const componentProps = {
      ...rest,
      name,
      id: `${name}-field`,
      'aria-label': t(ariaLabel || ''),
      readonly: readonly || isViewOnlyMode,
    }

    const Component = component as any
    return <Component {...componentProps} />
  }

  const dynamicFormFooterComponent = () =>
    footerComponent || (
      <Button
        type="submit"
        sx={styles.submitButton}
        color="primary"
        variant="contained"
        aria-label={t('DocReq.NewForm.AriaLabel.Submit')}
        tabIndex={0}>
        {t('Form.Submit')}
      </Button>
    )

  return (
    <Box aria-label={rootAriaLabel} data-testid={rootTestId}>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={initialValues ?? {}}
        validationSchema={buildValidationSchema(definition)}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
        {...(formRef && { innerRef: formRef })}>
        {(formik: FormikProps<FormValues>) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Grid
                spacing={
                  useMobileSpacing
                    ? { xs: '0.5rem', sm: '1rem', md: '1rem', lg: '1rem' }
                    : { xs: '0.5rem', sm: '1rem', md: '2rem', lg: '3rem' }
                }
                {...containerLayout}>
                {Object.keys(definition).map((name) => {
                  const { ariaLabel, itemLayout, label, required } =
                    definition[name]

                  const isExcluded =
                    exclusions !== undefined ? exclusions[name] : false

                  const { showComponent } = definition[name]

                  const shouldRenderField = !(
                    (isViewOnlyMode && !formik.values[name]) ||
                    isExcluded ||
                    showComponent === false
                  )

                  return (
                    shouldRenderField && (
                      <Grid {...itemLayout} key={`${name}-grid`}>
                        <FormControl key={`${name}-fc`} sx={styles.formControl}>
                          {label && (
                            <DynamicFormLabel
                              aria-label={t(ariaLabel || label)}
                              id={`${name}-label`}
                              htmlFor={`${name}-field`}>
                              {t(label)}{' '}
                              {required && !isViewOnlyMode ? '*' : ''}
                            </DynamicFormLabel>
                          )}
                          {dynamicFormFieldComponent(name, definition[name])}
                        </FormControl>
                      </Grid>
                    )
                  )
                })}
              </Grid>
            </FormGroup>

            <Box sx={styles.footer}>
              {!exclusions?.footer && dynamicFormFooterComponent()}
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default DynamicForm
