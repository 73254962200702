import { t } from 'i18next'
import { RSMTableHeadData } from '../../../rsmCoreComponents/components/table/RSMTableBody'
import {
  EngagementView,
  EngagementViewTable,
} from '../Interfaces/EngagementViewTypes'

export const filterEngagements = (
  engagementList: EngagementViewTable[],
  filter?: string,
) => {
  const engagements = [...engagementList]
  const returnEngagements: EngagementViewTable[] = []

  // Filter by filter string starting at 2 characters
  if (filter && filter.length >= 2) {
    engagements.forEach((eng) => {
      if (
        eng.name?.toLowerCase().includes(filter.toLowerCase()) ||
        eng.scheduledStartDateView
          ?.toLowerCase()
          .includes(filter.toLowerCase()) ||
        eng.scheduledEndDateView
          ?.toLowerCase()
          .includes(filter.toLowerCase()) ||
        eng.mdmClientName?.toLowerCase().includes(filter.toLowerCase()) ||
        eng.lineOfBusiness?.toLowerCase().includes(filter.toLowerCase()) ||
        eng.type?.toLowerCase().includes(filter.toLowerCase())
      ) {
        returnEngagements.push(eng)
      }
    })
    return returnEngagements
  }

  return engagements
}

export const structureBodyData = (
  engagements: EngagementView[],
  isMobile: boolean,
  isTablet: boolean,
  isEmployee: boolean,
) => {
  const bdataArray: EngagementViewTable[] = []
  engagements.forEach((item) => {
    if (isMobile) {
      const bdata: EngagementViewTable = {
        name: item.name,
        lineOfBusiness: item.lineOfBusiness,
        id: item.id,
      }
      bdataArray.push(bdata)
    }
    if (isTablet) {
      if (isEmployee) {
        const bdata: EngagementViewTable = {
          name: item.name,
          mdmClientName: item.clientName,
          lineOfBusiness: item.lineOfBusiness,
          id: item.id,
        }
        bdataArray.push(bdata)
      } else {
        const bdata: EngagementViewTable = {
          name: item.name,
          scheduledEndDateView: item.scheduledEndDateView,
          scheduledEndDate: item.scheduledEndDate,
          lineOfBusiness: item.lineOfBusiness,
          id: item.id,
        }
        bdataArray.push(bdata)
      }
    }
    if (!isMobile && !isTablet) {
      if (isEmployee) {
        const bdata: EngagementViewTable = {
          name: item.name,
          scheduledStartDateView: item.scheduledStartDateView,
          scheduledStartDate: item.scheduledStartDate,
          scheduledEndDateView: item.scheduledEndDateView,
          scheduledEndDate: item.scheduledEndDate,
          mdmClientName: item.clientName,
          lineOfBusiness: item.lineOfBusiness,
          type: item.type,
          id: item.id,
        }
        bdataArray.push(bdata)
      } else {
        const bdata: EngagementViewTable = {
          name: item.name,
          scheduledStartDateView: item.scheduledStartDateView,
          scheduledStartDate: item.scheduledStartDate,
          scheduledEndDateView: item.scheduledEndDateView,
          scheduledEndDate: item.scheduledEndDate,
          lineOfBusiness: item.lineOfBusiness,
          type: item.type,
          id: item.id,
        }
        bdataArray.push(bdata)
      }
    }
  })
  return bdataArray
}

export const structureHeaderData = (
  isMobile: boolean,
  isTablet: boolean,
  isEmployee: boolean,
  showEngagementSortHoverName: boolean,
  showEngagementSortHoverStart: boolean,
  showEngagementSortHoverEnd: boolean,
  showEngagementSortHoverClient: boolean,
  showEngagementSortHoverLineOfBusiness: boolean,
  showEngagementSortHoverType: boolean,
  setShowEngagementSortHoverName: React.Dispatch<React.SetStateAction<boolean>>,
  setShowEngagementSortHoverStart: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverEnd: React.Dispatch<React.SetStateAction<boolean>>,
  setShowEngagementSortHoverClient: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverLineOfBusiness: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setShowEngagementSortHoverType: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const dataArray: RSMTableHeadData[] = []

  interface DeviceWidths {
    mobile?: string
    tablet?: string
    desktop?: string
  }

  interface UserType {
    employee?: DeviceWidths
    client?: DeviceWidths
  }

  interface ColumnWidths {
    engagementName: UserType
    startDate: UserType
    endDate: UserType
    client: UserType
    lineOfBusiness: UserType
    type: UserType
  }

  const columnWidths: ColumnWidths = {
    engagementName: {
      employee: { mobile: '70%', tablet: '40%', desktop: '30%' },
      client: { mobile: '70%', tablet: '55%', desktop: '40%' },
    },
    startDate: {
      employee: { desktop: '10%' },
      client: { desktop: '10%' },
    },
    endDate: {
      employee: { desktop: '10%' },
      client: { desktop: '10%', tablet: '20%' },
    },
    client: {
      employee: { tablet: '35%', desktop: '20%' },
    },
    lineOfBusiness: {
      employee: { mobile: '30%', tablet: '25%', desktop: '15%' },
      client: { mobile: '30%', tablet: '25%', desktop: '20%' },
    },
    type: {
      employee: { desktop: '15%' },
      client: { desktop: '20%' },
    },
  }

  let userTypeKey: keyof UserType = 'client'
  if (isEmployee) userTypeKey = 'employee'

  let deviceKey: keyof DeviceWidths = 'desktop'
  if (isMobile) deviceKey = 'mobile'
  if (isTablet) deviceKey = 'tablet'

  const engagementName: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title: t('Engagement.Name'),
    key: 'name',
    hover: showEngagementSortHoverName,
    setHover: setShowEngagementSortHoverName,
    styles: { width: columnWidths.engagementName?.[userTypeKey]?.[deviceKey] },
  }
  dataArray.push(engagementName)

  if (!isMobile && !isTablet) {
    const startDate: RSMTableHeadData = {
      isSortable: true,
      shouldDisplay: true,
      title: t('Engagement.StartDate'),
      key: 'scheduledStartDate',
      hover: showEngagementSortHoverStart,
      setHover: setShowEngagementSortHoverStart,
      styles: { width: columnWidths.startDate?.[userTypeKey]?.[deviceKey] },
    }
    dataArray.push(startDate)
  }

  if ((isTablet && !isEmployee) || (!isMobile && !isTablet)) {
    const endDate: RSMTableHeadData = {
      isSortable: true,
      shouldDisplay: true,
      title: t('Engagement.EndDate'),
      key: 'scheduledEndDate',
      hover: showEngagementSortHoverEnd,
      setHover: setShowEngagementSortHoverEnd,
      styles: { width: columnWidths.endDate?.[userTypeKey]?.[deviceKey] },
    }
    dataArray.push(endDate)
  }

  if (!isMobile) {
    if (isEmployee) {
      const clientName: RSMTableHeadData = {
        isSortable: true,
        shouldDisplay: true,
        title: t('Engagement.Client'),
        key: 'mdmClientName',
        hover: showEngagementSortHoverClient,
        setHover: setShowEngagementSortHoverClient,
        styles: { width: columnWidths.client?.[userTypeKey]?.[deviceKey] },
      }
      dataArray.push(clientName)
    }
  }

  const lineOfBusiness: RSMTableHeadData = {
    isSortable: true,
    shouldDisplay: true,
    title:
      isMobile || isTablet
        ? t('Engagement.LOB')
        : t('Engagement.LineOfBusiness'),
    key: 'lineOfBusiness',
    hover: showEngagementSortHoverLineOfBusiness,
    setHover: setShowEngagementSortHoverLineOfBusiness,
    styles: { width: columnWidths.lineOfBusiness?.[userTypeKey]?.[deviceKey] },
  }
  dataArray.push(lineOfBusiness)

  if (!isMobile && !isTablet) {
    const typeHeader: RSMTableHeadData = {
      isSortable: true,
      shouldDisplay: true,
      title: t('Engagement.Type'),
      key: 'type',
      hover: showEngagementSortHoverType,
      setHover: setShowEngagementSortHoverType,
      styles: { width: columnWidths.type?.[userTypeKey]?.[deviceKey] },
    }
    dataArray.push(typeHeader)
  }

  if (!isMobile) {
    const buttonHeader: RSMTableHeadData = {
      isSortable: false,
      shouldDisplay: true,
      title: '',
      key: 'button',
      styles: { width: '13.75rem' },
    }
    dataArray.push(buttonHeader)
  }

  return dataArray
}
