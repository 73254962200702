import React from 'react'

const AvatarMaleIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.0099 33.55C31.9999 32.8 32.5899 32.18 33.3399 32.17C34.0899 32.16 34.6999 32.76 34.7199 33.5C34.7399 34.25 34.1399 34.87 33.3899 34.88C32.6499 34.89 32.0299 34.3 32.0099 33.55Z"
      fill="#009CDE"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M43.7501 33.3313C43.7401 32.5813 44.3401 31.9713 45.0801 31.9513C45.8201 31.9413 46.4501 32.5313 46.4601 33.2813C46.4701 34.0313 45.8801 34.6413 45.1301 34.6613C44.3801 34.6713 43.7601 34.0813 43.7501 33.3313V33.3313Z"
      fill="#009CDE"
      stroke="#009CDE"
      strokeMiterlimit="10"
    />
    <path
      d="M38.6118 28.7402L37.8418 40.3002L42.6318 40.3902"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.2617 71.8606V56.6406"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.8309 47.25C13.2909 47.55 13.5609 62.2 13.5609 62.2V63.57"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.7207 70.461V56.041"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.5302 60.081C69.5302 60.081 69.3402 47.251 53.8002 46.791L51.4902 46.841"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M24.2316 37.8609C24.2316 37.8609 20.7016 28.1809 25.9816 19.0709C28.5516 14.6009 33.3316 11.5309 38.8316 11.4309H39.5216C47.8516 11.2609 54.7916 17.9509 54.9516 26.2709L55.1316 36.0109C55.1416 36.8509 55.0916 37.6809 54.9616 38.5009C54.9616 38.5009 54.7316 43.3409 51.8916 46.4009C49.1816 49.4109 44.6016 51.3609 40.2616 51.4409H39.5716C32.0816 51.5909 25.0416 46.0209 24.2316 37.8609"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.6914 75.0008V72.6308L49.9414 48.3008"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41.6918 72.6315L31.0918 49.0215"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M24.35 39.6518C22.78 39.9118 21.26 38.7118 20.97 36.9718C20.68 35.2218 21.73 33.6018 23.31 33.3418"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M54.4004 39.6518C55.9804 39.9118 57.4904 38.7118 57.7804 36.9718C58.0704 35.2218 57.0204 33.6018 55.4404 33.3418"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M33.3418 54.041H47.8918"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M43.3216 17.6005C42.2916 17.4005 41.2216 17.3105 40.1116 17.3105C32.9616 17.3105 27.1616 21.6105 27.1616 26.9105C27.1616 26.9105 27.1316 33.6105 23.3516 34.0905"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M53.0617 26.9102C53.0617 23.1702 50.1817 19.9302 45.9817 18.3502C45.5017 18.1602 46.2817 12.8002 45.7617 12.6602"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.0801 75C60.2385 75 76.5801 58.6584 76.5801 38.5C76.5801 18.3416 60.2385 2 40.0801 2C19.9217 2 3.58008 18.3416 3.58008 38.5C3.58008 58.6584 19.9217 75 40.0801 75Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default AvatarMaleIcon
