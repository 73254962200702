/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { Box, BoxProps } from '@mui/material'
import React from 'react'
import { Styles } from '../../../types'

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap-reverse',
    height: 'auto',
    maxHeight: 'auto',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('tablet')]: {
      height: 'auto',
      maxHeight: 'auto',
    },
  }),
  content: (theme) => ({
    display: 'flex',
    flexGrow: 2,
    padding: 0,
    paddingBottom: '1.875rem',
    [theme.breakpoints.only('desktop')]: {
      flexGrow: 1,
    },
  }),
}

export type ArticleHeroImageContainerProps = {
  imageUrl?: string
  imageWidth?: number
  imageTestId?: string
} & BoxProps

const ArticleHeroImageContainer = ({
  children,
  imageUrl,
  imageWidth,
  imageTestId,
  ...boxProps
}: ArticleHeroImageContainerProps) => {
  const contentLayerWidth = 100 - (imageWidth || 65)

  return (
    <Box sx={styles.container} {...boxProps}>
      {/* Only show text on home page, which uses children. */}
      {children && <Box sx={styles.content}>{children}</Box>}{' '}
      <Box
        data-testid={imageTestId ?? ''}
        sx={(theme) => ({
          display: 'flex',
          flexGrow: 2,
          padding: 0,
          minHeight: '18.75rem',
          backgroundImage: `url('${imageUrl}')`,
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
          width: `${contentLayerWidth}%`,
          [theme.breakpoints.down('tablet')]: {
            minWidth: '100%',
            backgroundSize: 'cover',
          },
          [theme.breakpoints.only('tablet')]: {
            minHeight: '20.1875rem',
            backgroundSize: children ? 'cover' : 'contain',
          },
          [theme.breakpoints.only('desktop')]: {
            minHeight: '33.5rem',
            backgroundSize: children ? 'cover' : 'contain',
          },
        })}
      />
    </Box>
  )
}

export default ArticleHeroImageContainer
