import React from 'react'
import { SvgIconProps } from '@mui/material'

const WarningIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={19}
    fill="none"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}>
    <path
      d="m20.766 15.79-8.32-14.22C11.78.477 10.18.477 9.554 1.57l-8.36 14.22c-.625 1.093.157 2.46 1.446 2.46h16.68c1.289 0 2.07-1.367 1.445-2.46ZM10.063 6.062c0-.508.39-.938.937-.938a.95.95 0 0 1 .938.938v5c0 .546-.43.937-.938.937-.469 0-.937-.39-.937-.938v-5ZM11 15.75c-.703 0-1.25-.547-1.25-1.21 0-.665.547-1.212 1.25-1.212.664 0 1.211.547 1.211 1.211s-.547 1.211-1.21 1.211Z"
      fill="#515356"
    />
  </svg>
)

export default WarningIcon
