import React from 'react'
import { Link } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import { Styles } from '../../../types'

const styles: Styles = {
  linkbox: (theme) => ({
    paddingLeft: '1rem',
    [theme.breakpoints.only('tablet')]: {
      display: 'block',
      maxWidth: 445,
    },
  }),
  iconBox: (theme) => ({
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 64,
    [theme.breakpoints.only('desktop')]: {
      minHeight: 64,
    },
    [theme.breakpoints.only('tablet')]: {
      justifyContent: 'left',
      float: 'left',
    },
  }),
  description: (theme) => ({
    fontWeight: 'normal',
    lineHeight: '1.5rem',
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    [theme.breakpoints.up('mobile')]: {
      lineHeight: '1.5rem',
    },
  }),
  linkContainer: () => ({
    display: 'inline-flex',
    width: '100%',
  }),
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '700',
  textDecoration: 'underline',
  lineHeight: '1.5rem',
  fontSize: '1rem',
  [theme.breakpoints.only('tablet')]: {
    display: 'block',
    textAlign: 'left',
  },
}))

export interface HelpfulLinksItemProps {
  LinkIcon: JSX.Element
  linkText: string
  linkUrl: string
  description: string
  testId: string
}

const HelpfulLinksItem = ({
  LinkIcon,
  linkText,
  linkUrl,
  description,
  testId,
}: HelpfulLinksItemProps) => (
  <Box sx={styles.linkContainer}>
    <Box sx={styles.iconBox}>{LinkIcon}</Box>
    <Box sx={styles.linkbox}>
      <StyledLink
        to={linkUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-testid={testId}>
        {linkText}
      </StyledLink>
      <Box sx={styles.description}>{description}</Box>
    </Box>
  </Box>
)

export default HelpfulLinksItem
