import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CreditCardLogo from './CreditCardLogo'

export type NewPaymentMethodProps = {
  accountNumberSuffix: string | undefined
  institutionName: string | undefined
  paymentMethodType: string | undefined
}

const containerStyles: React.CSSProperties = {
  display: 'inline-flex',
  alignItems: 'center',
}

const endingInStyles: React.CSSProperties = {
  paddingLeft: '5px',
}

const NewPaymentMethod = ({
  institutionName,
  accountNumberSuffix,
  paymentMethodType,
}: NewPaymentMethodProps) => {
  const { t } = useTranslation()

  return (
    <Box component="div">
      {paymentMethodType === 'CreditCard' && (
        <Box component="span" sx={containerStyles}>
          <CreditCardLogo
            cardType={institutionName}
            height={52}
            width={42.56}
          />
          <Box component="span" sx={endingInStyles}>
            {` ${t('Invoicing.NewPaymentMethod.CardEndingIn', {
              num: accountNumberSuffix,
            })}`}
          </Box>
        </Box>
      )}
      {paymentMethodType === 'BankAccount' &&
        t('Invoicing.NewPaymentMethod.BankAccountEndingIn', {
          name: institutionName,
          num: accountNumberSuffix,
        })}
    </Box>
  )
}

export default NewPaymentMethod
