import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { AuthClientConfig } from '@rsmus/ecp-userservice'
import { Axios, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import interceptorInstance from './Interceptor/Interceptor'

type ExtraOptions = Partial<
  Omit<AxiosRequestConfig, 'url' | 'method' | 'data' | 'params'>
>

type Query = {
  url: string
  method?: Method
  formData?: boolean
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  onQueryStarting?: () => AbortSignal | undefined
}

export type BaseQuery = BaseQueryFn<
  Query,
  Response,
  Response,
  ExtraOptions | ((value: Query) => ExtraOptions)
>

export interface Response<T = any> {
  statusCode: number
  message: string
  data: T
  error?: {
    isError?: true
    exceptionMessage: string
    details: string
    errorId: string
  }
}

export const axiosClient = new Axios({
  baseURL: process.env.REACT_APP_APIURL ?? '',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosClient.interceptors.request.use(async (request) => {
  const token = await new AuthClientConfig().getAuthorization()
  if (token) {
    // eslint-disable-next-line no-param-reassign
    request.headers = { ...request.headers, Authorization: token }
  }

  await interceptorInstance.logoutIfExpired()

  return request
})

axiosClient.interceptors.response.use((response: AxiosResponse<Response>) => {
  //
  // if response in data is string convert it to JSON
  //
  if (response.data && typeof response.data === 'string')
    // eslint-disable-next-line no-param-reassign
    response.data = JSON.parse(response.data)

  //
  // if error got into response throw it into error
  //
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  if (!response.status.toString().startsWith('2')) throw response

  return response
})

export const axiosBaseQuery =
  (config?: AxiosRequestConfig): BaseQuery =>
  async (query, basequery, extraOptions) => {
    const {
      url,
      method = 'get',
      params = undefined,
      formData,
      onQueryStarting,
    } = query

    let signal: AbortSignal | undefined

    if (onQueryStarting) {
      signal = onQueryStarting()
    }

    let { data } = query

    if (formData) {
      const formdata = new FormData()

      Object.entries(data).forEach(([key, value]) => {
        formdata.append(key, value as string | Blob)
      })

      data = formdata
    } else {
      data = JSON.stringify(data)
    }

    const result = await axiosClient
      .request({
        ...config,
        url,
        method,
        data,
        signal,
        params,
        ...(typeof extraOptions === 'function'
          ? extraOptions(query)
          : extraOptions),
      })
      .catch((error) => {
        throw error?.data?.message ?? error?.message ?? 'Some Error Occurred'
      })

    return { data: result.data }
  }
