import { useTranslation } from 'react-i18next'
import {
  setSelectedInvoices,
  setPayingInvoices,
  setPayableAmount,
  setInvoicePayments,
  setScheduledInstallments,
  InvoicesWithPayments,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { PaymentMethodType } from '../../../types'

type PaidInvoices = { customerName: string; invoices: string }

export const getPaidInvoices = (
  invoicePayments: InvoicesWithPayments,
): PaidInvoices[] => {
  const groupedInvoices = invoicePayments.reduce(
    (group: { [key: string]: string[] }, invoice) => {
      if (!group[invoice.customerName]) {
        // eslint-disable-next-line no-param-reassign
        group[invoice.customerName] = []
      }
      group[invoice.customerName].push(invoice.invoiceNumber)
      return group
    },
    {},
  )

  const keys = Object.keys(groupedInvoices)
  const paidInvoices: PaidInvoices[] = []
  keys.forEach((key) =>
    paidInvoices.push({
      customerName: key,
      invoices: groupedInvoices[key].join(', '),
    }),
  )

  return paidInvoices
}

export const getPaymentMethodDisplay = (
  paymentMethod: PaymentMethodType,
  accountNumber: string | undefined,
): string => {
  const { t } = useTranslation()
  return paymentMethod === 'BankAccount'
    ? `${t('Invoicing.BankAccountEnding')} ${accountNumber}`
    : `${t('Invoicing.CreditCardEnding')} ${accountNumber}`
}

export const handleNavigateBackToInvoices = (dispatch: any, navigate: any) => {
  dispatch(setSelectedInvoices([]))
  dispatch(setPayingInvoices([]))
  dispatch(setInvoicePayments([]))
  dispatch(setPayableAmount(0))
  dispatch(setScheduledInstallments(null))
  navigate('/invoicing/invoices')
}
