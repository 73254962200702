import React, { useCallback } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { PaymentInstallmentFrequencyEnum } from '@rsmus/ecp-financeservice'
import { useSelector } from 'react-redux'
import { EntityLevelEnum } from '@rsmus/ecp-userservice'
import { Styles } from '../../../../types'
import { isCemFeatureEnabled } from '../../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS,
  CEM_FEATURE_PAYMENT_INST_ADDL_FREQUENCY_OPTS,
} from '../../../../utils/constants/constants'
import { getCemFeatures } from '../../../../store/userInfo/userInfoSlice'
import { formatCurrencyNoDecimal } from '../../../../rsmCoreComponents/utils/formatters'
import { tokens } from '../../../../styles/materialTheme'

const styles: Styles = {
  InstallmentsContainer: (theme) => ({
    width: '100%',
    paddingTop: 0,
    paddingRight: '1rem',
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(90% - 1.9rem)',
      paddingLeft: 0,
    },
  }),
  InstallmentLegend: () => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    paddingBottom: '1.25rem',
  }),
  InstallmentOptionGroup: (theme) => ({
    width: '100%',
    gap: '1rem',
    [theme.breakpoints.down('desktop')]: {
      paddingTop: '1rem',
    },
  }),
  InstallmentOption: (theme) => ({
    marginLeft: 0,
    height: '3.5rem',
    flex: '0 1 18.75rem',
    border: '1px solid #CCCCCC',
    ':has(.Mui-checked)': {
      backgroundColor: '#F4F4F4',
    },
    [theme.breakpoints.only('mobile')]: {
      flex: '0 1 100%',
    },
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
}

interface InstallmentPlanProps {
  getPaymentAmounts: (installments: number) => { Amount: number }[]
}

const InstallmentPlan = ({ getPaymentAmounts }: InstallmentPlanProps) => {
  const { control, getValues, watch } = useFormContext()
  const { t } = useTranslation()
  const cemFeatures = useSelector(getCemFeatures)

  const hasExtendedInstallments = useCallback(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS,
        cemFeatures,
        'any',
        EntityLevelEnum.Application,
      ),
    [cemFeatures],
  )

  const hasOtherOption = useCallback(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_INST_ADDL_FREQUENCY_OPTS,
        cemFeatures,
        'any',
      ),
    [cemFeatures],
  )

  const displayedInstallments = {
    [PaymentInstallmentFrequencyEnum.Weekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.BiWeekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.Monthly]: [2, 4, 6],
  }
  const displayedInstallmentsExtended = {
    [PaymentInstallmentFrequencyEnum.Weekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.BiWeekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.Monthly]: [2, 4, 6],
  }

  return (
    <Box sx={styles.InstallmentsContainer}>
      <FormControl component="fieldset">
        <Box component="legend">
          <Box component="span" sx={styles.InstallmentLegend}>
            {t('Invoicing.SelectInstallmentPlan')} *
          </Box>
          <span className="sr-only">{t('srOnlyRequired')}</span>
          <span id="installmentmessage">
            <i>{t('Invoicing.SelectInstallmentPlanSub')}</i>
          </span>
        </Box>
        <Controller
          name="numberOfInstallments"
          control={control}
          defaultValue=""
          rules={{
            required: `${t('Invoicing.SelectInstallmentPlanRequired')}`,
          }}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <>
              <RadioGroup
                ref={ref}
                row
                value={value}
                onChange={onChange}
                sx={styles.InstallmentOptionGroup}
                defaultValue={
                  (hasExtendedInstallments()
                    ? displayedInstallmentsExtended
                    : displayedInstallments)?.[
                    getValues('frequency') as keyof typeof displayedInstallments
                  ]?.[0]
                }>
                {(hasExtendedInstallments()
                  ? displayedInstallmentsExtended
                  : displayedInstallments)?.[
                  getValues('frequency') as keyof typeof displayedInstallments
                ]?.map((i: number) => {
                  const paymentAmount = formatCurrencyNoDecimal(
                    getPaymentAmounts(i)[0]?.Amount,
                  )
                  return (
                    <FormControlLabel
                      key={`numberOfInstallments_${i}`}
                      value={i}
                      control={<Radio disabled={!watch('frequency')} />}
                      sx={styles.InstallmentOption}
                      label={
                        <Trans
                          i18nKey="Invoicing.InstallmentPayments"
                          values={{
                            num: i,
                            paymentAmount,
                          }}
                          components={[<strong />]}
                        />
                      }
                    />
                  )
                })}
                {(getValues('frequency') ===
                  PaymentInstallmentFrequencyEnum.Monthly ||
                  hasOtherOption()) && (
                  <FormControlLabel
                    value="other"
                    control={<Radio disabled={!watch('frequency')} />}
                    sx={styles.InstallmentOption}
                    label={<b>{t('Invoicing.InstallmentPaymentOther')}</b>}
                  />
                )}
              </RadioGroup>
              {error && (
                <FormHelperText
                  id="installmentsErrorText"
                  sx={styles.requiredError}>
                  {error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
    </Box>
  )
}

export default InstallmentPlan
