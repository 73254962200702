import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'usehooks-ts'
import {
  useLocation,
  useNavigate,
  useOutlet,
  useSearchParams,
} from 'react-router-dom'
import SearchLayout from '../../layouts/SearchLayout'
import InvoicesActionBar from './InvoicesActionBar'
import InvoicesChips from './InvoicesChips'
import InvoicesFilters from './InvoicesFilters'
import InvoicesResults from './InvoicesResults'
import InvoicesSearch from './InvoicesSearch'
import useInvoicesSearch from './useInvoicesSearch'
import { resetAll as resetSelectedInvoicesSlice } from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { resetAll as resetPaymentInfoSlice } from '../../../store/invoices/paymentInfoSlice'
import InvoicesRetentionMessage from './InvoicesRetentionMessage'
import { RsmPaginationPageSizes } from '../../../rsmCoreComponents/components/RsmPagination/RsmPagination'
import {
  INVOICE_FILTER_STATUS_OPEN,
  INVOICE_FILTER_STATUS_SCHEDULED,
} from '../../../utils/constants/constants'
import {
  setFilterValue,
  InvoiceSelectedFiltersConstants,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'

const InvoicesLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const outlet = useOutlet()
  const {
    changeFilterAndSearch,
    clearCategoryFilter,
    clearFilters,
    filterIsError,
    filterIsSuccess,
    filterData,
    invoicesData,
    invoicesIsError,
    changeSearchFilterAndSearch,
    setInvoicePageSize,
    toPage,
    pageInfo,
    SortInvoiceData,
  } = useInvoicesSearch()

  const handlePageChange = (
    pageNumber: number,
    pageSize: RsmPaginationPageSizes,
  ) => {
    setInvoicePageSize(pageSize)
    toPage(pageNumber)
  }
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [filter, setFilter] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('invoiceDate') // Initialize sortColumn state
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc') // Initialize sortDirection state
  const [isMounted, setIsMounted] = useState<boolean>(false) // Initialize if layout component is mounted
  const debouncedFilter = useDebounce<string>(filter, 1000)
  // Define a function to handle sorting of invoices based on provided parameters
  const handleSorting = (sortBy: string, isAscending: 'asc' | 'desc') => {
    setSortColumn(sortBy)
    setSortDirection(isAscending)
    SortInvoiceData(sortBy, isAscending)
    setIsMounted(true)
    toPage(1)
  }
  const removeQueryParam = (paramName: string) => {
    const newParams = new URLSearchParams(searchParams)
    newParams.delete(paramName)
    // Update the URL without adding to history
    navigate(
      { pathname: window.location.pathname, search: newParams.toString() },
      { replace: true },
    )
  }

  const isValidClientId = (clientId: string | null): boolean => {
    // confirm client id is a positive integer
    if (clientId) {
      const n = Math.floor(Number(clientId))
      return n !== Infinity && String(n) === clientId && n >= 0
    }
    return false
  }

  const changeFilterValues = () => {
    changeFilterAndSearch(
      setFilterValue(
        InvoiceSelectedFiltersConstants.invoiceStatus,
        INVOICE_FILTER_STATUS_OPEN,
      ),
      location.pathname.indexOf('pay-invoices') > -1,
    ) // sets default search filter for status to open}
    changeFilterAndSearch(
      setFilterValue(
        InvoiceSelectedFiltersConstants.invoiceStatus,
        INVOICE_FILTER_STATUS_SCHEDULED,
      ),
      location.pathname.indexOf('pay-invoices') > -1,
    ) // sets default search filter for status to scheduled}
  }

  // Loading invoices based on saved state
  // DO NOT use requestSearch anywhere else.
  useEffect(() => {
    let clientId: string | null = null
    if (searchParams.has('clientId')) {
      clientId = searchParams.get('clientId')
      removeQueryParam('clientId')
    }
    if (isValidClientId(clientId)) {
      changeFilterAndSearch(
        setFilterValue(
          InvoiceSelectedFiltersConstants.clientId,
          clientId as string,
        ),
        location.pathname.indexOf('pay-invoices') > -1,
      )
    } else {
      changeFilterValues()
    }
  }, [])

  // Sets the status filter to paid during component unmount to fix issue where default is only selected on every other load when switching invoicing tabs
  useEffect(
    () => () => {
      let clientId: string | null = null
      if (searchParams.has('clientId')) {
        clientId = searchParams.get('clientId')
      }
      if (isValidClientId(clientId)) {
        clearFilters()
      } else {
        changeFilterValues()
      }
    },
    [],
  )

  useEffect(() => {
    if (location.pathname === '/invoicing/invoices') {
      dispatch(resetSelectedInvoicesSlice())
      dispatch(resetPaymentInfoSlice())
    }
  }, [location.pathname])

  useEffect(() => {
    changeSearchFilterAndSearch(debouncedFilter)
  }, [debouncedFilter])

  return (
    outlet || (
      <SearchLayout
        title={t('Invoicing.Invoices')}
        drawerDialogSlot={
          <InvoicesFilters
            filterData={filterData}
            isFilterError={filterIsError}
            isFilterSuccess={filterIsSuccess}
            changeFilterAndSearch={changeFilterAndSearch}
            clearCategoryFilter={clearCategoryFilter}
          />
        }
        dialogSecondaryButtonClickHandler={clearFilters}
        searchSlot={<InvoicesSearch setFilter={setFilter} />}
        // removing currency dropdown for now
        // but leaving code in place so we can uncomment when ready to implement
        // searchAuxSlot={
        //   <CurrencyFilter
        //     changeFilterAndSearch={changeFilterAndSearch}
        //     selectedFilterConstant={InvoiceSelectedFiltersConstants.currency}
        //     setFilterValue={setFilterValue}
        //     getSelectedFilters={getInvoiceSelectedFilters}
        //     currencyCodes={filterData?.data?.currency}
        //   />
        // }
        chipsSlot={
          <InvoicesChips
            changeFilterAndSearch={changeFilterAndSearch}
            clearCategoryFilter={clearCategoryFilter}
            clearAllFilters={clearFilters}
          />
        }
        retentionInfo={<InvoicesRetentionMessage />}
        footerSlot={<InvoicesActionBar />}>
        <InvoicesResults
          pageNumber={pageInfo.pageNumber}
          pageSize={pageInfo.pageSize}
          invoicesData={invoicesData}
          isInvoicesError={invoicesIsError}
          onPageChange={handlePageChange}
          onSorting={handleSorting} // Pass down handleSorting
          sortColumn={sortColumn} // Pass down sortColumn
          sortDirection={sortDirection} // Pass down sortDirection
          isMounted={isMounted} // Pass down if layout component is mounted
        />
      </SearchLayout>
    )
  )
}

export default InvoicesLayout
