// ****DO NOT COPY THIS PATTERN - THIS FILE NEEDS A REFACTOR AND UNIT TESTS****

import { ApiResponseOfConcurrentDictionaryOfStringAndIEnumerableOfString } from '@rsmus/ecp-cmsservice'
import { t } from 'i18next'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getInsightFilters from '../../api/insights/getInsightFilters'
import flagFavorites from '../../api/insights/flagFavorites'
import { FilterGroupExpandedStateDictionary } from '../../rsmCoreComponents/components/FiltersGroup/FiltersGroup'
import { isFeatureFlagEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import {
  getAppliedTagFilters,
  InsightsFilterObj,
  setAvailableTagFilters,
} from '../../store/insights/insightsSlice'
import {
  ArticleMetaData,
  ArticlesPaged,
  filterArticleList,
} from '../../utils/helpers/Article.service'
import { formatFilterGroupings } from '../../utils/helpers/formatTags.service'

// There is a separate story to move the auto
// expand-collapse behavior into the filter component.
// In the absence of support in this space, the following
// logic will take in a set of category names and will take the
// appropriate action to ensure the category is expanded in the filter control.
// This logic should be removed when the filter control handles the
// auto-expanding and collapsing of categories/groups based on selection.

export async function getFilters() {
  const filterGroupingKeyYourInsights = 'yourinsights'
  const filterGroupingKeyIndustry = 'industry'
  const filterGroupingKeyService = 'service'
  const filterGroupingKeyRole = 'role'
  const filterGroupKeyTopic = 'topic'

  const allLabel = t('InsightsList.FilterLabels.all')
  const favoritesLabel = t('InsightsList.FilterLabels.favorites')
  const myInterestsLabel = t('InsightsList.FilterLabels.myInterestsLabel')
  const sharedWithMeLabel = t('InsightsList.FilterLabels.sharedWithMe')

  const response: ApiResponseOfConcurrentDictionaryOfStringAndIEnumerableOfString =
    await getInsightFilters()

  const filterMapping = new Map<
    keyof InsightsFilterObj,
    Set<InsightsFilterObj[keyof InsightsFilterObj]>
  >()

  if (response.data) {
    const myInsightsValues = new Set<
      InsightsFilterObj[keyof InsightsFilterObj]
    >(
      isFeatureFlagEnabled('UserProfile')
        ? [allLabel, favoritesLabel, myInterestsLabel, sharedWithMeLabel]
        : [favoritesLabel, sharedWithMeLabel],
    )
    filterMapping.set(
      filterGroupingKeyYourInsights as keyof InsightsFilterObj,
      myInsightsValues,
    )

    Object.entries(response.data).forEach(([key, value]) => {
      const myValues = new Set<InsightsFilterObj[keyof InsightsFilterObj]>(
        value,
      )
      filterMapping.set(
        formatFilterGroupings(key) as keyof InsightsFilterObj,
        myValues,
      )
    })
    const sortedFilterMap = new Map<
      keyof InsightsFilterObj,
      Set<InsightsFilterObj[keyof InsightsFilterObj]>
    >()

    sortedFilterMap.set(
      filterGroupingKeyYourInsights as keyof InsightsFilterObj,
      new Set<InsightsFilterObj[keyof InsightsFilterObj]>(
        filterMapping.get(filterGroupingKeyYourInsights),
      ),
    )

    sortedFilterMap.set(
      filterGroupingKeyIndustry as keyof InsightsFilterObj,
      new Set<InsightsFilterObj[keyof InsightsFilterObj]>(
        filterMapping.get(filterGroupingKeyIndustry),
      ),
    )
    sortedFilterMap.set(
      filterGroupingKeyService as keyof InsightsFilterObj,
      new Set<InsightsFilterObj[keyof InsightsFilterObj]>(
        filterMapping.get(filterGroupingKeyService),
      ),
    )
    sortedFilterMap.set(
      filterGroupKeyTopic as keyof InsightsFilterObj,
      new Set<InsightsFilterObj[keyof InsightsFilterObj]>(
        filterMapping.get(filterGroupKeyTopic),
      ),
    )
    sortedFilterMap.set(
      filterGroupingKeyRole as keyof InsightsFilterObj,
      new Set<InsightsFilterObj[keyof InsightsFilterObj]>(
        filterMapping.get(filterGroupingKeyRole),
      ),
    )

    return sortedFilterMap
  }
  return filterMapping
}

const useCustomFetch = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFetchError: React.Dispatch<React.SetStateAction<boolean>>,
  setPageNumber: React.Dispatch<React.SetStateAction<number>>,
  setIsLoadMore: React.Dispatch<React.SetStateAction<boolean>>,
  setFocusIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  setArticleMetaData: React.Dispatch<React.SetStateAction<ArticleMetaData[]>>,
  debouncedFilter: string,
  pageNumber: number,
  articleMetadata: ArticleMetaData[],
) => {
  const filterTitleTranslationKeyPrefix = 'InsightsList.FilterHeaders'
  const favoritesLabel = t('InsightsList.FilterLabels.favorites')
  const myInterestsLabel = t('InsightsList.FilterLabels.myInterestsLabel')
  const sharedWithMeLabel = t('InsightsList.FilterLabels.sharedWithMe')
  const allLabel = t('InsightsList.FilterLabels.all')

  const pageSize = 12
  const dispatch = useDispatch()
  const filterDataState = useSelector(getAppliedTagFilters)

  const loadFilters = async (
    filterGroupExpanded: FilterGroupExpandedStateDictionary,
    setFilterGroupExpanded: React.Dispatch<
      React.SetStateAction<FilterGroupExpandedStateDictionary>
    >,
  ) => {
    const sortedFilterMap = await getFilters()

    if (sortedFilterMap.size > 0) {
      dispatch(setAvailableTagFilters(sortedFilterMap))

      // There is a separate story to move the auto
      // expand-collapse behavior into the filter component.
      // In the absence of support in this space, the following
      // logic will determine what filter groups need to be expanded
      // in the control based on the current filter selections - so
      // they are visible to the user.
      if (
        isFeatureFlagEnabled('InsightArticle_ClickableTags') &&
        filterDataState.size > 0
      ) {
        const categoriesWithFilters = new Set<string>()
        filterDataState.forEach(
          (
            setOfTags: Set<InsightsFilterObj[keyof InsightsFilterObj]>,
            category: keyof InsightsFilterObj,
          ) => {
            categoriesWithFilters.add(category)
          },
        )
        const updated = { ...filterGroupExpanded }
        categoriesWithFilters.forEach((group: string) => {
          updated[`${filterTitleTranslationKeyPrefix}.${group}`] = true
          setFilterGroupExpanded(updated)
        })
      }
    }
  }

  const fetchFilteredArticles = useCallback(async () => {
    setIsLoading(true)
    setFetchError(false)
    setPageNumber(1)
    // Any fetch errors or no data show robots page
    try {
      const filterValueList: string[] = []
      const filterRecentlyViewed = false
      let filterFavorites = false
      let filterMyInterests = false
      let sharedWithMe = false
      Array.from(filterDataState.entries()).forEach(([, set]) => {
        const filterValues = Array.from(set.values())
        filterValues.forEach((item) => {
          if (item === myInterestsLabel) {
            filterMyInterests = true
          } else if (item === sharedWithMeLabel) {
            sharedWithMe = true
          } else if (item === favoritesLabel) {
            filterFavorites = true
          } else if (item === allLabel) {
            filterFavorites = false
            filterMyInterests = false
            sharedWithMe = false
          } else {
            filterValueList.push(item as string)
          }
        })
      })

      const pagedResults: ArticlesPaged = await filterArticleList(
        1, // pageNumber forced to 1
        pageSize,
        debouncedFilter,
        filterValueList,
        filterFavorites,
        filterRecentlyViewed,
        filterMyInterests,
        sharedWithMe,
      )
      setFocusIndex(0)

      // // Fetch favorites only for new page
      setArticleMetaData([...pagedResults.articles])
      await flagFavorites(pagedResults.articles)
      setIsLoadMore(pagedResults.cursorPaging?.hasNextPage === true)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
      setFetchError(true)
    }
  }, [debouncedFilter, filterDataState, flagFavorites, setFetchError])

  const fetchFilteredArticlesPaged = useCallback(async () => {
    setIsLoading(true)
    setFetchError(false)
    // Any fetch errors or no data show robots page
    try {
      const filterValueList: string[] = []
      const filterRecentlyViewed = false
      let filterFavorites = false
      let filterMyInterests = false
      let sharedWithMe = false
      Array.from(filterDataState.entries()).forEach(([, set]) => {
        const filterValues = Array.from(set.values())
        filterValues.forEach((item) => {
          if (item === myInterestsLabel) {
            filterMyInterests = true
          } else if (item === sharedWithMeLabel) {
            sharedWithMe = true
          } else if (item === favoritesLabel) {
            filterFavorites = true
          } else if (item === allLabel) {
            filterFavorites = false
            filterMyInterests = false
            sharedWithMe = false
          } else {
            filterValueList.push(item as string)
          }
        })
      })

      const pagedResults: ArticlesPaged = await filterArticleList(
        pageNumber,
        pageSize,
        debouncedFilter,
        filterValueList,
        filterFavorites,
        filterRecentlyViewed,
        filterMyInterests,
        sharedWithMe,
      )

      setFocusIndex((pageNumber - 1) * pageSize)

      setArticleMetaData([...articleMetadata, ...pagedResults.articles])
      // Fetch favorites only for new page
      await flagFavorites(pagedResults.articles)
      setIsLoading(false)
      setIsLoadMore(pagedResults.cursorPaging?.hasNextPage === true)
    } catch {
      setIsLoading(false)
      setFetchError(true)
    }
  }, [pageNumber, setFetchError])

  return { fetchFilteredArticles, fetchFilteredArticlesPaged, loadFilters }
}

export default useCustomFetch
