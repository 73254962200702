import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  useTheme,
} from '@mui/material/'
import { OidcIdentityContext } from '@rsmus/react-auth'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { showPopover } from '../../../store/form/formSlice'
import { Styles } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'

const styles: Styles = {
  stack: {
    display: 'inline-flex',
  },
  iconButton: (theme) => ({
    display: 'inline-flex',
    verticalAlign: 'top',
    borderRadius: 0,
    padding: '0.5rem',
    minWidth: 'auto',
    color: theme.palette.common.white,
  }),
  paper: (theme) => ({
    backgroundColor: theme.palette.common.black,
  }),
  menuList: (theme) => ({
    minWidth: '8rem',
    '.MuiMenuItem-root': {
      justifyContent: 'right',
      border: 'none',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      ':hover': {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
    },
  }),
}

const AccountMenu = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { t } = useTranslation()

  const { isMobile } = useDeviceType()

  // Used to show/hide Account settings/logout
  const {
    userInfo: {
      UserInfo: { isEmployee },
    },
    popover: { messageType: message },
  } = useAppSelector((state) => state)

  const dispatch = useAppDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const iconButtonRef = useRef<HTMLButtonElement | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const iconButtonTabIndex =
    isFeatureFlagEnabled('UserProfile') || !isEmployee ? 0 : -1
  const iconButtonIsDisabled = isFeatureFlagEnabled('UserProfile')
    ? false
    : isEmployee

  const menuListRef = useRef<HTMLUListElement>(null)

  // Used for logout() redirect
  const context = useContext(OidcIdentityContext)

  // Handle PopOver
  useEffect(() => {
    if (message && !isMobile) {
      dispatch(showPopover(iconButtonRef?.current))
    }
  }, [message, isMobile])

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen((prevOpen) => !prevOpen)
    setAnchorEl(event?.currentTarget)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorEl === event.target) {
      return
    }

    setIsOpen(false)
  }

  const handleMenuListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setIsOpen((prevOpen) => !prevOpen)
    } else if (event.key === 'Escape') {
      event.preventDefault()
      setIsOpen(false)
    }
  }

  const handleButtonListKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setIsOpen((prevOpen) => !prevOpen)
    } else if (event.key === 'Escape') {
      event.preventDefault()
      setIsOpen(false)
    }
    setAnchorEl(event?.currentTarget)
  }

  const handleMenuItemBlur = (event: React.FocusEvent) => {
    if (menuListRef.current?.contains(event.relatedTarget as HTMLElement)) {
      return
    }

    if (isOpen) {
      setIsOpen(false)
    }
  }

  const handleProfileClick = () => {
    navigate('/profile')
  }

  const handleLogoutClick = () => {
    context.logout(false)
  }

  // Return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen)

  useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorEl?.focus()
    }

    prevOpen.current = isOpen
  }, [isOpen])

  return (
    <Stack direction="row" spacing={2} sx={styles.stack}>
      <div>
        <IconButton
          sx={styles.iconButton}
          name={t('Navigation.AccountSettings')}
          role="button"
          aria-controls={isOpen ? 'composition-menu' : undefined}
          aria-expanded={isOpen ? 'true' : undefined}
          aria-haspopup="true"
          aria-label={t('Navigation.AccountSettings')}
          tabIndex={iconButtonTabIndex}
          disabled={iconButtonIsDisabled}
          data-testid="Btn_Header_AccountMenu"
          ref={iconButtonRef}
          onClick={handleToggle}
          onKeyDown={handleButtonListKeyDown}
          disableRipple>
          <FontAwesomeIcon
            style={{ width: '1.375rem' }}
            color="white"
            icon={faUser}
          />
        </IconButton>
        <Popper
          style={{
            backgroundColor: theme.palette.common.white,
            zIndex: 1000,
            border: 'none',
          }}
          anchorEl={anchorEl}
          role={undefined}
          open={isOpen}
          placement="bottom-end"
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              in={TransitionProps?.in}
              onEnter={TransitionProps?.onEnter}
              onExited={TransitionProps?.onExited}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'right bottom',
              }}>
              <Paper elevation={0} sx={styles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    sx={styles.menuList}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    ref={menuListRef}
                    data-testid="Mnu_Account"
                    onKeyDown={handleMenuListKeyDown}
                    autoFocusItem={isOpen}>
                    {isFeatureFlagEnabled('UserProfile') && (
                      <MenuItem
                        data-testid="Lnk_Navigation_Profile"
                        onBlur={handleMenuItemBlur}
                        onClick={handleProfileClick}>
                        {t('Navigation.Profile')}
                      </MenuItem>
                    )}

                    {!isEmployee && (
                      <MenuItem
                        data-testid="Lnk_Navigation_Logout"
                        onBlur={handleMenuItemBlur}
                        onClick={handleLogoutClick}>
                        {t('Navigation.Logout')}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  )
}

export default AccountMenu
