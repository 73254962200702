import { styled, Link } from '@mui/material'
import { sxStyles } from '../../../styles/sxStyles'

// eslint-disable-next-line import/prefer-default-export
export const StyledLink = styled(Link)(({ theme }) => ({
  verticalAlign: 'center',
  textDecoration: 'underline',
  backgroundColor: 'white',
  color: theme.palette.secondary.main,
  fontWeight: '500',
  fontFamily: 'Prelo-Book, sans-serif',
  padding: '1.5rem 0.625rem',
  [theme.breakpoints.down('desktop')]: {
    padding: '1.5rem',
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.rsmBackgroundAccent.main,
  },
}))

// eslint-disable-next-line import/prefer-default-export
export const useStyles = () =>
  sxStyles({
    root: (theme) => ({
      pr: 3,
      pl: 0,
      [theme.breakpoints.down('tablet')]: {
        pr: 0,
      },
    }),
  })
