import React from 'react'
import { styled } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import tokens from '../../../styles/tokens.json'

const StyledTableCell = styled(TableCell)({
  fontFamily: 'Prelo-Book, sans-serif',
  fontSize: '14px',
  lineHeight: '24px',
  borderBottomWidth: 0,
  fontWeight: '700',
  color: tokens.colors.rsmGray.copy,
})

interface CustomTableCellProps {
  ariaSort?: 'other' | 'none' | 'descending' | 'ascending' | undefined
  styles?: any
  onMouseEnter?: () => void
  onMouseOut?: () => void
  onClick?: () => void
  children: React.ReactNode
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({
  ariaSort = undefined,
  styles = undefined,
  children,
  onMouseEnter = undefined,
  onMouseOut = undefined,
  onClick = undefined,
}) => (
  <StyledTableCell
    aria-sort={ariaSort}
    data-testid="customtablecell"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseOut}
    onClick={onClick}
    component="th"
    scope="col"
    align="left"
    sx={{ ...styles }}>
    {children}
  </StyledTableCell>
)

export default CustomTableCell
