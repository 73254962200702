import React from 'react'

const SolarArrayIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_130889)">
      <path
        d="M37.1492 48.9502L23.2892 44.7402L1.94922 63.3802L17.3692 67.7802L37.1492 48.9502Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.0602 47.0801L11.1602 66.0101"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.1396 45.6406L6.59961 64.7006"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.9785 49.3711L32.2785 53.8611"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M11.6289 53.8496L27.7489 58.4196"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M6.73828 58.6602L22.9783 63.2302"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M25.1309 60.3906L26.9109 73.6906"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.8595 67.0703L13.0195 73.6903"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M26.5395 70.3809H13.9395"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.94922 73.6895H76.0692"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57.2595 46.7499L44.0795 42.1699L22.5195 62.4599L38.0995 67.2599L57.2595 46.7499Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M52.9403 44.709L31.8203 65.329"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48.9788 43.1504L27.2188 63.9104"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M38.7188 47.2207L53.1587 52.1007"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.2988 52.0898L47.5388 57.0598"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.3594 57.3301L42.9594 62.3001"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M45.9395 59.2109L47.7395 73.6909"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M35.5692 66.4805L33.6992 73.6905"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M47.3709 70.0801H34.6309"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M77.0008 43.4003L64.5208 38.8203L45.3008 59.1103L59.1908 63.9103L77.0008 43.4003Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M72.4098 41.3711L53.5898 61.9811"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M68.8905 39.8008L49.4805 60.5608"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M59.7402 43.8711L72.6102 48.7511"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.0215 48.75L68.5415 53.71"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M49.6211 53.9805L64.2411 58.9505"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M66.1699 56.5391L70.1699 73.2191"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M56.0095 63.4102L54.0195 73.2202"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M68.6295 68.3203H55.0195"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M26.5397 30.4309C30.8806 30.4309 34.3997 26.9119 34.3997 22.5709C34.3997 18.23 30.8806 14.7109 26.5397 14.7109C22.1987 14.7109 18.6797 18.23 18.6797 22.5709C18.6797 26.9119 22.1987 30.4309 26.5397 30.4309Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M29.6309 15.3504L26.9209 6.40039L24.2109 15.0704"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.3699 21.5107L40.7899 14.7207L31.9199 16.6707"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.4805 28.7003L40.6405 30.5803L34.3105 24.0703"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.8105 29.9395L26.7205 38.8195L29.2305 30.0895"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M18.7598 21.4306L12.3398 14.6406L21.2098 16.5906"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.3202 28.5909L12.1602 30.4709L18.4902 23.9609"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32.5796 17.3695L34.1496 8.01953L27.8496 14.6595"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.0591 25.1094L42.8391 21.8494L34.0391 19.6094"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.2695 30.3302L35.6195 36.1902L32.8595 27.4102"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.8502 27.9707L19.4902 37.3507L25.6302 30.5707"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.1397 17.9606L17.4297 8.89062L24.4897 14.6806"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M19.2195 25.6206L10.1895 23.1806L18.7595 20.1406"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_130889">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SolarArrayIcon
