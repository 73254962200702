import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@mui/material'
import { AssociateViewModel } from '@rsmus/ecp-userservice'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IChipInfo } from '../../../rsmCoreComponents/components/RsmAutoComplete/AutoCompleteTypes'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import FilterChips from '../../FilterChips'

const styles: Styles = {
  userContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  nonEcpUserInfoMessage: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    fontFamily: 'Prelo-Bold, sans-serif',
  },
  nonEcpUserformControlLabel: { paddingTop: '1rem' },
  nonEcpUserAckMessage: { fontSize: '0.875rem' },
  nonEcpUserAckRequired: {
    fontFamily: 'Prelo-Medium, sans-serif',
    fontSize: '0.875rem !important',
    color: tokens.colors.rsmRed.secondary,
    fontStyle: 'italic',
  },
}

interface ShareArticleChipProps
  extends IChipInfo<AssociateViewModel & { nonEcpUser: boolean }> {
  ecpUserCheck: boolean
  showEcpWarning: boolean
  setShowEcpWarning: (value: boolean) => void
  setEcpUserCheck: (checked: boolean) => void
  checkboxRef: any
}

export const ShareArticleChip = ({
  listItems,
  onClearAll,
  chipHandleOnClicked,
  ecpUserCheck,
  setEcpUserCheck,
  showEcpWarning,
  setShowEcpWarning,
  checkboxRef,
}: ShareArticleChipProps) => {
  const { t } = useTranslation()
  const { getNonEcpUserList, getEcpUserList } = useMemo(
    () => ({
      getNonEcpUserList: listItems.filter((item) => item.context.nonEcpUser),
      getEcpUserList: listItems.filter((item) => !item.context.nonEcpUser),
    }),
    [listItems],
  )

  const computedStyles = {
    nonEcpUserCheckbox: useMemo(
      () => ({
        color: showEcpWarning ? tokens.colors.rsmRed.secondary : 'inherit',
      }),
      [showEcpWarning],
    ),
  }

  useEffect(() => {
    if (!getNonEcpUserList.length) {
      setShowEcpWarning(false)
    }
  }, [getNonEcpUserList.length])

  const chipRender = (userList: any, ecpUser: boolean) => (
    <FilterChips<AssociateViewModel & { nonEcpUser: boolean }>
      uniqueId={ecpUser ? `rsm_user` : 'non_rsm_user'}
      clearAllFocusQuerySelector="rsmAutoComplete"
      filtersSelected={userList.map((item: any) => {
        const ctx = item.context
        return {
          ...item,
          property: '',
          value: `${ctx.user?.firstName} ${ctx.user?.lastName}`,
        }
      })}
      onChipDeleted={chipHandleOnClicked}
      onClearAll={() => {
        if (ecpUser) {
          setEcpUserCheck(false)
          setShowEcpWarning(false)
        }
        const cloneListItems = [...listItems]
        onClearAll(
          cloneListItems.filter(
            (listItem) => listItem.context.nonEcpUser === !ecpUser,
          ),
        )
      }}
      filterChipTranslationKeyPrefix="InsightsList.FilterHeaders"
    />
  )

  return listItems.length ? (
    <Box sx={styles.userContainer}>
      {!!getEcpUserList?.length && chipRender(getEcpUserList, false)}

      {!!getNonEcpUserList?.length && (
        <Box>
          <Typography variant="subtitle1" sx={styles.nonEcpUserInfoMessage}>
            {t('ShareArticleModal.NonEcpUserInfoMessage')}
          </Typography>
          {chipRender(getNonEcpUserList, true)}
          <FormControl error={showEcpWarning}>
            <FormControlLabel
              sx={styles.nonEcpUserformControlLabel}
              control={
                <Checkbox
                  checked={ecpUserCheck}
                  sx={computedStyles.nonEcpUserCheckbox}
                  inputRef={checkboxRef}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEcpUserCheck(e.target.checked)
                  }
                  data-testid="Btn_Insight_ShareNonEcpPermission"
                />
              }
              label={
                <Typography sx={styles.nonEcpUserAckMessage}>
                  {t('ShareArticleModal.NonEcpUserAckMessage')}
                </Typography>
              }
            />
            {!!showEcpWarning && (
              <FormHelperText sx={styles.nonEcpUserAckRequired}>
                {t('ShareArticleModal.NonEcpUserAckRequired')}
              </FormHelperText>
            )}
          </FormControl>
        </Box>
      )}
    </Box>
  ) : (
    <> </>
  )
}

export default ShareArticleChip
