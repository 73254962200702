import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CemFeatureModel, IUserInfo } from '@rsmus/ecp-userservice'
import type { RootState } from '..'
import { TargetingFeatureFlag } from '../../utils/constants/constants'

interface UserInfoState {
  isReady?: boolean | undefined
  UserInfo: IUserInfo
  CemFeatures: CemFeatureModel[]
  TargetedFeatureFlags: TargetingFeatureFlag[]
}

const initialState: UserInfoState = {
  isReady: undefined,
  UserInfo: {} as IUserInfo,
  CemFeatures: [],
  TargetedFeatureFlags: [],
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, { payload }: PayloadAction<IUserInfo>) => {
      state.UserInfo = payload
    },
    setCemFeatures: (state, { payload }: PayloadAction<CemFeatureModel[]>) => {
      state.CemFeatures = payload
    },
    setTargetedFeatureFlags: (
      state,
      { payload }: PayloadAction<TargetingFeatureFlag[]>,
    ) => {
      state.TargetedFeatureFlags = payload
    },
    setReadyState: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.isReady = payload
    },
  },
})

export const getReadyState = (state: RootState) => state.userInfo.isReady
export const { setReadyState } = userInfoSlice.actions
export const getUserInfo = (state: RootState) => state.userInfo.UserInfo
export const { setUserInfo } = userInfoSlice.actions
export const getCemFeatures = (state: RootState) => state.userInfo.CemFeatures
export const { setCemFeatures } = userInfoSlice.actions
export const getTargetedFeatureFlags = (state: RootState) =>
  state.userInfo.TargetedFeatureFlags
export const { setTargetedFeatureFlags } = userInfoSlice.actions

export default userInfoSlice.reducer
