import React from 'react'

const MagnifyingGlassFingerprintIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131646)">
      <path
        d="M18.7598 20.8595C24.2898 14.2995 34.0598 15.3295 38.9698 19.7695"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.5195 27.2702C17.5195 27.2702 21.7195 19.3502 28.1995 19.2502C34.6895 19.1502 38.3395 22.6202 40.3095 25.7202"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.9512 31.8188C16.9512 31.8188 20.2812 29.7688 21.5212 26.8188C22.7612 23.8688 28.2812 21.0788 32.3612 22.8188C36.4412 24.5588 38.6512 27.2888 38.4512 32.1588C38.2912 35.9088 36.1912 37.4988 40.5312 41.5688"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.0996 27.6387C40.0996 27.6387 42.2996 33.1687 40.5296 38.7487"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.1899 38.3789C32.1899 38.3789 29.4499 42.0489 34.8299 46.1789"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M30.7002 47.4787C30.7002 47.4787 30.1802 43.9688 28.0602 43.9688C25.9402 43.9688 24.4902 46.7087 24.4902 46.7087"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.31 31.4785C28.74 34.8185 23.51 40.3385 18.5 43.5485"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.9512 40.2391C16.9512 40.2391 24.4912 35.3291 25.6612 31.5891C25.9512 30.3991 28.2312 27.5291 30.7012 28.9291C33.1712 30.3291 32.5912 32.7991 32.3512 33.9591C31.5012 38.0191 20.9312 45.7191 20.9312 45.7191"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M16.7402 36.1102C16.7402 36.1102 20.5502 33.8402 21.2302 32.8302C21.9102 31.8202 23.0602 29.7802 23.6902 28.6502C24.3202 27.5102 27.1302 24.1802 31.1102 25.7302C36.3802 28.1102 35.1902 32.2902 35.1902 32.2902C35.1902 32.2902 34.5202 36.1102 34.3602 37.9702C34.2002 39.8302 35.8602 42.9802 38.9602 43.9602"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M44.2501 10.2393C55.9901 18.6093 58.7201 34.8993 50.3501 46.6393C41.9801 58.3693 25.6901 61.1093 13.9501 52.7393C2.21006 44.3593 -0.519941 28.0693 7.85006 16.3293C16.2101 4.59933 32.5101 1.86933 44.2501 10.2393Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.11 14.6287C50.42 21.2587 52.58 34.1887 45.94 43.4887C39.31 52.7987 26.39 54.9587 17.08 48.3287C7.77004 41.6887 5.61004 28.7687 12.24 19.4687C18.87 10.1587 31.79 7.99871 41.1 14.6287H41.11Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M79.4196 62.0196L62.4496 49.6296C60.9396 48.5496 58.5296 49.3496 57.0596 51.4096C55.5896 53.4696 55.6296 56.0096 57.1296 57.0796L78.8996 72.5996"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M57.9307 49.9792L51.2207 45.1992"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M56.2394 53.0191L49.1094 47.8691"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131646">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MagnifyingGlassFingerprintIcon
