import {
  AuthClientConfig as DocumentRequestServiceConfig,
  DocumentRequestServiceClient,
  ApiResponseOfIEnumerableOfDocumentRequestSummaryModel,
} from '@rsmus/ecp-documentrequestservice-legacy'

import { DOCUMENTREQUESTSERVICE_BASE_URL } from '../../envVariables'

const documentRequestService = new DocumentRequestServiceClient(
  new DocumentRequestServiceConfig(),
  DOCUMENTREQUESTSERVICE_BASE_URL,
)

const GetClientDocumentRequestsSummary = (
  documentRequestId: string,
): Promise<ApiResponseOfIEnumerableOfDocumentRequestSummaryModel> =>
  documentRequestService.documentRequest_GetClientDocumentRequestsSummary(
    documentRequestId,
  )

export default GetClientDocumentRequestsSummary
