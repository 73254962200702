import React, { useState } from 'react'
import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import api from '../../../api'
import RsmDialog from '../../overlay/RsmDialog'
import { Styles } from '../../../types'

interface ConfirmCreditCardEnableProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  onError: () => void
  clientId: number
  clientName: string
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxHeight: '100%',
    height: '100%',
    border: '0',
    padding: '0.5rem',
  },
  acceptWrapper: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    border: '0',
    [theme.breakpoints.down('desktop')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  }),
  mobileDialogModal: (theme) => ({
    '& .MuiDialog-paper': {
      maxWidth: '45.5rem',
      [theme.breakpoints.only('mobile')]: {
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        left: '1rem',
        margin: '0rem',
        width: 'calc(100% - 2rem)',
        maxWidth: '100%',
        maxHeight: 'calc(100% - env(safe-area-inset-bottom))',
      },
    },
  }),
}

const ConfirmCreditCardEnable = ({
  open,
  onClose,
  onSuccess,
  onError,
  clientId,
  clientName,
}: ConfirmCreditCardEnableProps) => {
  const { t } = useTranslation()
  const [confirmDisabled, setConfirmDisabled] = useState(false)

  const handleConfirmEnable = async () => {
    try {
      setConfirmDisabled(true)
      await api.finance.client_EnableCreditCardPayment(clientId)
      onClose()
      onSuccess()
    } catch (error: any) {
      onError()
    } finally {
      setConfirmDisabled(false)
    }
  }
  return (
    <RsmDialog
      isOpen={open}
      closeHandler={onClose}
      dialogCustomProps={{
        sx: styles.mobileDialogModal,
        'aria-label': t(
          'Invoicing.PaymentManagement.ConfirmEnableCreditCard.Title',
        ),
      }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.dialogContent}>
              <Typography variant="body1">
                <Trans
                  i18nKey="Invoicing.PaymentManagement.ConfirmEnableCreditCard.EnableCreditCardReview"
                  components={{
                    client: <strong />,
                    lb: <br />,
                  }}
                  values={{
                    clientId,
                    clientName,
                  }}
                />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Stack sx={styles.acceptWrapper}>
                <Button variant="outlined" onClick={onClose}>
                  {t(
                    'Invoicing.PaymentManagement.ConfirmEnableCreditCard.Cancel',
                  )}
                </Button>
                <Button
                  disabled={confirmDisabled}
                  variant="contained"
                  onClick={handleConfirmEnable}>
                  {t(
                    'Invoicing.PaymentManagement.ConfirmEnableCreditCard.Proceed',
                  )}
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </RsmDialog>
  )
}

export default ConfirmCreditCardEnable
