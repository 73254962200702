import React from 'react'

const TXT = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="5" y="12" width="13" height="7" rx="0.8" fill="#646464" />
    <path
      d="M8.88848 14.5017V17H8.25786V14.5017H7.45806V13.9392H9.68828V14.5017H8.88848ZM11.4692 15.9299H11.4604L10.8584 17H10.1553L11.0781 15.4114L10.2058 13.9392H10.9375L11.5 14.895H11.511L12.0537 13.9392H12.792L11.9153 15.4114L12.8733 17H12.1218L11.4692 15.9299ZM14.7443 14.5017V17H14.1137V14.5017H13.3139V13.9392H15.5441V14.5017H14.7443Z"
      fill="white"
    />
  </svg>
)

export default TXT
