import { sxStyles } from '../../../styles/sxStyles'

const useStyles = () =>
  sxStyles({
    root: (theme) => ({
      '& > a ': {
        color: theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        lineHeight: 0,
        padding: '0.75rem 0.5rem',
        margin: '0.5rem 0rem 1.375rem',
        '&:focus': {
          outline: '0.125rem solid black',
        },
        '> span': {
          color: theme.palette.secondary.main,
          fontSize: '1rem',
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
    }),
  })

export default useStyles
