import React from 'react'

const AutopayEnabledIcon = () => (
  <svg
    width="23"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2891 6.875H20.0234V4.72656C20.0234 4.02832 20.5605 3.4375 21.3125 3.4375C22.0107 3.4375 22.6016 4.02832 22.6016 4.72656V6.875H24.75C26.6299 6.875 28.1875 8.43262 28.1875 10.3125V27.5C28.1875 29.4336 26.6299 30.9375 24.75 30.9375H7.5625C5.62891 30.9375 4.125 29.4336 4.125 27.5V10.3125C4.125 8.43262 5.62891 6.875 7.5625 6.875H9.71094V4.72656C9.71094 4.02832 10.248 3.4375 11 3.4375C11.6982 3.4375 12.2891 4.02832 12.2891 4.72656V6.875ZM6.70312 27.5C6.70312 27.9834 7.0791 28.3594 7.5625 28.3594H24.75C25.1797 28.3594 25.6094 27.9834 25.6094 27.5V13.75H6.70312V27.5Z"
      fill="#515356"
    />
    <path
      d="M21.3362 15.8125C21.6681 15.8125 22 16.0586 22 16.4769V20.0698C22 20.3159 21.7866 20.5373 21.5259 20.5373H18.0884C17.6853 20.5373 17.4483 20.1928 17.4483 19.8729C17.4483 19.7006 17.4957 19.5284 17.6379 19.4053L18.7047 18.2734C18.0409 17.7074 17.1875 17.3874 16.2866 17.3874C14.2004 17.3874 12.5172 19.1593 12.5172 21.3248C12.5172 23.4904 14.2004 25.2376 16.2866 25.2376C18.0409 25.2376 18.4203 24.3024 19.0366 24.3024C19.4634 24.3024 19.7716 24.6716 19.7716 25.0899C19.7716 25.9512 17.8513 26.8125 16.2866 26.8125C13.3707 26.8125 11 24.3516 11 21.3248C11 18.2734 13.3707 15.8125 16.3103 15.8125C17.5905 15.8125 18.8233 16.3047 19.7716 17.166L20.9095 16.0094C21.028 15.8617 21.194 15.8125 21.3362 15.8125Z"
      fill="#515356"
    />
    <path
      d="M21.3362 15.8125C21.6681 15.8125 22 16.0586 22 16.4769V20.0698C22 20.3159 21.7866 20.5373 21.5259 20.5373H18.0884C17.6853 20.5373 17.4483 20.1928 17.4483 19.8729C17.4483 19.7006 17.4957 19.5284 17.6379 19.4053L18.7047 18.2734C18.0409 17.7074 17.1875 17.3874 16.2866 17.3874C14.2004 17.3874 12.5172 19.1593 12.5172 21.3248C12.5172 23.4904 14.2004 25.2376 16.2866 25.2376C18.0409 25.2376 18.4203 24.3024 19.0366 24.3024C19.4634 24.3024 19.7716 24.6716 19.7716 25.0899C19.7716 25.9512 17.8513 26.8125 16.2866 26.8125C13.3707 26.8125 11 24.3516 11 21.3248C11 18.2734 13.3707 15.8125 16.3103 15.8125C17.5905 15.8125 18.8233 16.3047 19.7716 17.166L20.9095 16.0094C21.028 15.8617 21.194 15.8125 21.3362 15.8125Z"
      fill="#515356"
    />
  </svg>
)
export default AutopayEnabledIcon
