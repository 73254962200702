/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Button, ButtonProps, styled } from '@mui/material'

export const GroupHeaderButton = styled(Button)({
  m: 0,
  p: 0,
  display: 'flex',
  justifyContent: 'start',
  justifyItems: 'start',
})

const StyledButton = (props: ButtonProps) => (
  <Button {...props} disableFocusRipple />
)

export default StyledButton
