import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

// Define a type for the slice state
interface DeepLinkState {
  redirectUrl?: string
}

// Define the initial state using that type
const initialState: DeepLinkState = {
  redirectUrl: undefined,
}

export const deepLinkSlice = createSlice({
  name: 'deepLink',
  initialState,
  reducers: {
    setRedirectUrl: (state, { payload }) => {
      state.redirectUrl = payload
    },
  },
})

export const getRedirectUrl = (state: RootState) => state.deepLink.redirectUrl

export const { setRedirectUrl } = deepLinkSlice.actions
export default deepLinkSlice.reducer
