import api from '..'

/**
 * Safely get if an Article is favorited by current user
 * WILL SWALLOW all Errors.
 */
const tryGetIsFavorited = async (
  articleId: string | undefined,
): Promise<boolean> => {
  if (!articleId) return Promise.resolve(false)
  try {
    const favorites = await api.cms.insights_GetFavoriteArticles()
    return await Promise.resolve(favorites?.data?.includes(articleId) ?? false)
  } catch {
    return Promise.resolve(false)
  }
}

export default tryGetIsFavorited
