/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react'
import { SvgIconProps } from '@mui/material'

const CheckListIcon = (props: SvgIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    viewBox="0 0 80 80"
    {...props}>
    <path
      stroke="#9FA2A3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m60.57 55.54-.1 18.86a3.867 3.867 0 0 1-3.89 3.85l-43.85-.24a3.867 3.867 0 0 1-3.85-3.89l.34-62.88a3.867 3.867 0 0 1 3.89-3.85l5.91.08M51.06 7.55l5.9.08c2.14.01 3.86 1.75 3.85 3.89l-.18 33.88"
    />
    <path
      stroke="#9FA2A3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m27.514 21.509-8.84-.048-.048 8.84 8.84.048.048-8.84ZM27.427 37.427l-8.84-.048-.048 8.84 8.84.048.048-8.84ZM27.343 53.08l-8.84-.049-.048 8.84 8.84.048.048-8.84ZM33.2 24.031l18.51.1M33.17 27.95l11.28.07M33.11 39.32l18.52.11M33.09 43.25l11.28.06M33.02 55.563l12.75.07M33 59.492l8.74.05"
    />
    <path
      stroke="#009CDE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21.12 24.92 2.44 2.25 5.51-5.96M21.03 40.679l2.44 2.25 5.51-5.96M40.21 70.04l4.06-9 5.26 5.31-9.32 3.69Z"
    />
    <path
      stroke="#009CDE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m65.36 40.871-21.09 20.17 5.26 5.31 20.91-20.48-5.08-5ZM68.58 37.642l-3.22 3.22 5.08 5 3.42-3.77s1.25-1.43-1.09-3.61c-2.34-2.18-4.19-.84-4.19-.84v0Z"
    />
    <path
      stroke="#9FA2A3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M48.01 2.029c1.7 0 3.07 1.4 3.06 3.1v2.42c-.03 3.02-.03 3.14-.03 3.14l-32.06-.17.02-3.14v-2.68a2.838 2.838 0 0 1 2.85-2.8l26.16.14"
    />
  </svg>
)
export default CheckListIcon
