import { Box, SxProps, Theme } from '@mui/material'
import React from 'react'
import { Styles } from '../../../types'

const styles: Styles = {
  h1: (theme) => ({
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '1.875rem',
    lineHeight: '2.5rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.only('tablet')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.up('tablet')]: {
      lineHeight: '3.5rem',
    },
    [theme.breakpoints.up('desktop')]: {
      fontSize: '3rem',
    },
  }),
  h2: (theme) => ({
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('tablet')]: {
      fontSize: '1.875rem',
      lineHeight: '2.125rem',
    },
  }),
  h3: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('tablet')]: {
      fontFamily: 'Prelo-Light, sans-serif',
    },
  }),
  h4: (theme) => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    color: theme.palette.text.primary,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
  }),
  h5: (theme) => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.875rem',
    letterSpacing: '0.0625rem',
    lineHeight: '1rem',
    color: theme.palette.text.primary,
  }),
  h6: (theme) => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.text.primary,
  }),
}

interface HeadingProps {
  children: React.ReactNode
  variant?: string
  id?: string
  testId?: string
}

const Heading = ({
  children,
  variant = 'h2',
  id = '',
  testId = '',
}: HeadingProps) => {
  const defaultElement = 'h2'
  const defaultStyle = styles.h2

  interface HeadingVariants {
    [key: string]: { element: string; style: SxProps<Theme> }
  }

  const variants: HeadingVariants = {
    h1: { element: 'h1', style: styles.h1 },
    h1WithH2Styling: { element: 'h1', style: styles.h2 },
    h1WithH3Styling: { element: 'h1', style: styles.h3 },
    h1WithH4Styling: { element: 'h1', style: styles.h4 },
    h1WithH5Styling: { element: 'h1', style: styles.h5 },
    h1WithH6Styling: { element: 'h1', style: styles.h6 },
    h2: { element: 'h2', style: styles.h2 },
    h2WithH1Styling: { element: 'h2', style: styles.h1 },
    h2WithH3Styling: { element: 'h2', style: styles.h3 },
    h2WithH4Styling: { element: 'h2', style: styles.h4 },
    h2WithH5Styling: { element: 'h2', style: styles.h5 },
    h2WithH6Styling: { element: 'h2', style: styles.h6 },
    h3: { element: 'h3', style: styles.h3 },
    h3WithH1Styling: { element: 'h3', style: styles.h1 },
    h3WithH2Styling: { element: 'h3', style: styles.h2 },
    h3WithH4Styling: { element: 'h3', style: styles.h4 },
    h3WithH5Styling: { element: 'h3', style: styles.h5 },
    h3WithH6Styling: { element: 'h3', style: styles.h6 },
    h4: { element: 'h4', style: styles.h4 },
    h4WithH1Styling: { element: 'h4', style: styles.h1 },
    h4WithH2Styling: { element: 'h4', style: styles.h2 },
    h4WithH3Styling: { element: 'h4', style: styles.h3 },
    h4WithH5Styling: { element: 'h4', style: styles.h5 },
    h4WithH6Styling: { element: 'h4', style: styles.h6 },
    h5: { element: 'h5', style: styles.h5 },
    h5WithH1Styling: { element: 'h5', style: styles.h1 },
    h5WithH2Styling: { element: 'h5', style: styles.h2 },
    h5WithH3Styling: { element: 'h5', style: styles.h3 },
    h5WithH4Styling: { element: 'h5', style: styles.h4 },
    h5WithH6Styling: { element: 'h5', style: styles.h6 },
    h6: { element: 'h6', style: styles.h6 },
    h6WithH1Styling: { element: 'h6', style: styles.h1 },
    h6WithH2Styling: { element: 'h6', style: styles.h2 },
    h6WithH3Styling: { element: 'h6', style: styles.h3 },
    h6WithH4Styling: { element: 'h6', style: styles.h4 },
    h6WithH5Styling: { element: 'h6', style: styles.h5 },
  }

  const variantConfig = variants[variant]

  return (
    <Box
      component={(variantConfig.element || defaultElement) as React.ElementType}
      sx={variantConfig.style || defaultStyle}
      data-testid={testId ?? ''}
      id={id ?? ''}>
      {children}
    </Box>
  )
}

export default Heading
