import processAdobeAllClicks from './allClicks'
import processAdobeInsightsNavigationClick from './insightsNavigationClick'

const processors = [processAdobeAllClicks, processAdobeInsightsNavigationClick]

const processAdobeAnalytics = (analyticsObject: any) => {
  // iterate over each processor
  processors.forEach((p) => p(analyticsObject))
}

export default processAdobeAnalytics
