import React from 'react'
import { v4 as uuidv4 } from 'uuid'

const ArrowUpIcon = () => {
  const id = uuidv4()
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3661 8.26454L6.26446 13.3661C5.91185 13.7187 5.91185 14.2889 6.26446 14.6378L7.11222 15.4855C7.46483 15.8382 8.03501 15.8382 8.38387 15.4855L12 11.8694L15.6161 15.4855C15.9687 15.8382 16.5389 15.8382 16.8878 15.4855L17.7355 14.6378C18.0882 14.2852 18.0882 13.715 17.7355 13.3661L12.6339 8.26454C12.2888 7.91193 11.7187 7.91193 11.3661 8.26454V8.26454Z"
        fill="#888B8D"
      />
      <mask
        id={`mask0_${id}`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="8"
        width="12"
        height="8">
        <path
          d="M11.3661 8.26454L6.26446 13.3661C5.91185 13.7187 5.91185 14.2889 6.26446 14.6378L7.11222 15.4855C7.46483 15.8382 8.03501 15.8382 8.38387 15.4855L12 11.8694L15.6161 15.4855C15.9687 15.8382 16.5389 15.8382 16.8878 15.4855L17.7355 14.6378C18.0882 14.2852 18.0882 13.715 17.7355 13.3661L12.6339 8.26454C12.2888 7.91193 11.7187 7.91193 11.3661 8.26454V8.26454Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#mask0_${id})`}>
        <rect width="24" height="24" fill="#888B8D" />
      </g>
    </svg>
  )
}

export default ArrowUpIcon
