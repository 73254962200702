import React from 'react'

const HandshakeIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.6792 12.0293L13.8592 13.3793L2.19922 42.6893L5.84922 47.3193"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M65.7695 62.5495C65.7695 62.5495 65.9795 65.9895 63.1495 68.4395C60.3295 70.8995 56.1695 69.7895 56.1695 69.7895L38.2695 51.5195"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.1691 69.7893C56.1691 69.7893 54.2491 71.9993 52.0091 72.8593C49.7891 73.7193 46.2191 72.8593 46.2191 72.8593L32.3691 57.0293"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.2303 72.8598C46.2303 72.8598 40.2803 76.2398 33.5703 72.3398"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.8203 23.6699L72.2403 53.2299C72.2403 53.2299 74.8103 57.2799 72.2403 60.8399C69.6603 64.3899 65.1203 62.3099 65.1203 62.3099L46.9703 44.8899"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.3295 58.2306C22.6695 56.6606 22.2295 54.0806 20.3295 52.4406C18.4295 50.8106 15.7995 50.7506 14.4495 52.3206L11.4195 55.8506C10.0695 57.4206 10.5195 60.0206 12.4195 61.6406C14.3195 63.2706 16.9395 63.3206 18.2895 61.7606L21.3395 58.2306H21.3295Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.3003 52.0509C15.6403 50.4809 15.1903 47.9009 13.3003 46.2609C11.4003 44.6309 8.77025 44.5709 7.42025 46.1409L4.39025 49.6709C3.04025 51.2409 3.49025 53.8409 5.39025 55.4609C7.29025 57.0909 9.91025 57.141 11.2603 55.581L14.3103 52.0509H14.3003Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.5505 64.1104C29.9005 62.5504 29.4505 59.9604 27.5505 58.3304C25.6605 56.6904 23.0205 56.6304 21.6705 58.2004L18.6305 61.7404C17.2805 63.3104 17.7305 65.9003 19.6305 67.5303C21.5305 69.1603 24.1605 69.2204 25.5105 67.6504L28.5505 64.1204V64.1104Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.6198 69.4007C36.8898 67.9307 36.4798 65.5007 34.7198 63.9907C32.9598 62.4607 30.4898 62.4207 29.2298 63.9007L26.3698 67.2307C25.0998 68.7007 25.5098 71.1307 27.2698 72.6507C29.0398 74.1707 31.4998 74.2107 32.7698 72.7307L35.6298 69.4107L35.6198 69.4007Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.8202 23.67L31.6402 34.59C31.6402 34.59 27.5902 38.14 19.9902 34.59L29.9202 13.13L45.2502 7L66.3502 13.13L78.0002 45.63L70.9402 51.97"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default HandshakeIcon
