import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import JsZip from 'jszip' // For zipping files
import streamSaver from '../../../utils/helpers/streamSaver'
import { Styles } from '../../../types'
import api from '../../../api'
import { PaymentData } from '../../../store/invoices/paymentSearchService'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import {
  getSelectedPayments,
  setSelectedPayments,
} from '../../../store/invoices/paymentSelectedPaymentsSlice'
import CustomErrorAlert from '../../forms/Alert/CustomErrorAlert'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../../utils/constants/constants'

const styles: Styles = {
  container: (theme) => ({
    position: 'sticky',
    zIndex: 1200,
    bottom: '0rem',
    left: '0rem',
    marginTop: '2rem',
    padding: '1rem',
    opacity: '92%',
    boxShadow: '0 0.25rem 0.5rem rgba(0,0,0, 0.5)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.only('tablet')]: {
      marginRight: '2rem',
    },
    [theme.breakpoints.down('tablet')]: {
      bottom: '0rem',
      marginRight: '-1rem',
      marginLeft: '-1rem',
    },
  }),
  content: (theme) => ({
    display: 'grid',
    marginLeft: '1rem',
    [theme.breakpoints.down('tablet')]: {
      height: 'fit-content',
      gap: '1.25rem',
      gridTemplateColumns: 'repeat(1, 1fr)',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
  actionButtons: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    '.MuiButton-root': {
      marginRight: '0.5rem',
      marginLeft: '0.5rem',
      [theme.breakpoints.down('tablet')]: {
        paddingRight: '0.75rem',
        paddingLeft: '0.75rem',
      },
      '&:first-of-type': {
        marginLeft: '0rem',
      },
      '&:last-of-type': {
        marginRight: '0rem',
      },
    },
  }),
  clearButton: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    textDecoration: 'underline',
    color: 'white',
  },
  invoicesSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}

type DownloadStatus = 'nofailure' | 'notfound' | 'fail'

const PaymentHistoryActionBar = () => {
  const { isDesktop } = useDeviceType()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedPayments = useSelector(getSelectedPayments)
  const cemFeatures = useSelector(getCemFeatures)
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatus>('nofailure')
  const [open, setOpen] = useState(false)
  const { pathname } = useLocation()

  const handleClose = useCallback(() => {
    setOpen(false)
    setDownloadStatus(t('Invoicing.InvoiceonFailure') as DownloadStatus)
  }, [])

  const deselectAllPayments = () => {
    dispatch(setSelectedPayments([]))
    const clearSelectionFocusTargetElement = document.querySelector(
      '#invoicesTable input',
    ) as HTMLElement
    clearSelectionFocusTargetElement?.focus()
  }

  useEffect(() => {
    deselectAllPayments()
  }, [pathname])

  const downloadPayment = async (payment: PaymentData) => {
    const paymentStatus = payment.paymentStatus.toLowerCase()
    let response
    let fileName = ''

    if (['paid', 'partial', 'complete'].includes(paymentStatus)) {
      if (payment.invoiceNumber !== '') {
        response = await api.finance.invoiceDocument_DownloadPaymentReceipt(
          payment.transactionId,
        )
        fileName = `receipt-${payment.invoiceNumber}.pdf`
      } else {
        response =
          await api.finance.invoiceDocument_DownloadPayonAccountReceipt(
            payment.transactionId,
          )
        fileName = `pay-on-account-receipt-${payment.transactionId}.pdf`
      }
    } else if (paymentStatus === 'scheduled') {
      response =
        await api.finance.invoiceDocument_DownloadScheduledPaymentReceipt(
          payment.transactionId,
        )
      fileName = `scheduled-payment-${payment.transactionId}.pdf`
    } else if (paymentStatus === 'failed') {
      if (payment.invoiceNumber !== '') {
        response =
          await api.finance.invoiceDocument_DownloadFailedPaymentStatement(
            payment.transactionId,
          )
        fileName = `failed-payment-notice-${payment.transactionId}.pdf`
      } else {
        response =
          await api.finance.invoiceDocument_DownloadFailedPayOnAccountStatement(
            payment.transactionId,
          )
        fileName = `failed-pay-on-account-${payment.transactionId}.pdf`
      }
    } else if (paymentStatus === 'cancelled' && payment.invoiceNumber !== '') {
      response =
        await api.finance.invoiceDocument_DownloadCancelledPaymentStatement(
          payment.transactionId,
        )
      fileName = `cancelled-payment-${payment.transactionId}.pdf`
    }
    if (response && response.data instanceof Blob) {
      return { fileName, blob: response.data }
    }
    return null
  }

  const handleDownloadPaymentsAction = async () => {
    let hasSuccess = false
    let hasNotFound = false
    let hasFailures = false

    if (selectedPayments.length === 1) {
      // Single file download
      const payment = selectedPayments[0]
      const result = await downloadPayment(payment)

      if (result) {
        const { fileName, blob } = result
        const fileURL = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = fileURL
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        hasSuccess = true
      } else {
        hasNotFound = true
        setOpen(true)
        setDownloadStatus(t('Invoicing.InvoiceFail') as DownloadStatus)
      }
    } else {
      // Multiple files, zip them
      const downloadPromises = selectedPayments.map(downloadPayment)
      const zip = new JsZip()
      const currentDate = new Date().toISOString().split('T')[0]
      const zipFileName = `Payment transactions-${currentDate}.zip`

      await Promise.allSettled(downloadPromises)
        .then((results) => {
          results.forEach((result) => {
            if (result.status === 'fulfilled' && result.value) {
              const { fileName, blob } = result.value
              zip.file(fileName, blob)
              hasSuccess = true
            } else {
              hasNotFound = true
            }
          })

          if (hasSuccess) {
            zip
              .generateAsync({ type: 'blob' })
              .then((zipFile: Blob) => {
                const fileStream = streamSaver.createWriteStream(zipFileName)
                const readableStream =
                  zipFile.stream() as unknown as ReadableStream<Uint8Array>
                readableStream.pipeTo(fileStream)
              })
              .catch((zipError) => {
                console.error('Error generating ZIP blob:', zipError)
                hasFailures = true
                setOpen(true)
                setDownloadStatus(t('Invoicing.InvoiceFail') as DownloadStatus)
              })
          }
        })
        .catch((error) => {
          console.error('Error during file download or processing:', error)
          hasFailures = true
          setOpen(true)
          setDownloadStatus(t('Invoicing.InvoiceFail') as DownloadStatus)
        })
    }
    if (hasNotFound) {
      setDownloadStatus(t('Invoicing.InvoiceNotFound') as DownloadStatus)
      setOpen(true)
    } else if (hasFailures) {
      setDownloadStatus(t('Invoicing.InvoiceFail') as DownloadStatus)
      setOpen(true)
    }
  }

  return (
    <>
      <Box data-testid="errorAlert">
        {downloadStatus === 'notfound' && (
          <CustomErrorAlert
            testId="Banner_InvoicesActionBar_DownloadErrorMessageForNotFound"
            header="Invoicing.DownloadError"
            open={open}
            close={handleClose}
            message="Invoicing.DownloadErrorMessageForNotFound"
          />
        )}
        {downloadStatus === 'fail' && (
          <CustomErrorAlert
            testId="Banner_InvoicesActionBar_DownloadErrorMessage"
            header="Invoicing.DownloadError"
            open={open}
            close={handleClose}
            message="Invoicing.DownloadErrorMessage"
          />
        )}
      </Box>
      <Box
        hidden={selectedPayments.length < 1}
        role="status"
        style={selectedPayments.length < 1 ? visuallyHidden : undefined}
        sx={styles.container}>
        {selectedPayments.length > 0 && (
          <Box sx={styles.content}>
            <Box sx={styles.invoicesSelected}>
              {`${selectedPayments.length} ${
                selectedPayments.length === 1
                  ? t('Invoicing.InvoiceSelected').toLowerCase()
                  : t('Invoicing.InvoicesSelected').toLowerCase()
              }\u00A0\u00A0`}
              {isDesktop && '|\u00A0\u00A0'}
              <Button
                variant="text"
                sx={styles.clearButton}
                data-testid="Btn_InvoicesPage_ClearSelection"
                onClick={deselectAllPayments}>
                {isDesktop
                  ? t('Invoicing.ClearSelection')
                  : t('Invoicing.Clear')}
              </Button>
            </Box>
            <Box sx={styles.actionButtons}>
              {isCemFeatureEnabled(
                [
                  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
                  CEM_FEATURE_PAYMENT_PAY_INVOICES,
                ],
                cemFeatures,
                'any',
              ) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  data-testid="Btn_PaymentTransaction_Download"
                  onClick={handleDownloadPaymentsAction}>
                  {t('Invoicing.Download')}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}

export default PaymentHistoryActionBar
