import React from 'react'

const EllipsisIcon = () => (
  <svg
    width="4"
    height="18"
    viewBox="0 0 4 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.97083 2C3.97083 3.10457 3.08193 4 1.98541 4C0.888899 4 0 3.10457 0 2C0 0.89543 0.888899 0 1.98541 0C3.08193 0 3.97083 0.89543 3.97083 2Z"
      fill="#888B8D"
    />
    <path
      d="M3.97083 9C3.97083 10.1046 3.08193 11 1.98541 11C0.888899 11 0 10.1046 0 9C0 7.89543 0.888899 7 1.98541 7C3.08193 7 3.97083 7.89543 3.97083 9Z"
      fill="#888B8D"
    />
    <path
      d="M3.97083 16C3.97083 17.1046 3.08193 18 1.98541 18C0.888899 18 0 17.1046 0 16C0 14.8954 0.888899 14 1.98541 14C3.08193 14 3.97083 14.8954 3.97083 16Z"
      fill="#888B8D"
    />
  </svg>
)

export default EllipsisIcon
