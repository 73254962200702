// Business Logic: A modal should warn that a user has been idle 15 minutes before the configurable BrowserActivityTimeout
export const idleMinutesUntilTimeout = 15

// Determines how long until an inactivity timeout modal should appear base
export function calculateIdleMinutesUntilIdle(
  browserTimeoutTotal: number,
  idleMinUntilTimeout: number,
): number {
  // This line of code safeguards in case the browserTimeoutTotal is less than 5 minutes before the idleMinutesUntilTimeout value
  if (browserTimeoutTotal < idleMinUntilTimeout + 5) {
    return idleMinUntilTimeout + 5
  }
  return browserTimeoutTotal - idleMinUntilTimeout
}
