import React from 'react'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowLeftIcon from '../../../components/icons/ArrowLeftIcon'
import useStyles from './BackButton.styles'

interface IProps {
  linkToRedirect: string
  children: React.ReactNode
}

const BackButton = ({ linkToRedirect, children }: IProps) => {
  const styles = useStyles()

  return (
    <Box sx={styles.root} data-testid="header-link">
      <Link to={linkToRedirect} data-testid="btn-left">
        <ArrowLeftIcon />
        <span>{children}</span>
      </Link>
    </Box>
  )
}

export default BackButton
