import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'
import { PaymentData } from './paymentSearchService'

// Define a type for the slice state
interface PaymentsSelectedState {
  selectedPayments: PaymentData[]
}

// Define the initial state using that type
const initialState: PaymentsSelectedState = {
  selectedPayments: [] as PaymentData[],
}

export const paymentSelectedPaymentsSlice = createSlice({
  name: 'paymentSelectedPayments',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSelectedPayments: (state, { payload }) => {
      state.selectedPayments = payload
    },
  },
})

export const { setSelectedPayments } = paymentSelectedPaymentsSlice.actions
export const getSelectedPayments = (state: RootState) =>
  state.paymentSelectedPayments.selectedPayments

export default paymentSelectedPaymentsSlice.reducer
