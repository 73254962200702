import React from 'react'

const PDF = () => (
  <svg
    aria-hidden="true"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="5" y="12" width="13" height="7" rx="0.8" fill="#E40046" />
    <path
      d="M8.09087 15.3674H8.69951C8.99102 15.3674 9.13603 15.2231 9.13457 14.9346C9.13457 14.646 8.98955 14.5017 8.69951 14.5017H8.09087V15.3674ZM8.09087 15.9299V17H7.46025V13.9392H8.76543C9.0833 13.9392 9.33232 14.0293 9.5125 14.2095C9.69268 14.3896 9.78276 14.6321 9.78276 14.9368C9.78276 15.2415 9.69268 15.4832 9.5125 15.6619C9.33232 15.8406 9.0833 15.9299 8.76543 15.9299H8.09087ZM11.0452 16.4375H11.5593C11.8289 16.4375 12.012 16.3562 12.1086 16.1936C12.2068 16.0295 12.2559 15.7827 12.2559 15.4531C12.2559 15.1221 12.2075 14.8811 12.1108 14.7302C12.0156 14.5779 11.8318 14.5017 11.5593 14.5017H11.0452V16.4375ZM10.4146 13.9392H11.5593C12.0706 13.9392 12.4302 14.0828 12.6382 14.3699C12.8257 14.6292 12.9187 14.988 12.9172 15.4465C12.9172 16.2039 12.6829 16.6829 12.2141 16.8835C12.0325 16.9612 11.8142 17 11.5593 17H10.4146V13.9392ZM15.6276 14.5017H14.228V15.2268H15.4255V15.7893H14.228V17H13.5974V13.9392H15.6276V14.5017Z"
      fill="white"
    />
  </svg>
)

export default PDF
