import { Box, Typography } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  useSetTaxonomyPreferencesMutation,
  useGetTaxonomyPreferencesQuery,
} from '../../../store/cmsService'
import { getAppliedTagFilters } from '../../../store/insights/insightsSlice'
import { Styles } from '../../../types'
import NoSearchResultIcon from '../../icons/NoSearchResultIcon'
import NoInterests from '../../Profile/Interests/NoInterests'
import ProfileModal from '../../Profile/ProfileModal'

const styles: Styles = {
  container: (theme) => ({
    marginTop: '0rem',
    marginBottom: '1.5rem',
    [theme.breakpoints.up('tablet')]: {
      marginTop: '4.5rem',
      marginBottom: '4.5rem',
    },
  }),
  noResultsIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  noResultsMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  noResultsMessage: (theme) => ({
    color: theme.palette.common.black,
    fontFamily: 'Prelo-Light, sans-serif',
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('tablet')]: {
      paddingTop: '0.5rem',
      paddingBottom: '1rem',
    },
  }),
  adviceMessage: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.125rem',
    textAlign: 'center',
  },
}

interface ArticleListNoSearchResultsProps {
  filter: string
}

const ArticleListNoSearchResults = ({
  filter,
}: ArticleListNoSearchResultsProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const filterDataState = useSelector(getAppliedTagFilters)
  const getTaxonomyPreferences = useGetTaxonomyPreferencesQuery()
  const [setTaxonomyPreferences] = useSetTaxonomyPreferencesMutation()

  const isMyInterestSelected = useMemo(() => {
    if (
      isFeatureFlagEnabled('UserProfile') &&
      filterDataState.get('yourinsights')?.has('My Interests')
    ) {
      return true
    }
    return false
  }, [filterDataState])

  /// below code if business logic changes to show popup only on empty preferences
  // const isMyInterestAndNoUserPreferences = useMemo(() => getTaxonomyPreferences.data?.data.length === 0 && isMyInterestSelected
  //   , [getTaxonomyPreferences.data?.data, filterDataState])

  const saveAllAndCloseDialog = useCallback(
    (data: number[]) => {
      setOpen(false)
      setTaxonomyPreferences(data)
        .unwrap()
        .then(() => {
          navigate(`/insights?tkey=${new Date().getTime()}`)
        })
    },
    [setTaxonomyPreferences, setOpen, navigate],
  )

  return (
    <Box sx={styles.container} data-testid="noSearchResultsFoundContainer">
      {isMyInterestSelected && filter.length === 0 ? (
        <>
          <NoInterests openModal={() => setOpen(true)} />
          <ProfileModal
            open={open}
            defaultValue={
              getTaxonomyPreferences.data?.data.map((r) => r.id) ?? []
            }
            skipProfile
            onSubmit={saveAllAndCloseDialog}
            onClose={() => setOpen(false)}
          />
        </>
      ) : (
        <>
          <Box aria-hidden="true" sx={styles.noResultsIcon}>
            <NoSearchResultIcon />
          </Box>
          <Box sx={styles.noResultsMessageContainer}>
            <Typography
              variant="h2"
              sx={styles.noResultsMessage}
              data-testid="Lbl_Insight_NoSearchResults">
              {t('InsightsList.NoSearchResults')}
            </Typography>
          </Box>
          <Box sx={styles.adviceMessage}>
            {t('InsightsList.NoSearchResultsAdvice')}
          </Box>
        </>
      )}
    </Box>
  )
}

export default ArticleListNoSearchResults
