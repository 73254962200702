import { styled } from '@mui/material'

const HiddenSpanForSR = styled('span')({
  position: 'absolute',
  left: '-10000px',
  top: 'auto',
  width: '1px',
  height: '1px',
  overflow: 'hidden',
})

export default HiddenSpanForSR
