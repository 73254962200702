import React from 'react'

const AutomobileIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2806 57.0501C17.2806 57.0501 17.7006 63.2101 16.0806 63.6401C14.2306 64.1301 7.10062 64.1501 5.72062 63.4701C4.34062 62.7901 3.89062 55.8301 3.89062 55.8301"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.1896 57.0502C61.1896 57.0502 60.7696 63.2102 62.3896 63.6402C64.2396 64.1302 71.3696 64.1502 72.7496 63.4702C74.1296 62.7902 73.8596 55.2402 73.8596 55.2402"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5996 24.7707C10.5996 24.7707 14.5796 12.1207 17.8296 10.9907C21.0696 9.86074 59.1496 9.06074 61.4696 11.6107C63.7896 14.1607 67.8396 24.7707 67.8396 24.7707"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5992 24.7695C10.5992 24.7695 7.55921 22.6195 4.45921 23.9595C2.32921 24.8795 1.30921 27.7295 3.07921 29.9295C4.92921 32.2295 7.43921 31.4795 7.43921 31.4795C7.43921 31.4795 4.14921 35.7495 4.10921 38.5495C4.06921 41.3595 3.88921 55.8295 3.88921 55.8295C26.9092 58.8195 68.2592 57.9695 73.8492 55.2295V36.2295L71.0692 31.3595C71.0692 31.3595 75.1192 31.9395 76.2792 29.3895C77.4392 26.8395 75.8192 23.4795 72.3392 23.4795C68.8592 23.4795 67.8192 24.7495 67.8192 24.7495C52.9892 22.0995 27.5892 21.4695 10.5892 24.7495L10.5992 24.7695Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11 38.5605C4.11 38.5605 17.01 38.7505 18.06 40.6105C18.96 42.2005 18.23 46.4705 16.11 47.0005C14.16 47.4905 4 46.8805 4 46.8805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5996 40.1191H54.7396"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3203 48.0605H53.0003"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.7693 38.5605C73.7693 38.5605 60.8693 38.7505 59.8193 40.6105C58.9193 42.2005 59.6493 46.4705 61.7693 47.0005C63.7193 47.4905 73.8793 46.8805 73.8793 46.8805"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AutomobileIcon
