import React from 'react'

const LessThanIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.59 0L8 1.41L3.42 6L8 10.59L6.59 12L0.59 6L6.59 0Z"
      fill="currentColor"
    />
  </svg>
)

export default LessThanIcon
