import { AuditActionEnum } from '@rsmus/ecp-userservice'
import { subscribe } from './PubSub.service'
import api from '../../api'

export const AuditLoginTopic = 'AuditLogin'

export const subscribeToAuditTopics = () => {
  const unsubscribeFunctions: (() => void)[] = []

  unsubscribeFunctions.push(
    subscribe(AuditLoginTopic, async (action: AuditActionEnum) => {
      await api.user.audit_AddAuditAction({
        action,
      })
    }),
  )

  return () => {
    unsubscribeFunctions.forEach((fn) => fn())
  }
}
