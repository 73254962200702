import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import { getActiveEngagement } from '../../../store/engagements/engagementSlice'
import { useAppSelector } from '../../../utils/hooks'
import SubMenu from '../../layouts/SubMenu'
import RouterNavListItem from '../../Navigation/NavListItem/RouterNavListItem'

type EngagementMenuProps = {
  currentItem?: string
}

const EngagementMenu = ({ currentItem = '' }: EngagementMenuProps) => {
  const title = useSelector(getActiveEngagement)?.name
  const { t } = useTranslation()
  const { engagementId = '' } = useParams()
  const encodedEngagementId = window.encodeURIComponent(engagementId)
  const isEmployee = useAppSelector(
    (state) => state.userInfo.UserInfo.isEmployee,
  )

  return (
    <SubMenu currentItem={currentItem} title={title}>
      <RouterNavListItem
        to={`/engagements/${encodedEngagementId}`}
        value="dashboard"
        testId="Lnk_Navigation_Dashboard">
        {t('Navigation.Dashboard')}
      </RouterNavListItem>

      {isEmployee && isFeatureFlagEnabled('DocumentRequest') && (
        <RouterNavListItem
          to={`/engagements/${encodedEngagementId}/docs`}
          value="documents"
          testId="Lnk_Navigation_Documents">
          {t('Navigation.Documents')}
        </RouterNavListItem>
      )}

      {isFeatureFlagEnabled('DocumentRequest') && (
        <RouterNavListItem
          to={`/engagements/${encodedEngagementId}/requests`}
          value="requests"
          testId="Lnk_Navigation_DocumentRequests">
          {t('Navigation.DocumentRequests')}
        </RouterNavListItem>
      )}
    </SubMenu>
  )
}

export default EngagementMenu
