import React from 'react'

const DocumentCheckedIcon = () => (
  <svg
    width="42"
    height="44"
    viewBox="0 0 42 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8252 39.2862H36.2252V2.0957H6.8252V39.2862Z"
      stroke="#009CDE"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8252 39.2862H36.2252V2.0957H6.8252V39.2862Z"
      stroke="#009CDE"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2998 39.81H36.2248V2.0957H6.2998V39.81Z"
      stroke="#009CDE"
      strokeLinecap="round"
    />
    <path d="M9.97461 6.2865H26.2496" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 10.4769H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 20.4291H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 12.5717H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 22.5238H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 14.6674H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 24.6195H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 16.7621H24.1496" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 26.7152H19.9496" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 30.9056H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <path d="M9.97461 33.0004H32.0246" stroke="#009CDE" strokeLinecap="round" />
    <ellipse cx="33.5998" cy="29.3336" rx="6.3" ry="6.28572" fill="white" />
    <path
      d="M26.6006 29.6824C26.6006 25.8356 29.7178 22.6982 33.6006 22.6982C37.4561 22.6982 40.6006 25.8356 40.6006 29.6824C40.6006 33.5564 37.4561 36.6665 33.6006 36.6665C29.7178 36.6665 26.6006 33.5564 26.6006 29.6824ZM36.7451 28.482C37.0459 28.1819 37.0459 27.7181 36.7451 27.418C36.4443 27.1179 35.9795 27.1179 35.6787 27.418L32.7256 30.3644L31.4951 29.164C31.1943 28.8639 30.7295 28.8639 30.4287 29.164C30.1279 29.4641 30.1279 29.9279 30.4287 30.228L32.1787 31.974C32.4795 32.2741 32.9443 32.2741 33.2451 31.974L36.7451 28.482Z"
      fill="#3F9C35"
    />
  </svg>
)

export default DocumentCheckedIcon
