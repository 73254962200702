import React from 'react'

const NoSearchResultIcon = () => (
  <svg
    width="161"
    height="160"
    viewBox="0 0 161 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M33.5 32V52" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M65.5 32V52" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.0936 52.6334V52.6334C45.8726 52.6334 43.2568 50.0074 43.2568 46.7624V37.7082C43.2568 34.4683 45.8726 31.8423 49.0936 31.8423V31.8423C52.3146 31.8423 54.9252 34.4683 54.9252 37.7082V46.7675C54.9252 50.0074 52.3146 52.6334 49.0936 52.6334Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M97.7066 31.8406V52.6317" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.5032 52.6334V52.6334C78.2823 52.6334 75.6665 50.0074 75.6665 46.7675V37.7082C75.6665 34.4683 78.2823 31.8423 81.5032 31.8423V31.8423C84.7242 31.8423 87.3349 34.4683 87.3349 37.7082V46.7675C87.3349 50.0074 84.7242 52.6334 81.5032 52.6334Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M130.115 31.8406V52.6317" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M113.911 52.6334V52.6334C110.69 52.6334 108.075 50.0074 108.075 46.7624V37.7082C108.075 34.4683 110.69 31.8423 113.911 31.8423V31.8423C117.132 31.8423 119.743 34.4683 119.743 37.7082V46.7675C119.743 50.0074 117.132 52.6334 113.911 52.6334Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.8895 82.474V82.474C29.6685 82.474 27.0527 79.848 27.0527 76.6029V67.5487C27.0527 64.3088 29.6685 61.6829 32.8895 61.6829V61.6829C36.1105 61.6829 38.7211 64.3088 38.7211 67.5487V76.6081C38.7211 79.848 36.1105 82.474 32.8895 82.474Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.893 82.474V82.474C127.672 82.474 125.057 79.848 125.057 76.6029V67.5487C125.057 64.3088 127.672 61.6829 130.893 61.6829V61.6829C134.114 61.6829 136.725 64.3088 136.725 67.5487V76.6081C136.725 79.848 134.114 82.474 130.893 82.474Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M32.8882 91.522V112.313" stroke="#CCCCCC" strokeWidth="2" />
    <path d="M59.4756 79.7866V108.048" stroke="#009CDE" strokeWidth="2" />
    <path d="M103.528 79.7866V108.048" stroke="#009CDE" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.5021 108.05V108.05C77.122 108.05 73.5728 104.48 73.5728 100.074V87.7648C73.5728 83.3589 77.122 79.7888 81.5021 79.7888V79.7888C85.8822 79.7888 89.4314 83.3589 89.4314 87.7648V100.074C89.4314 104.48 85.8822 108.05 81.5021 108.05Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path d="M49.0928 2V22.7911" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.8909 22.7921V22.7921C29.6648 22.7921 27.0542 20.1661 27.0542 16.9262V7.86686C27.0542 4.62695 29.6648 2.00098 32.8909 2.00098V2.00098C36.1119 2.00098 38.7226 4.62695 38.7226 7.86686V16.9262C38.7226 20.1661 36.1119 22.7921 32.8909 22.7921Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.2991 22.7921V22.7921C62.073 22.7921 59.4624 20.1661 59.4624 16.9262V7.86686C59.4624 4.62695 62.073 2.00098 65.2991 2.00098V2.00098C68.5201 2.00098 71.1308 4.62695 71.1308 7.86686V16.9262C71.1308 20.1661 68.5201 22.7921 65.2991 22.7921Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M81.502 2V22.7911" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M97.7071 22.7921V22.7921C94.4861 22.7921 91.8755 20.1661 91.8755 16.9262V7.86686C91.8755 4.62695 94.4861 2.00098 97.7071 2.00098V2.00098C100.928 2.00098 103.539 4.62695 103.539 7.86686V16.9262C103.539 20.1661 100.928 22.7921 97.7071 22.7921Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M114.688 2V22.7911" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.894 22.7921V22.7921C127.668 22.7921 125.058 20.1661 125.058 16.9262V7.86686C125.058 4.62695 127.668 2.00098 130.894 2.00098V2.00098C134.115 2.00098 136.726 4.62695 136.726 7.86686V16.9262C136.726 20.1661 134.115 22.7921 130.894 22.7921Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.668 52.6334V52.6334C143.447 52.6334 140.832 50.0074 140.832 46.7624V37.7082C140.832 34.4683 143.447 31.8423 146.668 31.8423V31.8423C149.889 31.8423 152.5 34.4683 152.5 37.7082V46.7675C152.5 50.0074 149.889 52.6334 146.668 52.6334Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M147.445 61.6814V82.4725" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146.668 112.315V112.315C143.447 112.315 140.836 109.689 140.836 106.449V97.3896C140.836 94.1497 143.447 91.5237 146.668 91.5237V91.5237C149.889 91.5237 152.499 94.1497 152.499 97.3896V106.449C152.499 109.689 149.889 112.315 146.668 112.315Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M147.445 2V22.7911" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3367 52.6334V52.6334C13.1158 52.6334 10.5 50.0074 10.5 46.7624V37.7082C10.5 34.4683 13.1158 31.8423 16.3367 31.8423V31.8423C19.5577 31.8423 22.1684 34.4683 22.1684 37.7082V46.7675C22.1684 50.0074 19.5577 52.6334 16.3367 52.6334Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M16.335 61.6814V82.4725" stroke="#CCCCCC" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3355 112.315V112.315C13.1145 112.315 10.5039 109.689 10.5039 106.449V97.3896C10.5039 94.1497 13.1145 91.5237 16.3355 91.5237V91.5237C19.5565 91.5237 22.1671 94.1497 22.1671 97.3896V106.449C22.1671 109.689 19.5565 112.315 16.3355 112.315Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path d="M16.335 2V22.7911" stroke="#CCCCCC" strokeWidth="2" />
    <path
      d="M60.5986 67.0818C66.3379 62.7431 73.4723 60.1738 81.2067 60.1738C100.174 60.1738 115.55 75.6408 115.55 94.7191C115.55 113.792 100.174 129.259 81.2067 129.259C62.2399 129.259 46.8633 113.792 46.8633 94.7191C46.8633 84.762 51.0485 75.7955 57.7418 69.4911"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path d="M59.9397 121.83L31.5664 158" stroke="#009CDE" strokeWidth="2" />
    <path d="M130.893 91.522V112.313" stroke="#CCCCCC" strokeWidth="2" />
  </svg>
)

export default NoSearchResultIcon
