import { v4 as uuidv4 } from 'uuid'
import Queue from './queue'

interface Topics {
  [topic: string]: Topic
}

interface Topic {
  [id: string]: ((data: any) => Promise<void>) | null
}

const topics: Topics = {}
const queue = new Queue<number>()

/**
 * @param {string} topic - The topic or category to subscribe to.
 * @param {(data: any) => Promise<void>} fn - Callback function that will be called for each event and should return a Promise that is resolved once the subscriber is finished processing the event.
 * @returns {() => void} - A function to call to unsubscribe from the specified topic.
 */
export function subscribe(
  topic: string,
  fn: (data: any) => Promise<void>,
): () => void {
  if (!topics[topic]) {
    topics[topic] = {}
  }
  const id = uuidv4()
  topics[topic][id] = fn

  return () => {
    topics[topic][id] = null
    delete topics[topic][id]
  }
}

/**
 * @param {string} topic - The topic or category to publish the event to.
 * @param {any} args - Any arguments to pass to each subscriber.
 * @returns {Promise<number>} - A promise that resolves with the number of subscribers once all subscribers have finished processing this event.
 */
export function publish(topic: string, args: any): Promise<number> {
  if (!topics[topic]) return Promise.resolve(0)
  return queue.enqueue(
    () =>
      new Promise<number>((resolve, reject) => {
        const subscriberPromises = Object.values(topics[topic]).map((fn) => {
          if (fn) {
            return fn(args)
          }
          return Promise.resolve()
        })
        Promise.allSettled(subscriberPromises)
          .then(() => {
            resolve(subscriberPromises.length || 0)
          })
          .catch((reason) => {
            reject(reason)
          })
      }),
  )
}
