import React, { useEffect, useState } from 'react'
import TeamMembers from './TeamMembers'
import { getTeamMembers, TeamMember } from './TeamMembers.service'

const TeamMembersWrapper = (props: { engagementId: string }) => {
  const { engagementId } = props
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([])

  const init = async () => {
    setTeamMembers([...(await getTeamMembers(engagementId))])
  }

  useEffect(() => {
    init()
  }, [])

  return <TeamMembers engagementUsers={teamMembers} />
}

export default TeamMembersWrapper
