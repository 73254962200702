import PieChartItem from './PieChartItem'

// Pie chart properties.
// A unique id for the chart, and an array of chart items (pie slices)
type PieChartProps = {
  chartId: string
  chartTitle: string
  chartItems: PieChartItem[]
}

export const getChartMetricValueGrandTotal = (
  chartProps: PieChartProps,
): number => {
  let grandTotal = 0
  if (undefined !== chartProps) {
    chartProps.chartItems.forEach((chartItem: PieChartItem) => {
      grandTotal += chartItem.metricValue
    })
  }
  return grandTotal
}

export const assignMetricPercentages = (
  chartProps: PieChartProps,
): PieChartProps => {
  const grandTotal = getChartMetricValueGrandTotal(chartProps)
  const propsItemsCopy: PieChartItem[] = []
  chartProps.chartItems.forEach((item) => {
    propsItemsCopy.push({
      metricName: item.metricName,
      metricInternationalizationKey: item.metricInternationalizationKey,
      metricValue: item.metricValue,
      metricColor: item.metricColor,
      metricPercent:
        grandTotal === 0
          ? 0
          : +((item.metricValue / grandTotal) * 100).toFixed(0),
    })
  })
  const propsCopy: PieChartProps = {
    chartId: chartProps.chartId,
    chartTitle: chartProps.chartTitle,
    chartItems: propsItemsCopy,
  }
  return propsCopy
}

export default PieChartProps
