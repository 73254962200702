/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react'
import { Select, SelectProps } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Controller, useForm } from 'react-hook-form'
import { Styles } from '../../types'

export interface DropdownObject {
  display: string
  value: string
}

export interface DropdownProps extends Omit<SelectProps, 'native'> {
  data: DropdownObject[]
  defaultSelection: DropdownObject
  dropdownName: string
  ariaLabel: string
  onCompleteFunction?: (dropdownValue: string | unknown) => void
  onKeyDownFunction?: (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
}

const styles: Styles = {
  dropdown: (theme) => ({
    fontFamily: 'Prelo-Book, sans-serif',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '.MuiSvgIcon-root': {
      fill: theme.palette.secondary.main,
    },
  }),
}

const Dropdown = ({
  data,
  defaultSelection,
  dropdownName,
  ariaLabel,
  onCompleteFunction = () => null,
  onKeyDownFunction = () => null,
  ...rest
}: DropdownProps) => {
  const { control, setValue } = useForm()

  useEffect(() => {
    setValue(dropdownName, defaultSelection.value)
  }, [defaultSelection])

  return (
    <Controller
      name={dropdownName}
      control={control}
      defaultValue={defaultSelection.value}
      render={({ field: { onChange, value } }) => (
        <Select
          {...rest}
          native
          name={dropdownName}
          id={dropdownName}
          sx={styles.dropdown}
          value={value}
          onChange={(e) => {
            onChange(e)
            if (onCompleteFunction) {
              onCompleteFunction(e.target.value)
            }
          }}
          onKeyDown={(e) => {
            if (onKeyDownFunction) {
              onKeyDownFunction(e)
            }
          }}
          fullWidth
          IconComponent={KeyboardArrowDownIcon}
          inputProps={{ 'aria-label': ariaLabel }}>
          {data?.map((item) => (
            <option key={item.value} value={item.value}>
              {item.display}
            </option>
          ))}
        </Select>
      )}
    />
  )
}

export default Dropdown
