import { groupBy, lowerCase } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Filters from '../../rsmCoreComponents/components/Filters/Filters'
import { FilterGroupExpandedStateDictionary } from '../../rsmCoreComponents/components/FiltersGroup/FiltersGroup'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import { isFeatureFlagEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import { useGetTaxonomyPreferencesQuery } from '../../store/cmsService'
import {
  getAppliedTagFilters,
  getAvailableTagFilters,
  InsightsFilterObj,
  setAppliedTagFilters,
} from '../../store/insights/insightsSlice'
import { hasSharedWithMeArticles } from '../../utils/helpers/Article.service'
import {
  formatTagString,
  revertFormattedTagString,
} from '../../utils/helpers/formatTags.service'
import FilterChips from '../FilterChips'
import { FilterChipData } from '../FilterChips/FilterChips'
import SearchLayout from '../layouts/SearchLayout'
import InsightsResults from './InsightsSubcomponents/InsightsBody'
import InsightsSearch from './InsightsSubcomponents/InsightsHeader'

const ReloadPreferencesKey = 'tkey'
const filterTitleTranslationKeyPrefix = 'InsightsList.FilterHeaders'

const YourInsights = 'yourinsights'
const SharedWithMeQS = 'shared-with-me'

const InsightsLayout = () => {
  const availableTagFilters = useSelector(getAvailableTagFilters)
  const [filter, setFilter] = useState<string>('')
  const taxonomyPreferences = useGetTaxonomyPreferencesQuery()

  const filterDataState = useSelector(getAppliedTagFilters)
  const [filterGroupExpanded, setFilterGroupExpanded] =
    useState<FilterGroupExpandedStateDictionary>({})
  const [pageNumber, setPageNumber] = useState<number>(1)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useDeviceType()
  const { t } = useTranslation()
  const myInterestsLabel = t('InsightsList.FilterLabels.myInterestsLabel')
  const sharedWithMeLabel = t('InsightsList.FilterLabels.sharedWithMe')
  const hiddenFilters = [
    t('InsightsList.FilterLabels.all').toUpperCase(),
    t('InsightsList.FilterLabels.myInterestsLabel').toUpperCase(),
  ]

  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [showSharedwithMe, setShowSharedwithMe] = useState(false)
  const [sharedWithMeDataFetched, setSharedWithMeDataFetched] = useState(false)
  const [hasSharedWithMe, setHasSharedWithMe] = useState(false)
  const reloadPreferencesKey = searchParams.get(ReloadPreferencesKey)

  const isMyInterestSelected = useMemo(() => {
    if (filterDataState.get(YourInsights)?.has(myInterestsLabel)) return true
    return false
  }, [filterDataState])

  const isSharedWithMeSelected = useMemo(() => {
    if (filterDataState.get(YourInsights)?.has(sharedWithMeLabel)) return true
    return false
  }, [filterDataState])

  // Any change to filters need to go thru here to avoid race conditions
  const dispatchAppliedTagFilters = useCallback(
    (payload: any) => {
      setLoading(true)
      dispatch(setAppliedTagFilters(payload))
      setLoading(false)
    },
    [setLoading, dispatch, setAppliedTagFilters],
  )

  const sharedwithMeHandler = async () => {
    if (searchParams.has(SharedWithMeQS)) {
      setShowSharedwithMe(true)
      searchParams.delete(SharedWithMeQS)
      setSearchParams(searchParams)
    }
    if (isSharedWithMeSelected && !showSharedwithMe) {
      dispatchAppliedTagFilters(new Map())
    }
    const isSharedWithMe = await hasSharedWithMeArticles()
    setHasSharedWithMe(isSharedWithMe)
    setSharedWithMeDataFetched(true)
  }

  const expandSelectedFilters = () => {
    if (
      isFeatureFlagEnabled('InsightArticle_ClickableTags') &&
      filterDataState.size > 0
    ) {
      const categoriesWithFilters = new Set<string>()
      filterDataState.forEach(
        (
          setOfTags: Set<InsightsFilterObj[keyof InsightsFilterObj]>,
          category: keyof InsightsFilterObj,
        ) => {
          categoriesWithFilters.add(category)
        },
      )
      const updated = { ...filterGroupExpanded }
      categoriesWithFilters.forEach((group: string) => {
        updated[`${filterTitleTranslationKeyPrefix}.${group}`] = true
        setFilterGroupExpanded(updated)
      })
    }
  }

  const formatTag = (tag: string) => formatTagString(tag).toUpperCase()

  const filterEntries = Array.from(filterDataState.entries())

  const revertCategoryFormatting = (formattedCategory: string) =>
    formattedCategory.toLowerCase().replace(' ', '')

  const revertTagFormatting = (formattedTag: string) =>
    revertFormattedTagString(formattedTag).toLowerCase()

  const handleClearAll = () => {
    dispatchAppliedTagFilters(new Map())
  }

  const handleFilterDeleted = (chipData: FilterChipData<InsightsFilterObj>) => {
    const map = new Map<
      keyof InsightsFilterObj,
      Set<InsightsFilterObj[keyof InsightsFilterObj]>
    >()

    const chipDataCopy = { ...chipData }
    chipDataCopy.property = revertCategoryFormatting(
      chipDataCopy.property,
    ) as keyof InsightsFilterObj
    chipDataCopy.value = revertTagFormatting(chipDataCopy.value)

    const existingMapEntries = Array.from(filterDataState.entries())
    existingMapEntries.forEach((existingMapEntry) => {
      const [existingProp, existingSet] = existingMapEntry
      const existingValues = Array.from(existingSet.values())

      if (existingProp !== chipDataCopy.property) {
        map.set(existingProp, new Set([...existingValues]))
      } else {
        const existingExceptDeleted = existingValues.filter(
          (x) => `${x}` !== chipDataCopy.value,
        )
        if (existingExceptDeleted.length > 0) {
          map.set(existingProp, new Set([...existingExceptDeleted]))
        }
      }
    })

    // if filters are changed and yourinsights === my interests then set to all insights
    if (map.get(YourInsights)?.values().next().value === 'My Interests') {
      const value = new Set(map?.get(YourInsights))
      value.add('All Insights')
      value.delete('My Interests')
      map.set(YourInsights, value)
    }

    dispatchAppliedTagFilters(map)
  }

  const onLoadMore = useCallback(() => {
    setPageNumber(pageNumber + 1)
  }, [setPageNumber, pageNumber])

  const filterChipData = filterEntries
    .map((entry) => {
      const [key, set] = entry
      const valueArray = Array.from(set.values())
      return valueArray.map((tag) => ({
        property: key as keyof InsightsFilterObj,
        value: formatTag(tag),
      }))
    })
    .flat()

  const setInterestsFilter = () => {
    const filters = new Map().set(YourInsights, new Set([myInterestsLabel]))
    Object.entries(
      groupBy(taxonomyPreferences.data?.data, (item) => item.category),
    ).forEach(([key, val]) => {
      filters.set(
        lowerCase(key),
        new Set(val.map((item) => item.tag?.split('/').at(-1)) ?? []),
      )
    })
    dispatchAppliedTagFilters(filters)
  }

  const setSharedFilter = () => {
    dispatchAppliedTagFilters(
      new Map().set(YourInsights, new Set([sharedWithMeLabel])),
    )
  }

  useEffect(() => {
    sharedwithMeHandler()
    expandSelectedFilters()
  }, [])

  useEffect(() => {
    if (reloadPreferencesKey) {
      searchParams.delete(ReloadPreferencesKey)
      setSearchParams(searchParams)
      navigate(0)
    }
  }, [reloadPreferencesKey])

  useEffect(() => {
    if (sharedWithMeDataFetched && !taxonomyPreferences.isFetching) {
      if (showSharedwithMe && hasSharedWithMe) setSharedFilter()
      else if (!isFeatureFlagEnabled('UserProfile')) setLoading(false)
      else if (filterDataState.size === 0 || isMyInterestSelected) {
        if (taxonomyPreferences.data?.data?.length === 0) {
          dispatchAppliedTagFilters(new Map())
        } else {
          setInterestsFilter()
        }
      } else {
        if (
          filterChipData.filter((item) => !hiddenFilters.includes(item.value))
            .length === 0 &&
          taxonomyPreferences.data?.data?.length !== 0
        ) {
          setInterestsFilter()
        }
        // mark data loaded so it can continue,
        // only place to handle setLoading outside of dispatch
        setLoading(false)
      }
    }
  }, [taxonomyPreferences.isFetching, sharedWithMeDataFetched])

  const handleFilterGroupExpanded = (group: string, state: boolean) => {
    const updated = { ...filterGroupExpanded, [group]: state }
    setFilterGroupExpanded(updated)
  }

  return (
    <SearchLayout
      title={t('InsightsList.ECPInsightsHeading')}
      drawerDialogSlot={
        <Filters
          filterSelected={filterDataState}
          setFilterSelected={(payload) => {
            if (isMobile) {
              dispatch(setAppliedTagFilters(payload))
              return
            }

            if (
              Array.from(
                filterDataState?.get(YourInsights)?.values() ?? [],
              )[0] !== myInterestsLabel &&
              Array.from(payload?.get(YourInsights)?.values() ?? [])[0] ===
                myInterestsLabel
            ) {
              setInterestsFilter()
              return
            }

            dispatchAppliedTagFilters(payload)
          }}
          filterValues={availableTagFilters}
          showSharedwithMe={isMobile ? false : hasSharedWithMe}
          formatFilterDisplayFunction={formatTagString}
          filterClearTranslationKey="Filters.Clear"
          filterTitleTranslationKeyPrefix={filterTitleTranslationKeyPrefix}
          visibleItemsCount={6}
          filterGroupExpanded={filterGroupExpanded}
          handleFilterGroupExpanded={handleFilterGroupExpanded}
        />
      }
      dialogSecondaryButtonClickHandler={handleClearAll}
      searchSlot={<InsightsSearch setFilter={setFilter} />}
      chipsSlot={
        <FilterChips<InsightsFilterObj>
          clearAllFocusQuerySelector="#insights-filter-chips-clear-all-focus-target input"
          filtersSelected={filterChipData}
          onChipDeleted={handleFilterDeleted}
          onClearAll={handleClearAll}
          filterChipTranslationKeyPrefix="InsightsList.FilterHeaders"
          hiddenFilters={hiddenFilters}
        />
      }>
      {!taxonomyPreferences.isFetching &&
        !loading &&
        sharedWithMeDataFetched && (
          <InsightsResults
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            filter={filter}
            onLoadMore={onLoadMore}
          />
        )}
    </SearchLayout>
  )
}

export default InsightsLayout
