const PaymentTermsAndConditionsAutomaticUS = () => `<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=windows-1252" />
    <meta name="Generator" content="Microsoft Word 15 (filtered)" />
    <style>
      <!--
       /* Font Definitions */
       @font-face
      	{font-family:"Cambria Math";
      	panose-1:2 4 5 3 5 4 6 3 2 4;}
      @font-face
      	{font-family:Calibri;
      	panose-1:2 15 5 2 2 2 4 3 2 4;}
      @font-face
      	{font-family:"Calibri Light";
      	panose-1:2 15 3 2 2 2 4 3 2 4;}
       /* Style Definitions */
       p.MsoNormal, li.MsoNormal, div.MsoNormal
      	{margin-top:0in;
      	margin-right:0in;
      	margin-bottom:8.0pt;
      	margin-left:0in;
      	line-height:107%;
      	font-size:11.0pt;
      	font-family:"Calibri",sans-serif;}
      p.MsoFooter, li.MsoFooter, div.MsoFooter
      	{mso-style-link:"Footer Char";
      	margin:0in;
      	font-size:11.0pt;
      	font-family:"Calibri",sans-serif;}
      a:link, span.MsoHyperlink
      	{color:blue;
      	text-decoration:underline;}
      p
      	{margin-right:0in;
      	margin-left:0in;
      	font-size:12.0pt;
      	font-family:"Times New Roman",serif;}
      span.FooterChar
      	{mso-style-name:"Footer Char";
      	mso-style-link:Footer;}
      .MsoChpDefault
      	{font-family:"Calibri",sans-serif;}
      .MsoPapDefault
      	{margin-bottom:8.0pt;
      	line-height:107%;}
       /* Page Definitions */
       @page WordSection1
      	{size:8.5in 11.0in;
      	margin:1.0in 1.0in 1.0in 1.0in;}
      div.WordSection1
      	{page:WordSection1;}
      -->
    </style>
  </head>

  <body lang="EN-US" link="blue" vlink="#954F72" style="word-wrap: break-word">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><u
            ><span
              style="
                font-size: 14pt;
                line-height: 107%;
                font-family: 'Arial', sans-serif;
              "
              >Automatic Payment Plan/Recurring Payment</span
            ></u
          ></b
        ><b
          ><u
            ><span
              style="
                font-size: 20pt;
                line-height: 107%;
                font-family: 'Calibri Light', sans-serif;
              "
            >
            </span></u></b
        ><b
          ><u
            ><span
              style="
                font-size: 14pt;
                line-height: 107%;
                font-family: 'Arial', sans-serif;
              "
              >- US</span
            ></u
          ></b
        >
      </p>

      <p
        style="
          margin-top: 6pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
            >Automatic Payment Plan/Recurring Payment Authorization Agreement
            ("Authorization Agreement")</span
          ></b
        >
      </p>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><u
            ><span style="font-size: 16pt; font-family: 'Arial', sans-serif"
              >US-Commercial Customers</span
            ></u
          ></b
        >
      </p>

      <p
        style="
          margin-top: 9pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >RSM US LLP and its affiliates, agents, successors, and assigns
          ("RSM") are authorized to collect payments due to RSM by initiating
          either (i) Automated Clearing House (ACH) debits to Customer's
          designated bank account, or (ii) charges to the Customer's designated
          credit card. Customer agrees that automated debits or charges (as
          applicable) will occur in the amounts and with the frequency selected
          by Customer. It is understood and acknowledged that this Authorization
          Agreement is for recurring transactions based on the instructions
          provided above by Customer. This Authorization Agreement allows RSM to
          initiate credit entries to allow for the correction of processing
          errors.</span
        >
      </p>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If Customer revokes this authorization or RSM suspends or terminates
          Customer's enrollment in the Automatic Payment Plan because of the
          inability to process debits to Customer's designated bank account or
          charges to Customer's designated credit card (as applicable), Customer
          will remain responsible for making timely payments pursuant to
          alternative payment methods. In no event shall a revocation of this
          Authorization Agreement affect prior payments made to RSM.</span
        >
      </p>

      <p
        style="
          margin-top: 11pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><i
            ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
              >Additional Terms for ACH Recurring Payment Authorization
              Agreement.
            </span></i
          ></b
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If any payment due date is not a business day, the ACH debit
          authorized by this Authorization Agreement will be processed on the
          next following business day. Customer authorizes RSM to increase the
          amount of any scheduled ACH debit entry or assess multiple ACH debits
          for any previously scheduled payment(s) that was not paid in
          accordance with this Authorization Agreement, even if the amount of
          such debit entry exceeds any Auto Payment Limit (if applicable). This
          authorization is to remain in full force and effect until RSM has
          received written notification from Customer of its termination in such
          time and in such manner as to afford RSM and Customer's depository
          bank a reasonable opportunity to act on it. RSM may suspend or
          terminate Customer's enrollment in this Automatic Payment Plan
          immediately if Customer fails to keep Customer's designated bank
          account in good standing or if there are insufficient funds in
          Customer's designated bank account to process any payment.</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 11pt; font-family: 'Calibri', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p
        style="
          margin-top: 3pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If there is any missing or erroneous information in this
          Authorization Agreement regarding Customer's bank, bank routing
          number, or designated bank account number, Customer authorizes RSM to
          verify and correct such information. Customer attests that the
          designated bank account was established for business purposes and not
          primarily for personal, family or household purposes.</span
        >
      </p>

      <p
        style="
          margin-top: 11pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If this authorization is used for an ACH transaction and declined, it
          may be reinitiated up to two additional times. RSM is not responsible
          for any fees charged by Customer's depository bank as the result of
          debits initiated under this Authorization Agreement.</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 15pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 15pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 18.5pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p style="margin: 0in">
        <b
          ><u
            ><span style="font-size: 16pt; font-family: 'Arial', sans-serif"
              >US-Individual/Consumer</span
            ></u
          ></b
        >
      </p>

      <p
        style="
          margin-top: 9pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >RSM US LLP and its affiliates, agents, successors, and assigns
          ("RSM") are authorized to collect payments due to RSM by initiating
          either (i) Automated Clearing House (ACH) debits to Customer's
          designated bank account, or (ii) charges to the Customer's designated
          credit card. Customer agrees that automated debits or charges (as
          applicable) will occur in the amounts and with the frequency selected
          by Customer. It is understood and acknowledged that this Authorization
          Agreement is for recurring transactions based on the instructions
          provided above by Customer (including payment frequency, payment
          dates, and payment limits). This Authorization Agreement allows RSM to
          initiate credit entries to allow for the correction of processing
          errors.</span
        >
      </p>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If Customer revokes this authorization or RSM suspends or terminates
          Customer's enrollment in the Automatic Payment Plan because of the
          inability to process debits to Customer's designated bank account or
          charges to Customer's designated credit card (as applicable), Customer
          will remain responsible for making timely payments pursuant to
          alternative payment methods. In no event shall a revocation of this
          Authorization Agreement affect prior payments made to RSM.</span
        >
      </p>

      <p
        style="
          margin-top: 11pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><i
            ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
              >Additional Terms for ACH Recurring Payment Authorization
              Agreement.
            </span></i
          ></b
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If any payment due date is not a business day, the ACH debit
          authorized by this Authorization Agreement will be processed on the
          next following business day. RSM may suspend or terminate
          Customer's</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 11pt; font-family: 'Calibri', sans-serif"
          >&nbsp;</span
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >enrollment in this Automatic Payment Plan immediately if Customer
          fails to keep Customer's designated bank account in good standing or
          if there are insufficient funds in Customer's designated bank account
          to process any payment.</span
        >
      </p>

      <p
        style="
          margin-top: 11pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If there is any missing or erroneous information in this
          Authorization Agreement regarding Customer's bank, bank routing
          number, or designated bank account number, Customer authorizes RSM to
          verify and correct such information.</span
        >
      </p>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If this authorization is used for an ACH transaction and declined, it
          may be reinitiated up to two additional times. RSM is not responsible
          for any fees charged by Customer's depository bank as the result of
          debits initiated under this Authorization Agreement.</span
        >
      </p>

      <p
        style="
          margin-top: 11pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
            >RIGHT TO STOP PAYMENT AND PROCEDURE FOR DOING SO.
          </span></b
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >If</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >Customer has told RSM in advance to make regular payments out of
          Customer's designated bank account, Customer can stop any of these
          payments. Here's how: call RSM at </span
        ><span style="font-family: 'Arial', sans-serif">608-270-6950</span
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif">
          or write RSM at </span
        ><a href="mailto:Accounts_Receivable@rsmus.com"
          ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
            >Accounts_Receivable@rsmus.com,</span
          ></a
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif">
          in time for RSM to receive the request 3 business days or more before
          the payment is scheduled to be made. If Customer calls, RSM may also
          require Customer to put the request in writing and get it to RSM
          within 14 days after Customer's call.</span
        >
      </p>

      <p
        style="
          margin-top: 11pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
            >NOTICE OF VARYING AMOUNTS.
          </span></b
        ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >The ACH debits authorized by this Authorization Agreement will be
          processed in accordance with the instructions provided by Customer
          above regarding payment frequency, payment dates, and payment
          limits.</span
        >
      </p>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><span style="font-size: 14pt; font-family: 'Arial', sans-serif"
            >EXECUTION OF THIS VOLUNTARY AUTHORIZATION AGREEMENT IS
            <u>NOT</u> REQUIRED.</span
          ></b
        >
      </p>

      <p class="MsoNormal"><b>&nbsp;</b></p>
    </div>
  </body>
</html>
`

export default PaymentTermsAndConditionsAutomaticUS
