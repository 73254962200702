import { sxStyles } from '../../../styles/sxStyles'

const useStyles = () =>
  sxStyles({
    root: (theme) => ({
      width: '100%',
      height: '28.125rem',
      background: '#FFFFFF',
      padding: '1.5rem',
      paddingBottom: '2.8125rem',
      justifyContent: 'center',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      margin: '0 auto',
      [theme.breakpoints.up('desktop')]: {
        width: '21.875rem',
        flex: 0,
      },
    }),
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      textAlign: 'center',
      justifyContent: 'center',
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    text: {
      fontSize: '2.25rem',
      fontFamily: 'Prelo-Light, sans-serif',
    },
    tryText: (theme) => ({
      fontSize: '1.125rem',
      fontFamily: 'Prelo-Book, sans-serif',
      [theme.breakpoints.up('desktop')]: {
        width: '50rem',
      },
    }),
  })

export default useStyles
