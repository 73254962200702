import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface IServiceHealthInfo {
  CMSService: IServiceHealthDetails
}

export interface IServiceHealthDetails {
  IsHealthy: boolean | undefined
  ErrorMessage: string | undefined
}

const initialState: IServiceHealthInfo = {
  CMSService: {
    IsHealthy: true, // Assume healthy until we know otherwise so we don't show the error message on page load
    ErrorMessage: undefined,
  },
}

export const serviceHealthSlice = createSlice({
  name: 'serviceHealth',
  initialState,
  reducers: {
    setCMSHealthInfo: (state, { payload }) => {
      state.CMSService = payload
    },
  },
})

export const { setCMSHealthInfo } = serviceHealthSlice.actions
export const getCMSHealthInfo = (state: RootState) =>
  state.serviceHealth.CMSService

export default serviceHealthSlice.reducer
