import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PackmanPieIcon from '../../icons/PackmanPieIcon'

const EmptyStatusBreakdown = () => {
  const { t } = useTranslation()

  const EmptyStatusBreakdownMemo = useMemo(
    () => (
      <div className="flex flex-col tablet:w-full mobile:w-full mt-[1px] text-center p-[26px 0px 24px 0px] tablet:pb-[38px 0 36px 0]">
        <div className="flex justify-center pb-[12px]">
          <PackmanPieIcon />
        </div>
        <h2 className="flex justify-center font-prelo-light text-[30px] pb-[16px] desktop:text-[36px] tablet:text-[36px]">
          {t('EngagementDashboard.EmptyStatusBreakdownMainHeader')}
        </h2>
        <h2 className="flex justify-center font-prelo-book text-[16px]">
          {t('EngagementDashboard.EmptyStatusBreakdownSubHeader')}
        </h2>
      </div>
    ),
    [t],
  )

  return EmptyStatusBreakdownMemo
}

export default EmptyStatusBreakdown
