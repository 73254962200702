import { DocumentRequestSummaryModel } from '@rsmus/ecp-documentrequestservice-legacy'
import PieChartItem from '../../../rsmCoreComponents/visualizations/pieChart/PieChartItem'
import PieChartProps from '../../../rsmCoreComponents/visualizations/pieChart/PieChartProps'

// Possibly externalize these in the future.  Used for constructing the PieChart.
const statusCompleted = 'Completed'
const statusFollowUp = 'Follow-Up Needed'
const statusSubmitted = 'Submitted'
const statusInProgress = 'In Progress'
const statusRequested = 'Requested'

// The PieChart colors.  Lookup table for specifying colors
// for both the pie slice as well as the percentage text.
const pieChartStatusColors = new Map<string, string>()
pieChartStatusColors.set(statusCompleted, '#F1B434')
pieChartStatusColors.set(statusFollowUp, '#9F5CC0')
pieChartStatusColors.set(statusSubmitted, '#009CDE')
pieChartStatusColors.set(statusInProgress, '#E87722')
pieChartStatusColors.set(statusRequested, '#34A798')

// Translation map for internationalization.
const piChartNameTranslationMap = new Map<string, string>()

piChartNameTranslationMap.set(
  statusCompleted,
  'EngagementDashboard.Summary.StatusBreakDown.CompletedLabel',
)
piChartNameTranslationMap.set(
  statusFollowUp,
  'EngagementDashboard.Summary.StatusBreakDown.FollowUpLabel',
)
piChartNameTranslationMap.set(
  statusSubmitted,
  'EngagementDashboard.Summary.StatusBreakDown.SubmittedLabel',
)
piChartNameTranslationMap.set(
  statusInProgress,
  'EngagementDashboard.Summary.StatusBreakDown.InProgressLabel',
)
piChartNameTranslationMap.set(
  statusRequested,
  'EngagementDashboard.Summary.StatusBreakDown.RequestedLabel',
)

export interface RequestSummaryProps {
  all: {
    name: string | undefined
    total: number | null
    overdueRequests: number | null
    priorityOutstandingRequests: number | null
    followUpRequests: number | null
  }
  userSubset: {
    name: string | undefined
    total: number | null
    overdueRequests: number | null
    priorityOutstandingRequests: number | null
    followUpRequests: number | null
  }
}

export function calculateRequestSummaryData(
  data: DocumentRequestSummaryModel[] | undefined,
  user: string,
) {
  let total = 0
  let overdue = 0
  let outstanding = 0
  let followUp = 0
  let userTotal = 0
  let userOverdue = 0
  let userOutstanding = 0
  let userFollowUp = 0

  const emptyRequestSummaryProps: RequestSummaryProps = {
    all: {
      name: undefined,
      total: null,
      overdueRequests: null,
      priorityOutstandingRequests: null,
      followUpRequests: null,
    },
    userSubset: {
      name: undefined,
      total: null,
      overdueRequests: null,
      priorityOutstandingRequests: null,
      followUpRequests: null,
    },
  }

  if (data === undefined) {
    return emptyRequestSummaryProps
  }

  data.forEach((record) => {
    total += 1
    if (record.createdBy === user) {
      userTotal += 1
    }

    if (record.dueDate && record.dueDate < new Date()) {
      overdue += 1
      if (record.createdBy === user) {
        userOverdue += 1
      }
    }
    if (
      record.isPriority &&
      (record.statusId === 1 || record.statusId === 3 || record.statusId === 5)
    ) {
      // TO DO USE ENUMS
      outstanding += 1
      if (record.createdBy === user) {
        userOutstanding += 1
      }
    }
    if (record.statusId === 1) {
      followUp += 1
      if (record.createdBy === user) {
        userFollowUp += 1
      }
    }
  })

  const model: RequestSummaryProps = {
    all: {
      name: 'allRequests',
      total,
      overdueRequests: overdue,
      priorityOutstandingRequests: outstanding,
      followUpRequests: followUp,
    },
    userSubset: {
      name: 'userRequests',
      total: userTotal,
      overdueRequests: userOverdue,
      priorityOutstandingRequests: userOutstanding,
      followUpRequests: userFollowUp,
    },
  }
  return model
}

function getChartMetricValueGrandTotal(
  chartItems: PieChartItem[] | undefined,
): number {
  let grandTotal = 0
  if (undefined !== chartItems) {
    chartItems.forEach((chartItem: PieChartItem) => {
      grandTotal += chartItem.metricValue
    })
  }
  return grandTotal
}

export function getPieChartTitle(
  t: any,
  chartItems: PieChartItem[] | undefined,
): string {
  return t('EngagementDashboard.Summary.StatusBreakDown.TotalRequests', {
    count: getChartMetricValueGrandTotal(chartItems),
  })
}

export function calculateStatusBreakdownData(
  t: any,
  data: DocumentRequestSummaryModel[] | undefined,
  pieChartId: string,
) {
  const pieChartItems: PieChartItem[] = []
  if (undefined !== data) {
    const statusMap = new Map<string, number>()
    statusMap.set(statusCompleted, 0)
    statusMap.set(statusFollowUp, 0)
    statusMap.set(statusSubmitted, 0)
    statusMap.set(statusInProgress, 0)
    statusMap.set(statusRequested, 0)

    data.forEach((item) => {
      if (undefined !== item.status) {
        const itemCount: number | undefined = statusMap.get(item.status)
        if (undefined !== itemCount) {
          statusMap.set(item.status, itemCount + 1)
        } else {
          statusMap.set(item.status, 1)
        }
      }
    })

    const statusNames = Array.from(statusMap.keys())
    const statusCounts = Array.from(statusMap.values())
    for (let i = 0; i < statusNames.length; i += 1) {
      const name: string | undefined = statusNames.at(i)
      const count: number | undefined = statusCounts.at(i)
      const translationLabel: string | undefined =
        name === undefined ? undefined : piChartNameTranslationMap.get(name)
      let color: string | undefined = '#000000'
      if (
        name !== undefined &&
        undefined !== translationLabel &&
        undefined !== count
      ) {
        color = pieChartStatusColors.get(name)
        color = undefined === color ? '#000000' : color
        pieChartItems.push({
          metricName: name,
          metricInternationalizationKey: translationLabel,
          metricValue: count,
          metricColor: color,
          metricPercent: 0,
        })
      }
    }
  }
  const pieChartProps: PieChartProps = {
    chartId: pieChartId,
    chartTitle: getPieChartTitle(t, pieChartItems),
    chartItems: pieChartItems,
  }
  return pieChartProps
}
