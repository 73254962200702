import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import InsightsLayout from '../../components/Insights/InsightsContainer'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import processAdobePageView from '../../analytics/adobe/pageView'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import { UserType } from '../../analytics/adobe/types'

const pageTitle = 'Insights Page'

const Insights = () => {
  const { t } = useTranslation()
  const userInfo = useSelector(getUserInfo)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'

  useAITracking(t('ECPInsightsTitle'), window.location.href)

  useEffect(() => {
    document.title = t('ECPInsightsTitle')
  }, [])

  useEffect(() => {
    processAdobePageView(pageTitle, userType)
  }, [])

  return (
    <>
      <MainMenu currentItem="insights" />
      <MainContent>
        <InsightsLayout />
      </MainContent>
    </>
  )
}

export default Insights
