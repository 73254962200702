import React from 'react'

const GlobalCircumnavigateIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.7005 67.0007C63.0703 67.0007 76.3405 53.7304 76.3405 37.3607C76.3405 20.991 63.0703 7.7207 46.7005 7.7207C30.3308 7.7207 17.0605 20.991 17.0605 37.3607C17.0605 53.7304 30.3308 67.0007 46.7005 67.0007Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M17.4609 37.2812H76.0209"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.7012 7.96094V66.6809"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.7012 7.96094C46.7012 7.96094 61.4412 17.3509 61.4412 37.3609C61.4412 57.3709 46.7012 66.6809 46.7012 66.6809"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.7009 7.96094C46.7009 7.96094 31.9609 17.3509 31.9609 37.3609C31.9609 57.3609 46.7009 66.6809 46.7009 66.6809"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.9707 17.9199C23.9707 17.9199 31.6407 24.3399 46.7007 24.3399C61.7607 24.3399 69.4807 17.9199 69.4807 17.9199"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.9707 56.8399C23.9707 56.8399 31.6407 50.4199 46.7007 50.4199C61.7607 50.4199 69.4807 56.8399 69.4807 56.8399"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.2697 23.7793C15.5297 24.4293 -2.27034 28.4793 3.37966 40.4393C8.83966 51.9993 41.6197 49.1193 61.4697 43.9693"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M58.9803 50.3687L59.6603 44.3687L55.8203 39.7188L70.0203 41.2987L58.9803 50.3687Z"
      fill="#009CDE"
    />
  </svg>
)

export default GlobalCircumnavigateIcon
