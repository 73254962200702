import React from 'react'

const DollarPieChartIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.74 72.12C42.41 72.28 41.05 72.36 39.68 72.36C20.53 72.36 5 56.83 5 37.68C5 18.53 20.53 3 39.68 3C58.83 3 74.36 18.53 74.36 37.68C74.36 45.07 72.05 51.93 68.1 57.56"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M58.201 57.7108C57.431 57.2308 56.501 56.8908 55.611 56.7408C53.261 56.3508 50.581 57.4908 50.771 60.0708C50.971 62.6908 53.931 62.6308 55.981 63.0508C57.211 63.3008 58.471 63.5208 59.131 64.7408C59.881 66.1508 58.921 67.9208 57.591 68.5608C56.821 68.9308 55.831 69.1008 54.981 69.1008C53.471 69.0908 51.241 68.6808 50.041 67.7808"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M54.9707 54.4199V71.1899"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M54.9107 76.9991C62.7476 76.9991 69.1007 70.6461 69.1007 62.8091C69.1007 54.9722 62.7476 48.6191 54.9107 48.6191C47.0738 48.6191 40.7207 54.9722 40.7207 62.8091C40.7207 70.6461 47.0738 76.9991 54.9107 76.9991Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.1198 5.09961L40.7098 40.2996H5.08984"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M35.3693 38.7308C35.9713 38.7308 36.4593 38.2428 36.4593 37.6408C36.4593 37.0388 35.9713 36.5508 35.3693 36.5508C34.7673 36.5508 34.2793 37.0388 34.2793 37.6408C34.2793 38.2428 34.7673 38.7308 35.3693 38.7308Z"
      fill="#CCCCCC"
    />
    <path
      d="M29.9298 38.7308C30.5318 38.7308 31.0198 38.2428 31.0198 37.6408C31.0198 37.0388 30.5318 36.5508 29.9298 36.5508C29.3279 36.5508 28.8398 37.0388 28.8398 37.6408C28.8398 38.2428 29.3279 38.7308 29.9298 38.7308Z"
      fill="#CCCCCC"
    />
    <path
      d="M24.4904 38.7308C25.0924 38.7308 25.5804 38.2428 25.5804 37.6408C25.5804 37.0388 25.0924 36.5508 24.4904 36.5508C23.8884 36.5508 23.4004 37.0388 23.4004 37.6408C23.4004 38.2428 23.8884 38.7308 24.4904 38.7308Z"
      fill="#CCCCCC"
    />
    <path
      d="M19.0607 38.7308C19.6627 38.7308 20.1507 38.2428 20.1507 37.6408C20.1507 37.0388 19.6627 36.5508 19.0607 36.5508C18.4587 36.5508 17.9707 37.0388 17.9707 37.6408C17.9707 38.2428 18.4587 38.7308 19.0607 38.7308Z"
      fill="#CCCCCC"
    />
    <path
      d="M32.0998 35.4691C32.7018 35.4691 33.1898 34.9811 33.1898 34.3791C33.1898 33.7771 32.7018 33.2891 32.0998 33.2891C31.4978 33.2891 31.0098 33.7771 31.0098 34.3791C31.0098 34.9811 31.4978 35.4691 32.0998 35.4691Z"
      fill="#CCCCCC"
    />
    <path
      d="M26.6701 35.4691C27.2721 35.4691 27.7601 34.9811 27.7601 34.3791C27.7601 33.7771 27.2721 33.2891 26.6701 33.2891C26.0681 33.2891 25.5801 33.7771 25.5801 34.3791C25.5801 34.9811 26.0681 35.4691 26.6701 35.4691Z"
      fill="#CCCCCC"
    />
    <path
      d="M21.2306 35.4691C21.8326 35.4691 22.3206 34.9811 22.3206 34.3791C22.3206 33.7771 21.8326 33.2891 21.2306 33.2891C20.6286 33.2891 20.1406 33.7771 20.1406 34.3791C20.1406 34.9811 20.6286 35.4691 21.2306 35.4691Z"
      fill="#CCCCCC"
    />
    <path
      d="M15.8009 35.4691C16.4029 35.4691 16.8909 34.9811 16.8909 34.3791C16.8909 33.7771 16.4029 33.2891 15.8009 33.2891C15.1989 33.2891 14.7109 33.7771 14.7109 34.3791C14.7109 34.9811 15.1989 35.4691 15.8009 35.4691Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.6193 38.7308C14.2213 38.7308 14.7093 38.2428 14.7093 37.6408C14.7093 37.0388 14.2213 36.5508 13.6193 36.5508C13.0173 36.5508 12.5293 37.0388 12.5293 37.6408C12.5293 38.2428 13.0173 38.7308 13.6193 38.7308Z"
      fill="#CCCCCC"
    />
    <path
      d="M8.18961 38.7308C8.7916 38.7308 9.27961 38.2428 9.27961 37.6408C9.27961 37.0388 8.7916 36.5508 8.18961 36.5508C7.58762 36.5508 7.09961 37.0388 7.09961 37.6408C7.09961 38.2428 7.58762 38.7308 8.18961 38.7308Z"
      fill="#CCCCCC"
    />
    <path
      d="M10.3595 35.4691C10.9615 35.4691 11.4495 34.9811 11.4495 34.3791C11.4495 33.7771 10.9615 33.2891 10.3595 33.2891C9.75754 33.2891 9.26953 33.7771 9.26953 34.3791C9.26953 34.9811 9.75754 35.4691 10.3595 35.4691Z"
      fill="#CCCCCC"
    />
    <path
      d="M35.3693 32.2093C35.9713 32.2093 36.4593 31.7213 36.4593 31.1193C36.4593 30.5173 35.9713 30.0293 35.3693 30.0293C34.7673 30.0293 34.2793 30.5173 34.2793 31.1193C34.2793 31.7213 34.7673 32.2093 35.3693 32.2093Z"
      fill="#CCCCCC"
    />
    <path
      d="M29.9298 32.2093C30.5318 32.2093 31.0198 31.7213 31.0198 31.1193C31.0198 30.5173 30.5318 30.0293 29.9298 30.0293C29.3279 30.0293 28.8398 30.5173 28.8398 31.1193C28.8398 31.7213 29.3279 32.2093 29.9298 32.2093Z"
      fill="#CCCCCC"
    />
    <path
      d="M24.4904 32.2093C25.0924 32.2093 25.5804 31.7213 25.5804 31.1193C25.5804 30.5173 25.0924 30.0293 24.4904 30.0293C23.8884 30.0293 23.4004 30.5173 23.4004 31.1193C23.4004 31.7213 23.8884 32.2093 24.4904 32.2093Z"
      fill="#CCCCCC"
    />
    <path
      d="M19.0607 32.2093C19.6627 32.2093 20.1507 31.7213 20.1507 31.1193C20.1507 30.5173 19.6627 30.0293 19.0607 30.0293C18.4587 30.0293 17.9707 30.5173 17.9707 31.1193C17.9707 31.7213 18.4587 32.2093 19.0607 32.2093Z"
      fill="#CCCCCC"
    />
    <path
      d="M32.0998 28.9495C32.7018 28.9495 33.1898 28.4615 33.1898 27.8595C33.1898 27.2575 32.7018 26.7695 32.0998 26.7695C31.4978 26.7695 31.0098 27.2575 31.0098 27.8595C31.0098 28.4615 31.4978 28.9495 32.0998 28.9495Z"
      fill="#CCCCCC"
    />
    <path
      d="M26.6701 28.9495C27.2721 28.9495 27.7601 28.4615 27.7601 27.8595C27.7601 27.2575 27.2721 26.7695 26.6701 26.7695C26.0681 26.7695 25.5801 27.2575 25.5801 27.8595C25.5801 28.4615 26.0681 28.9495 26.6701 28.9495Z"
      fill="#CCCCCC"
    />
    <path
      d="M21.2306 28.9495C21.8326 28.9495 22.3206 28.4615 22.3206 27.8595C22.3206 27.2575 21.8326 26.7695 21.2306 26.7695C20.6286 26.7695 20.1406 27.2575 20.1406 27.8595C20.1406 28.4615 20.6286 28.9495 21.2306 28.9495Z"
      fill="#CCCCCC"
    />
    <path
      d="M15.8009 28.9495C16.4029 28.9495 16.8909 28.4615 16.8909 27.8595C16.8909 27.2575 16.4029 26.7695 15.8009 26.7695C15.1989 26.7695 14.7109 27.2575 14.7109 27.8595C14.7109 28.4615 15.1989 28.9495 15.8009 28.9495Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.6193 32.2093C14.2213 32.2093 14.7093 31.7213 14.7093 31.1193C14.7093 30.5173 14.2213 30.0293 13.6193 30.0293C13.0173 30.0293 12.5293 30.5173 12.5293 31.1193C12.5293 31.7213 13.0173 32.2093 13.6193 32.2093Z"
      fill="#CCCCCC"
    />
    <path
      d="M8.18961 32.2093C8.7916 32.2093 9.27961 31.7213 9.27961 31.1193C9.27961 30.5173 8.7916 30.0293 8.18961 30.0293C7.58762 30.0293 7.09961 30.5173 7.09961 31.1193C7.09961 31.7213 7.58762 32.2093 8.18961 32.2093Z"
      fill="#CCCCCC"
    />
    <path
      d="M11.4494 28.9495C12.0514 28.9495 12.5394 28.4615 12.5394 27.8595C12.5394 27.2575 12.0514 26.7695 11.4494 26.7695C10.8474 26.7695 10.3594 27.2575 10.3594 27.8595C10.3594 28.4615 10.8474 28.9495 11.4494 28.9495Z"
      fill="#CCCCCC"
    />
    <path
      d="M29.9298 25.6898C30.5318 25.6898 31.0198 25.2018 31.0198 24.5998C31.0198 23.9978 30.5318 23.5098 29.9298 23.5098C29.3279 23.5098 28.8398 23.9978 28.8398 24.5998C28.8398 25.2018 29.3279 25.6898 29.9298 25.6898Z"
      fill="#CCCCCC"
    />
    <path
      d="M24.4904 25.6898C25.0924 25.6898 25.5804 25.2018 25.5804 24.5998C25.5804 23.9978 25.0924 23.5098 24.4904 23.5098C23.8884 23.5098 23.4004 23.9978 23.4004 24.5998C23.4004 25.2018 23.8884 25.6898 24.4904 25.6898Z"
      fill="#CCCCCC"
    />
    <path
      d="M19.0607 25.6898C19.6627 25.6898 20.1507 25.2018 20.1507 24.5998C20.1507 23.9978 19.6627 23.5098 19.0607 23.5098C18.4587 23.5098 17.9707 23.9978 17.9707 24.5998C17.9707 25.2018 18.4587 25.6898 19.0607 25.6898Z"
      fill="#CCCCCC"
    />
    <path
      d="M32.0998 22.43C32.7018 22.43 33.1898 21.942 33.1898 21.34C33.1898 20.738 32.7018 20.25 32.0998 20.25C31.4978 20.25 31.0098 20.738 31.0098 21.34C31.0098 21.942 31.4978 22.43 32.0998 22.43Z"
      fill="#CCCCCC"
    />
    <path
      d="M26.6701 22.43C27.2721 22.43 27.7601 21.942 27.7601 21.34C27.7601 20.738 27.2721 20.25 26.6701 20.25C26.0681 20.25 25.5801 20.738 25.5801 21.34C25.5801 21.942 26.0681 22.43 26.6701 22.43Z"
      fill="#CCCCCC"
    />
    <path
      d="M21.2306 22.43C21.8326 22.43 22.3206 21.942 22.3206 21.34C22.3206 20.738 21.8326 20.25 21.2306 20.25C20.6286 20.25 20.1406 20.738 20.1406 21.34C20.1406 21.942 20.6286 22.43 21.2306 22.43Z"
      fill="#CCCCCC"
    />
    <path
      d="M15.8009 22.43C16.4029 22.43 16.8909 21.942 16.8909 21.34C16.8909 20.738 16.4029 20.25 15.8009 20.25C15.1989 20.25 14.7109 20.738 14.7109 21.34C14.7109 21.942 15.1989 22.43 15.8009 22.43Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.6193 25.6898C14.2213 25.6898 14.7093 25.2018 14.7093 24.5998C14.7093 23.9978 14.2213 23.5098 13.6193 23.5098C13.0173 23.5098 12.5293 23.9978 12.5293 24.5998C12.5293 25.2018 13.0173 25.6898 13.6193 25.6898Z"
      fill="#CCCCCC"
    />
    <path
      d="M9.27945 25.6898C9.88144 25.6898 10.3695 25.2018 10.3695 24.5998C10.3695 23.9978 9.88144 23.5098 9.27945 23.5098C8.67746 23.5098 8.18945 23.9978 8.18945 24.5998C8.18945 25.2018 8.67746 25.6898 9.27945 25.6898Z"
      fill="#CCCCCC"
    />
    <path
      d="M11.4494 22.43C12.0514 22.43 12.5394 21.942 12.5394 21.34C12.5394 20.738 12.0514 20.25 11.4494 20.25C10.8474 20.25 10.3594 20.738 10.3594 21.34C10.3594 21.942 10.8474 22.43 11.4494 22.43Z"
      fill="#CCCCCC"
    />
    <path
      d="M29.9298 19.1605C30.5318 19.1605 31.0198 18.6725 31.0198 18.0705C31.0198 17.4685 30.5318 16.9805 29.9298 16.9805C29.3279 16.9805 28.8398 17.4685 28.8398 18.0705C28.8398 18.6725 29.3279 19.1605 29.9298 19.1605Z"
      fill="#CCCCCC"
    />
    <path
      d="M24.4904 19.1605C25.0924 19.1605 25.5804 18.6725 25.5804 18.0705C25.5804 17.4685 25.0924 16.9805 24.4904 16.9805C23.8884 16.9805 23.4004 17.4685 23.4004 18.0705C23.4004 18.6725 23.8884 19.1605 24.4904 19.1605Z"
      fill="#CCCCCC"
    />
    <path
      d="M19.0607 19.1605C19.6627 19.1605 20.1507 18.6725 20.1507 18.0705C20.1507 17.4685 19.6627 16.9805 19.0607 16.9805C18.4587 16.9805 17.9707 17.4685 17.9707 18.0705C17.9707 18.6725 18.4587 19.1605 19.0607 19.1605Z"
      fill="#CCCCCC"
    />
    <path
      d="M26.6701 15.9007C27.2721 15.9007 27.7601 15.4127 27.7601 14.8107C27.7601 14.2087 27.2721 13.7207 26.6701 13.7207C26.0681 13.7207 25.5801 14.2087 25.5801 14.8107C25.5801 15.4127 26.0681 15.9007 26.6701 15.9007Z"
      fill="#CCCCCC"
    />
    <path
      d="M21.2306 15.9007C21.8326 15.9007 22.3206 15.4127 22.3206 14.8107C22.3206 14.2087 21.8326 13.7207 21.2306 13.7207C20.6286 13.7207 20.1406 14.2087 20.1406 14.8107C20.1406 15.4127 20.6286 15.9007 21.2306 15.9007Z"
      fill="#CCCCCC"
    />
    <path
      d="M15.8009 15.9007C16.4029 15.9007 16.8909 15.4127 16.8909 14.8107C16.8909 14.2087 16.4029 13.7207 15.8009 13.7207C15.1989 13.7207 14.7109 14.2087 14.7109 14.8107C14.7109 15.4127 15.1989 15.9007 15.8009 15.9007Z"
      fill="#CCCCCC"
    />
    <path
      d="M13.6193 19.1605C14.2213 19.1605 14.7093 18.6725 14.7093 18.0705C14.7093 17.4685 14.2213 16.9805 13.6193 16.9805C13.0173 16.9805 12.5293 17.4685 12.5293 18.0705C12.5293 18.6725 13.0173 19.1605 13.6193 19.1605Z"
      fill="#CCCCCC"
    />
    <path
      d="M29.9298 12.6409C30.5318 12.6409 31.0198 12.1529 31.0198 11.5509C31.0198 10.9489 30.5318 10.4609 29.9298 10.4609C29.3279 10.4609 28.8398 10.9489 28.8398 11.5509C28.8398 12.1529 29.3279 12.6409 29.9298 12.6409Z"
      fill="#CCCCCC"
    />
    <path
      d="M24.4904 12.6409C25.0924 12.6409 25.5804 12.1529 25.5804 11.5509C25.5804 10.9489 25.0924 10.4609 24.4904 10.4609C23.8884 10.4609 23.4004 10.9489 23.4004 11.5509C23.4004 12.1529 23.8884 12.6409 24.4904 12.6409Z"
      fill="#CCCCCC"
    />
    <path
      d="M19.0607 12.6409C19.6627 12.6409 20.1507 12.1529 20.1507 11.5509C20.1507 10.9489 19.6627 10.4609 19.0607 10.4609C18.4587 10.4609 17.9707 10.9489 17.9707 11.5509C17.9707 12.1529 18.4587 12.6409 19.0607 12.6409Z"
      fill="#CCCCCC"
    />
    <path
      d="M26.6701 9.37922C27.2721 9.37922 27.7601 8.89121 27.7601 8.28922C27.7601 7.68723 27.2721 7.19922 26.6701 7.19922C26.0681 7.19922 25.5801 7.68723 25.5801 8.28922C25.5801 8.89121 26.0681 9.37922 26.6701 9.37922Z"
      fill="#CCCCCC"
    />
  </svg>
)

export default DollarPieChartIcon
