import * as React from 'react'

const CheckboxDisabledIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 12 9.33"
    width="1em"
    height="1em">
    <path
      fill="#3f9c35"
      fillRule="evenodd"
      d="m0 5.79 1.93-2.02 2.26 2.08L10.42 0 12 2.05 4.14 9.34 0 5.79Z"
      opacity={0.5}
    />
  </svg>
)
export default CheckboxDisabledIcon
