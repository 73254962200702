import React from 'react'

const CheckboxOutlinedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 21V3C0.5 1.61929 1.61929 0.5 3 0.5H21C22.3807 0.5 23.5 1.61929 23.5 3V21C23.5 22.3807 22.3807 23.5 21 23.5H3C1.61929 23.5 0.5 22.3807 0.5 21Z"
      fill="white"
      stroke="#888B8D"
    />
  </svg>
)

export default CheckboxOutlinedIcon
