import * as React from 'react'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import { useScrollTrigger } from '@mui/material'

const ScrollTop = ({ children }: { children: React.ReactElement }) => {
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = () => {
    document.getElementById('main-content')?.focus()
    window.scrollTo(0, 0)
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}>
        {children}
      </Box>
    </Fade>
  )
}

export default ScrollTop
