import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { SharedArticleInfoViewModel } from '@rsmus/ecp-cmsservice'

import Article from '../../components/Insights/Article'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import tryGetIsFavorited from '../../api/insights/tryGetIsFavorited'
import {
  getArticleMetadata,
  getArticleContent,
  ArticleMetaData,
  getSharedArticleData,
} from '../../utils/helpers/Article.service'
import Unavailable from '../Unavailable'
import processAdobePageView from '../../analytics/adobe/pageView'
import { UserType } from '../../analytics/adobe/types'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import api from '../../api'

const ReloadKey = 'key'
const pageTitle = 'Insight Article Page'

const defaultArticle: ArticleMetaData = {
  id: '',
  label: '',
  ecpId: '',
  title: '',
  url: '',
  imageUrl: '',
  snapshot: '',
  publishedOn: new Date(),
  contributors: [],
  tags: [],
  favorite: false,
  bannerDeskTopImage: '',
  bannerHotSpotText: '',
  bannerMobileImage: '',
  bannerHotSpotUrl: '',
  bannerMsgContainerColor: '',
  bannerPoPBackgroundColor: '',
  bannerPrimaryCTALabel: '',
  bannerPrimaryCTAUrl: '',
  bannerSecondaryCTALabel: '',
  bannerSecondaryCTAUrl: '',
  bannerTitle: '',
  bannerSubtext: '',
  bannerUrl: '',
  bannerUseMessageContainer: '',
  cardBgColor: '',
  featuredCardBgColor: '',
  featuredCardUrl: '',
  bannerGradientOverlay: '',
  bannerPoPBackgroundPathDesktop: '',
}

const Insight = () => {
  const userInfo = useSelector(getUserInfo)
  const userType: UserType = userInfo.isEmployee ? 'Internal' : 'External'
  const [articleMetadata, setArticleMetaData] =
    useState<ArticleMetaData>(defaultArticle)
  const [sharedArticleInfoData, setSharedArticleInfoData] =
    useState<SharedArticleInfoViewModel>({
      sharedDate: new Date(),
      sharedByUserName: '',
      articleId: '',
      sharedByUserId: '',
      sharedWithUserId: '',
      sharedWithUserName: '',
    })
  const [articleContent, setArticleContent] = useState<string>('')
  const [fetchError, setFetchError] = useState<boolean>(false)
  const params = useParams()
  const { pathname, search } = useLocation()
  const reloadKey = new URLSearchParams(search).get(ReloadKey)

  const init = useCallback(async () => {
    setFetchError(false)
    if (!params?.insightId) {
      setFetchError(true)
      return
    }

    const articleId = decodeURI(params.insightId)
    // Any fetch errors or no data show robots page
    // Note: TryGetIsFavorited call failure will not affect flow.
    try {
      // Parallel calls
      const [article, isFavorited, articleInfo] = await Promise.all([
        getArticleMetadata(articleId),
        tryGetIsFavorited(articleId),
        getSharedArticleData(articleId),
      ])
      if (articleInfo?.data) {
        setSharedArticleInfoData(articleInfo?.data)
      }
      const tempArticleContent = await getArticleContent(article.url ?? '')
      if (
        !tempArticleContent ||
        !article ||
        tempArticleContent.length < 1 ||
        article.ecpId === ''
      ) {
        setFetchError(true)
        return
      }

      article.favorite = isFavorited
      setArticleMetaData(article)
      setArticleContent(tempArticleContent)
      api.cms.insights_UpdateRecentlyViewed(articleId)
    } catch {
      setFetchError(true)
    }
  }, [
    params?.insightId,
    setFetchError,
    getArticleMetadata,
    tryGetIsFavorited,
    setArticleMetaData,
    setArticleContent,
    getSharedArticleData,
  ])

  const renderUnavailable = useCallback(
    () => (
      <>
        <MainMenu currentItem="insights" />
        <MainContent>
          <Box sx={{ backgroundColor: 'white' }}>
            <Unavailable
              returnUrl={`${pathname}?${ReloadKey}=${new Date().getTime()}`}
            />
          </Box>
        </MainContent>
      </>
    ),
    [MainMenu, pathname, Unavailable],
  )

  useEffect(() => {
    init()
  }, [params?.insightId])

  useEffect(() => {
    if (reloadKey) init()
  }, [reloadKey])

  useEffect(() => {
    processAdobePageView(pageTitle, userType)
  }, [])

  if (fetchError === true) {
    return renderUnavailable()
  }

  return (
    <>
      <MainMenu currentItem="insights" />
      <MainContent>
        <Article
          articleMetadata={articleMetadata}
          articleContent={articleContent}
          sharedArticleInfoData={sharedArticleInfoData}
        />
      </MainContent>
    </>
  )
}

export default Insight
