import React from 'react'

const CallUsIcon = () => (
  <svg
    width="96"
    height="77"
    viewBox="0 0 96 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M77.6523 11.3995H95.9982" stroke="#CECECD" strokeWidth="2" />
    <path
      d="M59.3073 27.2397H44.7266V13.4447V11.3975H60.5187"
      stroke="#CECECD"
      strokeWidth="2"
    />
    <path d="M96 27.2432H75.9883" stroke="#CECECD" strokeWidth="2" />
    <path d="M76.3614 63.9053V76.0691" stroke="#009CDE" strokeWidth="2" />
    <path
      d="M60.5176 45.5099V34.2725H74.3126H76.3598V46.7964"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path d="M60.5176 76.0678V62.2959" stroke="#009CDE" strokeWidth="2" />
    <path d="M20.6145 62.6456H0" stroke="#CECECD" strokeWidth="2" />
    <path
      d="M36.3203 46.7998H53.3257V60.5948V62.642H37.8591"
      stroke="#CECECD"
      strokeWidth="2"
    />
    <path d="M0 46.8018H23.3492" stroke="#CECECD" strokeWidth="2" />
    <path d="M22.0176 10.1065V0.866211" stroke="#009CDE" strokeWidth="2" />
    <path
      d="M37.8598 28.2484V42.7734H24.0648H22.0176V27.2412"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path d="M37.8614 0.866211V11.8379" stroke="#009CDE" strokeWidth="2" />
    <path
      d="M24.5902 43.1279C24.5902 43.1279 20.9677 53.699 20.7231 55.1993C20.4804 56.6995 20.7231 64.6803 20.7231 65.3294C20.7231 65.9803 21.3451 67.2706 23.0958 67.2706C24.8444 67.2706 25.143 66.0843 25.143 66.0843V59.8329V67.548C25.143 68.4801 25.8112 69.7492 27.298 69.7492C28.7848 69.7492 29.2374 68.3472 29.2374 68.3472V60.0486V69.7492C29.2374 70.5985 29.8864 71.5576 31.4483 71.5576C33.0082 71.5576 33.7651 70.3347 33.7651 69.6414V60.1314V67.3361C33.7651 68.1835 34.7107 68.9943 36.028 68.9943C37.3453 68.9943 37.8595 67.4863 37.8595 66.8392V56.9961V60.0467C37.8595 60.0467 38.4064 61.8262 40.1879 61.7742C41.6207 61.7299 42.1715 59.8329 42.1715 59.8329C42.1715 59.8329 42.5105 55.6576 41.8807 53.4352C41.2509 51.2147 36.9716 48.0697 36.4594 46.685C35.9471 45.2984 36.4594 43.1279 36.4594 43.1279"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M73.7856 33.8037C73.7856 33.8037 77.4082 23.2326 77.6528 21.7324C77.8955 20.2321 77.6528 12.2513 77.6528 11.6023C77.6528 10.9513 77.0307 9.66101 75.2801 9.66101C73.5314 9.66101 73.2329 10.8473 73.2329 10.8473V17.0987V9.38369C73.2329 8.45156 72.5646 7.18242 71.0779 7.18242C69.5911 7.18242 69.1385 8.58445 69.1385 8.58445V16.883V7.18242C69.1385 6.33311 68.4895 5.37402 66.9276 5.37402C65.3677 5.37402 64.6108 6.59695 64.6108 7.29026V16.8002V9.59553C64.6108 8.74815 63.6652 7.93736 62.3479 7.93736C61.0306 7.93736 60.5164 9.44531 60.5164 10.0924V19.9355V16.8849C60.5164 16.8849 59.9695 15.1054 58.188 15.1594C56.7552 15.2017 56.2044 17.0987 56.2044 17.0987C56.2044 17.0987 55.8654 21.274 56.4952 23.4965C57.1249 25.717 61.4042 28.8619 61.9165 30.2466C62.4288 31.6333 61.9165 33.8037 61.9165 33.8037"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M44.2168 13.974C44.2168 13.974 33.6457 10.3515 32.1455 10.1069C30.6452 9.86421 22.6644 10.1069 22.0154 10.1069C21.3644 10.1069 20.0741 10.7289 20.0741 12.4795C20.0741 14.2282 21.2604 14.5267 21.2604 14.5267H27.5118H19.7968C18.8646 14.5267 17.5955 15.195 17.5955 16.6818C17.5955 18.1686 18.9975 18.6211 18.9975 18.6211H27.2961H17.5955C16.7462 18.6211 15.7871 19.2702 15.7871 20.832C15.7871 22.392 17.01 23.1489 17.7034 23.1489H27.2133H20.0086C19.1612 23.1489 18.3504 24.0945 18.3504 25.4118C18.3504 26.7291 19.8584 27.2433 20.5055 27.2433H30.3486H27.298C27.298 27.2433 25.5185 27.7902 25.5705 29.5716C25.6148 31.0045 27.5118 31.5553 27.5118 31.5553C27.5118 31.5553 31.6871 31.8942 33.9095 31.2645C36.1301 30.6347 39.275 26.3554 40.6597 25.8432C42.0463 25.3309 44.2168 25.8432 44.2168 25.8432"
      stroke="#CECECD"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M53.875 60.0698C53.875 60.0698 64.4461 63.6924 65.9464 63.937C67.4466 64.1796 75.4274 63.937 76.0764 63.937C76.7274 63.937 78.0177 63.3149 78.0177 61.5643C78.0177 59.8156 76.8314 59.5171 76.8314 59.5171H70.58H78.295C79.2272 59.5171 80.4963 58.8488 80.4963 57.362C80.4963 55.8753 79.0943 55.4227 79.0943 55.4227H70.7957H80.4963C81.3456 55.4227 82.3047 54.7737 82.3047 53.2118C82.3047 51.6518 81.0818 50.895 80.3885 50.895H70.8785H78.0832C78.9306 50.895 79.7414 49.9494 79.7414 48.6321C79.7414 47.3148 78.2334 46.8006 77.5863 46.8006H67.7432H70.7938C70.7938 46.8006 72.5733 46.2536 72.5213 44.4722C72.477 43.0394 70.58 42.4886 70.58 42.4886C70.58 42.4886 66.4047 42.1496 64.1823 42.7794C61.9617 43.4091 58.8168 47.6884 57.4321 48.2007C56.0455 48.713 53.875 48.2007 53.875 48.2007"
      stroke="#CECECD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default CallUsIcon
