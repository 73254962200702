import React from 'react'
import tokens from '../../../styles/tokens.json'

interface SearchIconProps {
  fill?: string
}

const SearchIcon = ({ fill = tokens.colors.rsmGray.copy }: SearchIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8323 15.3985L21.7278 19.2941C22.0912 19.6613 22.0912 20.2553 21.7239 20.6225L20.6181 21.7283C20.2548 22.0956 19.6609 22.0956 19.2936 21.7283L15.398 17.8327C15.2222 17.6569 15.1245 17.4186 15.1245 17.1685V16.5316C13.7453 17.61 12.0104 18.2508 10.1232 18.2508C5.63376 18.2508 1.99609 14.6132 1.99609 10.1237C1.99609 5.63425 5.63376 1.99658 10.1232 1.99658C14.6127 1.99658 18.2503 5.63425 18.2503 10.1237C18.2503 12.0109 17.6095 13.7457 16.5311 15.125H17.168C17.4181 15.125 17.6564 15.2227 17.8323 15.3985ZM5.12177 10.1236C5.12177 12.8899 7.36064 15.1249 10.1231 15.1249C12.8894 15.1249 15.1244 12.886 15.1244 10.1236C15.1244 7.35722 12.8855 5.12226 10.1231 5.12226C7.35673 5.12226 5.12177 7.36112 5.12177 10.1236Z"
      fill={fill}
    />
  </svg>
)

export default SearchIcon
