import React from 'react'

const EmptyBoxIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.4429 42.8677L39.6755 31.6816L62.3345 40.2863L39.3887 51.4724L16.4429 42.8677Z"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4429 42.868V68.6821L39.9624 77L62.3345 66.6743V40.2866"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.3887 51.4727V76.9999"
      stroke="#CCCCCC"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M66.3486 14.7598C62.3331 18.7753 58.6044 22.7908 54.5889 26.8063"
      stroke="#009CDE"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.6743 3C39.6743 8.44964 39.9611 14.1861 39.9611 19.6357"
      stroke="#009CDE"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 14.7598C17.0155 18.7753 20.7442 22.7908 24.7597 26.8063"
      stroke="#009CDE"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EmptyBoxIcon
