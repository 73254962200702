import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface ConfigurationsState {
  browserTimeout: number
  menuBarisHidden: boolean
}

const initialState: ConfigurationsState = {
  // Should the server call fail, use this as a default value instead
  browserTimeout: 30,
  menuBarisHidden: false,
}

export const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setBrowserTimeout: (state, { payload }) => {
      if (payload) state.browserTimeout = parseInt(payload, 10)
    },
    setMenuBarIsHidden: (state, { payload }) => {
      state.menuBarisHidden = payload
    },
  },
})

export const { setBrowserTimeout, setMenuBarIsHidden } =
  configurationsSlice.actions
export const getBrowserTimeout = (state: RootState) =>
  state.configurations.browserTimeout
export const getMenuBarIsHidden = (state: RootState) =>
  state.configurations.menuBarisHidden

export default configurationsSlice.reducer
