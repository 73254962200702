import React from 'react'

const CollaborationIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50.6408 13.3508C50.6408 7.66078 44.3008 3.05078 36.4708 3.05078C28.6408 3.05078 22.3008 7.66078 22.3008 13.3508C22.3008 17.5108 25.7008 21.1008 30.6008 22.7208L28.1308 28.8908L37.9608 23.5908C45.0808 23.0508 50.6408 18.6708 50.6408 13.3508Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.7109 3.77994C50.2109 0.93994 58.7109 6.30994 58.7109 11.9899C58.7109 16.1499 55.3109 19.7399 50.4109 21.3599L52.8809 27.5299L43.0509 22.2299"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M30.9102 14.7109C31.4624 14.7109 31.9102 14.2632 31.9102 13.7109C31.9102 13.1587 31.4624 12.7109 30.9102 12.7109C30.3579 12.7109 29.9102 13.1587 29.9102 13.7109C29.9102 14.2632 30.3579 14.7109 30.9102 14.7109Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.4707 14.7109C38.023 14.7109 38.4707 14.2632 38.4707 13.7109C38.4707 13.1587 38.023 12.7109 37.4707 12.7109C36.9184 12.7109 36.4707 13.1587 36.4707 13.7109C36.4707 14.2632 36.9184 14.7109 37.4707 14.7109Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.75 14.7109C44.3023 14.7109 44.75 14.2632 44.75 13.7109C44.75 13.1587 44.3023 12.7109 43.75 12.7109C43.1977 12.7109 42.75 13.1587 42.75 13.7109C42.75 14.2632 43.1977 14.7109 43.75 14.7109Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.2402 44.0599C14.2402 44.0599 16.1902 46.3499 18.9002 46.3499C21.6102 46.3499 21.1002 41.6899 21.1002 41.6899L22.7902 40.7599C22.7902 40.7599 20.2502 35.2499 20.5902 34.9199"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.0898 46.0195L15.7598 49.3995"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.83008 39.4902V47.7102"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.84011 71.0794C4.84011 71.0794 2.92011 50.7794 8.23011 48.3794C11.6501 46.8294 13.7601 48.4494 16.8701 50.9194C21.7801 54.8194 21.3601 66.0094 21.3601 66.0094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.83008 56.9405V64.7605C9.83008 68.2205 12.6901 71.6705 16.1501 71.5705L26.7701 71.2505V65.9105L20.3801 66.0705C18.2101 66.1205 16.4301 63.7005 16.4301 61.5305V54.8105"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.7695 71.3495L35.4295 71.2695C35.4295 71.2695 35.6095 66.0195 26.7695 66.0195"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.0895 37.6308C13.0895 41.5308 12.3695 32.7208 9.82953 34.9208C7.28953 37.1208 11.2695 40.1708 8.97953 40.1708C6.68953 40.1708 6.01953 37.7108 6.01953 33.8208C6.01953 29.9308 9.37953 26.8908 12.6295 26.3708C19.4595 25.2708 24.7495 29.7608 22.7895 32.9308C20.8295 36.1008 13.0895 33.7308 13.0895 37.6308Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M29.9102 66.1005C30.0002 65.9605 29.9902 59.6005 29.9602 55.5505C29.9402 52.2505 32.6102 49.5605 35.9102 49.5605H36.4702L41.7102 54.0505L46.5902 49.5605H47.8602C50.7602 49.5605 53.1102 51.9105 53.1102 54.8105V66.2405"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.9004 57.4102V68.8002"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.4395 57.4102V67.0402"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.2305 37.3694V40.3294C34.2305 42.4494 35.3305 48.1194 40.8405 48.1194C46.3505 48.1194 48.0405 42.1494 48.0405 40.0294V37.3594"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.1304 33.8797C35.4004 33.8797 34.2304 37.3697 34.2304 37.3697V38.8697C32.3104 38.7597 31.4804 29.9297 41.1304 29.9297C50.7804 29.9297 49.2904 37.7697 48.0304 38.8697V37.3697C48.0304 37.3697 46.8604 33.8797 41.1304 33.8797Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.6993 30.32C37.6993 30.32 36.4293 26.5 41.1293 26.5C45.8293 26.5 44.4393 30.32 44.4393 30.32"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.0191 47.5098L37.5391 50.4898"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.6309 47.1992L45.6009 50.3292"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.7598 66.259C58.7598 66.259 58.7598 58.019 61.0798 53.949C63.3998 49.879 64.9198 49.259 68.8698 48.129C72.8198 46.999 74.8598 51.689 74.8598 54.819V71.389"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M69.8905 57.1704V66.8804C69.8905 69.9904 67.2605 71.3504 65.4205 71.3504H52.5605V66.2504H61.8805C63.0705 66.2504 64.9905 65.5704 64.9905 63.1404V57.1504"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M52.7599 71.35L44.0999 71.27C44.0999 71.27 43.9199 66.25 52.7599 66.25"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.34961 71.3496H76.9996"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.4004 76.2109H61.2204"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M67.4391 43.6009C67.4391 43.6009 65.4791 46.3509 61.4291 45.8909C58.7391 45.5809 59.2291 41.2309 59.2291 41.2309L57.5391 40.3009C57.5391 40.3009 60.0791 34.7909 59.7391 34.4609"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M68.2093 39.35C68.2093 39.35 69.5593 36.53 69.4293 35.46C69.2993 34.39 67.4393 32.74 66.3693 35.6C64.5293 32.06 58.5293 36.4 58.5293 32.4C58.5293 28.4 60.1593 26.5 65.6193 26.5C71.0793 26.5 73.2793 28.01 73.7193 31.17C74.1593 34.33 74.3993 40.44 71.8093 40.99C69.2193 41.54 68.1993 39.36 68.1993 39.36L68.2093 39.35Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M71.0508 41.0703L71.1308 48.1303"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.9902 45.9395L64.0302 50.1795"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default CollaborationIcon
