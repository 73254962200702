import React from 'react'

const GraduationCapIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_130882)">
      <path
        d="M10.9492 36.6406V56.1906"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.9888 58.9636V67H8V59.2761C8.00107 59.2707 8.00224 59.265 8.00351 59.2589C8.01588 59.1998 8.03789 59.1076 8.07389 58.9936C8.14682 58.7626 8.2716 58.4596 8.47608 58.1624C8.85743 57.6081 9.55694 57 10.9777 57C12.4455 57 13.1597 57.5677 13.5312 58.0443C13.7314 58.301 13.853 58.5602 13.9237 58.7542C13.9561 58.8433 13.9767 58.9157 13.9888 58.9636Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9492 56.1895V67.0195"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M62.51 39.1496L79 33.1096L40.18 16.3496L1 33.1096L18.02 39.1496"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M62.3595 51.6191L62.5195 39.1591C62.5195 39.1591 60.9895 32.0391 40.0095 32.0391C19.0295 32.0391 18.0295 39.1591 18.0295 39.1591L17.9395 51.6191"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.2199 58.0704C52.5359 58.0704 62.5199 55.2901 62.5199 51.8604C62.5199 48.4307 52.5359 45.6504 40.2199 45.6504C27.904 45.6504 17.9199 48.4307 17.9199 51.8604C17.9199 55.2901 27.904 58.0704 40.2199 58.0704Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_130882">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GraduationCapIcon
