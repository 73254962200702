import React from 'react'
import { Box } from '@mui/material'
import NoSearchResultIcon from '../../../components/icons/NoSearchResultIcon'
import useStyles from './NoSearchFound.styles'

type OwnProps = {
  title: string
  subTitle: string
}

export const NoSearchFound = ({ title, subTitle }: OwnProps) => {
  const classes = useStyles()
  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <Box sx={classes.iconContainer}>
          <NoSearchResultIcon />
        </Box>
        <Box sx={classes.text}>{title}</Box>
        <Box sx={classes.tryText}>{subTitle}</Box>
      </Box>
    </Box>
  )
}

export default NoSearchFound
