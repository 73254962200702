import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { enUS, es } from 'date-fns/locale'
import { formatDistance, format as formatDate, formatRelative } from 'date-fns'
import messages from './messages'
import locales from './locales'

const localesFns = { enUS, es }

// https://www.i18next.com/overview/configuration-options
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: messages,
    debug: false,
    fallbackLng: locales.ENGLISH,
    interpolation: {
      escapeValue: false,

      format: (
        value: any,
        format: string | undefined,
        lng: string | undefined,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _options,
      ) => {
        // https://date-fns.org/v2.28.0/docs/format
        if (new Date(value).toString() !== 'Invalid Date') {
          const dateValue = typeof value === 'string' ? new Date(value) : value
          const locale = (localesFns as any)[lng || '']
          if (format === 'short') return formatDate(dateValue, 'P', { locale })
          if (format === 'medium')
            return formatDate(dateValue, 'PP', { locale })
          if (format === 'long')
            return formatDate(dateValue, 'PPPP', { locale })
          if (format === 'relative')
            return formatRelative(dateValue, new Date(), { locale })
          if (format === 'ago') {
            const localDate = new Date()
            const seconds = Math.floor(((localDate as any) - dateValue) / 1000)
            const weekSeconds = 7 * 24 * 60 * 60 - 30
            if (seconds > weekSeconds) {
              return formatDate(dateValue, 'MMM d', { locale })
            }
            return formatDistance(dateValue, localDate, {
              locale,
              addSuffix: true,
            })
          }

          return formatDate(dateValue, format || '', { locale })
        }

        return value
      },
    },
  })

export default i18n
