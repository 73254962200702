import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AppBar, Box, Slide } from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { setMenuBarIsHidden } from '../../../store/configuration/configurationSlice'
import { Styles } from '../../../types'
import CombinedMenus from '../CombinedMenus'

const styles: Styles = {
  appBar: (theme) => ({
    backgroundColor: theme.palette.common.white,
    zIndex: 1300,
  }),
}

type MainMenuProps = {
  currentItem?: string
  subNavSlot?: React.ReactNode
}

interface SliderProps {
  children: React.ReactElement
}

const HideOnScroll = ({ children }: SliderProps) => {
  const dispatch = useDispatch()
  const trigger = useScrollTrigger()

  useEffect(() => {
    dispatch(setMenuBarIsHidden(trigger))
  }, [trigger])

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const MainMenu = ({ currentItem = '', subNavSlot = null }: MainMenuProps) => {
  const { isMobile, isTablet } = useDeviceType()

  const computedStyles = {
    spacer: useCallback(
      () => ({
        height: subNavSlot ? '11rem' : '7.9375rem',
      }),
      [subNavSlot],
    ),
  }

  return (
    <header>
      <Box sx={computedStyles.spacer} />

      {/* We can't put AppBar into CombinedMenus :(  */}
      {isMobile || isTablet ? (
        <HideOnScroll>
          <AppBar sx={styles.appBar}>
            <CombinedMenus
              currentItem={currentItem}
              bottomBorder={subNavSlot == null}
            />
            {subNavSlot}
          </AppBar>
        </HideOnScroll>
      ) : (
        <AppBar sx={styles.appBar}>
          <CombinedMenus currentItem={currentItem} />
        </AppBar>
      )}
    </header>
  )
}

export default MainMenu
