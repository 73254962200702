import React, { useEffect } from 'react'
import { Chart, registerables } from 'chart.js'
import PieChartProps from './PieChartProps'
import '../../../index.css'

const PieChart = ({ tsProps }: { tsProps: PieChartProps | undefined }) => {
  Chart.register(...registerables)

  useEffect(() => {
    if (undefined !== tsProps) {
      const ctx: any = document.getElementById(tsProps.chartId)

      const pieChart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: tsProps.chartItems.map((d) => d.metricName),
          datasets: [
            {
              data: tsProps.chartItems.map((d) => d.metricValue),
              backgroundColor: tsProps.chartItems.map((d) => d.metricColor),
              borderColor: tsProps.chartItems.map((d) => d.metricColor),
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      })

      /*

    // This onClick handler is left in for future reference
    // The On Click handler for the Chart.js chart
    const chartElement = document.getElementById(tsProps.chartId);
    if ( chartElement !== null )
    {
        chartElement.onclick = function(evt)
        {
            const points = pieChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

            if (points.length) {
                const firstPoint = points[0];
                alert("Slice Index: " && firstPoint.index);
            }      
        }
    } 
    
    */

      return () => {
        pieChart.destroy()
      }
    }

    return undefined
  })

  return (
    <canvas
      className="desktop:h-[177px] desktop:w-[177px] mt-[1px] mobile:h-[162px] mobile:w-[162px] pr-[0px] mr-[0px] mobile:mr-[17px]"
      id={tsProps?.chartId}
    />
  )
}

export default PieChart
