import React from 'react'
import { Box, Typography, TextField, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

const commentsTextLimit = 1000
const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiFormHelperText-root': { margin: '0rem' },
})

interface CommentsProps {
  handleChange: (comments: string) => void
}

const Comments = ({ handleChange }: CommentsProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Box style={{ width: '100%' }}>
      <StyledLabel htmlFor="comments">
        {t('Invoicing.PayOnAccountPage.Comments')}
      </StyledLabel>
      <Box style={{ maxWidth: '47.125rem' }}>
        <Controller
          name="comments"
          control={control}
          defaultValue=""
          rules={{
            maxLength: commentsTextLimit,
          }}
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              id="comments"
              variant="outlined"
              fullWidth
              multiline
              value={value}
              rows={4}
              inputProps={{
                maxLength: commentsTextLimit,
                'data-testid': 'Inp_PayOnAccount_Comments',
              }}
              onChange={(e) => {
                onChange(e)
                handleChange(e.target.value)
              }}
              helperText={
                <Typography
                  component="span"
                  sx={(theme) => ({
                    float: 'right',
                    fontFamily: 'Prelo-Book, sans-serif',
                    fontSize: '1rem',
                    fontWeight: 400,
                    color: theme.palette.text.primary,
                    paddingTop: '0rem',
                  })}
                  data-testid="Txt_PayOnAccount_CommentsLength">
                  {value.length}/{commentsTextLimit}
                </Typography>
              }
            />
          )}
        />
      </Box>
    </Box>
  )
}

export default Comments
