import React from 'react'

const ToDoListIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.5709 55.5407L60.4709 74.4007C60.4609 76.5407 58.7209 78.2607 56.5809 78.2507L12.7309 78.0107C10.5909 78.0007 8.8709 76.2607 8.8809 74.1207L9.2209 11.2407C9.2309 9.10067 10.9709 7.38067 13.1109 7.39067L19.0209 7.47067"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.0605 7.55078L56.9605 7.63078C59.1005 7.64078 60.8205 9.38078 60.8105 11.5208L60.6305 45.4008"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5137 21.5088L18.6738 21.4609L18.626 30.3008L27.4659 30.3486L27.5137 21.5088Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.4278 37.4287L18.5879 37.3809L18.5401 46.2207L27.3799 46.2686L27.4278 37.4287Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3438 53.0771L18.5039 53.0293L18.4561 61.8692L27.2959 61.917L27.3438 53.0771Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.2012 24.0312L51.7112 24.1312"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.1719 27.9512L44.4519 28.0212"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.1113 39.3203L51.6313 39.4303"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.0918 43.25L44.3718 43.31"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.0215 55.5605L45.7715 55.6305"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33 59.4902L41.74 59.5402"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1211 24.9209L23.5611 27.1709L29.0711 21.2109"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0312 40.6807L23.4712 42.9307L28.9812 36.9707"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.2109 70.041L44.2709 61.041L49.5309 66.351L40.2109 70.041Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.3615 40.8711L44.2715 61.0411L49.5315 66.3511L70.4415 45.8711L65.3615 40.8711Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.5813 37.6399L65.3613 40.8599L70.4413 45.8599L73.8613 42.0899C73.8613 42.0899 75.1113 40.6599 72.7713 38.4799C70.4313 36.2999 68.5813 37.6399 68.5813 37.6399V37.6399Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.0105 2.03045C49.7105 2.03045 51.0805 3.43045 51.0705 5.13045V7.55045C51.0405 10.5704 51.0405 10.6904 51.0405 10.6904L18.9805 10.5205C18.9805 10.5205 18.9805 10.4004 19.0005 7.38045V4.70045C19.0205 3.15045 20.2905 1.89045 21.8505 1.90045L48.0105 2.04045"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ToDoListIcon
