import { tokens } from '../../styles/materialTheme'
import { Styles } from '../../types'

const styles: Styles = {
  container: () => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    fontFamily: 'Prelo-Light',
    color: tokens.colors.rsmGray.copy,
  }),
  modalContent: (theme) => ({
    overflowY: 'auto',
    zIndex: 3001,
    '& .MuiDialogContent-root': {
      padding: '0',
      margin: '0',
      paddingTop: '3rem',
      paddingLeft: '4rem',
      paddingRight: '4rem',
      paddingBottom: '3.4375rem',
      maxWidth: '100%',
      [theme.breakpoints.down('tablet')]: {
        paddingLeft: '0.9375rem',
        paddingRight: '0.9375rem',
      },
    },
  }),
  heading: (theme) => ({
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('tablet')]: {
      fontSize: '1.875rem',
    },
  }),
  headerDesc: {
    paddingTop: '0.5rem',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  question: {
    paddingTop: '3.5rem',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    paddingBottom: '1.875rem',
  },
  boxContainer: {
    padding: '1.5rem 2.5rem 1.5rem 1.5rem',
    gap: '1.5rem',
    background: '#FFFFFF',
    border: '0.0625rem solid #CCCCCC',
    cursor: 'pointer',
    minHeight: '9.375rem',
    display: 'flex',
    alignItems: 'center',
  },
  boxWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.375rem',
    justifyContent: 'center',
  },
  boxTitle: {
    fontSize: '0.75rem',
    fontFamily: 'Prelo-Bold',
    textTransform: 'uppercase',
    paddingBottom: '1rem',
  },
  boxLinkRoot: () => ({
    fontFamily: 'Prelo-Medium',
    '& > a ': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      lineHeight: 0,
      '&:focus': {
        outline: '0.125rem solid black',
      },
      '> span': {
        fontSize: '1rem',
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
  navCardContainer: (theme) => ({
    padding: '1.5rem 2.5rem 1.5rem 1.5rem',
    [theme.breakpoints.only('mobile')]: {
      padding: '1.5rem 0.35rem 1.5rem 1.5rem',
    },
    background: '#FFFFFF',
    border: '0.0625rem solid #CCCCCC',
    cursor: 'pointer',
    minHeight: '9.375rem',
    display: 'block',
  }),
  navDescriptionContainer: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      gap: '1.5rem',
    },
    display: 'flex',
    alignItems: 'center',
  }),
}

export default styles
