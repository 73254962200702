import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getCemFeatures, getUserInfo } from '../../store/userInfo/userInfoSlice'
import { isCemFeatureEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../utils/constants/constants'
import analyticsClickTracker from '../../analytics/eventListeners'

const TrackingManager = () => {
  const [dateConsentChanged, setDateConsentChanged] = useState<Date | null>(
    null,
  )
  const [trackingScriptsInitialized, setTrackingScriptsInitialized] =
    useState<boolean>(false)
  const userInfo = useSelector(getUserInfo)
  const cemFeatures = useSelector(getCemFeatures)
  const isInvoiceUser = isCemFeatureEnabled(
    [
      CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
      CEM_FEATURE_PAYMENT_PAY_INVOICES,
    ],
    cemFeatures,
    'any',
  )

  // Define the event handler for when OneTrust is updated.
  const handleOneTrustUpdated = () => {
    setDateConsentChanged(new Date())
  }

  // Define the event handler for when pendo is loaded.
  const handlePendoLoaded = () => {
    // Wait to ensure the pendo object is available before initializing.
    setTimeout(() => {
      ;(window as any).pendo?.initialize({
        visitor: {
          id: userInfo.userId, // Required if user is logged in
          email: userInfo.emailAddress, // Recommended if using Pendo Feedback, or NPS Email
          full_name: `${userInfo.firstName} ${userInfo.lastName}`, // Recommended if using Pendo Feedback
          role: userInfo.userType, // Optional
          isEmployee: userInfo.isEmployee, // Optional
          isInvoicing: isInvoiceUser, // Optional
        },
        account: {
          id: userInfo.userId, // Highly recommended, required if using Pendo Feedback
        },
      })
    }, 1000)
  }

  // LOAD ONETRUST: Only load the OneTrust script when the user is logged in to prevent the banner from flashing before the login page is shown.
  useEffect(() => {
    if (!userInfo?.userId) {
      return () => {}
    }

    // Subscribe to the event.
    window.addEventListener('OneTrustGroupsUpdated', handleOneTrustUpdated)

    // Add OneTrust script to the head.
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`
    script.setAttribute('data-language', 'en')
    script.setAttribute(
      'data-domain-script',
      process.env.REACT_APP_ONETRUST_DOMAIN_SCRIPT || '',
    )
    document.head.appendChild(script)

    // Unsubscribe from the events.
    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handleOneTrustUpdated)
    }
  }, [userInfo])

  // LOAD TRACKING SCRIPTS: Load the tracking scripts once the user is logged in and the OneTrust banner has been accepted.
  useEffect(() => {
    // If tracking was already enabled, and user changed their consent to opt out of "C0003" performance cookies, stop tracking in pendo & adobe.
    if (
      trackingScriptsInitialized &&
      !(window as any)?.OnetrustActiveGroups?.includes('C0003')
    ) {
      ;(window as any).pendo?.stopSendingEvents()
      window.removeEventListener('click', analyticsClickTracker, true)
      const adobeScript = document.getElementById('script_adobe')
      adobeScript?.remove()
      return () => {}
    }

    // If the user is not logged in or we've already initialized pendo, then don't load tracking scripts.
    if (!userInfo?.userId || trackingScriptsInitialized) {
      return () => {}
    }

    // Adobe Launch
    const addAdobeLaunchScript = () => {
      const script = document.createElement('script')
      script.async = true
      document.head.appendChild(script)
      script.onload = () => {
        window.adobeDataLayer = window.adobeDataLayer || []
        window.addEventListener('click', analyticsClickTracker, true)
      }
      script.setAttribute('id', 'script_adobe')
      script.src = process.env.REACT_APP_ADOBE_ANALYTICS || ''
    }
    addAdobeLaunchScript()

    // Subscribe to the event that will be raised when pendo is loaded.
    window.addEventListener('pendoLoaded', handlePendoLoaded)

    // Execute the pendo script in index.html (which will raise the "pendoLoaded" event)
    ;(window as any).globalPendoInit()

    setTrackingScriptsInitialized(true)

    return () => {
      window.removeEventListener('pendoLoaded', handlePendoLoaded)
    }
  }, [userInfo, dateConsentChanged])

  return null
}

export default TrackingManager
