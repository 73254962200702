import React from 'react'
import { Grid, Paper, Fab, styled, Box, useTheme } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesUp } from '@fortawesome/free-solid-svg-icons'
import { t } from 'i18next'

import Loader from '../../rsmCoreComponents/Loader'

import Header from './Header/Header'
import Search from './Search/Search'
import ProjectsProvider from './state/ProjectsProvider'
import SupportCard from './SupportCard/SupportCard'
import ScrollTop from '../../rsmCoreComponents/components/ScrollTop'

import ProjectList from './ProjectList/ProjectList'
import useProjects from '../../api/user/hooks/useProjects'
import MovedOrDeletedError from '../MoveOrDeletedError/MovedOrDeletedError'
import useStyles from './Project.styles'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import ReportAnIssueCard from './ReportAnIssueCard/ReportAnIssueCard'

const Content = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}))

const Projects = () => {
  const { results, loading } = useProjects()
  const classes = useStyles()
  const { isMobile, isTablet } = useDeviceType()

  const theme = useTheme()

  const pageSize = isMobile || isTablet ? 10 : 25

  return (
    <Loader loading={loading}>
      {!results?.length ? (
        <MovedOrDeletedError />
      ) : (
        <ProjectsProvider
          initialState={{
            projects: results,
            pageSize,
          }}>
          <Content elevation={0}>
            <Grid container direction="row" sx={classes.grid}>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Grid sx={{ pb: 4 }} item xs={12}>
                <Search />
              </Grid>
              <Grid sx={{ p: 0 }} item xs={12} lg={8}>
                <ProjectList />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={classes.sCard}
                  data-testid="Sec_Project_SupportCardDesktop">
                  <SupportCard />
                  <Box sx={{ marginTop: '1rem' }}>
                    <ReportAnIssueCard />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Content>
          <Box
            sx={classes.fullSCard}
            data-testid="Sec_Project_SupportCardMobile">
            <Box sx={isTablet ? { display: 'flex' } : null}>
              <SupportCard fullWidth /> <ReportAnIssueCard fullWidth />{' '}
            </Box>
          </Box>
        </ProjectsProvider>
      )}
      <ScrollTop>
        <Fab
          size="small"
          sx={{ backgroundColor: theme.palette.background.paper }}
          aria-label={t('Projects.GoToTop')}
          data-testid="Btn_Projects_GoToTop">
          <FontAwesomeIcon
            icon={faAnglesUp}
            color={theme.palette.text.primary}
          />
        </Fab>
      </ScrollTop>
    </Loader>
  )
}

export default Projects
