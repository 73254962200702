import React from 'react'

const TreasuryIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.0209 11.7504C40.4209 11.3804 39.6909 11.1104 38.9909 10.9904C37.1509 10.6904 35.0509 11.5804 35.2009 13.6004C35.3509 15.6504 37.6709 15.6004 39.2809 15.9304C40.2409 16.1204 41.2309 16.3004 41.7409 17.2504C42.3309 18.3504 41.5809 19.7404 40.5409 20.2404C39.9409 20.5304 39.1709 20.6604 38.5009 20.6604C37.3209 20.6604 35.5709 20.3304 34.6309 19.6204"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.4902 9.16992V22.2999"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M72.6905 69.6211H6.73047V74.3111H72.6905V69.6211Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8508 31.6196C11.8208 30.8296 12.7008 30.3296 13.3208 30.7696C14.1008 31.3196 14.2808 32.3896 13.7308 33.1696C13.0408 34.1396 11.7008 34.3696 10.7308 33.6896C9.5208 32.8296 9.2308 31.1496 10.0908 29.9396C10.5908 29.2296 11.3108 28.7796 12.0908 28.5996H19.9508C20.7308 28.7796 21.4508 29.2296 21.9508 29.9396C22.8108 31.1496 22.5208 32.8296 21.3108 33.6896C20.3408 34.3796 19.1908 34.3296 18.2008 33.4196C17.5008 32.7796 17.7008 31.5296 18.4808 30.9796C19.1008 30.5396 20.3808 30.4496 20.3808 31.7696"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.3809 65.9902V34.1602"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.6504 34.1602V65.9902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.0215 35.7188V63.1087"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.3216 65.9902H9.68164V69.6202H22.3216V65.9902Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.5 26.0002L38.72 1.66016L69.93 26.0002H7.5Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.541 22.1803L38.531 5.57031L60.401 22.1803"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.0404 74.3105H3.40039V79.0005H76.0404V74.3105Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6906 31.6196C27.6606 30.8296 28.5406 30.3296 29.1606 30.7696C29.9406 31.3196 30.1206 32.3896 29.5706 33.1696C28.8806 34.1396 27.5406 34.3696 26.5706 33.6896C25.3606 32.8296 25.0706 31.1496 25.9306 29.9396C26.4306 29.2296 27.1506 28.7796 27.9306 28.5996H35.7906C36.5706 28.7796 37.2906 29.2296 37.7906 29.9396C38.6506 31.1496 38.3607 32.8296 37.1507 33.6896C36.1806 34.3796 35.0306 34.3296 34.0406 33.4196C33.3406 32.7796 33.5406 31.5296 34.3206 30.9796C34.9406 30.5396 36.2206 30.4496 36.2206 31.7696"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.2305 65.9902V34.1602"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.5 34.1602V65.9902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.8711 35.7188V63.1087"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.1712 65.9902H25.5312V69.6202H38.1712V65.9902Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.0012 31.6196C42.9712 30.8296 43.8512 30.3296 44.4712 30.7696C45.2512 31.3196 45.4312 32.3896 44.8812 33.1696C44.1912 34.1396 42.8512 34.3696 41.8812 33.6896C40.6712 32.8296 40.3812 31.1496 41.2412 29.9396C41.7412 29.2296 42.4612 28.7796 43.2412 28.5996H51.1012C51.8812 28.7796 52.6012 29.2296 53.1012 29.9396C53.9612 31.1496 53.6712 32.8296 52.4612 33.6896C51.4912 34.3796 50.3412 34.3296 49.3512 33.4196C48.6512 32.7796 48.8512 31.5296 49.6312 30.9796C50.2512 30.5396 51.5312 30.4496 51.5312 31.7696"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M43.541 65.9902V34.1602"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.8105 34.1602V65.9902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M47.1719 35.7188V63.1087"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M53.472 65.9902H40.832V69.6202H53.472V65.9902Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M58.8508 31.6196C58.8208 30.8296 59.7008 30.3296 60.3208 30.7696C61.1008 31.3196 61.2808 32.3896 60.7308 33.1696C60.0408 34.1396 58.7008 34.3696 57.7308 33.6896C56.5208 32.8296 56.2308 31.1496 57.0908 29.9396C57.5908 29.2296 58.3108 28.7796 59.0908 28.5996H66.9508C67.7308 28.7796 68.4508 29.2296 68.9508 29.9396C69.8108 31.1496 69.5208 32.8296 68.3108 33.6896C67.3408 34.3796 66.1908 34.3296 65.2008 33.4196C64.5008 32.7796 64.7008 31.5296 65.4808 30.9796C66.1008 30.5396 67.3808 30.4496 67.3808 31.7696"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M59.3906 65.9902V34.1602"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66.6602 34.1602V65.9902"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M63.0215 35.7188V63.1087"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M69.3216 65.9902H56.6816V69.6202H69.3216V65.9902Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default TreasuryIcon
