/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  TeamMember,
  getRSMTeamMembers,
  getClientTeamMembers,
} from './TeamMembers.service'

const OverflowTip = ({ children }: { children: React.ReactNode }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (textElementRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { clientWidth = 0, scrollWidth = 0 } = textElementRef.current as any
      setIsOverflow(scrollWidth > clientWidth)
    }
  }, [children])

  if (!children) {
    return null
  }

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {children}
      </div>
    </Tooltip>
  )
}

export const Member = ({ teamMember }: { teamMember: TeamMember }) => {
  const { name, email } = teamMember
  const theme = useTheme()

  return (
    <Stack
      data-testid="teamMemberComponent"
      spacing={0}
      sx={{ paddingBottom: '1rem' }}>
      <Box
        sx={{
          px: '0.25rem',
          py: 0,
          m: 0,
          fontSize: '1rem',
          mr: '1rem',
          display: 'flex',
          justifyContent: 'flex-start',
          justifyItems: 'flex-start',
        }}>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontWeight: theme.typography.fontWeightBold,
          }}>
          <OverflowTip>{name}</OverflowTip>
        </Typography>
      </Box>

      <Link
        tabIndex={0}
        sx={{
          px: '0.25rem',
          py: '0.25rem',
          '&:focus': {
            boxShadow: 'inset 0 0 0 0.125rem black !important',
          },
        }}
        target="_top"
        rel="noopener noreferrer"
        aria-label={email}
        href={`mailto:${email}`}>
        <Stack direction="row" spacing={1}>
          <Box
            sx={{
              display: 'block',
              fontWeight: theme.typography.fontWeightBold,
            }}>
            <FontAwesomeIcon icon={faEnvelope} />
          </Box>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              paddingRight: '1rem',
            }}>
            <OverflowTip>{email}</OverflowTip>
          </Typography>
        </Stack>
      </Link>
    </Stack>
  )
}

interface MembersProps extends CardProps {
  title: string
  teamMembers: TeamMember[]
}

export const Members = ({ title, teamMembers, ...rest }: MembersProps) => {
  const theme = useTheme()
  return (
    <Card {...rest}>
      <CardHeader
        sx={{ px: 0, pb: '2rem' }}
        title={title}
        titleTypographyProps={{
          variant: 'h5',
          fontWeight: theme.typography.fontWeightMedium,
        }}
      />
      <CardContent sx={{ p: 0 }}>
        <Grid container>
          {teamMembers.map((teamMember: TeamMember) => (
            <Grid key={teamMember.id} xs={12} md={12} lg={6} item>
              <Member
                data-testid="teamMemberComponent"
                key={teamMember.id}
                teamMember={teamMember}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}

const TeamMembers = ({
  engagementUsers,
}: {
  engagementUsers: TeamMember[]
}) => {
  const { t } = useTranslation()

  const rsmTeamMembers = getRSMTeamMembers(engagementUsers)
  const clientTeamMembers = getClientTeamMembers(engagementUsers)

  return (
    <Grid container sx={{ display: 'flex', mb: '1.5rem' }}>
      <Grid xs={12} md={6} item>
        <Members
          data-testid="rsmTeamMembers"
          title={t('EngagementDashboard.TeamMembers.RSMTeam')}
          teamMembers={rsmTeamMembers}
        />
      </Grid>
      <Grid xs={12} md={6} item>
        <Members
          data-testid="clientTeamMembers"
          title={t('EngagementDashboard.TeamMembers.ClientTeam')}
          teamMembers={clientTeamMembers}
        />
      </Grid>
    </Grid>
  )
}

export default TeamMembers
