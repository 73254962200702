import React from 'react'
import { Outlet } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import Footer from '../Footer'
import Main from '../Main'
import useAppInsights from '../../../rsmCoreComponents/hooks/appInsights'
import { getHighContrast } from '../../../store/userSettings/userSettingsSlice'
import { accessibilityTheme, normalTheme } from '../../../styles/materialTheme'
import { useAppSelector } from '../../../utils/hooks'
import Popover from '../../Popover/Popover'
import ProfileModalContainer from '../../Profile/ProfileModal/ProfileModalContainer'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import WelcomeModal from '../../WelcomeModal/WelcomeModal'

const Layout = () => {
  const isHighContrast = useAppSelector(getHighContrast)

  return (
    <ThemeProvider theme={isHighContrast ? accessibilityTheme : normalTheme}>
      <Popover />
      <Main>
        <Outlet />
      </Main>
      <Footer />
      {isFeatureFlagEnabled('UserProfile') && <ProfileModalContainer />}
      <WelcomeModal />
    </ThemeProvider>
  )
}

export default useAppInsights(Layout)
