import React from 'react'

const FileIconAdd = () => (
  <svg
    width="83"
    height="97"
    viewBox="0 0 83 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.873 75H69.873V4H13.873V75Z"
      stroke="#888B8D"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.873 75H69.873V4H13.873V75Z"
      stroke="#888B8D"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.873 76H69.873V4H12.873V76Z"
      stroke="#888B8D"
      strokeLinecap="round"
    />
    <path d="M19.873 12H50.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 20H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 39H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 24H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 43H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 28H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 47H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 32H46.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 51H38.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 59H61.873" stroke="#888B8D" strokeLinecap="round" />
    <path d="M19.873 63H61.873" stroke="#888B8D" strokeLinecap="round" />
    <circle
      cx="59.5005"
      cy="73.627"
      r="16"
      transform="rotate(-45 59.5005 73.627)"
      fill="#3F9C35"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4499 65.1484L58.5508 65.1484V72.6774L51.0218 72.6774V74.5765L58.5508 74.5765V82.1055L60.4499 82.1055L60.4499 74.5765H67.9789V72.6774H60.4499L60.4499 65.1484Z"
      fill="#515356"
    />
  </svg>
)

export default FileIconAdd
