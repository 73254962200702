import React, { useMemo, useState } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function sortArray<T>(items: T[], isDescending: boolean) {
  items.sort((a, b) => {
    if (typeof a === 'string' || typeof b === 'string') {
      const aStr = `${a || ''}`.toLowerCase()
      const bStr = `${b || ''}`.toLowerCase()
      if (aStr < bStr) {
        return !isDescending ? -1 : 1
      }
      if (aStr > bStr) {
        return !isDescending ? 1 : -1
      }
      return 0
    }
    if ((a ?? 0) < (b ?? 0)) {
      return !isDescending ? -1 : 1
    }
    if ((a ?? 0) > (b ?? 0)) {
      return !isDescending ? 1 : -1
    }
    return 0
  })
}

export function sortObjects<T>(
  items: T[],
  key: keyof T,
  isDescending: boolean,
) {
  items.sort((a, b) => {
    if (typeof a?.[key] === 'string' || typeof b?.[key] === 'string') {
      const aStr = `${a?.[key]}`.toLowerCase()
      const bStr = `${b?.[key]}`.toLowerCase()
      if (aStr < bStr) {
        return !isDescending ? -1 : 1
      }
      if (aStr > bStr) {
        return !isDescending ? 1 : -1
      }
      return 0
    }
    if ((a?.[key] ?? 0) < (b?.[key] ?? 0)) {
      return !isDescending ? -1 : 1
    }
    if ((a?.[key] ?? 0) > (b?.[key] ?? 0)) {
      return !isDescending ? 1 : -1
    }
    return 0
  })
}

/**
 * @param {T} items - the array of items to sort.
 * @param {key: keyof T} initialSort - the initial property on T to sort on.
 * @param {isDescending: boolean} initialSortDescending - whether to sort ascending or descending initially.
 * @description - A reusable function for sorting columns in a grid.
 * @param {handleTableSort: void} handleTableSort - to store the table config while click on header
 * @param {tableSortConfig: object} tableSortConfig - to get the table sorted config
 */
export default function sortableData<T>(
  items: T[],
  initialSort: keyof T,
  initialSortDescending: boolean,
  reactUseState: typeof useState,
  reactUseMemo: typeof useMemo,
  handleTableSort: any,
  tableSortConfig = {},
) {
  // we have a issue when component is re-render its getting override the sort so we keep existing
  const [sortConfig, setSortConfig] = reactUseState({
    key: initialSort,
    isDescending: initialSortDescending,
    ...tableSortConfig,
  })

  const sortedItems = reactUseMemo(() => {
    const sortableItems = [...items.filter((x) => !!x)]
    sortObjects(sortableItems, sortConfig.key, sortConfig.isDescending)
    return sortableItems
  }, [items, sortConfig]) as T[]

  const sortIcon = (sortKey: keyof T, hover?: boolean) => {
    // If we hover don't return null
    if (!hover) {
      if (sortConfig.key !== sortKey) {
        return null
      }
    }
    // If the column is selected display chevron up or down on sort
    if (sortConfig.key === sortKey) {
      if (sortConfig.isDescending) {
        return (
          <FontAwesomeIcon className="ml-2" icon={faChevronDown as IconProp} />
        )
      }
      return <FontAwesomeIcon className="ml-2" icon={faChevronUp as IconProp} />
    }
    // If the column is not selected we are hovering and should only show up chevron
    return <FontAwesomeIcon className="ml-2" icon={faChevronUp as IconProp} />
  }

  const ariaSort = (sortKey: keyof T) => {
    if (sortConfig.key === sortKey) {
      return sortConfig.isDescending ? 'descending' : 'ascending'
    }
    return undefined
  }

  const handleSort = (key: keyof T) => {
    let isDescending = false
    if (sortConfig?.key === key) {
      isDescending = !sortConfig.isDescending
    }
    const sortD = { ...sortConfig, key, isDescending }
    handleTableSort?.(sortD)
    setSortConfig(sortD)
  }

  return { items: sortedItems, sortIcon, ariaSort, handleSort }
}
