/* eslint-disable */
import { createApi } from '@reduxjs/toolkit/query/react'
import { USERSERVICE_BASE_URL } from '../../envVariables'
import { axiosBaseQuery, Response } from '../../api/BaseQuery'
import {
  ContactMethodViewModel,
  NotificationFrequencyViewModel,
  TimeZoneViewModel,
  UserContactPreferenceRequestViewModel,
  UserContactPreferenceResponseViewModel,
} from '@rsmus/ecp-userservice'

const userService = createApi({
  reducerPath: 'userService',
  // Seconds to keep data after subscriber count is zero (default 60).
  keepUnusedDataFor: 30,
  // Encourage refetching stale data after N seconds.
  refetchOnMountOrArgChange: false,
  // Refetch on window focus.
  refetchOnFocus: false,
  // Refetch on network reconnect.
  refetchOnReconnect: true,
  tagTypes: ['preferences'],
  baseQuery: axiosBaseQuery({
    baseURL: USERSERVICE_BASE_URL,
  }),
  endpoints: (builder) => ({
    getTimeZoneList: builder.query<Response<TimeZoneViewModel[]>, void>({
      query: () => ({
        url: '/api/v1/userprofile/timezones',
        method: 'get',
      }),
    }),
    getContactMethodsList: builder.query<
      Response<ContactMethodViewModel[]>,
      void
    >({
      query: () => ({
        url: '/api/v1/userprofile/contactmethods',
        method: 'get',
      }),
    }),
    getNotificationFrequenciesList: builder.query<
      Response<NotificationFrequencyViewModel[]>,
      void
    >({
      query: () => ({
        url: '/api/v1/userprofile/NotificationFrequencies',
        method: 'get',
      }),
    }),
    getUserContactPreferenceDetails: builder.query<
      Response<UserContactPreferenceResponseViewModel>,
      void
    >({
      query: () => ({
        url: '/api/v1/userprofile/preferences',
        method: 'get',
      }),
      providesTags: ['preferences'],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            const {
              timeZone,
              engagementActivityNotificationFrequency,
              insightsActivityNotificationFrequency,
              preferredContactMethod,
              visited,
            } = data.data
            if (!visited)
              dispatch(
                userService.endpoints.setUserContactPreferenceDetails.initiate({
                  ...data.data,
                  timeZoneId: timeZone?.id ?? 3,
                  engagementActivityNotificationFrequencyId:
                    engagementActivityNotificationFrequency?.id ?? 4,
                  insightsActivityNotificationFrequencyId:
                    insightsActivityNotificationFrequency?.id ?? 4,
                  preferredContactMethodId: preferredContactMethod?.id ?? 1,
                  visited: true,
                }),
              )
          })
          .catch(() => {
            dispatch(
              userService.endpoints.setUserContactPreferenceDetails.initiate({
                clientBusinessName: '',
                timeZoneId: 3,
                engagementActivityNotificationFrequencyId: 4,
                insightsActivityNotificationFrequencyId: 4,
                businessPhone: '',
                preferredContactMethodId: 1,
                visited: true,
              }),
            )
          })
      },
    }),
    setUserContactPreferenceDetails: builder.mutation<
      Response<boolean>,
      UserContactPreferenceRequestViewModel
    >({
      query: (data) => ({
        url: '/api/v1/userprofile/Preferences',
        data,
        method: 'put',
      }),
      invalidatesTags: ['preferences'],
    }),
  }),
})

export const {
  useGetTimeZoneListQuery,
  useGetNotificationFrequenciesListQuery,
  useGetContactMethodsListQuery,
  useGetUserContactPreferenceDetailsQuery,
  useSetUserContactPreferenceDetailsMutation,
} = userService

export default userService
