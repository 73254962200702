import processAdobeAnalytics from './adobe/processAdobeAnalytics'

const processors = [processAdobeAnalytics]

const processAnalytics = (analyticsObject: any) => {
  // iterate over each processor
  processors.forEach((p) => p(analyticsObject))
}

export default processAnalytics
