import { createSlice } from '@reduxjs/toolkit'

interface ContactPreferenceFormState {
  FormValues: ContactPreferenceFormValues
}

interface ContactPreferenceFormValues {
  isSubmissionSuccessful: boolean | undefined
  isSubmissionFailed: boolean | undefined
  isViewOnlyMode: boolean
}

const initialState: ContactPreferenceFormState = {
  FormValues: {
    isSubmissionSuccessful: undefined,
    isSubmissionFailed: undefined,
    isViewOnlyMode: true,
  },
}

export const profilePageSlice = createSlice({
  name: 'profilePage',
  initialState,
  reducers: {
    setContactPreferenceFormState: (state, { payload }) => {
      state.FormValues = payload
    },
  },
})

export const { setContactPreferenceFormState } = profilePageSlice.actions

export default profilePageSlice.reducer
