import React from 'react'

// material ui components
import { styled, Table as MuiTable, TableContainer, Paper } from '@mui/material'

const StyledContainer = styled(TableContainer)({
  div: {
    boxShadow: '0px 0px 0px 0px',
  },
})

type RsmTableProps = {
  children: React.ReactNode
  testId?: string
}

const RsmTable = ({ children, testId = '' }: RsmTableProps) => (
  <div data-testid="rsmtable">
    <StyledContainer>
      <TableContainer component={Paper}>
        <MuiTable
          sx={{ tableLayout: 'fixed', width: '100%' }}
          data-testid={testId ?? ''}>
          {children}
        </MuiTable>
      </TableContainer>
    </StyledContainer>
  </div>
)

export default RsmTable
