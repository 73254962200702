import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import SectionHeader from '../../layouts/SectionHeader'
import { Styles } from '../../../types'
import { tokens } from '../../../styles/materialTheme'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'

const styles: Styles = {
  cardContainer: (theme) => ({
    background: theme.palette.common.white,
    border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
  }),
  cells: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down('desktop')]: {
      flexDirection: 'column',
    },
  }),
  cell: (theme) => ({
    flex: '1 1 50%',
    [theme.breakpoints.up('desktop')]: {
      '&:first-of-type': {
        flex: '1 1 35%',
      },
      '&:last-of-type': {
        flex: '1 1 65%',
      },
    },
    [theme.breakpoints.down('tablet')]: {
      flex: 'none',
    },
  }),
  cellTitle: {
    fontWeight: 800,
    marginBottom: '0.5rem',
  },
  description: {
    whiteSpace: 'pre-line',
  },
  emailAddresses: {
    whiteSpace: 'pre-line',
  },
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '800',
  textDecoration: 'underline',
}))

const AccountSupport = () => {
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useDeviceType()
  const externalPhone = t('Invoicing.AccountSupport.PhoneNumber')

  return (
    <Box sx={styles.cardContainer}>
      <SectionHeader
        title={t('CallUs.Heading')}
        testId="Lbl_ContactUs_Header"
      />
      <Box sx={styles.cells}>
        <Box sx={styles.cell}>
          <Box
            component="h3"
            sx={styles.cellTitle}
            data-testid="Txt_ContactUs_Title">
            {t('Invoicing.AccountSupport.PhoneTitle')}
          </Box>
          <Box sx={styles.description} data-testid="Txt_ContactUs_Description">
            {t('Invoicing.AccountSupport.PhoneDescription')}
          </Box>
          <Box>
            <Box component="span" sx={{ fontWeight: 800 }}>
              {t('Invoicing.AccountSupport.PhoneNumberPrefix')}
            </Box>
            <StyledLink
              to={`tel:${externalPhone?.replaceAll('.', '')}`}
              sx={{ textDecoration: 'none' }}
              data-testid="Lnk_PayMyBillDashboard_SupportSection_CallUs_TollFree">
              {externalPhone}
            </StyledLink>
          </Box>
          <Box sx={{ fontWeight: 800 }}>
            {t('Invoicing.AccountSupport.PhoneNumberSuffix')}
          </Box>
        </Box>
        <Box sx={styles.cell}>
          <Box
            component="h3"
            sx={styles.cellTitle}
            data-testid="Txt_EmailUs_Title">
            {t('Invoicing.AccountSupport.EmailTitle')}
          </Box>
          <Box sx={styles.description} data-testid="Txt_EmailUs_Description">
            {t('Invoicing.AccountSupport.EmailDescription')}
          </Box>
          <Box
            sx={styles.emailAddresses}
            data-testid="Txt_PayMyBillDashboard_SupportSection_EmailUs_EmailAddresses">
            <Trans
              i18nKey="Invoicing.AccountSupport.EmailAddresses"
              components={[
                <Box
                  component="span"
                  sx={{
                    fontWeight: '600',

                    display: isDesktop ? 'inline-block' : 'none',
                  }}
                />,
                <StyledLink
                  sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}
                  data-testid="Lnk_PayMyBillDashboard_SupportSection_Email_Accounts_Receivable_USA"
                  to="mailto:accounts_receivable@rsmus.com"
                />,
                <StyledLink
                  sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}
                  data-testid="Lnk_PayMyBillDashboard_SupportSection_Email_Accounts_Receivable_CANADA"
                  to="mailto:accounts_receivable@rsmcanada.com"
                />,
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountSupport
