import React from 'react'

const TransactionIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131663)">
      <path
        d="M1.34961 28.8006C6.37961 13.1906 20.8996 1.39062 38.1796 1.39062C59.6196 1.39062 76.9996 18.7706 76.9996 40.2106C76.9996 61.6506 59.6196 79.0306 38.1796 79.0306C26.1296 79.0306 15.3496 73.2706 8.21961 64.5006"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.9207 51.4696L33.1007 51.7996V58.8196L60.0007 40.2096L33.1007 21.5996V28.7996H0.970703"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23.4498 61.2595C23.7498 60.7995 23.6198 60.1795 23.1598 59.8695L10.3698 51.4695L23.3898 43.4395C23.8598 43.1495 24.0098 42.5295 23.7198 42.0595C23.4298 41.5895 22.8098 41.4495 22.3398 41.7295L7.97977 50.5895C7.68977 50.7695 7.50977 51.0895 7.50977 51.4295C7.50977 51.7695 7.67977 52.0895 7.95977 52.2795L22.0598 61.5395C22.2198 61.6495 22.4098 61.6995 22.5898 61.6995C22.9198 61.6995 23.2498 61.5495 23.4398 61.2495L23.4498 61.2595Z"
        fill="#009CDE"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131663">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TransactionIcon
