import React from 'react'

const PersonListeningIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_131616)">
      <path
        d="M51.3998 47H30.5898V69.64H51.3998V47Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7988 21.47C34.7988 20.8 35.3488 20.25 36.0188 20.25C36.6988 20.25 37.2388 20.8 37.2388 21.47C37.2388 22.15 36.6888 22.69 36.0188 22.69C35.3488 22.69 34.7988 22.15 34.7988 21.47Z"
        fill="#CCCCCC"
        stroke="#CCCCCC"
        strokeMiterlimit="10"
      />
      <path
        d="M45.4103 21.4602C45.4103 20.7902 45.9603 20.2402 46.6303 20.2402C47.3003 20.2402 47.8503 20.7902 47.8503 21.4602C47.8503 22.1302 47.3003 22.6802 46.6303 22.6802C45.9603 22.6802 45.4003 22.1402 45.4103 21.4602Z"
        fill="#CCCCCC"
        stroke="#CCCCCC"
        strokeMiterlimit="10"
      />
      <path
        d="M41.8788 17.25L42.3788 27.7L38.0488 27.71"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.2695 14.8214L45.2695 11.1914"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.8691 9.77133C30.4191 9.57133 39.3191 9.77133 46.2291 3.86133"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M36.4502 70.1413H26.4102V47.0312"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7498 70.1417C40.7498 70.1417 50.0498 67.9317 50.0498 76.1817V79.0017H21.7898C21.7898 79.0017 15.7498 78.5317 15.7598 73.0317V71.9617V47.0117C15.7598 47.0117 15.7598 33.7717 29.8098 33.7617"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.2988 70.1413H55.7488V47.0312"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4191 70.1417C41.4191 70.1417 32.1191 67.9317 32.1191 76.1817V79.0017H60.3891C60.3891 79.0017 66.4191 78.5317 66.4291 73.0317V71.9617V47.0117C66.4291 47.0117 66.4291 33.7617 52.3791 33.7617H50.2891"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M25.8002 25.1908C25.8002 25.1908 22.7702 16.3808 27.7002 8.24078C30.0902 4.25078 34.4702 1.56078 39.4402 1.55078H40.0602C47.5902 1.55078 53.7502 7.71078 53.7502 15.2308V24.0308C53.7502 24.7908 53.6802 25.5408 53.5602 26.2708C53.5602 26.2708 53.2702 30.6408 50.6602 33.3608C48.1602 36.0308 44.0002 37.7108 40.0702 37.7108H39.4402C32.6702 37.7108 26.4002 32.5508 25.8002 25.1708C28.9902 24.6808 31.4402 21.9408 31.4402 18.6208V16.0108C37.2802 14.9808 46.0702 12.5208 50.0502 6.75078"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.7402 79.0003V70.0703"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7605 50.9609H40.4805V55.4909H47.7605V50.9609Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7605 58.8809H34.2305"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7605 62.2793H34.2305"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2305 65.6797H47.7605"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_131616">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PersonListeningIcon
