import { Box, Typography } from '@mui/material'
import React from 'react'
import { stringComparer } from '../../../utils/helpers/stringHelpers'
import OverFlowToolTip from '../../OverFlowToolTip'

const styles = {
  container: {
    padding: {
      mobile: '2rem 0.5rem 0.5rem 0.5rem',
      tablet: '3rem 0.5rem 0.5rem 0.5rem',
      desktop: '6rem 1rem 1rem 1rem',
    },
  },
  firstContainer: {
    padding: {
      mobile: '0rem 0.5rem 0.5rem 0.5rem',
      tablet: '3rem 0.5rem 0.5rem 0.5rem',
      desktop: '6rem 1rem 1rem 1rem',
    },
  },
  heading: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  item: {
    paddingTop: '1rem',
    maxWidth: {
      mobile: '14rem',
      tablet: '16.375rem',
      desktop: '16.375rem',
    },
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
}

export interface UsersInterestsPerCategoryProps {
  title: string
  interests: string[]
  position?: number | undefined
}

const UsersInterestsPerCategory = ({
  title,
  interests,
  position = undefined,
}: UsersInterestsPerCategoryProps) => {
  const sortedInterests = !interests
    ? []
    : interests.sort((a, b) => stringComparer(a, b))

  return (
    <Box sx={position === 0 ? styles.firstContainer : styles.container}>
      <Box key={title}>
        <Typography component="span" sx={styles.heading}>
          {title}
        </Typography>{' '}
      </Box>
      {sortedInterests.map((item) => (
        <Box
          key={item}
          sx={styles.item}
          data-testid="Sec_Profile_Interesets_Item">
          <OverFlowToolTip>{item}</OverFlowToolTip>
        </Box>
      ))}
    </Box>
  )
}

export default UsersInterestsPerCategory
