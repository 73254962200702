import * as React from 'react'
import { SVGProps } from 'react'

const CreditDiscoverIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_1"
    data-testid="credit-discover-icon"
    x="0px"
    y="0px"
    viewBox="0 0 285.4 185.8"
    enableBackground="new 0 0 285.4 185.8"
    width={width}
    height={height}>
    <title>Discover Card</title>
    <g>
      <g id="Layer_2_00000039134260138449831850000008246287915768236215_">
        <g id="Discover">
          <g>
            <path
              fill="#231F20"
              stroke="#231F20"
              strokeWidth="2.14"
              strokeMiterlimit="10"
              d="M22.3,1.1h240.9
                   c11.7,0,21.2,9.5,21.2,21.2v141.3c0,11.7-9.5,21.2-21.2,21.2H22.3c-11.7,0-21.2-9.5-21.2-21.2V22.3C1.1,10.6,10.6,1.1,22.3,1.1z
                   "
            />
            <g>
              <g>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M29.3,69c0-0.6,0.1-0.8,0.8-0.8c3.4,0.1,6.8-0.1,10.2,0.1
                           c5,0.2,9.6,1.6,13.3,5.2c2.6,2.4,4.2,5.4,4.8,8.9c1.3,6.9-0.7,12.7-5.9,17.4c-2.9,2.6-6.3,3.8-10.1,4.1
                           c-4.2,0.3-8.4,0.1-12.6,0.2c-0.5,0-0.4-0.3-0.4-0.6C29.3,99.9,29.3,74.7,29.3,69L29.3,69z M36.2,97.3c0,0.5,0.1,0.6,0.6,0.6
                           c1.4,0,2.8,0,4.2-0.2c2.5-0.3,4.9-1,6.8-2.9c3.1-3.1,4.3-6.9,3.4-11.2c-0.9-4.3-3.4-7.4-7.7-8.7c-2.1-0.7-4.3-0.6-6.5-0.6
                           c-0.6,0-0.8,0.1-0.8,0.8C36.3,78.8,36.2,93.5,36.2,97.3L36.2,97.3z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M240.8,89c3.8,5,7.4,9.9,11.2,15h-1.5c-2.1,0-4.2,0-6.3,0
                           c-0.6,0-1-0.2-1.3-0.7c-2.9-4.4-5.9-8.7-8.8-13.1c0-0.1-0.1-0.1-0.1-0.2c-0.2-0.4-0.6-0.4-0.9-0.3c-0.4,0.1-0.2,0.4-0.2,0.7
                           c0,4.3,0,8.6,0,12.9c0,0.7-0.2,0.8-0.9,0.8c-1.9-0.1-3.7,0-5.5,0c-0.5,0-0.6-0.1-0.6-0.6c0-11.5,0-23.1,0-34.6
                           c0-0.4,0-0.6,0.5-0.6c4,0.1,8-0.2,11.9,0.2c2.8,0.2,5.4,1,7.5,2.8c2.5,2.3,3.3,5.2,3.1,8.5c-0.2,4.8-2.9,8-7.5,9.1
                           C241.1,88.8,241,88.9,240.8,89L240.8,89z M232.8,79.3c0,1.6,0,3.2,0,4.8c0,0.6,0.2,0.7,0.7,0.6c1,0,2.1,0,3.1-0.1
                           c3-0.3,4.8-2.1,5-5.1c0.2-2.4-1.1-4.3-3.4-5.1c-1.5-0.5-3.1-0.4-4.7-0.5c-0.5,0-0.7,0.1-0.7,0.7
                           C232.9,76.1,232.8,77.7,232.8,79.3L232.8,79.3z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M201.5,68.9c0-0.5,0.1-0.7,0.7-0.7c6.2,0,12.3,0,18.5,0
                           c0.5,0,0.6,0.1,0.6,0.6c0,1.7,0,3.3,0,5c0,0.5-0.2,0.6-0.6,0.6c-3.8,0-7.6,0-11.4,0c-0.6,0-0.8,0.2-0.8,0.8c0,2.2,0,4.3,0,6.5
                           c0,0.5,0.1,0.7,0.7,0.7c3.7,0,7.3,0,11,0c0.6,0,0.7,0.2,0.7,0.7c0,1.6,0,3.2,0,4.8c0,0.5-0.1,0.6-0.6,0.6c-3.6,0-7.3,0-10.9,0
                           c-0.7,0-0.8,0.2-0.8,0.8c0,2.7,0,5.4,0,8.1c0,0.5,0.1,0.7,0.7,0.7c3.8,0,7.6,0,11.4,0c0.6,0,0.7,0.2,0.7,0.7
                           c0,1.6,0,3.3,0,4.9c0,0.4-0.1,0.5-0.5,0.5c-6.2,0-12.5,0-18.8,0c-0.6,0-0.5-0.3-0.5-0.7C201.5,97.5,201.5,74.6,201.5,68.9
                           L201.5,68.9z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M76,93.6c0.9,1.8,2.2,3.2,4,4c2,0.9,4,1.2,6,0.2
                           c1.8-0.8,2.9-2.3,2.9-4.3c0.1-2-1-3.3-2.7-4.2c-2-1-4.1-1.6-6.2-2.5c-2-0.9-3.9-1.9-5.2-3.6c-0.8-1.1-1.3-2.3-1.5-3.6
                           c-0.7-5.9,2.5-10.1,7.4-11.5c5.1-1.4,9.7-0.2,13.8,3.1c0.3,0.2,0.4,0.4,0.1,0.8c-1.1,1.3-2.1,2.6-3.1,4
                           c-0.3,0.4-0.4,0.3-0.7,0c-1.1-1.2-2.4-2.2-4.1-2.4c-2.1-0.3-4.1,0-5.6,1.7c-1.3,1.6-1,3.5,0.6,4.7c1.5,1.1,3.2,1.6,4.9,2.2
                           c1.6,0.6,3.1,1.3,4.6,2.1c3,1.7,4.6,4.2,4.8,7.6c0.4,5.9-2.7,10.8-8.5,12.3c-6.1,1.6-12.1-0.2-16-6c-0.2-0.2-0.2-0.4,0-0.6
                           C73.1,96.4,74.5,95,76,93.6L76,93.6z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M164,68.2c2.4,0,4.8,0,7.1,0c0.5,0,0.6,0.2,0.8,0.6
                           c2.1,5.4,8.3,20.9,9.2,23.3c0.4-0.5,6.6-16.1,9.5-23.3c0.1-0.3,0.2-0.5,0.6-0.5c2.4,0,4.7,0,7.2,0
                           c-0.6,1.3-10.7,25.4-15.2,36.1c-0.2,0.4-0.4,0.6-0.8,0.6c-0.9,0-1.8,0-2.7,0c-0.4,0-0.6-0.1-0.7-0.5
                           c-2.8-6.7-12.5-30.3-14.6-35.3C164.2,68.8,164.1,68.5,164,68.2L164,68.2z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M125.9,77.9c-1.3-1.3-2.6-2.3-4.1-3
                           c-5.9-2.8-13.1,0.2-15.4,6.5c-1.8,4.9-1.2,9.5,2.4,13.4c2.4,2.6,5.5,3.6,9,3.6c2.8,0,5.1-1.2,7.1-2.9c0.3-0.3,0.6-0.5,0.9-0.8
                           c0.2,0.1,0.2,0.3,0.2,0.5c0,2.3,0,4.7,0,7c0,0.4-0.1,0.6-0.4,0.8c-7.2,3.5-16.3,2.1-21.9-3.7c-3.5-3.6-5.2-7.8-5.3-12.8
                           c-0.2-8.4,5.4-15.8,13.2-18.1c4.8-1.4,9.5-1.2,14,1.1c0.2,0.1,0.4,0.2,0.4,0.6C125.9,72.5,125.9,75.1,125.9,77.9L125.9,77.9z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  fill="#FFFFFF"
                  d="M68.9,103.2c0,0.6-0.1,0.8-0.8,0.8c-1.9-0.1-3.8,0-5.7,0
                           c-0.5,0-0.6-0.1-0.6-0.6c0-11.5,0-23.1,0-34.6c0-0.5,0.2-0.6,0.7-0.6c1.9,0,3.7,0,5.6,0c0.6,0,0.8,0.1,0.8,0.8
                           C68.8,74.7,68.9,97.5,68.9,103.2L68.9,103.2z"
                />
                <path
                  fill="none"
                  d="M36.2,75.1c0-0.7,0.2-0.8,0.8-0.8c2.2,0,4.4,0,6.5,0.6c4.3,1.3,6.8,4.4,7.7,8.7c0.9,4.3-0.3,8.1-3.4,11.2
                           c-1.9,1.9-4.2,2.6-6.8,2.9c-1.4,0.2-2.8,0.1-4.2,0.2c-0.5,0-0.6-0.2-0.6-0.6C36.2,93.6,36.3,78.8,36.2,75.1L36.2,75.1z"
                />
                <path
                  fill="none"
                  d="M232.8,79.3c0-1.6,0-3.2,0-4.8c0-0.6,0.2-0.7,0.7-0.7c1.6,0,3.2,0,4.7,0.5c2.3,0.8,3.6,2.6,3.4,5.1
                           c-0.2,3-1.9,4.8-5,5.1c-1,0.1-2.1,0.1-3.1,0.1c-0.5,0-0.7-0.1-0.7-0.6C232.9,82.5,232.8,80.9,232.8,79.3L232.8,79.3z"
                />
                <g>
                  <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="139.0427"
                    y1="119.1048"
                    x2="155.0773"
                    y2="84.7152"
                    gradientTransform="matrix(1 0 0 -1 0 188)">
                    <stop offset="0.38" stopColor="#E45E26" />
                    <stop offset="1" stopColor="#F8A020" />
                  </linearGradient>
                  <ellipse
                    fill="url(#SVGID_1_)"
                    cx="147.1"
                    cy="86.1"
                    rx="18.9"
                    ry="19"
                  />
                  <g>
                    <defs>
                      <ellipse
                        id="SVGID_00000171688572440334777720000012025477002931655560_"
                        cx="147.1"
                        cy="86.1"
                        rx="18.9"
                        ry="19"
                      />
                    </defs>
                    <clipPath id="SVGID_00000101789949331294436200000003070479759248726182_">
                      <use
                        xlinkHref="#SVGID_00000171688572440334777720000012025477002931655560_"
                        overflow="visible"
                      />
                    </clipPath>
                    <g clipPath="url(#SVGID_00000101789949331294436200000003070479759248726182_)">
                      <g>
                        <g>
                          <linearGradient
                            id="SVGID_00000079459942811661722130000008309304853248420761_"
                            gradientUnits="userSpaceOnUse"
                            x1="-30.1099"
                            y1="267.8225"
                            x2="-8.5643"
                            y2="221.6204"
                            gradientTransform="matrix(0.7118 -0.6291 -0.6622 -0.7493 326.4228 261.5152)">
                            <stop offset="0.32" stopColor="#E45E26" />
                            <stop offset="1" stopColor="#F8A020" />
                          </linearGradient>

                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            fill="url(#SVGID_00000079459942811661722130000008309304853248420761_)"
                            d="
                                               M168.1,75.8c9.5,10.7,9.6,26,0.3,34.2s-24.5,6.2-33.9-4.5s-9.6-26-0.3-34.2S158.6,65.1,168.1,75.8z"
                          />
                        </g>
                        <g>
                          <g opacity="0.75">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              fill="#8A371E"
                              d="M168.1,75.8c9.5,10.7,9.6,26,0.3,34.2
                                                   s-24.5,6.2-33.9-4.5s-9.6-26-0.3-34.2S158.6,65.1,168.1,75.8z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>

              <linearGradient
                id="SVGID_00000056429434613382784890000000367656757919677587_"
                gradientUnits="userSpaceOnUse"
                x1="80.81"
                y1="43.495"
                x2="284.27"
                y2="43.495"
                gradientTransform="matrix(1 0 0 -1 0 188)">
                <stop offset="0.38" stopColor="#E45E26" />
                <stop offset="1" stopColor="#F8A020" />
              </linearGradient>
              <path
                fill="url(#SVGID_00000056429434613382784890000000367656757919677587_)"
                d="M283.2,163.5v-58.1
                       c-61.3,35.8-113,60.6-200.7,78.2h180.6C274.2,183.6,283.2,174.6,283.2,163.5z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default CreditDiscoverIcon
