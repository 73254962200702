import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import SectionHeader from '../../layouts/SectionHeader'
import { Styles } from '../../../types'
import { tokens } from '../../../styles/materialTheme'
import HelpfulLinksItem, { HelpfulLinksItemProps } from './HelpfulLinksItem'
import { PayTraceIcon, WorkdaySquareIcon } from '../../icons'
import { RsmLogo } from '../../media'

const styles: Styles = {
  cardContainer: (theme) => ({
    background: theme.palette.common.white,
    border: `0.0625rem solid ${tokens.colors.rsmGray.disabled}`,
    padding: '1.5rem',
    fontFamily: 'prelo-book',
    fontWeight: 500,
    [theme.breakpoints.only('tablet')]: {
      width: 244,
    },
  }),
  primaryLinksContainer: (theme) => ({
    gridColumn: 'col 1 / span 1',
    gridRow: 'row 1 / span 1',
    paddingLeft: '1.625rem',
    [theme.breakpoints.only('tablet')]: {
      display: 'block',
    },
    [theme.breakpoints.down('desktop')]: {
      paddingLeft: 0,
    },
  }),
  secondaryLinksContainer: (theme) => ({
    gridColumn: 'col 2 / span 1',
    gridRow: 'row 1 / span 1',
    paddingLeft: '1rem',
    [theme.breakpoints.only('tablet')]: {
      display: 'block',
    },
    [theme.breakpoints.down('desktop')]: {
      gridColumn: 'col 1 / span 1',
      gridRow: 'row 2 / span 1',
      paddingLeft: 0,
    },
  }),
  linksContainer: (theme) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, [col] 1fr)',
    gridTemplateRows: '[row] auto [row]',
    [theme.breakpoints.down('desktop')]: {
      gridTemplateColumns: 'repeat(1, [col] 1fr)',
      gridTemplateRows: '[row] auto [row]',
    },
  }),
  secondaryLinks: (theme) => ({
    [theme.breakpoints.down('desktop')]: {
      paddingTop: '0.5rem',
    },
  }),
}

const HelpfulLinks = () => {
  const { t } = useTranslation()
  const translationPrefix = 'Invoicing.AccountManagementHelpfulLinks'

  const primaryLinks: HelpfulLinksItemProps[] = [
    {
      LinkIcon: <PayTraceIcon width={64} height={64} />,
      linkText: t(`${translationPrefix}.PayTrace.LinkText`),
      linkUrl: 'https://paytrace.com/Login.pay',
      description: t(`${translationPrefix}.PayTrace.LinkDescription`),
      testId: 'Lnk_PayTrace',
    },
    {
      LinkIcon: <WorkdaySquareIcon width={61} height={61} />,
      linkText: t(`${translationPrefix}.Workday.LinkText`),
      linkUrl: 'https://www.myworkday.com/rsm/d/home.htmld',
      description: t(`${translationPrefix}.Workday.LinkDescription`),
      testId: 'Lnk_Workday',
    },
  ]

  const secondaryLinks: HelpfulLinksItemProps[] = [
    {
      LinkIcon: (
        <Box sx={{ paddingTop: '0.4375rem' }}>
          <RsmLogo width={64} height={27} />
        </Box>
      ),
      linkText: t(`${translationPrefix}.AccountCentral.LinkText`),
      linkUrl: 'https://admincenter.rsmidentity.com/',
      description: t(`${translationPrefix}.AccountCentral.LinkDescription`),
      testId: 'Lnk_AccountCentral',
    },
    {
      LinkIcon: (
        <Box sx={{ paddingTop: '0.3125rem' }}>
          <RsmLogo width={64} height={27} />
        </Box>
      ),
      linkText: t(`${translationPrefix}.CEM.LinkText`),
      linkUrl: 'https://xceleratecem.rsmus.com/',
      description: t(`${translationPrefix}.CEM.LinkDescription`),
      testId: 'Lnk_CEM',
    },
  ]

  return (
    <Box sx={styles.cardContainer}>
      <SectionHeader
        title={t('Invoicing.HelpfulLinks')}
        testId="Lbl_HelpfulLinks_Header"
      />
      <Box sx={styles.linksContainer}>
        <Box sx={styles.primaryLinksContainer}>
          {primaryLinks.map((linkProps) => (
            <HelpfulLinksItem
              key={linkProps.testId}
              LinkIcon={linkProps.LinkIcon}
              linkText={linkProps.linkText}
              linkUrl={linkProps.linkUrl}
              description={linkProps.description}
              testId={linkProps.testId}
            />
          ))}
        </Box>
        <Box sx={styles.secondaryLinksContainer}>
          {secondaryLinks.map((linkProps) => (
            <Box key={linkProps.testId} sx={styles.secondaryLinks}>
              <HelpfulLinksItem
                LinkIcon={linkProps.LinkIcon}
                linkText={linkProps.linkText}
                linkUrl={linkProps.linkUrl}
                description={linkProps.description}
                testId={linkProps.testId}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default HelpfulLinks
