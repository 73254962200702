import React from 'react'

const PresentationIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.5901 61.5391H38.5801V77.2491H41.5901V61.5391Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5901 2H38.5801V6.98H41.5901V2Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.1895 77.9991L49.6895 61.5391H53.1595L61.7995 76.4991L59.1895 77.9991Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.9791 77.9991L18.3691 76.4991L26.9991 61.5391H30.4791L20.9791 77.9991Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.4407 52.7291H6.7207V9.86914H29.8907V12.8791H9.7307V52.7291H70.4307V12.8791H50.2807V9.86914H73.4407V52.7291Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.92 61.5402H3.25V52.7402H76.92V61.5402V61.5402Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.2806 15.7805H29.8906V6.98047H50.2806V15.7805V15.7805Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.4192 36.8698C35.4192 42.1698 31.1192 46.4798 25.8092 46.4798C20.4992 46.4798 16.1992 42.1798 16.1992 36.8698C16.1992 31.5598 20.4992 27.2598 25.8092 27.2598V36.8698H35.4192Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.7787 34.5502H28.1387V21.9102H29.6587C35.7887 21.9102 40.7887 26.9002 40.7887 33.0302V34.5502H40.7787Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.9595 21.9199H48.7695V24.9299H63.9595V21.9199Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.9595 27.3301H48.7695V30.3401H63.9595V27.3301Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.9595 32.75H48.7695V35.76H63.9595V32.75Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.9595 38.1699H48.7695V41.1799H63.9595V38.1699Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.3695 43.5898H48.7695V46.5998H56.3695V43.5898Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PresentationIcon
