import { useCallback, useEffect } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { stringComparer } from '../../../utils/helpers/stringHelpers'
import { UserAppModel, getUserApps } from '../../idm/getAllUserApplications'
import {
  setLoading,
  getLoading,
  getCalled,
  getUserApps as getApps,
  getError,
  setUserApps,
  setError,
  setCalled,
} from '../../../store/userApps/userAppsSlice'
import api from '../..'
import { consoleGoodStyle } from '../../../utils/helpers/version.service'
import { isFeatureFlagEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  REACT_APP_TEAMDOCS_DASHBOARD_URL,
  REACT_APP_TEAMDOCS_OKTA_APP_ID,
  REACT_APP_DISALLOWED_TOOLS,
} from '../../../envVariables'
import {
  getReadyState,
  getUserInfo,
} from '../../../store/userInfo/userInfoSlice'

function useApps() {
  const dispatch = useDispatch()
  const userApps = useSelector(getApps)
  const loading = useSelector(getLoading)
  const called = useSelector(getCalled)
  const error = useSelector(getError)
  const userInfo = useSelector(getUserInfo)
  const isReadyUserInfo = useSelector(getReadyState)

  const SortAndFilterApps = (
    apps: UserAppModel[],
    appsWhitelist: string[],
  ): UserAppModel[] => {
    // note: we can sort/case transform both and make it fwd only for performance but not really required here
    // whitelist should be the shorter one
    let filtered = appsWhitelist.reduce(
      (values: UserAppModel[], item: string) => {
        const match = apps.filter(
          (a) => a.identifier?.toLowerCase() === item?.toLowerCase(),
        )
        if (match.length > 0) values.push(match[0])
        return values
      },
      [],
    )

    if (isFeatureFlagEnabled('BrandedAppsToolsList')) {
      const excludedApps = REACT_APP_DISALLOWED_TOOLS.split(',')
      filtered = apps.filter((item: UserAppModel) =>
        excludedApps ? !excludedApps.includes(item.identifier ?? '') : item,
      )
    }

    return filtered.sort((a: UserAppModel, b: UserAppModel) =>
      stringComparer(a.name, b.name),
    )
  }

  const getAllowedAppsList = async (): Promise<string[]> => {
    if (isFeatureFlagEnabled('BrandedAppsToolsList')) {
      return Promise.resolve([])
    }
    try {
      const response = await api.user.configuration_GetAllowedToolsList()
      return response.data ?? []
    } catch {
      return Promise.resolve([])
    }
  }

  const fetch = async (forceLoad = false) => {
    try {
      if (called && !forceLoad) return

      dispatch(setLoading(true))
      dispatch(setCalled(true))

      // parallel execution
      const [
        appsCall,
        appsWhitelist,
        teamDocsProjectSites,
        teamDocsProjects2024,
      ] = await Promise.all([
        getUserApps(),
        getAllowedAppsList(),
        api.user
          .teamDocsUserMappings_GetTeamDocsUserMappings()
          .then((res) => res.data || []),
        api.user
          .teamDocs_GetTeamDocsSitesOfUser()
          .then((res) => res.data || []),
      ])
      let apps = appsCall

      // eslint-disable-next-line no-console
      console.log('%cProjects (legacy)', consoleGoodStyle, {
        'TeamDocs Projects': teamDocsProjectSites?.length,
      })
      // eslint-disable-next-line no-console
      console.log('%cProjects (2024)', consoleGoodStyle, {
        'Project count': teamDocsProjects2024?.length,
      })

      if (
        userInfo?.userType === 'Internal User' &&
        isFeatureFlagEnabled('TeamDocs2024Internal') &&
        teamDocsProjects2024?.length > 0
      ) {
        /* Protect getting a duplicate tool tile in UI if user has legacy IDM-based TeamDocs tool tile */
        if (
          !apps.some(
            (app) =>
              app.identifier?.toLowerCase() ===
              REACT_APP_TEAMDOCS_OKTA_APP_ID.toLowerCase(),
          )
        ) {
          apps = [
            ...apps,
            {
              id: undefined,
              identifier: REACT_APP_TEAMDOCS_OKTA_APP_ID,
              name: 'TeamDocs',
              description: t('HomePage.TeamDocsDescription'),
              category: t('HomePage.TeamDocsCategory'),
              launchUri: REACT_APP_TEAMDOCS_DASHBOARD_URL,
              logoUri: undefined,
              brandedApplicationType: undefined,
            },
          ]
        }
      }

      // sort here even if data comes sorted, we do not control this source.
      const filtered = SortAndFilterApps(
        apps as UserAppModel[],
        appsWhitelist as string[],
      )

      if (userInfo?.userType === 'External User') {
        const externalTeamDocsTile = {
          id: undefined,
          identifier: 'teamdocs',
          name: 'TeamDocs',
          description: t('HomePage.TeamDocsDescription'),
          category: t('HomePage.TeamDocsCategory'),
          launchUri: '/projects',
          logoUri: undefined,
          brandedApplicationType: undefined,
        }
        if (isFeatureFlagEnabled('TeamDocs2024External')) {
          if (teamDocsProjects2024?.length > 0) {
            filtered.push(externalTeamDocsTile)
          }
        } else if (teamDocsProjectSites?.length > 0) {
          filtered.push(externalTeamDocsTile)
        }
      }

      filtered.sort((a, b) => stringComparer(a.name, b.name))

      dispatch(setLoading(false))
      dispatch(setUserApps(filtered || []))
    } catch (err) {
      dispatch(setLoading(false))
      dispatch(setUserApps([]))
      dispatch(setError(err as Error))
    }
  }

  useEffect(() => {
    if (isReadyUserInfo) {
      localStorage.removeItem('loopCounter')
      fetch()
    }
  }, [isReadyUserInfo])

  const refetch = useCallback(() => fetch(true), [])

  return { userApps, loading, error, refetch }
}

export default useApps
