import React, { useState } from 'react'
import { useGetIndustryListQuery } from '../../../store/cmsService'
import AutomobileIcon from '../../icons/AutomobileIcon'
import BuildingTreeIcon from '../../icons/BuildingTreeIcon'
import BusinessBuildingIcon from '../../icons/BusinessBuildingIcon'
import CapitolIcon from '../../icons/CapitolIcon'
import CareIcon from '../../icons/CareIcon'
import CircuitryIcon from '../../icons/CircuitryIcon'
import ClapperboardIcon from '../../icons/ClapperboardIcon'
import ConstructionCraneIcon from '../../icons/ConstructionCraneIcon'
import ContractIcon from '../../icons/ContractIcon'
import DiceIcon from '../../icons/DiceIcon'
import FactoryFolderIcon from '../../icons/FactoryFolderIcon'
import GraduationCapIcon from '../../icons/GraduationCapIcon'
import HandshakeIcon from '../../icons/HandshakeIcon'
import LeafMoleculeIcon from '../../icons/LeafMoleculeIcon'
import OperatorIcon from '../../icons/OperatorIcon'
import PlaceSettingIcon from '../../icons/PlaceSettingIcon'
import RestaurantIcon from '../../icons/RestaurantIcon'
import RoboticArmIcon from '../../icons/RoboticArmIcon'
import ShoppingBagsIcon from '../../icons/ShoppingBagsIcon'
import ShoppingCartIcon from '../../icons/ShoppingCartIcon'
import SolarArrayIcon from '../../icons/SolarArrayIcon'
import StethoscopeIcon from '../../icons/StethoscopeIcon'
import TennisIcon from '../../icons/TennisIcon'
import TreasuryIcon from '../../icons/TreasuryIcon'
import { Interest, TagIconMap } from '../types'
import InterestTile from './InterestTile'

interface IndustriesSelectorProps {
  defaultSelected?: number[]
  onChange: (value: number[]) => void
}

const IndustriesSelector = ({
  defaultSelected = undefined,
  onChange,
}: IndustriesSelectorProps) => {
  const { data } = useGetIndustryListQuery()
  const [selected, setSelected] = useState<number[]>(defaultSelected ?? [])

  const tagIconMap: TagIconMap = {
    automotive: <AutomobileIcon />,
    'business-services': <BusinessBuildingIcon />,
    construction: <ConstructionCraneIcon />,
    'consumer-goods': <ShoppingCartIcon />,
    'education-us': <GraduationCapIcon />,
    energy: <SolarArrayIcon />,
    'financial-services': <TreasuryIcon />,
    'food-beverage': <PlaceSettingIcon />,
    gaming: <DiceIcon />,
    'government-contracting': <ContractIcon />,
    'government-us': <CapitolIcon />,
    'health-care-us': <StethoscopeIcon />,
    'life-sciences': <LeafMoleculeIcon />,
    manufacturing: <RoboticArmIcon />,
    'media-entertainment': <ClapperboardIcon />,
    nonprofit: <CareIcon />,
    'private-clubs': <TennisIcon />,
    'private-equity': <FactoryFolderIcon />,
    'professional-services': <HandshakeIcon />,
    'real-estate': <BuildingTreeIcon />,
    restaurant: <RestaurantIcon />,
    retail: <ShoppingBagsIcon />,
    'technology-industry': <CircuitryIcon />,
    telecommunications: <OperatorIcon />,
  }

  const interests = data?.data
    ?.filter((interest) => {
      const tag = interest.tag?.split('/')?.pop()
      return !tag?.match('government-public-sector-canada')
    })
    ?.map((interest) => {
      const tag = interest.tag?.split('/')?.pop()
      return tag ? { ...interest, icon: tagIconMap[tag] } : interest
    }) as Interest[]

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {interests?.map((interest, index) => (
        <InterestTile
          key={interest.tag}
          testId="Industry"
          checked={selected.includes(interest.id)}
          index={index}
          interest={interest}
          onClick={(checked) => {
            const value = checked
              ? [...selected, interest.id]
              : selected.filter((i) => i !== interest.id)
            setSelected(value)
            onChange(value)
          }}
        />
      ))}
    </>
  )
}

export default IndustriesSelector
