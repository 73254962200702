const PaymentTermsAndConditionsSingle = (): string => `<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=windows-1252" />
    <meta name="Generator" content="Microsoft Word 15 (filtered)" />
    <style>
      <!--
       /* Font Definitions */
       @font-face
      	{font-family:"Cambria Math";
      	panose-1:2 4 5 3 5 4 6 3 2 4;}
      @font-face
      	{font-family:Calibri;
      	panose-1:2 15 5 2 2 2 4 3 2 4;}
      @font-face
      	{font-family:"Calibri Light";
      	panose-1:2 15 3 2 2 2 4 3 2 4;}
       /* Style Definitions */
       p.MsoNormal, li.MsoNormal, div.MsoNormal
      	{margin-top:0in;
      	margin-right:0in;
      	margin-bottom:8.0pt;
      	margin-left:0in;
      	line-height:107%;
      	font-size:11.0pt;
      	font-family:"Calibri",sans-serif;}
      p.MsoFooter, li.MsoFooter, div.MsoFooter
      	{mso-style-link:"Footer Char";
      	margin:0in;
      	font-size:11.0pt;
      	font-family:"Calibri",sans-serif;}
      a:link, span.MsoHyperlink
      	{color:blue;
      	text-decoration:underline;}
      p
      	{margin-right:0in;
      	margin-left:0in;
      	font-size:12.0pt;
      	font-family:"Times New Roman",serif;}
      span.FooterChar
      	{mso-style-name:"Footer Char";
      	mso-style-link:Footer;}
      .MsoChpDefault
      	{font-family:"Calibri",sans-serif;}
      .MsoPapDefault
      	{margin-bottom:8.0pt;
      	line-height:107%;}
       /* Page Definitions */
       @page WordSection1
      	{size:8.5in 11.0in;
      	margin:1.0in 1.0in 1.0in 1.0in;}
      div.WordSection1
      	{page:WordSection1;}
      -->
    </style>
  </head>

  <body lang="EN-US" link="blue" vlink="#954F72" style="word-wrap: break-word">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align: center">
        <b
          ><u
            ><span
              style="
                font-size: 14pt;
                line-height: 107%;
                font-family: 'Arial', sans-serif;
              "
              >One-Time Payment</span
            ></u
          ></b
        >
      </p>

      <p
        style="
          margin-top: 12pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <b
          ><u
            ><span style="font-size: 16pt; font-family: 'Arial', sans-serif"
              >The following agreement applies to one-time payments - US</span
            ></u
          ></b
        >
      </p>

      <p
        style="
          margin-top: 9pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >RSM US LLP and its affiliates, agents, successors, and assigns are
          authorized to initiate a one-time transaction to the credit card or
          bank account entered above (the "Account") for partial or full payment
          of the amount(s) set forth on the selected invoice(s) (the "Selected
          Invoices") (partial or full payment to be determined by customer's
          instruction with respect to each Selected Invoice). It is understood
          and acknowledged that this authorization is for a single payment
          reflecting the total amount approved for payment by Customer for the
          Selected Invoices. If this authorization is used for an ACH
          transaction and declined, it may be reinitiated up to two additional
          times. By authorizing this payment, you certify that you are an
          authorized user or signatory for the Account.</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 15pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p style="margin: 0in">
        <span style="font-size: 18.5pt; font-family: 'Arial', sans-serif"
          >&nbsp;</span
        >
      </p>

      <p style="margin: 0in">
        <b
          ><u
            ><span style="font-size: 16pt; font-family: 'Arial', sans-serif"
              >The following agreement applies to one-time payments -
              CANADA</span
            ></u
          ></b
        >
      </p>

      <p
        style="
          margin-top: 9pt;
          margin-right: 0in;
          margin-bottom: 0in;
          margin-left: 0in;
        "
      >
        <span style="font-size: 14pt; font-family: 'Arial', sans-serif"
          >RSM Canada LLP and its affiliates, agents, successors, and assigns
          are authorized to initiate a one-time transaction to the credit card
          or bank account entered above (the "Account") for partial or full
          payment of the amount(s) set forth on the selected invoice(s) (the
          "Selected Invoices") (partial or full payment to be determined by
          customer's instruction with respect to each Selected Invoice). It is
          understood and acknowledged that this authorization is for a single
          payment reflecting the total amount approved for payment by Customer
          for the Selected Invoices. If this authorization is used for an
          Electronic Funds Transfer ("EFT") transaction and declined, RSM will
          attempt to process the scheduled up to one (1) additional time thirty
          (30) days from the original transaction date. By authorizing this
          payment, you certify that you are an authorized user or signatory for
          the Account. This authorization is to remain in effect until RSM
          completes the one-time transaction, at which time this authorization
          will automatically terminate.</span
        >
      </p>
    </div>
  </body>
</html>`

export default PaymentTermsAndConditionsSingle
