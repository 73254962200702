import React from 'react'

const MergerIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26 25.52V3H78V74H41"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.4493 74.0004H2.2793V25.4004H41.0393V42.2504H52.3593V36.3504L63.8093 47.7904L52.2393 58.8004V54.3104H41.0393V74.0004"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.7591 33.3906H9.78906V44.4606H19.7591V33.3906Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.7102 33.3906H24.7402V44.4606H34.7102V33.3906Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.7591 50.25H9.78906V61.32H19.7591V50.25Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.7102 50.25H24.7402V61.32H34.7102V50.25Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M55.8899 9.76953H45.9199V20.8395H55.8899V9.76953Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M70.8391 9.76953H60.8691V20.8395H70.8391V9.76953Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M55.8899 23.6406H45.9199V34.7106H55.8899V23.6406Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M70.8391 23.6406H60.8691V34.7106H70.8391V23.6406Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M41.6009 9.76953H31.6309V20.8395H41.6009V9.76953Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default MergerIcon
