import React from 'react'

const VSDX = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="2" y="12" width="16" height="7" rx="0.8" fill="#576D7E" />
    <path
      d="M5.59492 16.2354L6.26948 13.9392H6.93525L5.96406 17.022H5.19063L4.20845 13.9392H4.90498L5.59053 16.2354H5.59492ZM8.58218 14.4578C8.23208 14.4578 8.05703 14.561 8.05703 14.7676C8.05703 14.8877 8.12734 14.9785 8.26797 15.04C8.36465 15.0825 8.52505 15.1301 8.74917 15.1829C9.3644 15.3293 9.67202 15.6333 9.67202 16.0947C9.67202 16.4097 9.56436 16.647 9.34902 16.8066C9.13516 16.9648 8.84292 17.0439 8.47231 17.0439C8.10171 17.0439 7.72744 16.9875 7.34951 16.8748L7.45278 16.3474C7.83657 16.4368 8.18887 16.4814 8.50967 16.4814C8.66201 16.4814 8.78359 16.4514 8.87441 16.3914C8.96523 16.3298 9.01064 16.2537 9.01064 16.1628C9.01064 16.0706 8.9894 16.0061 8.94692 15.9695C8.90444 15.9329 8.87148 15.9065 8.84805 15.8904C8.80996 15.864 8.72793 15.8318 8.60195 15.7937C8.47744 15.7556 8.37783 15.7285 8.30313 15.7124C7.69668 15.5381 7.39346 15.2188 7.39346 14.7544C7.39346 14.46 7.49673 14.2432 7.70327 14.104C7.91128 13.9648 8.18374 13.8953 8.52065 13.8953C8.85903 13.8953 9.21719 13.9524 9.59512 14.0667L9.4501 14.5918C9.06631 14.5024 8.777 14.4578 8.58218 14.4578ZM10.9388 16.4375H11.453C11.7225 16.4375 11.9056 16.3562 12.0023 16.1936C12.1004 16.0295 12.1495 15.7827 12.1495 15.4531C12.1495 15.1221 12.1012 14.8811 12.0045 14.7302C11.9093 14.5779 11.7254 14.5017 11.453 14.5017H10.9388V16.4375ZM10.3082 13.9392H11.453C11.9642 13.9392 12.3238 14.0828 12.5318 14.3699C12.7193 14.6292 12.8124 14.988 12.8109 15.4465C12.8109 16.2039 12.5765 16.6829 12.1078 16.8835C11.9261 16.9612 11.7079 17 11.453 17H10.3082V13.9392ZM14.3809 15.9299H14.3721L13.7701 17H13.0669L13.9898 15.4114L13.1175 13.9392H13.8492L14.4117 14.895H14.4227L14.9654 13.9392H15.7037L14.827 15.4114L15.785 17H15.0335L14.3809 15.9299Z"
      fill="white"
    />
  </svg>
)

export default VSDX
