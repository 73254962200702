import React from 'react'
import { Box, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import SpotlightArticleWrapper from './SpotlightArticleWrapper'
import Unavailable from '../../../pages/Unavailable'
import { themeDefinition } from '../../../styles/materialTheme'
import { Styles } from '../../../types'
import {
  getHasPopBgColor,
  getHasPopImg,
  getPopBgColor,
  getPopBgImg,
} from '../../../store/spotlightArticle/spotlightArticleSlice'

type StyledParentContainerBackgroundProps = {
  useBrandEvolution: boolean
}

const StyledParentContainerBackground = styled(
  'div',
)<StyledParentContainerBackgroundProps>(({ useBrandEvolution, theme }) => ({
  backgroundColor: useBrandEvolution ? theme.palette.common.white : 'inherit',
}))

type StyledContainerProps = {
  useBrandEvolution: boolean
  usePopColor: boolean
  usePopImg: boolean
  popColor: string
  popBgImg: string
}

const StyledContainer = styled('div')<StyledContainerProps>(
  ({
    useBrandEvolution,
    usePopColor,
    usePopImg,
    popColor,
    popBgImg: bgImage,
    theme,
  }) => ({
    marginTop: '-2.5rem',
    backgroundColor:
      useBrandEvolution && usePopColor
        ? popColor
        : theme.palette.secondary.main,
    backgroundImage: useBrandEvolution && usePopImg ? `url(${bgImage})` : '',
    backgroundSize: 'cover',
    margin: useBrandEvolution ? '0 auto' : 'inherit',
    maxWidth: useBrandEvolution ? '120rem' : 'inherit',
  }),
)

const styles: Styles = {
  unavailableContainer: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: '33.5rem',
  }),
}

export interface HomeSpotlightArticleSectionProps {
  isUnavailable: boolean
}

const HomeSpotlightArticleSection = ({
  isUnavailable,
}: HomeSpotlightArticleSectionProps) => {
  const usePopColor = useSelector(getHasPopBgColor)
  const useBgImg = useSelector(getHasPopImg)
  const popBgColor = useSelector(getPopBgColor)
  const popBgImg = useSelector(getPopBgImg)

  return (
    <StyledParentContainerBackground useBrandEvolution>
      <StyledContainer
        useBrandEvolution
        usePopColor={usePopColor}
        usePopImg={useBgImg}
        popColor={popBgColor}
        popBgImg={popBgImg || ''}>
        <Box component="section" data-testid="Sec_Home_SpotlightArticle">
          {isUnavailable ? (
            <Box sx={styles.unavailableContainer}>
              <Unavailable
                returnUrl="#"
                headerTextKey="RobotPage.TechnicalDifficulties"
                subHeaderTextheaderTextKey="RobotPage.SomeSectionsUnloadable"
                subHeader2ndLineTextheaderTextKey="RobotPage.TryAgainLaterMessage"
                showTryAgain={false}
                textColor={themeDefinition.palette?.common?.white}
                isSpotlightSection
              />
            </Box>
          ) : (
            <SpotlightArticleWrapper />
          )}
        </Box>
      </StyledContainer>
    </StyledParentContainerBackground>
  )
}

export default HomeSpotlightArticleSection
