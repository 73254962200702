import React from 'react'

const StethoscopeIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.42023 12.7609L8.99023 5.87086L13.3202 5.63086"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.5003 7.82094C16.7043 7.82094 17.6803 6.84492 17.6803 5.64094C17.6803 4.43696 16.7043 3.46094 15.5003 3.46094C14.2963 3.46094 13.3203 4.43696 13.3203 5.64094C13.3203 6.84492 14.2963 7.82094 15.5003 7.82094Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M42.9505 10.6303L42.3105 3.57031L37.9805 3.86031"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M35.8206 6.36C37.0246 6.36 38.0006 5.38398 38.0006 4.18C38.0006 2.97602 37.0246 2 35.8206 2C34.6166 2 33.6406 2.97602 33.6406 4.18C33.6406 5.38398 34.6166 6.36 35.8206 6.36Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.9901 13.6795L40.6701 24.3295C41.1401 31.7695 35.4801 38.2095 28.0401 38.6795C20.6001 39.1495 14.1601 33.4895 13.6901 26.0495L13.0101 15.3195C13.0101 15.3195 12.9101 12.8395 9.74012 12.7595C5.90012 12.8295 6.24012 15.2995 6.24012 15.2995L6.95012 26.4695C7.59012 36.4995 15.4201 44.3295 25.0901 45.3295C25.0901 45.3295 23.4401 79.5696 49.2001 77.9396C61.2501 77.1796 67.3001 67.6495 67.2601 57.8895C71.1301 56.2595 73.7301 52.3295 73.4501 47.9095C73.0901 42.3195 68.2801 38.0795 62.6901 38.4395C57.1001 38.7995 52.8601 43.6095 53.2201 49.1995C53.5001 53.5895 56.5301 57.1295 60.5201 58.2895C61.4801 64.5995 56.6001 70.7695 50.0901 71.5695C30.2001 74.0095 31.8501 44.9095 31.8501 44.9095C41.3201 42.6895 48.0801 33.9295 47.4401 23.8995L46.7301 12.8295C46.7301 12.8295 46.4401 10.1995 42.9801 10.6295C39.5201 11.0595 40.0101 13.6795 40.0101 13.6795H39.9901Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M63.3102 53.6302C66.1103 53.6302 68.3802 51.3603 68.3802 48.5602C68.3802 45.7602 66.1103 43.4902 63.3102 43.4902C60.5102 43.4902 58.2402 45.7602 58.2402 48.5602C58.2402 51.3603 60.5102 53.6302 63.3102 53.6302Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default StethoscopeIcon
