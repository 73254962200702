import React from 'react'

const MSG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="4" y="12" width="14" height="7" rx="0.8" fill="#225DCF" />
    <path
      d="M9.57061 13.9392V17H8.93999V15.073H8.9312L8.21489 17H7.75786L7.05913 15.073H7.05034V17H6.4373V13.9392H7.27446L8.02153 15.9519H8.03032L8.73345 13.9392H9.57061ZM11.4417 14.4578C11.0916 14.4578 10.9165 14.561 10.9165 14.7676C10.9165 14.8877 10.9868 14.9785 11.1274 15.04C11.2241 15.0825 11.3845 15.1301 11.6086 15.1829C12.2239 15.3293 12.5315 15.6333 12.5315 16.0947C12.5315 16.4097 12.4238 16.647 12.2085 16.8066C11.9946 16.9648 11.7024 17.0439 11.3318 17.0439C10.9612 17.0439 10.5869 16.9875 10.209 16.8748L10.3123 16.3474C10.696 16.4368 11.0483 16.4814 11.3691 16.4814C11.5215 16.4814 11.6431 16.4514 11.7339 16.3914C11.8247 16.3298 11.8701 16.2537 11.8701 16.1628C11.8701 16.0706 11.8489 16.0061 11.8064 15.9695C11.7639 15.9329 11.731 15.9065 11.7075 15.8904C11.6694 15.864 11.5874 15.8318 11.4614 15.7937C11.3369 15.7556 11.2373 15.7285 11.1626 15.7124C10.5562 15.5381 10.2529 15.2188 10.2529 14.7544C10.2529 14.46 10.3562 14.2432 10.5627 14.104C10.7708 13.9648 11.0432 13.8953 11.3801 13.8953C11.7185 13.8953 12.0767 13.9524 12.4546 14.0667L12.3096 14.5918C11.9258 14.5024 11.6365 14.4578 11.4417 14.4578ZM15.5627 15.3521V16.833C15.1643 16.9736 14.8171 17.0439 14.5212 17.0439C13.9675 17.0439 13.5874 16.8857 13.3808 16.5693C13.1977 16.291 13.1062 15.9167 13.1062 15.4465C13.1062 14.6877 13.3391 14.2095 13.8049 14.0117C13.9865 13.9341 14.2304 13.8953 14.5366 13.8953C14.8442 13.8953 15.1862 13.9524 15.5627 14.0667L15.4243 14.5918C15.0405 14.5024 14.727 14.4578 14.4838 14.4578C14.0063 14.4578 13.7675 14.7874 13.7675 15.4465C13.7675 15.7849 13.8261 16.042 13.9433 16.2178C14.0605 16.3936 14.2524 16.4814 14.519 16.4814C14.6596 16.4814 14.798 16.4565 14.9343 16.4067V15.9146H14.4707V15.3521H15.5627Z"
      fill="white"
    />
  </svg>
)

export default MSG
