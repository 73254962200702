import React, { memo } from 'react'

const WarningIconBig = () => (
  <svg
    width="101"
    height="89"
    viewBox="0 0 101 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M99.8281 75.9453L58.2265 4.85156C54.9062 -0.617188 46.8984 -0.617188 43.7734 4.85156L1.9765 75.9453C-1.1485 81.4141 2.75775 88.25 9.20307 88.25H92.6015C99.0468 88.25 102.953 81.4141 99.8281 75.9453ZM46.3124 27.3125C46.3124 24.7734 48.2656 22.625 50.9999 22.625C53.539 22.625 55.6874 24.7734 55.6874 27.3125V52.3125C55.6874 55.0469 53.539 57 50.9999 57C48.6562 57 46.3124 55.0469 46.3124 52.3125V27.3125ZM50.9999 75.75C47.4843 75.75 44.7499 73.0156 44.7499 69.6953C44.7499 66.375 47.4843 63.6406 50.9999 63.6406C54.3203 63.6406 57.0546 66.375 57.0546 69.6953C57.0546 73.0156 54.3203 75.75 50.9999 75.75Z"
      fill="#515356"
    />
  </svg>
)

export default memo(WarningIconBig)
