import * as React from 'react'
import { SVGProps } from 'react'

const CreditJcbIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_2_00000167390507860004516630000001552837573545611430_"
    data-testid="credit-jcb-icon"
    x="0px"
    y="0px"
    viewBox="0 0 860.1 542"
    enableBackground="new 0 0 860.1 542"
    width={width}
    height={height}>
    <title>JCB</title>
    <g id="Layer_1-2">
      <g>
        <path
          fill="#003A91"
          d="M90.3,0h679.4c49.9,0,90.3,40.4,90.3,90.3v361.4c0,49.9-40.4,90.3-90.3,90.3H90.3
           C40.4,542,0,501.6,0,451.7V90.3C0,40.4,40.4,0,90.3,0z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M299.1,105.1h332.5c3.2,0,5.9,2.6,5.9,5.9v249.5c0,42.2-34.3,76.5-76.5,76.5H228.5
               c-3.2,0-5.9-2.6-5.9-5.9V181.6C222.6,139.4,256.9,105.1,299.1,105.1L299.1,105.1z"
          />
          <g>
            <path
              fill="#53C223"
              d="M545.4,145c-26.9,0-48.8,21.8-48.8,48.8v199.9h58c26.9,0,48.8-21.8,48.8-48.8V145L545.4,145L545.4,145z"
            />
            <path
              fill="#ED003A"
              d="M424.8,145c-26.9,0-48.8,21.8-48.8,48.8v199.9h58c26.9,0,48.8-21.8,48.8-48.8V145L424.8,145L424.8,145z"
            />
            <path
              fill="#077CE6"
              d="M305.4,148.4c-26.9,0-48.8,21.8-48.8,48.8V397h58c26.9,0,48.8-21.8,48.8-48.8V148.4L305.4,148.4
                   L305.4,148.4z"
            />
            <path
              fill="#FFFFFF"
              d="M343.9,232.4h-37.4c-0.3,0-0.6,0.3-0.6,0.6v43.6c0,12.4-10.1,21.8-23.5,21.8c-11.4,0-21.8-6.9-24.7-16.3
                   c-0.1-0.3-0.4-0.5-0.6-0.4c-0.3,0-0.5,0.3-0.5,0.6v19.9c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.5,0
                   c9,6.5,21.2,10.2,33.6,10.3c21.5-0.1,53.3-11.9,53.3-36.9v-43C344.5,232.7,344.2,232.5,343.9,232.4L343.9,232.4z"
            />
            <g>
              <path
                fill="none"
                d="M553.4,253.7c0-6.4-5.8-12.3-12.3-12.3H516v24h25.7C548.1,265.4,553.4,260.1,553.4,253.7z"
              />
              <path
                fill="none"
                d="M541.7,272.7H516v24.5h25.7c6.4,0,12.3-5.9,12.3-12.3S548.2,272.7,541.7,272.7L541.7,272.7z"
              />
              <path
                fill="#FFFFFF"
                d="M572.3,269.8c8-2.2,14.1-9.7,14.1-18.4c0-10.3-8.7-19-19-19h-70.2c-0.3,0-0.6,0.3-0.6,0.6v72.6
                       c0,0.3,0.3,0.6,0.6,0.6h70.2c11.8,0,20.7-7.9,20.7-18.5C588.1,278.9,581.5,271.7,572.3,269.8L572.3,269.8z M516,241.4h25.1
                       c6.4,0,12.3,5.9,12.3,12.3c0,6.4-5.4,11.7-11.7,11.7H516V241.4z M541.7,297.3H516v-24.5h25.7c6.4,0,12.3,5.9,12.3,12.3
                       S548.1,297.3,541.7,297.3L541.7,297.3z"
              />
            </g>
            <path
              fill="#FFFFFF"
              d="M427.2,242c6.8,0,21.2,1.6,36.8,5c0.2,0,0.4,0,0.5-0.1c0.1-0.1,0.2-0.3,0.2-0.4V233
                   c0-0.3-0.3-0.6-0.6-0.6h-63.7c-10.5,0-18.9,5.1-24.1,9.4c-0.1,0.1-0.2,0.3-0.2,0.4v54.2c0,0.2,0.1,0.3,0.2,0.4
                   c6.1,5.9,14.9,9.4,24.1,9.4h63.7c0.3,0,0.6-0.3,0.6-0.6V294c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.3-0.1-0.5-0.1
                   c-13.9,3.1-28.4,4.5-36.8,4.5c-15.4,0-27.9-12.5-27.9-27.9S411.8,242,427.2,242L427.2,242z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
export default CreditJcbIcon
