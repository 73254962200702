import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, IconButton, Snackbar, Box } from '@mui/material'
import Slide from '@mui/material/Slide'
import { CloseIcon, ToastAlertIcon } from '../../../icons'
import tokens from '../../../../styles/tokens.json'
import { useStyles } from '../Alert.styles'

export type CustomWarningAlertProps = {
  message: string
  header: string
  open: boolean
  close: () => void
  testId?: string
}

const CustomWarningAlert = ({
  header,
  message,
  open,
  close,
  testId = '',
}: CustomWarningAlertProps) => {
  const { t } = useTranslation()
  const toastMsgTimelimit = 8000
  const toastMsgSlideEnter = 1000
  const toastMsgSlideExit = 1000

  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transitionDuration={{
        enter: toastMsgSlideEnter,
        exit: toastMsgSlideExit,
      }}
      TransitionComponent={Slide}
      autoHideDuration={toastMsgTimelimit}
      open={open}
      sx={classes.snackbar}>
      <Alert
        role="presentation"
        sx={classes.alertRoot}
        style={{ backgroundColor: tokens.colors.rsmYellow.primary }}
        severity="warning"
        onClose={close}
        icon={
          <Box component="span" sx={classes.alertIcon}>
            <ToastAlertIcon color={tokens.colors.rsmGray.copy} size={54} />
          </Box>
        }
        action={
          <IconButton
            autoFocus
            aria-label="close"
            role="button"
            onClick={close}
            disableFocusRipple>
            <CloseIcon size={18} />
          </IconButton>
        }>
        <Box data-testid="errorAlert">
          <Box
            role="alert"
            sx={classes.contentRoot}
            data-testid={testId ?? 'Lbl_Error_ErrorMessage'}>
            <Box className="title" sx={classes.header}>
              {t(header)}:
            </Box>
            <Box component="span" sx={classes.msg}>
              <Box component="span" className="title" sx={classes.smHeader}>
                {t(header)}:
              </Box>
              {t(message)}
            </Box>
          </Box>
        </Box>
      </Alert>
    </Snackbar>
  )
}

export default CustomWarningAlert
