import React from 'react'
import { Box, TextField, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { Styles } from '../../../../types'

const styles: Styles = {
  textContainer: (theme) => ({
    '.MuiOutlinedInput-root': {
      width: '100%',
      maxWidth: '100%',
    },
    width: 'calc(100% - 4rem)',
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  fieldContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: 0,
    },
  }),
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiFormHelperText-root': { margin: '0rem' },
})

interface PurchaseOrderQuoteNumberProps {
  handleChange: (value: string) => void
}

const PurchaseOrderQuoteNumber = ({
  handleChange,
}: PurchaseOrderQuoteNumberProps) => {
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <Box sx={styles.fieldContainer}>
      <StyledLabel htmlFor="purchaseOrderQuoteNumber">
        {t('Invoicing.PayOnAccountPage.PurchaseOrderQuoteNumber')}
      </StyledLabel>
      <Box sx={styles.textContainer}>
        <Controller
          name="purchaseOrderQuoteNumber"
          control={control}
          defaultValue=""
          rules={{
            maxLength: 250,
          }}
          render={({ field: { onChange, value } }) => (
            <StyledTextField
              id="purchaseOrderQuoteNumber"
              value={value}
              onChange={(e) => {
                onChange(e)
                handleChange(e.target.value)
              }}
              inputProps={{
                maxLength: 250,
                'data-testid': 'Txt_PayOnAccount_PurchaseOrderQuoteNumber',
              }}
            />
          )}
        />
      </Box>
    </Box>
  )
}

export default PurchaseOrderQuoteNumber
