import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Typography,
  FormControlLabel,
  styled,
  Switch,
  SwitchProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../utils/hooks'
import {
  getHighContrast,
  setHighContrast,
} from '../../../store/userSettings/userSettingsSlice'
import { AnalyticEvent } from '../../../analytics/adobe/types'

const HCSwitch = styled((props: SwitchProps) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <Switch focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme }) => ({
  margin: '0 0.5rem',
  padding: 0,
  width: '2.625rem',
  height: '1.625rem',
  '& .MuiSwitch-switchBase': {
    margin: '0.125rem',
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(1rem)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        border: '0.125rem solid #fff',
        backgroundColor: 'transparent',
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: '1.375rem',
        height: '1.375rem',
        color: '#fff',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.secondary.main,
      border: '0.375rem solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '1.375rem',
    height: '1.375rem',
    color: '#fff',
  },
  '& .MuiSwitch-track': {
    border: '0.125rem solid #fff',
    borderRadius: '0.8125rem',
    backgroundColor: 'transparent',
    opacity: 1,
  },
}))

const ToolBarHighContrast = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isHighContrast = useAppSelector(getHighContrast)
  const highContrastAnalyticsEvent: AnalyticEvent = 'Accessibility_Toggle_Click'

  const handleHighContrastChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setHighContrast(event.target.checked))
  }

  // Handles keyboard click of slider control
  const handleSliderEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      dispatch(setHighContrast(!isHighContrast))
    }
  }

  return (
    <FormControlLabel
      sx={{ height: '100%' }}
      control={
        <HCSwitch
          id="hcSwitch"
          checked={Boolean(isHighContrast)}
          onChange={handleHighContrastChange}
          onKeyDown={handleSliderEnter}
          data-analytic-event={highContrastAnalyticsEvent}
          data-analytic-id={`High-Contrast-Turned-${
            isHighContrast ? 'On' : 'Off'
          }`}
          data-testid="Chk_Header_HighContrast"
        />
      }
      label={
        <Typography
          variant="body1"
          component="span"
          sx={{ fontFamily: 'prelo-bold' }}
          data-testid="Txt_Header_HighContrast">
          {t('Navigation.HighContrast')}
        </Typography>
      }
    />
  )
}

export default ToolBarHighContrast
