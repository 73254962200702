import React from 'react'

const MaskFemaleIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.9492 40.2196C37.9492 39.0496 38.8992 38.0996 40.0692 38.0996C41.2392 38.0996 42.1892 39.0496 42.1892 40.2196C42.1892 41.3896 41.2392 42.3396 40.0692 42.3396C38.8992 42.3396 37.9492 41.3996 37.9492 40.2196Z"
      fill="#CCCCCC"
    />
    <path
      d="M58.9395 40.2196C58.9395 39.0496 59.8895 38.0996 61.0595 38.0996C62.2295 38.0996 63.1795 39.0496 63.1795 40.2196C63.1795 41.3896 62.2295 42.3396 61.0595 42.3396C59.8895 42.3396 58.9395 41.3996 58.9395 40.2196Z"
      fill="#CCCCCC"
    />
    <path
      d="M51.6602 31.8594L53.2102 50.2594"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.3295 4.54C49.9195 2.91 46.1095 2 42.0895 2C22.7195 2 12.5095 11.54 12.5095 28.1C12.5095 28.1 12.5695 57.51 6.18945 62.58L21.0895 79C30.7695 77.26 30.8795 65.41 30.8795 65.41V26.04"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.209 74.2791C32.229 76.8091 36.989 78.2691 42.089 78.2691C56.509 78.2691 68.189 66.5791 68.189 52.1691V28.0991C68.189 27.3991 68.169 26.7191 68.119 26.0391"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6387 18.6393V26.0393H71.5887C71.5887 26.0393 73.1687 4.45928 53.3287 4.53928"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.0001 70C61.0001 70 68.0001 63 68.1501 53.62L53.2201 50.25L33.8301 52"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.1895 66.6398L33.8195 52.0098"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.1895 66.6406L51.0495 72.1306L61.0595 70.0806"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default MaskFemaleIcon
