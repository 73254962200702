import { Box, CircularProgress } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import flagFavorites from '../../../api/insights/flagFavorites'
import { Styles } from '../../../types'
import {
  ArticleMetaData,
  getArticleListMetadata,
} from '../../../utils/helpers/Article.service'
import ArticleHomeTile from '../ArticleHomeTile'

export interface ArticleHomeTilesWrapperProps {
  totalTiles: number
  parentClassName?: string
  individualArticleClassName?: string
  defaultImageColor?: string
}

const styles: Styles = {
  loadingContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    height: '6.125rem',
    color: theme.palette.common.white,
  }),
}

const ArticleHomeTilesWrapper = ({
  totalTiles,
  parentClassName = '',
  individualArticleClassName = '',
  defaultImageColor = '',
}: ArticleHomeTilesWrapperProps) => {
  const [articleMetadata, setArticleMetaData] = useState<ArticleMetaData[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const init = useCallback(
    async (pageSize: number) => {
      setIsLoading(true)
      const pagedResults = await getArticleListMetadata(1, pageSize)
      // Fetch favorites only for new page
      await flagFavorites(pagedResults.articles)

      setArticleMetaData(pagedResults.articles)
      setIsLoading(false)
    },
    [setIsLoading, getArticleListMetadata, flagFavorites, setArticleMetaData],
  )

  useEffect(() => {
    init(totalTiles)
  }, [])

  return (
    <Box>
      {isLoading ? (
        <Box sx={styles.loadingContainer} aria-busy="true">
          <CircularProgress
            size="6.25rem"
            color="inherit"
            data-testid="Spn_Insights_Loading"
          />{' '}
        </Box>
      ) : (
        <Box className={parentClassName}>
          {articleMetadata.map((article: ArticleMetaData, index: number) => (
            <Box key={article.ecpId} className={individualArticleClassName}>
              <ArticleHomeTile
                index={index}
                article={article}
                defaultImageColor={defaultImageColor}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default ArticleHomeTilesWrapper
