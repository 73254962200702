import React from 'react'

const HeadquartersBuildingIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.55078 70.8398H48.2008"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8499 70.84H8.41992V12.07L21.8499 2V70.84Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 17.1094H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 23.8301H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 30.5391H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 37.2598H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 43.9707H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 50.6895H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 57.4004H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.459 64.1191H16.819"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M65.5003 50.29V12.07L52.0703 2V51.32"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.4694 17.1094H57.1094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.4694 23.8301H57.1094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.4694 30.5391H57.1094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.4694 37.2598H57.1094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.4694 43.9707H57.1094"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8496 8.7207H52.0696"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.9596 70.8398H21.8496"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.9599 27.1894C39.7434 27.1894 41.9999 24.9329 41.9999 22.1494C41.9999 19.3659 39.7434 17.1094 36.9599 17.1094C34.1764 17.1094 31.9199 19.3659 31.9199 22.1494C31.9199 24.9329 34.1764 27.1894 36.9599 27.1894Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8906 37.2598H47.0406"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8906 43.9707H47.0406"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8906 50.6895H47.0406"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8906 57.4004H47.0406"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.8906 64.1191H46.0106"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.3803 63.7593C74.0303 71.4093 67.5403 77.3193 59.9003 76.9593C52.2603 76.6093 46.3503 70.1193 46.7003 62.4793C47.0503 54.8393 53.5403 48.9193 61.1803 49.2793C68.8303 49.6393 74.7303 56.1193 74.3803 63.7593V63.7593Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M60.3195 70.0202L53.0995 62.1302C52.8495 61.8602 52.8695 61.4502 53.1395 61.2002C53.4095 60.9602 53.8195 60.9702 54.0695 61.2402L60.3095 68.0602L76.9695 49.5902C77.2095 49.3202 77.6295 49.3002 77.8895 49.5402C78.1595 49.7802 78.1795 50.2002 77.9395 50.4702L60.3195 70.0202V70.0202Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default HeadquartersBuildingIcon
