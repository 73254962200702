/* eslint-disable react/jsx-props-no-spreading */
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useFormikContext } from 'formik'
import React from 'react'
import tokens from '../../../../../styles/tokens.json'
import { Styles } from '../../../../../types'
import { FormValues } from '../../models'

const styles: Styles = {
  readOnly: (theme) => ({
    '& .MuiInputBase-root': {
      '& input': {
        fontWeight: theme.typography.fontWeightLight,
      },
      '&.Mui-focused input': {
        color: tokens.colors.halfWhite,
      },
      '&:not(.Mui-focused) input': {
        paddingRight: '0rem',
        paddingLeft: '0rem',
      },
      '&.MuiOutlinedInput-root fieldset': {
        border: 'none',
      },
    },
  }),
}

type FormTextFieldProps = TextFieldProps & {
  maxLength?: number
  name: string
  readonly?: boolean
}

const FormTextField = ({
  label,
  name,
  maxLength,
  readonly,
  required,
  ...rest
}: FormTextFieldProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { errors, handleChange, setTouched, submitCount, touched, values } =
    useFormikContext<FormValues>()

  return (
    <TextField
      {...rest}
      sx={readonly ? styles.readOnly : undefined}
      fullWidth
      inputProps={{ 'aria-required': required, maxLength, readOnly: readonly }}
      name={name}
      value={values[name] || ''}
      required={required}
      autoComplete="off"
      error={(touched[name] || submitCount > 0) && Boolean(errors[name])}
      helperText={
        (touched[name] || submitCount > 0) && errors[name]?.toString()
      }
      data-testid={`${name}-test-id`}
      onChange={handleChange}
    />
  )
}

export default FormTextField
