import axios from 'axios'
import AuthClientConfig from '../../utils/helpers/AuthHelper'
import { IDMSERVICE_BASE_URL } from '../../envVariables'

export interface UserAppModel {
  id: number | undefined
  identifier: string | undefined
  name: string | undefined
  description: string | undefined
  category: string | undefined
  launchUri: string | undefined
  logoUri: string | undefined
  brandedApplicationType: string | undefined
}

export const getUserApps = async (): Promise<UserAppModel[]> => {
  const path = `${IDMSERVICE_BASE_URL}/api/BrandedApplications/myauthorizedapps`
  const token = await new AuthClientConfig().getAuthorization()

  const response = await axios.get(path, { headers: { Authorization: token } })

  const result =
    response.data.map((a: UserAppModel) => ({
      id: a.id,
      identifier: a.identifier,
      name: a.name,
      description: a.description,
      category: a.category,
      launchUri: a.launchUri,
      logoUri: a.logoUri,
      brandedApplicationType: a.brandedApplicationType,
    })) ?? []

  return [...result].filter(
    (a) => a.name?.indexOf('Enterprise Client Portal') === -1,
  )
}
