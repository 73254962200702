import React from 'react'

const CloseOutlinedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="9" stroke="#515356" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5856 8.62288L15.3771 7.41431L11.9998 10.7915L8.62263 7.41431L7.41406 8.62288L10.7913 12.0001L7.41406 15.3773L8.62263 16.5859L11.9998 13.2087L15.3771 16.5859L16.5856 15.3773L13.2084 12.0001L16.5856 8.62288Z"
      fill="#515356"
    />
  </svg>
)

export default CloseOutlinedIcon
