import { TeamDocsSiteViewModel } from '@rsmus/ecp-userservice'

// Incoming Projects (a and b below) look like this:
// emailAddress:'sbdavin+ecp@gmail.com'
//       client:'Miami Beach'
//      project:'Our Cool Project'
//      siteUrl:'https://www.lycos.com')

// Per story #430704, Project names should be displayed using a custom sort order
// Sort Rules:
// The sort is case-insensitive
// Goofy (non-alphanumeric) characters include: !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~
// If project name contains only goofy chars, sort them before numbers/letters
// If project name begins with goofy chars but also contains number(s)/letter(s), sort based on first numbers/letters

const sortProjects = (a: TeamDocsSiteViewModel, b: TeamDocsSiteViewModel) => {
  let aProject: string = a?.name?.toLowerCase() ?? ''
  let bProject: string = b?.name?.toLowerCase() ?? ''
  const exp = /[a-z0-9]/gi
  const aPos: number = aProject.search(exp)
  const bPos: number = bProject.search(exp)

  // Does 'a' begin with goofy chars?
  if (aPos > 0) {
    aProject = aProject.substring(aPos)
  }

  if (bPos > 0) {
    bProject = bProject.substring(bPos)
  }

  if (aPos === -1 && bPos !== -1) return -1 // If a is all goofy chars but b is not...
  if (bPos === -1 && aPos !== -1) return 1 // If b is all goofy chars but a is not...

  // Both use regular letters/numbers OR both are completely goofy chars
  if (aProject < bProject) return -1
  if (aProject > bProject) return 1
  return 0
}

export default sortProjects
