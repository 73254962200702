/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress as MuiCircularProgress, styled } from '@mui/material'

const CircularProgress = styled(MuiCircularProgress)(({ theme }) => ({
  color: theme.palette.secondary.main,
  position: 'absolute',
  top: '50%',
  left: '50%',
}))

export default CircularProgress
