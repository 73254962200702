import React from 'react'
import { useTheme } from '@mui/material'

const CheckboxFilledIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_0_5689)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3Z"
        fill={useTheme().palette.secondary.main}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 13.2374L7.93265 11.2163L10.1969 13.2988L16.4199 7.44434L18 9.48968L10.1426 16.7777L6 13.2374Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_0_5689"
        x="0"
        y="0"
        width="24"
        height="25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0590183 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_0_5689"
        />
      </filter>
    </defs>
  </svg>
)

export default CheckboxFilledIcon
