import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

interface NotificationState {
  deleteNotificationId: number
  newNotificationCount: number
}

const initialState: NotificationState = {
  deleteNotificationId: 0,
  newNotificationCount: 0,
}

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setDeleteNotificationId: (state, { payload }) => {
      if (payload) state.deleteNotificationId = parseInt(payload, 10)
    },
    setNewNotificationCount: (state, { payload }) => {
      state.newNotificationCount = payload
    },
  },
})

export const { setDeleteNotificationId, setNewNotificationCount } =
  notificationSlice.actions
export const getDeleteNotificationId = (state: RootState) =>
  state.notifications.deleteNotificationId
export const getNotificationCount = (state: RootState) =>
  state.notifications.newNotificationCount

export default notificationSlice.reducer
