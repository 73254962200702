import React from 'react'

const LeafMoleculeIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M67.9597 35.9C64.2897 40.06 59.4597 42.96 54.3697 45.88M38.5497 56.57C37.7097 57.36 36.8897 58.19 36.0897 59.08C29.6697 66.24 23.9797 78.54 23.9797 78.54M59.7697 27.42C68.3297 28.86 76.9997 23.25 76.9997 23.25C76.9997 23.25 77.1397 43.87 69.4297 59.12C61.7197 74.37 51.3597 77.17 41.7097 77.12C31.7897 77.06 32.1897 69.72 28.9197 68.3C25.6497 66.88 16.5097 64.72 15.1797 51.5"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M59.0598 29.36C59.0598 44.47 46.8098 56.72 31.6998 56.72C16.5898 56.72 4.33984 44.47 4.33984 29.36C4.33984 14.25 16.5898 2 31.6998 2C46.8098 2 59.0598 14.25 59.0598 29.36Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M45.9308 17.2209L47.8208 13.8909M53.3808 36.7709H49.6808M53.2808 23.7509H49.5808M36.9308 53.4809L34.8708 49.8509M25.8908 52.5909L27.4508 49.8509M23.7408 43.3309H20.0408M34.7908 10.7109L36.6808 7.38086M25.5608 7.41086L27.4208 10.6809M47.9208 46.9609L45.8608 43.3309M16.2908 17.2109L14.6508 14.3309M14.7508 33.0109L16.3108 30.2709M12.5008 23.8009H8.80078M27.4208 49.7909L23.7208 43.2809L27.4208 36.7709H34.8308L38.5308 43.2809L34.8308 49.7909H27.4208V49.7909ZM27.3808 23.7509L23.6808 17.2409L27.3808 10.7309H34.7908L38.4908 17.2409L34.7908 23.7509H27.3808V23.7509ZM38.4908 43.2709L34.7908 36.7609L38.4908 30.2509H45.9008L49.6008 36.7609L45.9008 43.2709H38.4908ZM27.3908 36.7609L23.6908 30.2509L27.3908 23.7409H34.8008L38.5008 30.2509L34.8008 36.7609H27.3908V36.7609ZM38.4908 30.2709L34.7908 23.7609L38.4908 17.2509H45.9008L49.6008 23.7609L45.9008 30.2709H38.4908ZM16.3008 30.2709L12.6008 23.7609L16.3008 17.2509H23.7108L27.4108 23.7609L23.7108 30.2709H16.3008Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default LeafMoleculeIcon
