import React from 'react'
import { useTheme, Box, Link, Theme, Card } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GreaterthanIcon from '../../icons/GreaterthanIcon'
import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import ToolsIcon from '../../icons/ToolsIcon'

type OwnProps = {
  fullWidth?: boolean
}

export const useStyles = (theme: Theme, { fullWidth }: OwnProps) => ({
  root: {
    margin: '0.25rem',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    width: fullWidth ? '100%' : '27rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    justifyContent: 'flex-start',
  },
  left: {
    flex: '1 1 100%',
  },
  category: {
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  title: {
    fontFamily: 'Prelo-Book,sans-serif',
    fontSize: '1.125rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    marginBottom: '1rem',
    marginRight: '2rem',
  },
  contactLink: {
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: '500',
    textDecoration: 'underline',
    fontFamily: 'Prelo-Book, sans-serif',
  },
  arrowIcon: {
    marginTop: '.6rem',
    color: theme.palette.secondary.main,
    marginLeft: '.3rem',
    display: 'inline-block',
  },
  icon: {
    display: 'block',
    [theme.breakpoints.down('tablet')]: {
      display: 'none',
    },
  },
})

const ReportAnIssueCard = ({ fullWidth = false }: OwnProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const classes = useStyles(theme, { fullWidth })

  return (
    <Card variant="outlined" sx={classes.root}>
      <Box sx={classes.left}>
        <Box component="h2" sx={classes.category}>
          <OverFlowToolTip testId="Hed_ReportAnIssueCard_Name">
            {t('Projects.ReportCard.Name')}
          </OverFlowToolTip>
        </Box>
        <Box component="h3" sx={classes.title}>
          <OverFlowToolTip
            numberOfLines={4}
            testId="Hed_ReportAnIssueCard_Name_Category">
            {t('Projects.ReportCard.Category')}
          </OverFlowToolTip>
        </Box>
        <Link
          href="#ref"
          onClick={() => navigate('/reportanissue')}
          sx={classes.contactLink}
          data-testid="Lnk_ReportAProblemCard_Name_ContactReportAnIssue">
          {t('Projects.ReportCard.Name')}
        </Link>
        <Link
          href="#ref"
          onClick={() => navigate('/reportanissue')}
          sx={classes.contactLink}
          tabIndex={-1}
          aria-hidden="true"
          data-testid="Lnk_ReportAProblemCard_Name_ContactReportAnIssueIcon">
          <Box component="span" sx={classes.arrowIcon}>
            <GreaterthanIcon />
          </Box>
        </Link>
      </Box>
      <Box sx={classes.icon}>
        <ToolsIcon heightInRem="3.75rem" widthInRem="3.75rem" />
      </Box>
    </Card>
  )
}

export default ReportAnIssueCard
