import React from 'react'

const HandCurrencyIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.39 62.12L46 32"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M45.8505 32.1004L34.4005 28.1104L31.6105 27.9304L26.3905 24.9304C24.6305 23.9204 24.0205 21.6704 25.0305 19.9104C25.4305 19.2204 26.0105 18.7104 26.6805 18.4004L31.0305 20.9004L38.7105 20.6004L50.4605 27.3404L62.3505 39.3404H69.3505V62.5904H57.9205"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M75.9998 35.0488H69.3398V68.8988H75.9998"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M40.5596 21.6595L32.1296 1.76953L2.84961 14.1895L10.8496 33.0695"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.6191 63.1901L28.6291 75.0001L57.9091 62.5801"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M27.6403 32.1791C24.2703 33.6091 22.7003 37.4991 24.1303 40.8691C25.5603 44.2391 29.4503 45.8091 32.8203 44.3791C36.1903 42.9491 37.7603 39.0591 36.3303 35.6891C34.9003 32.3191 31.0103 30.7491 27.6403 32.1791Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M24.8105 65.9905C29.7905 63.8805 35.5305 66.2005 37.6405 71.1805L28.6305 75.0005L24.8105 65.9905V65.9905Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.8498 10.3691C13.9598 15.3491 11.6398 21.0891 6.65984 23.1991L2.83984 14.1891L11.8498 10.3691Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.9107 62.5803L48.9007 66.4003C46.7907 61.4203 49.3707 55.6803 54.3407 53.5703"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M35.9491 10.7795C30.9691 12.8895 25.2291 10.5695 23.1191 5.58953L32.1291 1.76953L35.9491 10.7795Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.11022 41.4697L12.9602 40.7397C14.3602 39.8497 15.0802 38.2197 14.8602 36.4597C14.6502 34.8097 13.4302 33.4997 11.9902 32.9297L6.14022 33.6597C4.74022 34.5497 4.02022 36.1797 4.24022 37.9397C4.45022 39.5897 5.67022 40.8997 7.11022 41.4697V41.4697Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.3993 48.8603L17.2493 48.1303C18.6493 47.2403 19.3693 45.6103 19.1493 43.8503C18.9393 42.2003 17.7193 40.8903 16.2793 40.3203L10.4293 41.0503C9.02928 41.9403 8.30928 43.5703 8.52928 45.3303C8.73928 46.9803 9.95928 48.2903 11.3993 48.8603V48.8603Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M15.7001 56.249L21.5501 55.519C22.9501 54.629 23.6701 52.999 23.4501 51.239C23.2401 49.589 22.0201 48.279 20.5801 47.709L14.7301 48.439C13.3301 49.329 12.6101 50.959 12.8301 52.719C13.0401 54.369 14.2601 55.679 15.7001 56.249V56.249Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.9911 63.6396L25.8411 62.9096C27.2411 62.0196 27.9611 60.3896 27.7411 58.6296C27.5311 56.9796 26.3111 55.6696 24.8711 55.0996L19.0211 55.8296C17.6211 56.7196 16.9011 58.3496 17.1211 60.1096C17.3311 61.7596 18.5511 63.0696 19.9911 63.6396Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default HandCurrencyIcon
