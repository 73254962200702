import React from 'react'

const SuccessIcon = ({ color }: any) => (

   <svg
     width="20"
     height="15"
     viewBox="0 0 20 15"
     fill="none"
     xmlns="http://www.w3.org/2000/svg">
     <path
       fillRule="evenodd"
       clipRule="evenodd"
       d="M7.14286 14.8857L0 7.74286L2.01429 5.72857L7.14286 10.8429L17.9857 0L20 2.02857L7.14286 14.8857Z"
       fill={color}
     />
   </svg>
)

export default SuccessIcon
