/* eslint-disable max-classes-per-file */
import {
  AuthClientConfig as CemConfig,
  CemServiceClient as BaseCemServiceClient,
} from '@rsmus/xcelerate-cemservice'
import {
  AuthClientConfig as CmsConfig,
  CmsServiceClient as BaseCmsServiceClient,
} from '@rsmus/ecp-cmsservice'
import {
  AuthClientConfig as CommunicationConfig,
  CommunicationServiceClient as BaseCommunicationServiceClient,
} from '@rsmus/ecp-communicationservice'
import {
  AuthClientConfig as DocumentRequestConfig,
  DocumentRequestServiceClient as BaseDocumentRequestServiceClient,
} from '@rsmus/ecp-documentrequestservice'
import {
  AuthClientConfig as UserConfig,
  UserServiceClient as BaseUserServiceClient,
} from '@rsmus/ecp-userservice'
import {
  AuthClientConfig as FinanceConfig,
  FinanceServiceClient as BaseFinanceServiceClient,
} from '@rsmus/ecp-financeservice'
import {
  CEMSERVICE_BASE_URL,
  CMSSERVICE_BASE_URL,
  COMMUNICATIONSERVICE_BASE_URL,
  DOCUMENTREQUESTSERVICE_BASE_URL,
  FINANCESERVICE_BASE_URL,
  USERSERVICE_BASE_URL,
} from '../envVariables'

export function reviver(key: any, value: any) {
  const reDateTimeDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/ // startswith: 2015-04-29T22:06:55
  if (typeof value === 'string' && reDateTimeDetect.exec(value)) {
    const date = new Date(value)
    if (date instanceof Date && !Number.isNaN(date.valueOf())) {
      return date
    }

    return value
  }

  const reDateOnlyDetect = /^(\d{4})-(\d{2})-(\d{2})$/ // equals: 2015-04-29
  if (typeof value === 'string' && reDateOnlyDetect.exec(value)) {
    const date = new Date(`${value}T00:00:00`) // No 'Z' at the end, because we want the date to be interpreted as local time so that the date is not changed.
    if (date instanceof Date && !Number.isNaN(date.valueOf())) {
      return date
    }

    return value
  }

  return value
}

class CemServiceClient extends BaseCemServiceClient {
  protected override jsonParseReviver = reviver
}
class CmsServiceClient extends BaseCmsServiceClient {
  protected override jsonParseReviver = reviver
}

class CommunicationServiceClient extends BaseCommunicationServiceClient {
  protected override jsonParseReviver = reviver
}
class DocumentRequestServiceClient extends BaseDocumentRequestServiceClient {
  protected override jsonParseReviver = reviver
}
class FinanceServiceClient extends BaseFinanceServiceClient {
  protected override jsonParseReviver = reviver
}
class UserServiceClient extends BaseUserServiceClient {
  protected override jsonParseReviver = reviver
}
const cem = new CemServiceClient(new CemConfig(), CEMSERVICE_BASE_URL)
const cms = new CmsServiceClient(new CmsConfig(), CMSSERVICE_BASE_URL)
const communication = new CommunicationServiceClient(
  new CommunicationConfig(),
  COMMUNICATIONSERVICE_BASE_URL,
)
const documentRequest = new DocumentRequestServiceClient(
  new DocumentRequestConfig(),
  DOCUMENTREQUESTSERVICE_BASE_URL,
)
const finance = new FinanceServiceClient(
  new FinanceConfig(),
  FINANCESERVICE_BASE_URL,
)
const user = new UserServiceClient(new UserConfig(), USERSERVICE_BASE_URL)

export default {
  cem,
  cms,
  communication,
  documentRequest,
  finance,
  user,
}
