import React from 'react'

type OwnProps = {
  heightInRem?: string
  widthInRem?: string
}

const ToolsIcon = ({ heightInRem = '5rem', widthInRem = '5rem' }: OwnProps) => (
  <svg
    width={widthInRem}
    height={heightInRem}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.5477 8.397L65.9029 11.7522L45.5478 32.1129L47.8872 34.4523L68.2479 14.0972L71.6031 17.4524L51.248 37.8131L53.4335 39.9986C53.4335 39.9986 63.9961 29.4373 69.2774 24.1567C71.2569 22.1775 75.2157 18.2192 75.2157 18.2192C76.9339 16.501 78.0001 14.1224 78.0001 11.5003C78.0001 6.25347 73.7466 2 68.4997 2C65.8721 2 63.4963 3.06337 61.7781 4.78714L40.0015 26.5666L42.187 28.7521L62.5477 8.397Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.071 60.8994H9.60028L2 74.0516L5.94845 78L19.1006 70.3998V64.929L35.2974 48.7322L31.2678 44.7026L15.071 60.8994Z"
      stroke="#CCCCCC"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.4997 74.6728C65.0941 74.6728 62.3237 71.9024 62.3237 68.4997C62.3237 65.0941 65.0941 62.3237 68.4997 62.3237C71.9052 62.3237 74.6756 65.0941 74.6756 68.4997C74.6756 71.9024 71.9052 74.6728 68.4997 74.6728Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.8711 22.4362C36.0809 21.3561 36.2013 20.2424 36.2013 19.1006C36.2013 9.65625 28.545 2 19.1006 2C17.9561 2 16.8452 2.11753 15.765 2.3302L15.5831 2.5093L24.6665 11.5927C26.1133 13.0394 26.1133 15.3788 24.6665 16.8228L16.8256 24.6665C15.3788 26.1105 13.0394 26.1105 11.5955 24.6665L3.1809 16.2491L2.5121 15.5831L2.3302 15.7622C2.12033 16.8424 2 17.9561 2 19.1006C2 28.5422 9.65625 36.1985 19.1006 36.1985C20.2424 36.1985 21.3561 36.0809 22.4334 35.8683L61.7752 75.2073C63.4962 76.931 65.872 78 68.4996 78C73.7465 78 78 73.7437 78 68.4996C78 65.872 76.9338 63.4962 75.2157 61.778L35.8711 22.4362Z"
      stroke="#009CDE"
      strokeWidth="2"
    />
  </svg>
)

export default ToolsIcon
