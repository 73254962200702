import React, { useCallback } from 'react'
import { Box, FormHelperText, Select, styled } from '@mui/material'
import { PaymentInstallmentFrequencyEnum } from '@rsmus/ecp-financeservice'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { EntityLevelEnum } from '@rsmus/ecp-userservice'
import { ArrowDownIcon } from '../../../icons'
import { Styles } from '../../../../types'
import { tokens } from '../../../../styles/materialTheme'
import { isCemFeatureEnabled } from '../../../../rsmCoreComponents/utils/featureFlagUtils'
import { CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS } from '../../../../utils/constants/constants'
import { getCemFeatures } from '../../../../store/userInfo/userInfoSlice'

const styles: Styles = {
  FrequencyContainer: (theme) => ({
    width: '50%',
    paddingTop: 0,
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
      paddingLeft: 0,
    },
    [theme.breakpoints.only('tablet')]: {
      width: 'calc(50% - 4rem)',
      paddingLeft: 0,
    },
  }),
  requiredError: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '0.875rem',
    color: tokens.colors.rsmRed.secondary,
  },
}

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: 'Prelo-Black, sans-serif',
  fontSize: '1rem',
  paddingBottom: '0.5rem',
  color: theme.palette.text.primary,
  display: 'block',
}))

const StyledSelectField = styled(Select)(({ theme }) => ({
  '.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: '100%',
  },
  '.MuiSelect-select': { fontFamily: 'Prelo-Book !important' },
  width: 'calc(100% - 4rem)',
  [theme.breakpoints.down('desktop')]: {
    width: '100%',
  },
}))

const InstallmentFrequency = () => {
  const cemFeatures = useSelector(getCemFeatures)
  const { t } = useTranslation()
  const { control, setValue, getValues, clearErrors } = useFormContext()

  const hasExtendedInstallments = useCallback(
    () =>
      isCemFeatureEnabled(
        CEM_FEATURE_PAYMENT_EXTENDED_INSTALLMENTS,
        cemFeatures,
        'any',
        EntityLevelEnum.Application,
      ),
    [cemFeatures],
  )

  const displayedInstallments = {
    [PaymentInstallmentFrequencyEnum.Weekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.BiWeekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.Monthly]: [2, 4, 6],
  }
  const displayedInstallmentsExtended = {
    [PaymentInstallmentFrequencyEnum.Weekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.BiWeekly]: [2, 4, 6],
    [PaymentInstallmentFrequencyEnum.Monthly]: [2, 4, 6],
  }

  return (
    <Box sx={styles.FrequencyContainer}>
      <StyledLabel htmlFor="frequency">
        {t('Invoicing.Frequency')} *
        <span className="sr-only">{t('srOnlyRequired')}</span>
      </StyledLabel>
      <Controller
        name="frequency"
        control={control}
        defaultValue={PaymentInstallmentFrequencyEnum.Monthly}
        rules={{
          required: `${t('Invoicing.FrequencyRequired')}`,
        }}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <>
            <StyledSelectField
              native
              id="frequency"
              fullWidth
              value={value}
              inputRef={ref}
              IconComponent={ArrowDownIcon}
              onChange={(e) => {
                onChange(e)
                setValue(
                  'numberOfInstallments',
                  (hasExtendedInstallments()
                    ? displayedInstallmentsExtended
                    : displayedInstallments)?.[
                    getValues('frequency') as keyof typeof displayedInstallments
                  ][0],
                )
                setValue('numberOfInstallmentsOther', '')
                clearErrors([
                  'numberOfInstallments',
                  'numberOfInstallmentsOther',
                ])
              }}
              error={!!error}
              inputProps={{
                'aria-describedby': 'frequencyErrorText',
              }}>
              <option
                key="Monthly"
                value={PaymentInstallmentFrequencyEnum.Monthly}>
                {t(`Invoicing.Monthly`)}
              </option>
              <option
                key="Weekly"
                value={PaymentInstallmentFrequencyEnum.Weekly}>
                {t(`Invoicing.Weekly`)}
              </option>
              <option
                key="BiWeekly"
                value={PaymentInstallmentFrequencyEnum.BiWeekly}>
                {t(`Invoicing.BiWeekly`)}
              </option>
            </StyledSelectField>
            {error && (
              <FormHelperText id="frequencyErrorText" sx={styles.requiredError}>
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  )
}

export default InstallmentFrequency
