import api from '../../../api'
import { CEM_ENGAGEMENT_MAX } from '../../../envVariables'
import { publish } from '../../../utils/helpers/PubSub.service'

export interface TeamMember {
  id: string | undefined
  email: string | undefined
  name: string
  firstName: string
  lastName: string
}

export const getTeamMembers = async (engagementId: string) => {
  const unauthorizedEngagementTopic = 'unauthorizedEngagement'
  const response = await api.cem
    .engagements_GetTeam2(
      engagementId,
      undefined,
      CEM_ENGAGEMENT_MAX,
      undefined,
    )
    .catch((reason) => {
      if (reason?.status === 403 || reason?.status === 404) {
        publish(unauthorizedEngagementTopic, engagementId)
      }
      return null
    })
  const result =
    response?.results?.map((t) => ({
      id: t.id,
      email: t.email,
      name: t.name || '',
      firstName: t.firstName || '',
      lastName: t.lastName || '',
    })) ?? []

  const teamMembers: TeamMember[] = [...result]

  teamMembers.sort((a, b) => a.name.localeCompare(b.name))

  return teamMembers
}

const rsmDomain = '@rsm'

export const getRSMTeamMembers = (teamMembers: TeamMember[]) =>
  teamMembers.filter(
    (t) => t.email !== undefined && t.email?.indexOf(rsmDomain) > -1,
  )

export const getClientTeamMembers = (teamMembers: TeamMember[]) =>
  teamMembers.filter(
    (t) => t.email !== undefined && t.email?.indexOf(rsmDomain) === -1,
  )
