import React from 'react'

const FileIcon = () => (
  <svg
    width="58"
    height="74"
    viewBox="0 0 58 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 72H57.5V1H1.5V72Z"
      stroke="#009CDE"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 72H57.5V1H1.5V72Z"
      stroke="#009CDE"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 73H57.5V1H0.5V73Z"
      stroke="#009CDE"
      strokeLinecap="round"
    />
    <path d="M7.5 9H38.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 17H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 36H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 21H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 40H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 25H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 44H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 29H34.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 48H26.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 56H49.5" stroke="#009CDE" strokeLinecap="round" />
    <path d="M7.5 60H49.5" stroke="#009CDE" strokeLinecap="round" />
  </svg>
)

export default FileIcon
