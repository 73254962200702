import React from 'react'
import { Box } from '@mui/material'
import { FaqItemModel, FaqTopicModel } from 'src/store/faqs/faqsSlice'
import { tokens } from '../../../../../styles/materialTheme'
import { Styles } from '../../../../../types'
import FaqItem from '../FaqItem'
import tokensJson from '../../../../../styles/tokens.json'

const styles: Styles = {
  topic: (theme) => ({
    fontSize: '2.25rem',
    lineHeight: '2.25rem',
    color: tokens.colors.rsmGray.copy,
    [theme.breakpoints.up('mobile')]: {
      fontSize: '1.5rem',
      paddingTop: '2.5rem',
    },
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: '0.75rem',
    },
    [theme.breakpoints.up('tablet')]: {
      paddingTop: '2.5rem',
      paddingBottom: '1.5rem',
      fontSize: '2.25rem',
    },
  }),
  faqitem: () => ({
    backgroundColor: tokensJson.colors.white,
  }),
}

type FaqTopicProps = {
  topic: FaqTopicModel
  topicIndex: number
}
const faqTopic = ({ topic, topicIndex }: FaqTopicProps) => (
  <Box>
    <Box
      component="h2"
      sx={styles.topic}
      data-testid={`Lbl_Faq_TopicTitle_${topicIndex}`}
      className="font-prelo-light">
      {topic?.topic}
    </Box>
    <Box sx={styles.faqitem}>
      {topic?.items?.map((item: FaqItemModel, index: number) => (
        <FaqItem
          topicIndex={topicIndex}
          indexNum={index}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </Box>
  </Box>
)

export default faqTopic
