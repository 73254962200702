import { Box } from '@mui/material'
import React from 'react'

interface MainContentProps {
  children?: React.ReactNode
}

const MainContent = ({ children = undefined }: MainContentProps) => (
  <Box component="main" id="main-content" tabIndex={-1}>
    {children}
  </Box>
)

export default MainContent
