import { Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '../../rsmCoreComponents/components/RsmCircularProgress'
import InvoiceAgreementDialog from './InvoiceAgreementDialog'
import InvoiceBaseLayout from './InvoiceBaseLayout'
import InvoicePreviewLayout from './InvoicePreviewLayout'
import api from '../../api'

// Component representing the layout for protected invoices
const InvoiceProtectedLayout = () => {
  // State to manage the dialog open/close state
  const [open, setOpen] = useState<boolean>(false)
  // State to track if the user has accepted the agreement
  const [accepted, setAccepted] = useState<boolean>(true)
  // State to manage loading state
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // State to track if an error occurred during data retrieval
  const [isError, setIsError] = useState<boolean>(false)

  // This is incorporated to manage the Agreement dialog behavior across various pages.
  const [loopCounter, setLoopCounter] = useState(() => {
    // Retrieve loopCounter from localStorage on component mount
    const storedValue = localStorage.getItem('loopCounter')
    return parseInt(storedValue || '0', 10)
  })

  // React Router's navigation hook
  const navigate = useNavigate()

  const onError = useCallback(() => {
    // TODO Ask Business on Error Handling
    navigate('/support')
  }, [navigate])

  // Callback for declining the agreement
  // Define a callback function for declining the agreement
  const onDecline = useCallback(() => {
    // Check if the user has declined the agreement more than or equal to 2 times
    if (loopCounter >= 2) {
      localStorage.removeItem('loopCounter')
      // Close the dialog
      setOpen(false)
      // If exceeded the limit, remove loopCounter from localStorage and navigate to the parent directory
      // Introduce a delay before navigation
      setTimeout(() => {
        navigate('../')
      }, 0)
    } else {
      // If within the limit, increment the loopCounter, store in localStorage, and navigate back in the browser history
      // Increment loopCounter using the functional update to ensure proper state updates
      setLoopCounter((prevCounter) => {
        // Calculate the new counter value
        const newCounter = prevCounter + 1
        // Store the updated counter value in localStorage
        localStorage.setItem('loopCounter', newCounter.toString())
        // Return the new counter value
        return newCounter
      })
      // Close the dialog
      setOpen(false)
      // Navigate back in the browser history
      // Introduce a delay before navigation
      setTimeout(() => {
        navigate(-1)
      }, 0)
    }
  }, [navigate, setOpen, loopCounter, setLoopCounter])

  // Callback for accepting the agreement
  const onAccept = useCallback(async () => {
    // Set the agreement status to accepted, close the dialog, and record the user's agreement acceptance
    setAccepted(true)
    setOpen(false)
    await api.finance.userAgreement_AcceptUserAgreement()
  }, [setAccepted])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        // Fetch user agreement data from the API
        const agreement = await api.finance.userAgreement_GetUserAgreement()
        // Check if the user has not accepted the agreement
        if (!agreement?.data?.acceptedAgreementDate) {
          // Set the accepted state to false and open the dialog
          setAccepted(false)
          // Increment loop counter and store in localStorage
          setLoopCounter((prevCounter) => {
            const newCounter = prevCounter + 1
            localStorage.setItem('loopCounter', newCounter.toString())
            return newCounter
          })
          setOpen(true)
        }
      } catch (err) {
        /* eslint-disable no-console */
        console.error('Error Retrieving Invoice Data', err)
        setIsError(true)
        onError()
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  if (isLoading) return <CircularProgress />

  if (isError) return <Box>Error Loading </Box>

  // Render the appropriate layout based on the user's agreement status
  return (
    <>
      {!isLoading && accepted && <InvoiceBaseLayout />}
      {!isLoading && !accepted && <InvoicePreviewLayout />}
      <InvoiceAgreementDialog
        open={open}
        onAccept={onAccept}
        onClose={onDecline}
      />
    </>
  )
}

export default InvoiceProtectedLayout
