import React from 'react'

const MicrochipIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.3197 41.8301H8.92969"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.3198 47.5H14.1298L11.3298 50.3H5.33984"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.3198 35.0395H12.5298L11.0098 33.2695"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M23.3198 27.4504H13.3298L10.9298 24.6504H5.33984"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M44.25 54.5391V60.5291"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.5801 54.7402V64.1302L54.2401 66.9302V72.9202"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M37.1905 54.7402V67.7202L34.9805 70.0702"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.8707 54.7402V60.5302L27.4707 63.3302V72.5202"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M56.6797 48.6703H65.6697L68.4597 46.0703H74.4597"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M56.6797 41.3409H63.6697L66.4697 38.8809H73.2597"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M64.0697 34.0898H56.6797"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M56.6797 27.0898H66.4697"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.0898 21.5808V5.80078"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M43.9004 21.5791V15.3891L46.5704 12.5891V9.78906"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M36.7793 21.5806V11.3906L38.5793 9.39062"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M29.4495 21.5796V13.3896L26.1895 10.9896V6.59961"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M75.8492 47.6691C76.6224 47.6691 77.2492 47.0423 77.2492 46.2691C77.2492 45.4959 76.6224 44.8691 75.8492 44.8691C75.076 44.8691 74.4492 45.4959 74.4492 46.2691C74.4492 47.0423 75.076 47.6691 75.8492 47.6691Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M74.6598 40.0793C75.433 40.0793 76.0598 39.4525 76.0598 38.6793C76.0598 37.9061 75.433 37.2793 74.6598 37.2793C73.8866 37.2793 73.2598 37.9061 73.2598 38.6793C73.2598 39.4525 73.8866 40.0793 74.6598 40.0793Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M65.4703 35.2902C66.2435 35.2902 66.8703 34.6634 66.8703 33.8902C66.8703 33.117 66.2435 32.4902 65.4703 32.4902C64.6971 32.4902 64.0703 33.117 64.0703 33.8902C64.0703 34.6634 64.6971 35.2902 65.4703 35.2902Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M67.8609 28.4895C68.6341 28.4895 69.2609 27.8627 69.2609 27.0895C69.2609 26.3163 68.6341 25.6895 67.8609 25.6895C67.0877 25.6895 66.4609 26.3163 66.4609 27.0895C66.4609 27.8627 67.0877 28.4895 67.8609 28.4895Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.1598 5.8C51.933 5.8 52.5598 5.1732 52.5598 4.4C52.5598 3.6268 51.933 3 51.1598 3C50.3866 3 49.7598 3.6268 49.7598 4.4C49.7598 5.1732 50.3866 5.8 51.1598 5.8Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M46.7691 9.79023C47.5423 9.79023 48.1691 9.16343 48.1691 8.39023C48.1691 7.61704 47.5423 6.99023 46.7691 6.99023C45.9959 6.99023 45.3691 7.61704 45.3691 8.39023C45.3691 9.16343 45.9959 9.79023 46.7691 9.79023Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M39.1793 9.79023C39.9525 9.79023 40.5793 9.16343 40.5793 8.39023C40.5793 7.61704 39.9525 6.99023 39.1793 6.99023C38.4061 6.99023 37.7793 7.61704 37.7793 8.39023C37.7793 9.16343 38.4061 9.79023 39.1793 9.79023Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M26.3902 6.99922C27.1634 6.99922 27.7902 6.37242 27.7902 5.59922C27.7902 4.82602 27.1634 4.19922 26.3902 4.19922C25.617 4.19922 24.9902 4.82602 24.9902 5.59922C24.9902 6.37242 25.617 6.99922 26.3902 6.99922Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M47.3894 45.9507H32.7294L32.1094 45.3707V30.6407L32.6294 29.9707H47.4994L48.0894 30.6207V45.4907L47.3894 45.9507Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M54.4395 54.7401H25.6195L23.5195 52.7401V23.7701L25.3995 21.5801H54.6595L56.6795 23.7201V52.9901L54.4395 54.7401Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M7.53086 43.0305C8.30406 43.0305 8.93086 42.4037 8.93086 41.6305C8.93086 40.8573 8.30406 40.2305 7.53086 40.2305C6.75766 40.2305 6.13086 40.8573 6.13086 41.6305C6.13086 42.4037 6.75766 43.0305 7.53086 43.0305Z"
      fill="white"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M3.93906 51.8996C4.71226 51.8996 5.33906 51.2728 5.33906 50.4996C5.33906 49.7264 4.71226 49.0996 3.93906 49.0996C3.16586 49.0996 2.53906 49.7264 2.53906 50.4996C2.53906 51.2728 3.16586 51.8996 3.93906 51.8996Z"
      fill="white"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M10.3297 33.4406C11.1029 33.4406 11.7297 32.8138 11.7297 32.0406C11.7297 31.2674 11.1029 30.6406 10.3297 30.6406C9.55649 30.6406 8.92969 31.2674 8.92969 32.0406C8.92969 32.8138 9.55649 33.4406 10.3297 33.4406Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M3.93906 26.2492C4.71226 26.2492 5.33906 25.6224 5.33906 24.8492C5.33906 24.076 4.71226 23.4492 3.93906 23.4492C3.16586 23.4492 2.53906 24.076 2.53906 24.8492C2.53906 25.6224 3.16586 26.2492 3.93906 26.2492Z"
      fill="white"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M44.4508 63.3293C45.224 63.3293 45.8508 62.7025 45.8508 61.9293C45.8508 61.1561 45.224 60.5293 44.4508 60.5293C43.6776 60.5293 43.0508 61.1561 43.0508 61.9293C43.0508 62.7025 43.6776 63.3293 44.4508 63.3293Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M27.2691 75.3195C28.0423 75.3195 28.6691 74.6927 28.6691 73.9195C28.6691 73.1463 28.0423 72.5195 27.2691 72.5195C26.4959 72.5195 25.8691 73.1463 25.8691 73.9195C25.8691 74.6927 26.4959 75.3195 27.2691 75.3195Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M34.0602 72.5207C34.8334 72.5207 35.4602 71.8939 35.4602 71.1207C35.4602 70.3475 34.8334 69.7207 34.0602 69.7207C33.287 69.7207 32.6602 70.3475 32.6602 71.1207C32.6602 71.8939 33.287 72.5207 34.0602 72.5207Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M54.4391 75.7199C55.2123 75.7199 55.8391 75.0931 55.8391 74.3199C55.8391 73.5467 55.2123 72.9199 54.4391 72.9199C53.6659 72.9199 53.0391 73.5467 53.0391 74.3199C53.0391 75.0931 53.6659 75.7199 54.4391 75.7199Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default MicrochipIcon
