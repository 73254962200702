import React from 'react'
import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { APP_INSIGHTS_INSTRUMENTATION_KEY as KEY } from '../../envVariables'
import { parseJwt } from '../utils/AuthVerify'

export const rcPlugin = new ReactPlugin()

const clickPluginInstance = new ClickAnalyticsPlugin()
// Click Analytics configuration
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
}

const bHistory = createBrowserHistory()

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${KEY || 'APP_KEY'}`,
    enableAutoRouteTracking: true,
    extensions: [rcPlugin, clickPluginInstance],
    extensionConfig: {
      [rcPlugin.identifier]: { history: bHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
})

appInsights.loadAppInsights()

appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.tags?.push({ 'ai.cloud.role': 'React App' })

  const matchingKeys = Object.keys(sessionStorage).filter((key) =>
    key.startsWith('oidc.user:'),
  )

  if (matchingKeys && matchingKeys.length > 0) {
    const rawValueFromStorage = sessionStorage[matchingKeys[0]]
    if (rawValueFromStorage) {
      const token = JSON.parse(rawValueFromStorage)
      if (token && token.access_token) {
        const parsedToken = parseJwt(token.access_token)
        if (parsedToken && parsedToken.rsmuid) {
          envelope.tags?.push({ 'ai.user.authUserId': parsedToken.rsmuid })
        }
      }
    }
  }
})

export default (com: React.ComponentType<unknown>) =>
  withAITracking(rcPlugin, com)
