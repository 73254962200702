import { Box } from '@mui/material'
import { t } from 'i18next'
import React, { ReactNode } from 'react'

export type RequestSummaryWidgetProps = {
  pending: number | null
  total: number | null
  phrase: string | undefined
  icon: ReactNode | undefined
}

const RequestSummaryWidget = (props: RequestSummaryWidgetProps) => {
  const { icon, pending, total, phrase } = props

  return (
    <Box sx={{ flexBasis: '33.33%' }}>
      <Box sx={{ marginBottom: '1rem' }}>{icon}</Box>
      <Box
        sx={{
          marginBottom: '.25rem',
          fontSize: '.75rem',
          letterSpacing: '.0625rem',
          lineHeight: '1rem',
          fontWeight: '700',
          textTransform: 'uppercase',
        }}>
        {pending} <span>{t('EngagementDashboard.Summary.OutOf')}</span> {total}
      </Box>
      <Box sx={{ fontSize: '1rem', lineHeight: '1.5rem' }}>{phrase}</Box>
    </Box>
  )
}

export default RequestSummaryWidget
