/* eslint-disable */
import React from 'react'
import { Modal as MuiModal } from '@mui/material'
import Box from '@mui/material/Box'
import { Heading } from '../typography'
import { CloseIcon } from '../icons'

export type ModalProps = {
  isOpen: boolean
  children: any
  title?: string
  subTitle?: string
  style?: any
  closeHandler: () => void
}

const boxStyle = {
  position: 'absolute',
  top: '10%',
  left: 0,
  right: 0,
  margin: 'auto',
  width: '60%',
  bgcolor: '#fff',
  boxShadow: 24,
  maxWidth: 1170,
  pt: '88px',
  pb: '64px',
  px: '50px',
}

const Modal = (props: ModalProps) => {
  const {
    isOpen = false,
    closeHandler = () => {},
    children = null,
    title = '',
    subTitle = '',
    style,
  } = props
  return (
    <MuiModal
      open={isOpen}
      onClose={closeHandler}
      sx={{ ...style, overflowY: 'auto' }}>
      <Box sx={boxStyle}>
        <div className="relative font-prelo-book">
          <div
            className="absolute -top-6 right-0 text-2xl"
            role="button"
            tabIndex={0}
            onClick={closeHandler}
            onKeyDown={closeHandler}>
            <CloseIcon />
          </div>
          <div>
            {title && <Heading>{title}</Heading>}
            {subTitle && (
              <span className="font-prelo-light text-base">{subTitle}</span>
            )}
          </div>
          {children}
        </div>
      </Box>
    </MuiModal>
  )
}

export default Modal
