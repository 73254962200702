import React from 'react'

const MPG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 2C6.3125 2 5.75 2.5625 5.75 3.25V20.75C5.75 21.4375 6.3125 22 7 22H19.5C20.1875 22 20.75 21.4375 20.75 20.75V7L15.75 2H7Z"
      fill="#E2E5E7"
    />
    <path
      d="M17 7H20.75L15.75 2V5.75C15.75 6.4375 16.3125 7 17 7Z"
      fill="#B0B7BD"
    />
    <path d="M20.75 10.75L17 7H20.75V10.75Z" fill="#CAD1D8" />
    <path
      d="M17.15 18.875H5.75V19.5H17.15C17.48 19.5 17.75 19.2188 17.75 18.875V18.25C17.75 18.5938 17.48 18.875 17.15 18.875Z"
      fill="#CAD1D8"
    />
    <rect x="4" y="12" width="14" height="7" rx="0.8" fill="#1E9700" />
    <path
      d="M9.59038 13.9392V17H8.95977V15.073H8.95098L8.23467 17H7.77764L7.07891 15.073H7.07012V17H6.45708V13.9392H7.29424L8.04131 15.9519H8.0501L8.75322 13.9392H9.59038ZM10.9868 15.3674H11.5955C11.887 15.3674 12.032 15.2231 12.0305 14.9346C12.0305 14.646 11.8855 14.5017 11.5955 14.5017H10.9868V15.3674ZM10.9868 15.9299V17H10.3562V13.9392H11.6614C11.9792 13.9392 12.2283 14.0293 12.4084 14.2095C12.5886 14.3896 12.6787 14.6321 12.6787 14.9368C12.6787 15.2415 12.5886 15.4832 12.4084 15.6619C12.2283 15.8406 11.9792 15.9299 11.6614 15.9299H10.9868ZM14.6464 15.8025V14.7148H14.6376C14.4545 15.0503 14.1997 15.4128 13.873 15.8025H14.6464ZM15.2419 13.9392V15.8025H15.633V16.2991H15.2419V17H14.6464V16.2991H13.2666V15.8025C13.7968 15.2239 14.2092 14.6028 14.5036 13.9392H15.2419Z"
      fill="white"
    />
  </svg>
)

export default MPG
