import React from 'react'

const TeamworkIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M69.0605 12.4492H79.6606"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M50.4597 28.5092H35.6797V14.5192V12.4492H51.6897"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M79.6591 28.5098H67.3691"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M67.75 65.6699V77.9999"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.6895 47.0306V35.6406H65.6695H67.7495V48.3306"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M51.6895 77.9991V64.0391"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11.2398 64.3906H2.33984"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M27.1602 48.3301H44.4002V62.3201V64.3901H28.7202"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M2.33984 48.3301H14.0098"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M12.6602 11.1395V1.76953"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M28.7202 29.5298V44.2498H14.7402H12.6602V28.5098"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M28.7207 1.76953V12.8895"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M65.1401 35.1598C65.1401 35.1598 68.8101 24.4398 69.0601 22.9198C69.3101 21.3998 69.0601 13.3098 69.0601 12.6498C69.0601 11.9898 68.4301 10.6798 66.6601 10.6798C64.8901 10.6798 64.5801 11.8798 64.5801 11.8798V18.2198V10.3998C64.5801 9.44984 63.9001 8.16984 62.4001 8.16984C60.9001 8.16984 60.4301 9.58984 60.4301 9.58984V17.9998V8.16984C60.4301 7.30984 59.7701 6.33984 58.1901 6.33984C56.6101 6.33984 55.8401 7.57984 55.8401 8.27984V17.9198V10.6198C55.8401 9.75984 54.8801 8.93984 53.5501 8.93984C52.2201 8.93984 51.6901 10.4698 51.6901 11.1198V21.0998V18.0098C51.6901 18.0098 51.1401 16.2098 49.3301 16.2598C47.8801 16.2998 47.3201 18.2298 47.3201 18.2298C47.3201 18.2298 46.9801 22.4598 47.6101 24.7098C48.2501 26.9598 52.5901 30.1498 53.1101 31.5498C53.6301 32.9498 53.1101 35.1598 53.1101 35.1598"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.1696 15.0604C35.1696 15.0604 24.4496 11.3904 22.9296 11.1404C21.4096 10.8904 13.3196 11.1404 12.6596 11.1404C11.9996 11.1404 10.6896 11.7704 10.6896 13.5404C10.6896 15.3104 11.8896 15.6204 11.8896 15.6204H18.2296H10.4096C9.45961 15.6204 8.17961 16.3004 8.17961 17.8004C8.17961 19.3004 9.59961 19.7704 9.59961 19.7704H18.0096H8.17961C7.31961 19.7704 6.34961 20.4304 6.34961 22.0104C6.34961 23.5904 7.58961 24.3604 8.28961 24.3604H17.9296H10.6296C9.76961 24.3604 8.94961 25.3204 8.94961 26.6504C8.94961 27.9804 10.4796 28.5104 11.1296 28.5104H21.1096H18.0196C18.0196 28.5104 16.2196 29.0604 16.2696 30.8704C16.3096 32.3204 18.2396 32.8804 18.2396 32.8804C18.2396 32.8804 22.4696 33.2204 24.7196 32.5904C26.9696 31.9504 30.1596 27.6104 31.5596 27.0904C32.9596 26.5704 35.1696 27.0904 35.1696 27.0904"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M44.9609 61.7808C44.9609 61.7808 55.6809 65.4508 57.2009 65.7008C58.7209 65.9508 66.8109 65.7008 67.4709 65.7008C68.1309 65.7008 69.4409 65.0708 69.4409 63.3008C69.4409 61.5308 68.2409 61.2208 68.2409 61.2208H61.9009H69.7209C70.6709 61.2208 71.9509 60.5408 71.9509 59.0408C71.9509 57.5408 70.5309 57.0708 70.5309 57.0708H62.1209H71.9509C72.8109 57.0708 73.7809 56.4108 73.7809 54.8308C73.7809 53.2508 72.5409 52.4808 71.8409 52.4808H62.2009H69.5009C70.3609 52.4808 71.1809 51.5208 71.1809 50.1908C71.1809 48.8608 69.6509 48.3308 69.0009 48.3308H59.0209H62.1109C62.1109 48.3308 63.9109 47.7808 63.8609 45.9708C63.8209 44.5208 61.8909 43.9608 61.8909 43.9608C61.8909 43.9608 57.6609 43.6208 55.4109 44.2508C53.1609 44.8808 49.9709 49.2308 48.5709 49.7508C47.1709 50.2708 44.9609 49.7508 44.9609 49.7508"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.2694 44.6094C15.2694 44.6094 11.5994 55.3294 11.3494 56.8494C11.0994 58.3694 11.3494 66.4594 11.3494 67.1194C11.3494 67.7794 11.9794 69.0894 13.7494 69.0894C15.5194 69.0894 15.8294 67.8894 15.8294 67.8894V61.5494V69.3694C15.8294 70.3194 16.5094 71.5994 18.0094 71.5994C19.5094 71.5994 19.9794 70.1794 19.9794 70.1794V61.7694V71.5994C19.9794 72.4594 20.6394 73.4294 22.2194 73.4294C23.7994 73.4294 24.5694 72.1894 24.5694 71.4894V61.8494V69.1494C24.5694 70.0094 25.5294 70.8294 26.8594 70.8294C28.1894 70.8294 28.7194 69.2994 28.7194 68.6494V58.6694V61.7594C28.7194 61.7594 29.2694 63.5594 31.0794 63.5094C32.5294 63.4694 33.0894 61.5394 33.0894 61.5394C33.0894 61.5394 33.4294 57.3094 32.7994 55.0594C32.1594 52.8094 27.8194 49.6194 27.2994 48.2194C26.7794 46.8194 27.2994 44.6094 27.2994 44.6094"
      stroke="#009CDE"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default TeamworkIcon
