import React from 'react'
import MainContent from '../layouts/MainContent'
import MainMenu from '../layouts/MainMenu'
import MovedOrDeletedError from '../MoveOrDeletedError/MovedOrDeletedError'

const ProtectedRouteError = () => (
  <>
    <MainMenu />
    <MainContent>
      <MovedOrDeletedError />
    </MainContent>
  </>
)

export default ProtectedRouteError
