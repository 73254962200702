import React, { memo } from 'react'

const IcWarning = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.0899 32.8809L23.5293 7.99805C22.3672 6.08398 19.5645 6.08398 18.4707 7.99805L3.84181 32.8809C2.74806 34.7949 4.11525 37.1875 6.37111 37.1875H35.5606C37.8164 37.1875 39.1836 34.7949 38.0899 32.8809ZM19.3594 15.8594C19.3594 14.9707 20.043 14.2188 21 14.2188C21.8887 14.2188 22.6406 14.9707 22.6406 15.8594V24.6094C22.6406 25.5664 21.8887 26.25 21 26.25C20.1797 26.25 19.3594 25.5664 19.3594 24.6094V15.8594ZM21 32.8125C19.7695 32.8125 18.8125 31.8555 18.8125 30.6934C18.8125 29.5312 19.7695 28.5742 21 28.5742C22.1621 28.5742 23.1192 29.5312 23.1192 30.6934C23.1192 31.8555 22.1621 32.8125 21 32.8125Z"
      fill="#E40046"
    />
  </svg>
)

export default memo(IcWarning)
