/* eslint-disable no-console */
import packageInfo from '../../../package.json'
import api from '../../api'

export const consoleGoodStyle =
  'color: blue; background:#eee; -webkit-text-stroke: 1px black; font-size:24px; padding-left: 10px; padding-right: 10px;'
export const consoleErrorStyle =
  'color: red; background:#eee; -webkit-text-stroke: 1px black; font-size:24px; padding-left: 10px; padding-right: 10px;'

/**
 * @description - writes the service versions to the console.
 */
export default () => {
  const goodStyle = consoleGoodStyle
  const errorStyle = consoleErrorStyle
  console.log(`%cReact App: ${packageInfo.version}`, goodStyle)
  api.cms
    .version_GetVersion()
    .then((cms) => console.log(`%cCMS: ${cms?.data}`, goodStyle))
    .catch(() => console.log('%cCMS: Unavailable', errorStyle))
  api.communication
    .version_GetVersion()
    .then((comm) => console.log(`%cCommunication: ${comm?.data}`, goodStyle))
    .catch(() => console.log('%cCommunication: Unavailable', errorStyle))
  api.documentRequest
    .version_GetVersion()
    .then((docreq) => console.log(`%cDoc Request: ${docreq?.data}`, goodStyle))
    .catch(() => console.log('%cDoc Request: Unavailable', errorStyle))
  api.finance
    .version_GetVersion()
    .then((finance) => console.log(`%cFinance: ${finance?.data}`, goodStyle))
    .catch(() => console.log('%cFinance: Unavailable', errorStyle))
  api.user
    .version_GetVersion()
    .then((user) => console.log(`%cUser: ${user?.data}`, goodStyle))
    .catch(() => console.log('%cUser: Unavailable', errorStyle))
}
