import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import MainContent from '../../components/layouts/MainContent'
import MainMenu from '../../components/layouts/MainMenu'
import { useAITracking } from '../../rsmCoreComponents/hooks/useAITracking'
import {
  fetchAllEngagements,
  isEngagementDataAvailable,
} from '../../store/engagements/engagementSlice'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'
import { Styles } from '../../types'
import Unavailable from '../Unavailable'
import EngagementsListProvider from './state/provider'
import EngagementsSearchTable from './Table/EngagementsSearchTable'
import { AppDispatch } from '../../store'

const styles: Styles = {
  unavailableContainer: (theme) => ({
    backgroundColor: theme.palette.common.white,
  }),
}

const EngagementList = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()
  const userInfo = useSelector(getUserInfo)
  const isPageDataAvailable = useSelector(isEngagementDataAvailable)

  const loadEngagements = () => {
    dispatch(fetchAllEngagements({ isEmployee: userInfo.isEmployee }))
  }

  useAITracking(t('ECPAllEngagementsTitle'), window.location.href)

  useEffect(() => {
    document.title = t('ECPAllEngagementsTitle')
    if (userInfo.isEmployee !== undefined) {
      loadEngagements()
    }
  }, [userInfo, location.key])

  return (
    <EngagementsListProvider
      initialState={{ isEmployee: userInfo?.isEmployee }}>
      <MainMenu currentItem="engagements" />
      <MainContent>
        {isPageDataAvailable ? (
          <EngagementsSearchTable />
        ) : (
          <Box sx={styles.unavailableContainer}>
            <Unavailable returnUrl="/engagements" />
          </Box>
        )}
      </MainContent>
    </EngagementsListProvider>
  )
}

export default EngagementList
