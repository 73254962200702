import React, { useMemo } from 'react'
import { Box, Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { Styles } from '../../../types'
import { ArticleMetaData } from '../../../utils/helpers/Article.service'
import {
  INSIGHT_CONTENT_IMAGE_PREFIX,
  REACT_APP_POP_IMAGE,
} from '../../../envVariables'

import OverFlowToolTip from '../../OverFlowToolTip/OverFlowToolTip'
import { brandColorToHex } from '../../../utils/helpers/color.service'
import { AnalyticEvent } from '../../../analytics/adobe/types'
import CaretLinkWhiteVariation from '../../Navigation/CaretLinkWhiteVariation'
import { tokens } from '../../../styles/materialTheme'
import {
  getHasPopBgColor,
  getHasPopImg,
} from '../../../store/spotlightArticle/spotlightArticleSlice'

type StyledMainDesktopContainerProps = {
  bgImage: string
  bannerPoPBackgroundColor: string
  imageMaskUrl: string
  hasPopColor: boolean
  hasBgImg: boolean
}

const StyledMainDesktopContainer = styled(
  'div',
)<StyledMainDesktopContainerProps>(
  ({
    bgImage,
    bannerPoPBackgroundColor,
    imageMaskUrl,
    hasPopColor,
    hasBgImg,
  }) => ({
    backgroundColor: bannerPoPBackgroundColor,
    backgroundImage: `url('${bgImage}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    maskImage: hasPopColor || hasBgImg ? `url(${imageMaskUrl})` : '',
    maskSize: 'cover',
    maskRepeat: 'no-repeat',
    height: '37.5rem',
  }),
)

const StyledDesktopGradientContainer = styled('div')<StyledGradientProps>(
  ({ gradientColor, fallbackBgColor }) => ({
    backgroundColor: fallbackBgColor,
    backgroundImage: `linear-gradient(90deg,${brandColorToHex(
      gradientColor,
      0.65,
    )} 50%,transparent 80%)`,
    height: 'inherit',
  }),
)

const StyledDesktopTextContainer = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Prelo-Book, sans-serif',
  paddingLeft: '4rem',
  width: '65%',
  maxWidth: '60rem',
}))

const StyledDesktopEyebrowText = styled('div')(() => ({
  paddingTop: '5.5rem',
  lineHeight: '1.5rem',
  fontFamily: 'Prelo-Bold, sans-serif',
  fontSize: '0.75rem',
  letterSpacing: '0.0625rem',
  textTransform: 'uppercase',
}))

const StyleDesktopTitle = styled('h2')(() => ({
  paddingTop: '1rem',
  fontFamily: 'Prelo-Light, sans-serif',
  fontSize: '3rem',
  lineHeight: '3.5rem',
}))

const StyledSubtitle = styled('div')(() => ({
  paddingTop: '1rem',
}))

const StyledPublicationDate = styled('div')(() => ({
  paddingTop: '1rem',
  lineHeight: '1.5rem',
  fontFamily: 'Prelo-Bold, sans-serif',
  fontSize: '0.75rem',
  letterSpacing: '0.0625rem',
  textTransform: 'uppercase',
}))

// End Destkop, start Mobile/tablet
type StyledMainContainerProps = {
  bgImage: string
  bgColor: string
}

const StyledMainContainer = styled('div')<StyledMainContainerProps>(
  ({ bgImage, bgColor }) => ({
    backgroundImage: `url('${bgImage}')`,
    backgroundColor: bgColor,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '28rem',
  }),
)

type StyledGradientProps = {
  gradientColor: string
  fallbackBgColor: string
}

const StyledGradientContainer = styled('div')<StyledGradientProps>(
  ({ gradientColor, fallbackBgColor }) => ({
    backgroundColor: fallbackBgColor,
    backgroundImage: `linear-gradient(0deg,${brandColorToHex(
      gradientColor,
      0.7,
    )} 45%,rgba(0,0,0,.2) 75%,transparent)`,
    height: 'inherit',
  }),
)

const StyledTextContainer = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: 'Prelo-Book, sans-serif',
  paddingTop: '14rem',
  paddingLeft: '4rem',
  paddingBottom: '1rem',
}))

const StyledTitle = styled('h2')(() => ({
  fontSize: '2.4rem',
  lineHeight: 'normal',
}))

const StyledLabel = styled('div')(() => ({
  fontFamily: 'Prelo-Bold, sans-serif',
  fontSize: '1rem',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  paddingTop: '0.5rem',
}))
// END Mobile/tablet styling

const styles: Styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1rem',
    marginLeft: '1rem',
    paddingTop: '2.5rem',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('tablet')]: {
      marginLeft: '4rem',
      width: '24.5625rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '6.875rem',
    },
    [theme.breakpoints.up('desktop')]: {},
  }),
  metaContainer: {
    display: 'flex',
  },
  featuredTitle: (theme) => ({
    fontFamily: 'Prelo-Bold, sans-serif',
    fontSize: '0.75rem',
    letterSpacing: '0.0625rem',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  }),
  readNowButtonContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '1.75rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('tablet')]: {
      justifyContent: 'flex-start',
      marginTop: '2.625rem',
    },
  }),
  readNowButton: (theme) => ({
    padding: '0.75rem 1.5rem',
    height: '3.125rem',
    [theme.breakpoints.down('tablet')]: {
      width: '18rem',
      height: '3.125rem',
    },
    [theme.breakpoints.up('tablet')]: {
      width: '8.75rem',
    },
  }),
}

interface SpotlightArticleProps {
  article?: ArticleMetaData
}

const BrandEvolutionSpotlightArticle = ({
  article = undefined,
}: SpotlightArticleProps) => {
  const navigate = useNavigate()
  const { isDesktop } = useDeviceType()
  const { t } = useTranslation()
  const hasPopBgImg = useSelector(getHasPopImg)
  const hasPopBgColor = useSelector(getHasPopBgColor)

  const defaultLink = '/'
  const handleReadMore = (a: ArticleMetaData | undefined) => {
    const path = a ? `/insights/${a.ecpId ?? ''}` : defaultLink
    navigate(path)
  }

  const content = useMemo(() => {
    if (!article) {
      return <div />
    }

    const fallbackPopColor = tokens.colors.rsmGreen.primary
    const fallbackMainDesktopBgColor = article.bannerDeskTopImage
      ? ''
      : tokens.colors.rsmGray.copy
    const fallbackMobileTabletBgColor = article.featuredCardUrl
      ? ''
      : tokens.colors.rsmGray.copy

    const analyticEventType: AnalyticEvent = 'Navigation_Click'

    if (isDesktop) {
      const imageMaskUrl = `${INSIGHT_CONTENT_IMAGE_PREFIX}${REACT_APP_POP_IMAGE}?env=${window.origin}`

      return (
        <StyledMainDesktopContainer
          bgImage={article.bannerDeskTopImage || ''}
          bannerPoPBackgroundColor={
            article.bannerPoPBackgroundColor || fallbackPopColor
          }
          imageMaskUrl={imageMaskUrl}
          hasBgImg={hasPopBgImg}
          hasPopColor={hasPopBgColor}>
          <StyledDesktopGradientContainer
            gradientColor={article.bannerGradientOverlay || ''}
            fallbackBgColor={fallbackMainDesktopBgColor}>
            <StyledDesktopTextContainer>
              <StyledDesktopEyebrowText data-testid="Lbl_SpotlightArticle_Label">
                {article.label}
              </StyledDesktopEyebrowText>
              <StyleDesktopTitle>
                <OverFlowToolTip
                  numberOfLines={3}
                  testId="Lbl_SpotlightArticle_Title">
                  {article.title}
                </OverFlowToolTip>
              </StyleDesktopTitle>
              <StyledSubtitle>
                <OverFlowToolTip
                  numberOfLines={2}
                  testId="Lbl_SpotlightArticle_BannerSubText">
                  {article.bannerSubtext}
                </OverFlowToolTip>
              </StyledSubtitle>
              <StyledPublicationDate data-testid="Lbl_SpotlightArticle_Date">
                {t('InsightArticle.ArticleDate', {
                  date: article.publishedOn,
                })}
              </StyledPublicationDate>{' '}
              <Box sx={styles.readNowButtonContainer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={styles.readNowButton}
                  aria-label={`${t('HomePage.ArticleReadNow')} ${
                    article.title
                  }`}
                  onClick={() => handleReadMore(article)}
                  data-testid="Btn_SpotlightArticle_ReadMore">
                  {t('HomePage.ArticleReadNow')}
                </Button>
              </Box>
            </StyledDesktopTextContainer>
          </StyledDesktopGradientContainer>
        </StyledMainDesktopContainer>
      )
    }

    return (
      <StyledMainContainer
        bgImage={article?.featuredCardUrl || ''}
        bgColor={article?.featuredCardBgColor || tokens.colors.rsmGray.copy}>
        <StyledGradientContainer
          gradientColor={article.bannerGradientOverlay || ''}
          fallbackBgColor={fallbackMobileTabletBgColor}>
          <StyledTextContainer>
            <StyledTitle>
              <OverFlowToolTip
                testId="Lbl_SpotlightArticle_Title"
                numberOfLines={2}>
                {article.title}
              </OverFlowToolTip>
            </StyledTitle>
            <CaretLinkWhiteVariation
              onClick={() => handleReadMore(article)}
              href="#ref"
              aria-label={t('HomePage.ArticleReadNow')}
              data-testid="SpotlightArticle_ReadMore"
              data-analytic-event={analyticEventType}>
              {t('HomePage.ArticleReadNow')}
            </CaretLinkWhiteVariation>
            <StyledLabel data-testid="Lbl_SpotlightArticle_Label">
              {article.label}
            </StyledLabel>
          </StyledTextContainer>
        </StyledGradientContainer>
      </StyledMainContainer>
    )
  }, [article, isDesktop])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{content}</>
  )
}

export default BrandEvolutionSpotlightArticle
