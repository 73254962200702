import React from 'react'
import { useSelector } from 'react-redux'
import { FEATURE_MANAGEMENT } from '../../envVariables'
import {
  FeatureFlag,
  TargetingFeatureFlag,
} from '../../utils/constants/constants'
import {
  getUserInfo,
  getTargetedFeatureFlags,
} from '../../store/userInfo/userInfoSlice'
import {
  isFeatureFlagEnabled,
  isTargetedFeatureEnabled,
} from '../../rsmCoreComponents/utils/featureFlagUtils'
import { CloseIcon, SuccessIcon } from '../../components/icons'
import tokens from '../../styles/tokens.json'

const FeaturesPage = () => {
  const userInfo = useSelector(getUserInfo)
  const targetedFeatureFlags = useSelector(getTargetedFeatureFlags)
  if (Object.keys(userInfo).length === 0) {
    return null
  }

  const allFeatures =
    typeof FEATURE_MANAGEMENT === 'string'
      ? JSON.parse(FEATURE_MANAGEMENT)
      : FEATURE_MANAGEMENT
  function getFeatureFlagState(key: string) {
    return {
      key,
      enabled:
        typeof allFeatures[key] === 'boolean'
          ? isFeatureFlagEnabled(key as any as FeatureFlag)
          : isTargetedFeatureEnabled(
              key as any as TargetingFeatureFlag,
              targetedFeatureFlags,
            ),
    }
  }
  const featureFlags = Object.keys(allFeatures).map(getFeatureFlagState)

  return (
    <div
      style={{
        marginLeft: '50px',
        marginRight: '50px',
        paddingBottom: '50px',
      }}>
      <table style={{ width: '100%', textAlign: 'left' }}>
        <thead>
          <tr>
            <th> </th>
            <th>Feature Flag</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody>
          {featureFlags.map((flag, i) => (
            <tr
              key={flag.key}
              data-testid={`targeted-feature-flag-${i}`}
              style={{
                backgroundColor:
                  i % 2 === 0 ? tokens.colors.rsmGray.disabled : '',
              }}>
              <td>{i + 1}</td>
              <td>{flag.key}</td>
              <td>
                {flag.enabled ? (
                  <SuccessIcon color={tokens.colors.rsmGreen.primary} />
                ) : (
                  <CloseIcon size={20} color={tokens.colors.rsmRed.secondary} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FeaturesPage
