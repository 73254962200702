/* eslint-disable react/no-unstable-nested-components */
import {
  Button,
  TableCell,
  Typography,
  TableRow,
  TableFooter,
} from '@mui/material'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { EngagementViewTable } from '../Interfaces/EngagementViewTypes'
import RSMTableBody, {
  RSMTableHeadData,
} from '../../../rsmCoreComponents/components/table/RSMTableBody'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import { RootState } from '../../../store'
import {
  getSortedEngagements,
  getReadyState,
} from '../../../store/engagements/engagementSlice'
import { getUserInfo } from '../../../store/userInfo/userInfoSlice'
import { Styles } from '../../../types'
import {
  filterEngagements,
  structureBodyData,
  structureHeaderData,
} from './EngagementTable.service'
import { EngagementsContext } from '../state/context'
import { pushToDownloadQueue, updateEmployeeStatus } from '../state/actions'
import OverFlowToolTip from '../../../components/OverFlowToolTip/OverFlowToolTip'
import { NoSearchFound } from '../../../rsmCoreComponents/components/NoSearchFound/NoSearchFound'
import Spinner from '../../../components/forms/Spinner/Spinner'

const styles: Styles = {
  tableCell: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    borderBottomWidth: 0,
  },
  viewEngagementButton: {
    whiteSpace: 'nowrap',
  },
  viewEngagementLinkText: {
    textDecoration: 'underline',
  },
}

interface TableProps {
  filter?: string
  maxRecords?: number
}

const Table = ({ filter = '', maxRecords = undefined }: TableProps) => {
  const navigate = useNavigate()
  const [headerData, setHeaderData] = useState<RSMTableHeadData[]>()
  const [bodyData, setBodyData] = useState<EngagementViewTable[]>()
  const [showEngagementSortHoverName, setShowEngagementSortHoverName] =
    useState<boolean>(false)
  const [showEngagementSortHoverStart, setShowEngagementSortHoverStart] =
    useState<boolean>(false)
  const [showEngagementSortHoverEnd, setShowEngagementSortHoverEnd] =
    useState<boolean>(false)
  const [showEngagementSortHoverClient, setShowEngagementSortHoverClient] =
    useState<boolean>(false)
  const [
    showEngagementSortHoverLineOfBusiness,
    setShowEngagementSortHoverLineOfBusiness,
  ] = useState<boolean>(false)
  const [showEngagementSortHoverType, setShowEngagementSortHoverType] =
    useState<boolean>(false)

  const { isMobile, isTablet } = useDeviceType()

  const [pageInfo, setPageInfo] = useState({
    displayCount: isMobile || isTablet ? 10 : 25,
    pageSize: isMobile || isTablet ? 10 : 25,
    page: 1,
  })

  const userInfo = useSelector(getUserInfo)

  const engagements = useSelector((state: RootState) => {
    const sorted = getSortedEngagements(state)
    return maxRecords ? sorted.slice(0, maxRecords) : sorted
  })

  const isReady = useSelector((state: RootState) => getReadyState(state))

  useEffect(() => {
    setBodyData(
      structureBodyData(engagements, isMobile, isTablet, userInfo.isEmployee),
    )
  }, [engagements, isMobile, isTablet, userInfo])

  const { dispatch } = useContext(EngagementsContext)

  useEffect(() => {
    dispatch(updateEmployeeStatus(userInfo.isEmployee))
  }, [userInfo])

  useEffect(() => {
    setHeaderData(
      structureHeaderData(
        isMobile,
        isTablet,
        userInfo.isEmployee,
        showEngagementSortHoverName,
        showEngagementSortHoverStart,
        showEngagementSortHoverEnd,
        showEngagementSortHoverClient,
        showEngagementSortHoverLineOfBusiness,
        showEngagementSortHoverType,
        setShowEngagementSortHoverName,
        setShowEngagementSortHoverStart,
        setShowEngagementSortHoverEnd,
        setShowEngagementSortHoverClient,
        setShowEngagementSortHoverLineOfBusiness,
        setShowEngagementSortHoverType,
      ),
    )
  }, [
    isMobile,
    isTablet,
    showEngagementSortHoverName,
    showEngagementSortHoverStart,
    showEngagementSortHoverClient,
    showEngagementSortHoverEnd,
    showEngagementSortHoverLineOfBusiness,
    showEngagementSortHoverType,
    userInfo,
  ])

  useEffect(() => {
    if (bodyData) {
      dispatch(
        pushToDownloadQueue({
          engagements: bodyData,
          fileName: 'Engagements.csv',
        }),
      )
    }
  }, [bodyData])

  if (!bodyData) {
    return null
  }

  const filterItems = filterEngagements(bodyData, filter)
  const resultsPage = filterItems.slice(
    0,
    pageInfo.displayCount || pageInfo.pageSize,
  )

  if (isReady === false) {
    return <Spinner open />
  }

  if (isReady && resultsPage?.length === 0) {
    return (
      <NoSearchFound
        title={t('EngagementList.NoSearchResultsTitle')}
        subTitle={t('EngagementList.NoSearchResultsSubTitle')}
      />
    )
  }

  const renderLoadMoreBtn = () => {
    const displayLoadMore =
      pageInfo.pageSize &&
      headerData?.length &&
      filterItems?.length > (pageInfo.displayCount || pageInfo.pageSize)

    const loadMoreClick = () => {
      const currentPage = pageInfo.page + 1
      setPageInfo({
        ...pageInfo,
        page: currentPage,
        displayCount: pageInfo.pageSize * currentPage,
      })
    }

    if (displayLoadMore) {
      return (
        <TableFooter>
          <TableRow>
            <TableCell
              colSpan={headerData?.length || 0}
              sx={{ border: 0, textAlign: 'center' }}>
              <Button
                sx={{ width: '8.175rem', height: '3.125rem', mt: 2 }}
                variant="contained"
                onClick={loadMoreClick}
                aria-label={t('Projects.LoadMore')}
                data-testid="Btn_Projects_LoadMore">
                {t('Projects.LoadMore')}
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      )
    }
    return null
  }

  return (
    <>
      <RSMTableBody
        isAscending={false}
        tableKey="name"
        itemList={resultsPage}
        header={headerData}
        customRenderers={{
          id: (it: EngagementViewTable) =>
            !isMobile && (
              <TableCell sx={styles.tableCell} align="right">
                <Button
                  sx={styles.viewEngagementButton}
                  aria-label={t('Engagement.ViewEngagementAriaLabel', {
                    engagement: it.name,
                  })}
                  variant="outlined"
                  onClick={() => navigate(`/engagements/${it.id}`)}
                  data-testid="Btn_Engagement_ViewEngagement">
                  {t('Engagement.ViewEngagement')}
                </Button>
              </TableCell>
            ),
          name: (it: EngagementViewTable) => (
            <TableCell sx={styles.tableCell}>
              {isMobile ? (
                <NavLink
                  to={`/engagements/${it.id}`}
                  data-testid="Lnk_Engagement_ViewEngagement">
                  <Typography
                    sx={styles.viewEngagementLinkText}
                    color="secondary">
                    <OverFlowToolTip>{it.name}</OverFlowToolTip>
                  </Typography>
                </NavLink>
              ) : (
                <OverFlowToolTip>{it.name}</OverFlowToolTip>
              )}
            </TableCell>
          ),
          scheduledStartDate: () => null,
          scheduledEndDate: () => null,
        }}
      />
      {renderLoadMoreBtn()}
    </>
  )
}

export default Table
