import React from 'react'

const DiceIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7698 8.09961L2.58984 21.9396L23.5198 28.8596L39.9498 13.2696L19.7698 8.09961Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2.58984 21.9395L8.50984 42.7895L27.8598 50.4595L43.2898 34.9495L39.9498 13.2695"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M23.5195 28.8594L27.8595 50.4594"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M11.3236 31.7467C11.932 31.2175 11.717 29.9744 10.8434 28.9702C9.96984 27.966 8.7685 27.5809 8.16014 28.1101C7.55177 28.6393 7.76676 29.8824 8.64033 30.8867C9.5139 31.8909 10.7152 32.2759 11.3236 31.7467Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M22.3763 44.069C22.9847 43.5398 22.7697 42.2967 21.8961 41.2925C21.0226 40.2882 19.8212 39.9032 19.2129 40.4324C18.6045 40.9616 18.8195 42.2047 19.6931 43.2089C20.5666 44.2131 21.768 44.5982 22.3763 44.069Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M33.0079 40.3631C33.3991 39.0908 33.0999 37.87 32.3398 37.6363C31.5796 37.4026 30.6463 38.2445 30.2551 39.5167C29.864 40.789 30.1631 42.0098 30.9233 42.2435C31.6835 42.4772 32.6168 41.6353 33.0079 40.3631Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M35.6798 32.2928C36.071 31.0205 35.7718 29.7997 35.0116 29.566C34.2515 29.3323 33.3181 30.1742 32.927 31.4464C32.5358 32.7187 32.835 33.9395 33.5952 34.1732C34.3553 34.4069 35.2887 33.565 35.6798 32.2928Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M38.2951 24.4568C38.6862 23.1846 38.387 21.9638 37.6269 21.7301C36.8667 21.4964 35.9334 22.3382 35.5422 23.6105C35.1511 24.8827 35.4502 26.1035 36.2104 26.3372C36.9706 26.571 37.9039 25.7291 38.2951 24.4568Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M23.9542 23.5774C25.4439 23.3449 26.5508 22.5106 26.4265 21.7139C26.3021 20.9172 24.9937 20.4599 23.504 20.6923C22.0143 20.9248 20.9074 21.7591 21.0318 22.5558C21.1561 23.3525 22.4645 23.8099 23.9542 23.5774Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M18.098 21.606C19.5113 21.3223 20.5407 20.5129 20.3972 19.7981C20.2538 19.0834 18.9918 18.7339 17.5785 19.0176C16.1652 19.3013 15.1358 20.1107 15.2793 20.8254C15.4228 21.5402 16.6848 21.8896 18.098 21.606Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M12.6252 19.9163C13.9894 19.7034 15.0042 18.9469 14.8918 18.2266C14.7794 17.5063 13.5823 17.095 12.2181 17.3079C10.8539 17.5208 9.83917 18.2773 9.95158 18.9976C10.064 19.7179 11.261 20.1292 12.6252 19.9163Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M30.8464 17.5156C32.2433 17.2976 33.2812 16.5149 33.1645 15.7673C33.0479 15.0197 31.8209 14.5904 30.4239 14.8084C29.027 15.0264 27.9891 15.8092 28.1058 16.5567C28.2224 17.3043 29.4495 17.7336 30.8464 17.5156Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M25.2007 15.7794C26.5273 15.5131 27.4935 14.7529 27.3588 14.0815C27.224 13.4101 26.0393 13.0816 24.7126 13.3479C23.386 13.6142 22.4198 14.3744 22.5546 15.0458C22.6894 15.7173 23.8741 16.0457 25.2007 15.7794Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M19.4973 14.381C20.7796 14.1808 21.7336 13.4701 21.628 12.7934C21.5224 12.1168 20.3972 11.7305 19.1149 11.9306C17.8325 12.1307 16.8786 12.8415 16.9842 13.5181C17.0898 14.1948 18.2149 14.5811 19.4973 14.381Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M43.6895 33.4805L56.1095 51.4605L78.9995 50.0205L65.6995 33.4805H43.6895Z"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M43.6904 33.4805L35.9004 53.8205L46.4204 71.7105L68.0604 68.7005L79.0004 50.0205"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M56.1099 51.4609L46.4199 71.7109"
      stroke="#CCCCCC"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M56.0556 38.7837C56.3106 38.1422 55.444 37.1954 54.1199 36.669C52.7958 36.1426 51.5156 36.236 51.2606 36.8775C51.0056 37.519 51.8722 38.4658 53.1963 38.9922C54.5204 39.5186 55.8006 39.4252 56.0556 38.7837Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M61.6311 46.7326C61.9839 45.9953 61.1956 44.8836 59.8704 44.2495C58.5452 43.6154 57.1849 43.6991 56.8321 44.4364C56.4794 45.1738 57.2676 46.2855 58.5928 46.9196C59.918 47.5537 61.2783 47.4699 61.6311 46.7326Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M63.6087 42.6039C63.8995 41.9961 63.061 40.9893 61.7358 40.3553C60.4106 39.7212 59.1006 39.6999 58.8098 40.3077C58.519 40.9155 59.3575 41.9222 60.6827 42.5563C62.0079 43.1904 63.3179 43.2117 63.6087 42.6039Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M71.2312 46.2835C71.553 45.6109 70.7396 44.5517 69.4144 43.9176C68.0892 43.2835 66.7541 43.3147 66.4323 43.9873C66.1105 44.6599 66.9239 45.7191 68.2491 46.3532C69.5743 46.9872 70.9094 46.956 71.2312 46.2835Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M65.3893 38.858C65.6802 38.2502 64.8982 37.2706 63.6427 36.6698C62.3873 36.0691 61.1338 36.0749 60.843 36.6827C60.5522 37.2905 61.3341 38.2702 62.5896 38.8709C63.845 39.4716 65.0985 39.4658 65.3893 38.858Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M49.5871 54.9227C50.4037 54.8866 51.0125 53.6542 50.947 52.17C50.8814 50.6858 50.1662 49.5119 49.3497 49.548C48.5331 49.584 47.9243 50.8165 47.9898 52.3007C48.0554 53.7849 48.7706 54.9588 49.5871 54.9227Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M44.4235 46.8296C45.1573 46.7972 45.7008 45.6079 45.6374 44.1734C45.574 42.7389 44.9278 41.6022 44.1939 41.6346C43.4601 41.6671 42.9166 42.8563 42.98 44.2908C43.0434 45.7254 43.6897 46.862 44.4235 46.8296Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M41.0462 55.3904C41.7359 55.3599 42.2468 54.2438 42.1873 52.8976C42.1278 51.5513 41.5205 50.4847 40.8308 50.5151C40.1411 50.5456 39.6303 51.6617 39.6897 53.0079C39.7492 54.3542 40.3565 55.4209 41.0462 55.3904Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M45.9086 63.5101C46.6393 63.4347 47.1154 62.2469 46.972 60.857C46.8286 59.4671 46.12 58.4014 45.3894 58.4768C44.6587 58.5522 44.1826 59.74 44.326 61.1299C44.4694 62.5198 45.178 63.5855 45.9086 63.5101Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M63.3756 61.9748C64.5369 61.2178 65.1531 60.1053 64.752 59.4899C64.3508 58.8746 63.0843 58.9894 61.923 59.7465C60.7617 60.5035 60.1455 61.616 60.5466 62.2314C60.9478 62.8467 62.2143 62.7318 63.3756 61.9748Z"
      stroke="#009CDE"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
)

export default DiceIcon
