/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Box, styled } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import MainContent from '../../components/layouts/MainContent'
import { Heading } from '../../components/typography'
import { Styles } from '../../types'
import MainMenu from '../../components/layouts/MainMenu'
import ReportForm from './ReportForm'
import Faq from '../../components/domain/Support/Faq'
import CallUsCard from '../../rsmCoreComponents/components/CallUsCard/CallUsCard'
import { getUserInfo } from '../../store/userInfo/userInfoSlice'

const styles: Styles = {
  container: (theme) => ({
    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('desktop')]: {
      paddingLeft: '6.5rem',
      paddingRight: '6.5rem',
    },
  }),
}

const StyledGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'fit-content',
  marginTop: '2rem',
  gap: '1.25rem',
  [theme.breakpoints.only('tablet')]: {
    gridTemplateColumns: 'repeat(1,1fr)',
  },
  [theme.breakpoints.only('desktop')]: {
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '1.75rem',
  },
}))

const StyledFormGrid = styled('div')({
  gridColumn: 'span 2 / span 2',
})

const StyledCardsGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'fit-content',
  gap: '1.25rem',
  justifyContent: 'start',
  [theme.breakpoints.only('tablet')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.only('desktop')]: {
    gridTemplateColumns: 'repeat(1, 3fr)',
    gap: '1.75rem',
  },
  [theme.breakpoints.only('mobile')]: {
    gridColumn: 'span 2 / span 2',
  },
}))

const ReportAnIssue = () => {
  const userInfo = useSelector(getUserInfo)
  const internalPhone = '877-462-7573'
  const externalPhone = '833-518-3394'
  const supportPhone = userInfo.isEmployee ? internalPhone : externalPhone
  const supportDescriptionKey = userInfo.isEmployee
    ? 'CallUs.InternalDescription'
    : 'CallUs.ExternalDescription'
  return (
    <>
      <MainMenu />
      <MainContent>
        <Box sx={styles.container}>
          <Heading variant="h1" data-testid="Hed_Support_SupportFormPage">
            Report an Issue
          </Heading>
          <StyledGrid>
            <StyledFormGrid>
              <ReportForm />
            </StyledFormGrid>

            <StyledCardsGrid>
              <Faq />
              <CallUsCard
                isLoading={userInfo.isEmployee === undefined}
                supportPhone={supportPhone}
                supportDescriptionKey={supportDescriptionKey}
              />
            </StyledCardsGrid>
          </StyledGrid>
        </Box>
      </MainContent>
    </>
  )
}

export default ReportAnIssue
