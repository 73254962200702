import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DocumentModel } from '@rsmus/ecp-documentrequestservice'

import distinctGridValues from '../../utils/helpers/distinctGridValues'
import type { RootState } from '..'
import api from '../../api'

interface DocumentState {
  // original document
  documentData: DocumentModel[] | undefined

  // distinct group for the filter
  distinctGroupData:
    | Map<keyof DocumentModel, Set<DocumentModel[keyof DocumentModel]>>
    | undefined

  // state of the data that is selected by the user
  filterDataState: Map<
    keyof DocumentModel,
    Set<DocumentModel[keyof DocumentModel]>
  >

  // handle table filter state
  tableFilterState: object | undefined | null | any

  // loading for the document data
  status: 'loading' | 'idle'
  // to check for the error in getting or document
  error: object | undefined | null | any

  // Documents specific to the current request.
  requestDocumentData: DocumentModel[] | undefined
}

const initialState: DocumentState = {
  // Should the server call fail, use this as a default value instead
  documentData: [],
  requestDocumentData: [],
  status: 'idle',
  error: undefined,
  distinctGroupData: undefined,
  filterDataState: new Map(),
  tableFilterState: {},
}

// type FetchDocumentError = {
//   message: string
// }

interface DocumentArgsProps {
  engagementId: string | undefined
}

export const GetDocumentDataThunk = createAsyncThunk(
  'GetDocuments',
  async (DocumentArgs: DocumentArgsProps, thunkApi) => {
    const response = (
      await api.documentRequest.document_ListByPath(
        DocumentArgs.engagementId,
        null,
        0,
        1000,
      )
    )?.data
    if (response?.result === null) {
      // Return the error message:
      return thunkApi.rejectWithValue({ message: 'Failed to fetch Documents.' })
    }
    return response?.result
  },
)

interface RequestDocumentArgsProps {
  engagementId: string | undefined
  documentRequestId: number | undefined
}

export const GetRequestDocumentDataThunk = createAsyncThunk(
  'GetRequestDocuments',
  async (RequestDocumentArgs: RequestDocumentArgsProps, thunkApi) => {
    const response = (
      await api.documentRequest.document_ListByPath(
        RequestDocumentArgs.engagementId,
        RequestDocumentArgs.documentRequestId,
        0,
        1000,
      )
    )?.data
    if (response?.result === null) {
      // Return the error message:
      return thunkApi.rejectWithValue({
        message: 'Failed to fetch Request Documents.',
      })
    }
    return response?.result
  },
)

export const documentSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentData: (state, { payload }) => {
      state.documentData = payload
    },
    setRequestDocumentData: (state, { payload }) => {
      state.requestDocumentData = payload
    },
    setTableFilter: (state, { payload }) => {
      state.tableFilterState = payload
    },
    setFilteredStateData: (state, { payload }) => {
      state.filterDataState = payload
    },
    setLoading: (state, { payload }) => {
      state.documentData = payload
    },
    setError: (state, { payload }) => {
      state.documentData = payload
    },
  },
  // In `extraReducers` we declare
  // all the actions:
  extraReducers: (builder) => {
    // When we send a request,
    // `fetchTodos.pending` is being fired:
    builder.addCase(GetDocumentDataThunk.pending, (state) => {
      // At that moment,
      // we change status to `loading`
      // and clear all the previous errors:
      state.status = 'loading'
      state.error = null
    })
    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(GetDocumentDataThunk.fulfilled, (state, { payload }) => {
      // We add all the new todos into the state
      // and change `status` back to `idle`:
      const useMemoMock: any = (useMemo: any) => useMemo()

      state.documentData = payload

      const payloadKeys: (keyof DocumentModel)[] = [
        'createdBy',
        'status',
        'group',
        'subgroup',
        'contentType',
      ]

      const distinctValues = distinctGridValues(
        payload,
        payloadKeys,
        useMemoMock,
      )

      state.distinctGroupData = distinctValues

      state.status = 'idle'
    })
    // When a server responses with an error:
    builder.addCase(GetDocumentDataThunk.rejected, (state, { payload }) => {
      // We show the error message
      // and change `status` back to `idle` again.
      if (payload) state.error = payload
      state.status = 'idle'
    })

    builder.addCase(GetRequestDocumentDataThunk.pending, (state) => {
      state.status = 'loading'
      state.error = null
    })
    builder.addCase(
      GetRequestDocumentDataThunk.fulfilled,
      (state, { payload }) => {
        state.requestDocumentData = payload
        state.status = 'idle'
      },
    )
    builder.addCase(
      GetRequestDocumentDataThunk.rejected,
      (state, { payload }) => {
        if (payload) state.error = payload
        state.status = 'idle'
      },
    )
  },
})

export const {
  setDocumentData,
  setRequestDocumentData,
  setFilteredStateData,
  setTableFilter,
} = documentSlice.actions

export const getDocumentData = (state: RootState) =>
  state.documents.documentData
export const getRequestDocumentData = (state: RootState) =>
  state.documents.requestDocumentData
export const getDocumentStatus = (state: RootState) => state.documents.status
export const getError = (state: RootState) => state.documents.error
export const getFilterDataState = (state: RootState) =>
  state.documents.filterDataState
export const getDistinctGroupData = (state: RootState) =>
  state.documents.distinctGroupData

export const getTableFilterState = (state: RootState) =>
  state.documents.tableFilterState

export default documentSlice.reducer
