import * as React from 'react'
import { SVGProps } from 'react'

const CreditCupIcon = ({ width, height }: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_2"
    data-testid="credit-cup-icon"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 285.38 185.78"
    width={width}
    height={height}>
    <title>CUP</title>
    <defs>
      <style>{`
      .cls-1 {
        fill: navy;
        stroke: navy;
        stroke-miterlimit: 10;
        stroke-width: 2.14px;
      }

      .cls-2 {
        fill: #fff;
        stroke-width: 0px;
      }
      `}</style>
    </defs>
    <g id="Visa">
      <g>
        <rect
          className="cls-1"
          x="1.07"
          y="1.07"
          width="283.25"
          height="183.64"
          rx="21.19"
          ry="21.19"
        />
        <g>
          <path
            className="cls-2"
            d="m142.62,62.26l1.22-1.33-.03-.22-22.83-.06c0,.07-.04.11-.12.12.25,1.53.05,3.17-.6,4.94l-18.96,54.6c-.71,2.03-1.6,3.49-2.68,4.36l23.08-.02h0c-.61-.68-.61-1.92,0-3.7l20.91-58.69Z"
          />
          <path
            className="cls-2"
            d="m98.11,60.96l.39,2.09-26.13,40.05c-.1-.08-.22-.1-.35-.08l-.16-41.91h-28.39c.33.33,1.24,1.09,2.73,2.26,1.61,1.26,2.85,2.53,3.73,3.81,1.45,2.12,2.18,3.6,2.18,4.44l.16,53.32,20.58.15,44.63-64.08-19.37-.04Z"
          />
          <path
            className="cls-2"
            d="m176.49,91.42c-.49-.38-3.63-2.45-9.43-6.2-5.25-3.4-7.53-5.25-7.62-8.11-.12-3.96,5.01-7.16,13.78-5.79,4.86.76,9.61,3.11,14.24,7.06l.52-.05,2.52-13.53c-4.39-2.98-8.44-4.89-12.14-5.72-14.6-3.28-39.38,2.13-38.75,21.73.21,6.56,3.8,11.46,10.76,14.68l1.78.82c5.34,2.47,12.98,6.31,12.76,12.28-.31,8.44-18.48,6.99-28.51-.29l-4.26-3.34-.3,13.78c-.06,2.05,5.34,4.93,8.33,6.02,12.97,4.72,29.02,4.02,38.31-5.69h0c9.5-9.93,8.83-19.15-2-27.66Z"
          />
          <path
            className="cls-2"
            d="m241.38,120.44l-3.89-59.69-20.03.02-.24,1.63-37.65,62.29,17.9-.05c-.6-1.38-.68-2.63-.23-3.76l7.53-12.2,17.82-.3c0,.3.11,3.91.34,10.86.09,2.37-.05,4.16-.39,5.36l19.36-.08h0s-.52-4.09-.52-4.09Zm-30.25-22.49l9.05-15.35,1.13,15.1.03.22-10.21.03Z"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default CreditCupIcon
