import React, { useRef, useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Stack,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { ScheduledInvoicesPaymentViewModel } from '@rsmus/ecp-financeservice'
import {
  setSelectedBankAccount,
  setSelectedCreditCard,
  setSelectedPayer,
  setAccountSelectedType,
  SelectedAccountTypeState,
  setNewPaymentMethod,
} from '../../../store/invoices/paymentInfoSlice'
import ResponsiveModal from '../../../rsmCoreComponents/components/ResponsiveModal'
import { Styles } from '../../../types'
import AlertIconCircle from '../../icons/AlertIcon/AlertIconCircle'

interface ScheduledinvoicesDialogProps {
  Dialogtext: string
  Subtext1: string
  Subtext2: string
  buttonCloseText: string
  open: boolean
  onClose: () => void
  scheduledInvoicesData: ScheduledInvoicesPaymentViewModel[]
}

const styles: Styles = {
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    maxHeight: '100%',
    height: '100%',
    border: '0',
    padding: '0.5rem',
  },
  subText: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'center',
    padding: '0.5rem',
  },
  subTextLineItems: {
    fontFamily: 'Prelo-Book, sans-serif',
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    border: '0',
    textAlign: 'left',
    padding: '0.5rem',
    fontWeight: 'bold',
    paddingLeft: '1.5rem',
  },
  scrollableContainer: {
    maxHeight: '10rem',
    overflowY: 'auto',
    width: '100%',
    border: '0',
  },
  acceptWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
    paddingTop: '0.5rem',
    border: '0',
  },
}

const ScheduledinvoicesDialog = ({
  Dialogtext,
  Subtext1,
  Subtext2,
  buttonCloseText,
  open,
  onClose,
  scheduledInvoicesData,
}: ScheduledinvoicesDialogProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const unselectedStatus: SelectedAccountTypeState = 'Unselected'
  const desktopMaxWidth = '46rem'

  // State to manage the max height
  const [desktopMaxHeight, setDesktopMaxHeight] = useState('35rem')

  // Ref to get the content height
  const contentRef = useRef<HTMLTableElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight
      const newHeight = Math.min(contentHeight + 80, 640) // max height 640px (40rem)
      const newHeightInRem = newHeight / 16 // convert to rem
      setDesktopMaxHeight(`${newHeightInRem}rem`)
    }
  }, [scheduledInvoicesData, open])

  const handleBackToPreviousPage = () => {
    dispatch(setSelectedBankAccount(undefined))
    dispatch(setSelectedCreditCard(undefined))
    dispatch(setSelectedPayer(undefined))
    dispatch(setAccountSelectedType(unselectedStatus))
    dispatch(setNewPaymentMethod(false))
    navigate('/invoicing/invoices/pay-invoices')
  }

  return (
    <Box className="Rsm-ProfileModal">
      <ResponsiveModal
        open={open}
        onClose={onClose}
        aria-label={Dialogtext}
        desktopMaxHeight={desktopMaxHeight}
        desktopMaxWidth={desktopMaxWidth}
        removeHeaderSpacing>
        <Table ref={contentRef}>
          <TableBody>
            <TableRow>
              <TableCell sx={styles.dialogContent}>
                <AlertIconCircle />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.subText}>{Subtext1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: '0' }}>
                <Box
                  sx={{
                    ...styles.scrollableContainer,
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {scheduledInvoicesData.map((invoice) => (
                    <TableRow key={invoice.invoiceNumber}>
                      <TableCell sx={styles.subTextLineItems}>
                        <span
                          style={{
                            marginRight: '0.5rem',
                            fontWeight: 'bold',
                          }}>
                          •
                        </span>
                        {t(
                          'Invoicing.ScheduledinvoicesDialog.ScheduledInvoiceslineitems',
                          {
                            invoicenumber: invoice.invoiceNumber,
                            paymenttype: invoice.scheduledPaymentType,
                            Date: format(
                              new Date(invoice.createdDate),
                              'MM/dd/yyyy',
                            ),
                          },
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.subText}>{Subtext2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={styles.acceptWrapper}>
                <Stack sx={styles.acceptWrapper}>
                  <Button variant="outlined" onClick={onClose}>
                    {buttonCloseText}
                  </Button>

                  <Button
                    variant="contained"
                    onClick={handleBackToPreviousPage}>
                    {t('Invoicing.BackToPreviousPage')}
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ResponsiveModal>
    </Box>
  )
}

export default ScheduledinvoicesDialog
