import { Box, styled } from '@mui/material'
import { t } from 'i18next'
import React, { useContext } from 'react'
import SearchBox from '../../../rsmCoreComponents/components/Search/SearchBox'
import { applyFilter } from '../state/actions'
import { ProjectsContext } from '../state/context'

const Container = styled(Box)(({ theme }) => ({
  width: theme.typography.pxToRem(447),
  height: theme.typography.pxToRem(56),
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}))

const Search = () => {
  const { dispatch } = useContext(ProjectsContext)
  const handleSubmitFilter = (filter: string) => {
    dispatch(applyFilter(filter))
  }
  return (
    <Container>
      <SearchBox
        setFilter={handleSubmitFilter}
        placeholderText={t('SearchByKeyword')}
        testId="txt_ProjectList_Search"
      />
    </Container>
  )
}
export default Search
