import { Theme } from '@mui/material'

export const useStyles = (theme: Theme) => ({
  root: {
    flexDirection: 'column',
    height: '100%',
    fontSize: '1rem',
    color: '#515356',
    width: '100%',
    justifyContent: 'flex-start',
    minHeight: 'auto',

    [theme.breakpoints.up('tablet')]: {
      width: '16.75rem',
      justifyContent: 'space-between',
      minHeight: '10rem',
    },
  },
  appName: {
    fontFamily: 'Prelo-Bold, sans-serif',
    height: '1.5rem',
  },
  appCategory: {
    fontFamily: 'Prelo-Book,sans-serif',
    textTransform: 'uppercase',
    fontSize: '.75rem',
    pt: '.5rem',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
  },
  appDesc: {
    fontFamily: 'Prelo-Book,sans-serif',
    fontSize: '.875rem',
    pt: '.0625rem',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    height: 'auto',
    [theme.breakpoints.down('tablet')]: {
      width: '100%',
    },
    [theme.breakpoints.up('desktop')]: {
      height: '2.8125rem',
    },
  },
})

export default useStyles
