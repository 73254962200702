import React from 'react'

const ExclamationTriangleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0023 19.737H3.9977C3.86661 19.7366 3.73785 19.7023 3.62393 19.6374C3.51 19.5726 3.41478 19.4794 3.34751 19.3668C3.28357 19.2522 3.25 19.123 3.25 18.9917C3.25 18.8604 3.28357 18.7313 3.34751 18.6166L11.3498 4.61261C11.421 4.50532 11.5176 4.4173 11.631 4.35642C11.7445 4.29553 11.8712 4.26367 12 4.26367C12.1288 4.26367 12.2555 4.29553 12.369 4.35642C12.4824 4.4173 12.579 4.50532 12.6502 4.61261L20.6525 18.6166C20.7164 18.7313 20.75 18.8604 20.75 18.9917C20.75 19.123 20.7164 19.2522 20.6525 19.3668C20.5852 19.4794 20.49 19.5726 20.3761 19.6374C20.2621 19.7023 20.1334 19.7366 20.0023 19.737ZM5.28807 18.2365H18.7119L12 6.49315L5.28807 18.2365Z"
      fill="#FFB729"
    />
    <path
      d="M12 14.2353C11.8018 14.2328 11.6125 14.1529 11.4723 14.0127C11.3322 13.8726 11.2523 13.6833 11.2497 13.4851V9.98413C11.2497 9.78516 11.3288 9.59434 11.4695 9.45364C11.6102 9.31295 11.801 9.23391 12 9.23391C12.1989 9.23391 12.3897 9.31295 12.5304 9.45364C12.6711 9.59434 12.7502 9.78516 12.7502 9.98413V13.4851C12.7476 13.6833 12.6677 13.8726 12.5276 14.0127C12.3874 14.1529 12.1981 14.2328 12 14.2353Z"
      fill="#FFB729"
    />
    <path
      d="M12 17.2362C11.8018 17.2336 11.6125 17.1537 11.4723 17.0136C11.3322 16.8735 11.2523 16.6842 11.2497 16.486V15.9859C11.2497 15.7869 11.3288 15.5961 11.4695 15.4554C11.6102 15.3147 11.801 15.2356 12 15.2356C12.1989 15.2356 12.3897 15.3147 12.5304 15.4554C12.6711 15.5961 12.7502 15.7869 12.7502 15.9859V16.486C12.7476 16.6842 12.6677 16.8735 12.5276 17.0136C12.3874 17.1537 12.1981 17.2336 12 17.2362Z"
      fill="#FFB729"
    />
  </svg>
)

export default ExclamationTriangleIcon
