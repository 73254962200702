import React, { useEffect, useState } from 'react'
import PieChart from './PieChart'
import PieChartLegend from './PieChartLegend'
import PieChartProps from './PieChartProps'

const PieChartParent = ({ pProps }: { pProps: PieChartProps | undefined }) => {
  const [chartProps, setChartProps] = useState<PieChartProps>()

  useEffect(() => {
    if (pProps !== undefined) {
      setChartProps(pProps)
    }
  })

  return (
    <div className="tablet:w-full mobile:w-full mt-[1px]">
      <div className="flex-1 mt-[1px] mb-[1px] font-prelo-book text-[#000000] text-base leading-6">
        <p>{chartProps?.chartTitle}</p>
      </div>
      <div className="flex tablet:justify-center tablet:items-center mt-[35px]">
        <div className="basis-1/2 min-w-[100px] ">
          <PieChart tsProps={chartProps} />
        </div>
        <div className="basis-1/2 ">
          <PieChartLegend pProps={chartProps} />
        </div>
      </div>
    </div>
  )
}

export default PieChartParent
