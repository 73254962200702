import * as React from 'react'
import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Modal from './Modal/Modal'

export type ConfirmModalProps = {
  isOpen: boolean
  okHandler: () => void
  cancelHandler: () => void
  showCancel: boolean
  title: string
  description: string
  okText: string
  cancelText: string
}

const ConfirmModal = ({
  isOpen = false,
  okHandler,
  cancelHandler,
  showCancel,
  title,
  description,
  okText,
  cancelText,
}: ConfirmModalProps) => (
  <Modal
    title={title}
    subTitle={description}
    isOpen={isOpen}
    closeHandler={cancelHandler}>
    <br />
    <Stack direction="row" spacing={4}>
      <Button className="px-6 py-3" variant="outlined" onClick={okHandler}>
        {okText}
      </Button>
      {showCancel && (
        <Button variant="contained" onClick={cancelHandler}>
          {cancelText}
        </Button>
      )}
    </Stack>
  </Modal>
)

export default ConfirmModal
