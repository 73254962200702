import React, { useEffect, useState, useRef } from 'react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Link,
  Typography,
  useTheme,
} from '@mui/material'
import UserApp from './UserApp'
import { UserAppModel } from '../../api/idm/getAllUserApplications'
import { useDeviceType } from '../../rsmCoreComponents/hooks/useDeviceType'
import { Dimensions } from '../../rsmCoreComponents'
import EmptyPcIcon from '../icons/EmptyPcIcon'
import { userAppsStyles } from './UserApps.styles'
import { AnalyticEvent } from '../../analytics/adobe/types'
import useApps from '../../api/user/hooks/useApps'

const analyticEventType: AnalyticEvent = 'Navigation_Click'

// The cards are a static width: 345 = 268px width + 24px padding on each side + 1px border on each side + 27px right margin.
export const CardWidth = 345

const UserApps = () => {
  const [containerWidth, setContainerWidth] = useState<number>(1000)
  const [userAppsCount, setUserAppsCount] = useState<number>(0)
  const [userAppsRowCount, setUserAppsRowCount] = useState<number>(1)
  const [sectionWidth, setSectionWidth] = useState<string>('100%')
  const navigate = useNavigate()
  const focus = useRef<{
    element: HTMLAnchorElement | null
    firstIndex: number
  }>({
    element: null,
    firstIndex: 0,
  })

  const { isMobile } = useDeviceType()
  const theme = useTheme()
  const classes = userAppsStyles(theme)
  const { userApps, loading } = useApps()

  useEffect(() => {
    if (focus.current.element !== null) {
      window.scrollTo({
        top:
          ((
            focus.current.element?.closest(
              '.MuiCard-root',
            ) as HTMLDivElement | null
          )?.offsetTop ?? 0) -
          (document.querySelector('header')?.clientHeight ?? 0),
        behavior: 'smooth',
      })
      focus.current.element?.focus()
    }
  }, [focus.current.element])

  const calcUserAppsCount = (rows: number, divWidth: number) => {
    // See how many cards we can fit on a line.
    const childrenPerLine = Math.floor(divWidth / CardWidth)
    if (rows === 1) {
      setUserAppsCount(isMobile ? 4 : childrenPerLine * rows)
    } else {
      // Show all apps
      focus.current.firstIndex = childrenPerLine
      setUserAppsCount(1000)
    }

    // Set how wide the section is so we can center the section without centering the cards themselves.
    const newSectionWidth = isMobile
      ? '100%'
      : `${
          (userApps.length > 0
            ? Math.min(childrenPerLine, userApps.length)
            : childrenPerLine) *
            CardWidth +
          2
        }px`

    setSectionWidth(newSectionWidth)
  }

  const loadMore = () => {
    setUserAppsRowCount(userAppsRowCount + 1)
    calcUserAppsCount(userAppsRowCount + 1, containerWidth)
  }

  const handleMeasured = (width: number) => {
    setContainerWidth(width)
    calcUserAppsCount(userAppsRowCount, width)
  }

  if (loading) {
    return (
      <CircularProgress
        sx={classes.circularProgress}
        size={96}
        data-testid="Spn_Tools_Loading"
      />
    )
  }

  return userApps?.length > 0 ? (
    <>
      <Dimensions
        id="userTools"
        onMeasured={handleMeasured}
        dependencies={[userApps?.length, userAppsRowCount]}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={classes.cardContainer} width={sectionWidth}>
            {userApps
              ?.slice(0, userAppsCount)
              .map((userApp: UserAppModel, index: number) => (
                <Card key={userApp?.id ? userApp.id : index} sx={classes.card}>
                  <UserApp
                    ref={(element) => {
                      if (
                        index === focus.current.firstIndex &&
                        focus.current.firstIndex
                      ) {
                        focus.current.element = element
                      }
                    }}
                    userApp={userApp}
                    index={index}
                  />
                </Card>
              ))}
          </Box>
        </Box>
      </Dimensions>
      {userAppsCount != null && userApps?.length > userAppsCount ? (
        <Box sx={classes.centerBtnBox}>
          <Button
            variant="outlined"
            sx={{
              borderColor: theme.palette.secondary.main,
              color: theme.palette.secondary.main,
            }}
            onClick={loadMore}
            data-testid="Btn_Tools_LoadMore"
            data-analytic-event={analyticEventType}>
            {t('HomePage.ToolsLoadMore')}
          </Button>
        </Box>
      ) : null}
    </>
  ) : (
    <Box sx={classes.root}>
      <EmptyPcIcon />
      <Typography variant="subtitle1">{t('NoToolsMessage')}</Typography>
      <Typography variant="subtitle1">
        {t('IfYouAreMissingTools')}
        <Link
          href="#ref"
          onClick={() => navigate('/support')}
          sx={classes.rightLink}>
          {t('SubmitSupportTicket')}
        </Link>
      </Typography>
    </Box>
  )
}

export default UserApps
