import React, { memo } from 'react'

const AlertIconCircle = () => (
  <svg
    aria-hidden
    width="120"
    height="120"
    viewBox="0 0 134 135"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M66.9998 14.0625C36.0298 14.0625 11.1665 39.3311 11.1665 70.3125C11.1665 101.514 36.0298 126.562 66.9998 126.562C97.7518 126.562 122.833 101.514 122.833 70.3125C122.833 39.3311 97.7518 14.0625 66.9998 14.0625ZM61.7655 47.4609C61.7655 44.6045 63.9465 42.1875 66.9998 42.1875C69.8351 42.1875 72.2342 44.6045 72.2342 47.4609V75.5859C72.2342 78.6621 69.8351 80.8594 66.9998 80.8594C63.9465 80.8594 61.7655 78.6621 61.7655 75.5859V47.4609ZM66.9998 101.953C63.0741 101.953 60.0207 98.877 60.0207 95.1416C60.0207 91.4062 63.0741 88.3301 66.9998 88.3301C70.7075 88.3301 73.7609 91.4062 73.7609 95.1416C73.7609 98.877 70.7075 101.953 66.9998 101.953Z"
      fill="#515356"
    />
  </svg>
)

export default memo(AlertIconCircle)
