import React from 'react'

const TitleDeedIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_132010)">
      <path
        d="M58.2904 67.54H65.2604V1H12.4004V67.54H47.0804"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.7109 8.40039H55.3509"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.7109 15.9004H57.9809"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8203 33.3008H57.9803"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.7109 19.6602H57.9809"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8203 37.0508H57.9803"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M41.7109 23.4102H57.9809"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8203 40.8008H57.9803"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8203 44.5605H36.4403"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8203 52.2207H42.7203"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8203 55.9707H42.7203"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.7496 27.2606H18.8496V16.3706L28.2996 6.89062L31.7096 10.1206V6.89062H35.7796V14.1906L37.8196 16.2306L37.7496 27.2606Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M25.9492 27.2593V19.5293H31.2192V27.2593"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M53.1804 62.4897C56.6487 62.4897 59.4604 59.678 59.4604 56.2097C59.4604 52.7413 56.6487 49.9297 53.1804 49.9297C49.712 49.9297 46.9004 52.7413 46.9004 56.2097C46.9004 59.678 49.712 62.4897 53.1804 62.4897Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48.9303 61.2791L44.0703 78.9991L52.6503 74.1991L60.4603 78.7591L57.0803 61.1191"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_132010">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TitleDeedIcon
