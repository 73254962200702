import React from 'react'

const RobotHandMobileIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M50.0304 52.4209L51.2104 56.2909L53.7704 64.7109C54.1904 66.0909 53.4004 67.5609 52.0304 67.9709L22.7004 76.8809C21.3204 77.3009 19.8504 76.5109 19.4404 75.1409L16.8804 66.7209L1.55038 16.2909C1.13038 14.9109 1.92038 13.4409 3.29038 13.0209L32.6204 4.11086C34.0004 3.69086 35.4704 4.48086 35.8804 5.86086L46.6104 41.1609"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.5795 68.8504C34.3195 68.0004 34.8095 67.0904 35.6595 66.8304C36.5095 66.5704 37.4195 67.0504 37.6795 67.9104C37.9395 68.7704 37.4595 69.6704 36.5995 69.9304C35.7395 70.1904 34.8395 69.7104 34.5795 68.8504Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.8809 66.731L51.2109 56.291"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M2.66016 19.9517L36.9902 9.51172"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.2207 12.5402L21.6007 10.9102"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.5801 26.0306L28.8601 25.6406"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.0413 40.3517L12.1113 30.7317L25.1813 26.7617"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.4103 42.1406L17.5103 48.4906L15.8203 42.9406"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M34.5801 23.9108L36.5801 23.3008L41.5101 39.5308"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.2657 33.2021L24.8359 34.5488L27.3927 42.9588L31.8225 41.6121L29.2657 33.2021Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5593 39.5274L19.0625 40.8945L20.3423 45.1043L24.8391 43.7372L23.5593 39.5274Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.8418 21.5794L28.6895 22.8418L34.1782 40.8959L38.3305 39.6335L32.8418 21.5794Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3809 43.3805L17.3009 39.6205L21.6209 37.5405L22.3809 32.4505L26.6609 32.6205L26.7509 30.6105L26.4609 20.8305L28.9809 21.6505L32.6909 15.8105"
        stroke="#009CDE"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M59.5805 58.031C59.5805 58.031 45.0205 49.851 40.4905 47.141C35.5905 44.211 40.2405 37.271 45.1505 40.201C48.9205 42.451 63.5105 51.451 63.5105 51.451"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M57.6003 47.6319C57.6003 47.6319 55.1803 45.6819 56.9903 42.6619C58.7903 39.6419 63.1703 41.7419 63.1703 41.7419L67.7003 44.4419"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M70.991 37.2006L67.971 35.4006C66.461 34.5006 63.671 34.8806 62.551 36.7706C60.971 39.4106 62.411 41.2906 62.411 41.2906"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M75.3497 31.6212C72.7097 30.0412 70.0697 31.0312 69.1697 32.5412C68.2697 34.0512 68.7297 35.8612 69.4797 36.3112"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M49.3002 42.6816L45.2402 49.4716"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M55.3301 46.2812L50.8301 53.8313"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M65.7712 62.2305L63.5312 71.1305"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M79.6901 33.7311C77.2201 32.5111 75.3501 31.6211 75.3501 31.6211L59.5801 58.0311C59.5801 58.0311 69.9901 63.7311 71.8001 65.8411C73.6101 67.9511 76.0301 72.4611 76.0301 72.4611"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M66.371 62.0817C66.371 62.0817 62.901 61.0317 58.841 62.7017C54.771 64.3717 52.251 65.7717 53.861 68.5517C55.091 70.6917 74.321 73.3517 76.461 72.9017C77.251 72.7317 78.441 72.3616 79.731 71.8417"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
  </svg>
)

export default RobotHandMobileIcon
