import React from 'react'

export interface ShareIconProps {
  color?: string
  size?: string
}

const ShareIcon = ({ color = 'black', size = '1.5rem' }: ShareIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.0417 13.625C60.0417 20.6986 54.3027 26.4375 47.2292 26.4375C43.7591 26.4375 40.556 25.1029 38.2871 22.9674L25.7415 29.2402C25.7415 29.6406 25.7415 30.1745 25.7415 30.7083C25.7415 31.2422 25.7415 31.776 25.7415 32.3099L38.2871 38.5827C40.556 36.4473 43.7591 34.9792 47.2292 34.9792C54.3027 34.9792 60.0417 40.7181 60.0417 47.7917C60.0417 54.8652 54.3027 60.6042 47.2292 60.6042C40.0221 60.6042 34.4167 54.8652 34.4167 47.7917C34.4167 47.2578 34.4167 46.724 34.4167 46.3236L21.8711 40.0508C19.6022 42.1862 16.3991 43.5208 13.0625 43.5208C5.85547 43.5208 0.25 37.7819 0.25 30.7083C0.25 23.6348 5.85547 17.8958 13.0625 17.8958C16.3991 17.8958 19.6022 19.3639 21.8711 21.4993L34.4167 15.2266C34.4167 14.6927 34.4167 14.1589 34.4167 13.625C34.4167 6.55143 40.0221 0.8125 47.2292 0.8125C54.3027 0.8125 60.0417 6.55143 60.0417 13.625ZM12.929 34.9792C15.3314 34.9792 17.1999 33.1107 17.1999 30.7083C17.1999 28.4395 15.3314 26.4375 12.929 26.4375C10.6602 26.4375 8.6582 28.4395 8.6582 30.7083C8.6582 33.1107 10.6602 34.9792 12.929 34.9792ZM47.2292 9.35417C44.8268 9.35417 42.9583 11.3561 42.9583 13.625C42.9583 16.0273 44.8268 17.8958 47.2292 17.8958C49.498 17.8958 51.5 16.0273 51.5 13.625C51.5 11.3561 49.498 9.35417 47.2292 9.35417ZM47.2292 52.0625C49.498 52.0625 51.5 50.194 51.5 47.7917C51.5 45.5228 49.498 43.5208 47.2292 43.5208C44.8268 43.5208 42.9583 45.5228 42.9583 47.7917C42.9583 50.194 44.8268 52.0625 47.2292 52.0625Z"
      fill={color}
    />
  </svg>
)

export default ShareIcon
