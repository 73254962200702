import React from 'react'

const RocketIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10057_132136)">
      <path
        d="M79.09 3.00023C40.99 5.99023 24.25 38.5102 24.25 38.5102L43.23 57.4902C43.23 57.4902 73.1 44.6302 79.08 2.99023L79.09 3.00023Z"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M58.0608 32.02C62.3465 32.02 65.8208 28.5457 65.8208 24.26C65.8208 19.9743 62.3465 16.5 58.0608 16.5C53.7751 16.5 50.3008 19.9743 50.3008 24.26C50.3008 28.5457 53.7751 32.02 58.0608 32.02Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M35.9891 23.0502C35.9891 23.0502 26.2891 21.2302 18.9391 23.2702C11.5891 25.3102 6.28906 37.6902 6.28906 37.6902C6.28906 37.6902 17.8691 31.9002 26.2291 35.2002"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M59.4504 45.4492C59.4504 45.4492 61.2204 55.1592 59.1404 62.4892C57.0604 69.8292 44.6504 75.0692 44.6504 75.0692C44.6504 75.0692 50.5004 63.5192 47.2404 55.1392"
        stroke="#CCCCCC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M26.2301 47.3496L7.83008 65.2196"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.12 50.9609L2 79.3909"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.3908 55.5801L25.3008 65.1701"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.2696 39.5099C32.0539 39.5099 32.6896 38.8742 32.6896 38.0899C32.6896 37.3057 32.0539 36.6699 31.2696 36.6699C30.4854 36.6699 29.8496 37.3057 29.8496 38.0899C29.8496 38.8742 30.4854 39.5099 31.2696 39.5099Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M34.1192 42.7091C34.9035 42.7091 35.5392 42.0734 35.5392 41.2891C35.5392 40.5049 34.9035 39.8691 34.1192 39.8691C33.335 39.8691 32.6992 40.5049 32.6992 41.2891C32.6992 42.0734 33.335 42.7091 34.1192 42.7091Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M37.2305 45.9005C38.0148 45.9005 38.6505 45.2648 38.6505 44.4805C38.6505 43.6963 38.0148 43.0605 37.2305 43.0605C36.4463 43.0605 35.8105 43.6963 35.8105 44.4805C35.8105 45.2648 36.4463 45.9005 37.2305 45.9005Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.3302 48.7502C41.1144 48.7502 41.7502 48.1144 41.7502 47.3302C41.7502 46.5459 41.1144 45.9102 40.3302 45.9102C39.5459 45.9102 38.9102 46.5459 38.9102 47.3302C38.9102 48.1144 39.5459 48.7502 40.3302 48.7502Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M43.1798 52.1408C43.964 52.1408 44.5998 51.505 44.5998 50.7208C44.5998 49.9365 43.964 49.3008 43.1798 49.3008C42.3955 49.3008 41.7598 49.9365 41.7598 50.7208C41.7598 51.505 42.3955 52.1408 43.1798 52.1408Z"
        stroke="#009CDE"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10057_132136">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default RocketIcon
