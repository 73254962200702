import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'
import { revertFormattedTagString } from '../../utils/helpers/formatTags.service'

export interface InsightsFilterObj {
  yourinsights: string
  industry: string
  role: string
  service: string
  topic: string
}

export interface AppliedFilters {
  selectedTagFilters: Map<
    keyof InsightsFilterObj,
    Set<InsightsFilterObj[keyof InsightsFilterObj]>
  >
  limitToFavorites: boolean
  limitToRecentlyViewed: boolean
}

interface InsightsState {
  availableTagFilters: Map<
    keyof InsightsFilterObj,
    Set<InsightsFilterObj[keyof InsightsFilterObj]>
  >
  appliedFilters: AppliedFilters
}

const initialState: InsightsState = {
  availableTagFilters: new Map(),
  appliedFilters: {
    selectedTagFilters: new Map(),
    limitToFavorites: false,
    limitToRecentlyViewed: false,
  },
}

export const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    clearAppliedFilters: (state) => {
      state.appliedFilters = {
        selectedTagFilters: new Map(),
        limitToFavorites: false,
        limitToRecentlyViewed: false,
      }
    },
    // This will clear all previously applied filters and then will turn on any available
    // filters which match the specified tag parameter (payload)
    setFilterOnMatchingTagValue: (state, { payload }) => {
      // Spin up a new map.
      const newAppliedFilters = new Map<
        keyof InsightsFilterObj,
        Set<InsightsFilterObj[keyof InsightsFilterObj]>
      >()

      // Iterate through all available tag filters, searching for a tag match.
      state.availableTagFilters.forEach(
        (
          setOfTags: Set<InsightsFilterObj[keyof InsightsFilterObj]>,
          category: keyof InsightsFilterObj,
        ) => {
          setOfTags.forEach((tagValue: string) => {
            // We have to reverse engineer the tag so it is compatible with the list of available tags.
            const formattedTagValue = revertFormattedTagString(payload.tag)
            if (tagValue === formattedTagValue) {
              // We found a match.  Now add it to the new map of filters to be applied.
              const valueSet = new Set(newAppliedFilters?.get(category))
              valueSet.add(tagValue)
              newAppliedFilters.set(category, valueSet)
            }
          })
        },
      )
      // Update the selected tag filters with the new map.
      state.appliedFilters.selectedTagFilters = newAppliedFilters
    },
    setAvailableTagFilters: (state, { payload }) => {
      state.availableTagFilters = payload
    },
    setIncludeFavorites: (state, { payload }) => {
      state.appliedFilters.limitToFavorites = payload
    },
    setAppliedTagFilters: (state, { payload }) => {
      state.appliedFilters.selectedTagFilters = payload
    },
  },
})

export const {
  clearAppliedFilters,
  setAppliedTagFilters,
  setAvailableTagFilters,
  setIncludeFavorites,
  setFilterOnMatchingTagValue,
} = insightsSlice.actions
export const getAppliedTagFilters = (state: RootState) =>
  state.insights.appliedFilters.selectedTagFilters
export const getAvailableTagFilters = (state: RootState) =>
  state.insights.availableTagFilters
export const getIncludegetDocumentStatus = (state: RootState) =>
  state.insights.appliedFilters.limitToFavorites
export const getAppliedFilters = (state: RootState) =>
  state.insights.appliedFilters
export default insightsSlice.reducer
