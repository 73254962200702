import React from 'react'
import { Box } from '@mui/material'
import NoSearchResultIcon from '../../../components/icons/NoSearchResultIcon'
import useStyles from './NoSearchFoundsmall.styles'

export const NoSearchFoundsmall = () => {
  const classes = useStyles()
  return (
    <Box sx={classes.root}>
      <Box sx={classes.content}>
        <Box sx={classes.iconContainer}>
          <NoSearchResultIcon />
        </Box>
      </Box>
    </Box>
  )
}

export default NoSearchFoundsmall
