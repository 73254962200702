import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../utils/hooks'
import { getRedirectUrl } from '../../store/deepLink/deepLinkSlice'

const DeepLink = () => {
  const navigate = useNavigate()

  const redirectUrl = useAppSelector(getRedirectUrl)

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl.replace(window.location.origin, ''))
    }
  }, [redirectUrl])
  return <span />
}

export default DeepLink
