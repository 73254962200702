import {
  styled,
  TextField,
  Autocomplete as MuiAutocomplete,
} from '@mui/material'
import tokens from '../../../styles/tokens.json'

export const StyledTextField = styled(TextField)({
  '.MuiInputBase-root': {
    width: '100%',
    maxWidth: '100%',
  },
  fieldset: {
    borderRadius: 0,
    fontFamily: 'Prelo-medium, sans-serif',
    width: '100%',
  },
  'fieldset:active': {
    outline: 'none',
  },
})

export type IListModal<T> = {
  key: string
  context: T
}

export const Autocomplete = styled(MuiAutocomplete)({
  root: {
    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
      WebkitTextFillColor: 'red',
    },
  },

  '& .MuiOutlinedInput-root': {
    '& input': {
      '&::placeholder': {
        color: tokens.colors.rsmText.primary,
        opacity: 1,
      },
    },
  },

  '.MuiAutocomplete-popupIndicator.MuiAutocomplete-popupIndicatorOpen': {
    transform: 'rotate(0deg)',
    WebkitTransform: 'rotate(0deg)',
  },
})
